import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import NewReleaseCourses from "../dashboard/NewReleaseCourses";
import TrendingLearningCircle from "../dashboard/TrendingLearningCircle";
import LearningCircleTab from "./LearningCircleTab";
import StudentTab from "./StudentTab";
import TutorTab from "./TutorTab";
import { stickyArea } from '../dashboard/scroll';
import Joyride from 'react-joyride';
import Cookies from "js-cookie";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const NotificationListing = () => {
    const [fetchUnreadNotificationCount] = useOutletContext();
    const [active_tab, setActiveTab] = useState(1);
    const [studentCount, setStudentCount] = useState(0);
    const [tutorCount, setTutorCount] = useState(0);
    const [learningCircleCount, setLearningCircle] = useState(0);
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const _token = localStorage.getItem('lmstoken');
    const [tour_steps, setTourSteps] = useState([]);
    const [userNotiPreference, setUserNotiPreference] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        fetchNotification();
        fetchStudentNotifications();
        fetchTutorNotifications();
        fetchLearningCircleNotifications();
        if (user?.is_corporate === 1) { setActiveTab(2) }

        stickyArea();
        showNotificationTour();
    }, [])
    const showNotificationTour = () => {
        let show_tour = Cookies.get("show_notification_tour");
        if (show_tour != 'NO') {
            SwalBox.fire({
                allowOutsideClick: false,
                title: t('_show_tour_maintitle_'),
                text: t('_show_tour_msg_notilist_'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: t('_show_tour_yes_'),
                cancelButtonText: t('_show_tour_no_')
            }).then((result) => {
                if (result.isConfirmed) {
                    startNotificationTour();
                } else {
                    Cookies.set('show_notification_tour', 'NO');
                }
            });
        }
    }
    const startNotificationTour = () => {
        let steps = [];
        if (user?.is_corporate === 1) {
            steps = [
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-notification-step-01', title: t('_notilist_tour_t1_'), content: t('_notilist_tour_m1_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-notification-step-03', title: t('_notilist_tour_t2_'), content: t('_notilist_tour_m2_'), },
            ];
        } else {
            if (user?.is_tutor === 1) {
                steps = [
                    { placementBeacon: 'right', disableBeacon: true, target: '.tour-notification-step-01', title: t('_notilist_tour_t3_'), content: t('_notilist_tour_m3_'), },
                    { placementBeacon: 'right', disableBeacon: true, target: '.tour-notification-step-02', title: t('_notilist_tour_t4_'), content: t('_notilist_tour_m4_'), },
                    { placementBeacon: 'right', disableBeacon: true, target: '.tour-notification-step-03', title: t('_notilist_tour_t2_'), content: t('_notilist_tour_m2_'), },
                ];
            } else {
                steps = [
                    { placementBeacon: 'right', disableBeacon: true, target: '.tour-notification-step-02', title: t('_notilist_tour_t3_'), content: t('_notilist_tour_m3_'), },
                    { placementBeacon: 'right', disableBeacon: true, target: '.tour-notification-step-03', title: t('_notilist_tour_t2_'), content: t('_notilist_tour_m2_'), },
                ];
            }
        }
        setTourSteps(steps);
        Cookies.set('show_notification_tour', 'NO');
    }

    const fetchStudentNotifications = async () => {
        const response = await fetch(`${API_BASE}get-notification-feeds-by-tab/Student`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status && data?.data?.unread_count) {
            setStudentCount(data?.data?.unread_count);
        }
    }

    const fetchTutorNotifications = async () => {
        const response = await fetch(`${API_BASE}get-notification-feeds-by-tab/Tutor`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status && data?.data?.unread_count) {
            setTutorCount(data?.data?.unread_count);
        }
    }

    const fetchLearningCircleNotifications = async () => {
        const response = await fetch(`${API_BASE}get-notification-feeds-by-tab/Learning_Circle`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status && data?.data?.unread_count) {
            setLearningCircle(data?.data?.unread_count);
        }
    }

    const fetchNotification = async () => {
        let formData = {};
        const _lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
        const _lms_learner_id = _lmsuser['id'];
        formData.user_id = _lms_learner_id;


        const response = await fetch(`${API_BASE}get-notification-preferences`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
            body: JSON.stringify(formData)

        });

        const data = await response.json();
        if (data.status) {
            setUserNotiPreference(data.data);
        } else {

        }
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_noti_notification_')}`}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="custom-user-profile-content notification_dashboard">
                            <div className="course-details-tab-block explore-block">
                                <ul className="nav nav-pills custom-section" id="pills-tab" role="tablist">
                                    {
                                        user?.is_corporate !== 1
                                        &&
                                        <li className="nav-item tour-notification-step-01">
                                            <a onClick={(e) => { setActiveTab(1); }} className={`nav-link ${active_tab === 1 ? 'active' : ''}`} id="pills-student-tab" data-toggle="pill" href="#pill-student" role="tab" aria-controls="pill-student" aria-selected="true">{t('_noti_student_')} <span>{studentCount}</span></a>
                                        </li>
                                    }
                                    {
                                        user?.is_tutor === 1
                                        &&
                                        <li className="nav-item tour-notification-step-02">
                                            <a onClick={(e) => { setActiveTab(2); }} className={`nav-link ${active_tab === 2 ? 'active' : ''}`} id="pills-tutor-tab" data-toggle="pill" href="#pills-tutor" role="tab" aria-controls="pills-tutor" aria-selected="false">{user?.is_corporate === 1 ? t('_noti_course_') : t('_noti_tutor_')} <span>{tutorCount}</span></a>
                                        </li>
                                    }
                                    <li className="nav-item tour-notification-step-03">
                                        <a onClick={(e) => { setActiveTab(3); }} className={`nav-link ${active_tab === 3 ? 'active' : ''}`} id="pills-circle-tab" data-toggle="pill" href="#pills-circle" role="tab" aria-controls="pills-circle" aria-selected="false">{t('_noti_lc_')} <span>{learningCircleCount}</span></a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    {userNotiPreference !== '' && (<>
                                        {
                                            (active_tab === 1)
                                            &&
                                            <StudentTab setStudentCount={setStudentCount} fetchUnreadNotificationCount={fetchUnreadNotificationCount} userNotiPreference={userNotiPreference} />
                                        }
                                        {
                                            (active_tab === 2 && user?.is_tutor === 1)
                                            &&
                                            <TutorTab setTutorCount={setTutorCount} fetchUnreadNotificationCount={fetchUnreadNotificationCount}
                                                userNotiPreference={userNotiPreference}
                                            />
                                        }
                                        {
                                            (active_tab === 3)
                                            &&
                                            <LearningCircleTab setLearningCircle={setLearningCircle} fetchUnreadNotificationCount={fetchUnreadNotificationCount} userNotiPreference={userNotiPreference} />
                                        }
                                    </>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div data-sticky="true" data-top-gap="10" data-bottom-gap="10" className="custom-user-profile-content sticky-top">
                            {
                                user?.is_corporate !== 1
                                &&
                                <NewReleaseCourses />
                            }
                            <TrendingLearningCircle />
                        </div>
                    </div>
                </div>
            </div>

            {
                tour_steps.length > 0
                &&
                <Joyride
                    steps={tour_steps}
                    disableCloseOnEsc={true}
                    disableOverlayClose={true}
                    showSkipButton={true}
                    continuous={true}
                    hideCloseButton={true}
                    locale={{ back: t('_tour_back_'), close: t('_tour_close_'), last: t('_tour_last_'), next: t('_tour_next_'), open: t('_tour_open_dialog_'), skip: t('_tour_skip_') }}
                    styles={{ options: { width: 500, zIndex: 5000, primaryColor: '#31314c', textColor: '#ef4a45', overlayColor: 'rgb(2 1 0 / 69%)', } }}
                />
            }

        </>
    );
}

export default NotificationListing;
