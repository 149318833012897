import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";
import { API_BASE } from "../../../../constants/global";
import CustomPagination from "../../../../entity/CustomPagination";
import { SwalBox, Toaster } from "../../../../entity/GlobalJS";
import { useTranslation } from "react-i18next";

const Sessions = ({ course_id, learning_mode }) => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);
    const { t } = useTranslation();

    const _token = localStorage.getItem('lmstoken');
    const userData = JSON.parse(localStorage.getItem('lmsuser'));
    const [meeting_requests, setMeetingRequests] = useState('');
    const [list_meetings, setListMeetings] = useState('');
    const [list_timezones, setListTimezones] = useState('');
    const [total_count, setTotalCount] = useState(0);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [submitting, setSubmitting] = useState(false);
    const [min_time, setMinTime] = useState('');
    const [time_zone_time, setTimeZoneTime] = useState('');
    const [sessionbtn, setSessionbtn] = useState(false);
    useEffect(() => {
        fetchTimezones();
        fetchRequestedMeetings();
        fetchMeetings(currentPage);
        // eslint-disable-next-line
        fetchCourse();
    }, [location, reload]);

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }

    const fetchTimezones = async () => {
        const response = await fetch(`${API_BASE}get-all-timezones`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setListTimezones(data.data.timezones);
        } else {
            setListTimezones('');
        }
    }

    const fetchRequestedMeetings = async () => {
        const response = await fetch(`${API_BASE}get-meeting-time-slots/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setMeetingRequests(data.data.slots);
        } else {
            setMeetingRequests('');
        }
    }

    const chnageRequestStatus = async (id, status) => {
        const formData = new FormData();
        formData.append('status', status);

        const response = await fetch(`${API_BASE}post-update-slot-status/${id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            fetchRequestedMeetings();
            fetchMeetings();
        } else {
            Toaster.error(data.message);
        }
    }

    const fetchMeetings = async (page = 0) => {
        const response = await fetch(`${API_BASE}get-course-meetings/${course_id}?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setListMeetings(data.data.meetings);
            setTotalCount(data.data.total_count);
        } else {
            setListMeetings('');
            setTotalCount(0);
        }
    }

    const ConfirmDelete = (meeting_id) => {
        SwalBox.fire({
            title: t('_v_delete_confirm_'),
            text: t('_lcmeeting_delete_swal_m1_'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_cancel_')
        }).then((result) => {
            if (result.isConfirmed) {
                deleteMeeting(meeting_id);
            }
        });
    }

    const deleteMeeting = async (id) => {
        const response = await fetch(`${API_BASE}delete-course-meeting/${id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            fetchMeetings();
        } else {
            Toaster.error(data.message);
        }
    }

    const clearForm = () => {
        setValue('timezone', userData?.timezone);
        setValue('title');
        setValue('agenda');
        setValue('date');
        setValue('time');
        setValue('duration');
    }

    const saveMeeting = async (form) => {
        setSubmitting(true);

        const response = await fetch(`${API_BASE}post-create-course-meeting`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(form)
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            document.getElementById('SectionModalLongClose').click();
            fetchMeetings();
            clearForm();
        } else {
            Toaster.error(data.message);
        }

        setSubmitting(false);
    }

    const StartMeeting = async (meeting_id) => {
        const response = await fetch(`${API_BASE}start-course-meeting/${meeting_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            window.open(
                `${data.data}`,
                '_blank'
            );
            // window.location.href = data.data;
        } else {
            Toaster.error(data.message);
        }
    }

    const getDateTime = () => {
        let timeZone = document.getElementById('timeZone').value;
        let obj = list_timezones.find(o => o.time_zone === timeZone);
        let selectedOffset = Number(obj.time_zone_offset.replace('GMT', '').replace(':', '.'));
        let myTimeOffset = Number((new Date().getTimezoneOffset() / 60));
        let differ = myTimeOffset + selectedOffset;
        let currentTime = new Date().getTime();
        let updatedTIme = new Date(currentTime + differ * 60 * 60 * 1000);

        let date = document.getElementById('date_field').value;
        if (typeof date !== 'undefined' && date === new Date().toISOString().split('T')[0]) {
            setMinTime(updatedTIme.getHours() + ':' + updatedTIme.getMinutes());
        }
        setTimeZoneTime(updatedTIme.getHours() + ':' + updatedTIme.getMinutes());
        setValue('time_zone_time', updatedTIme.getHours() + ':' + updatedTIme.getMinutes());
    }

    const setTime = (date) => {
        if (date === new Date().toISOString().split('T')[0]) {
            setMinTime(time_zone_time);
        }
        else {
            setMinTime('');
        }
    }

    const fetchCourse = async () => {
        if (course_id !== '') {
            const response = await fetch(`${API_BASE}get-course-form-data-stepOne/${course_id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + _token
                },
            });
            const data = await response.json();
            if (data.status) {
                const course = data.data;
                if (course.mode_of_learning == 'One To One' && course.creator.uid == userData.id) {
                    setSessionbtn(false);
                } else {
                    setSessionbtn(true);
                }
            } else {
                Toaster.error(data.message);
            }
        }
    }
    return (
        <>
            <div className="tab-pane fade show active" id="pills-live" role="tabpanel" aria-labelledby="pills-live-tab">
                <div className="blog-card meeting_section">
                    {
                        sessionbtn == true
                        &&
                        <>
                            <button className="btn btn-circle btn-success float-right" data-toggle="modal" data-target="#SectionModalLong" onClick={(e) => { clearForm(); }}>{t('_managecour_session_m1_')} </button>
                        </>
                    }

                    <div className="modal fade book_session_popup" id="SectionModalLong" tabIndex="-1" role="dialog" aria-labelledby="SectionModalLongTitle" aria-hidden="true">
                        <div className="modal-dialog  popup_width modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 id="SectionModalLongTitle">{t('_managecour_session_m2_')} </h4>
                                    <button type="button" className="close" id="SectionModalLongClose" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={handleSubmit(saveMeeting)}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <select {...register("timezone")} className="form-control" id="timeZone" onChange={(e) => { getDateTime(); }} >
                                                        <option value="">{t('_lcmeeting_addmeet_timezone_')} </option>
                                                        {
                                                            list_timezones !== ''
                                                            &&
                                                            list_timezones.map((timezone, index) =>
                                                                <option key={index} value={timezone.time_zone}>{timezone.locations}  ({timezone.time_zone_offset})</option>
                                                            )
                                                        }
                                                    </select>
                                                    <span className="text-danger">
                                                        {errors.timezone && errors.timezone.type === "required" && t('_lcmeeting_addmeet_timezone_vali_m1_')}
                                                        {errors.timezone && errors.timezone.type !== "required" && errors.timezone.message}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <input {...register("course_id")} type="hidden" defaultValue={course_id} />
                                                    <input id="title" {...register("title", {
                                                        required: "required",
                                                        maxLength: {
                                                            value: 80,
                                                            message: t('_lcmeeting_addmeet_title_vali_m1_')
                                                        }
                                                    })} type="text" className="form-control" placeholder={t('_lcmeeting_addmeet_topic_')} />
                                                    <span className="add-on max-chr-counter chrchk-title" data-ele="title" data-limit="80" data-showcounter="false"></span>
                                                    <span className="text-danger">
                                                        {errors.title && errors.title.type === "required" && t('_lcmeeting_addmeet_timezone_vali_m2_')}
                                                        {errors.title && errors.title.type !== "required" && errors.title.message}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <textarea {...register("agenda", { required: "required", })} className="form-control" placeholder={t('_lcmeeting_addmeet_agenda_')} rows="5"></textarea>
                                                    <span className="text-danger">
                                                        {errors.agenda && errors.agenda.type === "required" && t('_lcmeeting_addmeet_agenda_vali_m1_')}
                                                        {errors.agenda && errors.agenda.type !== "required" && errors.agenda.message}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <input {...register("date", { required: "required" })} id="date_field" type="date" min={new Date().toISOString().split('T')[0]} className="form-control" placeholder={t('_lcmeeting_addmeet_date_')} onChange={(e) => { setTime(e.target.value) }} />
                                                    <span className="text-danger">
                                                        {errors.date && errors.date.type === "required" && t('_lcmeeting_addmeet_date_vali_m1_')}
                                                        {errors.date && errors.date.type !== "required" && errors.date.message}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <input
                                                        {...register("time", { required: "required" })}
                                                        type="time"
                                                        min={min_time}
                                                        className="form-control"
                                                        placeholder={t('_lcmeeting_addmeet_time_')}
                                                    />
                                                    <span className="text-danger">
                                                        {errors.time && errors.time.type === "required" && t('_lcmeeting_addmeet_time_vali_m1_')}
                                                        {errors.time && errors.time.type !== "required" && errors.time.message}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <input {...register("duration", {
                                                        required: "required",
                                                        min: {
                                                            value: 10,
                                                            message: t('_lcmeeting_addmeet_duration_vali_m2_')
                                                        },
                                                        max: {
                                                            value: 200,
                                                            message: t('_lcmeeting_addmeet_duration_vali_m3_')
                                                        }
                                                    })} type="number" className="form-control" placeholder={t('_lcmeeting_addmeet_duration_')} />
                                                    <span className="text-danger">
                                                        {errors.duration && errors.duration.type === "required" && t('_lcmeeting_addmeet_duration_vali_m1_')}
                                                        {errors.duration && errors.duration.type !== "required" && errors.duration.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">{t('_tour_close_')}</button>
                                        <button disabled={submitting} type="submit" className="btn btn-circle btn-md submit-pink-btn">{submitting ? <i className="fa fa-refresh fa-spin fa-fw"></i> : t('_v_add_submit_')}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        learning_mode === 'One To One'
                        &&
                        <>
                            <h4>{t('_learncourse_meeting_requested_ses_')}</h4>
                            <div className="meetingdata mb-5 request_data">
                                <table>
                                    <thead>
                                        <tr>
                                            <th width="30%">{t('_lcmeeting_meeting_opt2_')}</th>
                                            <th width="20%">{t('_managecour_session_m3_')} </th>
                                            <th width="25%">{t('_managecour_session_m4_')}</th>
                                            <th width="15%">{t('_lcmeeting_meeting_opt3_')}</th>
                                            <th width="10%">{t('_lcmeeting_meeting_opt5_')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            meeting_requests !== ''
                                            &&
                                            meeting_requests.map((request, index) =>
                                                <tr key={index}>
                                                    <td><p><strong>{request.meeting_title}</strong>{request.short_desc}</p></td>
                                                    <td>{request.date},<br />{request.start_time}</td>
                                                    <td>{request.student.name}</td>
                                                    <td>{request.duration}</td>
                                                    <td className="orange_color">
                                                        <a onClick={(e) => { chnageRequestStatus(request.id, 1); }} className="cursor-pointer text-success p-1 mr-2 btn"><i className="fa fa-check"></i></a>
                                                        <a onClick={(e) => { chnageRequestStatus(request.id, 0); }} className="cursor-pointer text-danger p-1 btn"><i className="fa fa-times"></i></a>
                                                    </td>
                                                </tr>
                                            )
                                        }

                                        {
                                            meeting_requests === ''
                                            &&
                                            <tr>
                                                <td className="text-center bg-white nodata" colSpan={5}>{t('_learncourse_meeting_requested_no_ses_')}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    }

                    <>
                        <div className="clearfix"></div>
                        <h4>{learning_mode} {t('_managelc_meetings_')}</h4>
                        <div className="meetingdata mb-5 one_to_one_meting">
                            <table>
                                <thead>
                                    <tr>
                                        <th width="200">{t('_lcmeeting_meeting_opt2_')} </th>
                                        <th width="110">{t('_lcmeeting_meeting_opt1_')}</th>
                                        <th width="110">{t('_mylc_circlejoined_create_')}</th>
                                        <th width="80">{t('_lcmeeting_meeting_opt3_')}</th>
                                        <th className="text-center" width="80">{t('_emplist_ag_col_status_')} </th>
                                        <th width="80">{t('_lcmeeting_meeting_opt5_')} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list_meetings !== ''
                                        &&
                                        list_meetings.map((meeting, index) =>
                                            <tr key={index}>
                                                <td><p><strong>{meeting.meeting_title}</strong>{meeting.short_desc}</p></td>
                                                <td>{meeting.date},<br />{meeting.time}</td>
                                                <td>{meeting.creator.name}</td>
                                                <td>{meeting.duration}</td>
                                                <td className={`text-center ${meeting.status === 'Not Started' ? 'text-danger' : ''}`}>
                                                    {
                                                        meeting.status === 'Not Started'
                                                        &&
                                                        <button onClick={(e) => { StartMeeting(meeting.id); }} className="btn btn-circle btn-info ml-1 mb-1"> {t('_lcmeeting_meetbtn_start_')} </button>
                                                    }

                                                    {
                                                        meeting.status !== 'Not Started'
                                                        &&
                                                        meeting.status
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        (meeting.status !== 'Completed' && meeting.status !== 'Deleted')
                                                        &&
                                                        <a className="cursor-pointer text-danger text-center p-1 btn" onClick={(e) => { ConfirmDelete(meeting.id); }}><i className="fa fa-trash"></i></a>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                    {
                                        list_meetings === ''
                                        &&
                                        <tr>
                                            <td className="text-center bg-white nodata" colSpan={6}>{t('_learncourse_meeting_no_sess_')}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <div className="clearfix"></div>

                            {
                                total_count > 10
                                &&
                                <div className="custom-pagination">
                                    <nav aria-label="Page navigation example">
                                        <CustomPagination pageCount={total_count} perPageRecord={10} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                                    </nav>
                                </div>
                            }
                        </div>
                    </>


                </div>
                <div className="clearfix"></div>
            </div>
        </>
    );
}

export default Sessions;
