import { Link } from "react-router-dom";
import VerifiedBadge from "../../../../components/VerifiedBadge";
import { useTranslation } from "react-i18next";

const SharedCourse = ({ ntfcn }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src={ntfcn?.shared_by?.image} className="rounded-circle" alt={ntfcn?.shared_by?.name} />
                    </div>
                    <div className="user-details-title">
                        <h6><Link to={`/profile-details/${ntfcn?.shared_by?.slug}`}>{ntfcn?.shared_by?.name}</Link><VerifiedBadge _is_verified={ntfcn?.shared_by?.is_verified} refname="notification" /> <span>{ntfcn.date_time}</span></h6>
                        <p>{ntfcn?.shared_by?.exp}</p>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>{t('_dh_studenttab_share_m1_')} <strong>{ntfcn?.course?.title}</strong> {t('_hr_report_tour_t1_')}.</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link ek-lrs-course-initialized" to={'/course-details/' + ntfcn?.course?.slug} target="_blank" data-ek-lrs-url={`/course-details/${ntfcn?.course?.slug}`} data-ek-lrs-title={ntfcn?.course?.title}>
                            <img src={ntfcn?.course?.image} alt={ntfcn?.course?.title} />
                            <span>{t('_dh_studenttab_share_m2_')}</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SharedCourse;
