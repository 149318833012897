const enJson = {
  "_username_": "Usernames",
  "_general_profile_": "General Profile",
  "_phone_number_": "Phone Number",
  "_headline_": "Headline",
  "_resource_id_": "Resource ID",
  "_about_yourself_": "Introduce yourself. Tell others about your professional journey and achievements here.",
  "_save_": "Save",
  "_facebook_url_": "http://www.facebook.com/",
  "_twitter_url_": "http://www.twitter.com/",
  "_linkedin_url_": "http://www.linkedin.com/",
  "_profile_picture_": "Profile Picture",
  "_company_logo_": "Company Logo",
  "_l_general_profile_": "General Profile",
  "_l_company_logo_": "Company Logo",
  "_l_profile_picture_": "Profile Picture",
  "_l_experience_": "Experience",
  "_l_education_": "Education",
  "_l_resume_builder_": "Resume Builder",
  "_l_responsible_ai_": "Responsible AI",
  "_l_learning_preference_": "Learning Preferences",
  "_l_mentor_preference_": "Mentor Preferences",
  "_l_notification_preference_": "Notification Preferences",
  "_l_ekyc_": "eKYC",
  "_l_account_and_security_": "Account and Security",
  "_experience_": "Experience",
  "_e_title_": "Title",
  "_e_company_name_": "Company name",
  "_e_industry_": "Industry",
  "_e_start_date_": "Start date",
  "_e_end_date_": "End date",
  "_e_employment_type_": "Employment type",
  "_e_working_status_": "I am currently working here",
  "_e_description_": "Description",
  "_e_internship_": "Internship",
  "_e_part_time_": "Part-Time",
  "_e_full_time_": "Full-time",
  "_e_trainee_": "Trainee",
  "_e_self_employed_": "Self-Employed",
  "_e_self_employed_": "Self-Employed",
  "_e_freelance_": "Freelance",
  "_e_add_experience_": "Add New Experience",
  "_e_education_": "Education",
  "_e_school_name_": "School Name",
  "_e_degree_": "Degree",
  "_e_field_of_study_": "Field of Study",
  "_e_add_new_education_": "Add New Education",
  "_e_grade_": "Grade",
  "_r_responsibleai_": "Responsible AI",
  "_r_mes3_": "Hide what you don't need, show it again when you do. Enjoy full control!",
  "_r_mes2_": "Enable or disable modules to personalize your menu items.",
  "_r_mes1_": "Customize your experience!",
  "_r_careeradvice_": "Career Advice",
  "_r_selfassessment_": "Self Assessment",
  "_r_virtualtutor_": "Virtual Tutor",
  "_r_suggestedvideos_": "Suggested Videos",
  "_r_enabled_": "Enabled",
  "_r_disabled_": "Disabled",
  "_lp_learning_preferences_": "Learning Preferences",
  "_lp_industries_": "Industries",
  "_lp_area_": "Area of",
  "_lp_specialization_": "Specialization",
  "_lp_interest_": "interest",
  "_lp_topics_": "Topics you wish to learn about",
  "_lp_check1_": "Do you want to get alerts about the courses you're interested in?",
  "_lp_check2_": "Do you want to get alerts about learning circles you might be interested in?",
  "_lp_check3_": "Do you wish to get alerts about job openings that might be of interest to you?",
  "_lp_que1_": "Receive email notifications and suggestions for preferred course topics :",
  "_lp_que2_": "Receive Chat messages from :",
  "_lp_que3_": "Enable Auto Play :",
  "_lp_que4_": "Enable Auto Complete :",
  "_lp_yes_": "Yes",
  "_lp_no_": "No",
  "_lp_mes_opt1_": "Connections from Course & Learning Circle",
  "_lp_mes_opt2_": "Anyone",
  "_mp_topics_": "Topics You′d like to teach",
  "_mp_email_": "Receive email notifications and suggestions for preferred course topics :",
  "_mp_mentor_preference_": "Mentor Preferences",
  "_mp_error_email1_": "Please select radio button",
  "_mp_message_": "Why should students learn from you ?",
  "_np_heading_": "Here, you can choose what types of notifications you want to receive.",
  "_np_course_learn_": "Courses I learn",
  "_np_course_teach_": "Courses I teach",
  "_np_lc_": "Learning circle",
  "_np_learn_op4_": "One to one meeting notification",
  "_np_learn_op3_": "Q&A notification",
  "_np_learn_op2_": "Meeting notification",
  "_np_learn_op1_": "Material notification",
  "_np_teach_op1_": "Course review notification",
  "_np_teach_op2_": "Q&A notification",
  "_np_teach_op3_": "One to one meeting notification",
  "_np_lc_op1_": "Like notification",
  "_np_lc_op2_": "Comment notification",
  "_np_lc_op3_": "Material notification",
  "_np_lc_op4_": "Meeting notification",
  "_np_lc_op5_": "New post notification",
  "_kyc_": "eKYC",
  "_kyc_mes_": "Upload the documents as mentioned below.( in .jpg/.png format only)",
  "_kyc_photo_": "Your Photo",
  "_kyc_id_": "A Valid Identity Proof",
  "_kyc_sample_": "Here are sample documents for eKYC.",
  "_kyc_smaple_photo_": "Sample Photo",
  "_kyc_sample_id_": "Sample ID",
  "_kyc_complete_": "Your e-KYC has already been approved.",
  "_as_": "Account and Security",
  "_as_email_": "Change Email Address",
  "_as_test_email_": "test@test.com",
  "_as_change_password_": "Change Password",
  "_as_new_pass_": "New Password",
  "_as_con_pass_": "Confirm Password",
  "_as_delete_": "Delete Account",
  "_as_delete_msg_": "This will remove your account & course(s) from the system",
  "_as_delete_btn_": "Delete Account",
  "_preferred_language_": "Preferred language",
  "_corporate_report_": "Corporate Report",
  "_hiring_report_": "Hiring Report",
  "_training_report_": "Training Report",
  "_select_course_": "Select Course",
  "_hr_export_": "Export",
  "_hr_share_": "Share",
  "_hr_print_": "Print",
  "_share_report_": "Share Report",
  "_hr_share_msg_": "Enter Email Addresses to Share Report File",
  "_hr_email_placeholder_": "Enter email address",
  "_hr_email_error_msg_": "Entered value does not match email format",
  "_hr_add_": "Add",
  "_hr_close_": "Close",
  "_hr_share_": "Share Report",
  "_hr_page_size_": "Page Size:-",
  "_hr_no_record_": "No Records Found",
  "_hr_ag_col_sr_": "Sr.",
  "_hr_ag_col_name_": "Name",
  "_hr_ag_col_emp_": "Employee Name",
  "_hr_ag_col_email_": "Email",
  "_hr_ag_col_course_": "Course Status",
  "_hr_ag_col_spent_": "Spent Time",
  "_hr_ag_col_score_": "Score Obtained",
  "_hr_ag_col_hiring_": "Hiring Status",
  "_hr_tour_1_": "To select the course to show course wise",
  "_tr_report_type_": "Select Report Type",
  "_tr_report_type_": "Select Report Type",
  "_tr_select_course_": "Select Course",
  "_tr_select_emp_": "Select Employee",
  "_tr_print_opt1_": "Report Type :-",
  "_hr_print_opt2_": "Creation Date :-",
  "_hr_print_opt3_": "Course Name :-",
  "_hr_print_opt4_": "Description :-",
  "_tr_print_opt3_": "Employee Name :-",
  "_tr_emp_report_": "Employee Training Report",
  "_tr_course_report_": "Course wise employee report",
  "_v_vacancies_": "Vacancies",
  "_v_vacancies_add_": "Create a New Vacancy",
  "_v_course_": "Course :",
  "_v_experience_": "Experience :",
  "_v_invite_talent_": "Invite Talent",
  "_v_detail_": "Details",
  "_v_remove_": "Remove",
  "_v_no_vacancy_": "No vacancy listed!",
  "_v_course_not_create_": "You Haven't Created Any Vacancy Yet.",
  "_v_invite_email_": "Invite via email",
  "_v_invite_msg_": "Invitation message email recipients will see",
  "_v_invite_add_emails_": "Add email addresses to invite",
  "_v_invite_limit_": "You can send invitations to up to 30 people at once by adding more email addresses. For adding multiple emails at once, use comma-separated emails.",
  "_v_invite_email_place_": "Enter email address",
  "_v_invite_email_add_": "Add",
  "_v_vacancy_": "Vacancy",
  "_v_back_to_list_": "Back to Listing",
  "_v_basic_detail_": "Basic Details",
  "_v_leaderboard_": "Leaderboard",
  "_v_update_vacancy_": "Update Vacancy",
  "_v_job_position_": "Job Position:",
  "_v_course_prerequisite_": "Course Prerequisite:",
  "_v_experience_": "Experience:",
  "_v_full_job_": "Full Job Detail :",
  "_v_leaderboard_share_": "Share Report",
  "_v_enter_email_": "Enter Email Addresses to Share Report File",
  "_v_invite_email_close_": "Close",
  "_v_invite_share_report_": "Share Report",
  "_v_search_student_": "Search Students",
  "_v_export_results_": "Export Results",
  "_v_share_result_": "Share Results",
  "_v_rank_": "Rank",
  "_v_name_": "Name",
  "_v_time_spent_": "Time Spent",
  "_v_quiz_score_": "Quiz Score",
  "_v_resume_": "Resume",
  "_v_note_": "Note",
  "_v_status_": "Status",
  "_v_recommended_": "Recommended",
  "_v_not_completed_": "Not Completed",
  "_v_note_": "Note",
  "_v_select_status_": "Select status",
  "_v_hired_": "Hired",
  "_v_not_contacted_": "Not Contacted",
  "_v_rejected_": "Rejected",
  "_v_no_student_": "No students found!",
  "_v_proctoring_report_": "Proctoring Report",
  "_v_pro_name_": "Name :-",
  "_v_proc_email_": "Email :-",
  "_v_proc_time_": "Time :-",
  "_v_credit_score_": "Credit Score",
  "_v_total_score_": "Total Score",
  "_v_no_attempt_": "No of attempt",
  "_v_wrong_": "Wrong",
  "_v_proc_summary_": "Summary",
  "_v_proc_copy_": "Copy",
  "_v_proc_paste_": "Paste",
  "_v_proc_newtab_": "New tab or window",
  "_v_screenshot_": "Take Screenshot",
  "_v_inactivity_": "Inactivity",
  "_v_console_": "View Console",
  "_v_escape_": "Escape",
  "_v_user_note_": "User Note",
  "_v_system_note_": "System Note",
  "_v_sys_note_place_1_": "This candidate has not completed the course.",
  "_v_sys_note_place_2_": "This candidate has completed the course in less than the average time",
  "_v_sys_note_place_3_": "This candidate has spent more than average time & having good quiz score",
  "_v_sys_note_place_4_": "This candidate has spent more than average time & having good quiz score",
  "_v_sys_note_place_5_": "This candidate has not completed the course.",
  "_v_sys_note_place_6_": "This candidate has not started the course yet.",
  "_v_delete_confirm_": "Are you sure?",
  "_v_delete_confirm2_": "Sure to delete vacancy?",
  "_v_edit_vacancy_": "Edit Vacancy",
  "_v_add_vacancy_": "Add Vacancy",
  "_v_back_listing_": "Back to Listing",
  "_v_job_position_": "Job Position",
  "_v_add_prerequisite_": "Prerequisite Course",
  "_v_add_pre_m1_": "Only courses that haven't been currently linked to any vacancy will be shown in the dropdown here.",
  "_v_select_course_": "Select course",
  "_v_add_experience_": "Experience",
  "_v_job_des_": "Job Description",
  "_v_des_plac1_": "Description for the Vacancy",
  "_v_cancel_": "Cancel",
  "_v_add_submit_": "Submit",
  "_v_add_place_experience_": "e.g. 3-4 Years",
  "_v_add_posi_": "e.g. Data Analyst",
  "_v_invite_invite_": "Invite",
  "_tr_emp_col_sr_": "Sr.",
  "_tr_learner_name_": "Learner's Name",
  "_tr_emp_col_email_": "Email",
  "_tr_emp_enroll_": "Date of Enrollment",
  "_tr_emp_com_": "Date  of Completion",
  "_tr_emp_course_status_": "Course Status",
  "_tr_cou_time_": "Time taken for Course Completion",
  "_tr_cou_score_": "Score Obtained",
  "_tr_emp_course_name_": "Course Name",
  "_tr_emp_doe_": "Date of Enrollment",
  "_tr_emp_doc_": "Date  of Completion",
  "_tr_emp_course_status_": "Course Status",
  "_tr_emp_spent_": "Spent Time",
  "_tr_emp_score_obt": "Score Obtained",
  "_np_notification_preference_": "Notification Preferences",
  "_sweetalert_process_": "Processing...",
  "_sweetalert_wait_": "Please wait...",
  "_l_ekyc2_": "eKYC",
  "_confirm_save_title_": "Changes won\'t be saved!",
  "_confirm_save_msg_": "To save the changes made, click on \'Save\'; or Click on \'Cancel\' to discard the changes.",
  "_p_profilephoto_size_": "Please upload logo with maximum 512x512 dimension.",
  "_imgcrop_cropimage_": "Crop Image",
  "_imgcrop_zoom_": "Zoom",
  "_imgcrop_border_": "Border Radius",
  "_imgcrop_rotate_": "Rotate",
  "_cancel_": "Cancel",
  "_imgcrop_crop_": "Crop",
  "_show_tour_maintitle_": "Would you like to have a quick tour ?",
  "_show_tour_msg_dashboard_": "This tour will take you through all the menu items and introduce you with the features.",
  "_show_tour_msg_emplist_": "This tour will take you through all the menu items in Employee section and introduce you with the features.",
  "_show_tour_msg_lc_": "This tour will take you through all the menu items in Learning Circle section and introduce you with the features.",
  "_show_tour_msg_notilist_": "This tour will take you through all the menu items in Notification section and introduce you with the features.",
  "_show_tour_msg_hiring_": "This tour will take you through all the menu items in Hiring report and introduce you with the features.",
  "_show_tour_msg_role_": "This tour will take you through all the menu items in Roles & Responsibility section and introduce you with the features.",
  "_show_tour_assessment_": "This tour will take you through all the menu items in Assessment section and introduce you with the features.",
  "_show_tour_course_": "This tour will take you through all the menu items in Student Dashboard section and introduce you with the features.",
  "_show_tour_corporate_": "This tour will take you through all the menu items in Corporate Dashboard section and introduce you with the features.",
  "_show_tour_vacancy_": "This tour will take you through all the menu items in Vacancy section and introduce you with the features.",
  "_show_tour_yes_": "Yes, please.",
  "_show_tour_no_": "No, thank you.",
  "_ud_l_home_": "Home",
  "_ud_l_lc_": "Learning Circles",
  "_ud_l_coulearn_": "Courses I am Learning",
  "_ud_l_courses_": "Courses",
  "_ud_l_couteach_": "Courses I am Teaching",
  "_ud_l_quiz_": "Quiz Bank",
  "_ud_l_roles_": "Roles & Responsibilities",
  "_ud_l_vac_": "Vacancies",
  "_ud_l_emp_": "Employees",
  "_ud_l_report_": "Reports",
  "_ud_l_ca_": "Career Advice",
  "_ud_l_selfassess_": "Self Assessment",
  "_ud_l_noti_": "Notifications",
  "_ud_l_chat_": "Chat",
  "_ud_l_emplist_": "Employee List",
  "_emplist_add_": "Add",
  "_emplist_addnewemp_": "Add New Employee",
  "_emplist_importcsv_": "Invite Via CSV",
  "_emplist_inviteviaemail_": "Invite Via Email",
  "_emplist_exportcsv_": "Export to CSV",
  "_emplist_print_": "Print",
  "_emplist_print_cmp_": "Company Name :-",
  "_emplist_ag_col_name_": "Full Name",
  "_emplist_ag_col_role_": "Role",
  "_emplist_ag_col_email_": "Email",
  "_emplist_ag_col_status_": "Status",
  "_emplist_ag_col_action_": "Action Buttons",
  "_emp_invite_msg_": "Email recipients will see this message",
  "_emplist_upload_": "Upload File",
  "_emplist_upload_csv_msg_": "Please upload only CSV here in proper format",
  "_emp_samplefile_": "Sample File",
  "_emplist_inviteemps_": "Invite Employees",
  "_emplist_empdetail_": "Employee Details",
  "_emplist_view_": "View",
  "_emplist_update_": "Update",
  "_emp_update_name_": "First Name",
  "_emp_update_name_place_": "e.g. Jack",
  "_emplist_lastname_": "Last Name",
  "_emplist_lastname_place": "e.g. Peter",
  "_emplist_error_fname_": "Please enter first name",
  "_emplist_lastname_error_": "Please enter last name",
  "_emplist_phone_": "Phone",
  "_emplist_phone_place_": "e.g. +919876543210",
  "_emplist_phone_vali_msg_": "Please enter minimum 8 and maximum 16 digit",
  "_emplist_update_emp_": "Employee",
  "_ekyc_vali_msg1_": "Invalid profile picture.",
  "_ekyc_vali_msg2_": "In order to start eKYC, you must upload profile photo that has your face in it.",
  "_ekyc_vali_msg3_": "Inappropriate image",
  "_ekyc_vali_msg4_": "Your image has been removed as it contains inappropriate content",
  "_ekyc_vali_msg5_": "please upload a valid image.",
  "_emplist_update_heading_": "Headline / Designation",
  "_emp_update_head_place_": "e.g. Data Analyst",
  "_emplist_update_email_place_": "e.g. jack@test.com",
  "_emplist_update_email_": "Update Employee",
  "_emp_invite_defaultmsg_": "We're delighted to invite you to EduKula, the employee training platform that we'll be using at our company! We are truly committed to helping you reach your learning and development goals through EduKula.",
  "_send_": "Send",
  "_emplist_del_con_": "This will activate",
  "_emplist_del_con2_": "employee account for login activities",
  "_emplist_del_con4_": "This will inactivate",
  "_emplist_del_con5_": "Are you sure you want to delete",
  "_emplist_del_con6_": "employee account ?",
  "_emp_invite_platformdefaultmsg_": "We're delighted to invite you to EduKula, the employee training platform that we'll be using at our company! We are truly committed to helping you reach your learning and development goals through EduKula.",
  "_emp_add_employee_": "Add Employee",
  "_emp_back_tolisting_": "Back to Listing",
  "_emplist_select_role_": "Select Role",
  "_password_": "Password",
  "_emp_pass_place_": "Enter a strong password",
  "_emp_add_employee_about_": "About",
  "_emp_add_about_des_": "Write a short introduction about this employee",
  "_course_tour_m1_": "Get Started Right",
  "_course_tour_m2_": "Before starting your learning journey, we recommend taking a quick tour of this page. It will familiarize you with the resources and features available, optimizing your experience. Don\'t miss out on this opportunity!",
  "_learn_course_tour_title1_": "Course Overview",
  "_learn_course_tour_m1_": "Here, you'll find a concise overview of the course you're about to embark on.",
  "_learn_course_tour_t2_": "Q&A Section",
  "_learn_course_tour_m2_": "Seek clarity and deepen your understanding! In this dedicated Q&A section, you have the opportunity to ask any questions related to the course. Engage with fellow learners and instructors to gain insights, resolve doubts, and enhance your learning experience.",
  "_learn_course_tour_t3_": "Notebook",
  "_learn_course_tour_m3_": "Unleash your inner curator and stay organized! Make note of any doubts or questions that arise along the way. Keep your thoughts in one place and personalize your learning experience to make it truly yours.",
  "_learn_course_tour_t4_": "Live Lessons",
  "_learn_course_tour_m4_": "Join the excitement of live sessions! In this section, you'll find information about any upcoming live sessions associated with your course",
  "_learn_course_tour_t5_": "Course Materials",
  "_learn_course_tour_m5_": "Get a quick glimpse of what lies ahead in your learning journey.",
  "_learn_course_tour_t6_": "Current Material",
  "_learn_course_tour_m6_": "Here you can easily view and engage with a range of educational materials such as videos, documents, and uploaded files from the instructor.",
  "_learn_course_tour_t7_": "Virtual Tutor",
  "_learn_course_tour_m7_": "Your virtual tutor is here to guide you every step of the way! Feel free to ask any questions related to the current course, and you'll receive immediate answers. This interactive feature ensures that you have a supportive learning environment where doubts are resolved promptly, allowing you to progress smoothly and confidently.",
  "_careeradvice_tour_t1_": "Chat display area",
  "_careeradvice_tour_m1_": "This is where the conversation between you and the AI bot for career advice will be displayed. You can view the responses and recommendations provided by the AI bot.",
  "_careeradvice_tour_t2_": "Message text box",
  "_careeradvice_tour_m2_": "Use this text box to enter information related to your career and your queries. Type your message and press enter to send it to the AI bot for processing.",
  "_careeradvice_tour_t3_": "Clear chat button",
  "_careeradvice_tour_m3_": "Click on this button to clear the chat history and start a new conversation with the AI bot.",
  "_dashboard_home_t1_": "Profile",
  "_dashboard_home_m1_": "Access your company's user dashboard, update your company's profile details, and log out using the dropdown menu in the top right corner.",
  "_dashboard_home_t2_": "Dock Menu",
  "_dashboard_home_m2_": "Expand and collapse your menu on the left-hand side of the screen using the dock menu button.",
  "_dashboard_home_t3_": "Home",
  "_dashboard_home_m3_": "Get important updates, invites, and announcements in a feed format for your company's courses and learning circles on EduKula.",
  "_dashboard_home_t4_": "Learning Circle",
  "_dashboard_home_m4_": "Create a collaborative learning environment for your employees using EduKula's learning circle. Members can share videos, images, and other materials while learning and interacting with each other. Visit the Learning Circle section to view the circles you've joined or created.",
  "_dashboard_home_t5_": "Courses",
  "_dashboard_home_m5_": "Access all the courses you've published on EduKula and drafts for the courses you're creating in the Courses section.",
  "_dashboard_home_t6_": "Vacancies",
  "_dashboard_home_m6_": "Create Vacancies on EduKula and connect them with courses you have created on EduKula. Invite and hire talent based on their performance.",
  "_dashboard_home_t7_": "Employees",
  "_dashboard_home_m7_": "Create or invite employees to the platform and manage the employee accounts added.",
  "_dashboard_home_t8_": "Reports",
  "_dashboard_home_m8_": "Access detailed reports on your employee's user activity, as well as reports on the courses you have published on EduKula.",
  "_dashboard_home_t9_": "Notifications",
  "_dashboard_home_m9_": "Stay updated on learning circles and your company's courses by checking the Notification section.",
  "_dashboard_home_t10_": "Chat",
  "_dashboard_home_m10_": "Communicate with employees and members of the Learning Circles your company has joined or created.",
  "_dashboard_home2_m1_": "Navigate to your user dashboard, update your profile details, and log out using the dropdown menu in the top right corner.",
  "_dashboard_home2_m2_": "Use the dock menu button to expand and collapse your menu on the left-hand side of the screen.",
  "_dashboard_home2_m4_": "Collaborate with like-minded learners in EduKula's learning circle. Share videos, images, and other materials while learning and interacting with each other. Visit the Learning Circle section to view the circles you've joined or created.",
  "_dashboard_home2_t5_": "Student",
  "_dashboard_home2_m5_": "Find the general and hiring courses you've enrolled in through EduKula and the certificates for the ones you've completed in the Student section.",
  "_dashboard_home2_t6_": "Tutor",
  "_dashboard_home2_m6_": "Access all the courses you've published on EduKula and drafts for the courses you're creating in the Tutor section.",
  "_dashboard_home2_m7_": "Access detailed reports on your user activity, as well as reports on the courses you are currently enrolled in or teaching.",
  "_dashboard_home2_m8_": "Grow in your career with personalized suggestions for courses, academic programs, and learning circles based on your user profile in the Career Advice section.",
  "_dashboard_home2_m9_": "Boost your confidence and evaluate your knowledge with the Self-Assessment tool, which generates quizzes on learner-selected topics using AI.",
  "_dashboard_home2_m10_": "Stay updated on learning circles and courses you're enrolled in or teaching by checking the Notification section.",
  "_dashboard_home2_m11_": "Communicate with course tutors and fellow members of the Learning Circles you have joined or created.",
  "_dashboard_home2_t11_": "Explore Courses",
  "_dashboard_home2_m12_": "This is where you can search for and discover public courses to take on EduKula. You can filter by category, level, and more to find the perfect course for you.",
  "_dashboard_home2_t13_": "Explore Learning Circles",
  "_dashboard_home2_m13_": "This is where you can discover and join learning circles on EduKula. You can search by category, rating, and more to find the right learning circle.",
  "_nav_opt1_": "Dashboard",
  "_nav_opt2_": "Courses",
  "_nav_opt3_": "Learning circle",
  "_nav_opt4_": "Start teaching",
  "_nav_opt5_": "For corporates",
  "_nav_log_in_": "Log in",
  "_nav_join_now_": "Join now",
  "_nav_user_dashboard_": "User Dashboard",
  "_nav_profile_": "Profile",
  "_nav_log_out_": "Logout",
  "_nav_company_": "Company",
  "_nav_personal_": "Personal",
  "_footer_about_us_": "About Us",
  "_footer_blogs_": "Blogs and News",
  "_footer_sitemap_": "Sitemap",
  "_footer_connect_": "Connect",
  "_footer_contact_us_": "Contact Us",
  "_footer_leader_": "Legal",
  "_footer_copyright_": "Copyright",
  "_footer_rights_": "All rights reserved.",
  "_footer_newsletter_": "Newsletter",
  "_footer_email_place_": "Your email address",
  "_footer_email_error_": "Invalid email address",
  "_footer_subscribe_": "subscribe",
  "_emplist_tour_t1_": "Add",
  "_emplist_tour_t2_": "Export to CSV",
  "_emplist_tour_t3_": "Print",
  "_emplist_tour_t4_": "Page Size",
  "_emplist_tour_m1_": "For adding employees to the platform, click on the \"Add\" button. You can either create a new employee or invite existing employees by adding their email addresses or uploading a CSV file.",
  "_emplist_tour_m2_": "To export employees list to CSV file, You can also export filtered list to CSV file.",
  "_emplist_tour_m3_": "To print employees list, You can also select no of employees per page from page size option.",
  "_emplist_tour_m4_": "To Select No of Employees per page",
  "_mycircle_tour_t1_": "Create a learning circle",
  "_mycircle_tour_t2_": "Learning circles I have created",
  "_mycircle_tour_t3_": "Learning circles I have joined",
  "_mycircle_tour_t4_": "Pending Join Invites",
  "_mycircle_tour_m1_": "Click here to start a new group. Choose a name, write a description, set the privacy level, and invite participants. Once you've created the circle, you can share educational resources and schedule meetings.",
  "_mycircle_tour_m2_": "This tab displays all the learning circles you have created. From here, you can manage them, invite members, and close them once their purpose is served.",
  "_mycircle_tour_m3_": "This tab displays all the learning circles you have joined. You can access them, interact with other members, and contribute to the shared learning materials.",
  "_mycircle_tour_m4_": "This tab displays all the join invites you have received for learning circles. You can either accept or reject these invites based on your preference.",
  "_notilist_tour_t1_": "Course Notifications",
  "_notilist_tour_m1_": "This tab shows all the notifications related to the courses you have created for employee training and hiring. You can view the details of the notifications and take necessary actions",
  "_notilist_tour_t2_": "Learning Circle Notifications",
  "_notilist_tour_m2_": "This tab shows all the notifications related to the learning circles you have joined or created. You can view the details of the notifications and take necessary actions.",
  "_notilist_tour_t3_": "Student notifications",
  "_notilist_tour_m3_": "This tab shows all the notifications related to the courses you have enrolled in for learning. You can view the details of the notifications and take necessary actions.",
  "_notilist_tour_t4_": "Tutor notifications",
  "_notilist_tour_m4_": "This tab shows all the notifications related to the courses you have created. You can view the details of the notifications and take necessary actions.",
  "_hr_report_tour_t1_": "Course",
  "_hr_report_tour_t2_": "Export",
  "_hr_report_tour_t3_": "Share",
  "_hr_report_tour_t4_": "Print",
  "_hr_report_tour_m2_": "To export students list to CSV file and download it",
  "_hr_report_tour_m3_": "To share report file to email addresses",
  "_hr_report_tour_m4_": "To print or download students list with details in pdf format",
  "_tour_back_": "Back",
  "_tour_close_": "Close",
  "_tour_last_": "Last",
  "_tour_next_": "Next",
  "_tour_open_dialog_": "Open the dialog",
  "_tour_skip_": "Skip",
  "_sa_tour_t1_": "Career Advancement",
  "_sa_tour_m1_": "By selecting this option, the system will remember the self-assessment topic and provide relevant suggestions for your career advancement in the future.",
  "_sa_tour_t2_": "Just for Practice",
  "_sa_tour_m2_": "This option can be utilized by young individuals who wish to practice and explore different topics without any specific career focus.",
  "_mycourse_tour_t1_": "Ongoing courses",
  "_mycourse_tour_m1_": "In this tab, you can find all the courses you have enrolled in that are either not started or currently in progress.",
  "_mycourse_tour_t2_": "Filter courses",
  "_mycourse_tour_m2_": "Use this feature to filter courses based on criteria such as category, course progress, and learning type. It helps you find the courses that best suit your preferences and needs.",
  "_mycourse_tour_t3_": "Completed courses",
  "_mycourse_tour_m3_": "In this tab, you will find all the certificates for the courses you have successfully completed. It serves as a record of your achievements and acquired skills.",
  "_courselist_tour_t1_": "Create course",
  "_course_tour_m1_": "Click on the \"Create Course\" button to start making a new course. Provide course details, add learning materials, and select the mode of learning. After you finish, submit the course for publishing.",
  "_courselist_tour_m1_": "Click on the \"Create Course\" button to start making a new course. Provide course details, add learning materials, and select the mode of learning. After you finish, submit the course for publishing.",
  "_courselist_tour_t2_": "Published courses",
  "_courselist_tour_m2_": "This tab displays all the courses that you have published on EduKula. You can manage them, update course details, view the enrollment, and invite employees and talent to enrol in your courses.",
  "_courselist_tour_t3_": "Drafts",
  "_courselist_tour_m3_": "This tab shows all the courses that you're currently working on but haven't published yet. You can continue editing them and submit them for publishing whenever you're ready.",
  "_courselist_tour_t4_": "Tutor Enable/Disable toggle button",
  "_courselist_tour_m4_": "Use this toggle button to enable or disable your status as a tutor. When enabled, you can create and manage courses, interact with learners, and perform tutoring activities. When disabled, your tutor functionalities will be temporarily deactivated.",
  "_vacancy_tour_t1_": "Create a new vacancy",
  "_vacancy_tour_m1_": "To create a new job vacancy, click on the \"Create a New Vacancy\" button. Fill in the necessary details such as the job title, and job description, and link it to the relevant course.",
  "_vacancy_tour_t2_": "Active/Inactive toggle switch",
  "_vacancy_tour_m2_": "Toggle the switch to activate or deactivate a vacancy. Active vacancies are displayed to potential candidates while inactive ones are not. The switch turns red to indicate that the vacancy is active",
  "_vacancy_tour_t3_": "Invite Talent",
  "_vacancy_tour_m3_": "Invite candidates to apply using the \"Invite Talent\" feature. Enter their email addresses and they will receive an email with vacancy details and a link to learn the relevant course to increase their chances of getting hired.",
  "_vacancy_tour_t4_": "Details",
  "_vacancy_tour_m4_": "Click on the \"Details\" button to view or edit the details of a particular vacancy. You can also see the leaderboard of prospects who have enrolled in the relevant course and can be hired.",
  "_vacancy_tour_t5_": "Remove",
  "_vacancy_tour_m5_": "Remove a vacancy by clicking on the \"Remove\" button. This will delete the vacancy permanently from the system.",
  "_dh_student_": "Student",
  "_dh_courses_": "Courses",
  "_dh_tutor_": "Tutor",
  "_dh_learningcircle_": "Learning Circle",
  "_dh_announcements_": "Announcements",
  "_load_more_": "Load more",
  "_no_notification_": "No notifications yet",
  "_dh_register_m1_": "Congratulations on becoming a Tutor on EduKula. Start your journey to upskill others by creating a course!",
  "_dh_create_course_": "Create a Course",
  "_dh_becometutor_": "Congratulations on successfully registering as a Tutor on EduKula",
  "_dh_coursepublished_m1_": "Congratulations! Your",
  "_dh_coursepublished_m2_": "course has been successfully published on EduKula.",
  "_dh_coursepublished_m3_": "Manage this course",
  "_dh_coursenotpublished_m2_": "course requires some fixes in order to published on the EduKula.",
  "_dh_coursenotpublished_m1_": "Your",
  "_dh_coursenotpublished_m3_": "View This Course",
  "_dh_lctab_m1_": "Circle has been successfully created on EduKula.",
  "_dh_lctab_m2_": "عرض هذه الدائرة التعليمية",
  "_dh_lctab_m3_": "Owner",
  "_dh_lctab_m4_": "Come join the",
  "_dh_lctab_m5_": "circle to further explore the concepts learned in the",
  "_trending_lc_": "Trending Learning Circles",
  "_dh_chat_head_": "Chat",
  "_dh_lctab_m6_": "You have been successfully added as a member in the",
  "_dh_lctab_m7_": "circle. Welcome to the circle!",
  "_dh_lctab_m7_": "Manage this circle",
  "_dh_lctab_m8_": "Congratulations! You have been assigned as an admin in the",
  "_dh_lctab_m9_": "Circle",
  "_dh_lctab_m10_": "Manage Circle",
  "_dh_lctab_21_": "Hey, I’d like to invite you to join the",
  "_dh_lctab_viewinvite_": "View Invitations",
  "_dh_lctab_lcactive_m1_": "Welcome back! The",
  "_dh_lctab_lcactive_m2_": "circle has been made active again.",
  "_dh_lctab_lcactive_m3_": "View this circle",
  "_dh_lctab_lcactive_m4_": "circle has been made temporarily inactive. See you soon!",
  "_dh_lctab_lcactive_m5_": "The",
  "_dh_lctab_noupdate_": "No Learning Circle Updates yet",
  "_dh_lctab_noupdate2_": "All the learning circle related updates will be shown here.",
  "_dh_lctab_lcclose_m1_": "circle has been closed. Thank you for joining us and supporting us in this journey of learning.",
  "_dh_lctab_register_": "Create a learning circle to experience a learning journey beyond the traditional structure of learning, which is driven by the cooperation and collaboration of your employees and fellow learners.",
  "_dh_announcementstab_": "No announcements yet",
  "_dh_announcementstab_m2_": "View Announcements",
  "_dh_lctab_new_": "New Release Courses",
  "_dh_newrelease_course_": "No New Courses",
  "_dh_studenttab_rgister_": "Check out our collection of high-on-demand online courses and develop the skills you need to advance your career.",
  "_dh_studenttab_rgister_m2_": "See our full course collection",
  "_dh_studenttab_rgister_m3_": "Congrats on successfully enrolling to the",
  "_dh_studenttab_rgister_m4_": "View This Course",
  "_dh_studenttab_invite_m1_": "Hey, come join my",
  "_dh_studenttab_invite_m2_": "course to enhance your career.",
  "_dh_studenttab_invite_m3_": "You can enroll using code :",
  "_dh_studenttab_complete_m1_": "Congratulations! You have successfully completed the",
  "_dh_studenttab_complete_m2_": "View Certificate",
  "_dh_studenttab_share_m1_": "Thought you might enjoy the",
  "_dh_studenttab_share_m2_": "View Course",
  "_dh_studenttab_highestrating_": "Highest Rated Courses",
  "_role_heading_": "Roles & Responsibilities",
  "_role_newrole_": "Create a New Role",
  "_role_rolename_": "Role Name:",
  "_role_responsibility_": "Responsibilities :",
  "_role_m1_": "Can manage Employees",
  "_role_m2_": "Can manage Roles & Responsibilities",
  "_role_m3_": "Can download Reports",
  "_role_m4_": "Can manage Courses",
  "_role_m5_": "Can manage Vacancies",
  "_role_m6_": "Can manage Learning Circles",
  "_edit_": "Edit",
  "_remove_": "Remove",
  "_role_norole_": "No role listed!",
  "_role_notcreated_": "You Haven't Created Any Roles Yet.",
  "_role_delete_confirm_": "All the current employees in this role will be transitioned to regular employee status? Are you sure you wish to proceed with deleting this role?",
  "_role_add_new_": "Add New Role",
  "_role_roles_": "Roles",
  "_roles_back_to_listing_": "Back to Listing",
  "_role_tittle_place_": "e.g. HR",
  "_roles_allowed_modules_": "Allowed Modules",
  "_roles_manage_emp_": "Manage Employees",
  "_roles_manage_roles_": "Manage Roles",
  "_roles_download_report_": "Download Reports",
  "_roles_manage_courses_": "Manage Courses",
  "_roles_manage_vacancies_": "Manage Vacancies",
  "_roles_manage_lc_": "Manage Learning Circles",
  "_role_edit_role_": "Edit Role",
  "_chat_typemessage_": "Type a message",
  "_chat_online_": "online",
  "_chat_offline_": "offline",
  "_chat_search_": "Search",
  "_noti_notification_": "Notification",
  "_noti_student_": "Student",
  "_noti_course_": "Course",
  "_noti_tutor_": "Tutor",
  "_noti_lc_": "Learning Circle",
  "_notilist_new_course_m1_": "Check out this brand-new course that",
  "_notilist_new_course_m2_": "published on EduKula that corresponds to your preferences.",
  "_notilist_cmaterial_add_": "A new material titled",
  "_notilist_cmaterial_add_m2_": "has been added to the",
  "_notilist_cassignment_add_": "A new assignment titled",
  "_notilist_quiz_add_": "A new quiz titled",
  "_notilist_course_": "course",
  "_notilist_material_update_m1_": "A new material titled",
  "_notilist_material_update_m2_": "in the",
  "_notilist_material_update_m3_": "course has been updated by the tutor.",
  "_notilist_assignment_update_m1_": "A new assignment titled",
  "_notilist_assignment_update_m2_": "in the",
  "_notilist_assignment_update_m3_": "course has been updated by the tutor.",
  "_notilist_quiz_update_m1_": "A new quiz titled",
  "_notilist_quiz_update_m2_": "in the",
  "_notilist_assignment_update_m3_": "course has been updated by the tutor.",
  "_notilist_cmaterial_delete_m1_": "The material titled",
  "_notilist_cmaterial_delete_m2_": "in the",
  "_notilist_cmaterial_delete_m3_": "course has been deleted",
  "_notilist_assignment_delete_m1_": "The assignment titled",
  "_notilist_assignment_delete_m2_": "in the",
  "_notilist_assignment_delete_m3_": "course has been deleted",
  "_notilist_quiz_delete_m1_": "The quiz titled",
  "_notilist_couinvite_m1_": "Hey, come join my",
  "_notilist_couinvite_m2_": "course to enhance your career",
  "_notilist_create_meeting_m1_": "The tutor has added a new session scheduled to be held on the",
  "_notilist_at_": "at",
  "_notilist_create_meeting_m2_": "for the duration of",
  "_notilist_create_meeting_m3_": "on the topic titled",
  "_notilist_meeting_delete_m1_": "The tutor has cancelled the session on the topic titled",
  "_notilist_meeting_accepted_m1_": "Session Booking Confirmed- Your session booking scheduled to be held on the",
  "_notilist_meeting_accepted_m2_": "for the duration of",
  "_notilist_meeting_accepted_m3_": "on the topic titled",
  "_notilist_meeting_accepted_m4_": "for the",
  "_notilist_meeting_accepted_m5_": "course has been confirmed",
  "_notilist_meeting_reject_m1_": "Session Booking Declined- Your session booking scheduled to be held on the",
  "_notilist_meeting_reject_m2_": "on the topic titled",
  "_notilist_meeting_reject_m3_": "course has been declined. Please select another timing suitable for you and try again",
  "_notilist_meeting_scheduled_m1_": "Meeting scheduled today at",
  "_notilist_meeting_scheduled_m2_": "on the topic titled",
  "_notilist_meeting_scheduled_tutor_m1_": "Meeting scheduled today at",
  "_noti_roq_m1_": "You have got",
  "_notilist_roq_m2_": "new responses for the query posted on the",
  "_notilist_roq_m3_": "in the Q&A section of the",
  "_notilist_courexpire_m1_": "Your",
  "_notilist_courexpire_m2_": "course expires in 30 days. Please complete the course before it expires in order to receive the certificate",
  "_notilist_vacancy_add_m1_": "Checkout this vacancy",
  "_notilist_vacancy_add_m2_": "is open related to your preference which is connected to this",
  "_notilist_cousubmit_m1_": "course has been successfully submitted for review. Your course is expected to be reviewed within 72 hours.",
  "_notilist_courpublished_m1_": "course has been reviewed and published successfully",
  "_notilist_coursenotpublished_m1_": "course has not been published as its contents seem to violate our policy. Please update the content and try again",
  "_notilist_newque_m1_": "A new question has been added in to your",
  "_notilist_otomeet_m1_": "has booked a session in the",
  "_notilist_otomeet_m2_": "course on the topic titled",
  "_notilist_otomeet_m3_": "which is scheduled to be held on the",
  "_notilist_otomeet_m4_": "Please confirm the status for this booking",
  "_notilist_meetcancel_m1_": "has cancelled the booking of the session on the topic titled",
  "_notilist_lcadd_m1_": "Check out the",
  "_notilist_lcadd_m2_": "learning circle that was introduced to build a new learning circle on EduKula based on your preferences",
  "_notilist_circle_": "circle",
  "_notilist_lcmaterial_m1_": "has been added by",
  "_notilist_to_the_": "to the",
  "_notilist_lcmaterial_update_m1_": "circle has been updated",
  "_notilist_lcmaterial_delete_m1_": "circle has been deleted",
  "_notilist_lcmeet_add_m1_": "has scheduled a new meeting on the",
  "_notilist_lcmeet_delete_m1_": "has cancelled the session on the topic titled",
  "_notilist_lcpost_add_m1_": "You have 1 new posts in the feed section of the",
  "_notilist_lcpost_add_m2_": "learning circle",
  "_notilist_lcpost_video_review_m1_": "The Video you just posted in the feed section of the",
  "_notilist_lcpost_video_review_m2_": "learning circle is under review",
  "_notilist_lcpost_video_remove_m1_": "The Video you posted in the feed section of the",
  "_notilist_lcpost_video_remove_m2_": "learning circle has been removed",
  "_notilist_lcpoll_add_m1_": "You have 1 new poll in the feed section of the",
  "_notilist_lcpost_like_m1_": "others in the",
  "_notilist_lcpost_like_m2_": "circle liked your post related to",
  "_notilist_and_": "and",
  "_notilist_new_post_comment_m1_": "circle commented on your post",
  "_mylc_heading_": "My Learning Circles",
  "_mylc_createlc_": "Create a Learning Circle",
  "_mylc_limit_swalbox_m1_": "You've reached maximum number of Learning Circle you can create. In order to create a new learning circle you'll either be required to leave or close a circle earlier created.",
  "_mylc_ok_": "OK",
  "_mylc_circle_created_": "Circles I Have Created",
  "_mylc_circle_joined_": "Circles I Have Joined",
  "_mylc_circle_invitations_": "Pending Join Invitations",
  "_mylc_created_circle_head_": "Created Circle",
  "_mylc_circle_created_main_msg_": "Start a Learning Circle of your own and learn more about a topic of your choice by networking with other professionals",
  "_mylc_created_circle_opt1_": "Edit Circle",
  "_mylc_created_circle_rating_": "Rating:",
  "_mylc_created_circle_norating_": "No Ratings",
  "_mylc_created_circle_noofpeople_": "No. of People",
  "_mylc_circle_created_nocircle_": "No circles found!",
  "_mylc_circle_created_nocircle_m1_": "You Haven't Created Any Leaning Circles Yet",
  "_mylc_trendinglc_head_": "Trending Learning Circles",
  "_mylc_lccard_": "Started",
  "_mylc_lccard_m2_": "members",
  "_mylc_lccard_rating_": "Rating",
  "_mylc_circlejoined_head_": "Joined Circle",
  "_mylc_circlejoined_msg_": "Join any professional learning group created on our learning portal to get advice and exchange ideas with your peers on specific topics",
  "_mylc_circlejoined_nojoined_": "You Haven’t Joined Any Leaning Circles Yet.",
  "_mylc_circlejoined_create_": "Created by",
  "_mylc_circle_noofpeople_": "No. of People",
  "_mylc_pending_invitation_head_": "Pending Invitation",
  "_mylc_pending_invitation_invitations_": "Invitations",
  "_mylc_pending_invitation_m1_": "has invited you to join",
  "_mylc_pending_invitation_join_": "Join",
  "_mylc_pending_invitation_ignore_": "Ignore",
  "_mylc_pending_invitation_noinvite_": "No Invitations Found!",
  "_mylc_showmore_": "Show more",
  "_lcform_add_": "Learning Circle Form",
  "_lcform_title_": "Learning Circle Title",
  "_lcform_title_place_": "Eg: Fundamentals of Sales and Marketing",
  "_lcform_title_vali_m1_": "Please enter title.",
  "_lcform_title_vali_m2_": "Circle name can not be more than 80 characters.",
  "_lcform_desc_": "Learning Circle Description",
  "_lcform_desc_place_": "Circle description",
  "_lcform_desc_vali_m1_": "Please enter description",
  "_lcform_category_": "Category",
  "_lcform_category_m1_": "Choose relevant category for the learning circle",
  "_lcform_choose_category_": "Choose Category",
  "_lcform_category_vali_m1_": "Please select category",
  "_lcform_subcategory_": "Sub Category",
  "_lcform_subcategory_m1_": "Choose relevant sub category for the learning circle.",
  "_lcform_choose_subcategory_": "Choose Sub Category",
  "_lcform_subcategory_vali_m1_": "Please select sub category.",
  "_lcform_whyjoin_": "Why Learners should join the Learning Circle?",
  "_lcform_whyjoin_m1_": "This section will help you explain how is your learning circle different from other similar learning circles and attract more members.",
  "_lcform_whyjoin_place_": "Elaborate on what the members achieve by joining your learning circle.",
  "_lcform_whyjoin_vali_m1_": "Please elaborate why user should join the circle.",
  "_lcform_coverimage_": "Upload Cover Image for the Learning Circle",
  "_lcform_imagesize_": "*Recommended Size (1266*400)",
  "_lcform_coverimage_vali_m1_": "Please upload circle image.",
  "_lcform_parent_cou_": "Choose parent course",
  "_lcform_parent_cou_m1_": "Select parent course related to the this learning circle",
  "_lcform_parent_cou_vali_m1_": "Please select parent course.",
  "_lcform_choose_privacy_": "Choose Privacy",
  "_lcform_choose_privacy_m1_": "Public Learning Circles are listed on EduKula portal and any user can join them. Private Learning circles are not listed on EduKula and only users approved or invited by the admin can join.",
  "_lcform_privacy_opt1_": "Choose privacy",
  "_lcform_privacy_opt2_": "Public",
  "_lcform_privacy_opt3_": "Private",
  "_lcform_choose_privacy_vali_m1_": "Please select whether circle is public or private.",
  "_lcform_invite_": "Select EduKula users you wish to invite to the Learning Circle",
  "_lcform_select_user_": "Select EduKula Users",
  "_lcform_select_user_vali_m1_": "Please select at least one member to invite.",
  "_lcform_emailinvite_": "Enter the email addresses of people you wish to invite to the Learning Circle",
  "_lcform_emailinvite_m1_": "Enter the email addresses (comma-separated) of the people you wish to invite to the learning circle",
  "_lcform_emailinvite_place_": "Enter email addresses(comma-separated) of the peoples you wish to invite to this learning circle.",
  "_explorelc_mainmsg_": "Learn the latest in information technology with our courses. From coding to cybersecurity, our diversified courses will enhance your skills and advance your career",
  "_explorelc_all_categories_": "View All Categories",
  "_explorelc_joinmsg_": "Join a learning circle today!",
  "_explorelc_joinmsg_place_": "What do you want to learn ?",
  "_lc_bread_related_cat_": "Related Category",
  "_lc_bread_view_all_cat_": "View All Categories",
  "_explorelc_category_": "Category",
  "_explorelc_subcategory2_": "Sub Category",
  "_explorelc_rating_": "Rating",
  "_explorelc_rate_above_": "Above",
  "_explorelc_clearfilter_": "Clear all",
  "_explorelc_learning_circle_": "Learning circles",
  "_explorelc_onedukula_": "on EduKula",
  "_explorelc_msg2_": "Broaden your understanding beyond the scope of just taking courses",
  "_explorelc_nolc_found_": "No Learning Circles Found!",
  "_explorelc_nolc_found_m2_": "No learning circles were found to match your search query",
  "_explorelc_createlc_msg_": "Be the first one to create a learning circle on this topic",
  "_explorelc_high_rated_": "High Rated Circles",
  "_lcdetail_swalbox_m1_": "Enrollment Alert",
  "_lcdetail_swalbox_m2_": "To join the circle, you must be logged in to the platform. If you do not have an account, don\'t worry! You can easily create one by clicking the \"Sign Up\" button below.",
  "_lcdetail_swalbox_m3_": "Sign In",
  "_lcdetail_swalbox_m4_": "Sign Up",
  "_lcdetail_circle_profile_": "Circle profile",
  "_lcdetail_whyjoin_": "Why should you join",
  "_lcdetail_admin_": "Admin",
  "_lcdetail_about_lc_": "About this learning circle",
  "_lcdetail_what_learn__": "What you'll learn",
  "_lcdetail_std_": "Students",
  "_lcdetail_includes_": "This learning circle includes",
  "_lcdetail_group_members_": "Group members",
  "_lcdetail_attend_meetings_": "Attend meetings",
  "_lcdetail_share_material_": "Share materials",
  "_lcdetail_conduct_dis_": "Conduct discussion",
  "_lcdetail_rec_main_msg_": "Learning circles recommended for you",
  "_lcfeed_caption_vali_m1_": "Caption can not be more than 2000 characters.",
  "_lcfeed_caption_place_": "Start a Post",
  "_lcfeed_caption_vali_m2_": "Please enter comment",
  "_lcfeed_caption_post_": "POST",
  "_lcfeed_photo_": "Photo",
  "_lcfeed_video_": "Video",
  "_lcfeed_poll_": "Poll",
  "_lcfeed_nofeed_": "No feeds posted for this circle",
  "_lcfeed_aboutcard_m1_": "About This Circle",
  "_lcfeed_aboutcard_m2_": "Public Circle",
  "_fc_title_": "For Corporate",
  "_fc_train_msg_m1_": "Train your",
  "_fc_train_msg_m2_": "future work force",
  "_fc_train_msg_m3_": "Get started",
  "_fc_train_msg_m4_": "Recruitment & training solution",
  "_fc_train_msg_m5_": "Solutions that empower organizations",
  "_fc_train_msg_m6_": "Corporate & youth connect",
  "_fc_train_msg_m7_": "Hiring-oriented courses",
  "_fc_train_msg_m8_": "Design a hiring-oriented course with ease using our e-Learning platform's sophisticated course builder, to accurately evaluate and train your potential talent.",
  "_fc_train_msg_m9_": "Vacancy management",
  "_fc_train_msg_m10_": "Create a job posting, connect it to the relevant course, and effortlessly identify potential hires based on their course completion records.",
  "_fc_train_msg_m11_": "Leaderboard",
  "_fc_train_msg_m12_": "Get an in-depth overview of the candidate's scores, course completion details, and contact information with our comprehensive leaderboard.",
  "_fc_train_msg_m13_": "Employee training",
  "_fc_train_msg_m14_": "Built-in course authoring",
  "_fc_train_msg_m15_": "Offer a seamless learning experience with a mix of classic eLearning materials like documents, videos and images, and modern eLearning materials like TINCAN & SCORM",
  "_fc_train_msg_m16_": "Evaluate and assess",
  "_fc_train_msg_m17_": "Evaluate the growth and retention of your employees' knowledge with our integrated quizzes and assignments feature.",
  "_fc_train_msg_m17_": "Peer-to-peer learning",
  "_fc_train_msg_m18_": "Foster a culture of collaborative learning and connect employees with peers, enabling them to share materials and collaborate virtually through our engaging learning circles",
  "_fc_train_msg_m19_": "User administration",
  "_fc_train_msg_m20_": "User account creation",
  "_fc_train_msg_m21_": "Effortlessly add your employees to our eLearning platform by creating individual user accounts for each of them.",
  "_fc_train_msg_m22_": "User invite- CSV/ email",
  "_fc_train_msg_m23_": "Our innovative invite feature allows you to invite multiple employees at once, either through a simple email input or a convenient CSV upload process.",
  "_fc_train_msg_m24_": "User management",
  "_fc_train_msg_m25_": "Effortlessly manage employee accounts with our intuitive user management feature, allowing you to activate, deactivate, and remove accounts with ease.",
  "_fc_train_msg_m26_": "Smart reporting",
  "_fc_train_msg_m27_": "Custom reports",
  "_fc_train_msg_m28_": "Allows for the generation of custom reports on user metrics, course reports, and learning circle reports for selected or all users, across all or specific learning circles and courses.",
  "_fc_train_msg_m29_": "Intuitive graphs",
  "_fc_train_msg_m30_": "Presenting user data in a clear and easy-to-understand way, allowing the admin to analyze and make informed decisions to improve the learning experience.",
  "_fc_train_msg_m31_": "CSV export",
  "_fc_train_msg_m32_": "Allows users to export data in CSV format with ease, enabling them to process and analyze the data as needed",
  "_fc_train_msg_m33_": "Register for a corporate account",
  "_fc_train_msg_m34_": "Register",
  "_managelc_swalbox_m1_": "Sure to",
  "_managelc_swalbox_m2_": "leave",
  "_managelc_currernt_rating_": "Current Rating",
  "_managelc_not_sure_": "Not Sure",
  "_managelc_back_to_dashboard_": "Back to Dashboard",
  "_managelc_deactivate_": "Deactivate",
  "_managelc_activate_": "Activate",
  "_managelc_invite_": "Invite",
  "_managelc_invite_emp_": "Invite Employees",
  "_managelc_invite_user_": "Invite EduKula Users",
  "_managelc_invite_via_email_": "Invite via email",
  "_managelc_invite_via_link_": "Invite with link",
  "_managelc_view_profile_": "View Profile",
  "_managelc_group_feed_": "Group Feed",
  "_managelc_meetings_": "Meetings",
  "_managelc_materials_": "Materials",
  "_managelc_members_": "Members",
  "_managelc_more_": "More",
  "_managelc_view_parent_cou_": "View Parent Course",
  "_managelc_join-request_": "Manage Join Requests",
  "_managelc_sent_invite_": "Sent Invitations",
  "_managelc_close_circle_": "Close Circle",
  "_managelc_leave_circle_": "Leave Circle",
  "_managelc_report_circle_": "Report Circle",
  "_managelc_rate_circle_": "Rate this Circle",
  "_managelc_rate_msg_": "How would you rate this circle?",
  "_managelc_select_rating_": "Select Rating",
  "_managelc_rate_tellus_": "Please tell us more (optional)",
  "_managelc_circle_inactive_": "This circle is currently inactive!",
  "_managelc_select_all_": "Select All",
  "_managelc_nouser_found_": "No user found",
  "_managelc_send_invitations_": "Send Invitations",
  "_managelc_email_invitation_msg_": "Invitation message email recipients will see",
  "_managelc_invite_email_vali_m1_": "Please enter email address",
  "_managelc_copy_link_": "Copy Link",
  "_managelc_delete_swal_m1_": "Sure to delete post?",
  "_managelc_remove_poll_": "Remove Vote",
  "_managelc_left_": "Left",
  "_managelc_poll_vote_": "Vote",
  "_managelc_votes_": "Votes",
  "_managelc_delete_": "Delete",
  "_managelc_see_more_": "See more",
  "_managelc_see_less_": "See less",
  "_managelc_comments_": "Comments",
  "_managelc_comment_": "Comment",
  "_managelc_about_card_m1_": "Any EduKula User can join this circle",
  "_managelc_about_card_m2_": "Visible",
  "_managelc_about_card_m3_": "Anyone can find this circle",
  "_managelc_about_card_m4_": "History",
  "_managelc_about_card_m5_": "Circle created on",
  "_managelc_create_post_": "Create Post",
  "_managelc_postphoto_caption_vali_m1_": "Caption can not be more than 1000 charaters.",
  "_managelc_postphoto_caption_place_": "What's on your mind",
  "_managelc_postphoto_photo_vali_": "Please select a photo for the post",
  "_managelc_video_notsupported_": "Sorry, your browser doesn't support embedded videos.",
  "_managelc_postvideo_vali_m1_": "Please select a video for the post",
  "_managelc_postpoll_create_poll_": "Create a poll",
  "_managelc_postpoll_poll_caption_": "Poll caption",
  "_managelc_postpoll_poll_caption_place_": "E.g., What this poll is about?",
  "_managelc_postpoll_question_": "Your Question",
  "_managelc_postpoll_question_opt_": "Option",
  "_managelc_postpoll_add_opt_": "Add option",
  "_managelc_postpoll_poll_duration_": "Poll duration",
  "_managelc_postpoll_poll_select_day_": "Select Day",
  "_managelc_postpoll_poll_ins_": "We don't approve of requests for political opinions, medical information or other sensitive data.",
  "_managelc_postpoll_post_btn_": "Post",
  "_managelc_loading_": "Loading....",
  "_managelc_feedcomments_loadmore_": "Load more comments",
  "_managelc_feedcomments_of_": "of",
  "_managelc_feedcomments_comments_": "comments",
  "_managelc_comment_delete_verify_": "Sure to delete comment?",
  "_managelc_reply_btn_": "Reply",
  "_managelc_load_more_reply_": "Load more replies",
  "_managelc_replies_": "replies",
  "_managelc_like_": "Like",
  "_managelc_liked_": "Liked",
  "_managelc_write_a_public_comment_": "Write a public comment...",
  "_managelc_invite_model_search_": "Search for user by name",
  "_managelc_email_invitation_msg_place_": "This message will be sent to the invited users",
  "_managelc_email_invitation_msg_defaultvalue_": "Hello, I am thrilled to invite you to join my learning circle on EduKula. Let's connect on the circle and learn together!",
  "_lcmeeting_delete_swal_m1_": "Sure to delete meeting?",
  "_lcmeeting_meeting_list_": "Meetings List",
  "_lcmeeting_add_meeting_": "Add Meeting",
  "_lcmeeting_meeting_opt1_": "Start Time",
  "_lcmeeting_meeting_opt2_": "Topic",
  "_lcmeeting_meeting_opt3_": "Duration",
  "_lcmeeting_meeting_opt4_": "Status",
  "_lcmeeting_meeting_opt5_": "Action",
  "_lcmeeting_meeting_no_meeting_created_": "No meetings are created",
  "_lcmeeting_meetbtn_start_": "START",
  "_lcmeeting_meetbtn_join_": "JOIN",
  "_lcmeeting_meetbtn_search_": "Search meetings",
  "_lcmeeting_addmeet_timezone_": "Select Timezone",
  "_lcmeeting_addmeet_timezone_vali_m1_": "Please select timezone.",
  "_lcmeeting_addmeet_topic_": "Meeting Topic*",
  "_lcmeeting_addmeet_title_vali_m1_": "Title can not be more than 80 characters.",
  "_lcmeeting_addmeet_timezone_vali_m2_": "Please enter meeting topic.",
  "_lcmeeting_addmeet_agenda_": "Short Agenda*",
  "_lcmeeting_addmeet_agenda_vali_m1_": "Please enter meeting agenda.",
  "_lcmeeting_addmeet_date_": "Date*",
  "_lcmeeting_addmeet_date_vali_m1_": "Please select meeting date.",
  "_lcmeeting_addmeet_time_": "Time*",
  "_lcmeeting_addmeet_time_vali_m1_": "Please select meeting time.",
  "_lcmeeting_addmeet_duration_": "Duration(Minutes)*",
  "_lcmeeting_addmeet_duration_vali_m1_": "Please enter meeting duration in minutes.",
  "_lcmeeting_addmeet_duration_vali_m2_": "Duration must be at least 10 minutes.",
  "_lcmeeting_addmeet_duration_vali_m3_": "Duration can not be more than 200 minutes.",
  "_general_profile_com_name_place_": "Company Name",
  "_general_profile_com_name_vali_m1_": "Company Name can not be more than 80 characters.",
  "_general_profile_com_name_vali_m2_": "Please enter at least 3 characters.",
  "_general_profile_com_name_vali_m3_": "Please enter company name",
  "_general_profile_com_name_vali_m4_": "Please add first name appropriately.",
  "_general_profile_com_name_vali_m5_": "First Name can not be more than 80 characters.",
  "_general_profile_first_name_place_": "First Name",
  "_general_profile_last_name_vali_m1_": "Please add last name appropriately",
  "_general_profile_last_name_vali_m2_": "Last Name can not be more than 80 characters.",
  "_general_profile_last_name_vali_m3_": "Please enter at least 3 characters.",
  "_general_profile_last_name_place_": "Last Name",
  "_general_profile_phone_vali_m1_": "Please enter minimum 8 and maximum 16 digit",
  "_general_profile_phone_vali_m2_": "Please enter phone number",
  "_general_profile_heading_vali_m1_": "Headline can not be more than 80 characters.",
  "_general_profile_heading_vali_m2_": "Please enter headline",
  "_general_profile_username_vali_m1_": "Please enter valid facebook username",
  "_general_profile_username_vali_m2_": "Please enter valid facebook username",
  "_general_profile_twitter_vali_m1_": "Please enter valid twitter username",
  "_general_profile_twitter_vali_m2_": "twitter username cannot be longer than 15 characters.",
  "_general_profile_twitter_vali_m3_": "twitter username cannot be shorter than 4 characters",
  "_general_profile_linkedin_vali_m1_": "Please enter valid linkedin username",
  "_general_profile_website_vali_m1_": "Please enter valid company URL.(Ex: www.example.com)",
  "_general_profile_website_vali_m2_": "Please enter at least 3 characters",
  "_general_profile_website_vali_m3_": "Company website can not be more than 80 characters.",
  "_general_profile_comwebsite_place_": "Company website",
  "_coursedetail_title_": "Course Category",
  "_coursedetail_title2_": "Courses",
  "_coursedetail_search_for_courses_": "Search for courses",
  "_coursedetail_search_place_": "What do you want to learn ?",
  "_coursedetail_sort_level_": "Level",
  "_coursedetail_sort_course_type_": "Course Type",
  "_coursedetail_sort_learning_type_": "Learning type",
  "_coursedetail_msg_results_": "Results",
  "_coursedetail_main_msg_2_": "Learn courses to upskill yourself and boost your job prospects",
  "_coursedetail_sort_course_highrate_": "Highest Rated",
  "_coursedetail_sort_course_selling_": "Best Selling",
  "_coursedetail_sort_course_trending_": "Trending",
  "_coursedetail_sort_course_hiring_": "Hiring",
  "_custom_pagi_previous_": "Previous",
  "_custom_pagi_next_": "Next",
  "_coursedetail_nocourse_m1_": "No courses found!",
  "_coursedetail_nocourse_m2_": "Explore our course catalogue to enrol on a course and start your learning journey.",
  "_coursedetail_alert_t1_": "Enrollment Alert",
  "_coursedetail_alert_m1_": "To enroll in this course, you must be logged in to the platform. If you do not have an account, do not worry! You can easily create one by clicking the Sign Up button below.",
  "_coursedetail_enroll_course_error_": "Please enter your unique code to enroll this course.",
  "_coursedetail_alert_t2_": "Course Enrollment Confirmation",
  "_coursedetail_alert_m2_": "Congratulations! You have successfully enrolled in the course. It is time to begin your learning journey. You will be redirected to the course page in a moment.",
  "_coursedetail_msg_mode_of_learning_": "Learn at your own pace",
  "_coursedetail_msg_session_": "Personalised Sessions",
  "_coursedetail_msg_onetomany_": "Group Sessions",
  "_coursedetail_msg_free_": "Free",
  "_coursedetail_msg_startlearning_": "Start learning right away",
  "_coursedetail_enroll_now_": "Enroll Now",
  "_coursedetail_gotocourse_": "Go to Course",
  "_coursedetail_instructors_": "Instructors",
  "_coursedetail_prerequisites_": "Prerequisites",
  "_coursedetail_cour_include_": "This course includes",
  "_coursedetail_cour_enrolled_": "Enrolled",
  "_coursedetail_cour_certificate_": "Certificate of completion",
  "_coursedetail_report_course_": "Report this course",
  "_coursedetail_cour_nofeedback_m1_": "No Feedback given till now.",
  "_coursedetail_cour_related_": "Related",
  "_coursedetail_cour_course_m1_": "course",
  "_coursedetail_more_course_": "More Courses by",
  "_coursedetail_about_tab_1_": "About this course",
  "_coursedetail_cour_subcategory_": "Subcategory",
  "_coursedetail_nooutcome_": "No Outcomes",
  "_coursedetail_prerequisites_tab_1_": "No Prerequisites",
  "_course_category_main_msg_m2_": "Learn the latest in information technology with our courses. From coding to cybersecurity, our diversified courses will enhance your skills and advance your career.",
  "_learncourse_nocour_m1_": "Important!",
  "_learncourse_nocour_m2_": "Either this course is removed or not available.",
  "_learncourse_back_btn_": "Back to Student Dashboard",
  "_learncourse_course_progress_": "View Course Progress",
  "_learncourse_course_com_m2_": "Please complete this course within",
  "_learncourse_course_com_m3_": "days, because this course is no longer available.",
  "_learncourse_virtualtutor_m1_": "I am your Virtual Tutor",
  "_learncourse_virtualtutor_m2_": "Your can ask your queries...",
  "_learncourse_virtualtutor_m3_": "Ask me anything!",
  "_learncourse_course_com_m4_": "This will complete the course and you won't receive any future updates or attend future sessions.",
  "_learncourse_slidelist_m1_": "Section",
  "_learncourse_slidelist_m2_": "Finish Course",
  "_learncourse_slidelist_feedback_m1_": "How was your experience ?",
  "_learncourse_slidelist_feedback_m2_": "Rate us",
  "_learncourse_slidelist_feedback_m3_": "Please Share your experience here...",
  "_learncourse_detail_overview_": "Overview",
  "_learncourse_detail_qa_": "Q&A",
  "_learncourse_detail_livesessions_": "Live Sessions",
  "_learncourse_ratecour_m1_": "Rate this Course",
  "_learncourse_ratecour_m2_": "How would you rate this Course ?",
  "_learncourse_ratecour_m3_": "Please tell us more (optional).",
  "_learncourse_qa_place_": "Write your query...",
  "_learncourse_qa_noqa_": "No Queries Raised !",
  "_learncourse_qa_delete_con_": "Sure to delete query?",
  "_learncourse_notebook_note_vali_m1_": "Notes can not be greater than 1000 characters",
  "_learncourse_notebook_note_vali_m2_": "Create a new note",
  "_learncourse_notebook_note_opt1_": "All lectures",
  "_learncourse_notebook_note_opt2_": "Current lecture",
  "_learncourse_notebook_note_opt3_": "Sort by most recent",
  "_learncourse_notebook_note_opt4_": "Sort by oldest",
  "_learncourse_notebook_note_opt5_": "Remaining",
  "_learncourse_notebook_nonote_": "No Notes Found.",
  "_learncourse_notebook_sweetalert_m2_": "Sure to delete note?",
  "_learncourse_notebook_edit_note_": "Edit note",
  "_learncourse_meeting_msg_m1_": "Book a Session",
  "_learncourse_meeting_msg_m2_": "Select Timezone",
  "_learncourse_meeting_timezone_vali_m1_": "Please select timezone.",
  "_learncourse_meeting_requested_ses_": "Requested Sessions",
  "_learncourse_meeting_requested_no_ses_": "No Sessions Requested.",
  "_learncourse_meeting_no_sess_": "No Sessions Found",
  "_learncourse_suggestvideo_filtervideo_": "Filter Videos",
  "_learncourse_suggestvideo_lan_": "Language",
  "_lan_eng_": "English",
  "_lan_hindi_": "Hindi",
  "_lan_french_": "French",
  "_lan_japan_": "Japanese",
  "_lan_span_": "Spanish",
  "_lan_arabic_": "Arabic",
  "_lan_russian_": "Russian",
  "_lan_portu_": "Portuguese",
  "_courseilearn_student_dash_": "Student Dashboard",
  "_courseilearn_no_cour_": "No courses enrolled",
  "_courseilearn_cou_progress_": "Course Progress",
  "_courseilearn_card_alert_m1_": "Complete Your Course - Do not Miss Out!",
  "_courseilearn_card_alert_m2_": "It looks like you have not finished this course yet. Before you leave, remember that you will have to start over if you want to rejoin later. Are you sure you want to leave the course?",
  "_courseilearn_card_opt1_": "Leave Course",
  "_courseilearn_card_opt2_": "Learning",
  "_courseilearn_card_opt3_": "Certificate",
  "_courseilearn_card_no_cour_": "No ongoing courses",
  "_courseilearn_completed_cou_": "No completed courses",
  "_courcerti_m1_": "Course Certificate",
  "_courcerti_m2_": "This certificate above verifies that",
  "_courcerti_m3_": "successfully completed the course",
  "_courcerti_m4_": "on",
  "_courcerti_m5_": "as taught by",
  "_courcerti_m6_": "on EduKula. The certificate indicates the entire course was completed as validated by the student. The course duration represents the total video hours of the course at time of most recent completion",
  "_courcerti_m7_": "About the Course",
  "_courcerti_m8_": "Completed",
  "_courcerti_m9_": "Certificate Recipient",
  "_courcerti_m10_": "Share Via",
  "_courcerti_m11_": "Download",
  "_courcerti_m12_": "JPEG",
  "_courcerti_m13_": "PDF",
  "_courselist_alert_m1_": "Generate a course using AI",
  "_courselist_alert_m2_": "Would you like to create this course with AI help?",
  "_courselist_alert_m3_": "Note:- you are creating course as a your company representative and all associated rights are the sole property of your company.",
  "_courselist_alert2_m1_": "This will enable your tutor services.",
  "_courselist_alert2_m2_": "This will disable your Tutor services. All the courses with no active learning students enrolled will be unpublished and sent to drafts immediately. Courses having active learning students will be unpublished in 30 days.",
  "_courselist_corpo_dash_": "Corporate Dashboard",
  "_courselist_tutor_dash_": "Tutor Dashboard",
  "_courselist_tutor_disable_": "Tutor Disable",
  "_courselist_tutor_enable_": "Enable",
  "_courselist_create_cou_btn_": "Create Course",
  "_courselist_tutor_service_dis_": "Tutor service is disabled!",
  "_courselist_cour_descript_": "Brief Course Description",
  "_courselist_cour_descript_vali_m1_": "Brief Course Description can not be more than 300 characters",
  "_courselist_cour_descript_place_": "Enter brief course description i.e. Basics of Photoshop and Graphic Designing, Digital Marketing Strategies",
  "_courselist_cour_descript_vali_m2_": "Brief Course Description.",
  "_courselist_cour_learner_level_": "Learner's Level",
  "_courselist_cour_learner_level_opt1_": "Select Learner's Level",
  "_courselist_cour_learner_level_opt2_": "Introductory",
  "_courselist_cour_learner_level_opt3_": "Intermediate",
  "_courselist_cour_learner_level_opt4_": "Expert",
  "_courselist_cour_learner_level_vali_m1_": "Please select the section.",
  "_courselist_cour_duration_": "Course Duration",
  "_courselist_cour_duration_opt1_": "Select Course Duration",
  "_courselist_cour_duration_opt2_": "0-1 Month",
  "_courselist_cour_duration_opt3_": "1-3 Months",
  "_courselist_cour_duration_opt4_": "3-6 Months",
  "_courselist_cour_duration_opt5_": "6-9 Months",
  "_courselist_cour_duration_opt6_": "9-12 Months",
  "_courselist_cour_apply_": "Apply",
  "_couseiteach_publish_title_": "Published Courses",
  "_couseiteach_no_publish_": "No courses published",
  "_couseiteach_del_alert_m1_": "Sure to delete course?",
  "_couseiteach_del_alert_m2_": "Sure to unpublish?",
  "_couseiteach_del_alert_m3_": "Sure to change privacy?",
  "_couseiteach_card_opt1_": "Unpublish Course",
  "_couseiteach_card_opt2_": "Make it",
  "_couseiteach_draft_alert_m1_": "Sure to delete draft course?",
  "_couseiteach_draft_title_": "Draft Course",
  "_couseiteach_draft_edit_pro_": "Edit Profile",
  "_couseiteach_draft_msg_m1_": "In order to submit a course for review, your company general profile must be completed with the company introduction.",
  "_couseiteach_draft_msg_m2_": "In order to submit a course for review, your profile must be completed with your name, self-introduction, a photo, and tutor preferences",
  "_couseiteach_draft_delete_btn_": "Delete Draft",
  "_couseiteach_draft_submit_review_": "Submit for Review",
  "_couseiteach_draft_no_cour_draft_": "No courses in draft",
  "_couseiteach_draft_main_msg_m1_": "Your course titled",
  "_couseiteach_draft_main_msg_m2_": "has been submitted for review and will be live in 72 hours.",
  "_couseiteach_draft_main_msg_m3_": "What would you like to do with this course next?",
  "_couseiteach_draft_main_msg_m4_": "Create vacancy & hire talent",
  "_couseiteach_draft_main_msg_m5_": "Invite & Train employees",
  "_couseiteach_draft_main_msg_m6_": "Skip for now",
  "_createcourse_step1_m1_": "Remark",
  "_createcourse_step1_m2_": "Basic Course Details",
  "_createcourse_step1_m3_": "Course Title",
  "_createcourse_step1_m4_": "It's ok if you can not think of a good title now, you can change it from your drafts later before submitting for review.",
  "_createcourse_step1_m5_": "Course name can not be more than 80 characters",
  "_createcourse_step1_m6_": "e.g. Learn Photoshop CS6 from Scratch",
  "_createcourse_step1_m7_": "Please enter title.",
  "_createcourse_step1_m8_": "If you're not sure about the right category, you can change it from your drafts later before submitting for review.",
  "_createcourse_step1_m9_": "Choose a category",
  "_createcourse_step1_m10_": "If you're not sure about the right sub category, you can change it from your drafts later before submitting for review.",
  "_createcourse_step1_m11_": "Course Objective",
  "_createcourse_step1_m12_": "What is the main objective that the student will achieve through learning this course.",
  "_createcourse_step1_m13_": "Objective can not be more than 500 characters.",
  "_createcourse_step1_m14_": "e.g. Students will learn the basic tools used in Photoshop to create and edit images.",
  "_createcourse_step1_m15_": "Please enter objective.",
  "_createcourse_step1_m16_": "Study Level",
  "_createcourse_step1_m17_": "Choose the level of expertise that the student will achieve by completing this course.",
  "_createcourse_step1_m18_": "Choose study level",
  "_createcourse_step1_m19_": "Please select level.",
  "_createcourse_step1_m20_": "Upload Course Image",
  "_createcourse_step1_m21_": "Please select course photo.",
  "_createcourse_step1_m22_": "Course Image",
  "_createcourse_step1_m23_": "Course Language",
  "_createcourse_step1_m24_": "Choose the primary language in which the course is taught.",
  "_createcourse_step1_m25_": "Please select language.",
  "_createcourse_step1_m26_": "Continue",
  "_createcourse_step1_m27_": "Save and Exit",
  "_createcourse_step2_m1_": "Describe the Course in Detail",
  "_createcourse_step2_m2_": "Public Courses are listed on EduKula portal and any user can enrol to it. Private courses are not listed on EduKula and only students invited can enrol to the course.",
  "_createcourse_step2_m3_": "Choose privacy",
  "_createcourse_step2_m4_": "Please select privacy.",
  "_createcourse_step2_m5_": "Upload Introductory Video",
  "_createcourse_step2_m6_": "Uploading an introductory video that gives a glimpse of the course will increase the chances of a student to enrol in your course.",
  "_createcourse_step2_m7_": "Course Video",
  "_createcourse_step2_m8_": "OR",
  "_createcourse_step2_m9_": "Add Youtube Video Link",
  "_createcourse_step2_m10_": "e.g. https://youtu.be/QUJ3OJZqe84",
  "_createcourse_step2_m11_": "Course description",
  "_createcourse_step2_m12_": "Insert your course description.",
  "_createcourse_step2_m13_": "Please enter description.",
  "_createcourse_step2_pre_m1_": "List Requirements or prerequisites for taking your course",
  "_createcourse_step2_pre_m2_": "If there are no requirements, use this space to highlight how the course is accessible to all.",
  "_createcourse_step2_pre_m3_": "List down the required tools, skills, and level of experience the learners should have prior to taking your course.",
  "_createcourse_step2_pre_m4_": "Example: No programming experience needed. You will learn everything you need to know",
  "_createcourse_step2_pre_m5_": "Example: No programming experience needed. You will learn everything you need to know",
  "_createcourse_step2_pre_m6_": "Add More To Your Response",
  "_createcourse_step2_out_m1_": "What will students learn in your course?",
  "_createcourse_step2_out_m2_": "You must enter at least 3 key concepts or skills that learners will come in possession of after completing your course.",
  "_createcourse_step2_out_m3_": "Example: Fundamentals of graphic design and image editing.",
  "_createcourse_step2_out_m4_": "Example: Fundamentals of graphic design and image editing.",
  "_createcourse_step2_out_m5_": "Example: How to navigate the Photoshop interface.",
  "_createcourse_step2_out_m6_": "Example: Techniques for selecting and manipulating images.",
  "_createcourse_step2_out_m7_": "Example: Fundamentals of graphic design and image editing.",
  "_createcourse_step2_input_m1_": "Sure to delete key point?",
  "_createcourse_step4_m1_": "Mode of Learning",
  "_createcourse_step4_m2_": "Self-Paced",
  "_createcourse_step4_m3_": "Students can study the course alone without the interference of a Tutor.",
  "_createcourse_step4_m4_": "Instructor Led",
  "_createcourse_step4_m5_": "Course",
  "_createcourse_step4_m6_": "Instructor can teach individuals or group of students through online sessions.",
  "_createcourse_step4_m7_": "One-to-One",
  "_createcourse_step4_m8_": "Tutoring",
  "_createcourse_step4_m9_": "Tutor can conduct personalised online sessions as per a student’s demand.",
  "_createcourse_step4_m10_": "One-to-Many",
  "_createcourse_step4_m11_": "Tutor can schedule online sessions as per his discretion for a group of students.",
  "_createcourse_step5_m1_": "Your course will be submitted for the review. Once the course is review and approved then after your course will be published on the platform.",
  "_createcourse_step3_m1_": "Reorder",
  "_createcourse_step3_m2_": "View Slide",
  "_createcourse_step3_m3_": "Enter a Title",
  "_createcourse_step3_m4_": "Please enter section title",
  "_createcourse_step3_m5_": "Save Section",
  "_createcourse_step3_m6_": "Slide",
  "_createcourse_step3_m7_": "Select Section",
  "_createcourse_step3_m8_": "Slide Title",
  "_createcourse_step3_m9_": "Please enter slide title",
  "_createcourse_step3_m10_": "Describe the Slide in detail",
  "_createcourse_step3_m11_": "Slide description",
  "_createcourse_step3_m12_": "Please enter short description",
  "_createcourse_step3_m13_": "The use of bullet point symbols in textual content can negatively impact the quality of the video when converting text into a video.",
  "_createcourse_step3_m14_": "When using this text-to-voice feature, please use the colon symbol : at the end of a title to signify a pause in the audio, allowing for a natural and clear speech rhythm.",
  "_createcourse_step3_m15_": "Generate Video",
  "_createcourse_step3_m16_": "Generate Video and transcript",
  "_createcourse_step3_m17_": "Save Slide",
  "_createcourse_step3_m18_": "Material",
  "_createcourse_step3_m19_": "File Title",
  "_createcourse_step3_m20_": "Please enter material title",
  "_createcourse_step3_m21_": "Selected File",
  "_createcourse_step3_m22_": "You can upload PDF, Word, PPT, Video, Image, SCORM and TINCAN",
  "_createcourse_step3_m23_": "Generate transcript",
  "_createcourse_step3_m24_": "Upload transcript",
  "_createcourse_step3_m25_": "Current file",
  "_createcourse_step3_m26_": "You can upload transcript file",
  "_createcourse_step3_m27_": "Please select materials file.",
  "_createcourse_step3_m28_": "Save Material",
  "_createcourse_step3_m29_": "Edit Assignment",
  "_createcourse_step3_m30_": "Add Assignment",
  "_createcourse_step3_m31_": "Assignment Title",
  "_createcourse_step3_m32_": "Please enter assignment title.",
  "_createcourse_step3_m33_": "Total marks of the assignment",
  "_createcourse_step3_m34_": "Enter Total marks of the assignment",
  "_createcourse_step3_m35_": "Please Total marks of the assignment",
  "_createcourse_step3_m36_": "Do You need AI assistance in grading the assignment ?",
  "_createcourse_step3_m37_": "Please select any option",
  "_createcourse_step3_m38_": "Please write down your assignment grading criteria",
  "_createcourse_step3_m39_": "Explain your assignment grading criteria in brief.",
  "_createcourse_step3_m40_": "Description for the Assignment",
  "_createcourse_step3_m41_": "Please enter assignment description.",
  "_createcourse_step3_m42_": "Save Assignment",
  "_createcourse_step3_m43_": "Generate Assignment",
  "_createcourse_step3_m44_": "Rough brief about the assignment",
  "_createcourse_step3_m45_": "Enter your assignment description here. For example: Create an assignment centered around [Topic/Area], outlining the required [Task/Requirement]. Your description should be clear and comprehensive, detailing the goals and objectives, along with any specific instructions or limitations.",
  "_createcourse_step3_m46_": "Format of Submission",
  "_createcourse_step3_m47_": "Written Document (DOC, DOCX, PDF, TXT, etc.)",
  "_createcourse_step3_m48_": "Presentation (PPT or PPTX)",
  "_createcourse_step3_m49_": "Video (MP4, AVI, MKV, etc.)",
  "_createcourse_step3_m50_": "Audio (MP3, WAV, AAC, etc.)",
  "_createcourse_step3_m51_": "Image (JPEG, PNG, GIF, BMP, etc.)",
  "_createcourse_step3_m52_": "Spreadsheets Format (XLS, XLSX, CSV, etc.)",
  "_createcourse_step3_m53_": "Edit Youtube Video",
  "_createcourse_step3_m54_": "Add Youtube Video",
  "_general_profile_comwebsite_vali_m1_": "Please enter Company website.",
  "_general_profile_upload_file_": "Uploaded File",
  "_general_profile_about_me_": "Introduce your company and share about its professional journey and achievements here.",
  "_general_profile_about_me_vali_m1_": "Please enter about description",
  "_profile_picture_update_w_m1_": "Profile Picture Update Warning",
  "_profile_picture_update_w_m2_": "Important Notice: \nYour new profile picture does not match the submitted KYC document, if you still update your profile picture, you will have to go through the KYC process again. \nProceed with caution. \nUpdate Profile Picture?",
  "_profile_picture_update_w_m3_": "I understand. Update Profile Picture.",
  "_e_education_vali_m1_": "Please enter school name",
  "_e_education_vali_m2_": "Please enter at least 3 characters.",
  "_e_education_vali_m3_": "School name can not be more than 80 characters.",
  "_e_education_vali_m4_": "Please Enter degree",
  "_e_education_vali_m5_": "Please enter field of study",
  "_e_education_vali_m6_": "field of study can not be more than 80 characters.",
  "_e_education_vali_m7_": "Please enter grade in the following pattern : A+, B",
  "_e_education_vali_m8_": "Must match the following pattern : A+, B",
  "_e_education_vali_m9_": "Please select start date",
  "_e_education_vali_m10_": "Please select end date",
  "_lp_learning_preferences_profe_vali_m1_": "Please select your profession.",
  "_lp_learning_preferences_profe_vali_m2_": "Invalid value",
  "_lp_learning_preferences_profe_vali_m3_": "Please enter industry.",
  "_lp_learning_preferences_profe_vali_m4_": "Please enter area of",
  "_lp_learning_preferences_profe_vali_m5_": "Enter learning preferences",
  "_lp_learning_preferences_profe_vali_m6_": "Enter learning preferences.",
  "_lp_learning_preferences_profe_vali_m7_": "Please select alteast one language",
  "_lp_learning_preferences_profe_vali_m8_": "Please select any one preference.",
  "_lp_learning_preferences_profe_vali_m9_": "Connections from Course & Learning Circle",
  "_as_pass_vali_m1_": "Please enter password.",
  "_as_pass_vali_m2_": "Yes, delete my account.",
  "_as_pass_vali_m3_": "Are you sure you want to delete your account?",
  "_as_pass_vali_m4_": "Please enter email.",
  "_as_pass_vali_m5_": "Password must contains at least a letter, number and a special character.",
  "_as_pass_vali_m6_": "Password can not be less than 8 characters.",
  "_as_pass_vali_m7_": "Password can not be more than 50 characters.",
  "_as_pass_vali_m8_": "Confirm password can not be less than 8 characters.",
  "_as_pass_vali_m9_": "Confirm password can not be more than 50 characters.",
  "_as_pass_vali_m10_": "Confirm password do not match.",
  "_as_pass_vali_m11_": "Please enter confirm password.",
  "_as_pass_vali_m12_": "Password must contain:",
  "_as_pass_vali_m13_": "8 or more characters minimum",
  "_as_pass_vali_m14_": "One or more than one characters with numbers",
  "_as_pass_vali_m15_": "One or more lowercase characters",
  "_as_pass_vali_m16_": "One or more capital characters",
  "_as_pass_vali_m17_": "One or more special characters",
  "_as_pass_vali_m18_": "Match password with confirm password",
  "_as_pass_vali_m19_": "Password must follow the above rules.",
  "_as_pass_vali_m20_": "Enter your current password",
  "_as_pass_vali_m21_": "Please enter current password.",
  "_as_pass_vali_m22_": "Verify",
  "_mate_listslide_m1_": "Convert to Video",
  "_mate_listslide_m2_": "View and Edit",
  "_mate_listslide_m3_": "This slide will be converted into a video file. Are you sure you want to convert this slide into a video file?",
  "_mate_listslide_m4_": "This feature is powered by AI, and the output is not always 100% accurate. Please double-check the output generated before utilizing it.",
  "_mate_popquiz_m1_": "Edit Quiz",
  "_mate_popquiz_m2_": "Add Quiz",
  "_mate_popquiz_m3_": "Quiz Title",
  "_mate_popquiz_m4_": "Please enter quiz title.",
  "_mate_popquiz_m5_": "Description can not be more than 500 characters.",
  "_mate_popquiz_m6_": "Please enter quiz description.",
  "_mate_popquiz_m7_": "Question",
  "_mate_popquiz_m8_": "Enter a Question",
  "_mate_popquiz_m9_": "Please enter question.",
  "_mate_popquiz_m10_": "Answer",
  "_mate_popquiz_m11_": "Add an Answer",
  "_mate_popquiz_m12_": "Please enter answer.",
  "_mate_popquiz_m13_": "Explain why this is or isn't the best answer.",
  "_createcourse_step2_m14_": "Add Question",
  "_createcourse_step2_m15_": "Save Quiz",
  "_mate_p2_m1_": "Video Title",
  "_mate_part2_m2_": "Enter a Video Title",
  "_mate_part2_m3_": "Please enter video title.",
  "_mate_part2_m4_": "Video URL",
  "_mate_part2_m5_": "Enter YouTube Video Url",
  "_mate_part2_m6_": "Please enter video url.",
  "_mate_part2_m7_": "Save Video",
  "_mate_part2_m8_": "Upload Whole Course(ZIP file)",
  "_mate_part2_m9_": "Upload Zip File",
  "_mate_part2_m10_": "You can upload ZIP file only.",
  "_mate_part2_m11_": "Upload ZIP",
  "_mate_part2_m12_": "Upload Whole Course(Folder)",
  "_mate_part2_m13_": "Upload Folder",
  "_mate_part2_m14_": "Selected Folder:",
  "_mate_part2_m15_": "Upload Folder",
  "_mate_part2_m16_": "Your browser doesn't support iframes",
  "_mate_part2_m17_": "Upload Materials",
  "_mate_add_model_m1_": "Add Section",
  "_mate_add_model_m2_": "Add Slide",
  "_mate_add_model_m3_": "Upload File",
  "_mate_add_model_m4_": "Upload Zip",
  "_mate_add_model_m5_": "Upload Folder",
  "_mate_add_model_m6_": "Question Bank",
  "_mate_add_model_m7_": "Saving Questions... Please wait",
  "_mate_add_model_m8_": "Add Assignment",
  "_mate_add_model_m9_": "Generating Questions... Please wait",
  "_mate_add_model_m10_": "Add Youtube Video",
  "_mate_model1_m1_": "Add Youtube Playlist",
  "_mate_model1_m2_": "Playlist Title ( This will be your section title )",
  "_mate_model1_m3_": "Add this Video to the Playlist",
  "_mate_model1_m4_": "Fetching Videos...Please wait",
  "_mate_model1_m5_": "Load Videos",
  "_mate_model1_m6_": "Clear",
  "_mate_model1_m7_": "Generating Playlist...Please wait",
  "_mate_model1_m8_": "Generate Playlist",
  "_mate_add_model_m7_": "Saving Questions... Please wait",
  "_mate_add_model_m8_": "Save Questions",
  "_mate_add_model_m9_": "Generating Questions... Please wait",
  "_mate_add_model_m10_": "Generate Questions",
  "_mate_add_model_m11_": "Delete Selected Material",
  "_mate_add_model_m12_": "Convert Selected Slides into Video",
  "_ekyc_alert_m1_": "Important Notice - KYC Verification Process",
  "_ekyc_alert_m2_": "Kindly note that once your KYC verification process is completed, it will not be possible to change your first name or last name without re-verifying your identity.",
  "_ekyc_alert_m3_": "Proceed with e-KYC",
  "_ekyc_alert_m4_": "Abort e-KYC",
  "_ekyc_alert_m5_": "We are verifying your documents... please wait",
  "_ekyc_alert_m6_": "Documents Required",
  "_ekyc_alert_m7_": "Please upload your documents before submitting.",
  "_ekyc_alert_m8_": "e-KYC Status",
  "_ekyc_alert_m9_": "Your e-KYC has been approved.",
  "_ekyc_alert_m10_": "Your e-KYC has been rejected, please try again.",
  "_ekyc_alert_m11_": "The document you have submitted do not have your face, please upload valid documents.",
  "_ekyc_alert_m12_": "Your documents are being verified.",
  "_ekyc_alert_m13_": "e-KYC Failed",
  "_ekyc_alert_m14_": "The documents you have submitted are invalid or do not full-fill our e-KYC requirement.",
  "_ekyc_alert_m15_": "Please upload your documents before submitting.",
  "_dh_alert_m1_": "Change your password.",
  "_dh_alert_m2_": "Your account has a default password. Would you like to set a password now ?.",
  "_dh_announcements_latest_anno_": "Latest Announcements",
  "_lcform_max_lc_limit_m1_": "You've reached maximum number of Learning Circle you can create. In order to create a new learning circle you'll either be required to leave or close a circle earlier created.",
  "_managelc_invite_email_vali_m31_": "Maximum 30 invitation can be sent at a time.",
  "_managelc_invite_email_vali_m32_": "Some of the email(s) are duplicate and not added to the list.",
  "_managelc_invite_email_vali_m33_": "This email already in the list.",
  "_managelc_invite_email_vali_m34_": "This email is already exists in your employee list.",
  "_managecour_msg_m1_": "This will enable grading feature for this course.",
  "_managecour_msg_m2_": "This will disable grading feature for this course.",
  "_managecour_msg_m3_": "Attention!",
  "_managecour_msg_m4_": "Editing your course details will unpublish your course. Once edited you will be required to submit the course again for review. This is necessary for quality control purposes and to ensure that your course meets our standards. Are you sure you want to proceed with editing?",
  "_managecour_msg_m5_": "Sure to change privacy?",
  "_managecour_msg_m6_": "Grading",
  "_managecour_msg_m7_": "View Students",
  "_managecour_courstudent_m1_": "Course Students",
  "_managecour_courstudent_m2_": "Students who enrolled this course will appear here.",
  "_managecour_courstudent_m3_": "Search for students by name",
  "_managecour_inviteviaemail_m1_": "The email is already in the course student list.",
  "_managecour_inviteviaemail_m2_": "Invitation message",
  "_managecour_inviteviaemail_m3_": "I am delighted to extend a personal invitation to join my course on EduKula, where you will have the opportunity to expand your knowledge and skills through engaging lectures, quizzes, and practical assignments.",
  "_managecour_invitevialink_m1_": "Link Copied!",
  "_managecour_reorder_m1_": "Reorder Materials",
  "_managecour_session_m1_": "Add Session",
  "_managecour_session_m2_": "Add new session",
  "_managecour_session_m3_": "Date & Time",
  "_managecour_session_m4_": "Requested by",
  "_pagenotfound_m1_": "Resource Not Found",
  "_pagenotfound_m2_": "Go Back",
  "_pagenotfound_m3_": "Home Page",
  "_ca_msg_m1_": "Welcome to Edukula Career Advisor.",
  "_ca_msg_m2_": "I am a Student",
  "_ca_msg_m3_": "Fresher",
  "_ca_msg_m4_": "I am a Fresher",
  "_ca_msg_m5_": "Working Professional",
  "_ca_msg_m6_": "I am a Working Professional",
  "_ca_msg_m7_": "Type a message and hit enter...",
  "_ca_msg_m8_": "Clear Chat",
  "_sa_warn_m1_": "Type of Self Assessment",
  "_sa_warn_m2_": "Please choose the option that best fits your needs. This will help us tailor the self-assessment experience to your specific goals.",
  "_sa_warn_m3_": "This feature is powered by AI, and the output is not always 100% accurate. Please double-check the output generated before utilizing it.",
  "_sa_warn_m4_": "Career Advancement",
  "_sa_warn_m5_": "Just for Practice",
  "_sa_warn_m6_": "Your quiz is being generated... please wait",
  "_sa_area_of_assessment_": "Area/Subject of assessment",
  "_sa_area_of_assessment_vali_m1_": "Area/Subject can not be more than 80 characters.",
  "_sa_area_of_assessment_vali_m2_": "Please enter at least 3 characters.",
  "_sa_area_of_assessment_place_": "Enter Area/Subject of assessment",
  "_sa_area_of_assessment_vali_m3_": "Please enter Area/Subject.",
  "_sa_eligibility_type_": "Eligibility Type",
  "_sa_eligibility_type_opt1_": "I am checking my eligibility to apply for a Course",
  "_sa_eligibility_type_opt2_": "I am checking my eligibility to apply for a Job",
  "_sa_eligibility_type_vali_m1_": "Please select the section.",
  "_sa_noofques_label_": "Number of Questions",
  "_sa_noofques_vali_m1_": "Please enter Area/Subject.",
  "_sa_generate_quiz_": "Generate Quiz",
  "_sa_resultmsg_m1_": "You have scored",
  "_sa_resultmsg_m2_": "Remember, there is always room for improvement, even for AI like myself. Keep practising, and you will see progress soon!",
  "_sa_resultmsg_m3_": "Great effort on your score! Keep practising and you will be ready for the next chapter in your career.",
  "_sa_resultmsg_m4_": "Great Job! Your hard work has prepared you for the next chapter in your career.",
  "_sa_resultmsg_m5_": "You have completed the quiz.",
  "_vacancy_alert_m1_": "This will activate vacancy.",
  "_vacancy_alert_m2_": "This will inactivate vacancy.",
  "_vacancy_alert_m3_": "The course currently connected with the vacancy is either not published yet on EduKula or faces some other issue. Please check and get the course published or edit the vacancy and connect it with another relevant published course to make the vacancy active.",
  "_vacancy_alert_m4_": "Would you like to edit the vacancy and change the course?",
  "_vacancy_alert_m5_": "Course Inactivated",
  "_v_delete_sucees_m1_": "Deleted successfully",
  "_v_list_msg_m1_": "Your vacancy has been successfully created for EduKula's hiring courses listing.",
  "_v_list_msg_m2_": "If your course is in review, it will start showing up on the listing once it's published. Would you like to go ahead and invite talent to learn the course?",
  "_v_list_msg_m3_": "Yes, Let's Invite Talent",
  "_vacancy_add_form_generate_btn_": "Generate Description Using AI",
  "_vacancy_email_limit_m1_": "Maximum 30 emails can be sent at a time.",
  "_vacancy_add_note_place_": "You can write your notes here",
  "_vacancy_share_report_m1_": "Report has been shared.",
  "_vacancy_share_report_m2_": "An email has been sent to the email address(es) you have provided.",
  "_vacancy_alert_msg_m1_": "Add a Note",
  "_vacancy_alert_msg_m2_": "Before you make any changes, it is required to add a note for the reference...",
  "_vacancy_alert_msg_m3_": "Remember, it is mandatory to include a note. The hiring status you have selected will not be updated unless you add a note.",
  "_vacancy_alert_msg_m4_": "You need to write something!",
  "_emp_addemp_csv_m1_": "The CSV file is empty, please download sample file for the format and then edit it",
  "_emp_addemp_csv_m2_": "The CSV file is not in proper format, please download sample file for the format and then edit it",
  "_emp_addemp_csv_m3_": "Please select csv file.",
  "_report_msg_m1_": "Report with Employees Details",
  "_report_msg_m2_": "Export to CSV",
  "_report_msg_m3_": "Employees Login / Logout Report ( Last 7 Days)",
  "_report_msg_m4_": "Login / Logout Report ( Last 7 Days)",
  "_report_msg_m5_": "Enrolled Learners in Course",
  "_report_msg_m6_": "Employee Course Status Report",
  "_report_msg_m7_": "Employee Course Status Report",
  "_noauthcerti_msg_m1_": "You can download all your course completion certificates by clicking the download button below",
  "_noauthcerti_msg_m2_": "Explore more",
  "_noauthcerti_msg_m3_": "Download Certificate",
  "_courprogress_msg_m1_": "Completed Slides",
  "_courprogress_msg_m2_": "Remaining Slides",
  "_courprogress_msg_m3_": "Back to Course",
  "_courprogress_msg_m4_": "Total slides",
  "_courprogress_msg_m5_": "Completed slides",
  "_e_experience_vali_m11_": "Please select employment type",
  "_e_education_vali_m12_": "Please enter location",
  "_e_education_vali_m13_": "Location can not be more than 80 charaters.",
  "_e_education_vali_m14_": "Please enter industry",
  "_e_education_vali_m15_": "Please enter at least 2 characters.",
  "_e_education_vali_m16_": "Industry can not be more than 80 charaters.",
  "_lcmaterial_msg_m1_": "Please select a valid file",
  "_lcmaterial_msg_m2_": "The file",
  "_lcmaterial_msg_m3_": "you're trying to upload is not supported by EduKula. Please ensure that your file meets our supported file formats, which include: PDF, Word, PPT, Audio, Video, Image, ZIP, SCORM and TINCAN",
  "_lcmaterial_msg_m4_": "Unsupported File Format",
  "_lcmaterial_msg_m5_": "Sure to delete material section?",
  "_lcmaterial_msg_m6_": "Sure to delete material slide?",
  "_lcmaterial_msg_m7_": "Materials List",
  "_lcmaterial_msg_m8_": "Search Materials",
  "_lcmaterial_msg_m9_": "Add After",
  "_lcmaterial_msg_m10_": "Select position",
  "_lcmaterial_msg_m11_": "After",
  "_lcmaterial_msg_m12_": "Please select position of the section",
  "_lcmaterial_msg_m13_": "Short Description",
  "_lcmaterial_msg_m14_": "Short Description can not be more than 200 characters.",
  "_lcmaterial_msg_m15_": "Please enter short description.",
  "_lcmaterial_msg_m16_": "Please enter slide title.",
  "_lcmaterial_msg_m17_": "About Slide..",
  "_lcmaterial_msg_m18_": "Please enter short description.",
  "_lcmaterial_msg_m19_": "View Content",
  "_lcmaterial_msg_m20_": "Added by",
  "_lcmaterial_msg_m21_": "Date-Time",
  "_lcmaterial_msg_m22_": "There is no material uploaded",
  "_lcmaterial_msg_m23_": "There were no sections or materials added",
  "_lcmember_msg_m1_": "New people and pages that join this group will appear here.",
  "_lcmember_msg_m2_": "Admins",
  "_lcmember_msg_m3_": "Find a member",
  "_lcmember_msg_m4_": "Dismiss as Admin",
  "_lcmember_msg_m5_": "Kick Out",
  "_lcmember_msg_m6_": "No members found",
  "_lcjoinrequest_msg_m1_": "Join Requests",
  "_lcjoinrequest_msg_m2_": "has requested to join the circle",
  "_lcjoinrequest_msg_m3_": "Accept",
  "_lcjoinrequest_msg_m4_": "No Request Found.",
  "_lcsendinvitation_msg_m1_": "Invitation sent to",
  "_lcsendinvitation_msg_m2_": "by",
  "_lcsendinvitation_msg_m3_": "There were no invitations sent.",
  "_mentorpre_msg_m11_": "Enter mentor preferences",
  "_mentorpre_msg_m12_": "Please enter why should students learn from you",
  "_vacancy_vali_m1_": "Job position is required",
  "_vacancy_vali_m2_": "Please select course",
  "_vacancy_vali_m3_": "Please enter max 50 characters.",
  "_vacancy_vali_m4_": "Please enter experience",
  "_role_vali_m1_": "Role name is required",
  "_rolename_vali_m1_": "Role name is required",
  "_empform_vali_msg_m1_": "Please enter a valid email",
  "_empform_vali_msg_m2_": "Password should be a minimum of eight characters in length and must contains at least a letter, number and a special character.",
  "_tutorprofile_msg_m1_": "Total Students",
  "_tutorprofile_msg_m2_": "Reviews",
  "_tutorprofile_msg_m3_": "Courses",
  "_tutorprofile_msg_m4_": "No about information of",
  "_aboutus_msg_m1_": "About EduKula",
  "_aboutus_msg_m2_": "EduKula is a futuristic learning platform which offers numerous courses in different genres to keen learners. It is a completely online platform, providing a marketplace for courses on various topics such as web development, designing, marketing, entrepreneurship, art, music, finance, lifestyle, personality development, animation, management, and many more. EduKula supports subject matter experts, professionals, and specialists to create different courses and share their knowledge with a large number of people. We have been creating a knowledge-driven community with the support of ingenious tutors and enthusiastic learners from every corner of the world.",
  "_aboutus_msg_m3_": "40M",
  "_aboutus_msg_m4_": "Languages",
  "_aboutus_msg_m5_": "480M",
  "_aboutus_msg_m6_": "Enrollments",
  "_aboutus_msg_m7_": "Countries",
  "_aboutus_msg_m8_": "Enterprise customers",
  "_aboutus_msg_m9_": "Trusted By",
  "_contactus_vali_ma_": "Please complete the reCAPTCHA verification.",
  "_contactus_msg_m1_": "Address & Hours",
  "_contactus_msg_m2_": "Send a message",
  "_contactus_msg_m3_": "YOUR EMAIL ADDRESS WILL NOT BE PUBLISHED. REQUIRED FIELDS ARE MARKED",
  "_contactus_msg_m4_": "Please enter valid name.",
  "_contactus_msg_m5_": "Name can not be more than 30 characters.",
  "_contactus_msg_m6_": "Your Name",
  "_contactus_msg_m7_": "Please enter your name.",
  "_contactus_msg_m8_": "Your Email",
  "_contactus_msg_m9_": "Please enter your email.",
  "_contactus_msg_m10_": "Message can not be more than 500 characters.",
  "_contactus_msg_m11_": "Message",
  "_contactus_msg_m12_": "Please enter your message.",
  "_contactus_msg_m13_": "Terms of Use",
  "_contactus_msg_m14_": "I agree to the",
  "_contactus_msg_m15_": "and have read the",
  "_contactus_msg_m16_": "Privacy Policy.",
  "_contactus_msg_m17_": "Please agree to Terms of Use & Privacy Policy.",
  "_mylc_managelc_warn_m1_": "Note:- you are creating learning circle on behalf of your company and all associated rights are the sole property of your company.",
  "_home2_msg_m1_": "To Create learning circle, you must be logged in to the platform. If you do not have an account, do not worry! You can easily create one by clicking the Sign Up button below.",
  "_home2_msg_m2_": "Homepage",
  "_home2_msg_m3_": "Enhance your skills",
  "_home2_msg_m4_": "& advance in your career",
  "_home2_msg_m5_": "What would you like to learn ?",
  "_home2_msg_m6_": "Corporates",
  "_home2_msg_m7_": "Solutions for",
  "_home2_msg_m8_": "corporates",
  "_home2_msg_m9_": "Corporate academia & youth connect",
  "_home2_msg_m10_": "Connects corporates with academia to create industry-relevant courses, and enables corporates to use them to train and hire talent.",
  "_home2_msg_m11_": "Train your team",
  "_home2_msg_m12_": "Provide comprehensive training and ongoing support to empower employees, enhance their performance in current job roles, and facilitate career advancement.",
  "_home2_msg_m13_": "Reporting & analytics",
  "_home2_msg_m14_": "Monitor the learning activities of your employees and obtain valuable insights that enable you to make informed decisions to achieve your organization's goals.",
  "_home2_msg_m15_": "Working",
  "_home2_msg_m16_": "Professionals",
  "_home2_msg_m17_": "Solutions for",
  "_home2_msg_m18_": "working professionals",
  "_home2_msg_m19_": "Grow in your career",
  "_home2_msg_m20_": "Whether you're seeking to acquire a new skill or wish to enhance your existing skills through upskilling or reskilling, our platform can help you accelerate your career.",
  "_home2_msg_m21_": "Collaborate with peers",
  "_home2_msg_m22_": "Gain deeper insights into your industry by creating or joining a learning circle and collaborating with like-minded individuals and industry peers.",
  "_home2_msg_m23_": "Career advice",
  "_home2_msg_m24_": "Get personalized career advice based on your profile, helping you to identify the right learning opportunities to improve your skills and grow in your existing line of work.",
  "_home2_msg_m25_": "& Freshers",
  "_home2_msg_m26_": "students & freshers",
  "_home2_msg_m27_": "Become job-ready",
  "_home2_msg_m28_": "Stay up-to-date with industry demands and gain practical experience by enhancing your skills and knowledge through top courses and trending learning circles.",
  "_home2_msg_m29_": "Career advice",
  "_home2_msg_m30_": "Considering your profile details the system suggests the most suitable courses, learning circles, job vacancies and more to help you launch your career.",
  "_home2_msg_m31_": "Get hired & earn rewards",
  "_home2_msg_m32_": "Study courses uploaded by corporates on EduKula, which are designed to find and hire new talent, and get rewarded monetarily for landing your dream job.",
  "_home2_msg_m33_": "courses for job seekers",
  "_home2_msg_m34_": "Courses created by corporates looking to hire talent for a particular vacancy",
  "_home2_msg_m35_": "Top",
  "_home2_msg_m36_": "courses",
  "_home2_msg_m37_": "Explore high-quality online courses on a wide array of topics and specializations.",
  "_home2_msg_m38_": "Harnessing the power of",
  "_home2_msg_m39_": "peer learning",
  "_home2_msg_m40_": "Are you looking for a powerful platform to elevate your learning? A learning circle can be proved as your savior. It is a feature-rich study group which facilitates individuals in gathering and engaging with one another to gain advanced knowledge on various topics.",
  "_home2_msg_m41_": "Know more",
  "_home2_msg_m42_": "Create learning circle",
  "_home2_msg_m43_": "Join learning circle",
  "_home2_msg_m36_": "learning circles",
  "_home2_msg_m37_": "Collaborative learning groups that connect you with industry peers and help you enhance your knowledge",
  "_home2_msg_m44_": "Educators",
  "_home2_msg_m45_": "educators",
  "_home2_msg_m46_": "You can create and publish courses on EduKula at no cost, enjoying complete control over your course material and no hidden charges.",
  "_home2_msg_m47_": "Earn revenue",
  "_home2_msg_m48_": "Generate income by developing customized, industry-specific courses for corporate clients that align with their training requirements.",
  "_home2_msg_m49_": "Track learner activity",
  "_home2_msg_m50_": "Our platform generates reports that track learner activity, providing you with valuable insights into how your courses are performing.",
  "_homesearch_msg_m1_": "Keyword Search",
  "_homesearch_msg_m2_": "results",
  "_home2_msg_new_course_p_m1_": "Explore high-quality online courses on a wide array of topics and specializations.",
  "_home2_courses_msg_m_": "courses",
  "_home2_msg_m37_11_": "Collaborative learning groups that connect you with industry peers and help you enhance your knowledge",
  "_home2_msg_m36_11_": "learning circles",
  "_blogs_msg_m1_": "No blogs or news found.",
  "_mate_add_model_m9_11_": "AI Generated Youtube Playlist",
  "_mate_add_slide_video_opt_not_": "Do Not Generate Video",
  "_mate_video_upload_without_transcript_": "Upload without transcript",
  "_courseplay_assign_m1_": "You've Already submitted this assignment",
  "_courseplay_assign_m2_": "View Submitted Assignment",
  "_courseplay_assign_m3_": "Click here to resubmit.",
  "_courseplay_assign_m4_": "Score",
  "_mate_converting_msg_m1_": "Your text content will be converted into a video shortly, depending on its length. Thank you for your patience.",
  "_mate_converting_msg_m2_": "Your text content will be converted into a video shortly, depending on its length. Thank you for your patience.",
  "_mate_converting_msg_m3_": "AI is generating course materials. Thank you for your patience.",
  "_mate_converting_msg_m4_": "Your slide has been converted into a video file!",
  "_mate_converting_msg_m5_": "Course materials have been added!"} 
export default enJson;
