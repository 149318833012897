import React from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';
import { Controller, useForm } from "react-hook-form";
import { API_BASE } from "../../../constants/global";
import { useLocation, Link } from "react-router-dom";
import ReactTags from 'react-tag-autocomplete';
import { Helmet } from "react-helmet";


function QuizBank() {
  const { register, handleSubmit, formState: { errors }, watch, control, unregister } = useForm();
  const [draftCourse, setDraftCourse] = useState('');
  const [courseCategories, setCourseCategories] = useState('');
  const [loadingdata, setLoadingdata] = useState(true);
  const [quizfrom, setQuizfrom] = useState(1);
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);



  const location = useLocation();
  let raw_courses = [];
  const _token = localStorage.getItem('lmstoken');

  const reactTags = useRef();
  const onDelete = useCallback((tagIndex) => {
    setTags(tags.filter((_, i) => i !== tagIndex));

  }, [tags])

  const onAddition = useCallback((newTag) => {
    setTags([...tags, newTag]);

  }, [tags])

  const saveQuizBank = async (form) => {
    // setLoadingdata(true);
    // setLoadingdata(false);
    const newTags = tags.map((tag, inx) => tag.name);
    let newForm = form;
    if (newTags.length > 0) {
      newForm = { ...form, 'tags': newTags };
    }
    console.log(newForm);
    alert("Generate Quiz");
  }

  const fetchCategories = async () => {
    setLoadingdata(true);
    const response = await fetch(`${API_BASE}get-category-list`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + _token
      },
    });

    const data = await response.json();
    if (data.status) {
      let Categories = [];
      let Cate = data.data.course_categories;
      Cate.forEach(function (q) {
        let tempCate = {
          "categories_id": q.id,
          "categories_title": q.title
        }

        Categories.push(tempCate);
      })
      setCourseCategories(Categories);
      setLoadingdata(false);
    } else {
    }
  }

  const fetchPublishedCourses = async (page) => {
    const response = await fetch(`${API_BASE}get-tutor-published-course-list?limit=50&page=${page}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${_token}`
      },
    });

    const data = await response.json();
    if (data.status) {
      let publishedCourse = data.data.courses;
      publishedCourse.forEach(function (q) {
        let tempDraft = {
          "course_id": q.id,
          "course_title": q.title
        }

        raw_courses.push(tempDraft);
      })
      setDraftCourse(raw_courses);
      setLoadingdata(false);
    }
  }

  const fetchDraftCourses = async (page) => {
    setLoadingdata(true);
    const response = await fetch(`${API_BASE}get-tutor-drafted-course-list?limit=50&page=${page}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${_token}`
      },
    });

    const data = await response.json();
    if (data.status) {

      let draftcourse = data.data.courses;
      draftcourse.forEach(function (q) {

        let temp_draft = {
          "course_id": q.id,
          "course_title": q.title
        }

        raw_courses.push(temp_draft);
      })
      fetchPublishedCourses(0);
    }
  }

  useEffect(() => {
    fetchCategories();
    fetchDraftCourses(0);
  }, [location]);

  const handleChange = event => {

    var radioSelect = event.target.value == 1 ? 1 : 0
    setQuizfrom(radioSelect);
    if (radioSelect === 0) {
      unregister('course_id');
    }
    if (radioSelect === 1) {
      unregister('tags');
      unregister('prompts');
    }
  };


  return (
    <>
      <Helmet>
        <title>{'EduKula - Add Questions'}</title>
      </Helmet>
      <div className="col-lg-9 home-section">
        <div className="custom-user-profile-content custom-learning-circle-content chatPage vacancypage">
          <div className="custom-learnig-circle-head">
            <h4>Add Questions</h4>
            <Link to={'/dashboard/tutor/quizbank'}>
              <button type="button" className="close" >
                <i className="fa fa-arrow-left" aria-hidden="true"></i>
              </button>
            </Link>

          </div>

          <div className='modal-body'>
            <p className='QBlable QBlablemain' htmlFor='Quizfrom'>Generate quiz :- </p>
            <div className="form-check-inline">
              <label className="form-check-label">
                <input type="radio" onChange={(event) => { handleChange(event); }} className="form-check-input" name="Quizfrom" defaultValue={1} checked={quizfrom === 1 ? 'checked' : ''} />Course
              </label>
            </div>

            <div className="form-check-inline">
              <label className="form-check-label">
                <input type="radio" onChange={(event) => { handleChange(event) }} className="form-check-input" name="learning_email" defaultValue={0} checked={quizfrom === 0 ? 'checked' : ''} />Keyword
              </label>
            </div>


            <form onSubmit={handleSubmit(saveQuizBank)}>
              <div className="form-group adq">
                <label className='QBlable'>Select Course Category :- </label>
                <select {...register("course_category_id", {
                  required: "required"
                })} className='QBSelect'>
                  <option value="">Select Course Category</option>
                  {
                    courseCategories !== ''
                    &&
                    courseCategories.map((section, index) =>
                      <option key={index} value={section.categories_id}>{section.categories_title}</option>
                    )
                  }
                </select>
                <span className="text-danger">
                  {errors.course_category_id && errors.course_category_id.type === "required" && 'Please select course category'}
                </span>
              </div>
              {quizfrom === 1 ? (
                <>
                  <div className="form-group adq">
                    <label className='QBlable'>Select Course :- </label>
                    <select {...register("course_id", {
                      required: "required"
                    })} className='QBSelect'>
                      <option value="">Select Course</option>
                      {
                        draftCourse !== ''
                        &&
                        draftCourse.map((section, index) =>
                          <option key={index} value={section.course_id}>{section.course_title}</option>
                        )
                      }
                    </select>
                    <span className="text-danger">
                      {errors.course_id && errors.course_id.type === "required" && 'Please select the course'}
                    </span>
                  </div>
                </>) : (<>
                  <div className="form-group adq tagdiv">
                    <label className='QBtags'>Add tags :- </label>
                    <Controller
                      control={control}
                      shouldUnregister={true}
                      name="tags"
                      rules={{ required: false, message: "Please enter keywords" }}
                      render={({ field: { onChange, onKeyUp, value, name, ref } }) => (
                        <ReactTags
                          ref={reactTags}
                          tags={tags}
                          suggestions={suggestions}
                          onDelete={onDelete}
                          onAddition={onAddition}
                          allowNew={true}
                          placeholderText={tags.length > 0 ? '' : 'Please enter keywords to generate quiz'}
                        />
                      )}
                    />

                  </div>
                  <div className="form-group adq tagdiv">
                    <label className='QBtags'>Custom Prompt :- </label>
                    {/* <input type='text' */}
                    <input id="prompts" type="text" {...register("prompts", {
                      required: "required"
                    })} className="form-control" placeholder="Please Enter prompt" autoFocus />
                  </div>
                </>)}
              <div style={{ marginBottom: '15px' }}>
                <a data-toggle="modal" data-target="#GenerateQuestionDia"
                  id="GenerateQues"
                  className="GenVid GenQueBank"
                >
                  Generate Questions
                </a>
              </div>
              <div className='QuizBankSave'>
                <button type="submit" className="btn btn-circle btn-md submit-pink-btn" disabled={loadingdata}>
                  {loadingdata ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Save'}
                </button>
              </div>
            </form>
          </div>
          <div className="modal fade" id="GenerateQuestionDia" tabIndex="-1" role="dialog" aria-labelledby="GenerateQuestionDia" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-ek-lg-1200" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 id="GenerateQuestionDiaTitle">Generate Questions</h4>
                  <button type="button" id="GenerateQuestionDiaClose" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group" style={{ 'textAlign': 'center' }}>
                    <h5>Generate Questions</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default QuizBank;
