import { useEffect, useState, useLayoutEffect } from "react";
import { Link, useParams, useLocation, useSearchParams } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import CustomPagination from "../../entity/CustomPagination";
import CircleBoxSkeleton from "../../components/skeletons/CircleBoxSkeleton"
import { TrendingCircleI } from "../../user_dashboards/learning_circle";
import LearningCircleBreadcrumb from "../../components/LearningCircleBreadcrumb";
import Slider from "react-slick";
import LearningCircleCard from "./learning_circle_tab/LearningCircleCard";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const ExploreLearningCircles = (props) => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);
    const { t } = useTranslation();
    const _token = localStorage.getItem('lmstoken');
    const [search, setSearch] = useState('');
    const [groups, setGroups] = useState('');
    const [trending_groups, setTrendingGroups] = useState('');
    const [high_rated_groups, setHighRatedGroups] = useState('');
    const [total_count, setTotalCount] = useState(0);
    const [isLoader, setIsLoader] = useState(true);
    const { category_slug, sub_category_slug } = useParams();

    const [categories, setCategories] = useState('');
    const [sub_categories, setSubCategories] = useState('');
    const [languages, setLanguages] = useState('');
    const [rates, setRates] = useState('');
    const [selected_categories, setSelectedCategories] = useState('');
    const [selected_sub_categories, setSelectedSubCategories] = useState((typeof sub_category_slug !== 'undefined' && sub_category_slug !== '') ? [sub_category_slug] : '');
    const [selected_languages, setSelectedLanguages] = useState('');
    const [selected_rate, setSelectedRates] = useState('');
    const [clearAllBtnClass, setclearAllBtnClass] = useState('hide');
    const [currentcat, setCurrentcat] = useState('');
    const type = (window.location.href.indexOf("sub-category") >= 0) ? 'sc' : 'c';

    const [currentItems, setCurrentItems] = useState([]);
    const [coursePerPage, setCoursePerPage] = useState(8);
    const [itemOffset, setItemOffset] = useState(0);

    var settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        }, {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }, {
            breakpoint: 680,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };

    useEffect(() => {
        fetchCategories();
        fetchSubCategories();
        fetchLanguage();
        fetchRates();
        fetchGroups(currentPage);
        removeClearBtn();
        fetchTrendingGroups();
        fetchHighRatedGroups();

        // eslint-disable-next-line
    }, [selected_categories, selected_sub_categories, selected_languages, selected_rate, category_slug, sub_category_slug, reload]);

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }

    const fetchGroups = async (page) => {
        var formData = new FormData();

        if (typeof category_slug === 'undefined') {
            if (selected_categories !== '' && selected_categories.length > 0) {
                for (var i = 0; i < selected_categories.length; i++) {
                    formData.append('c[]', selected_categories[i]);
                }
            }
        }

        if (typeof category_slug !== 'undefined') {
            if (selected_sub_categories !== '' && selected_sub_categories.length > 0) {
                for (var i = 0; i < selected_sub_categories.length; i++) {
                    formData.append('sc[]', selected_sub_categories[i]);
                }
            }
        }


        if (selected_languages !== '' && selected_languages.length > 0) {
            for (var i = 0; i < selected_languages.length; i++) {
                formData.append('lng[]', selected_languages[i]);
            }
        }

        if (selected_rate !== '' && selected_rate.length > 0) {
            for (var i = 0; i < selected_rate.length; i++) {
                formData.append('rt', selected_rate[i]);
            }
        }

        setIsLoader(true);
        const response = await fetch(`${API_BASE}get-group-search?page=${page}&search=${search}${(category_slug) ? `&category_slug=${category_slug}` : ''}${(sub_category_slug) ? '&sub_category_slug=' + sub_category_slug : ''}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        setIsLoader(false);
        if (data.status) {
            setGroups(data.data.groups);
            setTotalCount(data.data.total_count);
            // window.scrollTo(0, 470);
            setItemOffset(0);
            if (data?.data?.groups && data?.data?.groups?.length > 0) {
                let endOffset = 0 + coursePerPage;
                let tempCurrentItems = data.data.groups.slice(0, endOffset);
                setCurrentItems(tempCurrentItems);
            }
            if (groups) {
                setCurrentcat(data.data.groups[0].category);
            }
        } else {
            setCurrentItems([]);
            setGroups('');
            setTotalCount(0);
            // window.scrollTo(0, 800);
        }
        window.scrollTo(0, 0);
    }

    const fetchTrendingGroups = async () => {
        const response = await fetch(`${API_BASE}get-trending-circles`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setTrendingGroups(data.data.groups);
        }
    }

    const fetchHighRatedGroups = async () => {
        const response = await fetch(`${API_BASE}get-high-rated-group`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setHighRatedGroups(data.data.ratings);
        }
    }

    const fetchCategories = async () => {
        const response = await fetch(`${API_BASE}get-category-list`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();


        if (data.status) {
            setCategories(data.data.course_categories);
        }
    }

    async function fetchSubCategories() {
        if (typeof category_slug !== 'undefined') {
            const response = await fetch(`${API_BASE}get-sub-category-list/${category_slug}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            });

            const data = await response.json();
            if (data.status) {
                setSubCategories(data.data.course_sub_categories);
            }
        }
    }

    const fetchLanguage = async () => {
        const response = await fetch(`${API_BASE}get-course-languages`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setLanguages(data.data.languages);
        }
    }
    async function fetchRates() {
        const response = await fetch(`${API_BASE}get-course-rate-levels`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setRates(data.data.rates);
        }
    }
    const clearSearch = () => {
        fetchGroups(0);
        setSearch('');
        setSelectedCategories('');
        setSelectedSubCategories('');
        setSelectedLanguages('');
        setSelectedRates('');
        setclearAllBtnClass('hide');
    }

    const removeClearBtn = () => {
        if (selected_categories.length === 0 && selected_sub_categories.length === 0 && selected_languages.length === 0) {
            setclearAllBtnClass('hide');
        }
    }

    const handlePaginationPageClick = (event) => {
        const newOffset = (event.selected * coursePerPage) % groups.length;
        setItemOffset(newOffset);
        setSearchParams({ ...searchParams, page: event.selected });
    }

    useEffect(() => {
        let endOffset = itemOffset + coursePerPage;
        if (groups && groups.length > 0) {
            setCurrentItems(groups.slice(itemOffset, endOffset));
        }
    }, [itemOffset, coursePerPage]);

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_dashboard_home_t4_')}`} </title>
            </Helmet>
            {
                (location.pathname === '/explore-learning-circles')
                    ?
                    null
                    :
                    <>
                        <section className="sub_course_banner">
                            <div className="container">
                                <div className="row justify-content-end align-items-center">
                                    <div className="col-lg-7">
                                        <h1>{currentcat}</h1>
                                        <p>{t('_explorelc_mainmsg_')}.</p>
                                        <Link to={"/explore-learning-circles"} className="btn btn-success"> {t('_explorelc_all_categories_')}</Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="clearfix"></div>
                    </>
            }

            <section className="new_learning_circle_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="category-search-info">
                                <p>{t('_explorelc_joinmsg_')}</p>
                                <div className="footer-newslwtter1">
                                    <input type="text" onChange={(e) => { setSearch(e.target.value); }} onKeyUp={(e) => { if (e.key === 'Enter') { fetchGroups(0); } }} placeholder={t('_explorelc_joinmsg_place_')} value={search} />
                                    <button onClick={(e) => { fetchGroups(0); }} type="button" className="btn btn-success"><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <LearningCircleBreadcrumb category_slug={category_slug} sub_category_slug={sub_category_slug} />

            <section className="custom-section new_learning_circle_cate">
                <div className="category-search-section">
                    <div className="container">
                        <div className="category-search-block">
                            {/* <div className="category-search-info">
                                <p>Search learning circles</p>
                                <div className="footer-newslwtter">
                                    <i className="fas fa-search"></i>
                                    <input type="text" onChange={(e) => { setSearch(e.target.value); }} onKeyUp={(e) => { if (e.key === 'Enter') { fetchGroups(0); } }} placeholder="What do you want to learn ?" value={search} />
                                    <button onClick={(e) => { fetchGroups(0); }} type="button" className="btn btn-info">search</button>
                                </div>
                            </div> */}
                            <div className="category-navigation">
                                <ul>
                                    {
                                        (typeof props.type === 'undefined' && categories !== '' && typeof category_slug === 'undefined')
                                        &&
                                        <li>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('_explorelc_category_')} </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        categories.map((category, index) => {
                                                            var flag = selected_categories.indexOf(category.slug) !== -1;
                                                            return (<a onClick={(e) => { if (!flag) { setSelectedCategories([...selected_categories, category.slug]); } setclearAllBtnClass(''); }} key={index} className={`dropdown-item ${flag ? ' active' : ''}`}>{category.title}</a>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    }

                                    {
                                        (sub_categories !== '' && typeof category_slug !== 'undefined')
                                        &&
                                        <li>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('_explorelc_subcategory2_')} </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        sub_categories.map((category, index) => {
                                                            var flag = selected_sub_categories.indexOf(category.slug) !== -1;
                                                            return (<a onClick={(e) => { if (!flag) { setSelectedSubCategories([...selected_sub_categories, category.slug]); } setclearAllBtnClass(''); }} key={index} className={`dropdown-item ${flag ? ' active' : ''}`}>{category.title}</a>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    }

                                    {
                                        /*
                                            languages !== ''
                                            &&
                                            <li>
                                                <div className="dropdown">
                                                    <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Language</button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        {
                                                            languages.map((language, index) => {
                                                                var flag = selected_languages.indexOf(language.name) !== -1;
                                                                return (<a onClick={(e) => { if (!flag) { setSelectedLanguages([...selected_languages, language.name]); } setclearAllBtnClass(''); }} key={index} className={`dropdown-item ${flag ? ' active' : ''}`}>{language.name}</a>);
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        */
                                    }
                                    {
                                        rates !== ''
                                        &&
                                        <li>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('_explorelc_rating_')} </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        rates.map((rate, index) => {
                                                            var flag = selected_rate.indexOf(rate) !== -1;
                                                            return (<a onClick={(e) => { if (!flag) { setSelectedRates([rate]); } setclearAllBtnClass(''); }} key={index} className={`dropdown-item ${flag ? ' active' : ''}`}>{rate} {t('_explorelc_rate_above_')}</a>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                            <div className="selected-filter filter_display">
                                <ul>
                                    {
                                        selected_categories !== ''
                                        &&
                                        selected_categories.map((category, index) =>
                                            <li key={index}>
                                                <span className="btn btn-light btn-success select-filter"><span>{category}</span><i onClick={(e) => { setSelectedCategories(selected_categories.filter(item => item !== category)) }} className="fas fa-times"></i></span>
                                            </li>
                                        )
                                    }

                                    {
                                        selected_sub_categories !== ''
                                        &&
                                        selected_sub_categories.map((sub_category, index) =>
                                            <li key={index}>
                                                <span className="btn btn-light btn-success select-filter"><span>{sub_category}</span><i onClick={(e) => { setSelectedSubCategories(selected_sub_categories.filter(item => item !== sub_category)) }} className="fas fa-times"></i></span>
                                            </li>
                                        )
                                    }

                                    {
                                        /*
                                            selected_languages !== ''
                                            &&
                                            selected_languages.map((language, index) =>
                                                <li key={index}>
                                                    <span className="btn btn-light btn-success select-filter"><span>{language}</span><i onClick={(e) => { setSelectedLanguages(selected_languages.filter(item => item !== language)) }} className="fas fa-times"></i></span>
                                                </li>
                                            )
                                        */
                                    }
                                    {
                                        selected_rate !== ''
                                        &&
                                        selected_rate.map((rate, index) =>
                                            <li key={index}>
                                                <span className="btn btn-light btn-success select-filter"><span>{rate} {t('_explorelc_rate_above_')}</span><i onClick={(e) => { setSelectedRates(selected_rate.filter(item => item !== rate)) }} className="fas fa-times"></i></span>
                                            </li>
                                        )
                                    }
                                </ul>

                                {
                                    <a onClick={(e) => { clearSearch(); }} className={`remove-filter ${clearAllBtnClass}`}>{t('_explorelc_clearfilter_')}</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="clearfix"></div>

            <section className="custom-section new_learning_circle_list">
                <div className="container">
                    <div className="title_box">
                        <h1>{t('_explorelc_learning_circle_')} <span>{t('_explorelc_onedukula_')}</span></h1>
                        <p>{t('_explorelc_msg2_')}.</p>
                    </div>
                    <div className="clearfix"></div>
                    <div className="explore-block learning-circle-list-section">
                        <div className="row">
                            {
                                isLoader ?
                                    <>
                                        <CircleBoxSkeleton count={4} boxSize={'col-lg-3'} />
                                    </>
                                    :
                                    <>
                                        {
                                            groups
                                            &&
                                            currentItems.map((group, index) =>
                                                <div key={index} className="col-md-6 col-lg-3 mb-4">
                                                    <div className="list_box">
                                                        <div className="blog-card-detail">
                                                            <Link className="pos_link ek-lrs-module-initialized" to={'/learning-circle-details/' + group.unique_id} data-ek-lrs-url={`/learning-circle-details/${group.id}`} data-ek-lrs-title={group.title}></Link>
                                                            <div className="custom-card-img">
                                                                <img src={group.image} className="card-img-top" alt="Blog image" />
                                                            </div>
                                                            <div className="blog-card-inner">
                                                                <span className="hide">{t('_mylc_lccard_')} {group.started}</span>
                                                                <p className="membr_line"><span>{group.membersCount}+ {t('_mylc_lccard_m2_')}</span> &nbsp; | &nbsp; <span>{group?.owner}</span></p>
                                                                <h5>{group.title}</h5>
                                                                <ul className="course-tabs-details">
                                                                    <li><a><i className="fas fa-list"></i><span>{group.category}</span></a></li>
                                                                    {/* <li><Link to={`/profile-details/${group?.owner_slug}`}><i className="far fa-user"></i><span>{group?.owner}</span></Link></li> */}
                                                                    {/* <li><i className="far fa-user"></i><span>{group?.owner}</span></li> */}
                                                                    {/* <li><a><i className="fas fa-users"></i><span>{group.membersCount}+ Group members</span></a></li> */}
                                                                    {/* <li><a><i className="fas fa-language"></i><span>{group.language}</span></a></li> */}
                                                                    <li><i className="fas fa-star"></i><span>{group?.average_rating !== null ? group?.average_rating : 'No'} {t('_explorelc_rating_')}</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        }

                                        {
                                            !groups
                                            &&
                                            <div className="col-12">
                                                <div className="empty_state">
                                                    <div className="col-12">
                                                        <div className="empty_state">
                                                            <h3>{t('_explorelc_nolc_found_')} </h3>
                                                            <img alt="" src="images/no_courses.png" />
                                                            {
                                                                search != ""
                                                                &&
                                                                <p>{t('_explorelc_nolc_found_m2_')} <strong>{search}</strong></p>
                                                            }
                                                            <p>{t('_explorelc_createlc_msg_')}</p>
                                                            <Link className="btn btn-success btn-circle mt-1" to="/dashboard/create-learning-circle">{t('_mylc_createlc_')}</Link>
                                                        </div>
                                                        <br />
                                                        <br />
                                                    </div>
                                                    {
                                                        typeof trending_groups !== 'undefined' && trending_groups.length > 0
                                                        &&
                                                        <>
                                                            <div className="releted-course course_slider_arrow">
                                                                <h3 className="text-left">{t('_trending_lc_')}</h3>
                                                                <div className="blog-card learning-circle-list-section">
                                                                    <Slider {...settings}>
                                                                        {
                                                                            trending_groups.map((group, index) =>
                                                                                <LearningCircleCard key={index} group={group} />
                                                                            )
                                                                        }
                                                                    </Slider>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        typeof high_rated_groups !== 'undefined' && high_rated_groups.length > 0
                                                        &&
                                                        <>
                                                            <div className="releted-course course_slider_arrow">
                                                                <h3 className="text-left">{t('_explorelc_high_rated_')}</h3>
                                                                <div className="blog-card learning-circle-list-section">
                                                                    <Slider {...settings}>
                                                                        {
                                                                            high_rated_groups.map((data, index) =>
                                                                                <LearningCircleCard key={index} group={data.group} rating={data.average} />
                                                                            )
                                                                        }
                                                                    </Slider>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </>
                            }
                        </div>
                        <div className="clearfix"></div>

                        {
                            total_count > coursePerPage
                            &&
                            <div className="custom-pagination">
                                <nav aria-label="Page navigation example">
                                    <CustomPagination
                                        pageCount={total_count}
                                        perPageRecord={coursePerPage}
                                        handlePageClick={handlePaginationPageClick}
                                        currentPage={currentPage}
                                    />
                                </nav>
                            </div>
                        }
                    </div>
                    <div className="clearfix"></div>
                </div>
            </section>
            <div className="clearfix"></div>
        </>
    );
}

export default ExploreLearningCircles;
