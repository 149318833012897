import { useLayoutEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import parse from 'html-react-parser';
import VerifiedBadge from "../../components/VerifiedBadge";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const AnnouncementDetail = () => {
    const [ansmnt, setAnsmnt] = useState('');
    const [latestAnsmnt, setLatestAnsmnt] = useState('');
    const { announcementId } = useParams();
    const _token = localStorage.getItem('lmstoken');
    const { t } = useTranslation();

    useLayoutEffect(() => {
        fetchAnnouncement();
    }, []);

    const fetchAnnouncement = async () => {
        const response = await fetch(API_BASE + 'get-announcement-detail/' + announcementId, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setAnsmnt(data.data.announcement);
            setLatestAnsmnt(data.data.latest_announcements);
        }
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_dh_announcements_')}`}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="custom-learnig-circle-head "><Link className="btn btn-circle btn-success" to={'/dashboard'}>{t('_managelc_back_to_dashboard_')} </Link></div>
                <div className="row">
                    <div className="col-lg-8">
                        <div className="custom-user-profile-content">
                            <div className="course-details-tab-block explore-block feed_page">
                                <div className="feed_post">
                                    <div className="custom-learnig-circle-head">
                                        <div className="feed_prof_icon">
                                            <div className="img">
                                                <img src="images/logo_icon.png" alt="" />
                                            </div>
                                            <h3><a>EduKula</a><VerifiedBadge _is_verified={true} refname="notification" /> <span>{ansmnt?.date_time}</span></h3>
                                        </div>
                                        <div className="caption">
                                            <h5>{ansmnt?.title}</h5>
                                            <img src={ansmnt?.image} alt="Course Image" />
                                            {(ansmnt.description != '' && typeof ansmnt.description !== 'undefined') ? parse(ansmnt?.description) : ''}
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="custom-user-profile-content sticky-top">
                            <div className="user-right-section">
                                <div className="user-right-header">
                                    <h5>{t('_dh_announcements_latest_anno_')}</h5>
                                </div>
                                <div className="user-right-body">
                                    <ul className="listing">
                                        {
                                            latestAnsmnt !== '' && latestAnsmnt !== undefined ?
                                                latestAnsmnt.map((ansmnt, index) =>
                                                    <li key={index}>
                                                        <Link className="link" to={'/dashboard/announcement-details/' + ansmnt?.id} target="_blank">{ansmnt?.title}</Link>
                                                    </li>
                                                )
                                                :
                                                <li>
                                                    {t('_dh_announcementstab_')}
                                                </li>

                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AnnouncementDetail;
