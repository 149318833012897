import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import VacancyListSkeleton from "../../components/skeletons/VacancyListSkeleton";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import { API_BASE } from "../../constants/global";
import CustomPagination from "../../entity/CustomPagination";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import Joyride from 'react-joyride';
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const VacancyListing = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const navigate = useNavigate();
    const urlState = location.state;

    const _token = localStorage.getItem('lmstoken');

    const [vacancies, setVacancies] = useState('');
    const [loading, setLoading] = useState(false);
    const [total_count, setTotalCount] = useState(0);
    const [existingShow, setExistingShow] = useState(false);

    const [course_id, setCourseId] = useState('');
    const [vacancy_id, setVacancyId] = useState('');
    const [course_title, setCourseTitle] = useState('');
    const [vacancy_title, setVacancyTitle] = useState('');
    const [tour_steps, setTourSteps] = useState([]);
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const { t } = useTranslation();

    useEffect(() => {
        fetchVacancies(currentPage);

        if (urlState !== null && urlState.is_from === 'created_course') {
            document.getElementById('VacancyToInviteButton').click();
        }

        showVacancyTour();
    }, [location, reload]);

    const showVacancyTour = () => {
        let show_tour = Cookies.get("show_vacancy_tour");
        if (show_tour != 'NO') {
            SwalBox.fire({
                allowOutsideClick: false,
                title: t('_show_tour_maintitle_'),
                text: t('_show_tour_vacancy_'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: t('_show_tour_yes_'),
                cancelButtonText: t('_show_tour_no_')
            }).then((result) => {
                if (result.isConfirmed) {
                    startVacancyTour();
                } else {
                    Cookies.set('show_vacancy_tour', 'NO');
                }
            });
        }
    }
    const startVacancyTour = () => {
        const ek_manage_vacancies = localStorage.getItem('manage_vacancies');

        let steps = [];
        if (user?.is_corporate === 1 || ek_manage_vacancies == 'YES') {
            steps = [
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-vacancy-step-01', title: t('_vacancy_tour_t1_'), content: t('_vacancy_tour_m1_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-vacancy-step-02', title: t('_vacancy_tour_t2_'), content: t('_vacancy_tour_m2_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-vacancy-step-03', title: t('_vacancy_tour_t3_'), content: t('_vacancy_tour_m3_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-vacancy-step-04', title: t('_vacancy_tour_t4_'), content: t('_vacancy_tour_m4_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-vacancy-step-05', title: t('_vacancy_tour_t5_'), content: t('_vacancy_tour_m5_'), },
            ];
        } else {
            steps = [
            ];
        }
        setTourSteps(steps);
        Cookies.set('show_vacancy_tour', 'NO');
    }

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }

    const fetchVacancies = async (page = 0) => {
        setLoading(true);
        const response = await fetch(`${API_BASE}vacancy/get?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            setVacancies(data.data.vacancies);
            setTotalCount(data.data.total_count);
        } else {
            Toaster.error(data.message);
            setTotalCount(0);
        }

        setLoading(false);
    }

    const ConfirmStatusChange = (e, vacancy_id, course_status) => {
        var statusEnable = e.target.checked;
        var textT = statusEnable ?
            t('_vacancy_alert_m1_')
            :
            t('_vacancy_alert_m2_');

        if (course_status === 0) {
            textT = `${t('_vacancy_alert_m3_')} <br/> <strong> ${t('_vacancy_alert_m4_')} </strong>`;
        }

        SwalBox.fire({
            title: (course_status === 0) ? t('_vacancy_alert_m5_') : t('_v_delete_confirm_'),
            html: textT,
            icon: (course_status === 0) ? 'error' : 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_cancel_')
        }).then((result) => {
            if (result.isConfirmed) {
                (course_status === 0)
                    ?
                    navigate(`/dashboard/vacancy/edit/${vacancy_id}`)
                    :
                    ChangeStatus(statusEnable, vacancy_id);
            }
        });
    }

    const ChangeStatus = async (status, vacancy_id) => {
        const response = await fetch(`${API_BASE}vacancy/status/${vacancy_id}/${status ? 1 : 2}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            setReload(!reload);
        } else {
            Toaster.error(data.message);
        }
    }

    const ConfirmDeleteVacancy = (vacancy_id) => {
        SwalBox.fire({
            title: t('_v_delete_confirm_'),
            text: t('_v_delete_confirm2_'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_lp_no_')
        }).then((result) => {
            if (result.isConfirmed) {
                deleteVacancy(vacancy_id);
            }
        });
    }

    const deleteVacancy = async (id) => {
        const response = await fetch(`${API_BASE}vacancy/delete/${id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(t('_v_delete_sucees_m1_'));
            fetchVacancies();
        } else {
            Toaster.error(data.message);
        }
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_v_vacancies_')}`}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content chatPage vacancypage">
                    <div className="custom-learnig-circle-head">
                        <h4>{t('_v_vacancies_')} </h4>
                        <Link to={'/dashboard/vacancy/form'} className="btn btn-circle btn-info tour-vacancy-step-01">{t('_v_vacancies_add_')}</Link>
                    </div>
                    <div className="chatbox">
                        <div className="vacancy_list">
                            {
                                loading
                                    ?
                                    <VacancyListSkeleton count={4} />
                                    :
                                    <>
                                        {
                                            (vacancies !== '' && vacancies !== null && typeof vacancies !== 'undefined')
                                                ?
                                                vacancies.map((vacancy, index) =>
                                                    <div key={index} className="join-invite-list">
                                                        <div className="join-invite-user">
                                                            <div className="join-invite-content pl-2 rounded-left" style={{ borderLeft: "3px solid #ff3a43" }}>
                                                                <p className="mb-0"><strong>{vacancy.job_position}</strong></p>
                                                                <p className="mb-0">{t('_v_course_')} <span>{vacancy.course_title}</span></p>
                                                                <p>{t('_v_experience_')}<span>{vacancy.experience}</span></p>
                                                            </div>

                                                        </div>
                                                        <div className="join-invite-action">
                                                            <span className="tour-vacancy-step-02" title={(vacancy?.status === 1 && vacancy?.course_status === 1) ? 'Active' : 'Inactive'}>
                                                                <label className="switch switch-1-1" htmlFor={`status${index}`}>
                                                                    <input type="checkbox" onChange={(e) => { ConfirmStatusChange(e, vacancy?.vacancy_id, vacancy?.course_status) }} checked={(vacancy?.status === 1 && vacancy?.course_status === 1) ? 'checked' : ''} name="switch-1-1" id={`status${index}`} />
                                                                    <span className="slider round slider-1-1"></span>
                                                                </label>
                                                            </span>
                                                            <button onClick={(e) => {
                                                                setExistingShow(true);
                                                                setCourseId(vacancy.course_id);
                                                                setVacancyId(vacancy.vacancy_id);
                                                                setCourseTitle(vacancy.course_title);
                                                                setVacancyTitle(vacancy.job_position);
                                                            }} className="btn btn-circle btn-info tour-vacancy-step-03">{t('_v_invite_talent_')}</button>
                                                            <Link to={`/dashboard/vacancy/detail/${vacancy.vacancy_id}`} className="btn btn-circle btn-warning mr-2 ml-2 tour-vacancy-step-04">{t('_v_detail_')}</Link>
                                                            <button onClick={(e) => { ConfirmDeleteVacancy(vacancy.vacancy_id) }} className="btn btn-circle btn-info tour-vacancy-step-05">{t('_v_remove_')}</button>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                <div className="col-12">
                                                    <div className="empty_state">
                                                        <h3>{t('_v_no_vacancy_')}</h3>
                                                        <img alt="" src="images/no_courses.png" />
                                                        <p>
                                                            {t('_v_course_not_create_')}
                                                        </p>
                                                    </div>
                                                </div>
                                        }
                                    </>
                            }
                        </div>

                        {
                            total_count > 5
                            &&
                            <div className="custom-pagination">
                                <nav aria-label="Page navigation example">
                                    <CustomPagination pageCount={total_count} perPageRecord={5} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                                </nav>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <a className="btn ml-2 mb-2 d-none" data-toggle="modal" id="VacancyToInviteButton" data-target="#VacancyToInviteModal">Add</a>
            <div className="modal invitePopup header_red fade" id="VacancyToInviteModal" tabIndex="-1" role="dialog" aria-labelledby="VacancyToInviteModalTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button onClick={() => { document.getElementById('VacancyToInviteModalClose').click(); navigate(`/dashboard/vacancy`) }} type="button" id="VacancyToInviteModalClose" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <div className="blog-card">
                                <p>{t('_v_list_msg_m1_')}</p>
                                <br />
                                <p>{t('_v_list_msg_m2_')} </p>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <button onClick={() => {
                                            document.getElementById('VacancyToInviteModalClose').click();
                                            setExistingShow(true);
                                            setCourseId(urlState.invite.course_id);
                                            setVacancyId(urlState.invite.vacancy_id);
                                            setCourseTitle(urlState.invite.course_title);
                                            setVacancyTitle(urlState.invite.vacancy_title);
                                        }} className="btn btn-circle btn-info w-100 mb-2 mr-2">{t('_v_list_msg_m3_')} </button>
                                    </div>
                                    <div className="col-lg-6">
                                        <button onClick={() => { document.getElementById('VacancyToInviteModalClose').click(); navigate(`/dashboard/vacancy`) }} className="btn btn-circle btn-success w-100 m-auto">{t('_couseiteach_draft_main_msg_m6_')} </button>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>

            <InviteTalent
                show={existingShow}
                onHide={() => setExistingShow(false)}
                course_id={course_id}
                vacancy_id={vacancy_id}
                course_title={course_title}
                vacancy_title={vacancy_title}
            />

            {
                tour_steps.length > 0
                &&
                <Joyride
                    steps={tour_steps}
                    disableCloseOnEsc={true}
                    disableOverlayClose={true}
                    showSkipButton={true}
                    continuous={true}
                    hideCloseButton={true}
                    locale={{ back: t('_tour_back_'), close: t('_tour_close_'), last: t('_tour_last_'), next: t('_tour_next_'), open: t('_tour_open_dialog_'), skip: t('_tour_skip_') }}
                    styles={{ options: { width: 500, zIndex: 5000, primaryColor: '#31314c', textColor: '#ef4a45', overlayColor: 'rgb(2 1 0 / 69%)', } }}
                />
            }


        </>
    )
}

export default VacancyListing;

const InviteTalent = (props) => {
    const { register, handleSubmit, formState: { errors }, setValue, setError, setFocus } = useForm();
    const [emails, setEmails] = useState([]);
    const [processing, setProcessing] = useState(false);
    const { t } = useTranslation();

    var invite_msg = `Join the ###COURSE_NAME### course on EduKula, which covers the skills and knowledge required for the ###VACANCY_NAME### position. Completing this course will increase your chances of getting hired for the vacancy.`;
    var invite_msg = invite_msg.replace(`###COURSE_NAME###`, props.course_title);
    var invite_msg = invite_msg.replace(`###VACANCY_NAME###`, props.vacancy_title);

    const _token = localStorage.getItem('lmstoken');

    const onSubmit = async (data) => {
        if (emails.length >= 30) {
            setError("email", { type: "focus", message: t('_managelc_invite_email_vali_m31_') }, { shouldFocus: false });
            return false;
        }

        setProcessing(true);
        //var iEmail = data.email;
        var _email_string = data.email;
        var _remove_all_spaces = _email_string.replace(/\s\s+/g, ' ');
        var _convert_space_in_coma = _remove_all_spaces.split(/[ ,]+/).join(',');
        var iEmail = _convert_space_in_coma.trim();
        var comEmails = iEmail.split(",");

        if (typeof comEmails !== 'undefined' && comEmails.length > 1) {
            var duplicate = false;
            var checkEmails = [];
            for (let index = 0; index < comEmails.length; index++) {
                const sepEmail = comEmails[index].trim();

                if (emails.indexOf(sepEmail) === -1) {
                    checkEmails.push(sepEmail);
                } else {
                    duplicate = true;
                }
            }

            // checkEmails = checkEmails.join(",");

            // const checkAPI = async () => {
            //     const formData = new FormData();
            //     formData.append('email', checkEmails);
            //     var sURL = `${API_BASE}employee/check-emails-exists`;
            //     const response = await fetch(sURL, {
            //         method: 'POST',
            //         headers: {
            //             "Authorization": `Bearer ${_token}`
            //         },
            //         body: formData
            //     });

            //     const rdata = await response.json();
            //     if (rdata.status === true) {
            //         return rdata.data.emails;
            //     }
            // }

            const callAsync = async () => {
                // const newEmails = await checkAPI();
                const newList = [...emails, ...checkEmails];
                setEmails(newList.splice(30));
                setEmails(newList);
            }

            callAsync();

            if (duplicate) {
                Toaster.info(t('_managelc_invite_email_vali_m32_'));
            }

            setValue('email', '');
            setFocus('email');
            setProcessing(false);
        } else {
            if (emails.indexOf(iEmail) !== -1) {
                setError("email", { type: "focus", message: t('_managelc_invite_email_vali_m33_') }, { shouldFocus: true });
                setProcessing(false);
                return false;
            }

            const formData = new FormData();
            formData.append('email', iEmail);
            var sURL = `${API_BASE}employee/check-email-exists`;
            const response = await fetch(sURL, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData
            });

            const rdata = await response.json();
            if (rdata.status && rdata.data.exists === true) {
                setError("email", { type: "focus", message: t('_managelc_invite_email_vali_m34_') }, { shouldFocus: true });
                setProcessing(false);
                return false;
            }

            setEmails([...emails, data.email]);
            setValue('email', '');
            setFocus('email');
            setProcessing(false);
        }
    }

    const removeItem = async (e, item) => {
        const newEmails = [];

        emails.forEach((email, index) => {
            if (item !== email) {
                newEmails.push(email);
            }
        });

        setEmails(newEmails);
    }

    const sendInvitation = async () => {
        const message = document.getElementById('invite_message_box').value;
        var formData = new FormData();
        for (var i = 0; i < emails.length; i++) {
            formData.append('members[]', emails[i]);
        }
        formData.append('message', message);
        formData.append('course_id', props.course_id);
        formData.append('vacancy_id', props.vacancy_id);
        setProcessing(true);
        var sURL = `${API_BASE}vacancy/invite-to-course`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            Toaster.success(rdata.message);
            setEmails([]);
            setValue('email', '');
            setProcessing(false);
            props.onHide();
        } else {
            Toaster.error(rdata.message);
            setProcessing(false);
        }
    }

    return (
        <>
            <Modal
                className="invitePopup header_red"
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <h4>{t('_v_invite_email_')} </h4>
                    <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
                </Modal.Header>
                <Modal.Body className="scroll_add">
                    <div className="custom-login-box">
                        <div className="invitepop">
                            <strong>{t('_v_invite_msg_')} </strong>
                            <textarea className="form-control" id="invite_message_box" placeholder={t('_managelc_email_invitation_msg_place_')} defaultValue={invite_msg}></textarea>
                            <br /><strong>{t('_v_invite_add_emails_')}</strong>
                            <p>{t('_v_invite_limit_')}</p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="email_box">
                                    <input
                                        {...register("email", {
                                            required: "required",
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: t('_hr_email_error_msg_')
                                            }
                                        })}
                                        type="text"
                                        placeholder={t('_v_invite_email_place_')}
                                    />
                                    <button disabled={processing} className="btn btn-circle btn-info ml-2 add_btn">{
                                        processing
                                        &&
                                        <i className="fa fa-refresh fa-spin fa-fw"></i>
                                    }
                                        {
                                            !processing
                                            &&
                                            t('_v_invite_email_add_')
                                        }</button>
                                </div>
                                <span className="text-danger">
                                    {errors.email && errors.email.type === "required" && t('_managelc_invite_email_vali_m1_	')}
                                    {errors.email && errors.email.type !== "required" && errors.email.message}
                                </span>
                            </form>
                            {
                                emails.length > 0
                                &&
                                <>
                                    <div className='emailList_box'>
                                        <ul className="mt-3">
                                            {
                                                emails.map((email, index) =>
                                                    <li key={index} className="border px-2 py-1 mt-1 bg-white">
                                                        <span>{email}</span>
                                                        <i onClick={(e) => { removeItem(e, email) }} className="fa fa-times text-danger float-right mt-1" title="Close" aria-hidden="true"></i>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </>
                            }
                            <div className="btn_right pb-0">
                                {
                                    emails.length > 0
                                    &&
                                    <button type="submit" className="btn btn-circle btn-md submit-pink-btn" disabled={processing} onClick={(e) => { sendInvitation() }}>
                                        {
                                            processing
                                            &&
                                            <i className="fa fa-refresh fa-spin fa-fw"></i>
                                        }
                                        {
                                            !processing
                                            &&
                                            t('_v_invite_invite_')
                                        }
                                    </button>
                                }
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
