import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";


const CustomPagination = (props) => {
    let pageCount = Math.ceil(props.pageCount / props.perPageRecord);
    const { t } = useTranslation();

    return (
        <>
            <ReactPaginate
                forcePage={props.currentPage ? parseInt(props.currentPage) : 0}
                previousLabel={`<< ${t('_custom_pagi_previous_')}`}
                nextLabel={`${t('_custom_pagi_next_')} >>`}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={props.handlePageClick}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link btn-circle'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link font-weight-bold'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link font-weight-bold'}
                activeLinkClassName={'active'}
            />
        </>
    );
}

export default CustomPagination;
