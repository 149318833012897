import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Outlet, useLocation } from "react-router-dom";
import { API_BASE, IMAGE_EXT, IMAGE_TXT } from "../constants/global";
import authContext from "../context/auth/authContext";
import { SwalBox, Toaster } from "../entity/GlobalJS";
import Links from "./Links";
import ImageCropper from "../personal_profile/Tabs/ImageCropper";
import VerifiedBadge from "../components/VerifiedBadge";
import { useTranslation } from "react-i18next";

const LayoutDashboard = () => {
    const location = useLocation();
    const _token = localStorage.getItem('lmstoken');
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const cntxAuth = useContext(authContext);
    const headline = user?.headline;
    const [parent_class, setParentClass] = useState('student_dashboard');
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [user_photo, setPhoto] = useState(cntxAuth.userInfo.cover);
    const [prev, setPrev] = useState(false);
    const [notificationCount, setNotificationCount] = useState('');
    const [new_photo, setNewPhoto] = useState(cntxAuth.userInfo.cover);
    const [cropcpverbtnstatus, setCropcoverbtnstatus] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        try {
            let sidebar = document.querySelector(".sidebar");
            let sidebarBtn = document.querySelector(".bx-menu");
            if (sidebar && sidebarBtn) {
                //console.log(sidebarBtn);
                sidebarBtn.addEventListener("click", () => {
                    sidebar.classList.toggle("close");
                });
            }
        } catch (e) { }
        fetchUnreadNotificationCount();
        return () => {

        }
    }, []);

    useLayoutEffect(() => {
        const docker = document.getElementById("sidebar-docker");
        const hasDocker = (docker.className.indexOf("close") === -1);
        const onWidth = (window.innerWidth < 990 && hasDocker) ? 720 : 320;
        const scrollTo = (location.pathname === '/dashboard/home') ? 0 : onWidth; // for resposive 720
        window.scrollTo(0, scrollTo);
    }, [location]);

    const prevCover = (e) => {
        var filePath = e.target.value;
        if (!IMAGE_EXT.exec(filePath)) {
            Toaster.error(IMAGE_TXT);
            setValue('cover');
            setPrev(false);
            return false;
        }
        setPrev(true);
        setPhoto(URL.createObjectURL(e.target.files[0]));
        validateCoverImage(e.target.files[0]);
    }

    const saveProfileCover = async () => {
        const formData = new FormData();
        if (new_photo !== '' && typeof new_photo !== 'undefined') {
            formData.append("cover", new_photo);
        }

        const response = await fetch(`${API_BASE}post-update-cover`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            setValue('cover');
            setPrev(false);
            setPhoto(data?.data?.user?.cover);
            cntxAuth.updateUser(data?.data?.user);
            localStorage.setItem('lmsuser', JSON.stringify(data?.data?.user));
            Toaster.success(data.message);
        } else {
            Toaster.error(data.message);
        }
    }

    const fetchUnreadNotificationCount = async () => {
        const response = await fetch(`${API_BASE}get-unread-notification-count`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status && data?.data) {
            setNotificationCount(data?.data?.all_count);
        }
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const validateCoverImage = async (cimage) => {
        setCropcoverbtnstatus('disabled')
        const formData = new FormData();

        const base64 = await convertToBase64(cimage);
        formData.append("base64", base64);
        formData.append("mediatype", cimage.type);
        formData.append("image", cimage);
        const response = await fetch(API_BASE + 'validate-uploaded-image', {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            var _is_valid = data.data;
            if (_is_valid != "") {
                SwalBox.fire({
                    title: t('_ekyc_vali_msg3_'),
                    text: `${t('_ekyc_vali_msg4_')} + ` ` + ${_is_valid} + ` ` , ${t('_ekyc_vali_msg5_')}`,
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('_mylc_ok_'),
                });
                setTimeout(function () {
                    setValue('cover');
                    setPrev(false);
                    setPhoto(cntxAuth.userInfo.cover);
                }, 1000)
            } else {
                setCropcoverbtnstatus('');
            }
        } else {
            setCropcoverbtnstatus('');
        }
    }
    return (
        <>
            {
                cntxAuth.userInfo !== ''
                &&
                <>
                    <section className="new_profile_section">
                        <div className="profile-top-banner">
                            <form onSubmit={handleSubmit(saveProfileCover)}>
                                <input type="file" {...register("cover")} id="control_photo" className="d-none" accept="image/*" onChange={prevCover} />
                                <img src={user_photo} className="img-fluid w-100" alt={`${cntxAuth.userInfo.first_name} ${(cntxAuth?.userInfo?.last_name !== 'null' && cntxAuth?.userInfo?.last_name !== null) ? cntxAuth?.userInfo?.last_name : ''}`} />
                                <div className="edit_user_banner">
                                    {
                                        !prev
                                        &&
                                        <a onClick={(e) => { document.getElementById('control_photo').click(); }} className="edit_btn cursor-pointer"><i className="fa fa-edit" aria-hidden="true"></i></a>
                                    }
                                    {
                                        prev
                                        &&
                                        <>
                                            <button type="submit"><i className="fa fa-check"></i></button>
                                            <a onClick={(e) => { setPrev(false); setPhoto(cntxAuth.userInfo.cover); setValue('cover'); }}><i className="fa fa-undo"></i></a>
                                            <ImageCropper photo={user_photo} setPhotoToShow={setPhoto} show_modal={prev} setPhotoToUpload={setNewPhoto} src={'Cover'} />
                                        </>
                                    }
                                </div>
                            </form>
                        </div>

                    </section>

                    <br />

                    <section className="custom-section">
                        <div className={"left_collaps profile_user_middle_section " + parent_class}>
                            <div className="container">
                                <div className="row">
                                    <div id="sidebar-docker" className="col-lg-3 sidebar">
                                        <div className="profile-top-desc ">
                                            <div className="profile-top-block">
                                                <div className="proflie-user-img rounded-circle">
                                                    <img src={cntxAuth.userInfo.image} className="rounded-circle" alt={`${cntxAuth.userInfo.first_name} ${(cntxAuth?.userInfo?.last_name !== 'null' && cntxAuth?.userInfo?.last_name !== null) ? cntxAuth?.userInfo?.last_name : ''}`} />
                                                </div>
                                            </div>

                                            <div className="profile-top-block">
                                                <h3>{cntxAuth.userInfo.first_name} {(cntxAuth?.userInfo?.last_name !== 'null' && cntxAuth?.userInfo?.last_name !== null) ? cntxAuth?.userInfo?.last_name : ''} <VerifiedBadge _is_verified="" refname="dashabord" /></h3>
                                                <p>{headline}</p>
                                            </div>
                                        </div>
                                        <i className="bx-menu fa fa-bars tour-cr-step-02 tour-lr-step-02"></i>
                                        <div className="profile-dashboard">
                                            <div className="profile-info">
                                                <div className="nav flex-column nav-pills">
                                                    <Links notificationCount={notificationCount} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Outlet context={[fetchUnreadNotificationCount]} />
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }
        </>
    );
}

export default LayoutDashboard;
