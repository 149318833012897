import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
// import { VacancySchema } from "./schemas";
import { API_BASE } from "../../constants/global";
import { Toaster, SwalBox } from "../../entity/GlobalJS";
import { useState, useEffect } from "react";
import TinyMCEEditor from "../../entity/TinyMCEEditor";
import { Helmet } from "react-helmet";
import EduKulaAi from "../../entity/EdukulaAi";

import { useTranslation } from "react-i18next";
import * as Yup from "yup";



const VacancyForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlState = location.state;

    const { vacancy_id } = useParams();
    const _token = localStorage.getItem('lmstoken');

    const [courses, setCourses] = useState('');
    const [edit, setEdit] = useState('');
    const [description, setDescription] = useState('');
    const [processing, setProcessing] = useState(false);
    const [coursename, setCoursename] = useState('');

    let is_for_corporate = false;
    const manage_vacancies = localStorage.getItem('manage_vacancies');
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const { t } = useTranslation();

    const VacancySchema = Yup.object({
        job_position: Yup.string().required(t('_vacancy_vali_m1_')),
        course_id: Yup.string().required(t('_vacancy_vali_m2_')),
        experience: Yup.string().max(50, t('_vacancy_vali_m3_')).required(t('_vacancy_vali_m4_')),
        // description: Yup.string().required("Please enter description"),
    });

    if (typeof manage_vacancies !== 'undefined' && manage_vacancies !== null && user?.is_corporate !== 1) {
        is_for_corporate = true;
    }

    const initialValues = {
        job_position: "",
        course_id: "",
        experience: "",
        is_corporate_course: `${is_for_corporate}`,
    }


    const [formValues, setFormValues] = useState({
        job_position: "",
        course_id: "",
        experience: "",
        is_corporate_course: `${is_for_corporate}`,
    });

    useEffect(() => {
        fetchCourses();
        if (vacancy_id !== '' && vacancy_id !== null && typeof vacancy_id !== 'undefined') {
            fetchVacancy(vacancy_id);
            setEdit(vacancy_id)
        } else {
            setEdit('')
            if (urlState !== null) {
                setFormValues({ ...formValues, course_id: urlState?.course_slug });
                setCoursename(urlState?.course_slug);
            }
        }
    }, []);


    const fetchVacancy = async (vacancy_id) => {
        const response = await fetch(`${API_BASE}vacancy/detail/${vacancy_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            if (data.data.vacancy) {
                setFormValues(
                    {
                        job_position: data.data.vacancy.job_position,
                        course_id: data.data.vacancy.course_id,
                        experience: data.data.vacancy.experience,
                    }
                );
                setDescription(data.data.vacancy.description)
                // initialValues = formValues;
            }
        } else if (data.message === "Server Error") {
            SwalBox.fire({
                title: "Link is invalid",
                text: "It seems that the link is not valid. Please try again.",
                icon: "error",
            });
            setTimeout(() => {
                SwalBox.close();
            }, 3000);
            navigate('/dashboard/vacancy');
        }
    }

    const fetchCourses = async () => {
        const sURL = typeof vacancy_id !== 'undefined' ? `${API_BASE}vacancy/courses/${vacancy_id}` : `${API_BASE}vacancy/courses`;
        const response = await fetch(sURL, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            setCourses(data.data.courses);
        } else {
            if (data.data.count == 0) {
                setCourses('');
            }
        }
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        enableReinitialize: true,
        initialValues: formValues ? formValues : initialValues,
        validationSchema: VacancySchema,
        onSubmit: async (values, action) => {
            setProcessing(true);
            values.description = description;
            values.is_corporate_course = is_for_corporate;

            let url = `${API_BASE}vacancy/save`;

            if (vacancy_id !== '' && vacancy_id !== null && typeof vacancy_id !== 'undefined') {
                url = `${API_BASE}vacancy/save/${vacancy_id}`;
            }
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
                body: JSON.stringify(values),
            });

            const data = await response.json();
            if (data.status) {
                Toaster.success(data.message);
                action.resetForm();
                setProcessing(false);

                if (vacancy_id !== '' && vacancy_id !== null && typeof vacancy_id !== 'undefined') {
                    navigate('/dashboard/vacancy');
                } else {
                    navigate('/dashboard/vacancy', { state: { is_from: 'created_course', invite: data.data.invite } });
                }
            } else {
                Toaster.error(data.message);
                setProcessing(false);
            }
        }
    });

    const generateVacDes = async (val) => {
        if(val != "" && values.job_position != "" && coursename != ""){
            let _prompt_string = 'I want you to write a job description for me. Here is the basic details you need: 1) Job Position - '+values.job_position+' , 2) Prerequisite Course - '+coursename+', 3) Experience - '+val;
            try {
                SwalBox.fire({
                    title: 'Please wait...',
                    text:'System is generating Job Description for you... please wait',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    onBeforeOpen: () => {
                        SwalBox.showLoading();
                    }
                });
                
                EduKulaAi.createCompletion({
                    model: "text-davinci-003",
                    prompt: _prompt_string,
                    temperature: 0.1,
                    max_tokens: 4097-(_prompt_string.length),
                }).then((completion) => {
                    const responseText = completion.data.choices[0].text;
                    let  jdWithBreaks = responseText.replace(/\n/g, "<br />");
                    //if(jdWithBreaks == ""){
                        setDescription(jdWithBreaks);
                    //}
                    SwalBox.close();
                }).catch((error) => {
                    SwalBox.fire({
                        title: 'Something went wrong',
                        text: 'Please try again after some time.',
                        icon: 'error',
                    });
                    setProcessing(false);
                    SwalBox.close();
                });
            }catch(err) { SwalBox.close(); }
        }else{

            SwalBox.fire({
                title: 'Values can not be empty',
                text: 'Please fill-out the form. Job Position, Course & Experience can not be empty.',
                icon: 'error',
            });
            setProcessing(false);
            setTimeout(() => {
                SwalBox.close();
            }, 2000);
        }
    }
    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_v_vacancy_')}`}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        {
                            edit !== ''
                                ?
                                <h4>{t('_v_edit_vacancy_')}</h4>
                                :
                                <h4>{t('_v_add_vacancy_')} </h4>
                        }
                        <Link className="btn btn-circle btn-info" to={"/dashboard/vacancy"}>{t('_v_back_listing_')}</Link>
                    </div>

                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label><b>{t('_v_job_position_')}</b></label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                name="job_position"
                                                id="job_position"
                                                placeholder={t('_v_add_posi_')}
                                                className="form-control"
                                                autoFocus
                                                value={values.job_position}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {
                                                (errors.job_position && touched.job_position) &&
                                                <span className="text-danger">{errors.job_position}</span>
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label><b>{t('_v_add_prerequisite_')}</b></label>
                                            <p className="input-desc-class">{t('_v_add_pre_m1_')}</p>
                                            <select
                                                name="course_id"
                                                id="course_id"
                                                className="form-control select2"
                                                value={values.course_id}
                                                onChange={(e) => { setCoursename(e.target.options[e.target.selectedIndex].text); handleChange(e); }}
                                                onBlur={handleBlur}
                                                disabled={(urlState !== null)}
                                            >
                                                <option value="">{t('_v_select_course_')}</option>
                                                {
                                                    courses &&
                                                    courses.map((course, index) =>
                                                        <option key={index} value={course.slug}>{course.title}</option>
                                                    )
                                                }

                                            </select>
                                            {
                                                (errors.course_id && touched.course_id) &&
                                                <span className="text-danger">{errors.course_id}</span>
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label><b>{t('_v_add_experience_')}</b></label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                name="experience"
                                                id="experience"
                                                placeholder={t('_v_add_place_experience_')}
                                                className="form-control"
                                                value={values.experience}
                                                onChange={(e) => { handleChange(e); }}
                                                onBlur={handleBlur}
                                            />
                                            {
                                                (errors.experience && touched.experience) &&
                                                <span className="text-danger">{errors.experience}</span>
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label><b>{t('_v_job_des_')}</b></label><button className="btn btn-circle btn-md cancel-btn vacancyGenAI" type="button" onClick={generateVacDes}>{t('_vacancy_add_form_generate_btn_')}</button>
                                            <TinyMCEEditor
                                                name="description"
                                                id="description"
                                                placeholder={t('_v_des_plac1_')}
                                                className="form-control"
                                                value={description}
                                                onChange={(e) => { setDescription(e) }}
                                                onBlur={handleBlur}
                                            />
                                            {
                                                (errors.description && touched.description) &&
                                                <span className="text-danger">{errors.description}</span>
                                            }
                                        </div>
                                        <Link className="btn btn-circle btn-md cancel-btn" to={"/dashboard/vacancy"}>{t('_v_cancel_')} </Link>
                                        <button type="submit" disabled={processing} className="btn btn-circle btn-md submit-pink-btn">
                                            {
                                                processing
                                                    ?
                                                    <i className="fa fa-refresh fa-spin fa-fw"></i>
                                                    :
                                                    t('_v_add_submit_')
                                            }
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VacancyForm;
