import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import parse from 'html-react-parser';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Blogs = () => {
    const [blogs, setBlogs] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchBlogs();
        //eslint-disable-next-line
    }, []);

    async function fetchBlogs() {
        const response = await fetch(`${API_BASE}get-blogs`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setBlogs(data.data.blogs);
        } else {
            setBlogs('');
        }
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_footer_blogs_')}`}</title>
            </Helmet>
            <section className="custom-section">
                <div className="contact-banner">
                    <div className="container text-light text-center">
                        <h1>{t('_footer_blogs_')}</h1>
                    </div>
                </div>
            </section>

            <section className="custom-section">
                <div className="course-list custom-blog-list">
                    <div className="container">

                        <div className="blog-card">
                            <div className="row">
                                {
                                    blogs !== ''
                                    &&
                                    blogs.map((blog, index) =>
                                        blog.short_description !== null ?
                                            <div key={index} className="col-md-6 col-lg-3">
                                                <div className="blog-card-detail">
                                                    <div className="custom-card-img">
                                                        <img src={blog.image} className="card-img-top" alt={blog.title} />
                                                    </div>
                                                    <div className="blog-card-inner">
                                                        <span className="news-date">{blog.date}</span>
                                                        <h5><Link to={"/blog/" + blog.url}>{blog.title}</Link></h5>

                                                        {parse(blog.short_description !== null ? (blog.short_description) : "")}
                                                        <div className="blog-card-footer">
                                                            <Link to={"/blog/" + blog.url}><span>Read More</span></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            ''
                                    )
                                }

                                {
                                    blogs === ''
                                    &&
                                    <div className="text-center h3">{t('_blogs_msg_m1_')}</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default Blogs;
