import { useEffect } from "react";
import { useState } from "react";
import { API_BASE } from "../../constants/global";
import CompletedCourses from "./listings/CompletedCourses";
import OnGoingCourses from "./listings/OnGoingCourses";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Joyride from 'react-joyride';
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import { useTranslation } from "react-i18next";

const MyCourses = () => {
    const _token = localStorage.getItem('lmstoken');
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const [enrolled, setEnrolled] = useState(false);
    const [modes, setModes] = useState('');
    const [progs, setProgs] = useState('');
    const [tour_steps, setTourSteps] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (user?.is_corporate === 1) {
            navigate('/dashboard/home');
        }

        fetchIsEnrolled();
        showCourseTour();
    }, []);
    const showCourseTour = () => {
        let show_tour = Cookies.get("show_student_tour");
        if (show_tour != 'NO') {
            SwalBox.fire({
                allowOutsideClick: false,
                title: t('_show_tour_maintitle_'),
                text: t('_show_tour_course_'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: t('_show_tour_yes_'),
                cancelButtonText: t('_show_tour_no_')
            }).then((result) => {
                if (result.isConfirmed) {
                    startCourseTour();
                } else {
                    Cookies.set('show_student_tour', 'NO');
                }
            });
        }
    }
    const startCourseTour = () => {
        let steps = [];
        steps = [
            { placementBeacon: 'right', disableBeacon: true, target: '.tour-student-step-01', title: t('_mycourse_tour_t1_'), content: t('_mycourse_tour_m1_'), },
            { placementBeacon: 'right', disableBeacon: true, target: '.tour-student-step-02', title: t('_mycourse_tour_t2_'), content: t('_mycourse_tour_m2_'), },
            { placementBeacon: 'right', disableBeacon: true, target: '.tour-student-step-03', title: t('_mycourse_tour_t3_'), content: t('_mycourse_tour_m3_'), },
        ];
        setTourSteps(steps);
        Cookies.set('show_student_tour', 'NO');
    }
    const fetchIsEnrolled = async () => {
        const response = await fetch(`${API_BASE}get-student-count`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`

            },
        });

        const data = await response.json();
        if (data.status) {
            setEnrolled(data.data.count > 0 ? true : false);
            setModes(data.data.modes);
            setProgs(data.data.progs);
        } else {
            setEnrolled(false);
            setModes('');
            setProgs('');
        }
    }

    return (
        <>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        <h4>{t('_courseilearn_student_dash_')} </h4>
                    </div>

                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    {
                                        !enrolled
                                            ?
                                            <div className="empty_state">
                                                <h3>{t('_courseilearn_no_cour_')}</h3>
                                                <img alt="" src="images/no_courses.png" />
                                                <p>
                                                    {t('_coursedetail_nocourse_m2_')}
                                                    <br /><a href="/courses" className="link" >{t('_dashboard_home2_t11_')}</a>
                                                </p>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        enrolled
                                            ?
                                            <div className="explore-block">
                                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                    <li className="nav-item tour-student-step-01">
                                                        <NavLink to={`/dashboard/student/ongoing-courses`} className={`nav-link`} id="pills-course-tab" data-toggle="pill" href="#pills-course" role="tab" aria-controls="pills-course" aria-selected="true">{t('_mycourse_tour_t1_')} </NavLink>
                                                    </li>
                                                    <li className="nav-item tour-student-step-03">
                                                        <NavLink to={`/dashboard/student/completed-courses`} className={`nav-link`} id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">{t('_mycourse_tour_t3_')}</NavLink>
                                                    </li>
                                                </ul>
                                                <div className="tab-content" id="pills-tabContent">
                                                    <Outlet context={[modes, progs]} />
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {
                tour_steps.length > 0
                &&
                <Joyride
                    steps={tour_steps}
                    disableCloseOnEsc={true}
                    disableOverlayClose={true}
                    showSkipButton={true}
                    continuous={true}
                    hideCloseButton={true}
                    locale={{ back: t('_tour_back_'), close: t('_tour_close_'), last: t('_tour_last_'), next: t('_tour_next_'), open: t('_tour_open_dialog_'), skip: t('_tour_skip_') }}
                    styles={{ options: { width: 500, zIndex: 5000, primaryColor: '#31314c', textColor: '#ef4a45', overlayColor: 'rgb(2 1 0 / 69%)', } }}
                />
            }
        </>
    );
}

export default MyCourses;
