import { useEffect, useState } from "react";
import Slider from "react-slick";
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import parse from 'html-react-parser';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const AboutUs = () => {
    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const [testimonials, setTestimonials] = useState("");
    const [trusted_bys, setTrustedBy] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        fetchTestimonial();
        fetchTrustedBy();
        //eslint-disable-next-line
        window.scrollTo(0, 0);
    }, []);

    async function fetchTestimonial() {
        const response = await fetch(API_BASE + 'get-testimonials', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setTestimonials(data.data.testimonials);
        } else {
            Toaster.error(data.message);
        }
    }

    async function fetchTrustedBy() {
        const response = await fetch(API_BASE + 'get-trusted-bys', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setTrustedBy(data.data.trusted_by);
        } else {
            Toaster.error(data.message);
        }
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_footer_about_us_')}`}</title>
            </Helmet>
            <section className="custom-section">
                <div className="about-banner-section">
                    <div className="container">
                        <div className="about-banner-block">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="about-banner-info">
                                        <h1>{t('_footer_about_us_')} </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="custom-section">
                <div className="teaching-about">
                    <div className="container">
                        <div className="teaching-about-info text-center">
                            <h1>{t('_aboutus_msg_m1_')}</h1>
                            <p>{t('_aboutus_msg_m2_')}</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* PROGRESS COUTNS SECTION */}
            <section className="hide">
                <div className="teaching-view-section">
                    <div className="container">
                        <div className="teaching-view-info text-light text-center">
                            <div className="teaching-view-content">
                                <h3>{t('_aboutus_msg_m3_')} </h3>
                                <p>{t('_lcdetail_std_')} </p>
                            </div>
                            <div className="teaching-view-content">
                                <h3>65+</h3>
                                <p>{t('_aboutus_msg_m4_')} </p>
                            </div>
                            <div className="teaching-view-content">
                                <h3>{t('_aboutus_msg_m5_')} </h3>
                                <p>{t('_aboutus_msg_m6_')} </p>
                            </div>
                            <div className="teaching-view-content">
                                <h3>180+</h3>
                                <p>{t('_aboutus_msg_m7_')} </p>
                            </div>
                            <div className="teaching-view-content">
                                <h3>7,000+</h3>
                                <p>{t('_aboutus_msg_m8_')} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* PROGRESS COUTNS SECTION */}

            {/* TRUSTED BY SECTION */}
            {
                trusted_bys !== ""
                &&
                <section>
                    <div className="teaching-reward trusted-area">
                        <div className="container">
                            <div className="teaching-reward-section text-center">
                                <h1>{t('_aboutus_msg_m9_')} </h1>
                                <div className="row">
                                    {
                                        trusted_bys.map((trusted_by, index) =>
                                            <div key={index} className="col-md-3"><img className="w-100" src={trusted_by.image} alt={trusted_by.title} /></div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {/* TRUSTED BY SECTION */}

            {/* TESTIMONIAL SECTION */}
            {
                testimonials !== ""
                &&
                <section className=" custom-question-detail">
                    <div className="testimonial-section">
                        <div className="container">
                            <div className="testimonial-block text-white">
                                <Slider className="testimonial-info" {...settings}>
                                    {
                                        testimonials.map((testimonial, index) =>
                                            <div key={index} className="testimonial-slide text-center">
                                                <div className="testimonial-quote">
                                                    <i className="fas fa-quote-left"></i>
                                                    <div className="testi-details">{parse(testimonial.long_description)}</div>
                                                    <i className="fas fa-quote-right"></i>
                                                </div>
                                                <div className="testimonial-profile">
                                                    {/* <img src={testimonial.image} className="rounded-circle" alt="Testimonial Profile" /> */}
                                                    <h5>{testimonial.testimonial_by}</h5>
                                                    {/* <p>{testimonial.designation}</p> */}
                                                </div>
                                            </div>
                                        )
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {/* TESTIMONIAL SECTION */}
        </>
    );
}

export default AboutUs;
