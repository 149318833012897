import { useContext, useState, useEffect } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import authContext from "../../context/auth/authContext";
import { Toaster, SwalBox } from "../../entity/GlobalJS";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const ResponsibleAi = () => {
    const _token = localStorage.getItem('lmstoken');
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, watch, setValue } = useForm();
    const { register: register3, handleSubmit: handleSubmit3, formState: { errors: errors3 }, clearErrors, setError } = useForm();
    const user = JSON.parse(localStorage.getItem('lmsuser'));

    const [isCareerAdviceEnable, setCareerAdviceEnableOrNot] = useState(false);
    const [isSelfAssesementEnable, setSelfAssesementEnableOrNot] = useState(false);
    const [isVirtualTutorEnable, setVirtualTutorEnableOrNot] = useState(false);
    const [isSuggestedVideoEnable, setDSuggestedVideoEnableOrNot] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        getAiPreference();
        //eslint-disable-next-line
    }, []);
    const getAiPreference = async () => {
        let formData = {};
        const _lmsuser          = JSON.parse(localStorage.getItem('lmsuser'));
        const _lms_learner_id    = _lmsuser['id'];
        formData.user_id      = _lms_learner_id;
        formData.action      = "fetch";
        const response = await fetch(`${API_BASE}get-responsible-ai`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(formData)
        });
        const data = await response.json();
        if (data.status) {
            if(data.data.career_advice == "TRUE"){ setCareerAdviceEnableOrNot(true); }
            if(data.data.self_assesement == "TRUE"){ setSelfAssesementEnableOrNot(true);}
            if(data.data.virtual_tutor == "TRUE"){ setVirtualTutorEnableOrNot(true);}
            if(data.data.suggested_video == "TRUE"){ setDSuggestedVideoEnableOrNot(true); }
            //console.info(isSuggestedVideoEnable);
            //Toaster.success(data.message);
        } else {
            //Toaster.error(data.message);
        }

    }
    const saveAiPreference = async () => {
        let formData = {};
        const _lmsuser          = JSON.parse(localStorage.getItem('lmsuser'));
        const _lms_learner_id    = _lmsuser['id'];
        formData.user_id      = _lms_learner_id;
        formData.career_advice      = isCareerAdviceEnable;
        formData.self_assesement    = isSelfAssesementEnable;
        formData.virtual_tutor      = isVirtualTutorEnable;
        formData.suggested_video    = isSuggestedVideoEnable;
        const response = await fetch(`${API_BASE}responsible-ai`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(formData)
        });
        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
        } else {
            Toaster.error(data.message);
        }
    }


    const ConfirmCareerAdviceChange= (e) => {
        var featureEnable = e.target.checked;
        setCareerAdviceEnableOrNot(featureEnable);
    }
    const ConfirmSelfAssesementChange= (e) => {
        var featureEnable = e.target.checked;
        setSelfAssesementEnableOrNot(featureEnable);
    }
    const ConfirmVirtualTutorChange= (e) => {
        var featureEnable = e.target.checked;
        setVirtualTutorEnableOrNot(featureEnable);
    }
    const ConfirmSuggestedVideoChange= (e) => {
        var featureEnable = e.target.checked;
        setDSuggestedVideoEnableOrNot(featureEnable);
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_r_responsibleai_')}`}</title>
            </Helmet>
            <div className="tab-pane fade show active" id="v-pills-account" role="tabpanel" aria-labelledby="v-pills-account-tab">
                <div className="profile-dashboard-content">
                    <h4>{t('_r_responsibleai_')}</h4>
                    <div className="profile-dashboard-box">
                        <form onSubmit={handleSubmit(saveAiPreference)}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group ai-settings custom-user-profile-content">
                                        <p>{t('_r_mes1_')}<br></br>{t('_r_mes2_')}<br></br>{t('_r_mes3_')}</p>
                                        <p>&nbsp;</p>
                                        <h6>1.&nbsp;<span className="ek-res-ai-span">{(t('_r_careeradvice_'))}</span> <label className="switch switch-1-1"><input type="checkbox" onChange={(e) => { ConfirmCareerAdviceChange(e) }} checked={isCareerAdviceEnable ? 'checked' : ''} name="switch-1-1" id="switch-1-1" /><span className="slider round slider-1-1"></span></label>{isCareerAdviceEnable ? (t('_r_enabled_')) : (t('_r_disabled_'))}   </h6>
                                        <h6>2.&nbsp;<span className="ek-res-ai-span">{t('_r_selfassessment_')}</span> <label className="switch switch-1-1"><input type="checkbox" onChange={(e) => { ConfirmSelfAssesementChange(e) }} checked={isSelfAssesementEnable ? 'checked' : ''} name="switch-1-1" id="switch-1-1" /><span className="slider round slider-1-1"></span></label>{isSelfAssesementEnable ? (t('_r_enabled_')) : (t('_r_disabled_'))}   </h6>
                                        <h6>3.&nbsp;<span className="ek-res-ai-span">{t('_r_virtualtutor_')}</span> <label className="switch switch-1-1"><input type="checkbox" onChange={(e) => { ConfirmVirtualTutorChange(e) }} checked={isVirtualTutorEnable ? 'checked' : ''} name="switch-1-1" id="switch-1-1" /><span className="slider round slider-1-1"></span></label>{isVirtualTutorEnable ? (t('_r_enabled_')) : (t('_r_disabled_'))}   </h6>
                                        <h6>4.&nbsp;<span className="ek-res-ai-span">{t('_r_suggestedvideos_')}</span> <label className="switch switch-1-1"><input type="checkbox" onChange={(e) => { ConfirmSuggestedVideoChange(e) }} checked={isSuggestedVideoEnable ? 'checked' : ''} name="switch-1-1" id="switch-1-1" /><span className="slider round slider-1-1"></span></label>{isSuggestedVideoEnable ? (t('_r_enabled_')) : (t('_r_disabled_'))}   </h6>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-success btn-circle btn-md">{t('_save_')}</button>
                        </form>
                    </div>
                </div>
            </div>
            <button type="button" className="d-none" data-toggle="modal" id="openModalBtn" data-target="#verifyModalCenter"></button>
        </>
    );
}
export default ResponsibleAi;
