import { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useEffectOnce from "../../chat/useEffectOnce";
import { socket } from "../../constants/global";
import authContext from "../../context/auth/authContext";
import { Toaster } from "../../entity/GlobalJS";
import Cookies from "js-cookie";
const Logout = () => {
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    if (user) {
        socket.emit('logout', { userId: user.id });
    }
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    localStorage.setItem('lmsuser', JSON.stringify({}));
    localStorage.removeItem('lmstoken');
    localStorage.removeItem('lmsuser');
    localStorage.removeItem('lrsuser');
    localStorage.removeItem('profile');
    localStorage.removeItem('learningCircleUniqueId');
    localStorage.removeItem('enrolling');
    localStorage.removeItem('couid');
    localStorage.removeItem('uc');
    localStorage.removeItem('no_of_time_login');
    localStorage.removeItem('has_auto_password');
    localStorage.removeItem('DraftCourse');
    localStorage.removeItem('DCStep');
    localStorage.removeItem('cid');
    localStorage.removeItem('enrollLC');
    localStorage.removeItem('LCid');
    localStorage.removeItem('CorpPref');
    localStorage.removeItem('createLC');

    localStorage.removeItem('manage_employees');
    localStorage.removeItem('manage_roles');
    localStorage.removeItem('manage_reports');
    localStorage.removeItem('manage_courses');
    localStorage.removeItem('manage_vacancies');
    localStorage.removeItem('manage_circles');
    localStorage.removeItem('userlang');

    const location = useLocation();
    const urlState = location.state;

    useEffect(() => {
        cntxAuth.updateLogin(false);
        if (urlState !== null && urlState.is_from === 'reset-pass' && urlState.resetPasstoken !== null && urlState.resetPasstoken !== undefined) {
            navigate(`/reset-password/${urlState.resetPasstoken}`);
        } else if (urlState !== null && urlState.is_from === 'emp-invite' && urlState.empInviteID !== null && urlState.empInviteID !== undefined) {
            navigate(`/employee-invitation/${urlState.empInviteID}`);
        } else {
            navigate("/login");
        }
        //eslint-disable-next-line
    }, []);

    useEffectOnce(() => {
        Cookies.set('show_tour', '--');
        Toaster.success("You are successfully logged out.");
    })

    return (
        <></>
    );
}

export default Logout;
