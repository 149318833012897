import { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import InvitationSkeleton from "../../components/skeletons/InvitationSkeleton";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const PendingInvitation = (props) => {
    const [PendingInvitationCount] = useOutletContext();
    const _token = localStorage.getItem('lmstoken');
    const [groups, setGroups] = useState([]);
    const [page, setPage] = useState(1);
    const [all, setAll] = useState(false);
    const [isLoader, setIsLoader] = useState(true);
    const [pendingCount, setPendingCount] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        setPage(1 + 1);
        fetchGroups();
        // eslint-disable-next-line
    }, []);

    const fetchGroups = async () => {
        setIsLoader(true);
        const response = await fetch(API_BASE + 'pending-invitations?page=' + page, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        setIsLoader(false);
        if (data.status) {
            setGroups(data.data.groups);
            if (data.data.total === 1) {
                setAll(true);
            }
            setPendingCount(data?.data?.total_pending_count !== undefined ? data?.data?.total_pending_count : 0);
            PendingInvitationCount(data?.data?.total_pending_count !== undefined ? data?.data?.total_pending_count : 0);

        }
    }

    const acceptInvitation = async (id, flag) => {
        var sURL = `${API_BASE}accept-invitation/${id}/${flag}`;
        const response = await fetch(sURL, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + _token
            }
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
        } else {
            Toaster.error(data.message);
        }

        fetchGroups();
    }

    const showMore = async () => {
        setPage(page + 1);
        fetchGroups();
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_mylc_pending_invitation_head_')}`}</title>
            </Helmet>
            <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div className="join-invite-section">
                    <div className="join-invite-head">
                        {
                            pendingCount > 0
                            &&
                            <h3>{t('_mylc_pending_invitation_invitations_')} ({pendingCount})</h3>
                        }
                    </div>
                    <div className="join-invite-listing">
                        {
                            isLoader ?
                                <>
                                    <InvitationSkeleton count={4} />
                                </>
                                :
                                <>
                                    {
                                        typeof groups !== 'undefined' && groups.length > 0
                                        &&
                                        groups.map((group, index) =>
                                            <div key={index} className="join-invite-list">
                                                <div className="join-invite-user">
                                                    <div className="join-invite-avtar">
                                                        <img src={group.image} alt={group.title} />
                                                    </div>
                                                    <div className="join-invite-content">
                                                        <p><Link to={`/profile-details/${group.user_slug}`}>{group.invitor} </Link> {t('_mylc_pending_invitation_m1_')} <Link to={`/learning-circle-details/${group.unique_id}`} className="ek-lrs-module-launched"><strong>‘{group.title}’ </strong></Link> {t('_dh_learningcircle_')}.</p>
                                                    </div>
                                                </div>
                                                <div className="join-invite-action">
                                                    <button onClick={(e) => { acceptInvitation(group.id, true) }} className="btn btn-circle btn-warning mr-2">{t('_mylc_pending_invitation_join_')} </button>
                                                    <button onClick={(e) => { acceptInvitation(group.id, false) }} className="btn btn-circle btn-info">{t('_mylc_pending_invitation_ignore_')} </button>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        (typeof groups === 'undefined' || groups.length <= 0)
                                        &&
                                        <>
                                            <div className="col-12">
                                                <div className="empty_state">
                                                    <h3>{t('_mylc_pending_invitation_noinvite_')} </h3>
                                                    <img alt="" src="images/no_courses.png" />
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {
                                        all === false
                                        &&
                                        <div className="join-invite-more">
                                            <button onClick={(e) => { showMore() }} className="show-more-btn">{t('_mylc_showmore_')} </button>
                                        </div>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default PendingInvitation;
