import { useForm } from "react-hook-form";
import { API_BASE } from "../constants/global";
import authContext from '../context/auth/authContext';
import { useEffect, useState, useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { CircleBoxSkeleton } from "../components/skeletons";
import { SwalBox } from "../entity/GlobalJS";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const Home2 = () => {
    const [homePage, setHomePageContent] = useState('');
    const [search, setSearch] = useState('');
    const [isLoader, setIsLoader] = useState(true);
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const { t } = useTranslation();

    const _token = localStorage.getItem('lmstoken');

    useEffect(() => {
        fetchHomePage();
        //eslint-disable-next-line
    }, []);

    const fetchHomePage = async () => {
        setIsLoader(true);
        const response = await fetch(API_BASE + 'pages/home', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        });

        const data = await response.json();
        if (data.status) {
            setIsLoader(false);
            var homeData = data.data;
            setHomePageContent(homeData);
        } else {
            setIsLoader(false);
        }
    }

    const homeSearch = () => {
        if (search != "") {
            navigate('/search/' + search);
        }
    }

    const RedirecttoLC = () => {
        if (_token == null) {
            SwalBox.fire({
                allowOutsideClick: false,
                title: t('_coursedetail_alert_t1_'),
                text: t('_home2_msg_m1_'),
                icon: 'info',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: t('_lcdetail_swalbox_m3_'),
                cancelButtonText: t('_lcdetail_swalbox_m4_')
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.setItem('createLC', true);
                    navigate('/login');
                } else {
                    if (result.dismiss == 'cancel') {
                        localStorage.setItem('createLC', true);
                        navigate('/register');
                        return false;
                    } else {
                        SwalBox.close();
                    }
                }
            });
        } else {
            navigate('/dashboard/create-learning-circle')
        }
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_home2_msg_m2_')}`}</title>
            </Helmet>
            {/* Banner */}
            <section className="custom-section m-0 new_home_banner">
                <div className="banner-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="banner-info">
                                    <h2>{t('_home2_msg_m3_')} <span> {t('_home2_msg_m4_')}</span></h2>
                                    <div className="banner-form">
                                        <input type="text" onChange={(e) => { setSearch(e.target.value); }} onKeyUp={(e) => { if (e.key === 'Enter') { homeSearch(); } }} placeholder={t('_home2_msg_m5_')} value={search} />
                                        <a onClick={(e) => { homeSearch() }} type="submit" className="btn btn-success"><i className="fas fa-search"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* top image */}
                <div className="content" dangerouslySetInnerHTML={{ __html: homePage?.logo_section }}></div>
            </section>
            <div className="clearfix"></div>

            {/* Corporate */}
            <section className="corporate_section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 position-relative">
                            <img src="images/images/corporates.png" alt="" />
                            <h3>{t('_home2_msg_m6_')} </h3>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <h2>{t('_home2_msg_m7_')} <span>{t('_home2_msg_m8_')} </span></h2>
                            <div className="solutionbox">
                                <img src="images/images/corpo_icon1.svg" alt="" />
                                <div>
                                    <h4>{t('_home2_msg_m9_')} </h4>
                                    <p className="homeSolution">{t('_home2_msg_m10_')}</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/corpo_icon2.svg" alt="" />
                                <div>
                                    <h4>{t('_home2_msg_m11_')} </h4>
                                    <p className="homeSolution">{t('_home2_msg_m12_')}</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/corpo_icon3.svg" alt="" />
                                <div>
                                    <h4>{t('_home2_msg_m13_')}</h4>
                                    <p className="homeSolution">{t('_home2_msg_m14_')}</p>
                                </div>
                            </div>
                            {
                                cntxAuth.isLogged
                                    ?
                                    null
                                    :
                                    <Link to={"/corporate-register"} className="btn join_now">{t('_nav_join_now_')} </Link>
                            }
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </section>
            <div className="clearfix"></div>

            {/* Working Professionals */}
            <section className="corporate_section right_img_dgn white_bg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 order-lg-2 position-relative">
                            <img src="images/images/work-proffesional.png" alt="" />
                            <h3>{t('_home2_msg_m15_')} <br />{t('_home2_msg_m16_')} </h3>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <h2>{t('_home2_msg_m17_')} <span> {t('_home2_msg_m18_')} </span></h2>
                            <div className="solutionbox">
                                <img src="images/images/working_icon1.svg" alt="" />
                                <div>
                                    <h4>{t('_home2_msg_m19_')} </h4>
                                    <p className="homeSolution">{t('_home2_msg_m20_')}</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/working_icon2.svg" alt="" />
                                <div>
                                    <h4>{t('_home2_msg_m21_')} </h4>
                                    <p className="homeSolution">{t('_home2_msg_m22_')}</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/working_icon3.svg" alt="" />
                                <div>
                                    <h4>{t('_home2_msg_m23_')}</h4>
                                    <p className="homeSolution">{t('_home2_msg_m24_')}</p>
                                </div>
                            </div>
                            {
                                cntxAuth.isLogged
                                    ?
                                    null
                                    :
                                    <Link to={"/register"} className="btn join_now">{t('_nav_join_now_')} </Link>
                            }
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </section>
            <div className="clearfix"></div>

            {/* Students */}
            <section className="corporate_section student_title">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 position-relative">
                            <img src="images/images/student-freshers.png" alt="" />
                            <h3>{t('_lcdetail_std_')} <br /> {t('_home2_msg_m25_')} </h3>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <h2>{t('_home2_msg_m7_')} <span>{t('_home2_msg_m26_')} </span></h2>
                            <div className="solutionbox">
                                <img src="images/images/student_icon1.svg" alt="" />
                                <div>
                                    <h4>{t('_home2_msg_m27_')} </h4>
                                    <p className="homeSolution">{t('_home2_msg_m28_')}</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/student_icon2.svg" alt="" />
                                <div>
                                    <h4>{t('_home2_msg_m29_')} </h4>
                                    <p className="homeSolution">{t('_home2_msg_m30_')}</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/student_icon3.svg" alt="" />
                                <div>
                                    <h4>{t('_home2_msg_m31_')} </h4>
                                    <p className="homeSolution">{t('_home2_msg_m32_')}</p>
                                </div>
                            </div>
                            {
                                cntxAuth.isLogged
                                    ?
                                    null
                                    :
                                    <Link to={"/register"} className="btn join_now">{t('_nav_join_now_')}</Link>
                            }
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </section>
            <div className="clearfix"></div>

            {/* Hiring Courses */}
            <section className="hiring_course">
                <div className="container">
                    <div className="explore-block">
                        <div className="title_with_btn">
                            <div>

                                <h2><span>{t('_coursedetail_sort_course_hiring_')} </span> {t('_home2_msg_m33_')} </h2>
                                <p>{t('_home2_msg_m34_')} </p>
                            </div>
                            <Link to={"/courses"} className="btn">{t('_noauthcerti_msg_m2_')}</Link>
                        </div>
                        <Tabs defaultActiveKey="0" className="nav nav-pills" id="pills-tab1" role="tablist">
                            {
                                (typeof homePage?.vacancy_courses_section?.data !== "undefined" && homePage?.vacancy_courses_section?.data?.length > 0)
                                &&
                                homePage?.vacancy_courses_section?.data.map((element, index) =>
                                    (element?.courses && index < 3)
                                    &&
                                    <Tab key={index} eventKey={index} title={element.title} className="nav-item">
                                        <div className="blog-card">
                                            <div className="row">
                                                {
                                                    element.courses.map((child_element, child_index) =>
                                                        <div className="col-md-6 col-lg-3" key={child_index}>
                                                            <Link to={`/course-details/${child_element?.slug}`} className="ek-lrs-course-initialized" data-ek-lrs-url={`/course-details/${child_element?.slug}`} data-ek-lrs-title={child_element?.title}>
                                                                <div className="blog-card-detail m-0">
                                                                    <div className="custom-card-img">
                                                                        <img src={child_element?.image} className="card-img-top" alt={child_element?.title} />
                                                                    </div>
                                                                    <div className="blog-card-inner">
                                                                        <div className="comp_photo">
                                                                            <img src={child_element.creator_image} alt="" />
                                                                        </div>
                                                                        <h5><a href={undefined}>{child_element?.title}</a><span>{child_element.creator_name}</span></h5>
                                                                        <ul className="course_tabs_sub mt-3 mb-2">
                                                                            {
                                                                                child_element?.sub_category?.title
                                                                                &&
                                                                                <li><a><i className="fas fa-list"></i><span>{child_element?.sub_category?.title}</span></a></li>
                                                                            }
                                                                            {
                                                                                <li><a><i className="fas fa-chair"></i><span>{child_element?.vacancy ? child_element?.vacancy[0].job_position : ''}</span></a></li>
                                                                            }
                                                                        </ul>
                                                                        <div className="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </Tab>
                                )
                            }
                        </Tabs>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </section>
            <div className="clearfix"></div>

            {/* Top Courses */}
            {
                (typeof homePage?.our_courses_section?.data !== "undefined" && homePage?.our_courses_section?.data?.length > 0)
                &&
                <section className="hiring_course top_course">
                    <div className="container">
                        <div className="explore-block">
                            <div className="title_with_btn">
                                <div>
                                    {/* Commented and placed static due to word color css */}
                                    {/* <h2>{homePage?.our_courses_section?.title}</h2>
                                    <p>{homePage?.our_courses_section?.description}</p> */}

                                    <h2><span>{t('_home2_msg_m35_')} </span> {t('_home2_courses_msg_m_')}</h2>
                                    <p>{t('_home2_msg_new_course_p_m1_')}</p>
                                </div>
                                <Link to={"/courses"} className="btn">{t('_noauthcerti_msg_m2_')}</Link>
                            </div>
                            <Tabs defaultActiveKey="0" className="nav nav-pills" id="pills-tab1" role="tablist">
                                {
                                    (typeof homePage?.our_courses_section?.data !== "undefined" && homePage?.our_courses_section?.data?.length > 0)
                                    &&
                                    homePage?.our_courses_section?.data.map((element, index) =>
                                        (element?.courses && index < 3)
                                        &&
                                        <Tab key={index} eventKey={index} title={element.title} className="nav-item">
                                            <div className="blog-card">
                                                <div className="row">
                                                    {
                                                        element.courses.map((child_element, child_index) =>
                                                            <div className="col-md-6 col-lg-3" key={child_index}>
                                                                <Link to={`/course-details/${child_element?.slug}`} className="ek-lrs-course-initialized" data-ek-lrs-url={`/course-details/${child_element?.slug}`} data-ek-lrs-title={child_element?.title}>
                                                                    <div className="blog-card-detail m-0">
                                                                        <div className="custom-card-img">
                                                                            <img src={child_element?.image} className="card-img-top" alt={child_element?.title} />
                                                                        </div>
                                                                        <div className="blog-card-inner">
                                                                            <div className="compny_logo">
                                                                                <img src="./images/course_logo_img.jpg" alt="" />
                                                                            </div>
                                                                            <h5>{child_element?.title}</h5>
                                                                            <p className="membr_line">
                                                                                {
                                                                                    child_element?.mode_of_learning
                                                                                    &&
                                                                                    <>
                                                                                        <span>{child_element?.mode_of_learning}</span>&nbsp;  &nbsp;
                                                                                    </>
                                                                                }

                                                                            </p>
                                                                            <ul className="course_tabs_sub mt-3 mb-2">
                                                                                {
                                                                                    child_element?.sub_category?.title
                                                                                    &&
                                                                                    <li><span><i className="fas fa-list"></i><span>{child_element?.sub_category?.title}</span></span></li>
                                                                                }
                                                                                {
                                                                                    <li><span><i className="fas fa-star"></i><span>{child_element?.rating !== "" ? child_element?.rating : 'No'} {t('_explorelc_rating_')}</span></span></li>
                                                                                }
                                                                            </ul>
                                                                            <div className="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Tab>
                                    )
                                }
                            </Tabs>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </section>
            }

            <div className="clearfix"></div>

            {/* power of peer to peer */}
            <section className="custom-section harnessing_section">
                <div className="image-with-text-section custom__bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 order-lg-2">
                                <div className="question-block">
                                    <img src="images/images/learning-circle.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="question-block text-white">
                                    <h2 className="text-white">{t('_home2_msg_m38_')} <span>{t('_home2_msg_m39_')} </span></h2>
                                    <p className="text-white homeSolution">{t('_home2_msg_m40_')}
                                        <br />
                                        <Link to={'/explore-learning-circles'} >- {t('_home2_msg_m41_')}</Link>
                                    </p>
                                    <a onClick={(e) => { RedirecttoLC(); }} className="btn btn-info btn-circle">{t('_home2_msg_m42_')} </a>
                                    <Link to={'/explore-learning-circles'} className="btn btn-light btn-circle">{t('_home2_msg_m43_')} </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </section>
            <div className="clearfix"></div>

            {/* Top Learning Circles */}
            {
                (typeof homePage?.learning_circles_section?.data !== "undefined" && homePage?.learning_circles_section?.data?.length > 0)
                &&
                <section className="hiring_course top_learning_crcl">
                    <div className="container">
                        <div className="explore-block">
                            <div className="title_with_btn">
                                <div>
                                    {/* Commented and placed static due to word color css */}
                                    {/* <h1>{homePage?.learning_circles_section?.title}</h1>
                                    <p>{homePage?.learning_circles_section?.description}</p> */}
                                    <h2><span>{t('_home2_msg_m35_')} </span> {t('_home2_msg_m36_11_')} </h2>
                                    <p>{t('_home2_msg_m37_11_')}</p>
                                </div>
                                <Link to={'/explore-learning-circles'} className="btn">{t('_noauthcerti_msg_m2_')} </Link>
                            </div>

                            <Tabs defaultActiveKey="0" className="nav nav-pills pb-5">
                                {
                                    (typeof homePage?.learning_circles_section?.data !== "undefined" && homePage?.learning_circles_section?.data?.length > 0)
                                    &&
                                    homePage?.learning_circles_section?.data.map((element, index) =>
                                        (element?.learning_circle && index < 3)
                                        &&
                                        <Tab key={index} eventKey={index} title={element.title}>
                                            <div className="blog-card">
                                                <div className="row">
                                                    {
                                                        element?.learning_circle
                                                        &&
                                                        element?.learning_circle.map((group, index) =>
                                                            <div key={index} className="col-md-6 col-lg-3">
                                                                <div className="blog-card-detail m-0">
                                                                    <div className="custom-card-img">
                                                                        <Link to={'/learning-circle-details/' + group.unique_id} className="ek-lrs-module-initialized" data-ek-lrs-url={`/learning-circle-details/${group.id}`} data-ek-lrs-title={group.title}>
                                                                            <img src={group.image} className="card-img-top" alt="Blog image" />
                                                                        </Link>
                                                                    </div>
                                                                    <div className="blog-card-inner">
                                                                        <p className="membr_line"><span>{group.membersCount}+ {t('_mylc_lccard_m2_')}</span> &nbsp; | &nbsp; <span>{group?.owner}</span></p>
                                                                        <h5>
                                                                            <Link to={'/learning-circle-details/' + group.unique_id} className="ek-lrs-module-initialized" data-ek-lrs-url={`/learning-circle-details/${group.id}`} data-ek-lrs-title={group.title}>
                                                                                {group.title}
                                                                            </Link>
                                                                        </h5>

                                                                        <ul className="course_tabs_sub mt-3 mb-2">
                                                                            <li><a><i className="fas fa-list"></i><span>{group.category}</span></a></li>
                                                                            {/* <li><Link to={`/profile-details/${group?.owner_slug}`}><i className="fas fa-user"></i><span>{group?.owner}</span></Link></li>
                                                                        <li><a><i className="fas fa-users"></i><span>{group.membersCount}+ Group members</span></a></li> */}
                                                                            {/* <li><a><i className="fas fa-language"></i><span>{group.language}</span></a></li> */}
                                                                            <li><a><i className="fas fa-star"></i><span>{group?.average_rating !== null ? group?.average_rating : 'No'} {t('_mylc_lccard_rating_')}</span></a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Tab>
                                    )
                                }
                            </Tabs>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </section>
            }
            <div className="clearfix"></div>

            {/* Educators */}
            <section className="corporate_section right_img_dgn tutor_sect white_bg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 order-lg-2 position-relative">
                            <img src="images/images/tutors.png" alt="" />
                            <h3>{t('_home2_msg_m44_')} </h3>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <h2>{t('_home2_msg_m7_')} <span>{t('_home2_msg_m45_')} </span></h2>
                            <div className="solutionbox">
                                <img src="images/images/tutors_icon1.svg" alt="" />
                                <div>
                                    <h4>{t('_courselist_tour_t2_')} </h4>
                                    <p className="homeSolution">{t('_home2_msg_m46_')}</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/tutors_icon2.svg" alt="" />
                                <div>
                                    <h4>{t('_home2_msg_m47_')} </h4>
                                    <p className="homeSolution">{t('_home2_msg_m48_')}</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/tutors_icon3.svg" alt="" />
                                <div>
                                    <h4>{t('_home2_msg_m49_')} </h4>
                                    <p className="homeSolution">{t('_home2_msg_m50_')}</p>
                                </div>
                            </div>
                            {
                                cntxAuth.isLogged
                                    ?
                                    null
                                    :
                                    <Link to={"/register"} className="btn join_now">{t('_nav_join_now_')} </Link>
                            }
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </section>
            <div className="clearfix"></div>
        </>
    );
}

export default Home2;
