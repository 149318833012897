import { useEffect, useState } from "react";
import { API_BASE } from "../../constants/global";
import { FeedBoxSkeleton } from "../../components/skeletons";
import { CompletedCourse, EnrolledCourse, InvitedToCourse, HighratedCourses, Register, SharedCourse } from "./notifications/Student/index";
import NoNotifications from "./notifications/NoNotifications";
import VerifiedBadge from "../../components/VerifiedBadge";
import { useTranslation } from "react-i18next";

const StudenTab = () => {
    const [notifications, setNotifications] = useState('');
    const [high_rated_course, setHighRatedCourse] = useState('');
    const [isLoader, setLoader] = useState(true);
    const _token = localStorage.getItem('lmstoken');
    const [page, setPage] = useState(0);
    const [total_page, setTotalPage] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        fetchStudentNotification();
        fetchHighRatedCourse();
        // eslint-disable-next-line
    }, [page]);

    const fetchStudentNotification = async () => {
        setLoader(true);
        const response = await fetch(`${API_BASE}get-user-feeds-by-tab/Student?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        setLoader(false);
        if (data.status && data?.data?.feeds) {
            setTotalPage(data?.data?.count);
            setNotifications([...notifications, ...data?.data?.feeds]);
        }
    }

    const fetchHighRatedCourse = async () => {
        const response = await fetch(`${API_BASE}get-high-rated-course`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setHighRatedCourse(data.data.ratings);
        }
    }

    const nextPage = () => {
        setPage(page + 1);
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pill-student" role="tabpanel" aria-labelledby="pill-student-tab">
                <div className="course-tab-contents">
                    <div className="course-tabs-pane-block">
                        {
                            isLoader ?
                                <FeedBoxSkeleton count={3} />
                                :
                                notifications === '' ?
                                    <NoNotifications
                                        main_message={t('_no_notification_')}
                                        sub_message={<></>}
                                    />
                                    :
                                    <>
                                        {
                                            notifications !== ""
                                            &&
                                            notifications.map((ntfcn, index) =>

                                                <div key={index}>
                                                    {
                                                        ntfcn.action === 'Registration'
                                                        &&
                                                        <Register ntfcn={ntfcn} />
                                                    }

                                                    {
                                                        ntfcn.action === 'Enrolled_Course'
                                                        &&
                                                        <EnrolledCourse ntfcn={ntfcn} />
                                                    }

                                                    {
                                                        ntfcn.action === 'Invited_to_Course'
                                                        &&
                                                        <InvitedToCourse ntfcn={ntfcn} />
                                                    }

                                                    {
                                                        ntfcn.action === 'Completed_Course'
                                                        &&
                                                        <CompletedCourse ntfcn={ntfcn} />
                                                    }

                                                    {
                                                        ntfcn.action === 'Shared_Course'
                                                        &&
                                                        <SharedCourse ntfcn={ntfcn} />
                                                    }

                                                    {
                                                        index === 0
                                                        &&
                                                        <HighratedCourses ratings={high_rated_course} />
                                                    }
                                                </div>
                                            )
                                        }

                                        {
                                            notifications.length < total_page
                                            &&
                                            <div className="load_more_div">
                                                <a className="cursor-pointer" onClick={(e) => { nextPage(); }}> {t('_load_more_')} </a>
                                            </div>
                                        }
                                    </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default StudenTab;
