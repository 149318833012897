import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import enJson from "./language_local/en.js";
import aeJson from "./language_local/ae.js";

let userlang = localStorage.getItem('userlang');
if (typeof userlang === 'undefined' || userlang === null) {
  userlang = "en";
}


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enJson
      },
      ae: {
        translation: aeJson
      },
    },
    lng: userlang,
    fallbackLng: userlang,

    interpolation: {
      escapeValue: false
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
