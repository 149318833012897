import React from 'react';
import { Modal } from "react-bootstrap";


function QuizModal(props) {


  return (
    <>
      <Modal
        className="invitePopup header_red"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <h4>Edit Question</h4>
          <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <label>Question</label>
            </div>
            <input type="text" className="text-quiz-new" placeholder="Enter a Question" />
            <div className="qmodecontainer" style={{ display: 'flex', gap: 5 }}>

              <div style={{ width: '100%' }}>
                <input type="radio" className="radio-quiz" name="is_correct" value={1} />
                <label>answer 1</label>
                <div>
                  <input type="text" className="text-quiz-new" placeholder="Add an Answer" />
                </div>
              </div>

              <div style={{ width: '100%' }}>
                <input type="radio" className="radio-quiz" name="is_correct" value={1} />
                <label>answer 2</label>
                <div>
                  <input type="text" className="text-quiz-new" placeholder="Add an Answer" />
                </div>
              </div>

            </div>
            <div className="qmodecontainer" style={{ display: 'flex', gap: 5 }}>

              <div style={{ width: '100%' }}>
                <input type="radio" className="radio-quiz" name="is_correct" value={1} />
                <label>answer 3</label>
                <div>
                  <input type="text" className="text-quiz-new" placeholder="Add an Answer" />
                </div>
              </div>

              <div style={{ width: '100%' }}>
                <input type="radio" className="radio-quiz" name="is_correct" value={1} />
                <label>answer 4</label>
                <div>
                  <input type="text" className="text-quiz-new" placeholder="Add an Answer" />
                </div>
              </div>

            </div>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-circle btn-md submit-pink-btn">Save</button>

            <button type="button" className="btn btn-circle btn-md cancel-btn">Close</button>
          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default QuizModal;
