import { useEffect, useState } from "react";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import CustomPagination from "../../entity/CustomPagination";
import { useTranslation } from "react-i18next";

const Circles = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const { _slug } = useParams();
    const _token = localStorage.getItem('lmstoken');
    const [circles, setCircles] = useState('');
    const [total_count, setTotalCount] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        fetchCircles(currentPage);
    }, [location, reload]);

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }

    const fetchCircles = async (page = 0) => {
        const response = await fetch(`${API_BASE}get-course-circles/${_slug}?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();

        if (data.status) {
            setCircles(data.data.circles);
            setTotalCount(data.data.total_count);
        } else {
            setCircles('');
            setTotalCount(0);
        }
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-circle" role="tabpanel" aria-labelledby="pills-circle-tab">
                <div className="blog-card">
                    <div className="row">
                        {
                            circles !== ''
                            &&
                            circles.map((circle, index) =>
                                <div key={index} className="col-md-6 col-lg-3 mb-5">
                                    <div className="blog-card-detail">
                                        <div className="custom-card-img">
                                            <Link to={'/learning-circle-details/' + circle.unique_id} className="ek-lrs-module-initialized" data-ek-lrs-url="{`/learning-circle-details/${circle.unique_id}`}" data-ek-lrs-title="{circle.title}">
                                                <img src={circle.image} className="card-img-top" alt="Blog image" />
                                            </Link>
                                        </div>
                                        <div className="blog-card-inner">
                                            <span> {t('_mylc_lccard_')} {circle.started}</span>
                                            <Link to={'/learning-circle-details/' + circle.unique_id} className="ek-lrs-module-initialized" data-ek-lrs-url="{`/learning-circle-details/${circle.unique_id}`}" data-ek-lrs-title="{circle.title}">
                                                <h5>{circle.title}</h5>
                                            </Link>

                                            <ul className="course-tabs-details">
                                                <li><a><i className="fas fa-list"></i><span>{circle.category}</span></a></li>
                                                <li><a><i className="far fa-user"></i><span>{circle.owner}</span></a></li>
                                                <li><a><i className="fas fa-users"></i><span>{circle.membersCount}+ {t('_lcdetail_group_members_')}</span></a></li>
                                                {/* <li><a><i className="fas fa-language"></i><span>{circle.language}</span></a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            (circles === '')
                            &&
                            <div className="col-12">
                                <div className="empty_state">
                                    <h3>{t('_explorelc_nolc_found_')}</h3>
                                    <img alt="" src="images/no_courses.png" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="clearfix"></div>
                {
                    total_count > 8
                    &&
                    <div className="custom-pagination">
                        <nav aria-label="Page navigation example">
                            <CustomPagination pageCount={total_count} perPageRecord={8} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                        </nav>
                    </div>
                }
            </div>
        </>
    );
}

export default Circles;
