import { useEffect, useState } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import AboutCard from "./AboutCard";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const JoinRequests = (props) => {
    const { unique_id } = useParams();
    const _token = localStorage.getItem('lmstoken');
    const [group, setRequestsTotal, isowner, isadmin, members_total, setMembersTotal] = useOutletContext();
    const [join_requests, setJoinRequests] = useState('');
    const [page, setPage] = useState(1);
    const [all, setAll] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        fetchJoinRequest();
        // eslint-disable-next-line
    }, []);

    const acceptRequest = async (id, flag) => {
        var sURL = `${API_BASE}accept-join-request/${id}/${flag}`;
        const response = await fetch(sURL, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status) {
            if (flag) {
                setMembersTotal(members_total + 1);
            }

            Toaster.success(data.message);
        } else {
            Toaster.error(data.message);
        }

        fetchJoinRequest();
    }

    const showMore = async () => {
        setPage(page + 1);
        fetchJoinRequest();
    }

    const fetchJoinRequest = async (page) => {
        const response = await fetch(`${API_BASE}get-group-join-request/${unique_id}?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setJoinRequests(data.data.requests);
            setRequestsTotal(data.data.count);
            if (data.data.total === 1) {
                setAll(true);
            }
        } else {
            setJoinRequests('');
            setRequestsTotal(0);
        }
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_dh_learningcircle_')}`}</title>
            </Helmet>
            <section className={`custom-section feed_page`}>
                <div className="profile_user_middle_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="custom-user-profile-content custom-learning-circle-content post_box_top">
                                    <div className="custom-learnig-circle-head">
                                        <div className="join-invite-section">
                                            <h4>{t('_managelc_join-request_')} </h4>
                                            <div className="join-invite-head">
                                                <h3>{t('_lcjoinrequest_msg_m1_')} <span>{(join_requests !== '' && typeof join_requests !== 'undefined') ? `(${join_requests.length})` : ''}</span></h3>
                                            </div>
                                            <div className="join-invite-listing">
                                                {
                                                    join_requests !== '' && typeof join_requests !== 'undefined'
                                                    &&
                                                    join_requests.map((join_request, index) =>
                                                        <div key={index} className="join-invite-list">
                                                            <div className="join-invite-user">
                                                                <div className="join-invite-avtar">
                                                                    <img src={join_request.profile} alt={join_request.user} />
                                                                </div>
                                                                <div className="join-invite-content">
                                                                    <p><Link to="/">{join_request.user} </Link> {t('_lcjoinrequest_msg_m2_')}</p>
                                                                </div>
                                                            </div>
                                                            <div className="join-invite-action">
                                                                <button onClick={(e) => { acceptRequest(join_request.id, true) }} className="btn btn-circle btn-warning mr-2">{t('_lcjoinrequest_msg_m3_')} </button>
                                                                <button onClick={(e) => { acceptRequest(join_request.id, false) }} className="btn btn-circle btn-info">{t('_mylc_pending_invitation_ignore_')} </button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    (typeof join_requests === 'undefined' || join_requests.length <= 0)
                                                    &&
                                                    <><div className="text-center pt-4"><p>{t('_lcjoinrequest_msg_m4_')}</p></div></>
                                                }

                                                {
                                                    props.all === false && join_requests !== ''
                                                    &&
                                                    <div className="join-invite-more">
                                                        <button onClick={(e) => { showMore(); }} className="show-more-btn">{t('_mylc_showmore_')} </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <AboutCard group={group} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default JoinRequests;
