import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE } from "../../../constants/global";
import { Toaster } from "../../../entity/GlobalJS";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const CircleMeetingsCreate = (props) => {
    const navigate = useNavigate();
    const { unique_id } = useParams();
    const userData = JSON.parse(localStorage.getItem('lmsuser'));
    const [list_timezones, setListTimezones] = useState('');
    const [min_time, setMinTime] = useState('');

    const [time_zone_time, setTimeZoneTime] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        fetchTimezones();
        // eslint-disable-next-line
    }, []);

    const [submitting, setSubmitting] = useState(false);
    const _token = localStorage.getItem('lmstoken');
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const saveMeeting = async (form) => {
        setSubmitting(true);
        const response = await fetch(`${API_BASE}create-group-meeting/${unique_id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(form)
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            navigate(`/view-learning-circle/${unique_id}/meetings`);
        } else {
            Toaster.error(data.message);
        }

        setSubmitting(false);
    }

    const fetchTimezones = async () => {
        const response = await fetch(`${API_BASE}get-all-timezones`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setListTimezones(data.data.timezones);
        } else {
            setListTimezones('');
        }
    }


    function mTitme(hour) {
        return hour < 10 ? "0" + hour : hour;
    }

    const getDateTime = () => {
        let timeZone = document.getElementById('timeZone').value;
        let obj = list_timezones.find(o => o.time_zone === timeZone);
        let selectedOffset = Number(obj.time_zone_offset.replace('GMT', '').replace(':', '.'));
        let myTimeOffset = Number((new Date().getTimezoneOffset() / 60));
        let differ = myTimeOffset + selectedOffset;
        let currentTime = new Date().getTime();
        let updatedTIme = new Date(currentTime + differ * 60 * 60 * 1000);

        let date = document.getElementById('date_field').value;
        if (typeof date !== 'undefined' && date === new Date().toISOString().split('T')[0]) {
            setMinTime(updatedTIme.getHours() + ':' + updatedTIme.getMinutes());
        }
        setTimeZoneTime(updatedTIme.getHours() + ':' + updatedTIme.getMinutes());
        setValue('time_zone_time', mTitme(updatedTIme.getHours()) + ':' + mTitme(updatedTIme.getMinutes()));
    }

    const setTime = (date) => {
        if (date === new Date().toISOString().split('T')[0]) {
            setMinTime(time_zone_time);
        }
        else {
            setMinTime('');
        }
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_dashboard_home_t4_')}`} </title>
            </Helmet>
            <section className={`custom-section member_section`}>
                <div className="profile_user_middle_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="custom-user-profile-content custom-learning-circle-content">
                                    <div className="custom-learnig-circle-head">
                                        <h4>{t('_lcmeeting_add_meeting_')}</h4>
                                    </div>
                                    <div className="learning-circle-form">
                                        <form onSubmit={handleSubmit(saveMeeting)}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <select {...register("timezone")} id="timeZone" onChange={(e) => { getDateTime(); }} className="form-control" autoFocus>
                                                            <option value="">{t('_lcmeeting_addmeet_timezone_')}</option>
                                                            {
                                                                list_timezones !== ''
                                                                &&
                                                                list_timezones.map((timezone, index) =>
                                                                    <option key={index} value={timezone.time_zone}>{timezone.locations}  ({timezone.time_zone_offset})</option>
                                                                )
                                                            }
                                                        </select>
                                                        <span className="text-danger">
                                                            {errors.timezone && errors.timezone.type === "required" && t('_lcmeeting_addmeet_timezone_vali_m1_')}
                                                            {errors.timezone && errors.timezone.type !== "required" && errors.timezone.message}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <input id="title" {...register("title", {
                                                            required: "required",
                                                            maxLength: {
                                                                value: 80,
                                                                message: t('_lcmeeting_addmeet_title_vali_m1_')
                                                            }
                                                        })} type="text" className="form-control" placeholder={t('_lcmeeting_addmeet_topic_')} />
                                                        <span className="add-on max-chr-counter chrchk-title" data-ele="title" data-limit="80" data-showcounter="true"></span>
                                                        <span className="text-danger">
                                                            {errors.title && errors.title.type === "required" && t('_lcmeeting_addmeet_timezone_vali_m2_')}
                                                            {errors.title && errors.title.type !== "required" && errors.title.message}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <textarea {...register("agenda", { required: "required", })} className="form-control" placeholder={t('_lcmeeting_addmeet_agenda_')} rows="5"></textarea>
                                                        <span className="text-danger">
                                                            {errors.agenda && errors.agenda.type === "required" && t('_lcmeeting_addmeet_agenda_vali_m1_')}
                                                            {errors.agenda && errors.agenda.type !== "required" && errors.agenda.message}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <input {...register("date", { required: "required" })} id="date_field" type="date" min={new Date().toISOString().split('T')[0]} className="form-control" placeholder={t('_lcmeeting_addmeet_date_')} onChange={(e) => { setTime(e.target.value) }} />
                                                        <span className="text-danger">
                                                            {errors.date && errors.date.type === "required" && t('_lcmeeting_addmeet_date_vali_m1_')}
                                                            {errors.date && errors.date.type !== "required" && errors.date.message}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <input
                                                            {...register("time", { required: "required" })}
                                                            type="time"
                                                            min={min_time}
                                                            className="form-control"
                                                            placeholder={t('_lcmeeting_addmeet_time_')}
                                                        />
                                                        <span className="text-danger">
                                                            {errors.time && errors.time.type === "required" && t('_lcmeeting_addmeet_time_vali_m1_')}
                                                            {errors.time && errors.time.type !== "required" && errors.time.message}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <input {...register("duration", {
                                                            required: "required",
                                                            min: {
                                                                value: 10,
                                                                message: t('_lcmeeting_addmeet_duration_vali_m2_')
                                                            },
                                                            max: {
                                                                value: 200,
                                                                message: t('_lcmeeting_addmeet_duration_vali_m3_')
                                                            }
                                                        })} type="number" className="form-control" placeholder={t('_lcmeeting_addmeet_duration_')} />
                                                        <span className="text-danger">
                                                            {errors.duration && errors.duration.type === "required" && t('_lcmeeting_addmeet_duration_vali_m1_')}
                                                            {errors.duration && errors.duration.type !== "required" && errors.duration.message}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <a onClick={(e) => { navigate(`/view-learning-circle/${unique_id}/meetings`); }} className="btn btn-circle btn-md cancel-btn">{t('_cancel_')} </a>
                                            <button disabled={submitting} type="submit" className="btn btn-circle btn-md submit-pink-btn">{submitting ? <i className="fa fa-refresh fa-spin fa-fw"></i> : t('_v_add_submit_')}</button>
                                        </form>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CircleMeetingsCreate;
