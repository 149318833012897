import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AnnouncementTab from "./AnnouncementTab";
import LearningCircleTab from "./LearningCircleTab";
import NewReleaseCourses from "./NewReleaseCourses";
import StudenTab from "./StudenTab";
import TrendingLearningCircle from "./TrendingLearningCircle";
import TutorTab from "./TutorTab";
import { stickyArea } from './scroll';
import Joyride from 'react-joyride';
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const DashboardHome = () => {
    const [active_tab, setActiveTab] = useState(1);
    const [tour_steps, setTourSteps] = useState([]);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const no_of_time_login = localStorage.getItem('no_of_time_login');
    const has_auto_password = localStorage.getItem('has_auto_password');
    const { t } = useTranslation();

    useEffect(() => {
        if (user?.is_corporate === 1) { setActiveTab(2) }
        stickyArea();
        if (no_of_time_login == 1 && has_auto_password == 'YES') {
            SwalBox.fire({
                allowOutsideClick: false,
                title: t('_dh_alert_m1_'),
                text: t('_dh_alert_m2_'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: t('_show_tour_yes_'),
                cancelButtonText: t('_show_tour_no_')
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.removeItem('no_of_time_login');
                    localStorage.removeItem('has_auto_password');
                    navigate('/profile/security/');
                } else {
                    localStorage.removeItem('no_of_time_login');
                    localStorage.removeItem('has_auto_password');
                    showTour();
                }
            });
        } else {
            showTour();
        }
    }, []);

    const showTour = () => {
        let show_tour = Cookies.get("show_tour");
        if (show_tour != 'NO') {
            SwalBox.fire({
                allowOutsideClick: false,
                title: t('_show_tour_maintitle_'),
                text: t('_show_tour_msg_dashboard_'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: t('_show_tour_yes_'),
                cancelButtonText: t('_show_tour_no_')
            }).then((result) => {
                if (result.isConfirmed) {
                    startTour();
                } else {
                    Cookies.set('show_tour', 'NO');
                }
            });
        }
    }
    const startTour = () => {
        let steps = [];
        if (user?.is_corporate === 1) {
            steps = [
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-cr-step-01', title: t('_dashboard_home_t1_'), content: t('_dashboard_home_m1_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-cr-step-02', title: t('_dashboard_home_t2_'), content: t('_dashboard_home_m2_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-cr-step-03', title: t('_dashboard_home_t3_'), content: t('_dashboard_home_m3_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-cr-step-04', title: t('_dashboard_home_t4_'), content: t('_dashboard_home_m4_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-cr-step-05', title: t('_dashboard_home_t5_'), content: t('_dashboard_home_m5_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-cr-step-07', title: t('_dashboard_home_t6_'), content: t('_dashboard_home_m6_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-cr-step-08', title: t('_dashboard_home_t7_'), content: t('_dashboard_home_m7_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-cr-step-06', title: t('_dashboard_home_t8_'), content: t('_dashboard_home_m8_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-cr-step-09', title: t('_dashboard_home_t9_'), content: t('_dashboard_home_m9_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-cr-step-10', title: t('_dashboard_home_t10_'), content: t('_dashboard_home_m10_'), },
            ];
        } else {
            steps = [
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-lr-step-01', title: t('_dashboard_home_t1_'), content: t('_dashboard_home2_m1_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-lr-step-02', title: t('_dashboard_home_t2_'), content: t('_dashboard_home2_m2_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-lr-step-03', title: t('_dashboard_home_t3_'), content: t('_dashboard_home_m3_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-lr-step-04', title: t('_dashboard_home_t4_'), content: t('_dashboard_home2_m4_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-lr-step-05', title: t('_dashboard_home2_t5_'), content: t('_dashboard_home2_m5_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-lr-step-06', title: t('_dashboard_home2_t6_'), content: t('_dashboard_home2_m6_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-lr-step-07', title: t('_dashboard_home_t8_'), content: t('_dashboard_home2_m7_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-lr-step-08', title: t('_r_careeradvice_'), content: t('_dashboard_home2_m8_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-lr-step-09', title: t('_r_selfassessment_'), content: t('_dashboard_home2_m9_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-lr-step-10', title: t('_dashboard_home_t9_'), content: t('_dashboard_home2_m10_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-lr-step-11', title: t('_dashboard_home_t10_'), content: t('_dashboard_home2_m11_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-lr-step-12', title: t('_dashboard_home2_t11_'), content: t('_dashboard_home2_m12_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-lr-step-13', title: t('_dashboard_home2_t13_'), content: t('_dashboard_home2_m13_'), },
            ];
        }

        setTourSteps(steps);
        Cookies.set('show_tour', 'NO');
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_nav_opt1_')}`}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="custom-user-profile-content pt-0">
                            <div className="course-details-tab-block explore-block">
                                <ul className="nav nav-pills custom-section" id="pills-tab" role="tablist">
                                    {
                                        user?.is_corporate !== 1
                                        &&
                                        <li className="nav-item">
                                            <a onClick={(e) => { setActiveTab(1); }} className={"nav-link " + (active_tab === 1 ? 'active' : '')} id="pills-student-tab" data-toggle="pill" href="#pill-student" role="tab" aria-controls="pill-student" aria-selected="true">{t('_dh_student_')} </a>
                                        </li>
                                    }
                                    {
                                        user?.is_tutor === 1
                                        &&
                                        <>
                                            {
                                                user?.is_corporate === 1
                                                    ?
                                                    <li className="nav-item">
                                                        <a onClick={(e) => { setActiveTab(2); }} className={"nav-link " + (active_tab === 2 ? 'active' : '')} id="pills-tutor-tab" data-toggle="pill" href="#pills-tutor" role="tab" aria-controls="pills-tutor" aria-selected="false">{t('_dh_courses_')}</a>
                                                    </li>
                                                    :
                                                    <li className="nav-item">
                                                        <a onClick={(e) => { setActiveTab(2); }} className={"nav-link " + (active_tab === 2 ? 'active' : '')} id="pills-tutor-tab" data-toggle="pill" href="#pills-tutor" role="tab" aria-controls="pills-tutor" aria-selected="false">{t('_dh_tutor_')} </a>
                                                    </li>
                                            }
                                        </>
                                    }
                                    <li className="nav-item">
                                        <a onClick={(e) => { setActiveTab(3); }} className={"nav-link " + (active_tab === 3 ? 'active' : '')} id="pills-circle-tab" data-toggle="pill" href="#pills-circle" role="tab" aria-controls="pills-circle" aria-selected="false">{t('_dh_learningcircle_')} </a>
                                    </li>
                                    <li className="nav-item">
                                        <a onClick={(e) => { setActiveTab(4); }} className={"nav-link " + (active_tab === 4 ? 'active' : '')} id="pills-announcements-tab" data-toggle="pill" href="#pills-announcements" role="tab" aria-controls="pills-announcements" aria-selected="false">{t('_dh_announcements_')} </a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    {
                                        (active_tab === 1)
                                        &&
                                        <StudenTab />
                                    }

                                    {
                                        (active_tab === 2 && user?.is_tutor === 1)
                                        &&
                                        <TutorTab />
                                    }

                                    {
                                        (active_tab === 3)
                                        &&
                                        <LearningCircleTab />
                                    }

                                    {
                                        (active_tab === 4)
                                        &&
                                        <AnnouncementTab />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div data-sticky="true" data-top-gap="10" data-bottom-gap="10" className="custom-user-profile-content sticky-top pt-0">
                            {
                                user?.is_corporate !== 1
                                &&
                                <NewReleaseCourses />
                            }
                            <TrendingLearningCircle />
                        </div>
                    </div>
                </div>
            </div>
            {
                tour_steps.length > 0
                &&
                <Joyride
                    steps={tour_steps}
                    disableCloseOnEsc={true}
                    disableOverlayClose={true}
                    showSkipButton={true}
                    continuous={true}
                    hideCloseButton={true}
                    locale={{ back: t('_tour_back_'), close: t('_tour_close_'), last: t('_tour_last_'), next: t('_tour_next_'), open: t('_tour_open_dialog_'), skip: t('_tour_skip_') }}
                    styles={{ options: { width: 500, zIndex: 5000, primaryColor: '#31314c', textColor: '#ef4a45', overlayColor: 'rgb(2 1 0 / 69%)', } }}
                />
            }
        </>
    );
}

export default DashboardHome;
