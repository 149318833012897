import { React, useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import Joyride from 'react-joyride';
import { Link, useNavigate, NavLink, Button, useLocation, useSearchParams } from "react-router-dom";
import { API_BASE, LRS_ENDPOINT } from "../../constants/global";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Export from "react-data-table-component";
import downloadCSV from "react-data-table-component";
import { Chart as ChartJS, registerables } from 'chart.js';
import { Pie, Doughnut, Line, Bar } from 'react-chartjs-2';
import Cookies from "js-cookie";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Select from 'react-select';
import DropdownSkeleton from "../../components/skeletons/DropdownSkeleton";
import EduKulaAi from "../../entity/EdukulaAi";
import { Helmet } from "react-helmet";
ChartJS.register(...registerables);


const toggleLeftMenu = async () => {
    try {
        let sidebar = document.querySelector(".sidebar");
        sidebar.classList.add("close");
    } catch (e) { }
}


const ReportDashboard = () => {

    const gridRef = useRef();
    const selectInputRef = useRef();
    const navigate = useNavigate();
    const _token = localStorage.getItem('lmstoken');
    const userData = JSON.parse(localStorage.getItem('lrsuser'));

    const { register, control, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const [employees, setEmployees] = useState('');
    const [employees2, setEmployees2] = useState('');

    const [loading, setLoading] = useState(false);
    const [total_count, setTotalCount] = useState(0);
    const [processing, setProcessing] = useState(false);
    const [filter_processing, setFilterProcessing] = useState(false);
    const [query_processing, setQueryProcessing] = useState(false);
    const [file, setFiles] = useState('');
    const [viaEmailShow, setViaEmailShow] = useState(false);
    const [tour_steps, setTourSteps] = useState([]);
    const user = JSON.parse(localStorage.getItem('lmsuser'));

    const [rowData, setRowData] = useState([]);

    const [roles, setRoles] = useState('');
    const [employeeid, setEmployeeid] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const [course_options, setCourseOptions] = useState([]);
    const [emp_options, setEmpOptions] = useState([]);
    const [studentEmp, SetStudentEmp] = useState([]);
    const [fetchingStuEmp, setFetchingStuEmp] = useState(true);

    const [courses, setCourses] = useState('');
    const [selected_course, setSelectedCourse] = useState('');
    const [searchQuery, setSearchQuery] = useState();

    const { register: register2, control: control2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, clearErrors: clearErrors2, setError: setError2, reset: reset2, resetField: resetField2, unregister: unregister2, setValue: setValue2 } = useForm(
        {
            mode: "onSubmit",
            reValidateMode: "onChange"
        }
    );

    useEffect(() => {
        fetchEmployees(currentPage);
        fetchRoles();
        fetchCourses();
    }, [location, reload]);

    useEffect(() => {
        toggleLeftMenu();
    }, []);

    const fetchRoles = async (page = 0) => {
        const response = await fetch(`${API_BASE}role/get?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            setRoles(data.data.roles);
        } else {

        }
    }


    const fetchEmployees = async (page = 0, search = "") => {
        setLoading(true);
        const response = await fetch(`${API_BASE}employee/get?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            setEmployees(data.data.employees);
            setEmployees2(data.data.employees);
            setRowData(data.data.employees);
            setTotalCount(data.data.total_count);
        } else {
            setEmployees('');
            setEmployees2('');
            setTotalCount(0);
        }
        setLoading(false);
    }

    const fetchCourses = async () => {
        const response = await fetch(`${API_BASE}get-tutor-published-course-list?page=0`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            let defaultValue = '';
            // console.info(data.data.courses, "This is it");
            setCourses(data.data.courses);
            var stringCourse = [];
            data.data.courses.forEach(function (value, i) {
                stringCourse.push({ value: value.id, label: value.title });
            });
            setCourseOptions(stringCourse);
            if (defaultValue !== '') {
                setSelectedCourse(stringCourse.find((c) => c.value === defaultValue))
            }
        }
    }

    const fetchStudentEmployees = async (cid) => {

        let page = 1;
        let search = '';
        setProcessing(true);
        const response = await fetch(`${API_BASE}course-students/${cid}?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            var stringEmp = [];
            // console.log(data.data.students, "This is emp");
            data.data.students.forEach(function (value, i) {
                if (value.is_corp_emp) {
                    stringEmp.push({ value: value.email, label: value.name });
                }
            });
            setEmpOptions(stringEmp);
            setFetchingStuEmp(false);
        } else {
            setEmpOptions([]);
            setFetchingStuEmp(false);
        }
        setProcessing(false);
    }



    const defaultColDef = {
        resizable: true,
        editable: false,
        filter: true,
        sortable: true,
        floatingFilter: true,
        suppressNavigable: true,
    }


    const columnDefs = [
        {
            headerName: "Sr.",
            valueGetter: "node.rowIndex + 1",
            filter: false,
            sortable: false,
            floatingFilter: false,
            width: 70,
            minWidth: 70,
            colId: "No"
        },
        {
            headerName: "Full Name",
            field: 'full_name',
            cellRenderer: (params) => {
                return (
                    <>
                        <img src={params.data.image} alt="Profile Photo" width={45} height={45} ></img>
                        <span>&nbsp;&nbsp;{params.data.full_name}</span>
                    </>
                )
            },
            width: 250,
            minWidth: 250
        },
        {
            headerName: "Full Name",
            field: "full_name",
            hide: true,
            colId: "FName"
        },
        {
            headerName: "Role",
            field: 'corporate_emp_role',
            width: 200,
            minWidth: 200,
            colId: "Role"
        },
        {
            headerName: "Trained",
            field: 'email',
            flex: 3,
            valueGetter: (params) => {
                if (params.data.corporate_emp_status === 1) {
                    if (params.data.total_courses !== 0) {
                        let per = Math.floor((params.data.total_completed_courses * 100) / params.data.total_courses);
                        return `${per} %`;
                    } else {
                        return '-';
                    }
                }
                else if (params.data.corporate_emp_status !== 1) {
                    return '-';
                }
            },
            width: 70,
            minWidth: 70,
            colId: "Trained"
        },
        {
            headerName: "Enrollments",
            field: 'corporate_emp_status',
            valueGetter: (params) => {
                if (params.data.corporate_emp_status === 1) {
                    return params.data.total_courses;
                }
                else if (params.data.corporate_emp_status !== 1) {
                    return params.data.total_courses;
                }
            },
            width: 140,
            minWidth: 140,
            colId: "Enrollments"

        },
        {
            headerName: "Completed",
            field: 'corporate_emp_status',
            valueGetter: (params) => {
                if (params.data.corporate_emp_status === 1) {
                    return params.data.total_completed_courses;
                }
                else if (params.data.corporate_emp_status !== 1) {
                    return params.data.total_completed_courses;
                }
            },
            width: 120,
            minWidth: 120,
            colId: "Completed"

        },
        {
            headerName: "In Progress",
            field: 'corporate_emp_status',
            valueGetter: (params) => {
                if (params.data.corporate_emp_status === 1) {
                    return params.data.total_ongoing_courses;
                }
                else if (params.data.corporate_emp_status !== 1) {
                    return params.data.total_ongoing_courses;
                }
            },
            width: 140,
            minWidth: 140,
            colId: "InProgress"
        },

    ];

    const onClearEmp = () => {
        selectInputRef.current.clearValue();
    };

    const onSearch = async (form) => {
        setQueryProcessing(true);
        let _prompt = '';
        _prompt += 'I have an LRS named EduKula that stores learning records in a MySQL database. '; 
        _prompt += 'The table name is "lrs_statements". '; 
        _prompt += 'There is another table "lrs_agents" which stores Learners name and email. This table can be used as a reference to get Learners name by email. This table has "agent_name" and "agent_email" columns. '; 

        _prompt += 'The learning records that are being stored as JSON format in the database table. The statement strucuture follows xAPI standards. '; 
        _prompt += 'Here is detailed infomration about table columns of "lrs_statements" table:';
        _prompt += 'a) "statement_agent" : Stores email address of the agent or actor.';
        _prompt += 'b) "statement_context" : Stores information about activity. Example Value : {"contextActivities":{"parent":{"id":"https:\/\/ae.edukula.com\/courses","definition":{"name":{"en-US":"Soft Skills for Intermediate Learners"},"description":{"en-US":"Soft Skills for Intermediate Learners"},"type":"http:\/\/adlnet.gov\/expapi\/activities\/course"}}},"registration":"bd8b5147-06a1-4de7-b0d8-49a83a0bf745"}';
        _prompt += 'c) "statement_verb" : Stores full information about statement verb in json. Example Value: {"id":"http:\/\/adlnet.gov\/expapi\/verbs\/completed","display":{"en-US":"completed"}}  OR {"id":"http:\/\/adlnet.gov\/expapi\/verbs\/initialized","display":{"en-US":"initialized"}}';
        _prompt += 'd) "statement_activity_verb" : Stores activity verb as a single word. Example values : initialized, completed, registered etc';
        _prompt += 'e) "statement_duration" : Stores total seconds that a learner has spent in this particular activity OR course. Here is the rule: If the value of "statement_activity_verb" of a row is "registered", then "statement_duration" of this row is considered as a whole course duration. ';
        _prompt += 'f) statement_activity_url : Stores URL of the course or the lessor or activity. Here is the rule : If this column has https or http at the begenning, then this record is of "activity" or "lesson". But if it does not have any http or https then it is considered as a "Course"';
        _prompt += 'g) statement_data : Stores whole xAPI statement as JSON.';
        _prompt += 'Your task is to convert NLP statement into sql Query to generate accurate output. Before you give result, make sure you understand the statement.'; 
        _prompt += 'Keep in mind that mysql database will have statements stored as JSON so it might have additional slashes in it. So you will have to prepare queries that way. ';
        _prompt += 'I will provide a statement like:';
        _prompt += '- "Which is the most engaging/least engaging course?"';
        _prompt += '- "Which type of content is being more engaging for learners?"';
        _prompt += 'Do not use your own words in the SQL statement you write and follow only xAPI standards. For example, when NLP statement says Passed, consider it as Completed.';
        _prompt += 'Do not get confused between course and activity. See the point f)';
        _prompt += 'You will return the output as MySQL query. ';
        _prompt += 'Here is the first NLP statement for this job : "'+form.query+'” ';


        var element = document.getElementById('ekresult');
        element.innerHTML ='';






        //console.info(_prompt);
        try {
            EduKulaAi.createCompletion({
                model: "text-davinci-003",
                prompt: _prompt,
                temperature: 0.1,
                max_tokens: 4097-(_prompt.length),
            }).then((completion) => {
                const responseText = completion.data.choices[0].text;
                setQueryProcessing(false);
                //console.info(responseText);

                element.innerHTML ='';




                var _rep_1_request_json = { 'query': responseText};
                const response_n = fetch(`${LRS_ENDPOINT}report/generate-report`, {
                    method: 'POST',
                    body: JSON.stringify(_rep_1_request_json)
                });

                response_n.then(async (response) => {
                    const textContent = await response.text();
                    console.info(textContent);
                    
    
                    // Set the innerHTML of the element
                    element.innerHTML =textContent;


                }).catch((error) => {
                    console.error("Error fetching API:", error);
                });












                console.info(response_n);
                //let data_n = response_n.text();
                //alert(data_n);
/*
                console.info(data_n);


                var element = document.getElementById('ekresult');
    
                // Set the innerHTML of the element
                element.innerHTML =data_n;


*/






            }).catch((error) => {
                SwalBox.fire({
                    title: 'Something went wrong',
                    text: 'Please try again after some time.',
                    icon: 'error',
                });
                setQueryProcessing(false);
            });
        }catch(err) { 
            setTimeout(() => {
                setQueryProcessing(false);
            }, 1500);
        }
        //console.log(form);
    }


    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ columnKeys: ['No', 'FName', 'Role', 'Trained', 'Enrollments', 'Completed', 'InProgress'] });
        /*
        const gridApi       = gridRef.current.api;
        const visibleData   = [];
        gridApi.forEachNodeAfterFilterAndSort((node) => {   visibleData.push(node.data);    });
        const manipulatedData = visibleData.map((dataItem) => {
            return {
                full_name: (
                    <>
                    {dataItem.full_name}
                    </>
                ).props.children,
                role: (
                    <>
                    {dataItem.corporate_emp_role}
                    </>
                ).props.children,

                // ...
            };
        });
        console.log(manipulatedData);
        */
    }, []);


    const onBtnPrint = useCallback(() => {


    }, []);


    const onSearchSelect = async (form) => {
        setFilterProcessing(true);
        let _existing_leaderboard_rows = employees2;
        let _updated_leaderboard_rows = [];
        let _total_time = 0;

        if (_existing_leaderboard_rows.length > 0) {
            for (let [i, _existing_leaderboard_row] of _existing_leaderboard_rows.entries()) {
                if (_existing_leaderboard_row.total_courses != 0) {
                    studentEmp.map((std) => {
                        if (_existing_leaderboard_row.email === std) {
                            // console.log(std);
                            var _req_slug = selected_course;
                            var _req_agent = _existing_leaderboard_row.email;
                            var _rep_1_request_json = { 'slug': _req_slug, 'agent': _req_agent };
                            const response_n = fetch(`${LRS_ENDPOINT}statements/get-course-duration?slug=` + _req_slug + '&agent=' + _req_agent, {
                                method: 'POST',
                                body: JSON.stringify(_rep_1_request_json)
                            });
                            let data_n = response_n.json();
                            let _duration = data_n.duration;

                            console.log(_duration);
                        }
                        // console.log('_existing_leaderboard_row');
                    })
                    // console.log(_existing_leaderboard_row);

                }
            }
        }
        setTimeout(() => {
            setFilterProcessing(false);
        }, 1500);





    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Learners Progress'}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head"><h4 className="generate-report">Reports</h4></div>
                    <div className="learning-circle-courses">
                        <section>
                            <div className="profile_user_middle_section">
                                <div className="container">
                                    <div className="row ek-rw-custom-query">
                                        <form onSubmit={handleSubmit(onSearchSelect)} className="ek-frm-custom-query">
                                            <div className="col-lg-4 ek-mb-0 ek-rw-inline-block">
                                                <div className="form-group">
                                                    <label className="m-0" htmlFor={'course_id'}>Select Course</label>
                                                    <>
                                                        <Controller
                                                            control={control}
                                                            name="course_id"
                                                            id="course_id"
                                                            rules={{ required: false, message: "asfsf" }}
                                                            render={({ field: { onChange, value, name, ref } }) => (
                                                                <Select
                                                                    inputRef={ref}
                                                                    classNamePrefix="addl-class"
                                                                    options={course_options}
                                                                    onChange={(val) => {
                                                                        onClearEmp();
                                                                        setSelectedCourse(val.value);
                                                                        fetchStudentEmployees(val.value);
                                                                        setValue('course_id', val.value);
                                                                    }}
                                                                    searchable={true}
                                                                />
                                                            )}
                                                        />
                                                    </>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 ek-mb-0 ek-rw-inline-block">
                                                <div className="form-group">
                                                    <label className="m-0" htmlFor={'course_id'}>Select Employee</label>
                                                    <Controller
                                                        control={control}
                                                        name="employee_id"
                                                        id="employee_id"
                                                        rules={{ required: false, message: "asfsf" }}
                                                        render={({ field: { onChange, value, name, ref2 } }) => (
                                                            <Select
                                                                isDisabled={fetchingStuEmp}
                                                                ref={selectInputRef}
                                                                inputRef={ref2}
                                                                classNamePrefix="addl-class"
                                                                options={emp_options}
                                                                isMulti
                                                                onChange={(val) => {
                                                                    var selectEmp = [];
                                                                    val.forEach(function (value, i) {
                                                                        selectEmp.push(value.value);
                                                                    });
                                                                    SetStudentEmp(selectEmp);
                                                                    setValue('employee_id', selectEmp);
                                                                }}
                                                                searchable={true}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 ek-rw-inline-block">
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-circle btn-md submit-pink-btn">
                                                        {(filter_processing === true) ? <i className="fa fa-refresh fa-spin"></i> : 'Filter Result'}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="row ek-rw-custom-query">
                                        <form className="ek-frm-custom-query" key={2} onSubmit={handleSubmit2(onSearch)}>
                                            <div className="col-lg-10 ek-mb-20 ek-rw-inline-block">
                                                <div className="form-group">
                                                    <label className="m-0" htmlFor={'course_id'}><strong>Custom Query</strong> (Write a query to get customized result)</label>
                                                    <input
                                                        type="text"
                                                        autoComplete="none"
                                                        name="custom_query"
                                                        id="custom_query"
                                                        placeholder="e.g. Top 10 learners with the highest number of course completions"
                                                        className="form-control"
                                                        {...register2("query", {
                                                            required: "required",
                                                        })}
                                                        onChange={(e) => { setSearchQuery(e.target.value) }}

                                                    />

                                                    <span className="text-danger">
                                                        {errors2.query && errors2.query.type === "required" && 'Please write query'}
                                                    </span>
                                                </div>

                                            </div>
                                            <div className="col-lg-2 ek-mb-20 ek-rw-inline-block">
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-circle btn-md submit-pink-btn">{(query_processing === true) ? <i className="fa fa-refresh fa-spin"></i> : 'Apply'}</button>
                                                </div>
                                            </div>

                                            <div id="ekresult" className="col-lg-12 ek-mb-20 ek-rw-inline-block">



                                            </div>
                                        </form>



                                    </div>

                                    <div className="row ek-rw-custom-query">
                                        <div className="col-lg-12">
                                            <div className="custom-user-profile-content custom-learning-circle-content chatPage">
                                                <div className='QuizSelected'>
                                                    <h5>Learners Progress</h5>
                                                    <button className='GenVid GenQueBank tour-employee-step-02' onClick={() => { onBtnExport() }}>Export to CSV</button>
                                                    <button className='GenVid GenQueBank tour-employee-step-02' onClick={() => { onBtnPrint() }}>Print</button>
                                                </div>
                                                <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
                                                    <AgGridReact
                                                        // onGridReady={onGridReady}
                                                        columnDefs={columnDefs}
                                                        // columnDefs={QuizData}
                                                        ref={gridRef}
                                                        defaultColDef={defaultColDef}
                                                        rowData={rowData}
                                                        domLayout="autoHeight"
                                                        enableFilter={true}
                                                        enableSorting={true}
                                                        enableColResize={true}
                                                        pagination={true}
                                                        paginationPageSize={20}
                                                        rowHeight={50}
                                                        suppressRowClickSelection={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportDashboard;
