import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const CourseNotPublished = ({ ntfcn }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src="images/logo_icon.png" className="rounded-circle" alt="Profile Image" />
                    </div>
                    <div className="user-details-title">
                        <h6>EduKula <span>{ntfcn.date_time}</span></h6>
                        <p>{t('_hr_report_tour_t1_')} - {ntfcn?.course?.title}</p>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>{t('_dh_coursenotpublished_m1_')} <strong>{ntfcn?.course?.title}</strong> {t('_dh_coursenotpublished_m2_')}</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link" to={'/dashboard/tutor/edit-course/' + ntfcn?.course?.id} target="_blank">
                            <img src={ntfcn?.course?.image} alt={ntfcn?.course?.title} />
                            <span>{t('_dh_coursenotpublished_m3_')} </span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CourseNotPublished;
