import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import CustomPagination from "../../entity/CustomPagination";
import { Toaster } from "../../entity/GlobalJS";
import { useTranslation } from "react-i18next";

const Meetings = ({ course, certified, expier }) => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const _token = localStorage.getItem('lmstoken');
    const userData = JSON.parse(localStorage.getItem('lmsuser'));
    const [list_timezones, setListTimezones] = useState('');
    const [requests, setRequests] = useState('');
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [submitting, setSubmitting] = useState(false);
    const [total_count, setTotalCount] = useState(0);
    const [meetings, setMeetings] = useState('');
    const [min_time, setMinTime] = useState('');
    const [time_zone_time, setTimeZoneTime] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        fetchTimezones();
        fetchRequests();
        fetchMeetings(currentPage);
    }, [location, reload]);

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }

    const fetchRequests = async () => {
        const response = await fetch(`${API_BASE}get-session-requests/${course.id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setRequests(data.data.slots);
        } else {
            setRequests('');
        }
    }

    const fetchMeetings = async (page = 0) => {
        const response = await fetch(`${API_BASE}get-course-meetings/${course.id}/true`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setMeetings(data.data.meetings);
            setTotalCount(data.data.total_count);
        } else {
            setMeetings('');
            setTotalCount(0);
        }
    }

    const fetchTimezones = async () => {
        const response = await fetch(`${API_BASE}get-all-timezones`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setListTimezones(data.data.timezones);
        } else {
            setListTimezones('');
        }
    }

    const clearForm = () => {
        setValue('timezone', userData?.timezone);
        setValue('title');
        setValue('agenda');
        setValue('date');
        setValue('time');
        setValue('duration');
    }

    const saveMeeting = async (form) => {
        setSubmitting(true);

        const response = await fetch(`${API_BASE}post-request-time-slot`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(form)
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            document.getElementById('SectionModalLongClose').click();
            fetchRequests();
            clearForm();
        } else {
            Toaster.error(data.message);
        }

        setSubmitting(false);
    }

    const chnageRequestStatus = async (id, status) => {
        const response = await fetch(`${API_BASE}cancel-course-meeting/${id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            fetchRequests();
        } else {
            Toaster.error(data.message);
        }
    }

    const getDateTime = () => {
        let timeZone = document.getElementById('timeZone').value;
        let obj = list_timezones.find(o => o.time_zone === timeZone);
        let selectedOffset = Number(obj.time_zone_offset.replace('GMT', '').replace(':', '.'));
        let myTimeOffset = Number((new Date().getTimezoneOffset() / 60));
        let differ = myTimeOffset + selectedOffset;
        let currentTime = new Date().getTime();
        let updatedTIme = new Date(currentTime + differ * 60 * 60 * 1000);

        let date = document.getElementById('date_field').value;
        if (typeof date !== 'undefined' && date === new Date().toISOString().split('T')[0]) {
            setMinTime(updatedTIme.getHours() + ':' + updatedTIme.getMinutes());
        }
        setTimeZoneTime(updatedTIme.getHours() + ':' + updatedTIme.getMinutes());
        setValue('time_zone_time', updatedTIme.getHours() + ':' + updatedTIme.getMinutes());
    }

    const setTime = (date) => {
        if (date === new Date().toISOString().split('T')[0]) {
            setMinTime(new Date().getHours() + ':' + new Date().getMinutes());
        }
        else {
            setMinTime('');
        }
    }

    return (
        <>
            <div className="modal fade book_session_popup" id="SectionModalLong" tabIndex="-1" role="dialog" aria-labelledby="SectionModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 id="SectionModalLongTitle">{t('_learncourse_meeting_msg_m1_')} </h4>
                            <button type="button" className="close" id="SectionModalLongClose" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit(saveMeeting)}>
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <div className="form-group">
                                            <select {...register("timezone")} id="timeZone" onChange={(e) => { getDateTime(); }} className="form-control">
                                                <option value="">{t('_learncourse_meeting_msg_m2_')} </option>
                                                {
                                                    list_timezones !== ''
                                                    &&
                                                    list_timezones.map((timezone, index) =>
                                                        <option key={index} value={timezone.time_zone}>{timezone.locations}  ({timezone.time_zone_offset})</option>
                                                    )
                                                }
                                            </select>
                                            <span className="text-danger">
                                                {errors.timezone && errors.timezone.type === "required" && t('_learncourse_meeting_timezone_vali_m1_')}
                                                {errors.timezone && errors.timezone.type !== "required" && errors.timezone.message}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <input {...register("course_id")} type="hidden" defaultValue={course.id} />
                                            <input id="title" {...register("title", {
                                                required: "required",
                                                maxLength: {
                                                    value: 80,
                                                    message: t('_lcmeeting_addmeet_title_vali_m1_')
                                                }
                                            })} type="text" className="form-control" placeholder={t('_lcmeeting_addmeet_topic_')} />
                                            <span className="add-on max-chr-counter chrchk-title" data-ele="title" data-limit="80" data-showcounter="false"></span>
                                            <span className="text-danger">
                                                {errors.title && errors.title.type === "required" && t('_lcmeeting_addmeet_timezone_vali_m2_')}
                                                {errors.title && errors.title.type !== "required" && errors.title.message}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <textarea {...register("agenda", { required: "required", })} className="form-control" placeholder={t('_lcmeeting_addmeet_agenda_')} rows="5"></textarea>
                                            <span className="text-danger">
                                                {errors.agenda && errors.agenda.type === "required" && t('_lcmeeting_addmeet_agenda_vali_m1_')}
                                                {errors.agenda && errors.agenda.type !== "required" && errors.agenda.message}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <input {...register("date", { required: "required" })} id="date_field" type="date" min={new Date().toISOString().split('T')[0]} className="form-control" placeholder={t('_lcmeeting_addmeet_date_')} onChange={(e) => { setTime(e.target.value) }} />
                                            <span className="text-danger">
                                                {errors.date && errors.date.type === "required" && t('_lcmeeting_addmeet_date_vali_m1_')}
                                                {errors.date && errors.date.type !== "required" && errors.date.message}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <input
                                                {...register("time", { required: "required" })}
                                                type="time"
                                                min={min_time}
                                                className="form-control"
                                                placeholder={t('_lcmeeting_addmeet_time_')}
                                            />
                                            <span className="text-danger">
                                                {errors.time && errors.time.type === "required" && t('_lcmeeting_addmeet_time_vali_m1_')}
                                                {errors.time && errors.time.type !== "required" && errors.time.message}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <input {...register("duration", {
                                                required: "required",
                                                min: {
                                                    value: 10,
                                                    message: t('_lcmeeting_addmeet_duration_vali_m2_')
                                                },
                                                max: {
                                                    value: 200,
                                                    message: t('_lcmeeting_addmeet_duration_vali_m3_')
                                                }
                                            })} type="number" className="form-control" placeholder={t('_lcmeeting_addmeet_duration_')} />
                                            <span className="text-danger">
                                                {errors.duration && errors.duration.type === "required" && t('_lcmeeting_addmeet_duration_vali_m1_')}
                                                {errors.duration && errors.duration.type !== "required" && errors.duration.message}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">{t('_hr_close_')}</button>
                                <button disabled={submitting} type="submit" className="btn btn-circle btn-md submit-pink-btn">{submitting ? <i className="fa fa-refresh fa-spin fa-fw"></i> : t('_v_add_submit_')}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-pane fade show active" id="pills-live" role="tabpanel" aria-labelledby="pills-live-tab">
                <div className="blog-card">
                    <div className="row justify-content-lg-center">
                        {
                            expier === 0 && (course.mode_of_learning === 'One To One')
                            &&
                            <>
                                <div className="col-md-12 col-lg-10">
                                    <button className="btn btn-success float-right" data-toggle="modal" data-target="#SectionModalLong" onClick={(e) => { clearForm(); }}>{t('_learncourse_meeting_msg_m1_')} </button>
                                    <h5>{t('_learncourse_meeting_requested_ses_')} </h5>
                                    <div className="meetingdata mt-3">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th width="120">{t('_lcmeeting_meeting_opt1_')} </th>
                                                    <th width="240">{t('_lcmeeting_meeting_opt2_')} </th>
                                                    <th width="90">{t('_mylc_circlejoined_create_')}</th>
                                                    <th width="90">{t('_lcmeeting_meeting_opt3_')} </th>
                                                    <th width="100">{t('_lcmeeting_meeting_opt5_')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    requests !== ''
                                                    &&
                                                    requests.map((request, index) =>
                                                        <tr key={index}>
                                                            <td>{request.date},<br />{request.start_time}</td>
                                                            <td><p><strong>{request.meeting_title}</strong>{request.short_desc}</p></td>
                                                            <td>{request.student.name}</td>
                                                            <td>{request.duration}</td>
                                                            <td>
                                                                <a onClick={(e) => { chnageRequestStatus(request.id, 3); }} className="cursor-pointer text-danger p-1"><i className="fa fa-trash-alt"></i></a>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                                {
                                                    requests === ''
                                                    &&
                                                    <tr>
                                                        <td className="text-center bg-white" colSpan={5}>{t('_learncourse_meeting_requested_no_ses_')}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </>
                        }

                        <div className="col-md-12 col-lg-10">
                            <h5 className="pt-5">{t('_managelc_meetings_')} </h5>
                            <div className="meetingdata mb-5">
                                <table>
                                    <thead>
                                        <tr>
                                            <th width="120">{t('_lcmeeting_meeting_opt1_')} </th>
                                            <th width="240">{t('_lcmeeting_meeting_opt2_')}</th>
                                            <th width="90">{t('_mylc_circlejoined_create_')} </th>
                                            <th width="90">{t('_lcmeeting_meeting_opt3_')} </th>
                                            <th width="90">{t('_lcmeeting_meeting_opt4_')} </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            meetings !== ''
                                            &&
                                            meetings.map((meeting, index) =>
                                                <tr key={index}>
                                                    <td>{meeting.date},<br />{meeting.time}</td>
                                                    <td><p><strong>{meeting.meeting_title}</strong>{meeting.short_desc}</p></td>
                                                    <td>{meeting.creator.name}</td>
                                                    <td>{meeting.duration}</td>
                                                    <td className="orange_color">
                                                        <MeetingButton meeting={meeting} />
                                                    </td>
                                                </tr>
                                            )
                                        }

                                        {
                                            meetings === ''
                                            &&
                                            <tr>
                                                <td className="text-center bg-white" colSpan={6}>{t('_learncourse_meeting_no_sess_')}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                <div className="clearfix"></div>

                                {
                                    total_count > 10
                                    &&
                                    <div className="custom-pagination">
                                        <nav aria-label="Page navigation example">
                                            <CustomPagination pageCount={total_count} perPageRecord={10} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                                        </nav>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Meetings;

const MeetingButton = ({ meeting }) => {
    const _token = localStorage.getItem('lmstoken');
    const [join, setJoin] = useState(false);
    const [isLoader, setIsLoader] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        checkMeeting();
        setIsLoader(true);
    }, []);

    setTimeout(() => {
        checkMeeting();
    }, 2000);

    setTimeout(() => {
        setIsLoader(false);
    }, 3000);

    const checkMeeting = async () => {
        const response = await fetch(`${API_BASE}check-meeting-status/${meeting.id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setJoin(true);
        } else {
        }
    }

    const JoinMeeting = async (meeting_id) => {
        const response = await fetch(`${API_BASE}join-course-meeting/${meeting_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            window.open(
                `${data.data}`,
                '_blank'
            );
            // window.location.href = data.data;
        } else {
            Toaster.error(data.message);
        }
    }

    return (
        <>
            {isLoader && join ? (<button className="btn btn-circle btn-info ml-1 mb-1"><i className="fa fa-refresh fa-spin"></i></button>) : (
                join &&
                <button onClick={(e) => { JoinMeeting(meeting.id); }} className="btn btn-circle btn-info ml-1 mb-1"> {t('_lcmeeting_meetbtn_join_')} </button>
            )
            }

            {isLoader && !join ? (<button className="btn btn-circle btn-info ml-1 mb-1"><i className="fa fa-refresh fa-spin"></i></button>) :
                (!join
                    &&
                    meeting.status
                )
            }
        </>
    );
}
