import { Link } from "react-router-dom";
import { API_BASE } from "../../../constants/global";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import { useTranslation } from "react-i18next";

const CourseCard = ({ course, is_completed, refresh }) => {
    const _token = localStorage.getItem('lmstoken');
    const { t } = useTranslation();

    const ConfirmLeave = (course_id) => {
        SwalBox.fire({
            title: t('_courseilearn_card_alert_m1_'),
            text: t('_courseilearn_card_alert_m2_'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_lp_no_')
        }).then((result) => {
            if (result.isConfirmed) {
                leaveCourse(course_id);
            }
        });
    }

    const leaveCourse = async (course_id) => {
        const response = await fetch(`${API_BASE}leave-course/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token

            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            refresh();
        } else {
            // setProgs('');
        }
    }

    return (
        <>
            <div className="blog-card-detail">
                {
                    is_completed
                        ?
                        <Link className="pos_link" to={`/${'dashboard/student/course-certificate'}/${course.id}`}></Link>
                        :
                        <Link className="pos_link" to={`/${'learn-course'}/${course.slug}`}></Link>
                }
                <div className="custom-card-img">
                    {
                        !is_completed
                        &&
                        <>
                            <a className="ellipsbtn" id="MaterialsMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MaterialsMenuButton">
                                <a onClick={(e) => { ConfirmLeave(course.id); }} className="dropdown-item">{t('_courseilearn_card_opt1_')}</a>
                                <Link className="dropdown-item" to={`/${'dashboard/course-progress'}/${course.slug}`}>{t('_learncourse_course_progress_')} </Link>
                            </div>
                        </>
                    }
                    {/* <div className="badges">
                        <span className="selling">Best Selling</span>
                    </div> */}
                    <img src={course.image} className="card-img-top" alt={course.title} />
                </div>
                <div className="blog-card-inner">
                    <h5>{course.title}</h5>
                    <ul className="course_tabs_sub">
                        <li><a><i className="fas fa-list"></i><span>{course.category}</span></a></li>
                        <li><a><i className="fas fa-user-friends"></i><span>{course.mode} {t('_courseilearn_card_opt2_')}</span></a></li>
                        <li><a><i className="fas fa-tasks"></i><span>{course.progress}</span></a></li>
                        {
                            is_completed
                            &&
                            <li><Link to={`/${'dashboard/student/course-certificate'}/${course.id}`}><i className="text-primary fa fa-award"></i><span className="mt-1">{t('_courseilearn_card_opt3_')} </span></Link></li>
                        }
                    </ul>
                </div>
            </div>
        </>
    );
}

export default CourseCard;
