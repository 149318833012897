import { Link } from "react-router-dom";
import VerifiedBadge from "../../../../components/VerifiedBadge";
import { useTranslation } from "react-i18next";

const Register = ({ ntfcn }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src="images/logo_icon.png" className="rounded-circle" alt="Profile Image" />
                    </div>
                    <div className="user-details-title">
                        <h6>EduKula<VerifiedBadge _is_verified={true} refname="notification" /><span>{ntfcn.date_time}</span></h6>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>{t('_dh_studenttab_rgister_')}</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link" to="/courses" target="_blank">
                            <img src="images/check-out-our-courses.jpg" alt="Course Image" />
                            <span>{t('_dh_studenttab_rgister_m2_')} </span>
                        </Link>
                    </div>
                    <div className="like-main-area">
                        <div className=""></div>
                        <div className=""></div>
                        <div className=""></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;
