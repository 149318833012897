import { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const ContactUs = () => {
    const { register, handleSubmit, formState: { errors }, setValue, clearErrors, control, setError } = useForm();
    const recaptchaRef = useRef();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [is_agree, setIsAgree] = useState(0);
    const [is_submitting, setSubmitting] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    async function saveContactUs(form) {
        if (recaptchaValue === null) {
            setError('recaptcha', {
                type: 'manual',
                message: t('_contactus_vali_ma_')
            });
            return;
        }
        setSubmitting(true);
        const response = await fetch(API_BASE + 'post-contact-us', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(form)
        });
        clearErrors('recaptcha');
        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            if (recaptchaRef.current) {
                recaptchaRef.current.reset();
            }
            setValue('name', '');
            setValue('email', '');
            setValue('message', '');
            setIsAgree(0);
            setSubmitting(false);

        } else {
            if (recaptchaRef.current) {
                recaptchaRef.current.reset();
            }
            Toaster.error(data.message);
            setSubmitting(false);
        }
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_footer_contact_us_')}`}</title>
            </Helmet>
            {/* BREADCRUMB */}
            <section className="custom-section">
                <div className="custom-breadcrumb">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">{t('_dashboard_home_t3_')} </a></li>
                                <li className="breadcrumb-item active" aria-current="page">{t('_footer_contact_us_')} </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            {/* BREADCRUMB */}

            {/* CONTACT DETAILS */}
            <section className="custom-section">
                <div className="conatct-box-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="contact-detail-box phone-details">

                                    <div className="contact-box-info">
                                        <div className="contact-box-header red-box">
                                            <i className="fas fa-phone-volume"></i>
                                        </div>
                                        <div className="contact-box-body">
                                            <h3>{t('_phone_number_')} </h3>
                                            <p><a href="tel:+44 20 8144 6541">+44 20 8144 6541</a></p>
                                            <p><a href="tel:+1 586 840 6316">+1 586 840 6316</a></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="contact-detail-box mail-details">

                                    <div className="contact-box-info">
                                        <div className="contact-box-header blue-box">
                                            <i className="far fa-envelope"></i>
                                        </div>
                                        <div className="contact-box-body">
                                            <h3>{t('_emplist_ag_col_email_')} </h3>
                                            <p><a href="mailto:support@smartvisibility.com">support@smartvisibility.com</a></p>
                                            <p><a href="mailto:info@smartvisibility.com">info@smartvisibility.com</a></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="contact-detail-box address-details">

                                    <div className="contact-box-info">
                                        <div className="contact-box-header black-box">
                                            <i className="fas fa-map-signs"></i>
                                        </div>
                                        <div className="contact-box-body">
                                            <h3>{t('_contactus_msg_m1_')} </h3>
                                            <p>FF-107, Abhishree Adroit, Mansi Circle, Vastrapur, Ahmedabad - 380015. Gujarat, India.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* CONTACT DETAILS */}

            {/* CONTACT FORM */}
            <section className="custom-section">
                <div className="contact-form-section">
                    <div className="container">
                        <h3 className="text-center">{t('_contactus_msg_m2_')} </h3>
                        <p className="text-center custom-section">{t('_contactus_msg_m3_')} </p>
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="contact-form-details">
                                    <div className="contact-form-img">
                                        <img src="images/contact-form.png" alt="Contact" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="contact-form-details">
                                    <div className="contact-form-desc">
                                        <form onSubmit={handleSubmit(saveContactUs)}>
                                            <div className="form-group">
                                                <input {...register("name", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^[a-z A-Z]+$/,
                                                        message: t('_contactus_msg_m4_')
                                                    },
                                                    maxLength: {
                                                        value: 30,
                                                        message: t('_contactus_msg_m5_')
                                                    }
                                                })} onChange={(event) => { setName(event.target.value); clearErrors('name'); }} type="text" className="form-control" placeholder={t('_contactus_msg_m6_')} defaultValue={name ? name : ''} />
                                                <span className="text-danger">
                                                    {errors?.name && errors?.name?.type === "required" && t('_contactus_msg_m7_')}
                                                    {errors?.name && errors?.name?.type !== "required" && errors?.name?.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <input {...register("email", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: t('_hr_email_error_msg_')
                                                    }
                                                })} onChange={(event) => { setEmail(event.target.value); clearErrors('email'); }} type="text" className="form-control" placeholder={t('_contactus_msg_m8_')} defaultValue={email ? email : ''} />
                                                <span className="text-danger">
                                                    {errors.email && errors.email.type === "required" && t('_contactus_msg_m9_')}
                                                    {errors.email && errors.email.type !== "required" && errors.email.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <textarea {...register("message", {
                                                    required: "required",
                                                    maxLength: {
                                                        value: 500,
                                                        message: t('_contactus_msg_m10_')
                                                    }
                                                })} onChange={(event) => { setMessage(event.target.value); clearErrors('message'); }} className="form-control" name="message" placeholder={t('_contactus_msg_m11_')} rows="7" data-form-field="Message" defaultValue={message ? message : ''}></textarea>
                                                <span className="text-danger">
                                                    {errors.message && errors.message.type === "required" && t('_contactus_msg_m12_')}
                                                    {errors.message && errors.message.type !== "required" && errors.message.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <Controller
                                                    name="recaptcha"
                                                    control={control}
                                                    render={({ onChange, onBlur }) => (
                                                        <ReCAPTCHA
                                                            ref={recaptchaRef}
                                                            sitekey="6Lc7XD4lAAAAAA5YHYwBCSEJA9Aj3sGE90EXLNml"
                                                            onChange={(value) => { setRecaptchaValue(value); clearErrors('recaptcha') }}
                                                            onExpired={() => { setRecaptchaValue(null) }}
                                                        />
                                                    )}
                                                />
                                                {errors.recaptcha && <p className='text-theme_red font-WorkSans_Regular'>
                                                    <span className="text-danger">{errors.recaptcha.message}</span></p>}
                                            </div>
                                            <div className="form-group">
                                                <div className="form-desc">
                                                    <input type="checkbox" {...register("agree", { required: "required" })} onChange={(e) => { setIsAgree(e.target.checked ? 1 : 0); }} id="contactform_terms" checked={is_agree === 1 ? 'checked' : ''} defaultValue={1} />
                                                    <label htmlFor="contactform_terms" className="mb-0"><p>{t('_contactus_msg_m14_')} <Link to="/pages/terms-of-use"> {t('_contactus_msg_m13_')} </Link> {t('_contactus_msg_m15_')} <Link to="/pages/privacy-policy"> {t('_contactus_msg_m16_')} </Link></p></label>
                                                </div>
                                                <span className="text-danger">
                                                    {errors.agree && errors.agree.type === "required" && t('_contactus_msg_m17_')}
                                                </span>
                                            </div>
                                            <button disabled={is_submitting ? true : false} type="submit" className="btn btn-circle btn-success">{is_submitting ? <i className="fas fa-spinner fa-spin"></i> : t('_v_add_submit_')}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* CONTACT FORM */}
        </>
    );
}

export default ContactUs;
