import { Link } from "react-router-dom";
import VerifiedBadge from "../../../../components/VerifiedBadge";
import { useTranslation } from "react-i18next";

const CoursePublished = ({ ntfcn }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src="images/logo_icon.png" className="rounded-circle" alt="Profile Image" />
                    </div>
                    <div className="user-details-title">
                        <h6>EduKula<VerifiedBadge _is_verified={true} refname="notification" /><span>{ntfcn.date_time}</span></h6>
                        <p>{t('_hr_report_tour_t1_')} - {ntfcn?.course?.title}</p>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>{t('_dh_coursepublished_m1_') + " "}<strong>{ntfcn?.course?.title}</strong> {" " + t('_dh_coursepublished_m2_')}</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link ek-lrs-course-initialized" to={'/dashboard/tutor/manage-course/' + ntfcn?.course?.id} target="_blank" data-ek-lrs-url={`/course-details/${ntfcn?.course?.slug}`} data-ek-lrs-title={ntfcn?.course?.title}>
                            <img src={ntfcn?.course?.image} alt={ntfcn?.course?.title} />
                            <span>{t('_dh_coursepublished_m3_')}</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CoursePublished;
