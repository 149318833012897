import landingInIt from './landing';
import { useContext, useEffect } from 'react';
import authContext from '../../context/auth/authContext';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const ForCorporates = () => {
    const cntxAuth = useContext(authContext);
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
        landingInIt();
    }, []);

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_fc_title_')}`}</title>
            </Helmet>
            <div className='forcorporate'>
                <section className="business_pagebanner">
                    <div className="container">
                        <div className="banner_detail">
                            <img src="images/business_banner_img.png" alt="" />
                            <div>
                                <h1>{t('_fc_train_msg_m1_')} <br /> {t('_fc_train_msg_m2_')} </h1>
                                {
                                    cntxAuth.isLogged
                                        ?
                                        null
                                        :
                                        <Link to={`/corporate-register`} className="btn">{t('_fc_train_msg_m3_')} </Link>
                                }
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </section>
                <div className="clearfix"></div>

                <section className="traing_solution_section">
                    <h2>{t('_fc_train_msg_m4_')}</h2>
                    <section className="marquees-wrapper">
                        <div className="marquee marquee-1">
                            <ul className="marquee__content scroll">
                                <li><img src="./images/business_top_slider1.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider2.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider3.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider4.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider5.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider6.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider7.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider8.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider9.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider10.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider11.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider12.jpg" alt="" /></li>
                            </ul>
                            <ul className="marquee__content scroll" aria-hidden="true">
                                <li><img src="./images/business_top_slider1.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider2.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider3.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider4.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider5.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider6.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider7.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider8.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider9.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider10.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider11.jpg" alt="" /></li>
                                <li>&nbsp;</li>
                                <li><img src="./images/business_top_slider12.jpg" alt="" /></li>
                            </ul>
                        </div>
                    </section>
                    <div className="clearfix"></div>
                </section>
                <div className="clearfix"></div>

                <div className="desktop-only">
                    <div className="tm-hdg-24 sticky fd-title text-black lg:tm-hdg-40 ft-heading-circle">
                        <h2>{t('_fc_train_msg_m5_')} </h2>
                    </div>
                    <div className="section1_bg">
                        <div className="container">
                            <div className="feature-det-cont" id="ft-id-1">
                                <div className="flex-box">
                                    <div className="ft-left-cont">
                                        <div className="ft-det-sticky-part">
                                            <h3><img src="images/busines_icon_1.png" alt="" /> {t('_fc_train_msg_m6_')}</h3>
                                        </div>
                                        <div className="relative">
                                            <div className="text-left">
                                                <div id="sm-1" className="cursor-pointer text_line">
                                                    <div className="mordern-title">
                                                        <h4>{t('_fc_train_msg_m7_')} </h4>
                                                    </div>
                                                    <div className="tm-para-16 mordern-para mt-2.5">
                                                        <p>{t('_fc_train_msg_m8_')}</p>
                                                    </div>
                                                    <div className="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-left">
                                                <div id="sm-2" className="cursor-pointer text_line">
                                                    <div className="mordern-title">
                                                        <h4>{t('_fc_train_msg_m9_')} </h4>
                                                    </div>
                                                    <div className="tm-para-16 mordern-para mt-2.5">
                                                        <p>{t('_fc_train_msg_m10_')}</p>
                                                    </div>
                                                    <div className="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-left">
                                                <div id="sm-3" className="cursor-pointer text_line">
                                                    <div className="mordern-title">
                                                        <h4>{t('_fc_train_msg_m11_')}</h4>
                                                    </div>
                                                    <div className="tm-para-16 mordern-para mt-2.5">
                                                        <p>{t('_fc_train_msg_m12_')}</p>
                                                    </div>
                                                    <div className="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ft-right-cont">
                                        <div className="feature-right-img-cont custom-feedback-box-shadow">
                                            <div id="testimonial-carousel" className="h-full w-full">
                                                <div className="carousel-wrapper h-full w-full">
                                                    <div className="carousel h-full w-full flex justify-content">
                                                        <div className="sm-1-carousel-slide-item active">
                                                            <div className="">
                                                                <img src="images/business_top_slider1.jpg" alt="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div className="sm-1-carousel-slide-item ">
                                                            <div className="">
                                                                <img src="images/business_top_slider2.jpg" alt="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div className="sm-1-carousel-slide-item">
                                                            <div className="">
                                                                <img src="images/business_top_slider3.jpg" alt="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className="section2_bg">
                        <div className="container">
                            <div className="feature-det-cont" id="ft-id-2">
                                <div className="flex-box">
                                    <div className="ft-right-cont">
                                        <div className="feature-right-img-cont custom-feedback-box-shadow">
                                            <div id="testimonial-carousel" className="h-full">
                                                <div className="carousel-wrapper h-full w-full">
                                                    <div className="carousel h-full w-full flex justify-content">
                                                        <div className="sm-2-carousel-slide-item">
                                                            <div className="">
                                                                <img src="images/business_top_slider4.jpg" alt="" className="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div className="sm-2-carousel-slide-item">
                                                            <div className="">
                                                                <img src="images/business_top_slider5.jpg" alt="" className="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div className="sm-2-carousel-slide-item">
                                                            <div className="">
                                                                <img src="images/business_top_slider6.jpg" alt="" className="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ft-left-cont">
                                        <div className="ft-det-sticky-part">
                                            <h3><img src="images/busines_icon_2.png" alt="" /> {t('_fc_train_msg_m13_')}</h3>
                                        </div>
                                        <div className="relative">
                                            <div className="text-left">
                                                <div id="sm-4" className="cursor-pointer text_line">
                                                    <div className="mordern-title">
                                                        <h4>{t('_fc_train_msg_m14_')}</h4>
                                                    </div>
                                                    <div className="tm-para-16 mordern-para mt-2.5">
                                                        <p>{t('_fc_train_msg_m15_')}.</p>
                                                    </div>
                                                    <div className="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-left">
                                                <div id="sm-5" className="cursor-pointer text_line">
                                                    <div className="mordern-title">
                                                        <h4>{t('_fc_train_msg_m16_')}</h4>
                                                    </div>
                                                    <div className="tm-para-16 mordern-para mt-2.5">
                                                        <p>{t('_fc_train_msg_m17_')}</p>
                                                    </div>
                                                    <div className="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-left">
                                                <div id="sm-6" className="cursor-pointer text_line">
                                                    <div className="mordern-title">
                                                        <h4>{t('_fc_train_msg_m17_')}</h4>
                                                    </div>
                                                    <div className="tm-para-16 mordern-para mt-2.5">
                                                        <p>{t('_fc_train_msg_m18_')}</p>
                                                    </div>
                                                    <div className="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className="section1_bg">
                        <div className="container">
                            <div className="feature-det-cont" id="ft-id-3">
                                <div className="flex-box">
                                    <div className="ft-left-cont">
                                        <div className="ft-det-sticky-part">
                                            <h3><img src="images/busines_icon_3.png" alt="" /> {t('_fc_train_msg_m19_')}</h3>
                                        </div>
                                        <div className="relative">
                                            <div className="text-left">
                                                <div id="sm-7" className="cursor-pointer text_line">
                                                    <div className="mordern-title">
                                                        <h4>{t('_fc_train_msg_m20_')}</h4>
                                                    </div>
                                                    <div className="tm-para-16 mordern-para mt-2.5">
                                                        <p>{t('_fc_train_msg_m21_')}</p>
                                                    </div>
                                                    <div className="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-left">
                                                <div id="sm-8" className="cursor-pointer text_line">
                                                    <div className="mordern-title">
                                                        <h4>{t('_fc_train_msg_m22_')} </h4>
                                                    </div>
                                                    <div className="tm-para-16 mordern-para mt-2.5">
                                                        <p>{t('_fc_train_msg_m23_')}</p>
                                                    </div>
                                                    <div className="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-left">
                                                <div id="sm-9" className="cursor-pointer text_line">
                                                    <div className="mordern-title">
                                                        <h4>{t('_fc_train_msg_m24_')}</h4>
                                                    </div>
                                                    <div className="tm-para-16 mordern-para mt-2.5">
                                                        <p>{t('_fc_train_msg_m25_')}</p>
                                                    </div>
                                                    <div className="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ft-right-cont">
                                        <div className="feature-right-img-cont custom-feedback-box-shadow">
                                            <div id="testimonial-carousel" className="h-full">
                                                <div className="carousel-wrapper h-full w-full">
                                                    <div className="carousel h-full w-full flex justify-content">
                                                        <div className="sm-3-carousel-slide-item">
                                                            <div className="">
                                                                <img src="images/business_top_slider7.jpg" alt="" className="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div className="sm-3-carousel-slide-item">
                                                            <div className="">
                                                                <img src="images/business_top_slider8.jpg" alt="" className="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div className="sm-3-carousel-slide-item">
                                                            <div className="">
                                                                <img src="images/business_top_slider9.jpg" alt="" className=" " loading="lazy" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                    <div className="section2_bg">
                        <div className="container">
                            <div className="feature-det-cont" id="ml-sec-1">
                                <div className="flex-box">
                                    <div className="ft-right-cont">
                                        <div className="feature-right-img-cont custom-feedback-box-shadow">
                                            <div id="testimonial-carousel" className="h-full">
                                                <div className="carousel-wrapper h-full w-full">
                                                    <div className="carousel h-full w-full flex justify-content">
                                                        <div className="mordern-learning-carousel-slide-item">
                                                            <div className="">
                                                                <img src="images/business_top_slider10.jpg" alt="" className="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div className="mordern-learning-carousel-slide-item">
                                                            <div className="">
                                                                <img src="images/business_top_slider11.jpg" alt="" className="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                        <div className="mordern-learning-carousel-slide-item">
                                                            <div className="">
                                                                <img src="images/business_top_slider12.jpg" alt="" className="" loading="lazy" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ft-left-cont">
                                        <div className="ft-det-sticky-part">
                                            <h3><img src="images/busines_icon_4.png" alt="" /> {t('_fc_train_msg_m26_')}</h3>
                                        </div>
                                        <div className="relative">
                                            <div className="text-left">
                                                <div id="ml-sub-1" className="cursor-pointer text_line">
                                                    <div className="mordern-title">
                                                        <h4>{t('_fc_train_msg_m27_')} </h4>
                                                    </div>
                                                    <div className="tm-para-16 mordern-para mt-2.5">
                                                        <p>{t('_fc_train_msg_m28_')}</p>
                                                    </div>
                                                    <div className="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-left">
                                                <div id="ml-sub-2" className="cursor-pointer text_line">
                                                    <div className="mordern-title">
                                                        <h4>{t('_fc_train_msg_m29_')} </h4>
                                                    </div>
                                                    <div className="tm-para-16 mordern-para mt-2.5">
                                                        <p>{t('_fc_train_msg_m30_')}</p>
                                                    </div>
                                                    <div className="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-left">
                                                <div id="ml-sub-3" className="cursor-pointer text_line">
                                                    <div className="mordern-title">
                                                        <h4>{t('_fc_train_msg_m31_')} </h4>
                                                    </div>
                                                    <div className="tm-para-16 mordern-para mt-2.5">
                                                        <p>{t('_fc_train_msg_m32_')}</p>
                                                    </div>
                                                    <div className="animation-bar-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>

                <div className="clearfix"></div>

                {
                    cntxAuth.isLogged
                        ?
                        null
                        :
                        <div className="reg_biss_acc">
                            <div className="container">
                                <h2>{t('_fc_train_msg_m33_')} </h2>
                                <Link to={`/corporate-register`} className="btn">{t('_fc_train_msg_m34_')}</Link>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                }

                <div className="clearfix"></div>
            </div>
            <div className="clearfix"></div>
        </>
    );
}

export default ForCorporates;
