import { useEffect, useState } from 'react';
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { API_BASE } from "../../constants/global";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt.ts";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


function CreateResume() {

  const [activeStep, setActiveStep] = useState(1);
  const [completed, setCompleted] = useState(1);
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [headline, setHeadline] = useState('');
  const [phone, setPhone] = useState('');
  const { t } = useTranslation();


  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, setValue: setValue2, setError, clearErrors: clearErrors2 } = useForm(
    {
      mode: "all",
    }
  );

  const saveBasicDetails = async (form) => {
    console.log(form);
  }

  const user = JSON.parse(localStorage.getItem('lmsuser'));
  const navigate = useNavigate();
  // form validation rules
  const validationSchema = Yup.object().shape({
    education: Yup.array().of(
      Yup.object().shape({
        school_name: Yup.string().required('Please enter school name').min(3, 'Please enter at least 3 characters.').max(80, 'School name can not be more than 80 charaters.'),
        degree: Yup.string().required('Please Enter degree'),
        field_of_study: Yup.string().required('Please enter field of study').max(80, 'field of study can not be more than 80 charaters.'),
        grade: Yup.string().required('Please enter grade in the following pattern : A+, B').matches(/^.*[A-Za-z0-9][+%]?$/, 'Must match the following pattern : A+, B'),
        start_date: Yup.string().nullable().required('Please select start date'),
        end_date: Yup.string().nullable().required('Please select end date'),
      })
    )
  });
  const formOptions = { resolver: yupResolver(validationSchema) };


  // functions to build form returned by useForm() and useFieldArray() hooks
  const { register, control, handleSubmit, reset, formState: { errors }, clearErrors } = useForm(formOptions);

  // const { errors } = formState;
  const { fields, append, remove } = useFieldArray({ name: 'education', control });


  const EDUCATION_INITIAL_STATE = { school_name: "", degree: "", field_of_study: "", grade: "", start_date: "", end_date: "", description: "" };
  const _token = localStorage.getItem('lmstoken');
  const [formValues, setFormValues] = useState({ "education": [EDUCATION_INITIAL_STATE] });
  const [allowed, setAllowed] = useState('');
  const [startYear, setStartYear] = useState([]);
  const [startMonth, setStartMonth] = useState([]);
  const [endYears, setEndYears] = useState([]);
  const [endMonths, setEndMonths] = useState([]);
  const [current_month, setCurrentMonth] = useState('');
  const [current_year, setCurrentYear] = useState('');

  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation] = useCallbackPrompt(showDialog);

  const getYear = () => setCurrentYear(new Date().getFullYear());
  const getMonth = () => setCurrentMonth(new Date().getMonth());
  useEffect(() => {
    if (user?.is_corporate === 1) {
      navigate('/profile/general');
    }
    const fieldsLength = fields.length;
    for (let i = 0; i <= fieldsLength; i++) {
      remove(i);
    }
    getYear();
    getMonth();
    fetchEducations();
  }, []);

  useEffect(() => {
    if (showPrompt) {
      ConfirmSaveChanges();
    }
  }, [showPrompt]);

  const ConfirmSaveChanges = () => {
    SwalBox.fire({
      title: t('_confirm_save_title_'),
      text: t('_confirm_save_msg_'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonClass: 'button_confirm_class',
      cancelButtonColor: '#d33',
      cancelButtonClass: 'button_cancel_class',
      confirmButtonText: 'Save'
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit(saveEducation)();
        confirmNavigation();
      } else {
        confirmNavigation();
      }
    });
  }

  const testMonths = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  const yearOptions = [];
  for (let index = 0; index <= 12; index++) {
    yearOptions[index] = { value: index, label: testMonths[index] };
  }

  var years = [];
  for (let index = 1950; index <= new Date().getFullYear(); index++) {
    years.push(index);
  }

  let setMonthSelection = (i, e) => {
    let startMonth = e.target.value;
    document.getElementById(`end_date_${i}`).setAttribute('min', startMonth);
  }

  let handleChange = (i, e) => {
    const allow = e.target.value.replace(/[^a-z 0-9 +]/, '')
  }

  let addFormFields = () => {
    append(EDUCATION_INITIAL_STATE);
  }

  let removeFormFields = (i) => {
    remove(i);
  }

  const fetchEducations = async () => {
    const response = await fetch(API_BASE + 'get-user-educations', {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + _token
      },
    });

    const data = await response.json();
    if (data.status) {
      setFormValues({ "education": data.data });
      if (data.data.length > 0) {

        for (let i = 0; i <= data.data.length; i++) {
          remove(i);
        }
        data.data.map((ele, i) => {
          remove(i);
          append(ele);
        });
      }
      else {
        remove(0);
        append(EDUCATION_INITIAL_STATE);
      }
    } else {
      remove(0);
      append(EDUCATION_INITIAL_STATE);
      Toaster.error(data.message);
    }
  }

  const saveEducation = async (form) => {
    const response = await fetch(API_BASE + 'post-user-education', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + _token
      },
      body: JSON.stringify(form)
    });

    const data = await response.json();
    if (data.status) {
      Toaster.success(data.message);
      fetchEducations();
      setShowDialog(false);
    } else {
      Toaster.error(data.message);
    }
  }
  const formattedDate = new Date().toISOString().split('T')[0];



  const validationSchema3 = Yup.object().shape({
    experience: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required('Please enter title').min(3, 'Please enter at least 3 characters.').max(80, 'Title can not be more than 80 charaters.'),
        type: Yup.string().required('Please select employment type'),
        company: Yup.string().required('Please enter company name').min(3, 'Please enter at least 3 characters.').max(80, 'Company can not be more than 80 charaters.'),
        location: Yup.string().required('Please enter location').min(3, 'Please enter at least 3 characters.').max(80, 'Location can not be more than 80 charaters.'),
        industry: Yup.string().required('Please enter industry').min(2, 'Please enter at least 2 characters.').max(80, 'Industry can not be more than 80 charaters.'),
        start_date: Yup.string().nullable().required('Please select start date'),
        end_date: Yup.string().nullable().when("currently_working", {
          is: 0,
          then: Yup.string().required('Please select end date')
        }).when("currently_working", {
          is: false,
          then: Yup.string().required('Please select end date')
        }),
      })
    )
  });

  const formOptions3 = { resolver: yupResolver(validationSchema3) };

  // functions to build form returned by useForm() and useFieldArray() hooks
  const { register: register3, control: control3, handleSubmit: handleSubmit3, formState: { errors: errors3 }, clearErrors: clearErrors3, setValue: setValue3 } = useForm(formOptions3);
  // const { errors } = formState;
  const { fields: fields3, append: append3, remove: remove3 } = useFieldArray({ name: 'experience', control });

  const EXPERIENCE_INITIAL_STATE = { title: "", type: "", company: "", location: "", industry: "", start_date: "", end_date: "", description: "", experience_id: "", currently_working: false };
  // const _token = localStorage.getItem('lmstoken');
  const [formValues3, setFormValues3] = useState({ "experience": [EXPERIENCE_INITIAL_STATE] });
  const [startYear3, setStartYear3] = useState([]);
  const [startMonth3, setStartMonth3] = useState([]);
  const [endYears3, setEndYears3] = useState([]);
  const [endMonths3, setEndMonths3] = useState([]);
  const [current_month3, setCurrentMonth3] = useState('');
  const [current_year3, setCurrentYear3] = useState('');

  const [showDialog3, setShowDialog3] = useState(false);
  const [showPrompt3, confirmNavigation3] = useCallbackPrompt(showDialog);

  const getYear3 = () => setCurrentYear(new Date().getFullYear());
  const getMonth3 = () => setCurrentMonth(new Date().getMonth());

  useEffect(() => {
    if (user?.is_corporate === 1) {
      navigate('/profile/general');
    }

    const fieldsLength = fields.length;
    for (let i = 0; i <= fieldsLength; i++) {
      remove(i);
    }

    getYear();
    getMonth();
    fetchExperience();
  }, []);

  useEffect(() => {
    if (showPrompt) {
      ConfirmSaveChanges();
    }
  }, [showPrompt]);

  const ConfirmSaveChanges3 = () => {
    SwalBox.fire({
      title: t('_confirm_save_title_'),
      text: t('_confirm_save_msg_'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonClass: 'button_confirm_class',
      cancelButtonColor: '#d33',
      cancelButtonClass: 'button_cancel_class',
      confirmButtonText: 'Save'
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit(saveExperience)();
        confirmNavigation();
      } else {
        confirmNavigation();
      }
    });
  }

  const testMonths3 = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  const yearOptions3 = [];
  for (let index = 0; index <= 12; index++) {
    yearOptions[index] = { value: index, label: testMonths[index] };
  }

  var years = [];
  for (let index = 1950; index <= new Date().getFullYear(); index++) {
    years.push(index);
  }

  let setMonthSelection3 = (i, e) => {
    let startMonth = e.target.value;
    document.getElementById(`end_date_${i}`).setAttribute('min', startMonth)
  }

  let handleChange3 = (i, e) => {
    setShowDialog(true);
  }

  let handleChangeCurrentlyWorking = (i, e) => {
    fields[i].currently_working = e.target.checked;
  }

  let addFormFields3 = () => {
    append(EXPERIENCE_INITIAL_STATE);
  }

  let removeFormFields3 = (i) => {
    remove(i);
  }

  const fetchExperience = async () => {
    const response = await fetch(API_BASE + 'get-user-experience', {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${_token}`
      },
    });

    const data = await response.json();
    if (data.status) {
      setFormValues({ "experience": data.data });
      if (data.data.length > 0) {
        for (let i = 0; i <= data.data.length; i++) {
          remove(i);
        }
        data.data.map((ele, i) => {
          remove(i);
          append(ele);
        });


      }
      else {
        remove(0);
        append(EXPERIENCE_INITIAL_STATE);
      }
    } else {
      remove(0);
      append(EXPERIENCE_INITIAL_STATE);
      Toaster.error(data.message);
    }
  }

  const saveExperience = async (form) => {
    const response = await fetch(`${API_BASE}post-user-experience`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${_token}`
      },
      body: JSON.stringify(form)
    });

    const data = await response.json();
    if (data.status) {
      Toaster.success(data.message);
      fetchExperience();
      setShowDialog(false);
    } else {
      Toaster.error(data.message);
    }
  }

  const formattedDate3 = new Date().toISOString().split('T')[0];

  return (
    <>
      <Helmet>
        <title>{'EduKula - Resume Builder'}</title>
      </Helmet>
      <div className="tab-pane fade show active" id="v-pills-education" role="tabpanel" aria-labelledby="v-pills-education-tab">
        <div className="profile-dashboard-content">
          <h4>Resume Builder</h4>

          <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">

            <div className="count_level">
              <ul>
                <li className={activeStep >= 1 ? 'done' : ''}>
                  {completed >= 1 ? <span className="cursor-pointer" onClick={() => { setActiveStep(1); }}>1</span> : <span>1</span>}
                </li>
                <li className={activeStep >= 2 ? 'done' : ''}>
                  {completed >= 2 ? <span className="cursor-pointer" onClick={() => { setActiveStep(2); }}>2</span> : <span>2</span>}
                </li>
                <li className={activeStep >= 3 ? 'done' : ''}>
                  {completed >= 3 ? <span className="cursor-pointer" onClick={() => { setActiveStep(3); }}>3</span> : <span>3</span>}
                </li>
                <li className={activeStep >= 4 ? 'done' : ''}>
                  {completed >= 4 ? <span className="cursor-pointer" onClick={() => { setActiveStep(4); }}>4</span> : <span>4</span>}
                </li>
                <li className={activeStep >= 5 ? 'done' : ''}>
                  {completed >= 5 ? <span className="cursor-pointer" onClick={() => { setActiveStep(5); }}>5</span> : <span>5</span>}
                </li>
              </ul>
            </div>
            <div className="clearfix"></div>
            {
              activeStep === 1
              &&
              <>
                {/* <div className="profile-dashboard-box"> */}
                <form onSubmit={handleSubmit(saveBasicDetails)}>
                  <div className="row">
                    <div className="col-lg-6 mb-1">
                      <div className="form-group">
                        <input
                          id="first_name" {...register("first_name", {
                            required: "required",
                            pattern: {
                              value: /^[a-zA-Z ']*$/,
                              message: "Please add first name appropriately."
                            },
                            maxLength: {
                              value: 80,
                              message: "First Name can not be more than 80 charaters."
                            },
                            minLength: {
                              value: 3,
                              message: "Please enter at least 3 characters."
                            }
                          })}
                          // disabled={ekycstatus}
                          // readlonly={ekycstatus}
                          onChange={(event) => {
                            // setShowDialog(true);
                            clearErrors('first_name');
                            setFirstName(event.target.value);
                          }}
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                        // defaultValue={first_name ? first_name : ''}
                        />
                        <span className="add-on max-chr-counter chrchk-first_name" data-ele="first_name" data-limit="25" data-showcounter="false"></span>
                        {
                          errors?.first_name
                            ?
                            <span className="text-danger">
                              {errors.first_name.type === "required" && 'Please enter first name'}
                              {errors.first_name.type !== "required" && errors.first_name.message}
                            </span>
                            :
                            null
                        }
                      </div>
                    </div>
                    <div className="col-lg-6 mb-1">
                      <div className="form-group">
                        <input
                          id="last_name" {...register("last_name", {
                            required: "required",
                            pattern: {
                              value: /^[a-zA-Z ']*$/,
                              message: "Please add last name appropriately."
                            },
                            maxLength: {
                              value: 80,
                              message: "Last Name can not be more than 80 charaters."
                            },
                            minLength: {
                              value: 3,
                              message: "Please enter at least 3 characters."
                            }
                          })}
                          onChange={(event) => {
                            clearErrors('last_name');
                            setLastName(event.target.value);
                            // setShowDialog(true);
                          }}
                          // disabled={ekycstatus}
                          // readlonly={ekycstatus}
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                        // defaultValue={last_name ? last_name : ''}
                        />
                        <span className="add-on max-chr-counter chrchk-last_name" data-ele="last_name" data-limit="25" data-showcounter="false"></span>
                        {
                          errors.last_name
                            ?
                            <span className="text-danger">
                              {errors.last_name.type === "required" && 'Please enter last name'}
                              {errors.last_name.type !== "required" && errors.last_name.message}
                            </span>
                            :
                            null
                        }
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          {...register("phone", {
                            pattern: {
                              value: /^(\+\d{1,3}[- ]?)?\d{8,16}$/,
                              message: "Please enter minimum 8 and maximum 16 digit"
                            },
                          })}
                          onChange={(event) => {
                            clearErrors('phone');
                            setPhone(event.target.value);
                          }}
                          type="text"
                          className="form-control"
                          placeholder="Phone Number"
                        // defaultValue={phone ? phone : ''}
                        />
                        <span className="text-danger">
                          {errors.phone && errors.phone.type === "required" && 'Please enter phone number'}
                          {errors.phone && errors.phone.type !== "required" && errors.phone.message}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          id="headline" {...register("headline", {
                            maxLength: {
                              value: 80,
                              message: "Headline can not be more than 80 charaters."
                            }
                          })}
                          onChange={(event) => {
                            clearErrors('headline');
                            setHeadline(event.target.value);
                          }}
                          type="text"
                          className="form-control"
                          placeholder="Headline"
                        // defaultValue={headline ? headline : ''}
                        />
                        <span className="add-on max-chr-counter chrchk-headline" data-ele="headline" data-limit="80" data-showcounter="true"></span>
                        <span className="text-danger">
                          {errors.headline && errors.headline.type === "required" && 'Please enter headline'}
                          {errors.headline && errors.headline.type !== "required" && errors.headline.message}
                        </span>
                      </div>
                    </div>
                  </div>


                </form>
                {/* </div> */}
                <div className='d-flex flex-row-reverse'>
                  <button className="btn btn-success btn-circle btn-md" onClick={() => { setActiveStep(2); setCompleted(2) }}>Next</button>
                </div>
              </>
            }
            {
              activeStep === 2
              &&
              <>
                {/* <h6>Education Detail</h6> */}
                <form onSubmit={handleSubmit(saveEducation)}>
                  {fields.map((element, index) => (
                    <div className="row mb-4 pt-0 border-bottom" key={index}>
                      <div className="col-lg-12">
                        <h6>Education {index + 1}</h6>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input name={`education[${index}]school_name`} {...register(`education.${index}.school_name`)} type="text" placeholder="School Name" className={`form-control`} id={`school_name_${index}`} data-customcls="school_name" onChange={(e) => { setShowDialog(true); clearErrors(`education.${index}.school_name`); }} />
                          <div className="text-danger">{errors.education?.[index]?.school_name?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input name={`education[${index}]degree`} {...register(`education.${index}.degree`)} type="text" placeholder="Degree" className={`form-control`} id={`degree_${index}`} data-customcls="degree" onChange={(e) => { setShowDialog(true); clearErrors(`education.${index}.degree`); }} />
                          <div className="text-danger">{errors.education?.[index]?.degree?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input name={`education[${index}]field_of_study`} {...register(`education.${index}.field_of_study`)} type="text" placeholder="Field of Study" className={`form-control`} id={`field_of_study_${index}`} data-customcls="field_of_study" onChange={(e) => { setShowDialog(true); clearErrors(`education.${index}.field_of_study`); }} />
                          <div className="text-danger">{errors.education?.[index]?.field_of_study?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input name={`education[${index}]grade`} {...register(`education.${index}.grade`, { pattern: "/^.*[A-Za-z][+%]?$/", message: "Charecters allowed only!" })} type="text" placeholder="Grade" className={`form-control`} id={`grade_${index}`} data-customcls="grade" onChange={(e) => { setShowDialog(true); clearErrors(`education.${index}.grade`); }} />
                          <div className="text-danger">{errors.education?.[index]?.grade?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="start_date">Start date </label>
                          <input name={`education[${index}]start_date`} {...register(`education.${index}.start_date`)} type="date" min="1900-08-07" max={`${formattedDate}`}
                            placeholder="Start date" className={`form-control`} id={`start_date${index}`} data-customcls="grade" onChange={(e) => { setMonthSelection(index, e); setShowDialog(true); clearErrors(`education.${index}.start_date`); }} />
                          <div className="text-danger">{errors.education?.[index]?.start_date?.message}</div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="end_date">End date </label>
                          <input name={`education[${index}]end_date`} {...register(`education.${index}.end_date`)} type="date" min={fields?.[index]?.start_date} max={`${formattedDate}`} placeholder="End date" className={`form-control`} id={`end_date_${index}`} data-customcls="end_date" onChange={(e) => { setShowDialog(true); clearErrors(`education.${index}.end_date`); }} />
                          <div className="text-danger">{errors.education?.[index]?.end_date?.message}</div>
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <textarea name={`education[${index}]description`} {...register(`education.${index}.description`)} type="text" placeholder="Description" className={`form-control`} id={`description_${index}`} data-customcls="description" onChange={(e) => { setShowDialog(true); clearErrors(`education.${index}.description`); }}></textarea>
                          <div className="text-danger">{errors.education?.[index]?.description?.message}</div>
                        </div>
                      </div>
                      {

                        index ?
                          <div className="col-sm-12 mb-4">
                            <button type="button" className="btn m-0 btn-circle red_bg_btn float-right" onClick={() => removeFormFields(index)}><i className="fas fa-trash-alt"></i></button>
                          </div>
                          : null
                      }
                    </div>
                  ))}
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="add-new-education">
                        <a className="link" onClick={() => addFormFields()}>+ Add New Education</a>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-success btn-circle btn-md">Save</button>
                </form>
                <div className='d-flex justify-content-between mt-4'>
                  <button className="btn btn-success btn-circle btn-md" onClick={() => { setActiveStep(1); setCompleted(0) }}>Previous</button>
                  <button className="btn btn-success btn-circle btn-md" onClick={() => { setActiveStep(3); setCompleted(3) }}>Next</button>
                </div>
              </>
            }
            {
              activeStep === 3
              &&
              <>
                {/* <h6>Experience Detail</h6> */}
                <form onSubmit={handleSubmit(saveExperience)}>
                  {fields.map((element, index) => (
                    <div key={index}>
                      <h6>Experience {index + 1}</h6>
                      <div className="row">
                        <input type="hidden" name={`experience[${index}]experience_id`} {...register(`experience.${index}.experience_id`)} id={`experience_id_${index}`} data-customcls="experience_id" onChange={(e) => { handleChange(index, e); }} />
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input name={`experience[${index}]title`} {...register(`experience.${index}.title`)} type="text" placeholder="Title" className={`form-control`} id={`title_${index}`} data-customcls="title" onChange={(e) => { clearErrors(`experience.${index}.title`); handleChange(index, e); }} />
                            <div className="text-danger">{errors.experience?.[index]?.title?.message}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <select name={`experience[${index}]type`} {...register(`experience.${index}.type`)} className={`form-control`} id={`type_${index}`} data-customcls="type" onChange={(e) => { clearErrors(`experience.${index}.type`); handleChange(index, e); }}>
                              <option value=''>Employment type</option>
                              <option value='Full-time'>Full-time</option>
                              <option value='Part-Time'>Part-Time</option>
                              <option value='Internship'>Internship</option>
                              <option value='Trainee'>Trainee</option>
                              <option value='Self-Employed'>Self-Employed</option>
                              <option value='Freelance'>Freelance</option>
                            </select>
                            <div className="text-danger">{errors.experience?.[index]?.type?.message}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input name={`experience[${index}]company`} {...register(`experience.${index}.company`)} type="text" placeholder="Company name" className={`form-control`} id={`company_${index}`} data-customcls="company" onChange={(e) => { clearErrors(`experience.${index}.company`); handleChange(index, e); }} />
                            <div className="text-danger">{errors.experience?.[index]?.company?.message}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input name={`experience[${index}]location`} {...register(`experience.${index}.location`)} type="text" placeholder="Location" className={`form-control`} id={`location_${index}`} data-customcls="location" onChange={(e) => { clearErrors(`experience.${index}.location`); handleChange(index, e); }} />
                            <div className="text-danger">{errors.experience?.[index]?.location?.message}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input name={`experience[${index}]industry`} {...register(`experience.${index}.industry`)} type="text" placeholder="Industry" className={`form-control`} id={`industry_${index}`} data-customcls="industry" onChange={(e) => { clearErrors(`experience.${index}.industry`); handleChange(index, e); }} />
                            <div className="text-danger">{errors.experience?.[index]?.industry?.message}</div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group current_working">
                            <label htmlFor={`currently_working_${index}`}>I am currently working here</label>
                            <input name={`experience[${index}]currently_working`} {...register(`experience.${index}.currently_working`)} type="checkbox" className={`form-control`} id={`currently_working_${index}`} data-customcls="currently_working" onChange={(e) => { clearErrors(`experience.${index}.currently_working`); handleChange(index, e); handleChangeCurrentlyWorking(index, e); }} />
                            <div className="text-danger">{errors.experience?.[index]?.currently_working?.message}</div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4 border-bottom">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label htmlFor="start_date">Start date </label>
                            <input name={`experience[${index}]start_date`} {...register(`experience.${index}.start_date`)} type="date" min="1900-08-07"
                              max={`${formattedDate}`}
                              className={`form-control`} id={`start_date_${index}`} data-customcls="start_date" onChange={(e) => { setMonthSelection(index, e); clearErrors(`experience.${index}.start_date`); handleChange(index, e); }} />
                            <div className="text-danger">{errors.experience?.[index]?.start_date?.message}</div>
                          </div>
                        </div>
                        {
                          fields?.[index]?.currently_working == false
                          &&
                          <>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="end_date">End date </label>
                                <input name={`experience[${index}]end_date`} {...register(`experience.${index}.end_date`)} type="date" min={fields?.[index]?.start_date}
                                  max={`${formattedDate}`}
                                  className={`form-control`} id={`end_date_${index}`} data-customcls="end_date" onChange={(e) => { clearErrors(`experience.${index}.end_date`); handleChange(index, e); }} />
                                <div className="text-danger">{errors.experience?.[index]?.end_date?.message}</div>
                              </div>
                            </div>
                          </>
                        }
                        <div className="col-sm-12">
                          <div className="form-group">
                            <textarea name={`experience[${index}]description`} {...register(`experience.${index}.description`)} type="text" placeholder="Description" className={`form-control`} id={`description_${index}`} data-customcls="description" onChange={(e) => { clearErrors(`experience.${index}.description`); handleChange(index, e); }}></textarea>
                            <div className="text-danger">{errors.experience?.[index]?.description?.message}</div>
                          </div>
                        </div>
                        {

                          index ?
                            <div className="col-sm-12 mb-4">
                              <button type="button" className="btn m-0 btn-circle red_bg_btn float-right" onClick={() => removeFormFields(index)}><i className="fas fa-trash-alt"></i></button>
                            </div>
                            : null
                        }
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="add-new-education">
                        <a className="link" onClick={() => addFormFields()}>+ Add New Experience</a>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-success btn-circle btn-md">Save</button>
                </form>
                <div className='d-flex justify-content-between mt-4'>
                  <button className="btn btn-success btn-circle btn-md" onClick={() => { setActiveStep(2); setCompleted(1) }}>Previous</button>
                  <button className="btn btn-success btn-circle btn-md" onClick={() => { setActiveStep(4); setCompleted(4) }}>Next</button>
                </div>
              </>
            }
            {
              activeStep === 4
              &&
              <>
                <h6>Skills</h6>
                <div className='d-flex justify-content-between'>
                  <button className="btn btn-success btn-circle btn-md" onClick={() => { setActiveStep(3); setCompleted(2) }}>Previous</button>
                  <button className="btn btn-success btn-circle btn-md" onClick={() => { setActiveStep(5); setCompleted(5) }}>Next</button>
                </div>
              </>
            }
            {
              activeStep === 5
              &&
              <>
                <h6>Choose template</h6>
                <div className='d-flex justify-content-between'>
                  <button className="btn btn-success btn-circle btn-md" onClick={() => { setActiveStep(4); setCompleted(3) }}>Previous</button>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateResume;
