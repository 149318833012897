import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { API_BASE, FRONT_BASE } from "../../constants/global";
import authContext from "../../context/auth/authContext";
import { Toaster } from "../../entity/GlobalJS";
import ReportCircle from "../../user_dashboards/learning_circle/ReportCircle";
import About from "./course_tab/About";
import CourseCard from "./course_tab/CourseCard";
import Instructors from "./course_tab/Instructors";
import Prerequisites from "./course_tab/Prerequisites";
import Vacancy from "./course_tab/Vacancy";
import WhatYouLearn from "./course_tab/WhatYouLearn";
import ReactPlayer from "react-player";
import { SwalBox } from "../../entity/GlobalJS";
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { EmailIcon, FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from "react-share";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const CourseDetails = () => {
    const cntxAuth = useContext(authContext);
    const navigate = useNavigate();
    const location = useLocation();
    const _token = localStorage.getItem('lmstoken');
    const { _slug } = useParams();
    const [course, setCourse] = useState('');
    const [enrolled, setEnrolled] = useState(false);
    const [related_courses, setRelatedCourses] = useState('');
    const [courses_by_author, setCoursesByAuthor] = useState('');
    const [active_tab, setActiveTab] = useState(1);
    const [report_types, setReportTypes] = useState('');
    const [rating, setRating] = useState('');
    const [feedbacks, setFeedbacks] = useState('');
    const { t } = useTranslation();

    const [unique_code, setUniqueCode] = useState('');
    const [social_share_message, setSocialShareMessage] = useState('📚🚀 Excited to share this incredible learning opportunity on EduKula! 🎓🌟 Unlock your potential with this transformative course and gain valuable skills for personal and professional growth. Don\'t miss out – enroll now to embark on a journey towards success! #Education #EduKula #EnrollToday');


    const fetchCourse = async () => {
        const response = await fetch(`${API_BASE}get-course-detail/${_slug}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            if (data.message === "No data found.") {
                navigate('/404');
            }
            setEnrolled(data.data.enrolled);
            setCourse(data.data.course);
            fetchRating(data?.data?.course?.id);
            fetchFeedbacks(data?.data?.course?.id);
            setRelatedCourses(data.data.related_courses);
            setCoursesByAuthor(data.data.courses_by_author);
        } else {
            if (data.message === "No data found.") {
                navigate('/404');
            }
        }
    }
    useEffect(() => {
        localStorage.removeItem('enrolling');
        localStorage.removeItem('couid');
        localStorage.removeItem('uc');
        fetchCourse();
        fetchReportTypes();
        // eslint-disable-next-line
    }, []);
    useLayoutEffect(() => {
        fetchCourse();
    }, [location.pathname]);
    var settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        }, {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }, {
            breakpoint: 680,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };

    const EnrollToCourse = async () => {
        if (course?.course_privacy === 'Private') {
            if (unique_code === '') {
                Toaster.error(t('_coursedetail_enroll_course_error_'));
                return false;
            }
        }
        if (_token == null) {
            SwalBox.fire({
                allowOutsideClick: false,
                title: t('_coursedetail_alert_t1_'),
                text: t('_coursedetail_alert_m1_'),
                icon: 'info',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: t('_lcdetail_swalbox_m3_'),
                cancelButtonText: t('_lcdetail_swalbox_m4_')
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.setItem('enrolling', _slug);
                    localStorage.setItem('couid', course.id);
                    if (course?.course_privacy === 'Private' && unique_code !== '') {
                        localStorage.setItem('uc', unique_code);
                    }
                    navigate('/login');
                    return false;
                } else {
                    if (result.dismiss == 'cancel') {
                        localStorage.setItem('enrolling', _slug);
                        localStorage.setItem('couid', course.id);
                        if (course?.course_privacy === 'Private' && unique_code !== '') {
                            localStorage.setItem('uc', unique_code);
                        }
                        navigate('/register');
                        return false;
                    } else {
                        SwalBox.close();
                    }
                }
            });
            return false;
        }
        let url = `${API_BASE}post-enroll-course/${course.id}`;
        if (course?.course_privacy === 'Private') {
            if (unique_code === '') {
                Toaster.error(t('_coursedetail_enroll_course_error_'));
                return false;
            }
            else {
                url = `${API_BASE}post-enroll-course/${course.id}/${unique_code}`;
            }
        }
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status && data.data.enrolled) {
            localStorage.setItem('enrolled', _slug);
            SwalBox.fire({
                allowOutsideClick: false,
                title: t('_coursedetail_alert_t2_'),
                text: t('_coursedetail_alert_m2_'),
                icon: 'success',
                showCloseButton: true,
            });
            setTimeout(() => {
                SwalBox.close();
                navigate(`/learn-course/${course.slug}`);
            }, 3000);
            //Toaster.success('Enrolled successfully.');
            //navigate(`/learn-course/${course.slug}`);
        } else {
            Toaster.error(data.message);
        }
    }
    const fetchReportTypes = async () => {
        if (cntxAuth.isLogged) {
            const response = await fetch(`${API_BASE}get-report-types`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + _token
                },
            });
            const data = await response.json();
            if (data.status) {
                setReportTypes(data.data.types);
            }
        }
    }
    const fetchRating = async (course_id) => {
        const response = await fetch(`${API_BASE}get-average-course-ratings/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            if (data.data.average_ratings !== null) {
                setRating(data.data.average_ratings[0]);
            }
        }
    }
    const fetchFeedbacks = async (course_id) => {
        const response = await fetch(`${API_BASE}get-average-course-feedback/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            setFeedbacks(data.data.average_feedback);
        }
    }
    function youtube_parser(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length == 11) ? match[7] : false;
    }
    return (
        <>
            <Helmet>
                <title>{`${course?.title ? course?.title : 'Edukula'}`}</title>
                <meta name="description" content={`${course?.course_objective}`} />
                <meta name="og:title" content={`${course?.title}`} />
                <meta name="og:description" content={`${course?.course_objective}`} />
                <meta name="og:image" content={`${course?.image}`} />
            </Helmet>
            {
                course !== ''
                &&
                <>
                    <section className="custom-bg new_detail_pg_top">
                        <div className="course-top-banner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="course-detail-info">
                                            {/* <nav className="hide" aria-label="breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                                                    <li className="breadcrumb-item"><Link to={'/courses'}>Courses</Link></li>
                                                    <li className="breadcrumb-item"><Link to={`/category/${course?.category?.slug}`}>{course?.category?.title}</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">{course?.title}</li>
                                                </ol>
                                            </nav> */}

                                            <div className="badges_box">
                                                {
                                                    course?.badge
                                                    &&
                                                    <>
                                                        {course?.badge === 'Highest Rated' ? <span className="highest">{t('_coursedetail_sort_course_highrate_')}</span> : ''}
                                                        {course?.badge === 'Bestseller' ? <span className="selling">{t('_coursedetail_sort_course_selling_')} </span> : ''}
                                                        {course?.badge === 'Trending' ? <span className="tranding">{t('_coursedetail_sort_course_trending_')} </span> : ''}
                                                    </>
                                                }
                                                {
                                                    course?.associated_vacancy > 0 && typeof course?.associated_vacancy !== 'undefined'
                                                    &&
                                                    <>
                                                        <span className={`highest hiring ${course?.badge ? '' : 'ziro_pos'}`}>{t('_coursedetail_sort_course_hiring_')} </span>
                                                    </>
                                                }
                                            </div>
                                            <h3>
                                                {course?.title}
                                            </h3>
                                            <p className="mb-0">{course?.course_objective}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="course-video-section">
                                            {
                                                course.videoUp
                                                &&
                                                <div className={`${!course.videoUp && 'course-video-block'} video_img_box`}>
                                                    {
                                                        /*
                                                        <video controls>
                                                            <source src={course.video} type="video/mp4" />
                                                            Sorry, your browser doesn't support embedded videos.
                                                        </video>
                                                        */
                                                    }
                                                    <ReactPlayer
                                                        playing
                                                        controls={false}
                                                        url={course.video}
                                                        light={<img src={course?.image} alt='Thumbnail' />}
                                                        width='100%'
                                                        height='250px'
                                                    />


                                                </div>
                                            }
                                            {
                                                (!course.videoUp && (course.youtube_link == null || course.youtube_link == "null" || course.youtube_link == "") && course.image != null && course.image != '')
                                                &&
                                                <div className="img_box">
                                                    <img src={course?.image} alt="course video" />
                                                </div>
                                            }
                                            {
                                                (!course.videoUp && course.youtube_link != null && course.youtube_link != '' && course.youtube_link != 'null')
                                                &&
                                                <div className="video_img_box">
                                                    {/*
                                                    <iframe
                                                        width="100%"
                                                        height="700px"
                                                        src={`https://www.youtube-nocookie.com/embed/${youtube_parser(course.youtube_link)}?rel=0`}
                                                        frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    />
                                                    */}
                                                    <ReactPlayer
                                                        playing
                                                        controls={false}
                                                        url={`https://www.youtube-nocookie.com/embed/${youtube_parser(course.youtube_link)}?rel=0`}
                                                        light={<img src={course?.image} alt='Thumbnail' />}
                                                        width='100%'
                                                        height='250px'
                                                    />
                                                </div>
                                            }
                                            {/* <a className="btn btn-circle btn-login-icon"><i className="fas fa-play"></i></a> */}
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </section>
                    <div className="clearfix"></div>

                    {/* <section className="custom-section hide">
                        <div className="estimate-box-section orange-box">
                            <div className="container">
                                <div className="row text-light">
                                    <div className="col-lg-4">
                                        <div className="estimate-box-block">
                                            <div className="estimate-box-head">
                                                <i className="far fa-clock"></i>
                                            </div>
                                            <div className="estimate-box-content">
                                                <h3>Estimated {course?.duration}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="estimate-box-block">
                                            <div className="estimate-box-head">
                                                <i className="far fa-user"></i>
                                            </div>
                                            <div className="estimate-box-content">
                                                <h3>{course?.mode_of_learning}</h3>
                                                <p>
                                                    {
                                                        course?.mode_of_learning === 'Self Paced'
                                                        &&
                                                        'Learn at your own pace'
                                                    }
                                                    {
                                                        course?.mode_of_learning === 'One To One'
                                                        &&
                                                        'Personalised Sessions'
                                                    }
                                                    {
                                                        course?.mode_of_learning === 'One To Many'
                                                        &&
                                                        'Group Sessions'
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="estimate-box-block">
                                            <div className="estimate-box-head">
                                                <i className="fas fa-dollar-sign"></i>
                                            </div>
                                            <div className="estimate-box-content">
                                                <h3>Free</h3>
                                                <p>Start learning right away</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section className="self_placed_box">
                        <div className="container">
                            <div className="row text-light">
                                <div className="col-lg-4">
                                    <div className="estimate-box-block">
                                        <div className="estimate-box-head">
                                            <i className="fas fa-user"></i>
                                        </div>
                                        <div className="estimate-box-content">
                                            <h3>{course?.mode_of_learning}</h3>
                                            <p>
                                                {
                                                    course?.mode_of_learning === 'Self Paced'
                                                    &&
                                                    t('_coursedetail_msg_mode_of_learning_')
                                                }
                                                {
                                                    course?.mode_of_learning === 'One To One'
                                                    &&
                                                    t('_coursedetail_msg_session_')
                                                }
                                                {
                                                    course?.mode_of_learning === 'One To Many'
                                                    &&
                                                    t('_coursedetail_msg_onetomany_')
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="estimate-box-block">
                                        <div className="estimate-box-head">
                                            <i className="fas fa-donate"></i>
                                        </div>
                                        <div className="estimate-box-content">
                                            <h3>{t('_coursedetail_msg_free_')}</h3>
                                            <p>{t('_coursedetail_msg_startlearning_')} </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 login-form ekpd-0">
                                    {
                                        (course !== '' && !course.is_tutor && cntxAuth?.userInfo?.is_corporate !== 1)
                                        &&
                                        <>
                                            {
                                                !enrolled
                                                &&
                                                <>
                                                    {
                                                        course?.course_privacy === 'Private'
                                                            ?
                                                            <div className="enroll_newbox">
                                                                <p><br></br></p>
                                                                <input className="form-control" type="text" onChange={(event) => { setUniqueCode(event.target.value) }} placeholder="Enter Your Unique Code" />
                                                                <p><br></br></p>
                                                                <a onClick={(e) => { EnrollToCourse(); }} className="btn btn-success ek-lrs-course-registered" data-ek-lrs-title={course.title} data-ek-lrs-url={`/learn-course/` + course.slug}>{t('_coursedetail_enroll_now_')} </a>
                                                            </div>
                                                            :
                                                            <>
                                                                <p><br></br></p>
                                                                <a onClick={(e) => { EnrollToCourse(); }} className="btn btn-success ek-lrs-course-registered" data-ek-lrs-title={course.title} data-ek-lrs-url={`/learn-course/` + course.slug}>{t('_coursedetail_enroll_now_')} </a>
                                                            </>
                                                    }
                                                </>
                                            }
                                            {
                                                enrolled
                                                &&
                                                <>
                                                    <p><br></br></p>
                                                    <Link to={`/learn-course/${course.slug}`} className="btn btn-success">{t('_coursedetail_gotocourse_')} </Link>
                                                </>

                                            }

                                        </>
                                    }
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </section>
                    <div className="clearfix"></div>

                    <section className="custom-section new_course_detal">
                        <div className="course-details-tab-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="course-details-tab-block explore-block">
                                            <ul className="nav nav-pills custom-section" id="pills-tab" role="tablist">
                                                <li className="nav-item">
                                                    <a className={`nav-link ${active_tab === 1 ? 'active' : ''}`} onClick={(e) => { setActiveTab(1) }} id="pills-about-tab" data-toggle="pill" href="#pills-about" role="tab" aria-controls="pills-about" aria-selected="true">{t('_emp_add_employee_about_')} </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link ${active_tab === 2 ? 'active' : ''}`} onClick={(e) => { setActiveTab(2) }} id="pills-learn-tab" data-toggle="pill" href="#pills-learn" role="tab" aria-controls="pills-learn" aria-selected="false">{t('_lcdetail_what_learn__')} </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link ${active_tab === 3 ? 'active' : ''}`} onClick={(e) => { setActiveTab(3) }} id="pills-instructors-tab" data-toggle="pill" href="#pills-instructors" role="tab" aria-controls="pills-instructors" aria-selected="false">{t('_coursedetail_instructors_')} </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link ${active_tab === 4 ? 'active' : ''}`} onClick={(e) => { setActiveTab(4) }} id="pills-prerequisites-tab" data-toggle="pill" href="#pills-prerequisites" role="tab" aria-controls="pills-prerequisites" aria-selected="false">{t('_coursedetail_prerequisites_')} </a>
                                                </li>
                                                {
                                                    course?.associated_vacancy > 0 && typeof course?.associated_vacancy !== 'undefined'
                                                    &&
                                                    <li className="nav-item">
                                                        <a className={`nav-link ${active_tab === 5 ? 'active' : ''}`} onClick={(e) => { setActiveTab(5) }} id="pills-vacancy-tab" data-toggle="pill" href="#pills-vacancy" role="tab" aria-controls="pills-vacancy" aria-selected="false">{t('_v_vacancy_')} </a>
                                                    </li>
                                                }

                                            </ul>
                                            <div className="tab-content" id="pills-tabContent">
                                                {
                                                    active_tab === 1
                                                    &&
                                                    <About course={course} />
                                                }

                                                {
                                                    active_tab === 2
                                                    &&
                                                    <WhatYouLearn course={course} />
                                                }

                                                {
                                                    active_tab === 3
                                                    &&
                                                    <Instructors course={course} />
                                                }

                                                {
                                                    active_tab === 4
                                                    &&
                                                    <Prerequisites course={course} />
                                                }

                                                {
                                                    active_tab === 5
                                                    &&
                                                    <Vacancy course={course} />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="course-details-tab-block right_box">
                                            <div className="course-right-description">
                                                <h4 className="mt-0">{t('_coursedetail_cour_include_')} </h4>
                                                <ul className="course-tabs-details mt-3 mb-5">
                                                    {
                                                        course?.containts?.length > 0 && course?.containts !== ''
                                                        &&
                                                        course?.containts.map((item, index) =>
                                                            <li key={index}><a><i className={item.icon}></i><span>{item.text}</span></a></li>
                                                        )
                                                    }
                                                    {/* <li><a><i className="far fa-thumbs-up"></i><span>Skill Level {course?.study_level}</span></a></li>*/}
                                                    {/* <li><a><i className="fas fa-book"></i><span>Lectures 6 lessons</span></a></li> */}
                                                    {course?.enrolled_students > 0 ? <li><a><i className="fas fa-user-graduate"></i><span>{t('_coursedetail_cour_enrolled_')} {course?.enrolled_students} {t('_lcdetail_std_')}</span></a></li> : ''}
                                                    {/* {course?.assignments > 0 ? <li><a><i className="fas fa-clipboard"></i><span>{course?.assignments == 1 ? 'Assignment' : 'Assignments'}</span></a></li> : ''} */}
                                                    {/* {course?.quiz > 0 ? <li><a><i className="fas fa-clipboard"></i><span>{course?.quiz == 1 ? 'Quiz' : 'Quiz'}</span></a></li> : ''} */}
                                                    <li><a><i className="fas fa-trophy"></i><span>{t('_coursedetail_cour_certificate_')} </span></a></li>
                                                </ul>

                                                {
                                                    /*
                                                   (course !== '' && !course.is_tutor && cntxAuth?.userInfo?.is_corporate !== 1)
                                                   &&
                                                   <>
                                                       {
                                                           !enrolled
                                                           &&
                                                           <>
                                                               {
                                                                   course?.course_privacy === 'Private'
                                                                       ?
                                                                       <div className="enroll_newbox">
                                                                           <input className="form-control" type="text" onChange={(event) => { setUniqueCode(event.target.value) }} placeholder="Enter Your Unique Code" />
                                                                           <a onClick={(e) => { EnrollToCourse(); }} className="btn btn-success ek-lrs-course-registered" data-ek-lrs-title={course.title} data-ek-lrs-url={`/learn-course/` + course.slug}>Enroll Now</a>
                                                                       </div>
                                                                       :
                                                                       <a onClick={(e) => { EnrollToCourse(); }} className="btn btn-success ek-lrs-course-registered" data-ek-lrs-title={course.title} data-ek-lrs-url={`/learn-course/` + course.slug}>Enroll Now</a>
                                                               }
                                                           </>

                                                       }

                                                       {
                                                           enrolled
                                                           &&
                                                           <Link to={`/learn-course/${course.slug}`} className="btn btn-success">Go to Course</Link>
                                                       }

                                                   </>
                                                   */
                                                }

                                                <FacebookShareButton url={`${FRONT_BASE}learn-course/${course.slug}`} quote={social_share_message}><FacebookIcon size={32} round={true} /></FacebookShareButton>&nbsp;&nbsp;
                                                <TwitterShareButton url={`${FRONT_BASE}learn-course/${course.slug}`} title={course.title}><TwitterIcon size={32} round={true} /></TwitterShareButton>&nbsp;&nbsp;
                                                <LinkedinShareButton url={`${FRONT_BASE}learn-course/${course.slug}`} title={course.title} summary={social_share_message}><LinkedinIcon size={32} round={true} /></LinkedinShareButton>&nbsp;&nbsp;
                                                <EmailShareButton url={`${FRONT_BASE}learn-course/${course.slug}`} subject={`Checkout this course on EduKula: ${course.title}`} body={social_share_message}><EmailIcon size={32} round={true} /></EmailShareButton>&nbsp;&nbsp;
                                                <WhatsappShareButton url={`${FRONT_BASE}learn-course/${course.slug}`} title={social_share_message}><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
                                                <div className="top_banner_section hide">
                                                    {
                                                        course?.average_rating !== null && course?.average_rating?.[0]?.average > 0
                                                        &&
                                                        <a className="rating_text" data-toggle="modal" data-target="#ratingviewModalCenter"><i className="far fa-star"></i> {course?.average_rating?.[0]?.average} {t('_explorelc_rating_')}</a>
                                                    }
                                                    {
                                                        cntxAuth.isLogged
                                                        &&
                                                        <>
                                                            <span className="newreport cursor-pointer"><a className="dropdown-item" data-toggle="modal" data-target="#reportModalCenter"><i className="fas fa-flag"></i>{t('_coursedetail_report_course_')} </a></span>
                                                            <ReportCircle report_types={report_types} unique_id={''} for_course={true} course_id={course?.id} />
                                                        </>
                                                    }
                                                    <div className="modal fade ratingviewpop" id="ratingviewModalCenter" tabIndex="-1" role="dialog" aria-labelledby="ratingviewModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h4 className="modal-title" id="ratingviewModalLongTitle">{t('_managelc_currernt_rating_')} </h4>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div className="rating_pop_mai">
                                                                        <h4>{rating.average} {t('_explorelc_rating_')} </h4>
                                                                        {
                                                                            feedbacks !== '' && feedbacks !== null
                                                                            &&
                                                                            <div className="feedback_detailed-view">
                                                                                <ul className="list-feedbacks">
                                                                                    {
                                                                                        feedbacks.map((feedback, indexx) => {
                                                                                            return (
                                                                                                <li key={indexx}>
                                                                                                    <span>{feedback.question}</span>
                                                                                                    <div className="feedback-percent">
                                                                                                        <span style={{ width: feedback.feedback.Yes + '%' }} className="yes">{feedback.feedback.Yes}%</span>
                                                                                                        <span style={{ width: feedback.feedback.No + '%' }} className="no">{feedback.feedback.No}%</span>
                                                                                                        <span style={{ width: feedback.feedback.Not_Sure + '%' }} className="not_sure">{feedback.feedback.Not_Sure}%</span>
                                                                                                    </div>
                                                                                                </li>
                                                                                            )
                                                                                        }
                                                                                        )
                                                                                    }
                                                                                </ul>
                                                                                <ul className="percent-courns">
                                                                                    <li className="one">0</li>
                                                                                    <li className="two">20</li>
                                                                                    <li className="three">40</li>
                                                                                    <li className="four">60</li>
                                                                                    <li className="five">80</li>
                                                                                    <li className="six">100</li>
                                                                                </ul>
                                                                                <div className="template_names">
                                                                                    <span className="yes">{t('_lp_yes_')}</span>
                                                                                    <span className="no">{t('_lp_no_')}</span>
                                                                                    <span className="not_sure">{t('_managelc_not_sure_')} </span>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        {
                                                                            (feedbacks === '' || feedbacks === null)
                                                                            &&
                                                                            <span>{t('_coursedetail_cour_nofeedback_m1_')} </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearfix"></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </section>
                    <div className="clearfix"></div>

                    <section className="custom-section related_course new_arrow">
                        <div className="container">
                            {
                                (typeof related_courses !== 'undefined' && related_courses !== '' && related_courses.length > 0)
                                &&
                                <div className="releted-course course_slider_arrow">
                                    <h3><span>{t('_coursedetail_cour_related_')} </span> {t('_coursedetail_cour_course_m1_')}</h3>
                                    <div className="blog-card">
                                        {
                                            related_courses.length > 4
                                            &&
                                            <Slider {...settings}>
                                                {
                                                    related_courses.map((courseI, index) =>
                                                        <div key={index}>
                                                            <CourseCard course={courseI} />
                                                        </div>
                                                    )
                                                }
                                            </Slider>
                                        }

                                        {
                                            related_courses.length <= 4
                                            &&
                                            <div className="row">
                                                {

                                                    related_courses.map((courseI, index) =>
                                                        <div key={index} className="col-md-6 col-lg-3">
                                                            <CourseCard course={courseI} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                    <div className="clearfix"></div>
                    {
                        (typeof courses_by_author !== 'undefined' && courses_by_author !== '' && courses_by_author.length > 0)
                        &&
                        <section className="custom-section white_bg related_course new_arrow">
                            <div className="container">
                                <div className="releted-course course_slider_arrow">
                                    <h3><span>{t('_coursedetail_more_course_')}</span> {course?.creator?.name}</h3>
                                    <div className="blog-card">
                                        {
                                            courses_by_author.length > 4
                                            &&
                                            <Slider {...settings}>
                                                {
                                                    courses_by_author.map((courseI, index) =>
                                                        <div key={index}>
                                                            <CourseCard course={courseI} />
                                                        </div>
                                                    )
                                                }
                                            </Slider>
                                        }

                                        {
                                            courses_by_author.length <= 4
                                            &&
                                            <div className="row">
                                                {
                                                    courses_by_author.map((courseI, index) =>
                                                        <div key={index} className="col-md-6 col-lg-3">
                                                            <CourseCard course={courseI} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                    <div className="clearfix"></div>
                </>
            }
        </>
    );
}

export default CourseDetails;
