import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API_BASE } from '../../constants/global';
import { Toaster } from '../../entity/GlobalJS';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const InviteEmployee = () => {
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const { _id } = useParams();
    const navigate = useNavigate();
    const _token = localStorage.getItem('lmstoken');
    const { register, handleSubmit, formState: { errors }, setValue, setError, setFocus } = useForm();
    const [emails, setEmails] = useState([]);
    const [processing, setProcessing] = useState(false);
    const new_registration = localStorage.getItem('new_registration');
    const { t } = useTranslation();

    useEffect(() => {
        if (typeof new_registration === 'undefined' || !new_registration) {
            navigate(`/login`);
        }

        if (user?.is_corporate !== 1 || typeof _id === 'undefined') {
            navigate('/dashboard/home');
        }

        return () => {
        }
    }, []);

    const onSubmit = async (data) => {
        if (emails.length >= 30) {
            setError("email", { type: "focus", message: t('_managelc_invite_email_vali_m31_') }, { shouldFocus: false });
            return false;
        }

        setProcessing(true);
        //var iEmail = data.email;
        var _email_string = data.email;
        var _remove_all_spaces = _email_string.replace(/\s\s+/g, ' ');
        var _convert_space_in_coma = _remove_all_spaces.split(/[ ,]+/).join(',');
        var iEmail = _convert_space_in_coma.trim();
        var comEmails = iEmail.split(",");

        if (typeof comEmails !== 'undefined' && comEmails.length > 1) {
            var duplicate = false;
            var checkEmails = [];
            for (let index = 0; index < comEmails.length; index++) {
                const sepEmail = comEmails[index].trim();

                if (emails.indexOf(sepEmail) === -1) {
                    checkEmails.push(sepEmail);
                } else {
                    var duplicate = true;
                }
            }

            checkEmails = checkEmails.join(",");
            var newEmails = [];

            const checkAPI = async () => {
                const formData = new FormData();
                formData.append('email', checkEmails);
                var sURL = `${API_BASE}employee/check-emails-exists`;
                const response = await fetch(sURL, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${_token}`
                    },
                    body: formData
                });

                const rdata = await response.json();
                if (rdata.status === true) {
                    return rdata.data.emails;
                }
            }

            const callAsync = async () => {
                const newEmails = await checkAPI();
                const newList = [...emails, ...newEmails];
                setEmails(newList.splice(30));
                setEmails(newList);
            }

            callAsync();

            if (duplicate) {
                Toaster.info(t('_managelc_invite_email_vali_m32_'));
            }

            setValue('email', '');
            setFocus('email');
            setProcessing(false);
        } else {
            if (emails.indexOf(iEmail) !== -1) {
                setError("email", { type: "focus", message: t('_managelc_invite_email_vali_m33_') }, { shouldFocus: true });
                setProcessing(false);
                return false;
            }

            const formData = new FormData();
            formData.append('email', iEmail);
            var sURL = `${API_BASE}employee/check-email-exists`;
            const response = await fetch(sURL, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData
            });

            const rdata = await response.json();
            if (rdata.status && rdata.data.exists === true) {
                setError("email", { type: "focus", message: t('_managelc_invite_email_vali_m34_') }, { shouldFocus: true });
                setProcessing(false);
                return false;
            }

            setEmails([...emails, data.email]);
            setValue('email', '');
            setFocus('email');
            setProcessing(false);
        }
    }

    const sendInvitation = async () => {
        const message = document.getElementById('invite_message_box').value;
        var formData = new FormData();
        for (var i = 0; i < emails.length; i++) {
            formData.append('members[]', emails[i]);
        }
        formData.append('message', message);
        setProcessing(true);
        var sURL = `${API_BASE}employee/invite-to-corporate`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            Toaster.success(rdata.message);
            setEmails([]);
            setValue('email', '');
            setProcessing(false);
            navigate(`/register-steps/course-preference/${_token}`);
        } else {
            Toaster.error(rdata.message);
            setProcessing(false);
        }
    }

    const removeItem = async (e, item) => {
        const newEmails = [];

        emails.forEach((email, index) => {
            if (item !== email) {
                newEmails.push(email);
            }
        });

        setEmails(newEmails);
    }

    const skip = () => {
        localStorage.removeItem('new_registration');
        navigate(`/dashboard/home`);
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Invite employees'}</title>
            </Helmet>
            <section>
                <div className="step_3_box">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="custom-login-box">
                                    <div className="login-form">
                                        <h2>Invite employees via email</h2>
                                        <div className="invitepop">
                                            <strong>Invitation message email recipients will see</strong>
                                            <textarea className="form-control" id="invite_message_box" placeholder={t('_managelc_email_invitation_msg_place_')}>We're delighted to invite you to EduKula, the employee training platform that we'll be using at our company! We are truly committed to helping you reach your learning and development goals through EduKula.</textarea>
                                            <br /><strong>Add email addresses to invite</strong>
                                            <p>You can send invitations to up to 30 people at once by adding more email addresses. For adding multiple emails at once, use comma-separated emails.</p>
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="email_box">
                                                    <input
                                                        {...register("email", {
                                                            required: "required",
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: "Entered value does not match email format"
                                                            }
                                                        })}
                                                        type="text"
                                                        placeholder="Enter email address"
                                                    />
                                                    <button disabled={processing} className="btn btn-circle btn-info ml-2 add_btn">{
                                                        processing
                                                        &&
                                                        <i className="fa fa-refresh fa-spin fa-fw"></i>
                                                    }
                                                        {
                                                            !processing
                                                            &&
                                                            "Add"
                                                        }</button>
                                                </div>
                                                <span className="text-danger">
                                                    {errors.email && errors.email.type === "required" && 'Please enter email address'}
                                                    {errors.email && errors.email.type !== "required" && errors.email.message}
                                                </span>
                                            </form>
                                            {
                                                emails.length > 0
                                                &&
                                                <>
                                                    <div className='emailList_box'>
                                                        <ul className="mt-3">
                                                            {
                                                                emails.map((email, index) =>
                                                                    <li key={index} className="border px-2 py-1 mt-1 bg-white">
                                                                        <span>{email}</span>
                                                                        <i onClick={(e) => { removeItem(e, email) }} className="fa fa-times text-danger float-right mt-1" title="Close" aria-hidden="true"></i>
                                                                    </li>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                </>
                                            }
                                            <div className="btn_right pb-0">
                                                <a className="btn btn-circle btn-md cancel-btn mb-3 cursor-pointer" onClick={() => { navigate(-1); }}>Not Now</a>
                                                {
                                                    emails.length > 0
                                                    &&
                                                    <button type="submit" className="btn btn-circle btn-md submit-pink-btn" disabled={processing} onClick={(e) => { sendInvitation() }}>
                                                        {
                                                            processing
                                                            &&
                                                            <i className="fa fa-refresh fa-spin fa-fw"></i>
                                                        }
                                                        {
                                                            !processing
                                                            &&
                                                            "Invite"
                                                        }
                                                    </button>
                                                }
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default InviteEmployee;
