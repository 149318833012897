import { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { CircleBoxSkeleton } from "../../components/skeletons";
import { useParams } from 'react-router-dom';
import { API_BASE } from "../../constants/global";
import CourseCard from "./course_tab/CourseCard";
import LearningCircleCard from "./learning_circle_tab/LearningCircleCard";
import CustomPagination from "../../entity/CustomPagination";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const HomeSearchPage = () => {
    const [search, setSearch] = useState('');
    const [courses, setCourses] = useState([]);
    const [total_courses, setTotalCourses] = useState(0);
    const [learning_circles, setLearningCircles] = useState([]);
    const [total_learning_circles, setTotalLearningCircles] = useState(0);
    const [isCourseLoader, setIsCourseLoader] = useState(true);
    const [isLearningCircleLoader, setIsLearningCircleLoader] = useState(true);
    const [fetchFirstTime, setFetchFirstTime] = useState(false);
    const { slug } = useParams();
    const ref_courses = useRef(null);
    const ref_learning_circles = useRef(null);
    const { t } = useTranslation();


    function updateCardData(type, data = '') {
        if (type == '' && data != '') {
            setCourses(data.data.courses.data);
            setTotalCourses(data.data.courses.total_count);
            setLearningCircles(data.data.learning_circles.data);
            setTotalLearningCircles(data.data.learning_circles.total_count);
        } else if (type == 'courses' && data != '') {
            setCourses(data.data.courses.data);
            setTotalCourses(data.data.courses.total_count);
            ref_courses.current?.scrollIntoView({ behavior: 'auto' });
        } else if (type == 'learning_circles' && data != '') {
            setLearningCircles(data.data.learning_circles.data);
            setTotalLearningCircles(data.data.learning_circles.total_count);
            ref_learning_circles.current?.scrollIntoView({ behavior: 'auto' });
        } else {
            resetCardData(type);
        }
    }

    function resetCardData(type) {
        if (type == '') {
            setCourses([]);
            setTotalCourses(0);
            setLearningCircles([]);
            setTotalLearningCircles(0);
        } else if (type == 'courses') {
            setCourses([]);
            setTotalCourses(0);
        } else if (type == 'learning_circles') {
            setLearningCircles([]);
            setTotalLearningCircles(0);
        }
    }

    function updateSkeleton(type, value) {
        if (type == '') {
            setIsCourseLoader(value);
            setIsLearningCircleLoader(value);
        } else if (type == 'courses') {
            setIsCourseLoader(value);
        } else if (type == 'learning_circles') {
            setIsLearningCircleLoader(value);
        }
    }

    const fetchCourses = async (search, type = '', page = 0, length = 8) => {
        let search_keyword = (search) ? search : '';
        var formData = new FormData();
        updateSkeleton(type, true);
        const response = await fetch(`${API_BASE}home_search?search=${search_keyword}&type=${type}&page=${page}&length=${length}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        const data = await response.json();
        updateSkeleton(type, false);
        setFetchFirstTime(true);
        if (data.status) {
            updateCardData(type, data);
        } else {
            updateCardData(type, '');
        }
    }

    useEffect(() => {
        setSearch(slug);
        if (!fetchFirstTime) {
            fetchCourses(slug);
        }
        //eslint-disable-next-line
    }, []);
    {
        return (
            <>
                <Helmet>
                    <title>{`Edukula ${search ? ("- " + search) : ('')}`}</title>
                </Helmet>
                <section>
                    <div className="contact-banner">
                        <div className="container text-light text-center">
                            <h1>{t('_homesearch_msg_m1_')} "{search}"</h1>
                        </div>
                    </div>
                </section>
                <section className="custom-section pt-5 m-0 whitebg pb-5">
                    <div className="explore-section">
                        <div className="container">
                            <div className="explore-block">
                                <h1 className="pb-3" ref={ref_courses}>{t('_coursedetail_title2_')} <span className="h6"> {`(  ${total_courses} ${t('_homesearch_msg_m2_')} )`}</span></h1>
                                <div className={`row ${total_courses < 9 ? '' : ''}`}>
                                    {
                                        isCourseLoader ?
                                            <>
                                                <CircleBoxSkeleton count={4} boxSize={'col-lg-3'} />
                                                <CircleBoxSkeleton count={4} boxSize={'col-lg-3'} />
                                            </>
                                            :
                                            <>
                                                {
                                                    courses.length > 0
                                                    &&
                                                    courses.map((course, index) =>
                                                        <div key={index} className="col-md-6 col-lg-3 mb-5">
                                                            <CourseCard course={course} />
                                                        </div>
                                                    )
                                                }

                                                {
                                                    courses.length == '0'
                                                    &&
                                                    <div className="col-12 mb-5">
                                                        <div className="empty_state">
                                                            <h3>{t('_coursedetail_nocourse_m1_')}</h3>
                                                            <img alt="" src="images/no_courses.png" />
                                                            <p>
                                                                {t('_coursedetail_nocourse_m2_')}
                                                            </p>
                                                            <p><span><Link className="btn btn-circle btn-md submit-pink-btn create-l-circle" to="/courses">{t('_dashboard_home2_t11_')}</Link></span></p>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                    }
                                </div>
                                {
                                    total_courses > 8
                                    &&
                                    <div className="custom-pagination mt-0">
                                        <nav aria-label="Page navigation example">
                                            <CustomPagination pageCount={total_courses} perPageRecord={8} handlePageClick={(data) => { fetchCourses(search, 'courses', data.selected) }} />
                                        </nav>
                                    </div>
                                }

                                <h1 ref={ref_learning_circles}>{t('_ud_l_lc_')} <span className="h6">{`(  ${total_learning_circles} ${t('_homesearch_msg_m2_')} )`} </span></h1>
                                <div className="explore-block learning-circle-list-section pt-3">
                                    <div className="row">
                                        {
                                            isLearningCircleLoader ?
                                                <>
                                                    <CircleBoxSkeleton count={4} boxSize={'col-lg-3'} />
                                                </>
                                                :
                                                <>
                                                    {
                                                        learning_circles.length > 0
                                                        &&
                                                        learning_circles.map((group, index) =>
                                                            <div key={index} className="col-md-6 col-lg-3 mb-5">
                                                                <LearningCircleCard group={group} />
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        learning_circles.length == '0'
                                                        &&
                                                        <div className="col-12">
                                                            <div className="empty_state">
                                                                <h3>{t('_explorelc_nolc_found_')}</h3>
                                                                <img alt="" src="images/no_courses.png" />
                                                                {
                                                                    search != ""
                                                                    &&
                                                                    <p>{t('_explorelc_nolc_found_m2_')} <strong>{search}</strong> ,</p>

                                                                }
                                                                <p>{t('_explorelc_createlc_msg_')}</p>
                                                                <p><span><Link className="btn btn-circle btn-md submit-pink-btn create-l-circle" to="/dashboard/create-learning-circle">{t('_mylc_createlc_')}</Link></span></p>

                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                        }
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                {
                                    total_learning_circles > 8
                                    &&
                                    <div className="custom-pagination mb-5 mt-0">
                                        <nav aria-label="Page navigation example">
                                            <CustomPagination pageCount={total_learning_circles} perPageRecord={8} handlePageClick={(data) => { fetchCourses(search, 'learning_circles', data.selected) }} />
                                        </nav>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default HomeSearchPage;
