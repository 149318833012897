import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import { useContext, useEffect, useState } from "react";
import { Toaster, SwalBox } from "./../../entity/GlobalJS";
import authContext from "../../context/auth/authContext";
import { DEFAULT_UPLOAD_RESUME_IMG } from "../../constants/global";
import { DEFAULT_UPDATE_RESUME_IMG } from "../../constants/global";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt.ts";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const GeneralProfile = () => {
    const _token = localStorage.getItem('lmstoken');
    const cntxAuth = useContext(authContext);
    const auth_user = cntxAuth.userInfo;
    const [api_errors, setApiErrors] = useState([]);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [company_website, setCompanyWebsite] = useState('');
    const [about_me, setAbout] = useState('');
    const [headline, setHeadline] = useState('');
    const [fb_link, setFBLink] = useState('');
    const [tw_link, setTWLink] = useState('');
    const [li_link, setLILink] = useState('');
    const [resume_file, setResumeFile] = useState('');
    const [resume_title, setResumeTitle] = useState('');
    const [new_resume, setNewResume] = useState('');
    const [resume_name, setResumeName] = useState('');

    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation] = useCallbackPrompt(showDialog);
    const { t } = useTranslation();
    const [ekycstatus, seteKycStatus] = useState(false);
    const [processing, setProcessing] = useState(false);
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue, setError, clearErrors } = useForm(
        {
            mode: "all",
        }
    );

    useEffect(() => {
        fetchGeneralProfile();
        checkKycStatus();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (showPrompt) {
            ConfirmSaveChanges();
        }
    }, [showPrompt]);

    const ConfirmSaveChanges = () => {
        SwalBox.fire({
            title: t('_confirm_save_title_'),
            text: t('_confirm_save_msg_'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            // confirmButtonClass: 'button_confirm_class',
            cancelButtonColor: '#d33',
            // cancelButtonClass: 'button_cancel_class',
            confirmButtonText: t('_save_'),
            cancelButtonText: t('_cancel_')
        }).then((result) => {
            if (result.isConfirmed) {
                handleSubmit(saveGeneralProfile)();
                confirmNavigation();
            } else {
                confirmNavigation();
            }
        });
    }

    const fetchGeneralProfile = async () => {
        const response = await fetch(`${API_BASE}get-general-profile`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            var user = data.data;

            setValue('first_name', user.first_name);
            setValue('last_name', user.last_name);
            setValue('phone', user.phone);
            setValue('company_website', user.company_website);
            setValue('about_me', user.about_me);
            setValue('headline', user.headline);
            setValue('fb_link', user.fb_link);
            setValue('tw_link', user.tw_link);
            setValue('li_link', user.li_link);
            setResumeFile(user.resume);
            setResumeTitle(user.resume_title);
        } else {
            Toaster.error(data.message);
        }
    }

    function typeValidator(file) {
        if (file.type !== 'application/pdf') {
            setError('resume', { type: "focus", message: 'You can upload pdf file only.' });
            return true;
        }
        else { clearErrors('resume') }
        return null
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
        },
        maxFiles: 1,
        validator: typeValidator,
        onDrop: (acceptFile, fileRejections) => {
            if (fileRejections.length == 0) {
                setNewResume(acceptFile[0]);
                setResumeName(acceptFile[0].name);
                setShowDialog(true);
            } else {
                setNewResume('');
                setResumeName('');
            }
        },
    });

    const removeFile = () => {
        setNewResume('');
        setResumeName('');
        clearErrors('resume', '');
    }

    const saveGeneralProfile = async (form) => {
        setShowDialog(false);
        setProcessing(true);
        const formData = new FormData();
        formData.append('about_me', form.about_me);
        formData.append('fb_link', form.fb_link ? form.fb_link : '');
        if (ekycstatus == true) {
            formData.append('first_name', first_name);
            formData.append('last_name', last_name);
        } else {
            formData.append('first_name', form.first_name);
            formData.append('last_name', form.last_name);
        }
        formData.append('headline', form.headline ? form.headline : '');

        formData.append('li_link', form.li_link ? form.li_link : '');
        formData.append('phone', form.phone ? form.phone : '');
        formData.append('company_website', form.company_website ? form.company_website : '');
        formData.append('resume', new_resume);
        formData.append('tw_link', form.tw_link ? form.tw_link : '');

        const response = await fetch(`${API_BASE}post-general-profile`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            localStorage.setItem('lmsuser', JSON.stringify(data.data.user));
            cntxAuth.updateUser(data.data.user);
            Toaster.success(data.message);
            fetchGeneralProfile();
            removeFile();
            setShowDialog(false);
            setApiErrors([]);
            setProcessing(false);
            var DraftCourse = localStorage.getItem('DraftCourse');
            if (typeof DraftCourse !== 'undefined' && DraftCourse !== null) {
                setShowDialog(false);
                navigate('/dashboard/tutor/draft-courses');
            }
            var DCStep = localStorage.getItem('DCStep');
            var cid = localStorage.getItem('cid');

            if (typeof DCStep !== 'undefined' && DCStep !== null) {
                setShowDialog(false);
                navigate(`/dashboard/tutor/edit-course/${cid}/${5}`);
            }
        } else {
            setApiErrors(data?.data);
            // Toaster.error(data.message);
            setProcessing(false);
        }
    }



    const checkKycStatus = async () => {
        try {
            const response = await fetch(`${API_BASE}get-kyc-status`, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });
            const data = await response.json();
            if (data.status) {
                if (data.kyc == true || data.kyc == 'true') {
                    seteKycStatus(true);
                } else {
                    seteKycStatus(false);
                }
            }

        } catch (err) { }
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_general_profile_')}`}</title>
            </Helmet>
            <div className="tab-pane fade show active" id="v-pills-general" role="tabpanel" aria-labelledby="v-pills-general-tab">
                <div className="profile-dashboard-content">

                    <h4>{t('_general_profile_')} </h4>
                    {/*
                        resume_file !== '' && typeof resume_file !== 'undefined'
                        &&
                        <a className="btn become_tutor_btn mr-1" target="_blank" href={resume_file} download>Download Resume</a>
                    */}

                    <div className="clearfix"></div>
                    <div className="profile-dashboard-box">
                        <form onSubmit={handleSubmit(saveGeneralProfile)}>
                            <div className="row">
                                {
                                    auth_user.is_corporate === 1
                                        ?
                                        <>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <input
                                                        id="first_name" {...register("first_name", {
                                                            required: "required",
                                                            maxLength: {
                                                                value: 25,
                                                                message: t('_general_profile_com_name_vali_m1_')
                                                            },
                                                            minLength: {
                                                                value: 3,
                                                                message: t('_general_profile_com_name_vali_m2_')
                                                            }
                                                        })}
                                                        onChange={(event) => {
                                                            clearErrors('first_name');
                                                            setFirstName(event.target.value);
                                                            setShowDialog(true);
                                                        }}
                                                        disabled={ekycstatus}
                                                        readlonly={ekycstatus}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t('_general_profile_com_name_place_')}
                                                        defaultValue={first_name ? first_name : ''}
                                                        autoFocus
                                                    />
                                                    <span className="add-on max-chr-counter chrchk-first_name" data-ele="first_name" data-limit="25" data-showcounter="false"></span>
                                                    {
                                                        errors?.first_name
                                                            ?
                                                            <span className="text-danger">
                                                                {errors.first_name.type === "required" && t('_general_profile_com_name_vali_m3_')}
                                                                {errors.first_name.type !== "required" && errors.first_name.message}
                                                            </span>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        api_errors?.first_name
                                                            ?
                                                            <span className="text-danger">
                                                                {api_errors?.first_name.join(' ')}
                                                            </span>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        id="first_name" {...register("first_name", {
                                                            required: "required",
                                                            pattern: {
                                                                value: /^[a-zA-Z ']*$/,
                                                                message: t('_general_profile_com_name_vali_m4_')
                                                            },
                                                            maxLength: {
                                                                value: 80,
                                                                message: t('_general_profile_com_name_vali_m5_')
                                                            },
                                                            minLength: {
                                                                value: 3,
                                                                message: t('_general_profile_com_name_vali_m2_')
                                                            }
                                                        })}
                                                        disabled={ekycstatus}
                                                        readlonly={ekycstatus}
                                                        onChange={(event) => {
                                                            setShowDialog(true);
                                                            clearErrors('first_name');
                                                            setFirstName(event.target.value);
                                                        }}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t('_general_profile_first_name_place_')}
                                                        defaultValue={first_name ? first_name : ''}
                                                    />
                                                    <span className="add-on max-chr-counter chrchk-first_name" data-ele="first_name" data-limit="25" data-showcounter="false"></span>
                                                    {
                                                        errors?.first_name
                                                            ?
                                                            <span className="text-danger">
                                                                {errors.first_name.type === "required" && t('_emplist_error_fname_')}
                                                                {errors.first_name.type !== "required" && errors.first_name.message}
                                                            </span>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        api_errors?.first_name
                                                            ?
                                                            <span className="text-danger">
                                                                {api_errors?.first_name.join(' ')}
                                                            </span>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <input
                                                        id="last_name" {...register("last_name", {
                                                            required: "required",
                                                            pattern: {
                                                                value: /^[a-zA-Z ']*$/,
                                                                message: t('_general_profile_last_name_vali_m1_')
                                                            },
                                                            maxLength: {
                                                                value: 80,
                                                                message: t('_general_profile_last_name_vali_m2_')
                                                            },
                                                            minLength: {
                                                                value: 3,
                                                                message: t('_general_profile_last_name_vali_m3_')
                                                            }
                                                        })}
                                                        onChange={(event) => {
                                                            clearErrors('last_name');
                                                            setLastName(event.target.value);
                                                            setShowDialog(true);
                                                        }}
                                                        disabled={ekycstatus}
                                                        readlonly={ekycstatus}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t('_general_profile_last_name_place_')}
                                                        defaultValue={last_name ? last_name : ''}
                                                    />
                                                    <span className="add-on max-chr-counter chrchk-last_name" data-ele="last_name" data-limit="25" data-showcounter="false"></span>
                                                    {
                                                        errors.last_name
                                                            ?
                                                            <span className="text-danger">
                                                                {errors.last_name.type === "required" && t('_emplist_lastname_error_')}
                                                                {errors.last_name.type !== "required" && errors.last_name.message}
                                                            </span>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        api_errors?.last_name
                                                            ?
                                                            <span className="text-danger">
                                                                {api_errors?.last_name.join(' ')}
                                                            </span>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </>
                                }
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            {...register("phone", {
                                                pattern: {
                                                    value: /^(\+\d{1,3}[- ]?)?\d{8,16}$/,
                                                    message: t('_general_profile_phone_vali_m1_')
                                                },
                                            })}
                                            onChange={(event) => {
                                                clearErrors('phone');
                                                setPhone(event.target.value);
                                                setShowDialog(true);
                                            }}
                                            type="text"
                                            className="form-control"
                                            placeholder={t('_phone_number_')}
                                            defaultValue={phone ? phone : ''}
                                        />
                                        <span className="text-danger">
                                            {errors.phone && errors.phone.type === "required" && t('_general_profile_phone_vali_m2_')}
                                            {errors.phone && errors.phone.type !== "required" && errors.phone.message}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            id="headline" {...register("headline", {
                                                maxLength: {
                                                    value: 80,
                                                    message: t('_general_profile_heading_vali_m1_')
                                                }
                                            })}
                                            onChange={(event) => {
                                                clearErrors('headline');
                                                setHeadline(event.target.value);
                                                setShowDialog(true);
                                            }}
                                            type="text"
                                            className="form-control"
                                            placeholder={t('_headline_')}
                                            defaultValue={headline ? headline : ''}
                                        />
                                        <span className="add-on max-chr-counter chrchk-headline" data-ele="headline" data-limit="80" data-showcounter="true"></span>
                                        <span className="text-danger">
                                            {errors.headline && errors.headline.type === "required" && t('_general_profile_heading_vali_m2_')}
                                            {errors.headline && errors.headline.type !== "required" && errors.headline.message}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <div className="facebook_labell">
                                            <div className="social_linked">http://www.facebook.com/</div>
                                            <input
                                                {...register("fb_link",
                                                    {
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9./]+$/,
                                                            message: t('_general_profile_username_vali_m1_')
                                                        },
                                                        minLength: {
                                                            value: 5,
                                                            message: t('_general_profile_username_vali_m2_')
                                                        }
                                                    }
                                                )}
                                                onChange={(event) => {
                                                    setFBLink(event.target.value);
                                                    setShowDialog(true);
                                                }}
                                                type="text"
                                                className="form-control"
                                                placeholder={t('_username_')}
                                                defaultValue={fb_link ? fb_link : ''}
                                            />
                                            <span className="text-danger">
                                                {errors.fb_link && errors.fb_link.message}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <div className="facebook_labell">
                                            <div className="social_linked">http://www.twitter.com/</div>
                                            <input
                                                {...register("tw_link",
                                                    {
                                                        pattern: {
                                                            value: /^(?:@?[A-Za-z0-9_]{3,14}|[A-Za-z0-9_]{4,15})$/,
                                                            message: t('_general_profile_twitter_vali_m1_')
                                                        },
                                                        maxLength: {
                                                            value: 15,
                                                            message: t('_general_profile_twitter_vali_m2_')
                                                        },
                                                        minLength: {
                                                            value: 4,
                                                            message: t('_general_profile_twitter_vali_m3_')
                                                        },
                                                    }
                                                )}
                                                onChange={(event) => {
                                                    setTWLink(event.target.value);
                                                    setShowDialog(true);
                                                }}
                                                type="text"
                                                className="form-control"
                                                placeholder={t('_username_')}
                                                defaultValue={tw_link ? tw_link : ''}
                                            />
                                            <span className="text-danger">
                                                {errors.tw_link && errors.tw_link.message}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <div className="facebook_labell">
                                            <div className="social_linked">http://www.linkedin.com/</div>
                                            <input
                                                {...register("li_link",
                                                    {
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9./]+$/,
                                                            message: t('_general_profile_linkedin_vali_m1_')
                                                        },
                                                    }
                                                )}
                                                onChange={(event) => {
                                                    setLILink(event.target.value);
                                                    setShowDialog(true);
                                                }}
                                                type="text"
                                                className="form-control"
                                                placeholder={t('_resource_id_')}
                                                defaultValue={li_link ? li_link : ''}
                                            />
                                            <span className="text-danger">
                                                {errors.li_link && errors.li_link.message}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {
                                    auth_user.is_corporate === 1
                                        ?
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    id="company_website" {...register("company_website", {
                                                        required: "required",
                                                        pattern: {
                                                            value: /^((http|https?|ftp|smtp):\/\/)|(www.)|[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
                                                            message: t('_general_profile_website_vali_m1_')
                                                        },
                                                        minLength: {
                                                            value: 3,
                                                            message: t('_general_profile_website_vali_m2_')
                                                        },
                                                        maxLength: {
                                                            value: 80,
                                                            message: t('_general_profile_website_vali_m3_')
                                                        }
                                                    })}
                                                    onChange={(event) => {
                                                        clearErrors('company_website');
                                                        setCompanyWebsite(event.target.value);
                                                        setShowDialog(true);
                                                    }}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('_general_profile_comwebsite_place_')}
                                                    defaultValue={company_website ? company_website : ''}
                                                />
                                                <span className="add-on max-chr-counter chrchk-company_website" data-ele="company_website" data-limit="80" data-showcounter="false"></span>
                                                {
                                                    errors?.company_website
                                                        ?
                                                        <span className="text-danger">
                                                            {errors?.company_website.type === "required" && t('_general_profile_comwebsite_vali_m1_')}
                                                            {errors?.company_website.type !== "required" && errors?.company_website.message}
                                                        </span>
                                                        :
                                                        null
                                                }
                                                {
                                                    api_errors?.company_website
                                                        ?
                                                        <span className="text-danger">
                                                            {api_errors?.company_website.join(' ')}
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                {
                                                    resume_file !== '' && typeof resume_file !== 'undefined'
                                                        ?
                                                        <>
                                                            {
                                                                new_resume !== ''
                                                                &&
                                                                <div className="file-choosen upload_textbox">
                                                                    <div onClick={(e) => { removeFile(); }} className="btnGrp dlt_btn d-flex">
                                                                        <i className="fa fa-trash mr-2" aria-hidden="true"></i>
                                                                        <p>{resume_name}</p>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                new_resume === ''
                                                                &&
                                                                <>
                                                                    <div {...getRootProps()} className="upload_box_resume" >
                                                                        <img src={DEFAULT_UPDATE_RESUME_IMG} className="cursor_pointer" width="100%" alt='preview' />
                                                                        <input {...getInputProps()} />
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                new_resume !== ''
                                                                &&
                                                                <div className="file-choosen upload_textbox">
                                                                    <div onClick={(e) => { removeFile(); }} className="btnGrp dlt_btn d-flex">
                                                                        <i className="fa fa-trash mr-2" aria-hidden="true"></i>
                                                                        <p>{resume_name}</p>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                new_resume === ''
                                                                &&
                                                                <>
                                                                    <div {...getRootProps()} className="upload_box_resume" >
                                                                        <img src={DEFAULT_UPLOAD_RESUME_IMG} className="cursor_pointer" width="100%" alt='preview' />
                                                                        <input {...getInputProps()} />
                                                                    </div>
                                                                </>
                                                            }
                                                        </>

                                                }

                                                <span className="text-danger">
                                                    {
                                                        errors.resume &&
                                                        <>
                                                            <div onClick={(e) => { removeFile(); }} className="btnGrp dlt_btn d-flex">
                                                                {errors.resume.message}
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        resume_title !== '' && typeof resume_title !== 'undefined' &&
                                                        <>
                                                            <strong>{t('_general_profile_upload_file_')} : &nbsp;
                                                                <a className="btnGrp " target="_blank" href={resume_file} download>
                                                                    {resume_title}
                                                                </a>
                                                            </strong>
                                                        </>
                                                    }
                                                    {
                                                        api_errors?.resume
                                                            ?
                                                            <span className="text-danger">
                                                                {api_errors?.resume.join(' ')}
                                                            </span>
                                                            :
                                                            null
                                                    }
                                                </span>
                                            </div>
                                        </div>

                                }

                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <textarea
                                            {...register("about_me", { required: "required" })}
                                            onChange={(event) => {
                                                clearErrors('about_me');
                                                setAbout(event.target.value);
                                                setShowDialog(true);
                                            }}
                                            className="form-control"
                                            defaultValue={about_me ? about_me : ''}
                                            placeholder={
                                                (auth_user.is_corporate === 1)
                                                    ?
                                                    t('_general_profile_about_me_')
                                                    :
                                                    (t('_about_yourself_'))
                                            }
                                            rows="5"
                                        >
                                        </textarea>
                                        {
                                            errors?.about_me
                                                ?
                                                <span className="text-danger">
                                                    {errors.about_me.type === "required" && t('_general_profile_about_me_vali_m1_')}
                                                    {errors.about_me.type !== "required" && errors.email.message}
                                                </span>
                                                :
                                                null
                                        }
                                        {
                                            api_errors?.about_me
                                                ?
                                                <span className="text-danger">
                                                    {api_errors?.about_me.join(' ')}
                                                </span>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-success btn-circle btn-md" disabled={processing}>
                                {processing ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>{t('_save_')}</>}
                            </button>
                        </form>
                    </div>
                </div >
            </div >
        </>
    );
}

export default GeneralProfile;
