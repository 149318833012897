import { useEffect, useState } from "react";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import { API_BASE } from "../../constants/global";
import { useTranslation } from "react-i18next";

const Suggestedvideos = (course) => {
    const _token = localStorage.getItem('lmstoken');
    const { register, handleSubmit, formState: { errors }, setValue, setError, clearErrors } = useForm();
    const location = useLocation();
    const [reload, setReload] = useState(false);
    const [generatedvideos, setGeneratedVideos] = useState([]);
    const [video_filter, setVideoFilter] = useState('current');
    const { _slug } = useParams();
    const [languages, setLanguages] = useState('');
    const [language_filter, setLanguageFilter] = useState('en');
    const { t } = useTranslation();

    var settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }, {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };

    useEffect(() => {
        fetchVideos();
    }, [location, reload]);

    function getMultipleRandom(arr, num) {
        const shuffled = shuffle_videos(arr);
        return shuffled.slice(0, num);
    }

    function shuffle_videos(array) {
        let currentIndex = array.length, randomIndex;
        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    const fetchVideos = async (type, language) => {
        if (type == '') {
            type = video_filter;
        }
        //console.info(language_filter);
        try {
            let _course_info = course.course;
            let _current_slug = window.location.href;
            let _slide_id = _current_slug.substring(_current_slug.lastIndexOf('/') + 1);
            const response_mat = await fetch(`${API_BASE}get-single-material/${_course_info.slug}/${_slide_id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + _token
                },
            });
            const data_mat = await response_mat.json();
            if (data_mat.status) {
                let _topic_title = data_mat.data.slide.title;
                let language_filter_d = 'en';
                if (language) {
                    language_filter_d = language;
                } else {
                    language_filter_d = language_filter;
                }
                let _prompt_string = '';
                if (type == undefined || type == 'current') {
                    _prompt_string += _topic_title + ' | ' + _course_info.title;
                } else {
                    _prompt_string += _course_info.title;
                }
                let _youtube_call_url = `https://www.googleapis.com/youtube/v3/search?part=snippet&q=${_prompt_string} (in ${language_filter_d})&key=AIzaSyBNFIxrJ9I5Ti9aLIBVDPV9aCp0Jk-rKT0&maxResults=15&order=relevance&type=video&relevanceLanguage=${language_filter_d}`;
                const response_yotube = await fetch(_youtube_call_url, {
                    method: 'GET',
                    headers: { "Content-Type": "application/json", "Accept": "application/json", },
                });
                const data_youtube = await response_yotube.json();
                let youtube_vodeos = data_youtube.items;
                let _generated_vidoes = [];
                youtube_vodeos.forEach(function (yv) {
                    let _video_title = yv.snippet.title;
                    let _video_thumb = yv.snippet.thumbnails.high.url;
                    let _video_id = yv.id.videoId;
                    let _video_url = 'https://www.youtube.com/watch?v=' + _video_id;
                    _generated_vidoes.push({ 'title': _video_title, 'url': _video_url, 'thumb': _video_thumb });
                });
                setGeneratedVideos(getMultipleRandom(_generated_vidoes, 10));
            }
        } catch (err) { }
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-video" role="tabpanel" aria-labelledby="pills-video-tab">
                <div className="blog-card">
                    <div className="row justify-content-lg-center">
                        <div className="col-md-4 col-lg-4">
                            <div className="form-group mb-5">
                                <label><strong>{t('_learncourse_suggestvideo_filtervideo_')} :    </strong> </label>&nbsp;&nbsp;&nbsp;
                                <select onChange={(e) => { setVideoFilter(e.target.value); fetchVideos(e.target.value); }}>
                                    <option value='all'>{t('_learncourse_notebook_note_opt1_')} </option><option value='current'>{t('_learncourse_notebook_note_opt2_')} </option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="form-group mb-5">
                                <label htmlFor={'language_id'}><strong>{t('_learncourse_suggestvideo_lan_')} : </strong></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {
                                    <select onChange={(e) => { setLanguageFilter(e.target.value); fetchVideos('', e.target.value); }}>
                                        <option value="en">{t('_lan_eng_')} </option>
                                        <option value="hi">{t('_lan_hindi_')}</option>
                                        <option value="fr">{t('_lan_french_')} </option>
                                        <option value="jp">{t('_lan_japan_')} </option>
                                        <option value="es">{t('_lan_span_')} </option>
                                        <option value="ar">{t('_lan_arabic_')} </option>
                                        <option value="ru">{t('_lan_russian_')} </option>
                                        <option value="pr">{t('_lan_portu_')} </option>
                                    </select>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="sliderbox yt-slider-box">
                        {
                            generatedvideos !== ''
                            &&
                            <Slider {...settings}>
                                {
                                    generatedvideos.map((generatedvideo, index) =>
                                        <a target="_blank" key={index} href={generatedvideo.url}>
                                            <div className="blog-card-detail">
                                                <div className="custom-card-img">
                                                    <img src={generatedvideo.thumb} className="card-img-top" alt="Blog image" width="253" />
                                                </div>
                                                <div className="blog-card-inner">
                                                    <h5 className="yt-slider-title">{generatedvideo.title}</h5>
                                                </div>
                                            </div>
                                        </a>
                                    )
                                }
                            </Slider>
                        }
                    </div>
                </div>
                <div className="clearfix"></div>

            </div>
        </>
    );
}

export default Suggestedvideos;
