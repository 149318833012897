import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const UpgradedToAdmin = ({ ntfcn }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src={ntfcn?.group?.owner?.image} className="rounded-circle" alt={ntfcn?.group?.owner?.name} />
                    </div>
                    <div className="user-details-title">
                        <h6><Link to={`/profile-details/${ntfcn?.group?.owner?.slug}`}>{ntfcn?.group?.owner?.name}</Link> <span>{ntfcn.date_time}</span></h6>
                        <p>{t('_dh_lctab_m3_') + " "} - {ntfcn?.group?.title}</p>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>{t('_dh_lctab_m8_')} <strong>{ntfcn?.group?.title}</strong> {t('_dh_lctab_m9_')}.</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link" to={`/view-learning-circle/${ntfcn?.group?.uid}/feeds`} target="_blank">
                            <img src={ntfcn?.group?.image} alt={ntfcn?.group?.title} />
                            <span>{t('_dh_lctab_m10_')} </span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpgradedToAdmin;
