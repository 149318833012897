import { useEffect, useState } from "react";
import DetailListSkeleton from "../../../components/skeletons/DetailListSkeleton";
import { API_BASE } from "../../../constants/global";
import { useTranslation } from "react-i18next";

const Prerequisites = ({ course }) => {
    const _token = localStorage.getItem('lmstoken');
    const [prerequisites, setPrerequisites] = useState('');
    const [processing, setProcessing] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        fetchPrerequisites();
        // eslint-disable-next-line
    }, []);

    const fetchPrerequisites = async () => {
        setProcessing(true);
        const response = await fetch(`${API_BASE}get-course-prerequisites/${course.id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setPrerequisites(data.data.prerequisites);
        }
        setProcessing(false);
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-prerequisites" role="tabpanel" aria-labelledby="pills-prerequisites-tab">
                <div className="course-tab-contents">
                    <div className="course-tabs-pane-block">
                        <h3>{t('_coursedetail_prerequisites_')}</h3>
                        <div className="course-tabs-box">

                            <div className="course-tabs-body">
                                <div className="course-accordion-section">
                                    <ul className="about-course-list">
                                        {
                                            processing
                                                ?
                                                <DetailListSkeleton count={3} />
                                                :
                                                <>
                                                    {
                                                        prerequisites !== ''
                                                        &&
                                                        prerequisites.map((prerequisite, index) =>
                                                            <li key={index}><span>{prerequisite.description}.</span></li>
                                                        )
                                                    }

                                                    {
                                                        (prerequisites === '' || prerequisites.length <= 0)
                                                        &&
                                                        <li><span>{t('_coursedetail_prerequisites_tab_1_')} </span></li>
                                                    }
                                                </>
                                        }

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Prerequisites;
