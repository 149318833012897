import { Link } from "react-router-dom";
import { useContext } from "react";
import authContext from '../../../../context/auth/authContext';
import { useTranslation } from "react-i18next";

const Register = ({ ntfcn }) => {
    const cntxAuth = useContext(authContext);
    const { t } = useTranslation();

    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src="images/logo_icon.png" className="rounded-circle" alt="Profile Image" />
                    </div>
                    <div className="user-details-title">
                        <h6>EduKula<span>{ntfcn.date_time}</span></h6>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>{t('_dh_lctab_register_')}</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link" to="/dashboard/create-learning-circle" target="_blank">
                            {
                                cntxAuth.userInfo.is_corporate == 1
                                &&
                                <img src="images/corporate-to-create-learning-circles.jpg" alt="Course Image" />
                            }
                            {
                                cntxAuth.userInfo.is_corporate == 0
                                &&
                                <img src="images/create-learning-circles.jpg" alt="Course Image" />
                            }
                            <span>{t('_mycircle_tour_t1_')}</span>
                        </Link>
                    </div>
                    <div className="like-main-area">
                        <div className=""></div>
                        <div className=""></div>
                        <div className=""></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;
