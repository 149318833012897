import { useEffect, useState, useLayoutEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import RateStart from "../components/RateStart";
import { API_BASE } from "../constants/global";
import { Toaster } from "../entity/GlobalJS";
import About from "./tabs/About";
import Circles from "./tabs/Circles";
import Meetings from "./tabs/Meetings";
import Notebook from "./tabs/Notebook";
import Suggestedvideos from "./tabs/Suggestedvideos";
import QAs from "./tabs/QAs";
import { useTranslation } from "react-i18next";

const Details = ({ course, rate, certified, expier, needtoRefresh, refresh2 }) => {
    const { _slug } = useParams();
    const { _slide } = useParams();
    const navigate = useNavigate();
    const _token = localStorage.getItem('lmstoken');
    const [active_tab, setActiveTab] = useState(1);
    const [feedback_id, setFeedbackId] = useState('');
    const [questions, setQuestions] = useState('');
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const rateArrays = [1, 2, 3, 4, 5];
    const [my_rating, setMyRating] = useState(0);
    var fetched_rate = false;
    const [showSuggestedVideo, setShowSuggestedVideo] = useState(false);
    const [previous_slide, setPreviouSlide] = useState('1');
    const [next_slide, setNextSlide] = useState('1');
    const [ids_slide, setIdsSlide] = useState([]);
    const [current_slide, setCurrentSlide] = useState('');
    const location = useLocation();
    const [processing, setProcessing] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        fetchMaterials();
        fetchQuestions();
        isSuggestedVideoEnabled();
    }, []);

    useLayoutEffect(() => {
        fetchMaterials();
    }, []);


    useEffect(() => {
        fetchMaterials();
        fetchSlide();
    }, [location])


    setInterval(() => {
        if (!fetched_rate) {
            fetchRating();
        }
    }, 3000);
    const postRate = async (rate) => {
        const formData = new FormData();
        formData.append('course_id', course.id);
        formData.append('star_count', rate);
        const response = await fetch(`${API_BASE}post-course-rating`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const rdata = await response.json();
        if (rdata.status) {
            setFeedbackId(rdata.data.rating.id);
            Toaster.success(rdata.message);
            document.getElementById('ratingModalCenterClose').click();
            document.getElementById('nextModalCenterClick').click();
            setMyRating(rate);
            fetchRating();
        } else {
            Toaster.error(rdata.message);
        }
    }

    const fetchQuestions = async () => {
        const response = await fetch(`${API_BASE}get-course-feedback-questions`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });
        const data = await response.json();
        if (data.status) {
            setQuestions(data.data.questions);
        }
    }

    const fetchRating = async () => {
        if (typeof course !== 'undefined' && !fetched_rate) {
            fetched_rate = true;
            const response = await fetch(`${API_BASE}get-my-course-rate/${course.id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + _token
                },
            });
            const data = await response.json();
            if (data.status) {
                setMyRating(data.data.rate);
            }
        }
    }

    const onSaveFeedback = async (form) => {
        const response = await fetch(`${API_BASE}post-course-feedback/${feedback_id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(form)
        });
        const rdata = await response.json();
        if (rdata.status) {
            document.getElementById('nextModalCenterClose').click();
            Toaster.success(rdata.message);
        } else {
            Toaster.error(rdata.message);
        }
    }
    const isSuggestedVideoEnabled = async () => {
        let formData = {};
        const _lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
        const _lms_learner_id = _lmsuser['id'];
        formData.user_id = _lms_learner_id;
        formData.action = "fetch";
        const response = await fetch(`${API_BASE}get-responsible-ai`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(formData)
        });
        const data = await response.json();
        if (data.status) {
            if (data.data.suggested_video == "TRUE") { setShowSuggestedVideo(true); }
            //console.info(showSuggestedVideo);
        } else {
        }
    }

    function findPreviousAndNext(array, value) {
        let _previous_id = '';
        let _next_id = '';
        for (let i = 0; i < array.length; i++) {
            if (array[i] == value) {
                _previous_id = array[(i - 1)];
                _next_id = array[(i + 1)];
                if (_previous_id != 'undefined' && _previous_id != undefined) {
                    setPreviouSlide(_previous_id);
                } else {
                    setPreviouSlide('');
                }
                if (_next_id != 'undefined' && _next_id != undefined) {
                    setNextSlide(_next_id);
                } else {
                    setNextSlide('');
                }
            }
        }
        return { _previous_id, _next_id }
    }
    function extractIds(array45) {
        if (Array.isArray(array45) === true) {
            const idsArray = array45.map((entry) => entry.uid);
            return idsArray;
        } else {
            return [];
        }
    }

    const fetchMaterials = async () => {
        const response = await fetch(`${API_BASE}get-learning-materials/${_slug}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });
        const data = await response.json();
        if (data.status) {

            if (data.data.sections === "") {
                let IDtoSend = extractIds(data.data.outer_slides);
                setIdsSlide(IDtoSend);
                findPreviousAndNext(IDtoSend, _slide);
            }
            if (data.data.sections.length > 0) {
                var _material_string = '';
                let _materials = data.data;
                if (_materials.outer_slides.length > 0) {
                    var s_mat = _materials.outer_slides.map(obj => obj.uid);
                    _material_string += s_mat.join(',');
                }
                if (_materials.sections.length > 0) {
                    _materials.sections.forEach(function (s) {
                        var se_mat = s.slides.map(obj => obj.uid);
                        if (se_mat.length > 0) {
                            _material_string += ',';
                            _material_string += se_mat.join(',');
                        }
                    });
                }
                const resultArray = _material_string.split(",");
                setIdsSlide(resultArray);
                findPreviousAndNext(resultArray, _slide);
            }
        }
    }


    const fetchSlide = async () => {
        if (_slug !== '' && typeof _slug !== 'undefined' && _slide != undefined) {
            const response = await fetch(`${API_BASE}get-single-material/${_slug}/${_slide}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });

            const data = await response.json();
            if (data.status) {
                setCurrentSlide(data.data.slide.id);
            }
        }
    }

    const updateProgress = async (current_slideID) => {
        setProcessing(true);
        Toaster.dismiss();

        var forData = new FormData();
        forData.append('course_id', course.id);
        forData.append('slide_id', current_slideID);
        const response = await fetch(`${API_BASE}update-progress/true`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: forData
        });
        const data = await response.json();
        if (data.status) {
            if (data.data.completed) {
                //Toaster.success(data.message);
                var checkbox = document.getElementById('slide_' + _slide);
                if (!checkbox.checked) {
                    document.getElementById('slide_' + _slide).click();
                    checkbox.checked = true;
                }
                needtoRefresh();
                setProcessing(false);
                navigate(`/learn-course/${_slug}/${next_slide}`);
            } else {
                setProcessing(false);
            }
        } else {
            Toaster.error(data.message);
            setProcessing(false);
        }
    }



    const gotoPreviousSlide = async () => {
        navigate(`/learn-course/${_slug}/${previous_slide}`);
    }

    const gotoNextSlide = (current_slideID) => {
        updateProgress(current_slideID);
    }




    return (
        <>
            {
                (course !== '' && typeof course !== 'undefined')
                &&
                <div className="explore-block">
                    <div className="row">
                        {previous_slide != '' && <div className="col-6"><button onClick={(e) => { gotoPreviousSlide(); }} id="ek-previous-float-left" className="btn btn-circle btn-md submit-pink-btn"><i className="fa fa-chevron-left" aria-hidden="true"></i>&nbsp;{t('_custom_pagi_previous_')}</button></div>}
                        {next_slide != '' && <div className={`${previous_slide == '' ? 'col-12' : 'col-6'}`}><button onClick={(e) => { gotoNextSlide(current_slide); }} id="ek-next-float-right" className="btn btn-circle btn-md submit-pink-btn" disabled={processing} >

                            {processing ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>{t('_custom_pagi_next_')} &nbsp;</>}

                            <i className="fa fa-chevron-right" aria-hidden="true"></i></button></div>}
                    </div>
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item tour-course-step-01"><a className={`link ${active_tab === 1 ? 'active' : ''}`} onClick={(e) => { setActiveTab(1); }} id="pills-course-tab" data-toggle="pill" href="#pills-course" role="tab" aria-controls="pills-course" aria-selected="true">{t('_learncourse_detail_overview_')} </a></li>
                        <li className="nav-item tour-course-step-02"><a className={`link ${active_tab === 2 ? 'active' : ''}`} onClick={(e) => { setActiveTab(2); }} id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">{t('_learncourse_detail_qa_')} </a></li>
                        <li className="nav-item tour-course-step-03"><a className={`link ${active_tab === 3 ? 'active' : ''}`} onClick={(e) => { setActiveTab(3); }} id="pills-note-tab" data-toggle="pill" href="#pills-note" role="tab" aria-controls="pills-note" aria-selected="true">{t('_learn_course_tour_t3_')} </a></li>
                        {
                            (course.mode_of_learning !== 'Self Paced') && certified == false && expier === 0
                            &&
                            <li className="nav-item tour-course-step-04"><a className={`link ${active_tab === 4 ? 'active' : ''}`} onClick={(e) => { setActiveTab(4); }} id="pills-live-tab" data-toggle="pill" href="#pills-live" role="tab" aria-controls="pills-live" aria-selected="true">{t('_learncourse_detail_livesessions_')} </a></li>
                        }
                        {
                            (course.circles > 0)
                            &&
                            <li className="nav-item"><a className={`link ${active_tab === 5 ? 'active' : ''}`} onClick={(e) => { setActiveTab(5); }} id="pills-circle-tab" data-toggle="pill" href="#pills-circle" role="tab" aria-controls="pills-circle" aria-selected="true">{t('_dashboard_home_t4_')} </a></li>
                        }
                        {
                            showSuggestedVideo == true
                            &&
                            <li className="nav-item"><a className={`link ${active_tab === 6 ? 'active' : ''}`} onClick={(e) => { setActiveTab(6); }} id="pills-video-tab" data-toggle="pill" href="#pills-video" role="tab" aria-controls="pills-video" aria-selected="true">{t('_r_suggestedvideos_')} </a></li>
                        }
                        <li className="book_session"><a className="rating_text cursor-pointer" data-toggle="modal" data-target="#ratingModalCenter"><i className="fas fa-star"></i> {t('_learncourse_ratecour_m1_')} </a></li>
                    </ul>

                    <div className="modal fade ratingpopup" id="ratingModalCenter" tabIndex="-1" role="dialog" aria-labelledby="ratingModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" id="ratingModalLongTitle">{t('_learncourse_ratecour_m2_')} </h4>
                                    <button type="button" id="ratingModalCenterClose" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <h5>{t('_managelc_select_rating_')} </h5>
                                    {
                                        rateArrays.map((rate_val, indexv) =>
                                            <RateStart key={indexv} postRate={postRate} value={rate_val} my_rating={my_rating} />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <button id="nextModalCenterClick" className="d-none" data-toggle="modal" data-target="#nextModalCenter">RATE2</button>
                    <div className="modal fade ratingpopup2" id="nextModalCenter" tabIndex="-1" role="dialog" aria-labelledby="nextModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                            <form onSubmit={handleSubmit(onSaveFeedback)}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title" id="nextModalLongTitle">{t('_learncourse_ratecour_m3_')}</h4>
                                        <button type="button" id="nextModalCenterClose" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {
                                            questions !== ''
                                            &&
                                            questions.map((question, index) =>
                                                <div key={index} className="form-group">
                                                    <label>{question.question}</label>
                                                    <div className="form-check">
                                                        <input {...register(`feedback.${question.id}`)} id={`exampleRadios1${index}1`} className="form-check-input" type="radio" value="Yes" />
                                                        <label className="form-check-label" htmlFor={`exampleRadios1${index}1`}>{t('_lp_yes_')}</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input {...register(`feedback.${question.id}`)} className="form-check-input" id={`exampleRadios1${index}2`} type="radio" value="No" />
                                                        <label className="form-check-label" htmlFor={`exampleRadios1${index}2`}>{t('_lp_no_')}</label>
                                                    </div>
                                                    <div className="form-check disabled">
                                                        <input {...register(`feedback.${question.id}`)} className="form-check-input" id={`exampleRadios1${index}3`} type="radio" value="Not Sure" />
                                                        <label className="form-check-label" htmlFor={`exampleRadios1${index}3`}>{t('_managelc_not_sure_')} </label>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        <div className="clear"></div>
                                        <div className="btn_right">
                                            <input type="submit" value="Save" />
                                            <button type="button" data-dismiss="modal">{t('_cancel_')} </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="tab-content" id="pills-tabContent">
                        {
                            active_tab === 1
                            &&
                            <About course={course} />
                        }

                        {
                            active_tab === 2
                            &&
                            <QAs course={course} />
                        }

                        {
                            active_tab === 3
                            &&
                            <Notebook course={course} />
                        }

                        {
                            (course.mode_of_learning !== 'Self Paced') && active_tab === 4
                            &&
                            <Meetings course={course} certified={certified} expier={expier} />
                        }

                        {
                            active_tab === 5
                            &&
                            <Circles course={course} />
                        }
                        {
                            active_tab === 6
                            &&
                            <Suggestedvideos course={course} />
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default Details;
