import { useEffect, useRef, useState, useContext, useLayoutEffect, React, useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { API_BASE, DEFAULT_UPLOAD_FILE_IMG, DEFAULT_UPLOAD_FILE_IMG_NEW } from "../../../../constants/global";

import { SwalBox, Toaster } from "../../../../entity/GlobalJS";
import TinyMCEEditor from "../../../../entity/TinyMCEEditor";
import parse from 'html-react-parser';
import ReorderMaterials from "./ReorderMaterials";
import CustomPagination from "../../../../entity/CustomPagination";
import { Modal } from "react-bootstrap";
import EduKulaAi from "../../../../entity/EdukulaAi";
import DataTable from 'react-data-table-component';
import Cookies from "js-cookie";
import ProgressBar from "@ramonak/react-progress-bar";
//import AddQuizBankQue from "./QA/AddQuizBankQue";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useTranslation } from "react-i18next";

const Materials = (props) => {
    const navigate = useNavigate();
    const ref = useRef();
    const { register, control, formState: { errors }, getValues, setError, clearErrors } = useForm();
    const _token = localStorage.getItem('lmstoken');
    const course_id = props.course_id;
    const [upFileClass, setUpFileClass] = useState('');
    const [total_count, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [reordering, setReordering] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [processingplaylist, setProcessingPlayList] = useState(false);
    const [processingplaylistg, setProcessingPlayListg] = useState(false);
    const [isQuizDisplay, setQuiz] = useState(false);
    const [outer_slides, setOuterSlides] = useState('');
    const [sections, setSections] = useState('');
    const [section_id, setSectionId] = useState('');
    const [section_title, setSectionTitle] = useState('');
    const [section_desc, setSectionDesc] = useState('');
    const [slide_section_id, setSlideSectionId] = useState('');
    const [slide_id, setSlideId] = useState('');
    const [slide_title, setSlideTitle] = useState('');
    const [slide_desc, setSlideDesc] = useState('');
    const [assignment_desc, setAssignmentDesc] = useState('');
    const [youtube_url, setYoutubeUrl] = useState('');
    const [material_file, setMaterialFile] = useState('');
    const [file_name, setFileName] = useState('');
    const [quiz_list, setQuizList] = useState('');
    const [view_slide, setViewSlide] = useState('');
    const [course_zip, setCourseZip] = useState('');
    const [zip_name, setZipName] = useState('');
    const [zipFileClass, setZipFileClass] = useState('');
    const [zipFileError, setZipFileError] = useState('');
    const [course_folder, setCourseFolder] = useState('');
    const [folder_name, setFolderName] = useState('');
    const [folderError, setFolderError] = useState('');
    const [savingSection, setSavingSection] = useState(false);
    const [savingSlide, setSavingSlide] = useState(false);
    const [savingMaterial, setSavingMaterial] = useState(false);
    const [savingAssignment, setSavingAssignment] = useState(false);
    const [savingYoutube, setSavingYoutube] = useState(false);
    const [savingZip, setSavingZip] = useState(false);
    const [savingFolder, setSavingFolder] = useState(false);
    const [savingPlaylist, setSavingPlaylist] = useState(false);
    const [playlist_title, setPlaylistTitle] = useState('');
    const [savingQuestionbank, setSavingQuestionbank] = useState(false);

    const [qbankcolumns, setQbankcolumns] = useState('');
    const [qbankrows, setQbankrows] = useState('');
    const [generatedvideos, setGeneratedVideos] = useState([]);
    const [playlistvideos, setPlaylistVideos] = useState([]);
    const [aiautofill, setAiAutoFill] = useState(null);

    const [assignment_brief, setAssignmentBrief] = useState('');
    const [assignment_submissiontype, setAssignmentSubmissionType] = useState('');
    const [refresh, setRefresh] = useState(0);

    const [videoSubtitleUpload, setVideoSubtitleUpload] = useState('');
    const [vidSubFileName, setVideoSubFileName] = useState('');

    const [videoSubtitleUpload2, setVideoSubtitleUpload2] = useState('');
    const [vidSubFileName2, setVideoSubFileName2] = useState('');
    const [upFileClass4, setUpFileClass4] = useState('');


    const [vidConType, setVidConType] = useState('none');
    const [vidConType2, setVidConType2] = useState('video');
    const [vidConType3, setVidConType3] = useState('without_transcript');
    const [getSelectedRows, setGetSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isAIvideo, setIsAIvideo] = useState(false);
    const [videoViewEdit, setVideoViewEdit] = useState(false);

    const [assignmentMark, setAssignmentMark] = useState('');
    const [aigrading, setAIgrading] = useState('');
    const [gradingCriteria, setGradingCriteria] = useState('');

    const [progress_bar_one, setProgressBarOne] = useState(0);
    const [progress_bar_one_msg, setProgressBarOneMsg] = useState('Please wait.');
    const [youtubeMaterial, setYoutubeMaterial] = useState('');
    let interval;

    const { t } = useTranslation();
    const [transcriptValidationMsg, setTranscriptValidationMsg] = useState('');
    const [showtranscriptMenu, setShowtranscriptMenu] = useState(false);

    const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, setValue: setValue2, setError: setError2, clearErrors: clearErrors2 } = useForm();

    const setprogresstozero = () => {
        setProgressBarOne(0);
    }

    const clearprogressinterval = () => {
        clearInterval(interval);
    }

    const needtoRefresh = () => {
        setRefresh(!refresh);
    };
    const removePhoto = () => {
        setMaterialFile('');
        setFileName('');
        setUpFileClass('');
        setShowtranscriptMenu(false);
        setVidConType3('without_transcript');
        setTranscriptValidationMsg('');
        setVideoSubtitleUpload2('');
    }

    const removeZip = () => {
        setCourseZip('');
        setZipName('');
        setZipFileClass('');
    }

    const removeFolder = () => {
        setCourseFolder('');
        setFolderName('');
        ref.current.setAttribute("directory", "");
        ref.current.setAttribute("webkitdirectory", "");
        ref.current.setAttribute("mozdirectory", "");
    }

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        onDrop: (acceptFile, fileRejections) => {

            if (acceptFile[0].type == 'video/mp4' || acceptFile[0].type == 'video/mp4') {
                setShowtranscriptMenu(true);
            } else {
                setShowtranscriptMenu(false);
            }

            setMaterialFile(acceptFile[0]);
            setFileName(acceptFile[0].name);
            setUpFileClass('upfile_img_vdo');
            moderateUploadedMedia(acceptFile[0]);
        },
    });

    function typeValidator(file) {
        if (file.type !== 'application/zip') {
            setZipFileError('Please upload zip file only');
            return true;
        }
        return null
    }

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        accept: {
            'application/zip': []
        },
        maxFiles: 1,
        validator: typeValidator,
        onDrop: (acceptFile, fileRejections) => {
            if (fileRejections.length == 0) {
                setCourseZip(acceptFile[0]);
                setZipName(acceptFile[0].name);
                setZipFileClass('upfile_img_vdo');
                setZipFileError('');
            } else {
                removeZip();
            }
        },
    });

    const clearSectionError = () => {
        clearErrors('section_title');
        clearErrors('section_desc');
        clearErrors('set_order_after');
    }

    const clearSlideError = () => {
        clearErrors('slide_title');
        clearErrors('slide_desc');
    }

    const clearMaterialError = () => {
        clearErrors('slide_section');
        clearErrors('material_title');
        clearErrors('material_file');
        clearErrors('transcript_file');
    }

    const clearAssignmentError = () => {
        clearErrors('assignment_title');
        clearErrors('assignment_desc');
        clearErrors('assignment_mark');
    }

    const setPopSection = (id = '', title = '', desc = '') => {
        clearSectionError();
        setSectionId(id);
        setSectionTitle(title);
        setSectionDesc(desc);
    }

    const setPopSlideFile = (id = '', title = '', desc = '', extraData = "") => {
        clearMaterialError('');
        clearSlideError('');
        setSlideId(id);
        setSlideTitle(title);
        setSlideDesc(desc);
        setAssignmentDesc(desc);
        setMaterialFile('');
        setYoutubeUrl(desc);
        setYoutubeMaterial(extraData);
    }

    const setPopSlideQuiz = (objSlide = '') => {
        if (objSlide !== '') {
            setSlideId(objSlide.id);
            setSlideTitle(objSlide.title);
            setAssignmentDesc(objSlide.material.material);
            var questions = [];
            objSlide.material.questions.map((quizo, index) => {
                var questionX = quizo.question;
                var answersX = [];
                quizo.answers.map((answer, indexs) => {
                    answersX.push({ is_correct: answer.is_correct === 'Yes' ? true : false, value: answer.answer, description: answer.description });
                });
                questions.push({ question: questionX, answers: answersX });
            });
            setQuizList(questions);
        } else {
            setSlideId('');
            setSlideTitle('');
            setYoutubeUrl('');
            setYoutubeMaterial('');
            setAssignmentDesc('');
            setQuizList('');
        }
    }

    useEffect(() => {
        if (ref.current !== null) {
            ref.current.setAttribute("directory", "");
            ref.current.setAttribute("webkitdirectory", "");
            ref.current.setAttribute("mozdirectory", "");
        }
        setchAiAutofill();
        fetchSections(0);
        getQuizBank();

    }, [ref, refresh]);

    const handleFolderUpload = (e) => {
        setFolderName(e.target.files[0].webkitRelativePath.split("/")[0]);
        setCourseFolder(e.target.files);
        // const ArrFiles = [];
        // for (const file of e.target.files) {
        //     ArrFiles.push([file.webkitRelativePath, file]);
        // }
    }

    const getQuizBank = async () => {
        /*
        const formData = new FormData();
        formData.append('course_id', course_id);

        const response = await fetch(`${API_BASE}post-get-course-quiz-bank/`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const data = await response.json();
        //console.info(data);
        //if (data.status) {

        //}
        */
    }



    const fetchSections = async (page = 0) => {
        //console.info('gettting data');
        const response = await fetch(`${API_BASE}get-course-materials/${course_id}?limit=all`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            const d1 = data.data.material_sections.only_slide;
            const d2 = data.data.material_sections.section_with_slide;
            if (typeof d1 !== 'undefined' && d1.length > 0) {
                setOuterSlides(d1);
            } else {
                setOuterSlides('');
            }
            if (typeof d2 !== 'undefined' && d2.length > 0) {
                setSections(d2);
                setTotalCount(data.data.material_sections.total_count);
            } else {
                setSections('');
                setTotalCount(0);
            }
            if (page === 0) {
                setCurrentPage(0);
            }

            if (!props.is_edit) {
                props.setCount(0);
            }
            if (!props.is_edit && ((typeof d1 !== 'undefined' && d1.length > 0 && d1 !== '') || (typeof d2 !== 'undefined' && d2.length > 0 && d2 !== '' && d2[0].slides.length > 0))) {
                props.setCount(1);
            }
        } else {
            setOuterSlides('');
            setSections('');
        }
    }

    const saveSection = async () => {
        if (section_title === '') {
            setError("section_title", { type: "focus", message: t('_createcourse_step1_m7_') }, { shouldFocus: true });
            return false;
        }
        // if (section_desc === '') {
        //     setError("section_desc", { type: "focus", message: 'Please enter short description.' }, { shouldFocus: true });
        //     return false;
        // }
        setSavingSection(true);
        const formData = new FormData();
        formData.append('title', section_title);
        // formData.append('description', section_desc);
        formData.append('description', '');
        var sURL = `${API_BASE}post-save-course-section/${course_id}${section_id !== '' ? `/${section_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            document.getElementById('SectionModalLongClose').click();
            Toaster.success(data.message);
            fetchSections(currentPage);
            setSavingSection(false);
        } else {
            Toaster.error(data.message);
            setSavingSection(false);
        }
    }

    const saveSlide = async () => {
        var section_id = getValues('slide_section1');
        if (slide_title === '') {
            setError("slide_title", { type: "focus", message: t('_lcmaterial_msg_m16_') }, { shouldFocus: true });
            return false;
        }
        if (slide_desc === '') {
            setError("slide_desc", { type: "focus", message: t('_lcmaterial_msg_m15_') }, { shouldFocus: true });
            return false;
        }
        setSavingSlide(true);
        const formData = new FormData();
        if (typeof section_id !== 'undefined' && section_id !== '') {
            formData.append('section_id', section_id);
        }
        if (isAIvideo === true) {
            formData.append('isAIvideo', true);
        }
        formData.append('course_id', course_id);
        formData.append('title', slide_title);
        formData.append('description', slide_desc);
        formData.append('conversion_type', vidConType);

        if (vidConType === 'none') {
            formData.append('generate_video', 'NO');
        } else if (vidConType === 'video' || vidConType === 'video-transcript') {
            formData.append('generate_video', 'YES');
        }


        var sURL = `${API_BASE}post-save-course-slide${slide_id !== '' ? `/${slide_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            document.getElementById('SlideModalLongClose').click();
            if (vidConType != "" && vidConType != null && vidConType != "none") {
                fetchSections(currentPage);
                clearSlideError();
                setSavingSlide(false);
                setIsAIvideo(false);
                processConvertSlideToVideo(data.data.slider_id, vidConType);
            } else {
                Toaster.success(data.message);
                fetchSections(currentPage);
                clearSlideError();
                setSavingSlide(false);
                setIsAIvideo(false);
            }
        } else {
            Toaster.error(data.message);
            setSavingSlide(false);
            setIsAIvideo(false);
        }
    }


    const updateProgressBar = (totalSeconds, progresstype) => {

        setProgressBarOne(0);
        document.getElementsByClassName('ek-progress-bar-barCompleted')[0].style.width = 0 + '%';
        document.getElementsByClassName('ek-progress-bar-barCompleted')[0].style.backgroundColor = '#ef4848';
        const intervalDuration = 1000; // 1 second
        const totalSteps = totalSeconds;
        let currentStep = 0;

        interval = setInterval(() => {
            if (currentStep < totalSteps) {
                currentStep++;
                //const progress = (currentStep / totalSteps) * 100;
                const progress = Math.floor((currentStep / totalSteps) * 100);
                setProgressBarOne(progress);
                if (progresstype == 'single_video') {
                    setProgressBarOneMsg(t('_mate_converting_msg_m1_'));
                } else if (progresstype == 'multi_video') {
                    setProgressBarOneMsg(t('_mate_converting_msg_m2_'));
                } else if (progresstype == 'material') {
                    setProgressBarOneMsg(t('_mate_converting_msg_m3_'));
                } else {
                    setProgressBarOneMsg(t('_sweetalert_wait_'));
                }
                if (progress > 0) {
                    document.getElementsByClassName('ek-progress-bar-barCompleted')[0].style.width = progress + '%';
                    document.getElementsByClassName('ek-progress-bar-barCompleted')[0].style.backgroundColor = '#ef4848';
                }
            } else {
                clearInterval(interval); // Stop the interval when progress reaches 100%
                document.getElementById('ProgressModalLongOpen').click();
                setProgressBarOne(1);
                document.getElementsByClassName('ek-progress-bar-barCompleted')[0].style.width = '1%';
                SwalBox.close();
                var _swal_msg = t('_mate_converting_msg_m4_');
                if (progresstype == 'single_video') {
                    _swal_msg = t('_mate_converting_msg_m4_');
                } else if (progresstype == 'multi_video') {
                    _swal_msg = t('_mate_converting_msg_m4_');
                } else if (progresstype == 'material') {
                    _swal_msg = t('_mate_converting_msg_m5_');
                }
                //console.info(data);
                SwalBox.fire(
                    'Success!',
                    _swal_msg,
                    'success'
                );
                setTimeout(() => {
                    SwalBox.close();
                    needtoRefresh();
                }, 2000);
            }
        }, intervalDuration);
    };

    const processConvertSlideToVideo = async (_convert_slide_id, vidConType) => {
        /*
                SwalBox.fire({
                title: 'Please Wait...',
                html: '<div><p>Your text content will be converted into a video shortly, depending on its length. Thank you for your patience.</p><progress height="40px" width="300px" animateOnRender="true" id="progressBar" max="100" value={progress_bar_one} className="ek-rogress-bar-wrapper" barContainerClassName="ek-rogress-bar-container" completedClassName="ek-rogress-bar-barCompleted" labelClassName="ek-rogress-bar-label"></progress></div>',
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    simulateProgress();
                }
                });

        */
        document.getElementById('ProgressModalLongOpen').click();
        updateProgressBar(130, 'single_video');
        const formData = new FormData();
        formData.append('convert_slide_id', _convert_slide_id);
        formData.append('conversion_type', vidConType);

        var sURL = `${API_BASE}post-convert-course-slide-to-video`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            clearInterval(interval);
            document.getElementById('ProgressModalLongOpen').click();
            setProgressBarOne(0);
            SwalBox.close();
            //console.info(data);
            SwalBox.fire(
                'Success!',
                'Your slide has been converted into a video file!',
                'success'
            );
            setTimeout(() => {
                SwalBox.close();
                needtoRefresh();
            }, 2000);
        }
    }



    const saveMaterial = async () => {
        var slide_section2 = getValues('slide_section2');
        if (slide_title === '') {
            setError("material_title", { type: "focus", message: 'Please enter material title.' }, { shouldFocus: true });
            return false;
        }
        if (slide_desc != "") {

        } else {
            if (material_file === '') {
                setError("material_file", { type: "focus", message: 'Please select materials file.' }, { shouldFocus: true });
                return false;
            }
        }
        if (material_file !== '') {
            let _allowed_material_type = [
                'video/3gpp', 'video/x-msvideo', 'video/x-flv', 'video/jpeg', 'video/x-ms-wmv', 'video/mp4', 'application/mp4', 'video/quicktime', 'video/webm', 'video/x-ms-asf', 'video/mkv', 'video/x-matroska',
                'application/x-7z-compressed', 'application/zip', 'application/x-rar-compressed', 'application/x-tar',
                'application/pdf',
                'audio/x-aac', 'audio/x-ms-wma',
                'image/bmp', 'image/gif', 'image/jpeg', 'application/vnd.oasis.opendocument.image', 'image/png', 'image/tiff',
                'text/csv', 'text/plain', 'text/tab-separated-values', 'text/richtext', 'text/calendar', 'application/rtf', 'application/vnd.oasis.opendocument.text',
                'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.spreadsheetml.template', 'application/vnd.oasis.opendocument.spreadsheet', 'application/oda', 'application/msword',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/vnd.oasis.opendocument.presentation',
            ];
            let _is_a_valid_mine = _allowed_material_type.includes(material_file.type);
            if (_is_a_valid_mine != true) {
                SwalBox.fire({
                    title: 'Unsupported File Format',
                    text: "The file " + material_file.name + " you're trying to upload is not supported by EduKula. Please ensure that your file meets our supported file formats, which include: PDF, Word, PPT, Audio, Video, Image, ZIP, SCORM and TINCAN",
                    icon: 'warning',
                });
                setError("material_file", { type: "focus", message: 'Please select a valid file.' }, { shouldFocus: true });
                return false;
            }
        }
        clearMaterialError();
        setSavingMaterial(true);
        const formData = new FormData();
        if (slide_section2 !== '') {
            formData.append('section_id', slide_section2);
        }
        if (material_file !== '') {
            formData.append('material_file', material_file);
        }

        formData.append('video_transcript', vidConType3);

        if (vidConType3 === 'with_transcript') {
            if (videoSubtitleUpload2 !== "") {
                formData.append('transcript_file', videoSubtitleUpload2);
            } else if (videoSubtitleUpload2 === "") {
                setError("transcript_file", { type: "focus", message: 'Please select transcript file.' }, { shouldFocus: true });
                setTranscriptValidationMsg('');
                setSavingMaterial(false);
                return false;
            }
        }

        formData.append('course_id', course_id);
        formData.append('title', slide_title);
        var sURL = `${API_BASE}post-save-course-material${slide_id !== '' ? `/${slide_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            document.getElementById('UploadModalLongClose').click();
            Toaster.success(data.message);
            fetchSections(currentPage);
            setSavingMaterial(false);
        } else {
            Toaster.error(data.message);
            setSavingMaterial(false);
        }
        setVideoViewEdit(false);
        setViewSlide('')
        setUpFileClass('');
        setShowtranscriptMenu(false);
        setVideoSubtitleUpload2('');
        setVideoSubFileName2('');
        setUpFileClass4('');
    }

    const needAiHelpForAssignment = async () => {
        //startAssesementWithoutAi();

        SwalBox.fire({
            allowOutsideClick: false,
            html: "Would you like to create this assignment <br> with AI's help?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#30314c',
            cancelButtonColor: '#30314c',
            confirmButtonText: 'Yes, please.',
            cancelButtonText: 'No, thank you.'
        }).then((result) => {
            if (result.isConfirmed) {
                setAssignmentBrief('');
                startAssesementWithAi();
            } else {
                setAssignmentBrief('');
                startAssesementWithoutAi();
            }
        });

    }

    const startAssesementWithAi = async () => {
        document.getElementById('MaterialsTypeModalLongClose').click();
        document.getElementById('AssignmentAiModalLongOpen').click();
    }
    const startAssesementWithoutAi = async () => {
        document.getElementById('AssignmentModalOpen').click();
        setAssignmentBrief('');
    }

    const saveAssignment = async () => {
        if (slide_title === '') {
            setError("assignment_title", { type: "focus", message: 'Please enter assignment title.' }, { shouldFocus: true });
            return false;
        }

        if (assignment_desc === '') {
            setError("assignment_desc", { type: "focus", message: 'Please enter short description.' }, { shouldFocus: true });
            return false;
        }

        if (assignmentMark === '') {
            setError("assignment_mark", { type: "focus", message: 'Please enter total marks of the assignment' }, { shouldFocus: true });
            return false;
        }

        clearAssignmentError();
        setSavingAssignment(true);

        const formData = new FormData();
        if (slide_section_id !== '') {
            formData.append('section_id', slide_section_id);
        }

        formData.append('course_id', course_id);
        formData.append('title', slide_title);
        formData.append('description', assignment_desc);
        // formData.append('assignment_mark', assignmentMark);
        // formData.append('ai_grading', aigrading);
        // formData.append('grading_criteria', aigrading);

        var sURL = `${API_BASE}post-save-course-assignment${slide_id !== '' ? `/${slide_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            document.getElementById('AssignmentModalLongClose').click();
            Toaster.success(data.message);
            fetchSections(currentPage);
            setSavingAssignment(false);
        } else {
            Toaster.error(data.message);
            setSavingAssignment(false);
        }
    }

    const saveAssignmentParams = async () => {

        SwalBox.close();
        SwalBox.fire({
            title: 'Please Wait...',
            text: 'AI is generating assignment. Thank you for your patience.',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                SwalBox.showLoading();
            }
        });

        let _brief = assignment_brief
        let _submissiontype = assignment_submissiontype;

        let _prompt_string = '';
        let _prompt_def = '';
        setAssignmentBrief('');
        document.getElementById('assignment_brief').value = '';
    }

    const saveYoutubeLink = async () => {
        if (slide_title === '') {
            setError("youtube_title", { type: "focus", message: 'Please enter video title.' }, { shouldFocus: true });
            return false;
        }
        if (youtube_url === '') {
            setError("youtube_url", { type: "focus", message: 'Please enter video url.' }, { shouldFocus: true });
            return false;
        }
        setSavingYoutube(true);
        const formData = new FormData();
        if (slide_section_id !== '') {
            formData.append('section_id', slide_section_id);
        }
        formData.append('course_id', course_id);
        formData.append('title', slide_title);
        formData.append('description', youtube_url);
        var sURL = `${API_BASE}post-save-course-video${slide_id !== '' ? `/${slide_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            document.getElementById('YoutubeModalLongClose').click();
            Toaster.success(data.message);
            fetchSections(currentPage);
            setSavingYoutube(false);
            setSlideTitle('');
            setYoutubeUrl('');
        } else {
            Toaster.error(data.message);
            setSavingYoutube(false);
        }
        setYoutubeMaterial('');
    }
    const saveQuestionbank = async () => {
        setProcessing(true);
        setGenerating(false);
    }

    const generateQuestionbank = async () => {

    }

    function shuffle_videos(array) {
        let currentIndex = array.length, randomIndex;
        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }
    function getMultipleRandom(arr, num) {
        const shuffled = shuffle_videos(arr);
        return shuffled.slice(0, num);
    }

    const generatePlaylist = async () => {
        setProcessingPlayList(true);
        let _prompt_string = '';
        const response = await fetch(`${API_BASE}get-course/${course_id}?page=0`, {
            method: 'GET',
            headers: { "Content-Type": "application/json", "Accept": "application/json", "Authorization": `Bearer ${_token}` },
        });
        const data = await response.json();
        if (data.status) {
            setGeneratedVideos([]);
            try {
                setProcessingPlayList(true);
                let _course_info = data.data.course;
                let _prompt_string = '';
                _prompt_string += ' Tutorial videos related to ' + _course_info.title;
                const response_yotube = await fetch(`https://www.googleapis.com/youtube/v3/search?part=snippet&q=${_course_info.title}&key=AIzaSyBINvRclyHPDFR7bCoyLbBXCa3eXxiiYs8&maxResults=50&order=viewCount&type=video`, {
                    method: 'GET',
                    headers: { "Content-Type": "application/json", "Accept": "application/json", },
                });
                const data_youtube = await response_yotube.json();
                let youtube_vodeos = data_youtube.items;
                let _generated_vidoes = [];
                youtube_vodeos.forEach(function (yv) {

                    let _video_title = yv.snippet.title;
                    let _video_thumb = yv.snippet.thumbnails.high.url;
                    let _video_id = yv.id.videoId;
                    let _video_url = 'https://www.youtube.com/watch?v=' + _video_id;
                    _generated_vidoes.push({ 'title': _video_title, 'url': _video_url, 'thumb': _video_thumb });

                });
                setGeneratedVideos([]);
                setGeneratedVideos(getMultipleRandom(_generated_vidoes, 10));
                setProcessingPlayList(false);
            } catch (err) { }
        }
        setProcessingPlayList(false);
    }
    const saveGeneratedPlaylist = async () => {

        if (playlistvideos.length === 0) {
            SwalBox.fire({
                title: 'Please add videos in the playlist first',
                text: '',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonClass: 'button_confirm_class',
                confirmButtonText: 'Okay',
            }).then((result) => {
                if (result.isConfirmed) {
                    SwalBox.close();
                } else {

                }
            });
            return;
        }

        SwalBox.fire({
            title: "Processing...",
            html: "Please wait...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                SwalBox.showLoading();
            },
        });

        setProcessingPlayListg(true);
        let _playListSection = playlist_title;
        if (_playListSection == '') { _playListSection = 'YouTube Playlist' }
        const formData = new FormData();
        formData.append('title', _playListSection);
        formData.append('description', '');
        var sURL = `${API_BASE}post-save-course-section/${course_id}${section_id !== '' ? `/${section_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            let _playlist_section = data.data.section_id;
            try {
                if (_playlist_section != undefined) {
                    playlistvideos.forEach(item => {
                        setTimeout(() => {
                            const formData2 = new FormData();
                            formData2.append('section_id', _playlist_section);
                            formData2.append('course_id', course_id);
                            formData2.append('title', item.title);
                            formData2.append('description', item.url);
                            var sURL = `${API_BASE}post-save-course-video${slide_id !== '' ? `/${slide_id}` : ''}`;
                            const response = fetch(sURL, {
                                method: 'POST',
                                headers: {
                                    "Authorization": "Bearer " + _token
                                },
                                body: formData2
                            });
                            const data = response.json();
                            if (data.status) {

                            }
                        }, 500);
                    });
                }
            } catch (err) { }
            navigate('/dashboard/tutor/manage-course/' + course_id);
            setTimeout(() => {
                setProcessingPlayListg(false);
                document.getElementById('PlaylistModalLongClose').click();
                SwalBox.close();
                window.location.reload();
            }, playlistvideos.length * 1000);
        }
    }
    const setPlaylistVideo = async (url, title) => {
        let _existing = playlistvideos;
        _existing.push({ 'title': title, 'url': url });
        setPlaylistVideos(_existing);
        //console.info(playlistvideos);
    }



    const savePlaylist = async () => {
        setSavingPlaylist(true);
    }


    const saveCourseZip = async () => {
        if (typeof course_zip === 'undefined' || course_zip === '') {
            setZipFileError('Please upload course zip file.');
            return;
        }

        const formData = new FormData();
        formData.append('course_zip', course_zip);

        setSavingZip(true);
        var sURL = `${API_BASE}post-save-course-zip/${course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            let _rejected_files = data.data.rejected_files;
            if (_rejected_files.length > 0) {
                SwalBox.fire({
                    title: 'Unsupported File Format',
                    html: "Some of the files have not been uploaded as they are not supported by EduKula. <br>The unsupported files are:<br>" + _rejected_files.toString() + "<br>Please ensure that your file meets our supported file formats, which include: PDF, Word, PPT, Audio, Video, Image, ZIP, SCORM and TINCAN",
                    icon: 'warning',
                });
            }
            document.getElementById('ZipModalLongClose').click();
            Toaster.success(data.message);
            fetchSections(currentPage);
            removeZip();
            setSavingZip(false);
        } else {
            Toaster.error(data.message);
            setSavingZip(false);
        }
    }

    const saveCourseFolder = async () => {
        if (typeof course_folder === 'undefined' || course_folder === '') {
            setFolderError('Please upload course folder.');
            return;
        }

        const formData = new FormData();
        var cc = 0;
        for (const file of course_folder) {
            formData.append(`course_folder[${cc}][name]`, file.webkitRelativePath);
            formData.append(`course_folder[${cc}][file]`, file);
            cc++;
        }

        setSavingFolder(true);
        var sURL = `${API_BASE}post-save-course-folder/${course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            let _rejected_files = data.data.rejected_files;
            if (_rejected_files.length > 0) {
                SwalBox.fire({
                    title: 'Unsupported File Format',
                    html: "Some of the files have not been uploaded as they are not supported by EduKula. <br>The unsupported files are:<br>" + _rejected_files.toString() + "<br>Please ensure that your file meets our supported file formats, which include: PDF, Word, PPT, Audio, Video, Image, ZIP, SCORM and TINCAN",
                    icon: 'warning',
                }).then(function () {
                    window.location.reload();
                });
                document.getElementById('FolderModalLongClose').click();
            } else {
                document.getElementById('FolderModalLongClose').click();
                Toaster.success(data.message);
                setTimeout(() => {
                    //window.location.reload();
                    navigate('/dashboard/tutor/manage-course/' + course_id);
                }, 1000);
            }
            setSavingFolder(false);
        } else {
            Toaster.error(data.message);
            setSavingFolder(false);
        }
    }

    const ConfirmDeleteSection = (id, is_section, type = "Material") => {
        let message = 'Sure to Delete ' + type + ' Slide?';
        if (type === 'Assignment' || type === 'Quiz' || type === 'Section') {
            message = 'Sure to Delete ' + type + '?';
        }
        SwalBox.fire({
            title: 'Are you sure?',
            text: message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                if (is_section) {
                    deleteSection(id);
                } else {
                    deleteSlide(id);
                }
            }
        });
    }

    const deleteSection = async (id) => {
        const response = await fetch(`${API_BASE}delete-course-material-section/${id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
        });
        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            fetchSections(currentPage);
        } else {
            Toaster.error(data.message);
        }
    }

    const deleteSlide = async (id) => {
        const response = await fetch(`${API_BASE}delete-course-material-slide/${id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            fetchSections(currentPage);
        } else {
            Toaster.error(data.message);
        }
    }

    function getExtension(filename) {
        return filename.split('.').pop()
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };


    const moderateUploadedMedia = async (image) => {
        const formData = new FormData();
        var fileField = image;
        if (fileField.type == "image/png" || fileField.type == "image/jpeg") {
            const base64 = await convertBase64(fileField);
            formData.append("image", fileField);
            formData.append("base64", base64);
            formData.append("mediatype", fileField.type);
            const response = await fetch(API_BASE + 'validate-uploaded-image', {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + _token
                },
                body: formData
            });
            const data = await response.json();
            if (data.status) {
                var _is_valid = data.data;
                if (_is_valid != "") {
                    SwalBox.fire({
                        title: 'Inappropriate image',
                        text: 'Your image has been removed as it contains inappropriate content (' + _is_valid + ') , please upload a valid image.',
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ok'
                    });
                    setTimeout(function () { removePhoto(); }, 1000)
                }
            }
        }

    }

    const saveAiSection = async (section_title) => {
        //console.info(section_title);
        try {
            const formData = new FormData();
            formData.append('title', section_title);
            // formData.append('description', section_desc);
            formData.append('description', '');
            var sURL = `${API_BASE}post-save-course-section/${course_id}${section_id !== '' ? `/${section_id}` : ''}`;
            const response = fetch(sURL, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData
            });
            const data = await response.json();
            if (data.status) {
                fetchSections(0);
            }
        } catch (err) { }
    }

    const saveAiSlide = async (slide_title, aicontent, slideindex) => {
        const formData = new FormData();
        formData.append('course_id', course_id);
        formData.append('title', slide_title);
        formData.append('slideindex', slideindex);
        formData.append('generate_video', 'NO');
        formData.append('description', aicontent);

        var sURL = `${API_BASE}post-save-course-slide${slide_id !== '' ? `/${slide_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            //console.info(data);
        }
    }

    //------
    const demoTextToVideo = async () => {
        const formData = new FormData();
        formData.append('title', 'Demo Video');
        var sURL = `${API_BASE}demo-text-to-video`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            alert('Generated');
            //console.info(data);
        }
    }
    //------





    function setchAiAutofill() {

        let ai_course = Cookies.get('ai_course_structure');
        if (ai_course != null && ai_course != '' && ai_course != "null") {
            SwalBox.fire({
                title: 'Add Course Materials?',
                text: 'Would you like to add course materials generated by AI ?',
                icon: 'question',
                footer: '<span class="ek-red-col-text"><i class="fa fa-info-circle" aria-hidden="true"></i> This feature is powered by AI, and the output isn\'t always 100% accurate. Please double-check the output generated before utilizing it.</span>',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    _generateAiCourseMaterials();
                } else {
                    Cookies.set('ai_course', null);
                    Cookies.set('ai_course_info', null);
                    Cookies.set('ai_course_structure', null);
                }
            });
        }

    }

    const _generateAiCourseMaterials = async (slide_title, aicontent) => {

    }
    const { getRootProps: getRootProps3, getInputProps: getInputProps3 } = useDropzone({
        maxFiles: 1,
        onDrop: (acceptFile) => {
            setVideoSubtitleUpload(acceptFile[0]);
            setVideoSubFileName(acceptFile[0].name);
            setUpFileClass('upfile_img_vdo');
        },
    });

    // function transcriptTypeValidator(file) {
    //     if (file.type !== 'application/pdf') {
    //         setError('resume', { type: "focus", message: 'You can upload pdf file only.' });
    //         return true;
    //     }
    //     else { clearErrors('resume') }
    //     return null
    // }

    const { getRootProps: getRootProps4, getInputProps: getInputProps4 } = useDropzone({
        accept: {
            'text/vtt': ['.vtt'],
            'text/srt': ['.srt'],
        },
        maxFiles: 1,
        onDrop: (acceptFile, fileRejections) => {

            fileRejections.forEach((file) => {
                file.errors.forEach((err) => {
                    if (err.code === "file-invalid-type") {
                        setTranscriptValidationMsg("file type must be .srt or .vtt only");
                        setVideoSubtitleUpload2('');
                        setVideoSubFileName2('');
                        setUpFileClass4('');
                    }
                });
            });
            // if (fileRejections.length == 0) {
            //     setVideoSubtitleUpload2(acceptFile[0]);
            //     setVideoSubFileName2(acceptFile[0].name);
            //     setUpFileClass4('upfile_img_vdo');
            //     setTranscriptValidationMsg('');
            // }
        },
        onDropAccepted: (acceptFile) => {
            setVideoSubtitleUpload2(acceptFile[0]);
            setVideoSubFileName2(acceptFile[0].name);
            setUpFileClass4('upfile_img_vdo');
            setTranscriptValidationMsg('');
        }
    });


    const handleDeleteSelectedRows = () => {
        SwalBox.fire({
            title: 'Are you sure ?',
            text: 'Are you sure to delete selected material ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteMultipleSlidesById(getSelectedRows.join(','));
            } else {
                SwalBox.close();
            }
        });
    }


    const convertMultipleSlidesById = async (total, ids, transcriptEnable) => {
        var total_seconds = 130 * total;
        document.getElementById('ProgressModalLongOpen').click();
        updateProgressBar(total_seconds, 'multi_video');
        /*
        SwalBox.fire({
            title: 'Please Wait...',
            text:'Your slides are being converted into video. Thank you for your patience.',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                SwalBox.showLoading();
            }
        });
        */

        const formData = new FormData();
        formData.append('slide_ids', ids);
        formData.append('transcript', transcriptEnable);
        const response = await fetch(`${API_BASE}convert-course-material-slides`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            clearprogressinterval();
            setprogresstozero();
            document.getElementById('ProgressModalLongOpen').click();
            fetchSections(currentPage);
            SwalBox.close();
        } else {
            clearprogressinterval();
            setprogresstozero();
            document.getElementById('ProgressModalLongOpen').click();
            SwalBox.close();
        }
    }

    const deleteMultipleSlidesById = async (ids) => {
        SwalBox.close();
        SwalBox.fire({
            title: 'Please Wait...',
            text: 'Your slides are being deleted. Thank you for your patience.',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                SwalBox.showLoading();
            }
        });
        const formData = new FormData();
        formData.append('slide_ids', ids);
        const response = await fetch(`${API_BASE}delete-course-material-slides`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            fetchSections(currentPage);
            SwalBox.close();
        } else {
            SwalBox.close();
        }
    }

    const handleGenerateVideoselectedRows = (transcriptEnable) => {
        SwalBox.fire({
            allowOutsideClick: false,
            html: "Your text content will be converted into video shortly, depending on its length & number of slides. Thank you for your patience.",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#30314c',
            cancelButtonColor: '#30314c',
            confirmButtonText: 'Yes, please.',
            cancelButtonText: 'No, thank you.'
        }).then((result) => {
            if (result.isConfirmed) {
                convertMultipleSlidesById(getSelectedRows.length, getSelectedRows.join(','), transcriptEnable);
                // processConvertToVideo();
                // console.log(getSelectedRows);
            } else {
                SwalBox.close();
            }
        });
    }

    const toggleSelectAll = () => {
        if (selectAll) {
            setGetSelectedRows([]);
            setSelectedRows([]);
        } else {
            let allRowIds = [];
            if (outer_slides !== '') {
                allRowIds = outer_slides.map((row) => row.uid);
            }
            if (typeof sections !== 'undefined' && sections !== '' && sections.length > 0) {
                sections.forEach((section) => {
                    section.slides.forEach((slide) => {
                        allRowIds.push(slide.uid)
                    })
                })
            }

            setGetSelectedRows(allRowIds);
            setSelectedRows(allRowIds);
        }
        setSelectAll(!selectAll);
    };

    const clearGeneratedPlaylist = () => {
        setGeneratedVideos([]);
        setPlaylistTitle('');
    }

    return (
        <>
            {
                reordering
                &&
                <ReorderMaterials course_id={course_id} fetchSectionsParent={fetchSections} setReordering={setReordering} />
            }

            {
                isQuizDisplay
                &&
                <div className="materials_section mb-5">
                    <div className="custom-learnig-circle-head mb-4">
                        <div className="top_list meetingdata add_quiz">
                            <PopQuizAddEdit course_id={course_id} slide_id={slide_id} slide_title={slide_title} slide_desc={assignment_desc} sections={sections} fetchSections={fetchSections} quiz_list={quiz_list} setQuiz={setQuiz} currentPage={currentPage} />
                            <div className="clearfix"></div>
                            {/*<AddQuizBankQue setQuiz={setQuiz} />*/}
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            }

            {
                !reordering && !isQuizDisplay
                &&
                <div className="materials_section mb-5">
                    <div className="custom-learnig-circle-head mb-4">
                        <div className="top_list">
                            <h4>{t('_managelc_materials_')}</h4>
                            {/*<a className="btn ml-2 mb-2" id="MaterialsMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Add</a>*/}
                            <a className="btn ml-2 mb-2" data-toggle="modal" data-target="#MaterialsTypeModalLong">{t('_emplist_add_')}</a>
                            {
                                (outer_slides !== '' || sections !== '')
                                &&
                                <a className="btn" onClick={(e) => { setReordering(true); }}>{t('_createcourse_step3_m1_')} </a>
                            }

                            <div className="dropdown-menu dropdown-menu-right ek_matitems" aria-labelledby="MaterialsMenuButton">
                                <a className="d-none" data-toggle="modal" data-target="#MaterialViewModal" id="MaterialViewModalclick">{t('_createcourse_step3_m2_')}</a>
                            </div>


                            <div className="modal fade" id="SectionModalLong" tabIndex="-1" role="dialog" aria-labelledby="SectionModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="SectionModalLongTitle">{section_id === '' ? t('_emplist_add_') : t('_edit_')} {t('_learncourse_slidelist_m1_')}</h4>
                                            <button type="button" id="SectionModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <label>{t('_learncourse_slidelist_m1_')}</label>
                                                <input id="section_title" {...register("section_title", {
                                                    maxLength: {
                                                        value: 80,
                                                        message: t('_lcmeeting_addmeet_title_vali_m1_')
                                                    }
                                                })} onChange={(e) => { setSectionTitle(e.target.value); }} type="text" className="form-control" value={section_title} placeholder={t('_createcourse_step3_m3_')} autoFocus />
                                                <span className="add-on max-chr-counter chrchk-section_title" data-ele="section_title" data-limit="80" data-showcounter="true"></span>
                                                <span className="text-danger">
                                                    {errors.section_title && errors.section_title.type === "required" && t('_createcourse_step3_m4_')}
                                                    {errors.section_title && errors.section_title.type !== "required" && errors.section_title.message}
                                                </span>
                                            </div>
                                            {/* <div className="form-group">
                                                <label>Short Description</label>
                                                <textarea {...register("section_desc", {
                                                    maxLength: {
                                                        value: 200,
                                                        message: "Short Description can not be more than 200 charaters."
                                                    }
                                                })} onChange={(e) => { setSectionDesc(e.target.value); }} rows="10" cols="10" placeholder="Description" value={section_desc}></textarea>
                                                <span className="text-danger">
                                                    {errors.section_desc && errors.section_desc.type === "required" && 'Please enter short description.'}
                                                    {errors.section_desc && errors.section_desc.type !== "required" && errors.section_desc.message}
                                                </span>
                                            </div> */}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">{t('_hr_close_')}</button>
                                            <button onClick={(e) => { saveSection(); }} type="button" className="btn btn-circle btn-md submit-pink-btn" disabled={savingSection}>{savingSection ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>{t('_createcourse_step3_m5_')}</>}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="SlideModalLong" tabIndex="-1" role="dialog" aria-labelledby="SlideModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered modal-ek-lg-1200" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="SlideModalLongTitle">{slide_id === '' ? t('_emplist_add_') : t('_edit_')} {t('_createcourse_step3_m6_')}</h4>
                                            <button type="button" id="SlideModalLongClose" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setIsAIvideo(false) }}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {
                                                (slide_id === '' && sections !== '')
                                                &&
                                                <div className="form-group">
                                                    <label>{t('_createcourse_step3_m7_')} </label>
                                                    <select {...register("slide_section1")}>
                                                        <option value="">{t('_createcourse_step3_m7_')} </option>
                                                        {
                                                            sections !== ''
                                                            &&
                                                            sections.map((section, index) =>
                                                                <option key={index} value={section.id}>{section.title}</option>
                                                            )
                                                        }
                                                    </select>
                                                    <span className="text-danger">
                                                        {errors.slide_section1 && errors.slide_section1.type !== "required" && errors.slide_section1.message}
                                                    </span>
                                                </div>
                                            }
                                            <div className="form-group">
                                                <label>{t('_createcourse_step3_m8_')} </label>
                                                <input id="slide_title" {...register("slide_title", {
                                                    maxLength: {
                                                        value: 80,
                                                        message: t('_lcmeeting_addmeet_title_vali_m1_')
                                                    }
                                                })} onChange={(e) => { setSlideTitle(e.target.value); }} value={slide_title} type="text" className="form-control" placeholder={t('_createcourse_step3_m3_')} autoFocus />
                                                <span className="add-on max-chr-counter chrchk-slide_title" data-ele="slide_title" data-limit="80" data-showcounter="true"></span>
                                                <span className="text-danger">
                                                    {errors.slide_title && errors.slide_title.type === "required" && t('_createcourse_step3_m9_')}
                                                    {errors.slide_title && errors.slide_title.type !== "required" && errors.slide_title.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <label>{t('_createcourse_step3_m10_')}</label>
                                                <Controller
                                                    control={control}
                                                    name="slide_desc"
                                                    rules={{ required: true, message: "asfsf" }}
                                                    render={({ field: { onChange, value, name, ref } }) => (
                                                        <TinyMCEEditor onChange={(e) => { setSlideDesc(e) }} value={slide_desc} placeholder={t('_createcourse_step3_m11_')} />
                                                    )}
                                                />
                                                <span className="text-danger">
                                                    {errors.slide_desc && errors.slide_desc.type === "required" && t('_createcourse_step3_m12_')}
                                                    {errors.slide_desc && errors.slide_desc.type !== "required" && errors.slide_desc.message}
                                                </span>
                                                <p className="ek-sl-mat-warning">- {t('_createcourse_step3_m13_')} <br></br>
                                                    - {t('_createcourse_step3_m14_')}</p>
                                            </div>
                                            <div className="">
                                                <div className="d-flex w-100 float-left" style={{ marginTop: '-12px', gap: '20px' }}>
                                                    <div >
                                                        <input type="radio" className="radio-quiz" name="is_correct" value={'video'}
                                                            {...
                                                            register("conversion_type", {
                                                                required: "required",
                                                            })}
                                                            onChange={(e) => { setVidConType(e.target.value) }}
                                                            checked={vidConType === 'video'}
                                                        />
                                                        <label><strong>{t('_createcourse_step3_m15_')}</strong></label>
                                                    </div>

                                                    <div >
                                                        <input type="radio" className="radio-quiz" name="is_correct" value={'video-transcript'}
                                                            {...register("conversion_type", {
                                                                required: "required",
                                                            })}
                                                            onChange={(e) => { setVidConType(e.target.value) }}
                                                            checked={vidConType === 'video-transcript'}

                                                        />
                                                        <label><strong>{t('_createcourse_step3_m16_')} </strong></label>
                                                    </div>

                                                    <div >
                                                        <input type="radio" className="radio-quiz" name="is_correct" value={'none'}
                                                            {...register("conversion_type", {
                                                                required: "required",
                                                            })}
                                                            onChange={(e) => { setVidConType(e.target.value) }}
                                                            checked={vidConType === 'none'}

                                                        />
                                                        <label><strong>{t('_mate_add_slide_video_opt_not_')}</strong></label>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal"
                                                onClick={() => { setIsAIvideo(false) }}
                                            >{t('_hr_close_')}</button>
                                            <button type="button" onClick={(e) => { saveSlide(); }} className="btn btn-circle btn-md submit-pink-btn" disabled={savingSlide}> {savingSlide ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>{t('_createcourse_step3_m17_')}</>}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="UploadModalLong" tabIndex="-1" role="dialog" aria-labelledby="UploadModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className={`modal-dialog modal-dialog-centered ${videoViewEdit === true ? 'modal-ek-lg-1200' : ''}`} role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="UploadModalLongTitle">{slide_id === '' ? t('_emplist_add_') : t('_edit_')} {t('_createcourse_step3_m18_')}</h4>
                                            <button type="button" id="UploadModalLongClose" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                                                setVideoViewEdit(false); setViewSlide(''); setShowtranscriptMenu(false); setVidConType3('without_transcript'); setTranscriptValidationMsg(''); setVideoSubtitleUpload2('');
                                                setVideoSubFileName2(''); setUpFileClass4('');
                                            }}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className={`${videoViewEdit === true ? 'd-flex flex-row' : ''}`}>
                                                {
                                                    (videoViewEdit === true && view_slide !== '')
                                                    &&
                                                    <div className={`${videoViewEdit === true ? 'w-50 pr-3' : ''}`}>
                                                        <div className="" >
                                                            <video
                                                                className=""
                                                                crossOrigin="anonymous"
                                                                preload="metadata"
                                                                controls
                                                                style={{ width: '100%', height: '100%' }}
                                                            >
                                                                <source src={view_slide.material.material} type="video/mp4" />
                                                                {view_slide.material.material.includes('.mp4') && (
                                                                    <track
                                                                        src={view_slide.material.material.replace('.mp4', '.vtt')}
                                                                        kind="subtitles"
                                                                        srcLang="en"
                                                                        label="English"
                                                                        default
                                                                    />
                                                                )}
                                                                {t('_managelc_video_notsupported_')}
                                                            </video>
                                                        </div>
                                                    </div>
                                                }

                                                <div className={`${videoViewEdit === true ? 'w-50' : ''}`}>

                                                    {
                                                        slide_id === ''
                                                        &&
                                                        <div className="form-group">
                                                            <label>{t('_createcourse_step3_m7_')} </label>
                                                            <select {...register("slide_section2")}>
                                                                <option value="">{t('_createcourse_step3_m7_')}  </option>
                                                                {
                                                                    sections !== ''
                                                                    &&
                                                                    sections.map((section, index) =>
                                                                        <option key={index} value={section.id}>{section.title}</option>
                                                                    )
                                                                }
                                                            </select>
                                                            <span className="text-danger">
                                                                {errors.slide_section2 && errors.slide_section2.type === "required" && t('_courselist_cour_learner_level_vali_m1__')}
                                                                {errors.slide_section2 && errors.slide_section2.type !== "required" && errors.slide_section2.message}
                                                            </span>
                                                        </div>
                                                    }


                                                    <div className="form-group m-0">
                                                        <label>{t('_createcourse_step3_m19_')} </label>
                                                        <input id="material_title" {...register("material_title", {
                                                            maxLength: {
                                                                value: 80,
                                                                message: t('_lcmeeting_addmeet_title_vali_m1_')
                                                            }
                                                        })} onChange={(e) => { setSlideTitle(e.target.value); }} value={slide_title} type="text" className="form-control" placeholder={t('_createcourse_step3_m3_')} autoFocus />
                                                        <span className="add-on max-chr-counter chrchk-material_title" data-ele="material_title" data-limit="80" data-showcounter="true"></span>
                                                        <span className="text-danger">
                                                            {errors.material_title && errors.material_title.type === "required" && t('_createcourse_step3_m20_')}
                                                            {errors.material_title && errors.material_title.type !== "required" && errors.material_title.message}
                                                        </span>
                                                    </div>

                                                    <div className="form-group m-0">
                                                        <label>{t('_emplist_upload_')}</label>
                                                    </div>
                                                    {
                                                        material_file !== ''
                                                        &&
                                                        <>
                                                            <div className="form-group">

                                                                <div className="file-choosen mt-4">
                                                                    <div onClick={(e) => { removePhoto(); }} className="btnGrp">
                                                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                                                    </div>
                                                                    <h5>{t('_createcourse_step3_m21_')}: {file_name}</h5>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    {
                                                        material_file === ''
                                                        &&
                                                        <>
                                                            <div className="form-group">
                                                                <div {...getRootProps()} className={upFileClass}>
                                                                    <img src={slide_id === '' ? DEFAULT_UPLOAD_FILE_IMG : DEFAULT_UPLOAD_FILE_IMG_NEW} className="" alt='preview' height={'105px'} width={'75%'} />
                                                                    <input {...getInputProps()} />
                                                                </div>
                                                                <strong>{(slide_desc && slide_desc !== '') && 'Current file : '}{parse((slide_desc && slide_desc !== '') ? slide_desc : '')}</strong>
                                                                <p className="small_txt">{t('_createcourse_step3_m22_')}</p>
                                                            </div>
                                                        </>
                                                    }

                                                    {/* {(slide_id !== '' && videoViewEdit === true) && */}
                                                    {((showtranscriptMenu === true && material_file !== '') || (videoViewEdit === true && view_slide !== '')) &&
                                                        <>
                                                            <div className="">
                                                                <div className="d-flex w-100 float-left" style={{ marginTop: '-12px', gap: '20px' }}>
                                                                    <div >
                                                                        <input type="radio" className="radio-quiz" name="is_correct" value={'without_transcript'}
                                                                            onChange={(e) => { setVidConType3(e.target.value) }}
                                                                            checked={vidConType3 === 'without_transcript'}
                                                                        />
                                                                        <label><strong>{t('_mate_video_upload_without_transcript_')}</strong></label>
                                                                    </div>
                                                                    <div >
                                                                        <input type="radio" className="radio-quiz" name="is_correct" value={'with_transcript'}
                                                                            onChange={(e) => { setVidConType3(e.target.value) }}
                                                                            checked={vidConType3 === 'with_transcript'}
                                                                        />
                                                                        <label><strong>{t('_createcourse_step3_m24_')} </strong></label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                {vidConType3 === 'with_transcript' &&
                                                                    <>
                                                                        <div {...getRootProps4()} className={upFileClass4}>
                                                                            <img src={DEFAULT_UPLOAD_FILE_IMG} className="" alt='preview' width={'75%'} height={'105px'} />
                                                                            <input {...getInputProps4()} />
                                                                        </div>
                                                                        <strong>{(vidSubFileName2 && vidSubFileName2 !== '') && `${t('_createcourse_step3_m25_')} : `}{parse((vidSubFileName2 && vidSubFileName2 !== '') ? vidSubFileName2 : '')}</strong>
                                                                        <div className="form-group mb-0">
                                                                            <p className="small_txt">You can upload .srt or .vtt format transcript file.</p>
                                                                        </div>
                                                                        <p className="text-danger mb-0">{transcriptValidationMsg !== '' && transcriptValidationMsg}</p>
                                                                        <p className="text-danger mb-0">{errors.transcript_file && errors.transcript_file.message}</p>
                                                                    </>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                    <span className="text-danger">
                                                        {errors.material_file && errors.material_file.type === "required" && t('_createcourse_step3_m27_')}
                                                        {errors.material_file && errors.material_file.type !== "required" && errors.material_file.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal"
                                                onClick={() => {
                                                    setVideoViewEdit(false); setViewSlide(''); setShowtranscriptMenu(false); setVidConType3('without_transcript'); setTranscriptValidationMsg(''); setVideoSubtitleUpload2(''); setVideoSubFileName2(''); setUpFileClass4('');
                                                }}>{t('_hr_close_')} </button>
                                            <button onClick={(e) => { saveMaterial(); }} disabled={savingMaterial} type="button" className="btn btn-circle btn-md submit-pink-btn"> {savingMaterial ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>{t('_createcourse_step3_m28_')}</>}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="AssignmentModalLong" tabIndex="-1" role="dialog" aria-labelledby="AssignmentModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered modal-ek-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="AssignmentModalLongTitle">{slide_id !== '' ? t('_createcourse_step3_m29_') : t('_createcourse_step3_m30_')}</h4>
                                            <button type="button" id="AssignmentModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {
                                                (slide_id === '' && sections !== '')
                                                &&
                                                <div className="form-group">
                                                    <label>{t('_createcourse_step3_m7_')} </label>
                                                    <select {...register("slide_section")} onChange={(e) => { setSlideSectionId(e.target.value); }} value={slide_section_id !== '' ? slide_section_id : ''}>
                                                        <option value="">{t('_createcourse_step3_m7_')} </option>
                                                        {
                                                            sections !== ''
                                                            &&
                                                            sections.map((section, index) =>
                                                                <option key={index} value={section.id}>{section.title}</option>
                                                            )
                                                        }
                                                    </select>
                                                    <span className="text-danger">
                                                        {errors.slide_section && errors.slide_section.type === "required" && t('_courselist_cour_learner_level_vali_m1_')}
                                                        {errors.slide_section && errors.slide_section.type !== "required" && errors.slide_section.message}
                                                    </span>
                                                </div>
                                            }

                                            <div className="form-group m-0">
                                                <label>{t('_createcourse_step3_m31_')} </label>
                                                <input id="assignment_title" {...register("assignment_title", {
                                                    maxLength: {
                                                        value: 80,
                                                        message: t('_lcmeeting_addmeet_title_vali_m1_')
                                                    }
                                                })} onChange={(e) => { setSlideTitle(e.target.value); }} value={slide_title} type="text" className="form-control" placeholder={t('_createcourse_step3_m3_')} autoFocus />
                                                <span className="add-on max-chr-counter chrchk-assignment_title" data-ele="assignment_title" data-limit="80" data-showcounter="true"></span>
                                                <span className="text-danger">
                                                    {errors.assignment_title && errors.assignment_title.type === "required" && t('_createcourse_step3_m32_')}
                                                    {errors.assignment_title && errors.assignment_title.type !== "required" && errors.assignment_title.message}
                                                </span>
                                            </div>

                                            <div className="form-group m-0 mb-2">
                                                <label>{t('_createcourse_step3_m33_')} </label>
                                                <input id="assignment_mark" {...register("assignment_mark", {
                                                    required: "required",
                                                })} onChange={(e) => { setAssignmentMark(e.target.value); }} value={assignmentMark} type="number" className="form-control" placeholder={t('_createcourse_step3_m34_')} autoFocus />
                                                <span className="text-danger">
                                                    {errors.assignment_mark && errors.assignment_mark.type === "required" && t('_createcourse_step3_m35_')}
                                                </span>
                                            </div>

                                            <div className="">
                                                <label className="aigrade">{t('_createcourse_step3_m36_')}</label>

                                                <div className="d-flex" style={{ gap: '20px' }}>
                                                    <div >
                                                        <input type="radio" className="radio-quiz" name="is_correct" value={'yes'}
                                                            {...
                                                            register("ai_grading", {
                                                                required: "required",
                                                            })}
                                                            onChange={(e) => { setAIgrading(e.target.value) }}
                                                            checked={aigrading === 'yes'}
                                                        />
                                                        <label>{t('_lp_yes_')}</label>
                                                    </div>
                                                    <div >
                                                        <input type="radio" className="radio-quiz" name="is_correct" value={'no'}
                                                            {...register("ai_grading", {
                                                                required: "required",
                                                            })}
                                                            onChange={(e) => { setAIgrading(e.target.value) }}
                                                            checked={aigrading === 'no'}
                                                        />
                                                        <label>{t('_lp_no_')}</label>
                                                    </div>
                                                </div>

                                                {/* <input id="ai_grading" {...register("ai_grading", {
                                                    required: "required",
                                                })} onChange={(e) => { setAssignmentMark(e.target.value); }} value={assignmentMark} type="number" className="form-control" placeholder="Enter Total marks of the assignment" autoFocus /> */}
                                                {/*
                                                <span className="add-on max-chr-counter chrchk-assignment_title" data-ele="assignment_mark" data-limit="80" data-showcounter="true"></span> */}
                                                <span className="text-danger">
                                                    {errors.ai_grading && errors.ai_grading.type === "required" && t('_createcourse_step3_m37_')}
                                                    {/* {errors.assignment_title && errors.assignment_title.type !== "required" && errors.assignment_title.message} */}
                                                </span>

                                            </div>
                                            {aigrading === 'yes' &&
                                                <div className="form-group m-0">
                                                    <label className="m-0">{t('_createcourse_step3_m38_')} </label>
                                                    <textarea className="text-quiz-textarea m-0" placeholder={t('_createcourse_step3_m39_')} onChange={(e) => { setGradingCriteria(e.target.value); }} value={gradingCriteria}
                                                    // {...register("grading_criteria")}
                                                    />
                                                </div>
                                            }



                                            <div className="form-group">
                                                <label>{t('_e_description_')} </label>
                                                <Controller
                                                    control={control}
                                                    name="assignment_desc"
                                                    render={({ field: { onChange, value, name, ref } }) => (
                                                        <TinyMCEEditor onChange={setAssignmentDesc} value={assignment_desc} placeholder={t('_createcourse_step3_m40_')} />
                                                    )}
                                                />
                                                <span className="text-danger">
                                                    {errors.assignment_desc && errors.assignment_desc.type === "required" && t('_createcourse_step3_m41_')}
                                                    {errors.assignment_desc && errors.assignment_desc.type !== "required" && errors.assignment_desc.message}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">{t('_hr_close_')} </button>
                                            <button type="button" onClick={(e) => { saveAssignment(); }} className="btn btn-circle btn-md submit-pink-btn" disabled={savingAssignment}> {savingAssignment ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>{t('_createcourse_step3_m42_')}</>} </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <a className="hide" id="AssignmentAiModalLongOpen" data-toggle="modal" data-target="#AssignmentAiModalLong">&nbsp;</a>
                            <div className="modal fade" id="AssignmentAiModalLong" tabIndex="-1" role="dialog" aria-labelledby="AssignmentAiModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered modal-ek-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="AssignmentAiModalLongTitle">{t('_createcourse_step3_m43_')}</h4>
                                            <button type="button" id="AssignmentAiModalLongClose" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <label>{t('_createcourse_step3_m44_')} </label>
                                                <textarea onChange={(e) => { setAssignmentBrief(e.target.value); }} rows="4" id="assignment_brief" className="form-control" placeholder={t('_createcourse_step3_m45_')}></textarea>
                                            </div>
                                            <div className="form-group">
                                                <label>{t('_createcourse_step3_m46_')}</label>
                                                <select onChange={(e) => { setAssignmentSubmissionType(e.target.value); }}>
                                                    <option value="Document">{t('_createcourse_step3_m47_')} </option>
                                                    <option value="Presentation">{t('_createcourse_step3_m48_')} </option>
                                                    <option value="Video">{t('_createcourse_step3_m49_')} </option>
                                                    <option value="Audio">{t('_createcourse_step3_m50_')} </option>
                                                    <option value="Image">{t('_createcourse_step3_m51_')} </option>
                                                    <option value="Spreadsheet">{t('_createcourse_step3_m52_')}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">{t('_cancel_')}</button>
                                            <button type="button" onClick={(e) => { saveAssignmentParams(); }} className="btn btn-circle btn-md submit-pink-btn"> {t('_courselist_cour_apply_')} </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="YoutubeModalLong" tabIndex="-1" role="dialog" aria-labelledby="YoutubeModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            {
                                                slide_title !== ''
                                                    ?
                                                    <h4 id="YoutubeModalLongTitle">{t('_createcourse_step3_m53_')} </h4>
                                                    :
                                                    <h4 id="YoutubeModalLongTitle">{t('_createcourse_step3_m54_')} </h4>
                                            }
                                            <button type="button" id="YoutubeModalLongClose" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setSlideTitle(''); setYoutubeUrl(''); setYoutubeMaterial(''); }}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {
                                                (slide_id === '' && sections !== '')
                                                &&
                                                <div className="form-group">
                                                    <label>{t('_createcourse_step3_m7_')} </label>
                                                    <select {...register("slide_section")} onChange={(e) => { setSlideSectionId(e.target.value); }} value={slide_section_id !== '' ? slide_section_id : ''}>
                                                        <option value="">{t('_createcourse_step3_m7_')} </option>
                                                        {
                                                            sections !== ''
                                                            &&
                                                            sections.map((section, index) =>
                                                                <option key={index} value={section.id}>{section.title}</option>
                                                            )
                                                        }
                                                    </select>
                                                    <span className="text-danger">
                                                        {errors.slide_section && errors.slide_section.type === "required" && t('_courselist_cour_learner_level_vali_m1_')}
                                                        {errors.slide_section && errors.slide_section.type !== "required" && errors.slide_section.message}
                                                    </span>
                                                </div>
                                            }

                                            <div className="form-group">
                                                <label>{t('_mate_p2_m1_')} </label>
                                                <input id="youtube_title" {...register("youtube_title", {
                                                    maxLength: {
                                                        value: 80,
                                                        message: t('_lcmeeting_addmeet_title_vali_m1_')
                                                    }
                                                })} onChange={(e) => { setSlideTitle(e.target.value); }} value={slide_title} type="text" className="form-control" placeholder={t('_mate_part2_m2_')} />
                                                <span className="add-on max-chr-counter chrchk-youtube_title" data-ele="youtube_title" data-limit="80" data-showcounter="true"></span>
                                                <span className="text-danger">
                                                    {errors.youtube_title && errors.youtube_title.type === "required" && t('_mate_part2_m3_')}
                                                    {errors.youtube_title && errors.youtube_title.type !== "required" && errors.youtube_title.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <label>{t('_mate_part2_m4_')} </label>
                                                <input type="text" {...register("youtube_url")} onChange={(e) => { setYoutubeUrl(e.target.value); }} value={youtube_url} className="form-control" placeholder={t('_mate_part2_m5_')} />
                                                <span className="text-danger">
                                                    {errors.youtube_url && errors.youtube_url.type === "required" && t('_mate_part2_m6_')}
                                                    {errors.youtube_url && errors.youtube_url.type !== "required" && errors.youtube_url.message}
                                                </span>
                                            </div>
                                            {
                                                youtubeMaterial !== "" &&
                                                <div className="w-5/6">
                                                    <div className="" >
                                                        <div className="iframe_box">
                                                            <iframe width="580" height="315" src={`https://www.youtube.com/embed/${youtubeMaterial}?start=4`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal" onClick={() => { setSlideTitle(''); setYoutubeUrl(''); setYoutubeMaterial(''); }}>{t('_tour_close_')} </button>
                                            <button type="button" onClick={(e) => { saveYoutubeLink(); }} className="btn btn-circle btn-md submit-pink-btn" disabled={savingYoutube}>{savingYoutube ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>{t('_mate_part2_m7_')}</>} </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="ZipModalLong" tabIndex="-1" role="dialog" aria-labelledby="ZipModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="ZipModalLongTitle">{t('_mate_part2_m8_')} </h4>
                                            <button type="button" id="ZipModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <label>{t('_mate_part2_m9_')} </label>
                                            {
                                                course_zip !== ''
                                                &&
                                                <div className="file-choosen folderbox">
                                                    <div onClick={(e) => { removeZip(); }} className="btnGrp">
                                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                                    </div>
                                                    <h5>{t('_createcourse_step3_m21_')} : {zip_name}</h5>
                                                </div>
                                            }

                                            {
                                                course_zip === ''
                                                &&
                                                <>
                                                    <div {...getRootProps2()} className={upFileClass}>
                                                        <img src={DEFAULT_UPLOAD_FILE_IMG} className="" alt='preview' />
                                                        <input {...getInputProps2()} />
                                                    </div>
                                                    <p className="small_txt">{t('_mate_part2_m10_')}</p>
                                                </>
                                            }

                                            {zipFileError ? <span className="text-danger">{zipFileError}</span> : null}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">{t('_tour_close_')}</button>
                                            <button type="button" onClick={(e) => { saveCourseZip(); }} className="btn btn-circle btn-md submit-pink-btn" disabled={savingZip}> {savingZip ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>{t('_mate_part2_m11_')}</>} </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="FolderModalLong" tabIndex="-1" role="dialog" aria-labelledby="FolderModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="FolderModalLongTitle">{t('_mate_part2_m12_')} </h4>
                                            <button type="button" id="FolderModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <label><strong>{t('_mate_part2_m13_')} </strong> &nbsp;</label>
                                            {
                                                course_folder !== ''
                                                    ?
                                                    <div className="file-choosen folderbox">
                                                        <div onClick={(e) => { removeFolder(); }} className="btnGrp">
                                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                                        </div>
                                                        <h5>{t('_mate_part2_m14_')} {folder_name}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                course_folder === '' ? <input type={'file'} ref={ref} onChange={handleFolderUpload} /> : null
                                            }

                                            {folderError ? <span className="d-block text-danger">{folderError}</span> : null}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">{t('_tour_close_')}</button>
                                            <button type="button" onClick={(e) => { saveCourseFolder(); }} className="btn btn-circle btn-md submit-pink-btn" disabled={savingFolder}> {savingFolder ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>{t('_mate_part2_m15_')}</>} </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="MaterialViewModal" tabIndex="-1" role="dialog" aria-labelledby="MaterialViewModalTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered pdf_height modal-ek-lg-1200" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="MaterialViewModalTitle">{view_slide !== '' && view_slide.material.material_type} {t('_createcourse_step3_m18_')}</h4>
                                            <button onClick={(e) => { setViewSlide(''); }} type="button" id="MaterialViewModalClose" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {
                                                view_slide !== ''
                                                &&
                                                <>
                                                    {
                                                        ((view_slide.material.material_type === 'Text' || view_slide.material.material_type === 'Assignment' || view_slide.material.material_type === 'Quiz') && view_slide.material.material !== '')
                                                        &&
                                                        <>
                                                            <span className="ek-modal-content-text-material">

                                                                {parse(view_slide?.material?.material ? view_slide?.material?.material : '')}
                                                            </span>
                                                            {
                                                                view_slide.material.material_type === 'Quiz'
                                                                &&
                                                                <>
                                                                    {
                                                                        (view_slide.material.questions !== '' && view_slide.material.questions !== null)
                                                                        &&
                                                                        <>
                                                                            <ul>
                                                                                {
                                                                                    view_slide.material.questions.map((question, index) =>
                                                                                        <li key={index} className="mt-3">
                                                                                            <strong>{t('_mate_popquiz_m7_')} {index + 1}: {question.question}</strong>
                                                                                            <br />
                                                                                            <ul className="pl-3">
                                                                                                {
                                                                                                    question.answers.map((answer, indexx) =>
                                                                                                        <li key={indexx} className="mt-2">
                                                                                                            {t('_managelc_postpoll_question_opt_')} {indexx + 1}: {answer.answer}({answer.is_correct})
                                                                                                            <br />
                                                                                                            {t('_e_description_')} : {answer.description}
                                                                                                        </li>
                                                                                                    )
                                                                                                }
                                                                                            </ul>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            </ul>
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }

                                                    {
                                                        (view_slide.material.material_type === 'Photo' && view_slide.material.material !== '')
                                                        &&
                                                        <img className="w-100" src={view_slide.material.material} alt={view_slide.material.title} />
                                                    }

                                                    {
                                                        (view_slide.material.material_type === 'Video' && view_slide.material.material !== '')
                                                        &&
                                                        <video className="w-100" controls>
                                                            <source src={view_slide.material.material} type="video/mp4" />
                                                            {t('_managelc_video_notsupported_')}
                                                        </video>
                                                    }
                                                    {
                                                        view_slide.material.material_type === 'Document'
                                                            ?
                                                            <>
                                                                {
                                                                    ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].indexOf(getExtension(view_slide.material.material).toLowerCase()) !== -1
                                                                        ?
                                                                        getExtension(view_slide.material.material).toLowerCase() === 'pdf'
                                                                            ?
                                                                            <iframe className="doc_iframe" id="pdf-js-viewer" src={`${API_BASE.replace('public/api/', '')}pdfjs/web/viewer.html?file=${view_slide.material.material}`} title="webviewer" frameBorder="0" width="500" height="600"></iframe>
                                                                            :
                                                                            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${view_slide.material.material}`} style={{ width: 100 + '%' }} frameBorder="0"></iframe>
                                                                        :
                                                                        <p>{t('_courcerti_m11_')}: <a className="btn btn-circle btn-success coman_btn m-0" href={view_slide.material.material} target="_blank" download>{view_slide.material.title} <i className="fas fa-download"></i></a></p>
                                                                }
                                                            </>
                                                            :
                                                            ""
                                                    }

                                                    {
                                                        view_slide.material.material_type === 'Youtube'
                                                            ?
                                                            <>
                                                                {
                                                                    <iframe src={`https://www.youtube.com/embed/${view_slide.material.material}?start=4`} style={{ width: 100 + '%' }} frameBorder="0"></iframe>
                                                                }
                                                            </>
                                                            :
                                                            ""
                                                    }

                                                    {
                                                        (view_slide.material.material_type === 'TinCan')
                                                        &&
                                                        <iframe frameBorder="0" marginHeight="0" marginWidth="0" width="100%" height="500" scrolling="auto" src={view_slide.material.material}>
                                                            {t('_mate_part2_m16_')}
                                                        </iframe>
                                                    }

                                                    {
                                                        (view_slide.material.material_type === 'Scorm')
                                                        &&
                                                        <iframe frameBorder="0" marginHeight="0" marginWidth="0" width="100%" height="500" scrolling="auto" src={view_slide.material.material}>
                                                            {t('_mate_part2_m16_')}
                                                        </iframe>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal fade" id="MaterialsTypeModalLong" tabIndex="-1" role="dialog" aria-labelledby="MaterialsTypeModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered modal-ek-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header"><h4 id="MaterialsTypeModalLongTitle">{t('_mate_part2_m17_')} </h4>
                                            <button type="button" id="MaterialsTypeModalLongClose" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="blog-card">
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-6 mb-6">
                                                        <a onClick={(e) => { setPopSection(); }} data-toggle="modal" data-target="#SectionModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-list-check" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>{t('_mate_add_model_m1_')} </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 mb-6">
                                                        <a onClick={(e) => { setPopSlideFile(); }} data-toggle="modal" data-target="#SlideModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-spell-check" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>{t('_mate_add_model_m2_')} </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-lg-4 mb-4">
                                                        <a onClick={(e) => { setPopSlideFile(); }} data-toggle="modal" data-target="#UploadModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-file-arrow-up" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>{t('_mate_add_model_m3_')}</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 mb-4">
                                                        <a onClick={() => { setZipFileError(''); removeZip(); }} data-toggle="modal" data-target="#ZipModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-file-zipper" style={{ 'fontSize': '20px', 'padding': '5px' }}></i> {t('_mate_add_model_m4_')}</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 mb-4">
                                                        <a onClick={() => { setZipFileError(''); removeZip(); }} data-toggle="modal" data-target="#FolderModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-folder" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>{t('_mate_add_model_m5_')} </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-lg-4 mb-4">
                                                        <a data-toggle="modal" data-target="#QuestionbankModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-list" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>{t('_mate_add_model_m6_')} </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    <div className="col-md-4 col-lg-4 mb-4">
                                                        <a onClick={(e) => { setPopSlideQuiz(); setQuiz(true); }} id="ek-quiz-modal-link">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-question-circle" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>{t('_mate_popquiz_m2_')} </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    {/**
                                                    <div className="col-md-4 col-lg-4 mb-4">
                                                        <a onClick={(e) => { setPopSlideFile(); }} data-toggle="modal" data-target="#AssignmentModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-tasks" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>Add Assignment</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    */}
                                                    <div className="col-md-4 col-lg-4 mb-4">
                                                        <a className="hide" id="AssignmentModalOpen" data-toggle="modal" data-target="#AssignmentModalLong">&nbsp;</a>
                                                        <a onClick={(e) => { setPopSlideFile(); needAiHelpForAssignment(); }}>
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-tasks" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>{t('_mate_add_model_m8_')} </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-6 mb-6">
                                                        <a data-toggle="modal" data-target="#PlaylistModalLong">
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-film" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>{t('_mate_add_model_m9_11_')} </h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 mb-6">
                                                        <a data-toggle="modal" data-target="#YoutubeModalLong" onClick={(e) => { setPopSlideFile() }}>
                                                            <div className="blog-card-detail">
                                                                <div className="blog-card-inner">
                                                                    <h5 style={{ 'textAlign': 'center', 'color': '#ef403d' }}><i className="fas fa-play" style={{ 'fontSize': '20px', 'padding': '5px' }}></i>{t('_mate_add_model_m10_')}</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">{t('_tour_close_')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="modal fade" id="PlaylistModalLong" tabIndex="-1" role="dialog" aria-labelledby="PlaylistModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered modal-ek-lg-1200" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="PlaylistModalLongTitle">{t('_mate_model1_m1_')} </h4>
                                            <button type="button" id="PlaylistModalLongClose" className="close" data-dismiss="modal" aria-label="Close" onClick={clearGeneratedPlaylist}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit2(generatePlaylist)}>
                                                <div className="form-group mb-0">
                                                    <label>{t('_mate_model1_m2_')} </label>
                                                    <input id="playlist_title" {...register2("playlist_title", {
                                                        required: "required",
                                                        maxLength: {
                                                            value: 80,
                                                            message: t('_lcmeeting_addmeet_title_vali_m1_')
                                                        }
                                                    })} onChange={(e) => { setPlaylistTitle(e.target.value); }} value={playlist_title} type="text" className="form-control" placeholder={t('_createcourse_step3_m3_')} autoFocus />
                                                    <span className="add-on max-chr-counter chrchk-playlist_title" data-ele="playlist_title" data-limit="80" data-showcounter="true"></span>
                                                    <span className="text-danger">
                                                        {errors2.playlist_title && errors2.playlist_title.type === "required" && t('_createcourse_step3_m9_')}
                                                        {errors2.playlist_title && errors2.playlist_title.type !== "required" && errors.playlist_title.message}
                                                    </span>
                                                </div>

                                                <div className="form-group yt-video-div blog-card ek-yt-max-h-450 GenerateVideoParent">
                                                    {
                                                        generatedvideos !== ''
                                                        &&
                                                        <>
                                                            <div className="row">
                                                                {generatedvideos.map((generatedvideo, index) =>
                                                                    <>
                                                                        <div key={generatedvideo.url + 'div' + index} className="col-md-6 col-lg-6 ek-yt-col-6">
                                                                            <div className="blog-card-detail">
                                                                                <a target="_blank" className="pos_link" href={generatedvideo.url}></a>
                                                                                <div className="custom-card-img"><img src={generatedvideo.thumb} className="card-img-top" alt={generatedvideo.title} /></div>
                                                                                <div className="blog-card-inner"><h5 className="">{generatedvideo.title}<span className="text-danger"></span></h5></div>
                                                                            </div>
                                                                            <p className="ek-yt-p-chk"><input onClick={(e) => { setPlaylistVideo(generatedvideo.url, generatedvideo.title); }} className="form-check-input AIytPlaylistcheck" type="checkbox" value={generatedvideo.url} id={generatedvideo.url} /> <span className="ek-yt-span-chk">{t('_mate_model1_m3_')} </span></p>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </>
                                                    }
                                                </div>


                                                <div className="form-group" style={{ 'textAlign': 'center' }}>
                                                    <button type="submit" className="btn btn-circle btn-md cancel-btn pl-3 pr-3">{processingplaylist ? <>{t('_mate_model1_m4_')}</> : <>{t('_mate_model1_m5_')}</>}</button>

                                                    {
                                                        generatedvideos !== '' && generatedvideos.length > 0
                                                        &&
                                                        <>
                                                            <button type="button" onClick={(e) => { clearGeneratedPlaylist(); }} className="btn btn-circle btn-md cancel-btn pl-3 pr-3 ml-3">{t('_mate_model1_m6_')}</button>
                                                            <button type="button" onClick={(e) => { saveGeneratedPlaylist(); }} className="btn btn-circle btn-md submit-pink-btn pl-3 pr-3">{processingplaylist ? <>{t('_mate_model1_m7_')}</> : <>{t('_mate_model1_m8_')}</>}</button>
                                                        </>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="QuestionbankModalLong" tabIndex="-1" role="dialog" aria-labelledby="QuestionbankModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                <div className="modal-dialog modal-dialog-centered modal-ek-lg-1200" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 id="QuestionbankModalLongTitle">{t('_mate_add_model_m6_')} </h4>
                                            <button type="button" id="PlaylistModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-group" style={{ 'textAlign': 'center' }}>
                                                <div className="row">
                                                    {
                                                        qbankrows !== ''
                                                        &&
                                                        <button
                                                            onClick={(e) => { saveQuestionbank(); }}
                                                            disabled={processing}
                                                            className="btn btn-circle btn-md submit-pink-btn">
                                                            {processing ? <>{t('_mate_add_model_m7_')}</> : <>{t('_mate_add_model_m8_')}</>}
                                                        </button>
                                                    }
                                                    <button
                                                        onClick={(e) => { generateQuestionbank(); }}
                                                        disabled={generating}
                                                        className="btn btn-circle btn-md submit-pink-btn">
                                                        {generating ? <>{t('_mate_add_model_m9_')}</> : <>{t('_mate_add_model_m10_')}</>}
                                                    </button>
                                                </div>
                                                {
                                                    qbankrows !== ''
                                                    &&
                                                    <div className="row">
                                                        <DataTable
                                                            columns={qbankcolumns}
                                                            data={qbankrows}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="p-2 d-flex gap-2 matsele">
                        <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={toggleSelectAll}
                            style={{ width: '25px' }}
                            className={`form-control`}
                        />
                        <span>{t('_managelc_select_all_')} </span>
                    </div>
                    {getSelectedRows.length !== 0 &&
                        <>
                            <div className='QuizSelected'>
                                <button className='GenVid GenQueBank' onClick={handleDeleteSelectedRows}>
                                    {t('_mate_add_model_m11_')}
                                </button>
                                <button className='GenVid GenQueBank' onClick={() => { handleGenerateVideoselectedRows(false) }}>
                                    {t('_mate_add_model_m12_')}
                                </button>
                                <button className='GenVid GenQueBank' onClick={() => { handleGenerateVideoselectedRows(true) }}>
                                    Convert Selected Slides into Video with transcript
                                </button>
                            </div>
                        </>
                    }
                    <div className="meetingdata mb-4">
                        <table>
                            <tbody>
                                {
                                    outer_slides !== ''
                                    &&
                                    <>

                                        <tr>
                                            <td className="p-0" colSpan="2">
                                                <table>
                                                    <tbody>
                                                        {
                                                            outer_slides.map((outer_slide, index) =>
                                                                <ListSlide key={index} slide={outer_slide} setPopSlideFile={setPopSlideFile} setViewSlide={setViewSlide} setPopSlideQuiz={setPopSlideQuiz} setQuiz={setQuiz} ConfirmDeleteSection={ConfirmDeleteSection} needtoRefresh={needtoRefresh} refresh={refresh} setGetSelectedRows={setGetSelectedRows} setSelectedRows={setSelectedRows} selectedRows={selectedRows} setIsAIvideo={setIsAIvideo} setVideoViewEdit={setVideoViewEdit} updateProgressBar={updateProgressBar} clearprogressinterval={clearprogressinterval} setprogresstozero={setprogresstozero} />
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </>
                                }

                                {
                                    sections !== ''
                                    &&
                                    sections.map((section, index) =>
                                        <tr key={index}>
                                            <td className="p-0" colSpan="2">
                                                <table>
                                                    <thead>
                                                        <tr key={index}>
                                                            <th colSpan={4} scope="row"><p><strong>{section.title}</strong>{section.description}</p></th>
                                                            <th scope="row" className="d-flex justify-content-end">
                                                                <a data-toggle="modal" data-target="#SectionModalLong" onClick={(e) => { setPopSection(section.id, section.title, section.description) }} className="edit_btn  ek-lrs-file-launched-t ml-3">
                                                                    {t('_mate_listslide_m2_')}
                                                                </a>
                                                                <a onClick={(e) => { ConfirmDeleteSection(section.id, true, "Section"); }} className="dlt_btn"><i className="fas fa-trash-alt"></i></a>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            section.slides.map((slide, index) =>
                                                                <ListSlide key={index} slide={slide} setPopSlideFile={setPopSlideFile} setViewSlide={setViewSlide} setPopSlideQuiz={setPopSlideQuiz} setQuiz={setQuiz} ConfirmDeleteSection={ConfirmDeleteSection} needtoRefresh={needtoRefresh} refresh={refresh} setGetSelectedRows={setGetSelectedRows} setSelectedRows={setSelectedRows} selectedRows={selectedRows} setIsAIvideo={setIsAIvideo} setVideoViewEdit={setVideoViewEdit} updateProgressBar={updateProgressBar} clearprogressinterval={clearprogressinterval} setprogresstozero={setprogresstozero} />
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    )
                                }

                                {
                                    (outer_slides === '' && sections === '')
                                    &&
                                    <tr>
                                        <td className="p-0 text-center p-3 ek_uploadmat" data-toggle="modal" data-target="#MaterialsTypeModalLong" id="MaterialsTypeModalLongclick">
                                            {t('_mate_part2_m17_')}
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <div className="clearfix"></div>

                        {
                            props.is_edit
                            &&
                            total_count > 5
                            &&
                            <div className="custom-pagination">
                                <nav aria-label="Page navigation example">
                                    {/*<CustomPagination currentPage={currentPage} pageCount={total_count} perPageRecord={5} handlePageClick={(data) => { fetchSections(data.selected); setCurrentPage(data.selected); }} />*/}
                                </nav>
                            </div>
                        }

                    </div>

                    {
                        !props.is_edit
                        &&
                        <>
                            <button type="button" onClick={(e) => { props.setStep(2); }} className="btn btn-circle btn-md cancel-btn mr-2">{t('_custom_pagi_previous_')}</button>
                            <button onClick={(e) => { props.submitNext(true); }} className="btn btn-circle btn-md submit-pink-btn ml-1 mr-2">{t('_createcourse_step1_m26_')}</button>
                            <button onClick={(e) => { navigate('/dashboard/tutor/published-courses'); }} className="btn btn-circle btn-md cancel-btn">{t('_createcourse_step1_m27_')}</button>
                        </>
                    }
                    <div className="clearfix"></div>
                </div >
            }


            <div className="modal fade" id="ProgressModalLong" tabIndex="-1" role="dialog" aria-labelledby="ProgressModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-ek-lg" role="document">
                    <div className="modal-content ProgressModalCont">
                        <div className="modal-header">
                            <p>{progress_bar_one_msg}</p>
                        </div>
                        <div className="modal-body">
                            <ProgressBar
                                id="progress_bar_one"
                                value={progress_bar_one}
                                completed={progress_bar_one}
                                className="ek-progress-bar-wrapper"
                                barContainerClassName="ek-progress-bar-container"
                                completedClassName="ek-progress-bar-barCompleted"
                                labelClassName={'Processing... please wait. ' + progress_bar_one + '% completed.'}
                            />


                        </div>
                        <div className="modal-footer"></div>
                    </div>
                </div>
            </div>
            <a className="hide" id="ProgressModalLongOpen" data-toggle="modal" data-target="#ProgressModalLong">&nbsp;</a>
        </>
    );
}

export default Materials;

const ListSlide = ({ slide, setPopSlideFile, setViewSlide, setPopSlideQuiz, setQuiz, ConfirmDeleteSection, needtoRefresh, refresh, setGetSelectedRows, setSelectedRows, selectedRows, setIsAIvideo, setVideoViewEdit, updateProgressBar, clearprogressinterval, setprogresstozero }) => {
    const [assignmentShow, setAssignmentShow] = useState(false);
    const [assignment_id, setAssignmentId] = useState(null);
    const { t } = useTranslation();
    const _token = localStorage.getItem('lmstoken');
    const showConvertToVideo = async () => {
        SwalBox.fire({
            allowOutsideClick: false,
            html: t('_mate_listslide_m3_'),
            icon: 'question',
            footer: `<span class="ek-red-col-text"><i class="fa fa-info-circle" aria-hidden="true"></i> ${t('_mate_listslide_m4_')}</span>`,
            showCancelButton: true,
            confirmButtonColor: '#30314c',
            cancelButtonColor: '#30314c',
            confirmButtonText: t('_show_tour_yes_'),
            cancelButtonText: t('_show_tour_no_')
        }).then((result) => {
            if (result.isConfirmed) {
                processConvertToVideo();
            } else {
                SwalBox.close();
            }
        });
    }

    const processConvertToVideo = async (slideid = null) => {
        if (slideid != null) {
            var _convert_slide_id = slideid;
        } else {
            var _convert_slide_id = slide.uid;
        }
        const formData = new FormData();
        formData.append('convert_slide_id', _convert_slide_id);

        /*
        SwalBox.fire({
            title: 'Please Wait...',
            text:'Your text content will be converted into video shortly, depending on its length. Thank you for your patience.',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                SwalBox.showLoading();
            }
        });
        */
        document.getElementById('ProgressModalLongOpen').click();
        updateProgressBar(130, 'single_video');
        var sURL = `${API_BASE}post-convert-course-slide`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            /*
            SwalBox.close();
            //console.info(data);
            SwalBox.fire(
                'Success!',
                'Your slide has been converted into a video file!',
                'success'
            );
            */
            // clearInterval(intervalvariable);
            clearprogressinterval();
            setprogresstozero();
            document.getElementById('ProgressModalLongOpen').click();
            SwalBox.close();
            //console.info(data);
            SwalBox.fire(
                'Success!',
                'Your slide has been converted into a video file!',
                'success'
            );

            setTimeout(() => {
                SwalBox.close();
                needtoRefresh();
            }, 2000);
        }
    }

    function formatDate(inputDate) {
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June', 'July',
            'August', 'September', 'October', 'November', 'December'
        ];
        const dateComponents = inputDate.split(' ');
        const day = dateComponents[0];
        const month = monthNames.indexOf(dateComponents[1]) + 1;
        const year = dateComponents[2].slice(0, 4);
        const formattedDay = day.padStart(2, '0');
        const formattedMonth = month.toString().padStart(2, '0');
        const formattedYear = year.slice(-2);
        const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`;

        return formattedDate;
    }

    const toggleRowSelection = (id) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(id)) {
                return prevSelectedRows.filter((rowId) => rowId !== id);
            } else {
                return [...prevSelectedRows, id];
            }
        });

        setGetSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(id)) {
                return prevSelectedRows.filter((rowId) => rowId !== id);
            } else {
                return [...prevSelectedRows, id];
            }
        });
    };


    return (
        <>
            <tr>
                <td>
                    <input type="checkbox" className={`form-control`}
                        checked={selectedRows.includes(slide.uid)}
                        onChange={() => toggleRowSelection(slide.uid)}
                        style={{ width: '20px' }}
                    />
                </td>
                <td>
                    {
                        (slide.material.material_type === 'Assignment' || slide.material.material_type === 'Quiz')
                        &&
                        <p><strong>{slide.material.material_type}</strong></p>
                    }
                    {slide.title}
                </td>
                <td width={'5%'}>
                    {
                        (slide.material.material_type !== 'Text' && slide.material.material_type !== 'Assignment' && slide.material.material_type !== 'Quiz' && slide.material.material_type !== 'Youtube')
                        &&
                        <i className="fas fa-paperclip"></i>
                    }
                </td>
                <td width={'10%'}>{formatDate(slide.date_time)}</td>
                <td width={'55%'}>
                    {/* {
                        (slide.material !== null)
                        &&
                         <a onClick={(e) => { setViewSlide(''); setViewSlide(slide); document.getElementById('MaterialViewModalclick').click(); }} className="edit_btn  ek-lrs-file-launched">View</a>
                    } */}

                    {
                        (slide.material.material_type === 'Text')
                        &&
                        <>
                            <div className="d-flex justify-content-end">
                                <a title="convert to video" onClick={(e) => { setPopSlideFile(slide.id, slide.title, slide.material.material); showConvertToVideo(); }} className="edit_btn  ek-lrs-file-launched-t ml-3">{t('_mate_listslide_m1_')}</a>
                                <a data-toggle="modal" data-target="#SlideModalLong" onClick={(e) => { setPopSlideFile(slide.id, slide.title, slide.material.material); }}
                                    className="edit_btn  ek-lrs-file-launched-t ml-3"
                                >{t('_mate_listslide_m2_')} </a>
                                <a onClick={(e) => { ConfirmDeleteSection(slide.id, false, slide?.material?.material_type); }} className="dlt_btn"><i className="fas fa-trash-alt"></i></a>
                            </div>
                        </>
                    }

                    {
                        (slide.material.material_type === 'Assignment')
                        &&
                        <>
                            {assignmentShow ? <SubmittedAssignments onHide={() => setAssignmentShow(false)} slide={assignment_id} /> : null}
                            <div className="d-flex justify-content-end">
                                <a data-toggle="modal" data-target="#AssignmentModalLong" onClick={(e) => { setPopSlideFile(slide.id, slide.title, slide.material.material); }} className="edit_btn  ek-lrs-file-launched ml-3">{t('_mate_listslide_m2_')} </a>

                                <a onClick={(e) => { setAssignmentId(slide.id); setAssignmentShow(true); }} className="dlt_btn" title="View Submissions"><i className="fas fa-users"></i></a>

                                <a onClick={(e) => { ConfirmDeleteSection(slide.id, false, slide?.material?.material_type); }} className="dlt_btn"><i className="fas fa-trash-alt"></i></a>
                            </div>
                        </>
                    }

                    {
                        (slide.material.material_type === 'Youtube')
                        &&
                        <>
                            <div className="d-flex justify-content-end">
                                <a data-toggle="modal" data-target="#YoutubeModalLong" onClick={(e) => { setPopSlideFile(slide.id, slide.title, slide.material.material_link ? slide.material.material_link : slide.material.material, slide.material.material); }} className="edit_btn  ek-lrs-file-launched">{t('_mate_listslide_m2_')} </a>
                                <a onClick={(e) => { ConfirmDeleteSection(slide.id, false, slide?.material?.material_type); }} className="dlt_btn"><i className="fas fa-trash-alt"></i></a>
                            </div>
                        </>
                    }

                    {
                        (slide.material.material_type === 'Quiz')
                        &&
                        <div className="d-flex justify-content-end">
                            <a onClick={(e) => { setQuiz(true); setPopSlideQuiz(slide); }} className="edit_btn  ek-lrs-file-launched-t">{t('_mate_listslide_m2_')}</a>
                            <a onClick={(e) => { ConfirmDeleteSection(slide.id, false, slide?.material?.material_type); }} className="dlt_btn"><i className="fas fa-trash-alt"></i></a>
                        </div>
                    }

                    {
                        (slide.material.material_type !== 'Text' && slide.material.material_type !== 'Assignment' && slide.material.material_type !== 'Quiz' && slide.material.material_type !== 'Youtube' && slide.material.material_type !== "Video" && slide.converted_from_text !== "YES")
                        &&
                        <div className="d-flex justify-content-end">
                            <a onClick={(e) => { setViewSlide(''); setViewSlide(slide); document.getElementById('MaterialViewModalclick').click(); }} className="edit_btn  ek-lrs-file-launched-t">{t('_emplist_view_')} </a>
                            <a data-toggle="modal" data-target="#UploadModalLong" onClick={(e) => { setPopSlideFile(slide.id, slide.title, slide.material_desc) }} className="edit_btn  ek-lrs-file-launched-t ml-3">{t('_edit_')} </a>
                            <a onClick={(e) => { ConfirmDeleteSection(slide.id, false, slide?.material?.material_type); }} className="dlt_btn"><i className="fas fa-trash-alt"></i></a>
                        </div>
                    }

                    {
                        (slide.material.material_type === "Video" && slide.converted_from_text !== "YES")
                        &&
                        <>
                            <div className="d-flex justify-content-end">
                                {/* <a onClick={(e) => { setViewSlide(''); setViewSlide(slide); document.getElementById('MaterialViewModalclick').click(); }} className="edit_btn  ek-lrs-file-launched">View</a> */}
                                <a data-toggle="modal" data-target="#UploadModalLong" onClick={(e) => { setPopSlideFile(slide.id, slide.title, slide.material_desc); setViewSlide(''); setViewSlide(slide); setVideoViewEdit(true) }} className="edit_btn  ek-lrs-file-launched">{t('_mate_listslide_m2_')} </a>
                                <a onClick={(e) => { ConfirmDeleteSection(slide.id, false, slide?.material?.material_type); }} className="dlt_btn"><i className="fas fa-trash-alt"></i></a>
                            </div>
                        </>
                    }

                    {
                        (slide.material.material_type === "Video" && slide.converted_from_text === "YES")
                        &&
                        <div className="d-flex justify-content-end">
                            <a onClick={(e) => { setViewSlide(''); setViewSlide(slide); document.getElementById('MaterialViewModalclick').click(); }} className="edit_btn  ek-lrs-file-launched-t">{t('_emplist_view_')}</a>
                            <a data-toggle="modal" data-target="#SlideModalLong" onClick={(e) => { setPopSlideFile(slide.id, slide.title, slide.material_backup); setIsAIvideo(true); }} className="edit_btn  ek-lrs-file-launched-t ml-3"> {t('_edit_')} </a>
                            <a onClick={(e) => { ConfirmDeleteSection(slide.id, false, slide?.material?.material_type); }} className="dlt_btn"><i className="fas fa-trash-alt"></i></a>
                        </div>
                    }
                    {/* <a onClick={(e) => { ConfirmDeleteSection(slide.id, false, slide?.material?.material_type); }} className="dlt_btn"><i className="fas fa-trash-alt"></i></a> */}
                </td>
            </tr>
        </>
    );
}

const PopQuizAddEdit = ({ course_id, slide_id, slide_title, slide_desc, sections, fetchSections, quiz_list, setQuiz, currentPage }) => {
    const _token = localStorage.getItem('lmstoken');
    const { register, control, handleSubmit, formState: { errors }, clearErrors } = useForm();
    const newQuestion = { question: "", answers: [{ is_correct: true, value: "", description: "" }, { is_correct: false, value: "", description: "" }] };
    const [questions, setQuestions] = useState(quiz_list !== '' ? quiz_list : [newQuestion]);
    const [processing, setProcessing] = useState(false);
    const [title, setTitle] = useState(slide_title);
    const [desc, setDesc] = useState(slide_desc);
    const { t } = useTranslation();

    const handleQuestionChange = (event, qIndex) => {
        var newArr = [];
        questions.map((objQues, oqIndexs) => {
            if (qIndex === oqIndexs) {
                newArr.push({
                    question: event.target.value,
                    answers: objQues.answers
                });
            } else {
                newArr.push(objQues);
            }
        });

        setQuestions(newArr);
    }

    const handleAnswerChange = (event, qIndex, aIndex, isValue = false) => {
        var newArr = [];
        questions.map((objQues, oqIndexs) => {
            if (qIndex === oqIndexs) {
                var question = objQues.question;
                var answers = [];

                objQues.answers.map((objAnswer, anIndex) => {
                    if (anIndex === aIndex) {
                        var is_corr = objAnswer.is_correct;
                        var val = objAnswer.value;
                        var desc = objAnswer.description;
                        if (isValue) {
                            val = event.target.value;
                        } else {
                            desc = event.target.value;
                        }

                        answers.push({ is_correct: is_corr, value: val, description: desc });
                    } else {
                        answers.push(objAnswer);
                    }
                });

                newArr.push({ question: question, answers: answers });
            } else {
                newArr.push(objQues);
            }
        });

        setQuestions(newArr);
    }

    const handleCorrChange = (event, qIndex, aIndex) => {
        var newArr = [];
        questions.map((objQues, oqIndexs) => {
            if (qIndex === oqIndexs) {
                var question = objQues.question;
                var answers = [];

                objQues.answers.map((objAnswer, anIndex) => {
                    if (anIndex === aIndex) {
                        answers.push({ ...objAnswer, is_correct: true });
                    } else {
                        answers.push({ ...objAnswer, is_correct: false });
                    }
                });

                newArr.push({ question: question, answers: answers });
            } else {
                newArr.push(objQues);
            }
        });

        setQuestions(newArr);
    }

    const addNewAnswer = (qIndex) => {
        const newArr = questions.map((objQues, oqIndexs) =>
            qIndex === oqIndexs ? { question: objQues.question, answers: [...objQues.answers, { is_correct: false, value: "", description: "" },] } : objQues
        );

        setQuestions(newArr);
    }

    const deleteQuestion = (qIndex) => {
        var newArr = [];
        questions.map((objQues, oqIndexs) => {
            if (qIndex !== oqIndexs) {
                newArr.push(objQues);
            }
        });

        setQuestions(newArr);
    }

    const deleteAnswer = (qIndex, aIndex) => {
        var newArr = [];
        questions.map((objQues, oqIndexs) => {
            if (qIndex === oqIndexs) {
                var question = objQues.question;
                var answers = [];

                objQues.answers.map((objAnswer, anIndex) => {
                    if (anIndex !== aIndex) {
                        answers.push(objAnswer);
                    }
                });

                newArr.push({ question: question, answers: answers });
            } else {
                newArr.push(objQues);
            }
        });

        setQuestions(newArr);
    }

    const saveQuiz = async (form) => {
        const formData = new FormData();
        if (typeof form.section_id !== 'undefined' && form.section_id.length > 0) {
            formData.append('section_id', form.section_id);
        }
        formData.append('quiz_title', form.quiz_title);
        formData.append('quiz_description', form.quiz_description);
        formData.append('question', JSON.stringify(questions));

        setProcessing(true);
        var sURL = `${API_BASE}post-save-course-quiz/${course_id}${slide_id !== '' ? `/${slide_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            document.getElementById('QuizeModalLongClose').click();
            Toaster.success(data.message);
            fetchSections(currentPage);
            setQuiz(false);
            setProcessing(false);
        } else {
            Toaster.error(data.message);
            setProcessing(false);
        }

    }

    return (
        <>
            <div className="modal-content">
                <form onSubmit={handleSubmit(saveQuiz)}>
                    <div className="modal-header">
                        <h4 id="QuizeModalLongTitle">{slide_id !== '' ? t('_mate_popquiz_m1_') : t('_mate_popquiz_m2_')}</h4>
                        <button type="button" id="QuizeModalLongClose" onClick={(e) => { setQuiz(false); }} className="close" >
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        {
                            (slide_id === '' && sections !== '')
                            &&
                            <div className="form-group">
                                <label>{t('_createcourse_step3_m7_')} </label>
                                <select {...register("section_id")}>
                                    <option value="">{t('_createcourse_step3_m7_')} </option>
                                    {
                                        sections !== ''
                                        &&
                                        sections.map((section, index) =>
                                            <option key={index} value={section.id}>{section.title}</option>
                                        )
                                    }
                                </select>
                                <span className="text-danger">
                                    {errors.section_id && errors.section_id.type === "required" && t('_courselist_cour_learner_level_vali_m1_')}
                                    {errors.section_id && errors.section_id.type !== "required" && errors.section_id.message}
                                </span>
                            </div>
                        }

                        <div className="form-group">
                            <label>{t('_mate_popquiz_m3_')} </label>
                            <input id="quiz_title" {...register("quiz_title", {
                                required: "required",
                                maxLength: {
                                    value: 80,
                                    message: t('_lcmeeting_addmeet_title_vali_m1_')
                                }
                            })} onChange={(e) => { setTitle(e.target.value); }} type="text" className="form-control" placeholder={t('_createcourse_step3_m3_')} value={title !== '' ? title : ''} autoFocus />
                            <span className="add-on max-chr-counter chrchk-quiz_title" data-ele="quiz_title" data-limit="80" data-showcounter="true"></span>
                            <span className="text-danger">
                                {errors.quiz_title && errors.quiz_title.type === "required" && t('_mate_popquiz_m4_')}
                                {errors.quiz_title && errors.quiz_title.type !== "required" && errors.quiz_title.message}
                            </span>
                        </div>
                        <div className="form-group">
                            <label>{t('_e_description_')}</label>
                            <textarea id="quiz_description" {...register("quiz_description", {
                                required: "required",
                                maxLength: {
                                    value: 500,
                                    message: t('_mate_popquiz_m5_')
                                }
                            })} onChange={(e) => { setDesc(e.target.value); }} className="form-control small_height" placeholder={t('_e_description_')} value={desc !== '' ? desc : ''}></textarea>
                            <span className="add-on max-chr-counter chrchk-quiz_description" data-ele="quiz_description" data-limit="500" data-showcounter="true"></span>
                            <span className="text-danger">
                                {errors.quiz_description && errors.quiz_description.type === "required" && t('_mate_popquiz_m6_')}
                                {errors.quiz_description && errors.quiz_description.type !== "required" && errors.quiz_description.message}
                            </span>
                        </div>
                        <div className="form-group">
                            <hr />
                        </div>
                        {
                            (typeof questions !== 'undefined' && questions.length > 0)
                            &&
                            questions.map((question, qIndex) =>
                                <div key={qIndex}>
                                    <div className="form-group">
                                        <label>{t('_mate_popquiz_m7_')} {qIndex + 1}</label>
                                        {
                                            questions.length > 1
                                            &&
                                            <a className="float-right text-danger" onClick={(e) => { deleteQuestion(qIndex); }}>{t('_managelc_delete_')} </a>
                                        }
                                        <input {...register(`question.${qIndex}`, {
                                            required: "required"
                                        })} onChange={(e) => { handleQuestionChange(e, qIndex); }} type="text" className="form-control" value={question.question} placeholder={t('_mate_popquiz_m8_')} />
                                        <span className="text-danger">
                                            {errors.question?.[qIndex]?.type === "required" && t('_mate_popquiz_m9_')}
                                            {errors.question?.[qIndex]?.type !== "required" && errors.question?.[qIndex]?.message}
                                        </span>
                                    </div>
                                    <div className="form-group">
                                        {
                                            question.answers.map((answer, aIndex) =>
                                                <div key={aIndex}>
                                                    <label>{t('_mate_popquiz_m10_')} {aIndex + 1}</label>
                                                    <div className="ansbox">
                                                        <input {...register(`is_correct.${qIndex}`, { required: "required" })} onChange={(e) => { handleCorrChange(e, qIndex, aIndex); }} type="radio" defaultChecked={answer.is_correct ? true : false} value={aIndex} />
                                                        <input {...register(`answer.${qIndex}.${aIndex}`, {
                                                            required: "required"
                                                        })} onChange={(e) => { handleAnswerChange(e, qIndex, aIndex, true); }} type="text" value={answer.value} className="form-control" placeholder={t('_mate_popquiz_m11_')} />
                                                        <span className="text-danger">
                                                            {typeof errors.answer?.[qIndex] !== 'undefined' && errors.answer?.[qIndex][aIndex]?.type === "required" && t('_mate_popquiz_m12_')}
                                                            {typeof errors.answer?.[qIndex] !== 'undefined' && errors.answer?.[qIndex][aIndex]?.type !== "required" && errors.answer?.[qIndex][aIndex]?.message}
                                                        </span>
                                                        {
                                                            question.answers.length > 2
                                                            &&
                                                            <a onClick={(e) => { deleteAnswer(qIndex, aIndex); }}><i className="fas fa-trash-alt"></i></a>
                                                        }
                                                        {
                                                            (question.answers.length - 1) === aIndex
                                                            &&
                                                            <a onClick={(e) => { addNewAnswer(qIndex); }}><i className="fas fa-plus"></i></a>
                                                        }
                                                    </div>
                                                    <textarea {...register(`description.${qIndex}.${aIndex}`)} onChange={(e) => { handleAnswerChange(e, qIndex, aIndex, false); }} value={answer.description} className="form-control small_height mb-3" placeholder={t('_mate_popquiz_m13_')}></textarea>
                                                    <span className="text-danger">
                                                        {typeof errors.description?.[qIndex] !== 'undefined' && errors.description?.[qIndex][aIndex]?.type === "required" && t('_createcourse_step2_m13_')}
                                                        {typeof errors.description?.[qIndex] !== 'undefined' && errors.description?.[qIndex][aIndex]?.type !== "required" && errors.description?.[qIndex][aIndex]?.message}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <div className="form-group">
                            <a onClick={(e) => { clearErrors('question'); clearErrors('answer'); clearErrors('description'); setQuestions([...questions, newQuestion]); }} className="link">{t('_createcourse_step2_m14_')}</a>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-circle btn-md cancel-btn" onClick={(e) => { setQuiz(false); }}>{t('_tour_close_')}</button>
                        <button disabled={processing} type="submit" className="btn btn-circle btn-md submit-pink-btn"> {processing ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>{t('_createcourse_step2_m15_')}</>} </button>
                    </div>
                </form>
            </div>
        </>
    );
}

const SubmittedAssignments = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const [students, setStudents] = useState([]);
    const [all, setAll] = useState(false);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState("created_at");
    const [order, setOrder] = useState(true);
    const [count, setCount] = useState(0);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [showaddNote, setShowaddNote] = useState(false);
    const { register, handleSubmit, reset, setValue } = useForm();
    const [currentStudentName, setCurrentStudentName] = useState('');
    const [submissionID, setSubmissionID] = useState('');

    useEffect(() => {
        setPage(1 + 1);
        fetchStudents();
        // eslint-disable-next-line
    }, [sort, order, showaddNote]);

    const fetchStudents = async (search = '') => {
        const response = await fetch(`${API_BASE}submitted-assignments/${props.slide}?page=${page}&search=${search}&sort=${sort}&order=${order ? 'ASC' : 'DESC'}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            console.log(data.data);
            setStudents(data.data.assignments);
            setRowData(data.data.assignments);
            setCount(data.data.all_counts);
            if (data.data.total === 1) {
                setAll(true);
            }

        } else {
            setStudents([]);
            setRowData([]);

        }
    }

    const showMore = async () => {
        setPage(page + 1);
        fetchStudents();
    }

    const setSortName = () => {
        setOrder(!order);
        setSort('name');
    }
    const setSortDate = () => {
        setOrder(!order);
        setSort('created_at');
    }
    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        const allColumnIds = [];
        params.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        params.columnApi.autoSizeColumns(allColumnIds);
    };


    function cellRenderer(props) {
        console.log(props.api.startEditingCell);
        console.log(props.rowIndex);
        console.log(props.column.getId());
        console.log(props.value);
        const handleClick = () => {
            props.api.startEditingCell({
                rowIndex: props.rowIndex,
                colKey: props.column.getId(),
            });
        };
        return (
            <span>
                <button style={{ height: '30px' }} onClick={handleClick}>
                    ✎
                </button>
                <span style={{ paddingLeft: '4px' }}>{props.value}</span>
            </span>
        );
    }

    const showaddnoteform = () => {
        setShowaddNote(true);
    }

    const filladdnoteform = (defaultdata) => {
        setValue('grade', defaultdata.grade);
        setValue('score', defaultdata.score);
        setValue('notes', defaultdata.notes);
    }

    const showsubmitassignlist = () => {
        console.log('btn clicked');
        setShowaddNote(false);
    }

    const defaultColDef = {
        resizable: true,
        editable: false,
        filter: true,
        sortable: true,
        floatingFilter: true,
        suppressNavigable: true,
    }

    const columnDefs = [
        {
            headerName: "Sr. No.",
            valueGetter: "node.rowIndex + 1",
            filter: false,
            sortable: false,
            floatingFilter: false,
            width: 80,
            minWidth: 80,
            colId: "No"
        },
        {
            headerName: "Submitted by",
            field: 'submitted_by',
            cellRenderer: (params) => {
                return (
                    <>
                        <img src={params.data.submitted_by.image} alt="Profile Photo" width={45} height={45} ></img>
                        <span>&nbsp;&nbsp;{params.data.submitted_by.name}</span>
                    </>
                )
            },
            filterValueGetter: (params) => {
                return params.data.submitted_by.name;
            },
            width: 250,
            minWidth: 220,
            colId: "SubmittedBy",
            filter: "agTextColumnFilter",
            flex: 1
        },
        {
            headerName: "Submitted at",
            field: "submitted_at",
            colId: "SubmittedAt",
            minWidth: 160,
            flex: 1,
        },
        {
            headerName: "Assignment",
            field: "Assignment",
            colId: "Assignment",
            minWidth: 270,
            cellRenderer: (params) => {
                return (
                    <>
                        <a href={`${params.data.assignment}`} target="_blank"><span className="dlt_btn"><i className="fa fa-download"></i> Download Submitted Assignment</span></a>
                    </>
                )
            },
            filter: false,
            sortable: false,
            floatingFilter: false,
        },
        {
            headerName: "Grade",
            field: "grade",
            colId: "grade",
            minWidth: 100,
            width: 100
        },
        {
            headerName: "Score",
            field: "score",
            colId: "Score",
            minWidth: 100,
            width: 100
        },
        {
            headerName: "Notes",
            field: "notes",
            colId: "Notes",
            minWidth: 160,
            flex: 1,
        },
        {
            headerName: "Action",
            width: 50,
            pinned: 'right',
            filter: false,
            sortable: false,
            floatingFilter: false,
            resizable: false,
            cellRenderer: (params) => {
                return (
                    <>
                        <span style={{
                            cursor: 'pointer',
                            color: '#ef453f'
                        }}>
                            <span onClick={() => { setSubmissionID(params?.data?.submission_id); setCurrentStudentName(params?.data?.submitted_by?.name); showaddnoteform(); filladdnoteform(params?.data) }} style={{ marginLeft: '1px' }} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Add / edit"><i className="fa fa-pencil"></i></span>
                        </span>
                    </>
                )
            },
            filter: false,
            sortable: false,
            floatingFilter: false,
        },
    ];

    const gridOptions = {
        stopEditingWhenCellsLoseFocus: true,
    }

    const getRowId = useMemo(() => {
        return (params) => params.data.submitted_by.uid;
    }, []);

    const onCellEditRequest = useCallback((event) => {
        const oldData = event.data;
        const field = event.colDef.field;
        const newValue = event.newValue;
        const newData = { ...oldData };
        newData[field] = event.newValue;
        console.log('onCellEditRequest, updating ' + field + ' to ' + newValue);
        const tx = {
            update: [newData],
        };
        event.api.applyTransaction(tx);
    }, []);

    const handleCellValueChanged = (event) => {
        const { data, colDef, newValue } = event;
        // Create a new row data array with the updated value
        const updatedRowData = rowData.map((row) => {
            console.log(row.id);
            console.log(row);

            if (row.submitted_by.uid === data.submitted_by.uid) {
                return {
                    ...row,
                    [colDef.field]: newValue,
                };
            }
            return row;
        });

        // Update the state with the new data
        setRowData(updatedRowData);
        // const { data } = event.node;
        // You can make an API call here to save the updated data to your server
    };

    const saveAssignNote = async (form) => {
        const formData = new FormData();

        formData.append('grade', form.grade);
        formData.append('score', form.score);
        formData.append('notes', form.notes);

        const response = await fetch(`${API_BASE}update-submitted-assignment/${submissionID}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData

        });

        const data = await response.json();
        if (data.status) {
            console.log(data.data);
            reset();
            setShowaddNote(false);
        } else {
            reset();
            setShowaddNote(false);
        }
    }

    return (
        <Modal
            className={`invitePopup view_assignement mw-60 ${showaddNote ? 'ViewAssignSmall' : 'ViewAssign'}`}
            id="ViewAssign"
            dialogClassName="mw-100"
            show={true}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            style={{ width: '70% !important' }}
        >
            <Modal.Header>
                <Modal.Title> <h4>{showaddNote === true ? 'Add Data' : 'View Assignment Submissions'}</h4> </Modal.Title>
                <button type="button" className="close" onClick={() => { setShowaddNote(false); props.onHide(); reset() }}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body className="scroll_add">
                {showaddNote === true ?
                    (<>
                        <div className="d-flex flex-row">
                            <div className="form-group m-0 col-lg-8 p-0">
                                <label className="addnotelabel">Student Name</label>
                                <input id="student_name" name="student_name" type="text" className="form-control" value={currentStudentName} readOnly />
                            </div>
                            <div className="col-lg-4 d-flex ViewAssignFirstdiv ml-2 mt-0 mb-0 ViewAssignBackbtn">
                                <button type="button" className="btn btn-circle btn-info align-self-end" onClick={() => {
                                    setShowaddNote(false); reset()
                                }}>Back to list</button>
                            </div>
                        </div>
                        <div>
                            <form onSubmit={handleSubmit(saveAssignNote)}>

                                <div className="form-group m-0">
                                    <label className="addnotelabel">Add Grade</label>
                                    <input id="grade" name="grade" type="text" className="form-control" placeholder="Enter a grade" {...register("grade")} />
                                </div>
                                <div className="form-group m-0">
                                    <label className="addnotelabel">Add Score</label>
                                    <input id="score" name="score" type="text" className="form-control" placeholder="Enter a score"  {...register("score")} />
                                </div>
                                <div className="form-group m-0">
                                    <label className="addnotelabel">Add Notes</label>
                                    <textarea id="notes" name="notes" type="text" className="form-control" placeholder="Enter a Notes" {...register("notes")} />
                                </div>
                                <div className="mt-2">
                                    <button type="submit" className="btn btn-circle btn-md submit-pink-btn m-0 mt-1 float-right">Save</button>
                                </div>
                            </form>
                        </div>
                    </>
                    ) : (<section className="custom-section member_section mb-0">
                        <div className="profile_user_middle_section">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="custom-user-profile-content p-0">
                                        <div className="custom-learnig-circle-head">
                                            <p>Students who submit this assignment will appear here, hover. To add/edit grade, score and notes hover on the raw and click on pencil icon.</p>
                                        </div>
                                        {/* <div className="member_list border0 pt-0">
                                        <div className="form-group row">
                                            <div className="col-lg-12">
                                                <i className="fas fa-search ml-2"></i><input onChange={(e) => { fetchStudents(e.target.value); }} type="text" className="form-control" id="search_user_control" placeholder="Search for students by name" />
                                            </div>
                                        </div>
                                        <div className="scroll_table">
                                            <table className="table">
                                                <tr>
                                                    <th onClick={() => { setSortName(); }} className="cursor-pointer">Submitted by &nbsp;<i className="fa fa-sort"></i></th>
                                                    <th onClick={() => { setSortDate(); }} className="cursor-pointer">Submitted at &nbsp;<i className="fa fa-sort"></i></th>
                                                    <th>Assignment</th>
                                                </tr>
                                                {
                                                    (typeof students !== 'undefined' && students.length > 0)
                                                    &&
                                                    <>
                                                        {
                                                            students.map((student, index) =>
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="list_img">
                                                                            <div className="img_box">
                                                                                <img src={student?.submitted_by?.image} alt="" />
                                                                            </div>
                                                                            <h5>
                                                                                <Link to={`/profile-details/${student?.submitted_by?.slug}`}>{student?.submitted_by?.name}</Link>
                                                                                <span className="headline">{student?.submitted_by?.exp}</span>
                                                                            </h5>
                                                                        </div>
                                                                    </td>
                                                                    <td><p>{student?.submitted_at}</p></td>
                                                                    <td>{
                                                                        student?.assignment !== ''
                                                                        &&
                                                                        <a href={`${student?.assignment}`} target="_blank"><span className="dlt_btn"><i className="fa fa-download"></i> Assignment</span></a>
                                                                    }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </>
                                                }
                                            </table>
                                            {
                                                (typeof students === 'undefined' || students.length <= 0)
                                                &&
                                                <>
                                                    <div className="clearfix"></div>
                                                    <ul>
                                                        <li>
                                                            <div className="list_img">
                                                                <h5 className="text-center">No submissions found</h5>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </>
                                            }
                                            {
                                                students.length > 0 && all === false
                                                &&
                                                <>
                                                    <div className="clearfix"></div>
                                                    <div className="overflow-hidden">
                                                        <div className="join-invite-more">
                                                            <button onClick={(e) => { showMore() }} className="show-more-btn btn-pink">Show more</button>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="clearfix"></div> */}
                                        <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
                                            <AgGridReact
                                                onGridReady={onGridReady}
                                                columnDefs={columnDefs}
                                                // columnDefs={QuizData}
                                                ref={gridRef}
                                                defaultColDef={defaultColDef}
                                                rowData={rowData}
                                                domLayout="autoHeight"
                                                enableFilter={true}
                                                enableSorting={true}
                                                enableColResize={true}
                                                pagination={true}
                                                paginationPageSize={5}
                                                suppressRowClickSelection={true}
                                                rowHeight={50}
                                                rowSelection={'multiple'}
                                                gridOptions={gridOptions}
                                                getRowId={getRowId}
                                                // onCellEditRequest={onCellEditRequest}
                                                // onSelectionChanged={onSelectionChanged}
                                                onCellValueChanged={handleCellValueChanged}
                                            // suppressClickEdit={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>)

                }
            </Modal.Body>
        </Modal >
    );
}
