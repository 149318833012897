import { useContext, useEffect, useLayoutEffect, useState, React } from "react";
import { Controller, useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import EduKulaAi from "../../entity/EdukulaAi";
import Chat, { Recorder, Bubble, useMessages, useQuickReplies, Form, Input, ScrollView, Card, CardTitle, CardText } from "@chatui/core";
//import '@chatui/core/dist/index.css';
import Cookies from "js-cookie";
import Linkify from 'react-linkify';
import Iframe from 'react-iframe';
import { Helmet } from "react-helmet";

const VirtualTutor = () => {
    const _ca_welcome_message = 'Say Hello to your virtual tutor.';
    let _system_message = '';
    const _token = localStorage.getItem('lmstoken');
    const _lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
    const _lms_learner = _lmsuser['first_name'];
    const _lms_learner_id = _lmsuser['id'];
    const defaultSettings = {
        "STARTING_REQUEST": "",
        "USER_PREFIX": "Learner: ", "AI_PREFIX": "Virtual Tutor:", "CUT_DIALOGUE_PLACEHOLDER": "...",
        "FREQUENCY_PENALTY": 1, "PRESENCE_PENALTY": 1, "MAX_NUM_USER_INPUTS": 3, "MAX_TOKENS": 4000,
        "STARTING_PROMPT": 'I want you to act as my virtual tutor. I will initiate chat with you. I may ask you queries related to different topics and you will explain or answer me based on my queries. You will need to analise my reply/statement first and answer me according to the type of my statement i.e whether it is a question or just a sentense. Please keep your reply as short as possible.',
        "LANGUAGE": "English"
    };
    const defaultQuickReplies = [];
    const { messages, appendMsg, setTyping, deleteMsg } = useMessages([]);
    const [currtext, setcurrtext] = useState('');

    /*
        useLayoutEffect(() => {
            return () => {
                _show_welcome_message();
                _show_greeting_message();
                _get_career_info();
                _get_courses_info();
                _get_learning_circles_info();
            };
        },[]);
    */
    useEffect(() => {
        //console.log('i fire once');
        _show_welcome_message();
        //_show_greeting_message();
    }, []);

    const _show_welcome_message = async () => {
        setTyping(true);
        appendMsg({ type: 'system', content: { text: _ca_welcome_message } });
    }
    const _show_greeting_message = async () => {
        setTyping(true);
        setTimeout(() => {
            appendMsg({ type: 'text', content: { text: 'Hi, ' + _lms_learner + '.' } });
        }, 500);
    }
    function urlify(message) {
        if (!message) return;
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        return message.replace(urlRegex, function (url) {
            var hyperlink = url;
            if (!hyperlink.match('^https?:\/\/')) {
                hyperlink = 'http://' + hyperlink;
            }
            return '<a href="' + hyperlink + '" target="_blank">' + url + '</a>'
        });
    }
    function _get_prompt(user_input) {
        var conversation = "";
        const user_prefix = (Cookies.get("userPrefix") || defaultSettings["USER_PREFIX"]);
        var starting_prompt = defaultSettings["STARTING_PROMPT"];
        const ai_prefix = (Cookies.get("AIPrefix") || defaultSettings["AI_PREFIX"]);
        if (conversation === "") {
            conversation = starting_prompt + "\n" + user_prefix + user_input + "\n" + ai_prefix;
        } else {
            conversation = conversation.trim() + "\n" + user_prefix + user_input + "\n" + ai_prefix;
        }
        return conversation;
    }
    function handleSend(type, val) {
        if (type === 'text' && val.trim()) {
            document.getElementById("virtual-tutor-loader").style.display = "block";
            setTyping(true);
            appendMsg({type: 'text',content: { text: val },position: 'right'});
            setTyping(true);
            appendMsg({type: 'system',content: { text: 'Please wait...' }});
            let _prompt_string = _get_prompt(val+'.');
            try {
                EduKulaAi.createCompletion({
                    model: "text-davinci-003",
                    prompt: _prompt_string,
                    stop: (Cookies.get("userPrefix") || defaultSettings['USER_PREFIX']).trim(),
                    max_tokens: 4097-(_prompt_string.length),
                    temperature: 0.1,
                }).then((completion) => {
                    document.getElementById("virtual-tutor-loader").style.display = "none";
                    document.getElementById("virtual-tutor-chatbox").style.display = "block";
    
                    const responseText = completion.data.choices[0].text;
                    var iframe = document.getElementById('resultFrame');
                    var iframeWindow = iframe.contentWindow;
                    iframeWindow.edukulaSpeaker(responseText);
                }).catch((error) => {
                    SwalBox.fire({
                        title: 'Something went wrong',
                        text: 'Please try again after some time.',
                        icon: 'error',
                    });
                });
            }catch(err) { }



            
        }
    }


    function renderMessageContent(msg) {
        const { type, content } = msg;
        let linki = '';
        //if(type == 'uni'){
        linki = <Linkify options={{ target: 'blank' }}>{content.text}</Linkify>;
        //console.info(urlify(content.text));
        //alert(urlify(content.text));
        //}
        switch (type) {
            case 'text':
                return <Bubble content={linki} />;
            case 'uni':
                return <Bubble content={linki} />;
            default:
                return null;
        }
    }
    const ConfirmDeleteChat = () => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: '    Sure to delete chat?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                clearChat();
            }
        });
    }

    function clearChat() {
        for (let i = 0; i <= messages.length; i++) {
            if (messages[i] != undefined && messages[i] != 'undefined') {
                var idmsg = messages[i]._id; deleteMsg(idmsg);
            }
        }
        _show_welcome_message();
    }
    function handleOnScroll() {

    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Virtual Tutor'}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content">
                    <div className="custom-learnig-circle-head">
                        <h4>Virtual Tutor</h4>
                    </div>
                    <div className="learning-circle-courses">
                        <section className="virtual-tutor">
                            <Iframe url="./virtualtutor/index.html"
                                width="100%"
                                height="420px"
                                id="resultFrame"
                                className=""
                                display="block"
                                position="relative" />
                        </section>
                    </div>
                    <div className="learning-circle-courses">
                        <section id="virtual-tutor-chatbox" className="virtual-tutor">
                            <Chat
                                locale="en-US"
                                placeholder='Type a message and hit enter...'
                                text={currtext}
                                messages={messages}
                                renderMessageContent={renderMessageContent}
                                onSend={handleSend}
                                onScroll={handleOnScroll}
                            />
                            <p id="virtual-tutor-loader" className="virtual-tutor-loader"><img src="./images/Iphone-spinner-2.gif"></img></p>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VirtualTutor
