import { useTranslation } from "react-i18next";

const About = ({ course }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="tab-pane fade show active" id="pills-about" role="tabpanel" aria-labelledby="pills-about-tab">
                <div className="course-tab-contents">
                    <div className="course-tabs-pane-block">
                        <h3>{t('_coursedetail_about_tab_1_')} </h3>
                        <div className="course-tabs-box">
                            <div className="course-tabs-body">
                                <p>{course?.description}</p>
                                {/* <h4>At a glance</h4> */}
                                <div className="course-accordion-section">
                                    <ul className="about-course-list">
                                        <li><div><strong>{t('_explorelc_category_')}:</strong><span>{course?.category?.title}</span></div></li>
                                        <li><div><strong>{t('_coursedetail_cour_subcategory_')}:</strong><span>{course?.sub_category?.title}</span></div></li>
                                        <li><div><strong>{t('_coursedetail_sort_level_')}:</strong><span>{course?.study_level}</span></div></li>
                                        {/*<li><div><strong>Course Language:</strong><span>{course?.language}</span></div></li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;
