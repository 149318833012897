import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const LearningCircleCreated = ({ ntfcn }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src="images/logo_icon.png" className="rounded-circle" alt="Profile Image" />
                    </div>
                    <div className="user-details-title">
                        <h6>EduKula <span>{ntfcn.date_time}</span></h6>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>{t('_dh_coursepublished_m1_') + " "} <strong>{ntfcn?.group?.title}</strong> {t('_dh_lctab_m1_')}</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link" to={`/view-learning-circle/${ntfcn?.group?.uid}/feeds`} target="_blank">
                            <img src={ntfcn?.group?.image} alt={ntfcn?.group?.title} />
                            <span>{t('_dh_lctab_m2_')}</span>
                        </Link>
                    </div>
                    <div className="like-main-area">
                        <div className=""></div>
                        <div className=""></div>
                        <div className=""></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LearningCircleCreated;
