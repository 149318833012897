import { useContext, useEffect, useLayoutEffect, useState, React } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import EduKulaAi from "../../entity/EdukulaAi";
import Quiz from 'react-quiz-component';
import Joyride from 'react-joyride';
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const SelfAssessment = () => {
    const _lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
    const _lms_learner = _lmsuser['first_name'];
    const _lms_learner_id = _lmsuser['id'];
    const navigate = useNavigate();
    const [type_assessment, setTypeAssesement] = useState('');
    const [area_assessment, setAreaAssesement] = useState('');
    const [no_questions, setNumberofquestions] = useState('1');
    const [processing, setProcessing] = useState(false);
    const [selfassesement, setSelfassesement] = useState(false);
    const [aiquiz, setAiquiz] = useState(false);
    const [result_heading_title_text, setResultHeadingTitleText] = useState('');
    const [result_points_text, setResultPointsText] = useState('');
    const [assesement_result_text, setAssesementResultText] = useState('');
    const [tour_steps, setTourSteps] = useState([]);
    const { t } = useTranslation();

    const { register, control, formState: { errors }, getValues, setError, clearErrors } = useForm(
        {
            mode: "all",
        }
    );
    useEffect(() => {
        selectAssesementType();
        //showAssesementTour();
        startAssesementTour();
    }, []);

    //window.onscroll = function () { window.scrollTo(100, 0); };


    const showAssesementTour = () => {
        let show_tour = Cookies.get("show_assesement_tour");
        if (show_tour != 'NO') {
            SwalBox.fire({
                allowOutsideClick: false,
                title: t('_show_tour_maintitle_'),
                text: t('_show_tour_assessment_'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: t('_show_tour_yes_'),
                cancelButtonText: t('_show_tour_no_')
            }).then((result) => {
                if (result.isConfirmed) {
                    startAssesementTour();
                } else {
                    Cookies.set('show_assesement_tour', 'NO');
                }
            });
        }
    }
    const startAssesementTour = () => {
        let steps = [];
        steps = [
            { placementBeacon: 'right', disableBeacon: true, target: '.tour-assesement-step-01', title: t('_sa_tour_t1_'), content: t('_sa_tour_m1_'), },
            { placementBeacon: 'right', disableBeacon: true, target: '.tour-assesement-step-02', title: t('_sa_tour_t2_'), content: t('_sa_tour_m2_'), },
        ];
        setTourSteps(steps);
        Cookies.set('show_assesement_tour', 'NO');
    }
    const selectAssesementType = () => {
        SwalBox.fire({
            allowOutsideClick: false,
            title: t('_sa_warn_m1_'),
            text: t('_sa_warn_m2_'),
            icon: 'question',
            footer: `<span class="ek-red-col-text"><i class="fa fa-info-circle" aria-hidden="true"></i> ${t('_sa_warn_m3_')}</span>`,
            showCancelButton: true,
            confirmButtonColor: '#30314c',
            cancelButtonColor: '#30314c',
            showCloseButton: true,
            confirmButtonText: t('_sa_warn_m4_'),
            cancelButtonText: t('_sa_warn_m5_'),
            customClass: {
                confirmButton: 'tour-assesement-step-01',
                cancelButton: 'tour-assesement-step-02',
            }

        }).then((result) => {
            if (result.isDismissed && result.dismiss == 'close') {
                navigate('/dashboard/home');
            } else {
                if (result.isConfirmed) {
                    setTypeAssesement('A');
                    openAssesementPopup('A');
                } else {
                    openAssesementPopup('B');
                    setTypeAssesement('B');
                }
            }
        });
    }

    function openAssesementPopup(type) {
        document.getElementById('modalTogglerAssesement').click();
    }

    const cancelPopup = async () => {
        setTimeout(() => {
            navigate('/dashboard/home');
        }, 700);
    }

    const generateQuiz = async () => {
        if (area_assessment == "") {
            var inputElement = document.getElementById("area_assessment");
            if (inputElement) {
                inputElement.focus();
            } else {
                console.log("Element not found");
            }
        } else {
            SwalBox.fire({
                title: t('_sweetalert_wait_'),
                text: t('_sa_warn_m6_'),
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    SwalBox.showLoading();
                }
            });

            let _generate_prompt = '';
            _generate_prompt += 'I want you to generate the exact prompt for me using given input. The info might be a sentence or just a single word.';
            _generate_prompt += 'You will have to figure out the primary goal or the subject area and then generate below prompt for me. You must follow the exact pattern.';
            _generate_prompt += 'Input : AWS';
            _generate_prompt += 'Output : ';
            _generate_prompt += 'I want you to generate '+ no_questions +' multi-choice question(s) to test my knowledge in AWS and Cloud Computing.';
            _generate_prompt += 'Generate a multiple-choice question with the following pattern:';
            _generate_prompt += 'Question: [Insert your question here] \nA. [Option A] \nB. [Option B] \nC. [Option C] \nD. [Option D] \nAnswer: [Correct option]';
            _generate_prompt += '\nPlease follow the regex pattern given above and give me straigt forward output no initial messages. The output must start with generated questions. You must add "***" at the end of each generated entry';
            
            _generate_prompt += 'Input : Selling products and improving business';
            _generate_prompt += 'Output : ';
            _generate_prompt += 'I want you to generate '+ no_questions +' multi-choice question(s) to test my knowledge in Sales and Marketing.';
            _generate_prompt += 'Generate a multiple-choice question with the following pattern:';
            _generate_prompt += 'Question: [Insert your question here] \nA. [Option A] \nB. [Option B] \nC. [Option C] \nD. [Option D] \nAnswer: [Correct option]';
            _generate_prompt += '\nPlease follow the regex pattern given above and give me straigt forward output no initial messages. The output must start with generated questions. You must add "***" at the end of each generated entry';
            
            _generate_prompt += 'Input: Algebra';
            _generate_prompt += 'Output : ';
            _generate_prompt += 'I want you to generate '+ no_questions +' multi-choice question(s) to test my knowledge in Algebra.';
            _generate_prompt += 'Generate a multiple-choice question with the following pattern:';
            _generate_prompt += 'Question: [Insert your question here] \nA. [Option A] \nB. [Option B] \nC. [Option C] \nD. [Option D] \nAnswer: [Correct option]';
            _generate_prompt += '\nPlease follow the regex pattern given above and give me straigt forward output no initial messages. The output must start with generated questions. You must add "***" at the end of each generated entry';
            
            _generate_prompt += 'Input : ';
            _generate_prompt += area_assessment;

 
            try {
                EduKulaAi.createCompletion({
                    model: "text-davinci-003",
                    prompt: _generate_prompt,
                    temperature: 0.1,
                    max_tokens: 4097-(_generate_prompt.length),
                }).then((pcompletion) => {
                    const responsePrompt = pcompletion.data.choices[0].text;
                    setProcessing(true);
                    let _prompt_string = '';
                    _prompt_string = responsePrompt;
                    setNumberofquestions(no_questions);
                    try {
                        EduKulaAi.createCompletion({
                            model: "text-davinci-003",
                            prompt: _prompt_string,
                            temperature: 0.1,
                            max_tokens: 4097-(_prompt_string.length),
                        }).then((completion) => {
                            const responseText = completion.data.choices[0].text;
                            console.info(responseText);
                            document.getElementById('assesementModalClose').click();
                            const _questions = responseText.split("***");
                            console.info(_questions);
                            var _questionbank = [];
                            var _aiquestionbank = [];
                            var _counter = 1;
                            _questions.forEach(function(qtemp) { 
                                let q = qtemp.trim();
                                const _answers = q.split("\n");
                                console.info(_answers);
                                try {
                                    if(_answers){
                                        var _temp_q     = _answers[0];
                                        var _temp_op1   = _answers[1];
                                        var _temp_op2   = _answers[2];
                                        var _temp_op3   = _answers[3];
                                        var _temp_op4   = _answers[4];
                                        var _temp_a_text = _answers[5];
                                        if(_temp_a_text.includes("Answer: A") == true){ var _temp_a     = 'A';}
                                        if(_temp_a_text.includes("Answer: B") == true){ var _temp_a     = 'B';}
                                        if(_temp_a_text.includes("Answer: C") == true){ var _temp_a     = 'C';}
                                        if(_temp_a_text.includes("Answer: D") == true){ var _temp_a     = 'D';}
                
                                        var _quiz_question = {
                                            'ID':_counter,
                                            'Q':_temp_q.replace('Question: ',''),
                                            'A':_temp_a.replace('A. ',''),
                                            'OP1':_temp_op1.replace('B. ',''),
                                            'OP2':_temp_op2.replace('C. ',''),
                                            'OP3':_temp_op3.replace('D. ',''),
                                            'OP4':_temp_op4,
                                        }
                                        let _canswer = 1;
                
                                        if(_quiz_question.A == 'A') { _canswer = '1';}
                                        if(_quiz_question.A == 'B') { _canswer = '2';}
                                        if(_quiz_question.A == 'C') { _canswer = '3';}
                                        if(_quiz_question.A == 'D') { _canswer = '4';}
                                        let _temp_question = {
                                            "question": _quiz_question.Q,
                                            "questionType": "text",
                                            "answerSelectionType": "single",
                                            "answers": [
                                                _quiz_question.OP1,
                                                _quiz_question.OP2,
                                                _quiz_question.OP3,
                                                _quiz_question.OP4,
                                            ],
                                            "correctAnswer": _canswer,
                                            "messageForCorrectAnswer": "Correct answer. Good job.",
                                            "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
                                            "point": "20"
                                        };
                                        //_questionbank.push(_quiz_question);
                                        //if(no_questions <= _counter){
                                            _aiquestionbank.push(_temp_question);
                                        //}
                                        //console.info(_temp_question);
                                        _counter++;
                                    }
                                }catch(err) { }
                            });
                            setAiquiz({
                                "quizSynopsis":"Your Quiz for - "+area_assessment+ ' is ready',
                                "nrOfQuestions": no_questions,
                                "questions": _aiquestionbank,
                                 "appLocale": {"resultPageHeaderText":result_heading_title_text, "resultPagePoint": result_points_text}
                            });
                            SwalBox.close();
                        }).catch((error) => {
                            SwalBox.fire({
                                title: 'Something went wrong',
                                text: 'Please try again after some time.',
                                icon: 'error',
                            });
                            setProcessing(false);
                        });
                    }catch(err) { }
                }).catch((error) => {

                });
            }catch(err) { }
            
            /*
            setProcessing(true);
            let _prompt_string = '';
            if (no_questions < 5) {
                _prompt_string += 'I want you to generate 5 multi-choice questions to test my knowledge in  ' + area_assessment + '. \nPlease follow the regex pattern given in this example: \nQuestion: What is two plus three ? \nA. One \nB. Seven \nC. Five \nD. Ten \nAnswer:  C. ';
            } else {
                _prompt_string += 'I want you to generate ' + no_questions + ' multi-choice questions to test my knowledge in  ' + area_assessment + '. \nPlease follow the regex pattern given in this example: \nQuestion: What is two plus three ? \nA. One \nB. Seven \nC. Five \nD. Ten \nAnswer:  C. ';
            }
            setNumberofquestions(no_questions);
            try {
                EduKulaAi.createCompletion({
                    model: "text-davinci-003",
                    prompt: _prompt_string,
                    temperature: 0.1,
                    max_tokens: 4097 - (_prompt_string.length),
                }).then((completion) => {
                    const responseText = completion.data.choices[0].text;
                    document.getElementById('assesementModalClose').click();
                    const _questions = responseText.split("\n\n");
                    var _questionbank = [];
                    var _aiquestionbank = [];
                    var _counter = 1;
                    _questions.forEach(function (q) {
                        const _answers = q.split("\n");
                        try {
                            if (_answers) {
                                var _temp_q = _answers[0];
                                var _temp_op1 = _answers[1];
                                var _temp_op2 = _answers[2];
                                var _temp_op3 = _answers[3];
                                var _temp_op4 = _answers[4];
                                var _temp_a_text = _answers[5];
                                if (_temp_a_text.includes("Answer: A.") == true) { var _temp_a = 'A'; }
                                if (_temp_a_text.includes("Answer: B.") == true) { var _temp_a = 'B'; }
                                if (_temp_a_text.includes("Answer: C.") == true) { var _temp_a = 'C'; }
                                if (_temp_a_text.includes("Answer: D.") == true) { var _temp_a = 'D'; }

                                var _quiz_question = {
                                    'ID': _counter,
                                    'Q': _temp_q.replace('Question: ', ''),
                                    'A': _temp_a.replace('A. ', ''),
                                    'OP1': _temp_op1.replace('B. ', ''),
                                    'OP2': _temp_op2.replace('C. ', ''),
                                    'OP3': _temp_op3.replace('D. ', ''),
                                    'OP4': _temp_op4,
                                }
                                let _canswer = 1;

                                if (_quiz_question.A == 'A') { _canswer = '1'; }
                                if (_quiz_question.A == 'B') { _canswer = '2'; }
                                if (_quiz_question.A == 'C') { _canswer = '3'; }
                                if (_quiz_question.A == 'D') { _canswer = '4'; }
                                let _temp_question = {
                                    "question": _quiz_question.Q,
                                    "questionType": "text",
                                    "answerSelectionType": "single",
                                    "answers": [
                                        _quiz_question.OP1,
                                        _quiz_question.OP2,
                                        _quiz_question.OP3,
                                        _quiz_question.OP4,
                                    ],
                                    "correctAnswer": _canswer,
                                    "messageForCorrectAnswer": "Correct answer. Good job.",
                                    "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
                                    "point": "20"
                                };
                                //_questionbank.push(_quiz_question);
                                //if(no_questions <= _counter){
                                _aiquestionbank.push(_temp_question);
                                //}
                                //console.info(_temp_question);
                                _counter++;
                            }
                        } catch (err) { }
                    });
                    setAiquiz({
                        "quizSynopsis": "Your Quiz for - " + area_assessment + ' is ready',
                        "nrOfQuestions": no_questions,
                        "questions": _aiquestionbank,
                        "appLocale": { "resultPageHeaderText": result_heading_title_text, "resultPagePoint": result_points_text }
                    });
                    SwalBox.close();
                }).catch((error) => {
                    SwalBox.fire({
                        title: 'Something went wrong',
                        text: 'Please try again after some time.',
                        icon: 'error',
                    });
                    setProcessing(false);
                });
            } catch (err) { }
            */
        }

    }
    const no_questions_options = Array.from(Array(20).keys()).map((num) => (<option key={num + 1} value={num + 1}>{num + 1}</option>));
    const renderCustomResultPage = (obj) => {
        console.log(obj);
        return (
            <div>
                This is a custom result page. You can use obj to render your custom result page
            </div>
        )
    }
    const setQuizResult = (obj) => {
        setTimeout(() => {
            let _result_string = '';
            _result_string = 'Done';
            let _total_points = obj.totalPoints;
            let _correct_points = obj.correctPoints;
            let _percentage_f = (100 * _correct_points) / _total_points;
            let _percentage = _percentage_f.toFixed(2);

            let _message_text = `${t('_sa_resultmsg_m1_')} + ${_percentage} + '%. '`;
            if (_percentage < 50) {
                _message_text += t('_sa_resultmsg_m2_');
            } else if (_percentage > 50 && _percentage < 69) {
                _message_text += t('_sa_resultmsg_m3_');
            } else if (_percentage > 70) {
                _message_text += t('_sa_resultmsg_m4_');
            } else {
                _message_text = t('_sa_resultmsg_m5_');
            }
            setAssesementResultText(_message_text);
            // YOUR LOGIC GOES HERE
        }, 2000);
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_r_selfassessment_')}`}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content">
                    <div className="custom-learnig-circle-head"><h4>{t('_r_selfassessment_')} </h4></div>
                    <div className="learning-circle-courses">
                        <section>
                            <p className="assesement-result-text">{assesement_result_text}</p>
                        </section>
                        <section>
                            {
                                (aiquiz != false)
                                &&
                                <Quiz
                                    quiz={aiquiz}
                                    shuffle={true}
                                    showDefaultResult={true}
                                    onComplete={setQuizResult}
                                />
                            }
                        </section>
                    </div>
                </div>
            </div>
            <div className="materials_section">
                <div className="top_list">
                    <div className="modal fade" id="assesementModal" tabIndex="-1" role="dialog" aria-labelledby="assesementModalTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-ek-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header"><h4 id="assesementModalTitle">{t('_r_selfassessment_')}</h4><button type="button" id="assesementModalClose" className="close hide" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>{t('_sa_area_of_assessment_')} </label>
                                        <input id="area_assessment" {...register("area_assessment", {
                                            required: "required",
                                            maxLength: {
                                                value: 80,
                                                message: t('_sa_area_of_assessment_vali_m1_')
                                            },
                                            minLength: {
                                                value: 3,
                                                message: t('_sa_area_of_assessment_vali_m2_')
                                            }
                                        })} onChange={(e) => { setAreaAssesement(e.target.value); }} value={area_assessment} type="text" className="form-control" placeholder={t('_sa_area_of_assessment_place_')} />
                                        <span className="add-on max-chr-counter chrchk-area_assessment" data-ele="area_assessment" data-limit="80" data-showcounter="true"></span>
                                        <span className="text-danger">
                                            {errors.area_assessment && errors.area_assessment.type === "required" && t('_sa_area_of_assessment_vali_m3_')}
                                            {errors.area_assessment && errors.area_assessment.type !== "required" && errors.area_assessment.message}
                                        </span>
                                    </div>
                                    {
                                        (type_assessment === 'A' && type_assessment !== '')
                                        &&
                                        <div className="form-group">
                                            <label>{t('_sa_eligibility_type_')}</label>
                                            <select {...register("eligibility")}>
                                                <option value="course">{t('_sa_eligibility_type_opt1_')} </option>
                                                <option value="course">{t('_sa_eligibility_type_opt2_')} </option>
                                            </select>
                                            <span className="text-danger">
                                                {errors.eligibility && errors.eligibility.type === "required" && t('_sa_eligibility_type_vali_m1_')}
                                                {errors.eligibility && errors.eligibility.type !== "required" && errors.eligibility.message}
                                            </span>
                                        </div>
                                    }
                                    <div className="form-group">
                                        <label>{t('_sa_noofques_label_')} </label>
                                        <select {...register("no_questions")} onChange={(e) => { setNumberofquestions(e.target.value); }}>
                                            {no_questions_options}
                                        </select>
                                        <span className="add-on max-chr-counter chrchk-no_questions" data-ele="no_questions" data-limit="80" data-showcounter="false"></span>
                                        <span className="text-danger">
                                            {errors.no_questions && errors.no_questions.type === "required" && t('_sa_noofques_vali_m1_')}
                                            {errors.no_questions && errors.no_questions.type !== "required" && errors.no_questions.message}
                                        </span>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={(e) => { cancelPopup(); }} type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">{t('_cancel_')} </button>
                                    <button onClick={(e) => { generateQuiz(); }} disabled={processing} type="submit" className="btn btn-circle btn-md submit-pink-btn"> {processing ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>{t('_sa_generate_quiz_')}</>} </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a id="modalTogglerAssesement" data-toggle="modal" data-target="#assesementModal">&nbsp;</a>
            {
                tour_steps.length > 0
                &&
                <Joyride
                    steps={tour_steps}
                    disableCloseOnEsc={true}
                    disableOverlayClose={true}
                    showSkipButton={true}
                    continuous={true}
                    hideCloseButton={true}
                    disableScrolling={true}
                    styles={{ options: { width: 500, zIndex: 5000, primaryColor: '#31314c', textColor: '#ef4a45', overlayColor: 'rgb(2 1 0 / 69%)', } }}
                    locale={{ back: t('_tour_back_'), close: t('_tour_close_'), last: t('_tour_last_'), next: t('_tour_next_'), open: t('_tour_open_dialog_'), skip: t('_tour_skip_') }}
                />
            }
        </>
    )
}

export default SelfAssessment;
