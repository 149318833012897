import { useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE } from "../../../constants/global";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import { useTranslation } from "react-i18next";

const CardPublished = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const [course, setCourse] = useState(props.course);
    const { t } = useTranslation();

    const ConfirmDelete = (id) => {
        SwalBox.fire({
            title: t('_v_delete_confirm_'),
            text: t('_couseiteach_del_alert_m1_'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_lp_no_')
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCourses(id);
            }
        });
    }

    const deleteCourses = async (id) => {
        const response = await fetch(`${API_BASE}delete-course/${id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status && data.data.deleted) {
            Toaster.success(data.message);
            setCourse('');
            props.setTotalCountView(props.total_count_view - 1);
            props.setPublished(props.published - 1);
        }
    }

    const ConfirmUnpublish = (id) => {
        SwalBox.fire({
            title: t('_v_delete_confirm_'),
            text: t('_couseiteach_del_alert_m2_'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_cancel_')
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUnpublish(id);
            }
        });
    }

    const deleteUnpublish = async (id) => {
        const response = await fetch(`${API_BASE}change-publish-status/${id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();


        if (data.status && data.data.changed) {
            Toaster.success(data.message);
            setCourse('');
            props.setTotalCountView(props.total_count_view - 1);
            props.setPublished(props.published - 1);
            props.setDrafted(props.drafted + 1);
            //console.log(props.drafted);
        }
    }

    const ConfirmPrivacy = (id) => {
        SwalBox.fire({
            title: t('_v_delete_confirm_'),
            text: t('_couseiteach_del_alert_m3_'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_cancel_')
        }).then((result) => {
            if (result.isConfirmed) {
                changePrivacy(id);
            }
        });
    }

    const changePrivacy = async (id) => {
        const response = await fetch(`${API_BASE}change-public-status/${id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();


        if (data.status && data.data.changed) {
            Toaster.success(data.message);
            setCourse(data.data.course);
        }
    }

    return (
        <>
            {
                course !== ''
                &&
                <div className="col-md-6 col-lg-6">
                    <div className="blog-card-detail card_newbox">
                        <Link className="pos_link" to={`/dashboard/tutor/manage-course/${course.id}`}></Link>
                        <div className="custom-card-img">
                            {/*
                            <a className="ellipsbtn" id={`MaterialsMenuButton${course.id}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby={`MaterialsMenuButton${course.id}`}>
                                <a onClick={(e) => { ConfirmUnpublish(course.id); }} className="dropdown-item">Unpublish Course</a>
                                <a onClick={(e) => { ConfirmPrivacy(course.id); }} className="dropdown-item">Make it {(course.course_privacy === 'Public') ? 'Private' : 'Public'}</a>
                                <a onClick={(e) => { ConfirmDelete(course.id); }} className="dropdown-item">Delete Course</a>
                            </div>
                            */}

                            {
                                course?.badge
                                &&
                                <div className="badges">
                                    {course?.badge === 'Highest Rated' ? <span className="highest">{t('_coursedetail_sort_course_highrate_')} </span> : ''}
                                    {course?.badge === 'Bestseller' ? <span className="selling">{t('_coursedetail_sort_course_selling_')} </span> : ''}
                                    {course?.badge === 'Trending' ? <span className="tranding">{t('_coursedetail_sort_course_trending_')} </span> : ''}
                                </div>
                            }
                            <img src={course.image} className="card-img-top" alt={course.title} />
                        </div>
                        <div className="blog-card-inner ek_mb50">
                            <h5 className="ek_h5inner">{course.title}</h5>
                            <a className="ekellipsbtn" id={`MaterialsMenuButton${course.id}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby={`MaterialsMenuButton${course.id}`}>
                                <a onClick={(e) => { ConfirmUnpublish(course.id); }} className="dropdown-item">{t('_couseiteach_card_opt1_')} </a>
                                <a onClick={(e) => { ConfirmPrivacy(course.id); }} className="dropdown-item">{t('_couseiteach_card_opt2_')} {(course.course_privacy === 'Public') ? t('_lcform_privacy_opt3_') : t('_lcform_privacy_opt2_')}</a>
                                {/* <a onClick={(e) => { ConfirmDelete(course.id); }} className="dropdown-item">Delete Course</a> */}
                            </div>
                            <ul className="course_tabs_sub ekellipsdd">
                                {
                                    course.status === 'Active'
                                    &&
                                    <>
                                        <li><a><i className="fas fa-list"></i><span>{course.category.title}</span></a></li>
                                        <li><a><i className="far fa-user"></i><span>{course.mode_of_learning}</span></a></li>
                                        {/* <li><a><i className="fas fa-star"></i><span>{course.average_rating === null ? 'No' : course.average_rating} Rating</span><span>{course.course_privacy}</span></a></li> */}
                                    </>
                                }
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default CardPublished;
