import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Links = (props) => {
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const is_tutor = props?.is_tutor;
    const { t } = useTranslation();

    return (
        <>
            <NavLink className={'nav-link '} id="v-pills-general-tab" to={'/profile/general'} role="tab" aria-controls="v-pills-general" aria-selected="true"><i className="far fa-user"></i>{t('_l_general_profile_')}</NavLink>

            {
                user?.is_corporate === 1
                    ?
                    <>
                        <NavLink className={'nav-link '} id="v-pills-profile-picture-tab" to={'/profile/profile-picture'} role="tab" aria-controls="v-pills-profile-picture" aria-selected="false"><i className="far fa-user-circle"></i>{t('_l_company_logo_')}</NavLink>
                    </>
                    :
                    <>
                        <NavLink className={'nav-link '} id="v-pills-profile-picture-tab" to={'/profile/profile-picture'} role="tab" aria-controls="v-pills-profile-picture" aria-selected="false"><i className="far fa-user-circle"></i>{t('_l_profile_picture_')}</NavLink>
                        {
                            user?.career_type === 'Working Professional'
                                ?
                                <NavLink className={'nav-link '} id="v-pills-experience-tab" to={'/profile/experience'} role="tab" aria-controls="v-pills-experience" aria-selected="false"><i className="fab fa-black-tie"></i>{t('_l_experience_')}</NavLink>
                                :
                                null
                        }
                        <NavLink className={'nav-link '} id="v-pills-education-tab" to={'/profile/education'} role="tab" aria-controls="v-pills-education" aria-selected="false"><i className="fas fa-graduation-cap"></i>{t('_l_education_')}</NavLink>

                        {/* <NavLink className={'nav-link '} id="v-pills-education-tab" to={'/profile/resumebuilder'} role="tab" aria-controls="v-pills-education" aria-selected="false"><i className="fa-solid fa-file"></i>{t('_l_resume_builder_')}</NavLink> */}

                        <NavLink className={'nav-link '} id="v-pills-ai-tab" to={'/profile/responsible-ai'} role="tab" aria-controls="v-pills-ai" aria-selected="false"><i className="fas fa-brain"></i>{t('_l_responsible_ai_')}</NavLink>

                        <NavLink className={'nav-link '} id="v-pills-learning-tab" to={'/profile/learning-preferences'} role="tab" aria-controls="v-pills-learning" aria-selected="false"><i className="far fa-id-badge"></i>{t('_l_learning_preference_')}</NavLink>
                        {
                            (is_tutor !== "undefined" && is_tutor === 1)
                            &&
                            <NavLink className={'nav-link '} id="v-pills-mentor-tab" to={'/profile/mentor-preferences'} role="tab" aria-controls="v-pills-mentor" aria-selected="false"><i className="fas fa-id-card-alt"></i>{t('_l_mentor_preference_')}</NavLink>
                        }
                    </>
            }

            <NavLink className={'nav-link '} id="v-pills-ai-tab" to={'/profile/notification-preferences'} role="tab" aria-controls="v-pills-ai" aria-selected="false"><i className="fas fa-bell"></i>{t('_l_notification_preference_')}</NavLink>

            <NavLink className={'nav-link '} id="v-pills-kyc-tab" to={'/profile/e-kyc'} role="tab" aria-controls="v-pills-kyc" aria-selected="false"><i className="fas fa-id-badge"></i>{t('_l_ekyc2_')}</NavLink>

            <NavLink className={'nav-link '} id="v-pills-account-tab" to={'/profile/security'} role="tab" aria-controls="v-pills-account" aria-selected="false"><i className="fas fa-user-lock"></i>{t('_l_account_and_security_')}</NavLink>
        </>
    );
}

export default Links;
