import React, { useEffect, useRef, useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import { API_BASE } from "../constants/global";
import useEffectOnce from './useEffectOnce';
import { useTranslation } from "react-i18next";

const ChatUserBox = (props) => {
    // const key = props.key;
    const user_id = props.user_id;
    const user_name = props.user_name;
    const is_admin = props.is_admin;
    const socket = props.socket;
    const room = props.join_room;
    const sender_id = props.sender_id;
    const encrypted_id = props.encrypted_id;
    const receiver_image = props.userimage;
    const removeChatBox = props.removeChatBox;
    const cross_button_hide = props.cross_button_hide;
    socket.emit("join_room", room);
    const [currentMessage, setCurrentMessage] = useState("");
    const [messageList, setMessageList] = useState([]);
    const _token = localStorage.getItem('lmstoken');

    const messageEl = useRef(null);
    const chatInputRef = useRef();

    const { t } = useTranslation();

    useEffect(() => {
        if (messageEl) {
            messageEl.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, []);


    //---Daksh Dec-12-2022 for chat pop-up---//
    //old code starts
    //useEffectOnce(() => {
    //    socket.on("receive_message", (data) => {
    //        getAllChats();
    //    });
    //    getAllChats();
    //}, [socket,messageList]);
    //old code ends

    //new code starts
    useEffectOnce(() => {
        socket.on("receive_message", (data) => {
            if (data != null) {
                var _room_id = data.room;
                var _s_id = data.author;
                var _r_id_info = _room_id.replace(_s_id, '');
                var _r_id = _r_id_info.replace(/\D/g, '');
                var _current_u_info = JSON.parse(localStorage.getItem('lmsuser'));
                var _current_u_id = _current_u_info.id
                if (parseInt(_r_id) == parseInt(_current_u_id)) {
                    var element = document.getElementById('popup_box_' + _room_id);
                    element?.classList.remove("h_chatbox");
                    element?.classList.add("s_chatbox");
                }
            }
            getAllChats();
        });
        getAllChats();
    }, [socket, messageList]);
    //new code ends
    //---Daksh Dec-12-2022 for chat pop-up---//

    // SEND MESSAGE TO USER
    const sendMessage = async () => {
        if (currentMessage.trim() != "" || currentMessage.trim().length > 0) {
            const time = new Date(Date.now()).getHours() + ":" + new Date(Date.now()).getMinutes();
            setCurrentMessage('');
            const formData = new FormData();
            formData.append('sender_id', sender_id);
            formData.append('receiver_id', encrypted_id);
            formData.append('chat', currentMessage);
            formData.append('time', time);

            const response = await fetch(`${API_BASE}send-chat-message`, {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + _token
                },
                body: formData
            });
            const data = await response.json();
            if (data.status) {
                const messageData = {
                    room: room,
                    author: sender_id,
                    message: currentMessage,
                    time: data.data.time,
                };
                await socket.emit("send_message", messageData);
            } else {
            }
            getAllChats();
        }
    };

    // GET ALL CHATS OF PERTICULAR USER
    const getAllChats = async () => {
        const formData = new FormData();
        formData.append('sender_id', sender_id);
        formData.append('receiver_id', user_id);
        const response = await fetch(API_BASE + 'get-chat-message/' + encrypted_id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setMessageList(data.data);
        } else {
            setMessageList('');
        }
    };

    //---Daksh Dec-12-2022 for chat pop-up---//
    const removeChatBoxThis = (id) => {
        //old code starts
        //	props.setUserChatBox(props.chatBox.filter(item => item.key != id));
        //old code ends
        //new code starts
        var element = document.getElementById('popup_box_' + id);
        element?.classList.add("h_chatbox");
        element?.classList.remove("s_chatbox");
        element.style.display = (element.style.display != 'none' ? 'none' : 'none');
        //new code ends
    };
    //---Daksh Dec-12-2022 for chat pop-up---//

    const handlechatkeypress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            sendMessage();
        }
    }

    return (
        /*{<div className={"msg_box user_box_"+room} key={room}>}*/
        <div className={"h_chatbox msg_box user_box_" + room} key={room} id={"popup_box_" + room}>
            <div className="msg_head">
                <div className="prof_img">
                    <img src={receiver_image} alt="avatar" />
                </div>
                {/* {is_admin == 1 ? <span>Admin</span> : ""} */}
                <h3>{user_name} </h3>
                {cross_button_hide == false ? <div className="close_chat" id={room} onClick={(e) => { removeChatBoxThis(room); }} >x</div> : ""}
            </div>
            <div className="msg_wrap">
                <div className="msg_body" ref={messageEl}>
                    {Object.keys(messageList).map((keyName, i) => (
                        <div key={i}>
                            <div key={keyName} className="chatDate"><span>{keyName}</span></div>
                            {
                                Object.keys(messageList[keyName]).map((chatK, index) => {
                                    return (
                                        <div data-userid={sender_id} key={i + index} data-author={messageList[keyName][chatK].author} className={parseInt(sender_id) === parseInt(messageList[keyName][chatK].author) ? "msg_b" : "msg_a"}>{messageList[keyName][chatK].message}<span>{messageList[keyName][chatK].time}</span></div>
                                    );

                                })
                            }
                        </div>
                    ))}
                </div>
                <div className="msg_footer">
                    <div className="attach_wrapper">

                        <textarea className="msg_input" value={currentMessage} placeholder={t('_chat_typemessage_')} onChange={(event) => { setCurrentMessage(event.target.value); }} onKeyPress={handlechatkeypress}></textarea>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatUserBox;
