import { useLayoutEffect, useEffect } from "react";
import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { API_BASE } from "../constants/global";
import { useTranslation } from "react-i18next";

const Links = (props) => {
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem('lmsuser'));


    const ek_manage_employees = localStorage.getItem('manage_employees');
    const ek_manage_roles = localStorage.getItem('manage_roles');
    const ek_manage_reports = localStorage.getItem('manage_reports');
    const ek_manage_courses = localStorage.getItem('manage_courses');
    const ek_manage_vacancies = localStorage.getItem('manage_vacancies');
    const ek_manage_circles = localStorage.getItem('manage_circles');
    const { t } = useTranslation();


    const [lcActive, setLcActive] = useState('');
    const [dsActive, setDsActive] = useState('');
    const [scActive, setScActive] = useState('');
    const [tcActive, setTcActive] = useState('');
    const [rpActive, setRpActive] = useState('');
    const _token = localStorage.getItem('lmstoken');
    const [showCareerAdvice, setShowCareerAdvice] = useState(false);
    const [showSelfAssesement, setShowSelfAssesement] = useState(false);

    useLayoutEffect(() => {
        setLcActive(window.location.href.indexOf("create-learning-circle") > -1 || window.location.href.indexOf("my-circles") > -1 || window.location.href.indexOf("edit-learning-circle") > -1 ? 'active' : '');
        setScActive(window.location.href.indexOf("completed-courses") > -1 || window.location.href.indexOf("course-certificate") > -1 ? 'active' : '');
        setTcActive(window.location.href.indexOf("draft-courses") > -1 || window.location.href.indexOf("create-course") > -1 ? 'active' : '' || window.location.href.indexOf("edit-course") > -1 ? 'active' : '' || window.location.href.indexOf("manage-course") > -1 ? 'active' : '');
        setDsActive(window.location.href.indexOf("announcement-details") > -1 ? 'active' : '');
        setRpActive(window.location.href.indexOf("tutor-reports") > -1 ? 'active' : '');
    }, [location.pathname]);

    useEffect(() => {
        getAiEnabledPreference();
    }, []);

    const getAiEnabledPreference = async () => {
        let formData = {};
        const _lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
        const _lms_learner_id = _lmsuser['id'];
        formData.user_id = _lms_learner_id;
        formData.action = "fetch";
        const response = await fetch(`${API_BASE}get-responsible-ai`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(formData)
        });
        const data = await response.json();
        if (data.status) {
            if (data.data.career_advice == "TRUE") { setShowCareerAdvice(true); }
            if (data.data.self_assesement == "TRUE") { setShowSelfAssesement(true); }
            //console.info(showCareerAdvice);
        } else {
        }
    }

    return (
        <>
            <NavLink className={'tour-lr-step-03 tour-cr-step-03 nav-link ' + (dsActive)} to={'/dashboard/home'}><i className="fas fa-home"></i> {t('_ud_l_home_')}</NavLink>
            <NavLink className={'tour-lr-step-04 tour-cr-step-04 nav-link ' + (lcActive)} to={'/dashboard/my-circles/i-have-created'}><i className="fas fa-users"></i>{t('_ud_l_lc_')}</NavLink>

            {
                user?.is_corporate !== 1
                &&
                <NavLink className={'tour-lr-step-05 nav-link  ' + (scActive)} to={'/dashboard/student/ongoing-courses'}><i className="fas fa-user-graduate"></i>{t('_ud_l_coulearn_')}</NavLink>
            }
            {
                user?.is_tutor === 1
                &&
                <>
                    {
                        user?.is_corporate === 1
                            ?
                            <NavLink className={'tour-cr-step-05 nav-link ' + (tcActive)} to={'/dashboard/tutor/published-courses'}><i className="fas fa-book"></i>{t('_ud_l_courses_')} </NavLink>
                            :
                            <NavLink className={'tour-lr-step-06 nav-link ' + (tcActive)} to={'/dashboard/tutor/published-courses'}><i className="fas fa-user-tie"></i>{t('_ud_l_couteach_')}</NavLink>
                    }
                    <NavLink className={'nav-link'} to={'/dashboard/tutor/quizbank'}>
                        <i className="fa-solid fa-circle-question"></i>
                        {t('_ud_l_quiz_')} </NavLink>

                </>
            }

            {ek_manage_roles == 'YES' && <NavLink className={'tour-cr-step-08 nav-link '} to={'/dashboard/roles'}><i className="fas fa-check-square"></i>{t('_ud_l_roles_')}</NavLink>}
            {ek_manage_vacancies == 'YES' && <NavLink className={'tour-cr-step-07 nav-link '} to={'/dashboard/vacancy'}><i className="fas fa-bullhorn"></i>{t('_ud_l_vac_')}</NavLink>}
            {ek_manage_employees == 'YES' && <NavLink className={'tour-cr-step-08 nav-link '} to={'/dashboard/employee'}><i className="fas fa-users"></i>{t('_ud_l_emp_')} </NavLink>}

            {
                /*

                user?.is_corporate === 1
                ?
                <NavLink className={'nav-link ' + (rpActive)} to={'/dashboard/reports/tutor-reports'}><i className="fas fa-dashboard"></i>Reports </NavLink>
                :
                <NavLink className={'nav-link ' + (rpActive)} to={'/dashboard/reports/student-reports'}><i className="fas fa-dashboard"></i>Reports </NavLink>
                */
            }
            {
                user?.is_corporate === 1 || ek_manage_reports == 'YES'
                    ?
                    <NavLink className={'tour-lr-step-07 tour-cr-step-06 nav-link ' + (rpActive)} to={'/dashboard/reports/corporate-report'}><i className="fas fa-dashboard"></i>{t('_ud_l_report_')} </NavLink>
                    :
                    <NavLink className={'tour-lr-step-07 tour-cr-step-06 nav-link ' + (rpActive)} to={'/dashboard/reports'}><i className="fas fa-dashboard"></i>{t('_ud_l_report_')} </NavLink>
            }
            {
                user?.is_corporate != 1 && showCareerAdvice == true
                &&
                <>
                    <NavLink className={'tour-lr-step-08 nav-link '} to={'/dashboard/career-advice'}><i className="fa fa-handshake" aria-hidden="true"></i>{t('_ud_l_ca_')} </NavLink>
                </>
            }

            {
                user?.is_corporate != 1 && showSelfAssesement == true
                &&
                <>
                    <NavLink className={'tour-lr-step-09 nav-link '} to={'/dashboard/self-assessment'}><i className="fa fa-clipboard-question" aria-hidden="true"></i>{t('_ud_l_selfassess_')}</NavLink>
                </>
            }
            <NavLink className={'tour-lr-step-10 tour-cr-step-09 nav-link '} to={'/dashboard/notification'}><i className="fas fa-bell"></i>{t('_ud_l_noti_')}<span className="ml-3 bg-dark">{props.notificationCount > 0 && props.notificationCount}</span></NavLink>
            <a className={'tour-lr-step-11 tour-cr-step-10 nav-link '} href={'/dashboard/my-chat'}><i className="fas fa-comment"></i>{t('_ud_l_chat_')} </a>
        </>
    );
}

export default Links;
