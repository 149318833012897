import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import TinyMCEEditor from "../../entity/TinyMCEEditor";
import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";

const Notebook = ({ course }) => {
    const { _slug } = useParams();
    const { _slide } = useParams();
    const _token = localStorage.getItem('lmstoken');
    const [notes, setNotes] = useState('');
    const [new_notes, setNewNotes] = useState('');

    const [add_note, setAddNote] = useState('');
    const [processing, setProcessing] = useState(false);
    const [get_from, setGetFrom] = useState('');
    const [sort_by, setSortBy] = useState('');
    const [char_count, setCharCount] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const { register, control, formState: { errors }, getValues, setError, clearErrors } = useForm();
    const { t } = useTranslation();

    useEffect(() => {
        fetchNotes();
    }, []);

    const fetchNotes = async (from = '', order = '') => {
        const response = await fetch(`${API_BASE}get-course-notes/${_slug}?slide=${from}&order=${order}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();

        if (data.status) {
            setNotes(data.data.notes);
            setNewNotes('');
        } else {
            setNotes('');
        }
    }

    const setCountChars = (html) => {
        let text = html.replace(/(<([^>]+)>|&nbsp;)/gi, "");
        setCharCount(text.length);
        if (char_count >= 1000) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }

    const saveNewNote = async () => {
        if (char_count > 1000) {
            Toaster.error(t('_learncourse_notebook_note_vali_m1_'));
            return false;
        }
        if (add_note.trim() !== '' && add_note.trim().length > 0) {
            var formData = new FormData();
            formData.append('note', add_note);

            setProcessing(true);
            const response = await fetch(`${API_BASE}create-note/${course.id}/${_slide}`, {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + _token
                },
                body: formData
            });

            const data = await response.json();
            if (data.status) {
                setNewNotes([data.data.note, ...new_notes]);
                setAddNote('');
                fetchNotes();
            } else {
                Toaster.error(data.message);
            }

            setProcessing(false);
        }
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-note" role="tabpanel" aria-labelledby="pills-note-tab">
                <div className="blog-card">
                    <div className="row justify-content-lg-center">
                        <div className="col-md-12 col-lg-8">
                            <div className="form-group mb-5">
                                <div className="response">
                                    <Controller
                                        control={control}
                                        name="slide_desc"
                                        rules={{ required: true, message: "asfsf" }}
                                        render={({ field: { onChange, value, name, ref } }) => (
                                            <TinyMCEEditor onChange={(e) => { setAddNote(e); setCountChars(e); }} value={add_note} placeholder={t('_learncourse_notebook_note_vali_m2_')} />
                                        )}
                                    />
                                </div>
                                <select onChange={(e) => { setGetFrom(e.target.value); fetchNotes(e.target.value, sort_by); }} value={get_from}>
                                    <option value=''>{t('_learncourse_notebook_note_opt1_')} </option>
                                    <option value={_slide}>{t('_learncourse_notebook_note_opt2_')} </option>
                                </select>
                                <select onChange={(e) => { setSortBy(e.target.value); fetchNotes(get_from, e.target.value); }} value={sort_by}>
                                    <option value=''>{t('_learncourse_notebook_note_opt3_')} </option>
                                    <option value='Oldest'>{t('_learncourse_notebook_note_opt4_')} </option>
                                </select>
                                <small className={`ml-5 ${(disabled) ? 'text-danger' : 'text-muted'}`}><strong>{t('_learncourse_notebook_note_opt5_')} : {((1000 - char_count) >= 0) ? 1000 - char_count : 0}</strong></small>
                                {
                                    !processing
                                    &&
                                    <a onClick={(e) => { saveNewNote(); }} className="dlt_btn ek-lrs-course-experienced text-success" data-ek-lrs-url={'/learn-course/' + course.slug} data-ek-lrs-title={course.title}><i className="fas fa-check"></i></a>
                                }
                                {
                                    processing
                                    &&
                                    <a className="dlt_btn"><i className="fas fa-spinner fa-spin"></i></a>
                                }
                            </div>

                            {
                                notes !== ''
                                &&
                                notes.map((note, index) =>
                                    <NoteBox key={index} index={index} objnote={note} course={course} slide={_slide} refresh={fetchNotes} />
                                )
                            }

                            {
                                new_notes !== '' || notes === ''
                                &&
                                <p>{t('_learncourse_notebook_nonote_')} </p>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Notebook;

const NoteBox = ({ index, objnote, course, slide, refresh }) => {
    const [note, setNote] = useState(objnote);
    const [editing, setEditing] = useState(false);
    const [new_note, setNewNote] = useState('');
    const [processing, setProcessing] = useState(false);
    const _token = localStorage.getItem('lmstoken');
    const { register, control, formState: { errors }, getValues, setError, clearErrors } = useForm();
    const { t } = useTranslation();

    const saveNote = async () => {
        if (new_note.trim() !== '' && new_note.trim().length > 0) {
            var formData = new FormData();
            formData.append('note', new_note);

            setProcessing(true);
            const response = await fetch(`${API_BASE}create-note/${course.id}/${slide}/${objnote.id}`, {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + _token
                },
                body: formData
            });

            const data = await response.json();
            if (data.status) {
                setNote(data.data.note);
            } else {
                Toaster.error(data.message);
            }

            setProcessing(false);
            setEditing(false);
            refresh();
        }
    }

    const ConfirmDelete = (id) => {
        SwalBox.fire({
            title: t('_v_delete_confirm_'),
            text: t('_learncourse_notebook_sweetalert_m2_'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_lp_no_')
        }).then((result) => {
            if (result.isConfirmed) {
                deleteNote(id);
            }
        });
    }

    const deleteNote = async (id) => {
        const response = await fetch(`${API_BASE}delete-note/${id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setNote('');
            refresh();
        } else {
            Toaster.error(data.message);
        }
    }

    return (
        <>
            {
                objnote !== ''
                &&
                <div className="question_box">
                    <span className="new_tag">{index + 1}</span>
                    {
                        !editing
                        &&
                        <>
                            <p>
                                {objnote.slide.section !== '' ? <strong>{objnote.slide.section} - </strong> : ''} {objnote?.slide?.title}
                                <a onClick={(e) => { setEditing(true); setNewNote(objnote.note); }} className="edit_link"><i className="fas fa-pencil-alt"></i></a>
                                <a onClick={(e) => { ConfirmDelete(objnote.id); }} className="dlt_link"><i className="fas fa-trash-alt"></i></a>
                            </p>
                            <div className="note_box">
                                <p>{parse(objnote?.note ? objnote?.note : '')}</p>
                            </div>
                        </>
                    }

                    {
                        editing
                        &&
                        <>
                            <div className="form-group">

                                <div className="response">
                                    <Controller
                                        control={control}
                                        disabled={processing}
                                        name="slide_desc"
                                        rules={{ required: true, message: "asfsf" }}
                                        render={({ field: { onChange, value, name, ref } }) => (
                                            <TinyMCEEditor onChange={(e) => { setNewNote(e) }} value={new_note} placeholder={t('_learncourse_notebook_edit_note_')} />
                                        )}
                                    />

                                </div>
                                <a onClick={(e) => { setEditing(false); }} className="dlt_btn"><i className="fas fa-times"></i></a>
                                <a onClick={(e) => { saveNote(); }} className="dlt_btn text-success mr-1"><i className="fas fa-check"></i></a>
                            </div>
                        </>

                    }

                </div>
            }
        </>
    )
}
