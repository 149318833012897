import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import ChatUserBox from './chat_user_box';
import useEffectOnce from './useEffectOnce';
import './chat.css';
import { API_BASE, socket } from "../constants/global";
import authContext from '../context/auth/authContext';
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Chat = () => {
	const _token = localStorage.getItem('lmstoken');
	const userData = JSON.parse(localStorage.getItem('lmsuser'));
	const learningCircleUniqueId = localStorage.getItem('learningCircleUniqueId');
	const cntxAuth = useContext(authContext);
	const [chatBox, setUserChatBox] = useState([]);
	const [chatUserData, setChatUserData] = useState([]);
	const [onlineUserIds, setOnlineUser] = useState([]);
	const [isActive, setActive] = useState("false");
	const [searchField, setSearchField] = useState("");
	const crossBtnHide = false;
	const [allowChat, setAllowChat] = useState(false);
	const location = useLocation();
	const { t } = useTranslation();

	if (userData) {
		socket.emit('login', { userId: userData.id });
	}

	useLayoutEffect(() => {
		getChatUserData();
		let _current_location = location.pathname;
		if (_current_location.includes('learn-course/') == false && _current_location.includes('view-learning-circle/') == false && _current_location.includes('my-chat') == false) {
			setAllowChat(true);
		} else {
			setAllowChat(false);
		}
	}, [location.pathname]);

	/* let url_parameter = {};
		const currLocation = window.location.href;
	console.log("currLocation",currLocation); */


	//---Daksh Dec-12-2022 for chat pop-up---//
	// On Page load - GET USER CHAT BOX AND APPEND ON ARRAY
	const getUserChatBoxInit = (user_id, encrypted_id, userimage, user_name, is_admin, socket) => {
		var unique_id = (parseInt(userData.id) > parseInt(user_id)) ? user_id + '_' + userData.id : userData.id + '_' + user_id;
		const isFound = chatBox.some(element => {
			if (element.key == unique_id) {
				return true;
			}
			return false;
		});
		if (!isFound) {
			setUserChatBox([
				{
					key: unique_id,
					sender_id: userData.id,
					userimage: userimage,
					encrypted_id: encrypted_id,
					user_id: user_id,
					user_name: user_name,
					is_admin: is_admin,
					socket: socket,
					join_room: unique_id,
					unique_id: unique_id,
					cross_button_hide: crossBtnHide,
					value: <React.Fragment key={unique_id}><ChatUserBox key={unique_id} sender_id={userData.id} userimage={userimage} encrypted_id={encrypted_id} user_id={user_id} removeChatBox={removeChatBox} user_name={user_name} is_admin={is_admin} socket={socket} join_room={unique_id} cross_button_hide={crossBtnHide} /></React.Fragment>
				}, ...chatBox
			]);
			var element = document.getElementById('popup_box_' + unique_id);
			element?.classList.add("h_chatbox");
		}
	}
	//---Daksh Dec-12-2022 for chat pop-up---//


	// GET USER CHAT BOX AND APPEND ON ARRAY
	const getUserChatBox = (user_id, encrypted_id, userimage, user_name, is_admin, socket) => {
		var unique_id = (parseInt(userData.id) > parseInt(user_id)) ? user_id + '_' + userData.id : userData.id + '_' + user_id;
		const isFound = chatBox.some(element => {
			if (element.key == unique_id) {
				return true;
			}
			return false;
		});
		if (!isFound) {
			setUserChatBox([
				{
					key: unique_id,
					sender_id: userData.id,
					userimage: userimage,
					encrypted_id: encrypted_id,
					user_id: user_id,
					user_name: user_name,
					is_admin: is_admin,
					socket: socket,
					join_room: unique_id,
					unique_id: unique_id,
					cross_button_hide: crossBtnHide,
					value: <React.Fragment key={unique_id}><ChatUserBox key={unique_id} sender_id={userData.id} userimage={userimage} encrypted_id={encrypted_id} user_id={user_id} removeChatBox={removeChatBox} user_name={user_name} is_admin={is_admin} socket={socket} join_room={unique_id} cross_button_hide={crossBtnHide} /></React.Fragment>
				}, ...chatBox
			]);
		}
		//---Daksh Dec-12-2022 for chat pop-up---//
		var element = document.getElementById('popup_box_' + unique_id);
		if (element != null && typeof (element) != 'undefined') {
			element.style.display = (element.style.display != 'block' ? 'block' : 'block');
		} else {
			try {
				element.click();
				setTimeout(() => {
					element.click();
				}, 1000);
			} catch (err) { }
		}
		//---Daksh Dec-12-2022 for chat pop-up---//
	}

	//---Daksh Dec-12-2022 for chat pop-up---//
	// REMOVE OPENED CHAT BOX
	const removeChatBox = (e) => {
		const id = e.target.getAttribute("id");
		//old code starts
		//setUserChatBox(chatBox.filter(item => item.key != id));
		//old code ends
		//new code starts
		var element = document.getElementById('popup_box_' + id);
		element.style.display = (element.style.display != 'none' ? 'none' : 'none');
		//new code ends
	};
	//---Daksh Dec-12-2022 for chat pop-up---//

	socket.once("connect", () => {
		socket.once("online", (userId) => {
			// update online status
			setOnlineUser(userId);
		});

		// USER IS OFFLINE
		socket.once("offline", (userId) => {
			// update offline status
			setOnlineUser(userId);
		});
	});

	// GET ALL CHAT USERS
	const getChatUserData = async (param = "") => {
		if (param != "") {
			param = '/' + param;
		}
		const formData = new FormData();
		if (learningCircleUniqueId != null) {
			formData.append('learningCircleUniqueId', learningCircleUniqueId);
		}
		const response = await fetch(API_BASE + 'get-all-chat-users' + param, {
			method: 'POST',
			headers: {
				"Authorization": "Bearer " + _token
			},
			body: formData
		});

		const data = await response.json();
		if (data.status) {
			setChatUserData(data.data);
		} else {
			setChatUserData([]);
		}
	}

	// HANDLE TOOGLE FOT CHATBOX
	const handleToggle = () => {
		setActive(!isActive);
		socket.emit('login', { userId: userData.id });
	};

	const handleSearchChange = e => {
		setSearchField(e.target.value);
		getChatUserData(e.target.value);
	};


	return (
		cntxAuth.isLogged && allowChat == true ?
			<>
				<div className="container">
					<div className="people-list" id="people-list">
						<div className="chat_stack_head" onClick={handleToggle}>
							<h4>{t('_dh_chat_head_')} </h4>
							<a className="closebox"><i className="fas fa-commenting"></i></a>
						</div>
						<div className={"chat_stack_body" + (isActive ? ' hide_chatbox' : ' show_chatbox')}>
							<div className="search">
								<input type="text" placeholder="Search" onChange={handleSearchChange} />
								<i className="fa fa-search"></i>
							</div>
							<ul className="list">
								{(typeof chatUserData !== 'undefined' && chatUserData.length > 0) ?
									chatUserData.map(userdata => {
										return (
											<li className="clearfix" key={userdata.id}>
												<a className="link" onLoad={(e) => { getUserChatBoxInit(userdata.id, userdata.encrypted_id, userdata.image, userdata.name, userdata.is_admin, socket) }} onClick={(e) => { getUserChatBox(userdata.id, userdata.encrypted_id, userdata.image, userdata.name, userdata.is_admin, socket) }}>
													<div className="prof_pic">
														<img src={userdata.image} alt="avatar" />
													</div>
													<div className="about">
														<div className="name">{userdata.name} {userdata.is_admin == 1 ? <i className="fas fa-user-alt"></i> : ""}</div>
														<div className="status">
															{onlineUserIds.includes(userdata.id) ? <span><i className="fa fa-circle online"></i> online</span> : <span><i className="fa fa-circle offline"></i> offline</span>}
														</div>
													</div>
												</a>
											</li>
										);

									})
									:
									""
								}
							</ul>
						</div>
					</div>
					<div className="user_chat_box user_chat_box2">
						{chatBox.map(d => <React.Fragment key={d.unique_id}><ChatUserBox key={d.unique_id} setUserChatBox={setUserChatBox} chatBox={chatBox} sender_id={d.sender_id} userimage={d.userimage} encrypted_id={d.encrypted_id} user_id={d.user_id} removeChatBox={removeChatBox} user_name={d.user_name} is_admin={d.is_admin} socket={d.socket} join_room={d.unique_id} cross_button_hide={d.cross_button_hide} /></React.Fragment>)}
					</div>
				</div>
			</>
			:
			''
	);
}

export default Chat;
