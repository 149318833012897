const aeJson = {
  "_username_": "اسم المستخدم",
  "_general_profile_": "الملف العام",
  "_phone_number_": "رقم التليفون",
  "_headline_": "العنوان",
  "_resource_id_": "معرف المورد",
  "_about_yourself_": "عرفنى بنفسك. أخبر الآخرين عن رحلتك المهنية وإنجازاتك هنا.",
  "_save_": "يحفظ",
  "_facebook_url_": "http://www.facebook.com/",
  "_twitter_url_": "http://www.twitter.com/",
  "_linkedin_url_": "http://www.linkedin.com/",
  "_profile_picture_": "الصوره الشخصيه",
  "_company_logo_": "شعار الشركة",
  "_l_general_profile_": "الملف العام",
  "_l_company_logo_": "شعار الشركة",
  "_l_profile_picture_": "الصوره الشخصيه",
  "_l_experience_": "خبرة",
  "_l_education_": "تعليم",
  "_l_resume_builder_": "استئناف البناء",
  "_l_responsible_ai_": "الذكاء الاصطناعي المسؤول",
  "_l_learning_preference_": "تفضيلات التعلم",
  "_l_mentor_preference_": "تفضيلات المرشد",
  "_l_notification_preference_": "تفضيلات الإخطار",
  "_l_ekyc_": "eKYC",
  "_l_account_and_security_": "الحساب والأمن",
  "_experience_": "الخبرة",
  "_e_title_": "العنوان",
  "_e_company_name_": "اسم الشركة",
  "_e_industry_": "الصناعة",
  "_e_start_date_": "تاريخ البدء",
  "_e_end_date_": "تاريخ الانتهاء",
  "_e_employment_type_": "نوع التوظيف",
  "_e_working_status_": "أنا أعمل حاليًا هنا",
  "_e_description_": "الوصف",
  "_e_internship_": "تدريب",
  "_e_part_time_": "دوام جزئي",
  "_e_full_time_": "دوام كامل",
  "_e_trainee_": "متدرب",
  "_e_self_employed_": "عمل حر",
  "_e_self_employed_": "عمل حر",
  "_e_freelance_": "عمل حر",
  "_e_add_experience_": "إضافة تجربة جديدة",
  "_e_education_": "التعليم",
  "_e_school_name_": "اسم المدرسة",
  "_e_degree_": "الدرجة",
  "_e_field_of_study_": "مجال الدراسة",
  "_e_add_new_education_": "إضافة تعليم جديد",
  "_e_grade_": "الدرجة",
  "_r_responsibleai_": "الذكاء الاصطناعي المسؤول",
  "_r_mes3_": "قم بإخفاء ما لا تحتاجه، وأظهره مرة أخرى عندما تفعل ذلك. استمتع بالتحكم الكامل!",
  "_r_mes2_": "تمكين أو تعطيل الوحدات لتخصيص عناصر القائمة الخاصة بك.",
  "_r_mes1_": "تخصيص تجربتك!",
  "_r_careeradvice_": "نصيحة مهنية",
  "_r_selfassessment_": "تقييم ذاتى",
  "_r_virtualtutor_": "المعلم الظاهري",
  "_r_suggestedvideos_": "الفيديوهات المقترحة",
  "_r_enabled_": "ممكّن",
  "_r_disabled_": "عاجز",
  "_lp_learning_preferences_": "تفضيلات التعلم",
  "_lp_industries_": "الصناعات",
  "_lp_area_": "منطقة",
  "_lp_specialization_": "تخصص",
  "_lp_interest_": "اهتمام",
  "_lp_topics_": "المواضيع التي ترغب في التعرف عليها",
  "_lp_check1_": "هل ترغب في الحصول على تنبيهات حول الدورات التي تهتم بها؟",
  "_lp_check2_": "هل ترغب في الحصول على تنبيهات حول دوائر التعلم التي قد تكون مهتمًا بها؟",
  "_lp_check3_": "هل ترغب في الحصول على تنبيهات حول فرص العمل التي قد تهمك؟",
  "_lp_que1_": "تلقي إشعارات واقتراحات البريد الإلكتروني لموضوعات الدورة المفضلة:",
  "_lp_que2_": "تلقي رسائل الدردشة من:",
  "_lp_que3_": "تمكين التشغيل التلقائي:",
  "_lp_que4_": "تمكين الإكمال التلقائي:",
  "_lp_yes_": "نعم",
  "_lp_no_": "لا",
  "_lp_mes_opt1_": "اتصالات من الدورة ودائرة التعلم",
  "_lp_mes_opt2_": "أي واحد",
  "_mp_topics_": "المواضيع التي ترغب في تدريسها",
  "_mp_email_": "تلقي إشعارات واقتراحات البريد الإلكتروني لموضوعات الدورة المفضلة:",
  "_mp_mentor_preference_": "تفضيلات المرشد",
  "_mp_error_email1_": "الرجاء تحديد زر الاختيار",
  "_mp_message_": "لماذا يجب أن يتعلم الطلاب منك؟",
  "_np_heading_": "هنا، يمكنك اختيار أنواع الإشعارات التي تريد تلقيها.",
  "_np_course_learn_": "الدورات التي أتعلمها",
  "_np_course_teach_": "الدورات التي أقوم بتدريسها",
  "_np_lc_": "دائرة التعلم",
  "_np_learn_op4_": "إشعار اجتماع واحد إلى واحد",
  "_np_learn_op3_": "إشعار الأسئلة والأجوبة",
  "_np_learn_op2_": "إشعار الاجتماع",
  "_np_learn_op1_": "إخطار المواد",
  "_np_teach_op1_": "إشعار مراجعة الدورة",
  "_np_teach_op2_": "إشعار الأسئلة والأجوبة",
  "_np_teach_op3_": "إشعار اجتماع واحد إلى واحد",
  "_np_lc_op1_": "مثل الإخطار",
  "_np_lc_op2_": "إشعار التعليق",
  "_np_lc_op3_": "إخطار المواد",
  "_np_lc_op4_": "إشعار الاجتماع",
  "_np_lc_op5_": "إشعار مشاركة جديدة",
  "_kyc_": "eKYC",
  "_kyc_mes_": "قم بتحميل المستندات كما هو مذكور أدناه. (بتنسيق .jpg/.png فقط)",
  "_kyc_photo_": "صورتك",
  "_kyc_id_": "إثبات هوية صالح",
  "_kyc_sample_": "فيما يلي نماذج من المستندات الخاصة بـ eKYC.",
  "_kyc_smaple_photo_": "صورة عينة",
  "_kyc_sample_id_": "رقم تعريف العينة",
  "_kyc_complete_": "لقد تمت الموافقة بالفعل على e-KYC الخاص بك.",
  "_as_": "الحساب والأمن",
  "_as_email_": "تغيير البريد الالكتروني",
  "_as_test_email_": "test@test.com",
  "_as_change_password_": "تغيير كلمة المرور",
  "_as_new_pass_": "كلمة المرور الجديدة",
  "_as_con_pass_": "تأكيد كلمة المرور",
  "_as_delete_": "حذف الحساب",
  "_as_delete_msg_": "سيؤدي هذا إلى إزالة حسابك والدورة (الدورات) الخاصة بك من النظام",
  "_as_delete_btn_": "حذف الحساب",
  "_preferred_language_": "اللغة المفضلة",
  "_corporate_report_": "تقرير الشركة",
  "_hiring_report_": "تقرير التوظيف",
  "_training_report_": "تقرير التدريب",
  "_select_course_": "حدد الدورة التدريبية",
  "_hr_export_": "يصدّر",
  "_hr_share_": "يشارك",
  "_hr_print_": "مطبعة",
  "_share_report_": "مشاركة التقرير",
  "_hr_share_msg_": "أدخل عناوين البريد الإلكتروني لمشاركة ملف التقرير",
  "_hr_email_placeholder_": "أدخل عنوان البريد الالكتروني",
  "_hr_email_error_msg_": "القيمة المدخلة لا تتطابق مع تنسيق البريد الإلكتروني",
  "_hr_add_": "يضيف",
  "_hr_close_": "يغلق",
  "_hr_share_": "مشاركة التقرير",
  "_hr_page_size_": "مقاس الصفحه:-",
  "_hr_no_record_": "لا توجد سجلات",
  "_hr_ag_col_sr_": "الرقم التسلسلي",
  "_hr_ag_col_name_": "اسم",
  "_hr_ag_col_emp_": "اسم الموظف",
  "_hr_ag_col_email_": "بريد إلكتروني",
  "_hr_ag_col_course_": "حالة الدورة",
  "_hr_ag_col_spent_": "امضى وقتا",
  "_hr_ag_col_score_": "النتيجة التي تم الحصول عليها",
  "_hr_ag_col_hiring_": "حالة التوظيف",
  "_hr_tour_1_": "لتحديد الدورة لإظهار الدورة الحكيمة",
  "_tr_report_type_": "حدد نوع التقرير",
  "_tr_report_type_": "حدد نوع التقرير",
  "_tr_select_course_": "حدد الدورة التدريبية",
  "_tr_select_emp_": "حدد الموظف",
  "_tr_print_opt1_": "نوع التقرير :-",
  "_hr_print_opt2_": "تاريخ الإنشاء :-",
  "_hr_print_opt3_": "اسم الدورة التدريبية :-",
  "_hr_print_opt4_": "وصف :-",
  "_tr_print_opt3_": "اسم الموظف :-",
  "_tr_emp_report_": "تقرير تدريب الموظفين",
  "_tr_course_report_": "تقرير الموظف الحكيم بالطبع",
  "_v_vacancies_": "الشواغر",
  "_v_vacancies_add_": "إنشاء وظيفة شاغرة جديدة",
  "_v_course_": "دورة :",
  "_v_experience_": "خبرة :",
  "_v_invite_talent_": "دعوة المواهب",
  "_v_detail_": "تفاصيل",
  "_v_remove_": "يزيل",
  "_v_no_vacancy_": "لم يتم إدراج أي وظيفة شاغرة!",
  "_v_course_not_create_": "لم تقم بإنشاء أي وظيفة شاغرة بعد.",
  "_v_invite_email_": "الدعوة عبر البريد الإلكتروني",
  "_v_invite_msg_": "سيشاهد مستلمو البريد الإلكتروني رسالة الدعوة",
  "_v_invite_add_emails_": "أضف عناوين البريد الإلكتروني للدعوة",
  "_v_invite_limit_": "يمكنك إرسال دعوات إلى ما يصل إلى 30 شخصًا في وقت واحد عن طريق إضافة المزيد من عناوين البريد الإلكتروني. لإضافة عدة رسائل بريد إلكتروني في وقت واحد، استخدم رسائل بريد إلكتروني مفصولة بفواصل.",
  "_v_invite_email_place_": "أدخل عنوان البريد الالكتروني",
  "_v_invite_email_add_": "يضيف",
  "_v_vacancy_": "خالي",
  "_v_back_to_list_": "العودة إلى القائمة",
  "_v_basic_detail_": "تفاصيل أساسية",
  "_v_leaderboard_": "المتصدرين",
  "_v_update_vacancy_": "تحديث الوظيفة الشاغرة",
  "_v_job_position_": "وظيفه:",
  "_v_course_prerequisite_": "المتطلب السابق للدورة:",
  "_v_experience_": "خبرة:",
  "_v_full_job_": "تفاصيل الوظيفة الكاملة :",
  "_v_leaderboard_share_": "مشاركة التقرير",
  "_v_enter_email_": "أدخل عناوين البريد الإلكتروني لمشاركة ملف التقرير",
  "_v_invite_email_close_": "يغلق",
  "_v_invite_share_report_": "مشاركة التقرير",
  "_v_search_student_": "بحث الطلاب",
  "_v_export_results_": "نتائج التصدير",
  "_v_share_result_": "مشاركة النتائج",
  "_v_rank_": "رتبة",
  "_v_name_": "اسم",
  "_v_time_spent_": "قضاء الوقت",
  "_v_quiz_score_": "نقاط الاختبار",
  "_v_resume_": "سيرة ذاتية",
  "_v_note_": "ملحوظة",
  "_v_status_": "حالة",
  "_v_recommended_": "مُستَحسَن",
  "_v_not_completed_": "غير مكتمل",
  "_v_note_": "ملحوظة",
  "_v_select_status_": "اختر الحالة",
  "_v_hired_": "استأجرت",
  "_v_not_contacted_": "لم يتم الاتصال",
  "_v_rejected_": "مرفوض",
  "_v_no_student_": "لم يتم العثور على الطلاب!",
  "_v_proctoring_report_": "تقرير المراقبة",
  "_v_pro_name_": "اسم :-",
  "_v_proc_email_": "بريد إلكتروني :-",
  "_v_proc_time_": "وقت :-",
  "_v_credit_score_": "مستوى الرصيد",
  "_v_total_score_": "مجموع النقاط",
  "_v_no_attempt_": "لا من المحاولة",
  "_v_wrong_": "خطأ",
  "_v_proc_summary_": "ملخص",
  "_v_proc_copy_": "ينسخ",
  "_v_proc_paste_": "معجون",
  "_v_proc_newtab_": "علامة تبويب أو نافذة جديدة",
  "_v_screenshot_": "خد لقطة للشاشة",
  "_v_inactivity_": "عدم النشاط",
  "_v_console_": "عرض وحدة التحكم",
  "_v_escape_": "يهرب",
  "_v_user_note_": "ملاحظة المستخدم",
  "_v_system_note_": "ملاحظة النظام",
  "_v_sys_note_place_1_": "هذا المرشح لم يكمل الدورة.",
  "_v_sys_note_place_2_": "أكمل هذا المرشح الدورة في أقل من متوسط الوقت",
  "_v_sys_note_place_3_": "لقد أمضى هذا المرشح وقتًا أطول من المتوسط وحصل على نتيجة جيدة في الاختبار",
  "_v_sys_note_place_4_": "لقد أمضى هذا المرشح وقتًا أطول من المتوسط وحصل على نتيجة جيدة في الاختبار",
  "_v_sys_note_place_5_": "هذا المرشح لم يكمل الدورة.",
  "_v_sys_note_place_6_": "هذا المرشح لم يبدأ الدورة بعد.",
  "_v_delete_confirm_": "هل أنت متأكد؟",
  "_v_delete_confirm2_": "متأكد من حذف الوظيفة الشاغرة؟",
  "_v_edit_vacancy_": "تحرير الوظيفة الشاغرة",
  "_v_add_vacancy_": "أضف وظيفة شاغرة",
  "_v_back_listing_": "العودة إلى القائمة",
  "_v_job_position_": "وظيفه",
  "_v_add_prerequisite_": "دورة متطلب سابق",
  "_v_add_pre_m1_": "سيتم فقط عرض الدورات التدريبية التي لم يتم ربطها حاليًا بأي وظيفة شاغرة في القائمة المنسدلة هنا.",
  "_v_select_course_": "اختر الدورة",
  "_v_add_experience_": "خبرة",
  "_v_job_des_": "المسمى الوظيفي",
  "_v_des_plac1_": "وصف الوظيفة الشاغرة",
  "_v_cancel_": "يلغي",
  "_v_add_submit_": "يُقدِّم",
  "_v_add_place_experience_": "على سبيل المثال 3-4 سنوات",
  "_v_add_posi_": "على سبيل المثال محلل بيانات",
  "_v_invite_invite_": "يدعو",
  "_tr_emp_col_sr_": "ريال سعودى.",
  "_tr_learner_name_": "اسم المتعلم",
  "_tr_emp_col_email_": "بريد إلكتروني",
  "_tr_emp_enroll_": "تاريخ التسجيل",
  "_tr_emp_com_": "تاريخ الانتهاء",
  "_tr_emp_course_status_": "حالة الدورة",
  "_tr_cou_time_": "الوقت المستغرق لإكمال الدورة",
  "_tr_cou_score_": "النتيجة التي تم الحصول عليها",
  "_tr_emp_course_name_": "اسم الدورة التدريبية",
  "_tr_emp_doe_": "تاريخ التسجيل",
  "_tr_emp_doc_": "تاريخ الانتهاء",
  "_tr_emp_course_status_": "حالة الدورة",
  "_tr_emp_spent_": "امضى وقتا",
  "_tr_emp_score_obt": "النتيجة التي تم الحصول عليها",
  "_np_notification_preference_": "تفضيلات الإخطار",
  "_sweetalert_process_": "يعالج...",
  "_sweetalert_wait_": "انتظر من فضلك...",
  "_l_ekyc2_": "ه كي واي سي",
  "_confirm_save_title_": "لن يتم حفظ التغييرات!",
  "_confirm_save_msg_": "لحفظ التغييرات التي تم إجراؤها، انقر فوق \'حفظ\'; أو انقر فوق \'إلغاء\' لتجاهل التغييرات.",
  "_p_profilephoto_size_": "يرجى تحميل الشعار بأبعاد 512 × 512 كحد أقصى.",
  "_imgcrop_cropimage_": "قص الصوره",
  "_imgcrop_zoom_": "تكبير",
  "_imgcrop_border_": "نصف قطر الحدود",
  "_imgcrop_rotate_": "استدارة",
  "_cancel_": "يلغي",
  "_imgcrop_crop_": "اقتصاص",
  "_show_tour_maintitle_": "هل ترغب في القيام بجولة سريعة؟",
  "_show_tour_msg_dashboard_": "ستأخذك هذه الجولة عبر جميع عناصر القائمة وتعرفك بالميزات.",
  "_show_tour_msg_emplist_": "ستأخذك هذه الجولة عبر جميع عناصر القائمة في قسم الموظفين وتعرفك بالميزات.",
  "_show_tour_msg_lc_": "ستأخذك هذه الجولة عبر جميع عناصر القائمة في قسم دائرة التعلم وستقدم لك الميزات.",
  "_show_tour_msg_notilist_": "ستأخذك هذه الجولة عبر جميع عناصر القائمة في قسم الإشعارات وستقدم لك الميزات.",
  "_show_tour_msg_hiring_": "ستأخذك هذه الجولة عبر جميع عناصر القائمة في تقرير التوظيف وستقدم لك الميزات.",
  "_show_tour_msg_role_": "ستأخذك هذه الجولة عبر جميع عناصر القائمة في قسم الأدوار والمسؤوليات وستقدم لك الميزات.",
  "_show_tour_assessment_": "ستأخذك هذه الجولة عبر جميع عناصر القائمة في قسم التقييم وتعرفك بالميزات.",
  "_show_tour_course_": "ستأخذك هذه الجولة عبر جميع عناصر القائمة في قسم لوحة تحكم الطالب وستقدم لك الميزات.",
  "_show_tour_corporate_": "ستأخذك هذه الجولة عبر جميع عناصر القائمة في قسم لوحة تحكم الشركة وتعرفك بالميزات.",
  "_show_tour_vacancy_": "ستأخذك هذه الجولة عبر جميع عناصر القائمة في قسم الوظائف الشاغرة وتعرفك بالميزات.",
  "_show_tour_yes_": "نعم من فضلك.",
  "_show_tour_no_": "لا، شكرا.",
  "_ud_l_home_": "بيت",
  "_ud_l_lc_": "دوائر التعلم",
  "_ud_l_coulearn_": "الدورات التي أتعلمها",
  "_ud_l_courses_": "الدورات",
  "_ud_l_couteach_": "الدورات التي أقوم بتدريسها",
  "_ud_l_quiz_": "بنك المسابقات",
  "_ud_l_roles_": "الأدوار والمسؤوليات",
  "_ud_l_vac_": "الشواغر",
  "_ud_l_emp_": "موظفين",
  "_ud_l_report_": "التقارير",
  "_ud_l_ca_": "نصيحة مهنية",
  "_ud_l_selfassess_": "تقييم ذاتى",
  "_ud_l_noti_": "إشعارات",
  "_ud_l_chat_": "محادثة",
  "_ud_l_emplist_": "قائمة موظف",
  "_emplist_add_": "يضيف",
  "_emplist_addnewemp_": "إضافة موظف جديد",
  "_emplist_importcsv_": "قم بالدعوة عبر ملف سي إس في",
  "_emplist_inviteviaemail_": "الدعوة عبر البريد الإلكتروني",
  "_emplist_exportcsv_": "تصدير إلى سي إس في",
  "_emplist_print_": "مطبعة",
  "_emplist_print_cmp_": "اسم الشركة :-",
  "_emplist_ag_col_name_": "الاسم الكامل",
  "_emplist_ag_col_role_": "دور",
  "_emplist_ag_col_email_": "بريد إلكتروني",
  "_emplist_ag_col_status_": "حالة",
  "_emplist_ag_col_action_": "أزرار العمل",
  "_emp_invite_msg_": "سيرى مستلمو البريد الإلكتروني هذه الرسالة",
  "_emplist_upload_": "رفع ملف",
  "_emplist_upload_csv_msg_": "يرجى تحميل ملف سي إس في فقط هنا بالتنسيق الصحيح",
  "_emp_samplefile_": "ملف عينة",
  "_emplist_inviteemps_": "دعوة الموظفين",
  "_emplist_empdetail_": "تفاصيل الموظف",
  "_emplist_view_": "منظر",
  "_emplist_update_": "تحديث",
  "_emp_update_name_": "الاسم الأول",
  "_emp_update_name_place_": "على سبيل المثال جاك",
  "_emplist_lastname_": "اسم العائلة",
  "_emplist_lastname_place": "على سبيل المثال نفذ",
  "_emplist_error_fname_": "الرجاء إدخال الاسم الأول",
  "_emplist_lastname_error_": "الرجاء إدخال الاسم الأخير",
  "_emplist_phone_": "هاتف",
  "_emplist_phone_place_": "على سبيل المثال +919876543210",
  "_emplist_phone_vali_msg_": "الرجاء إدخال الحد الأدنى 8 والحد الأقصى 16 رقما",
  "_emplist_update_emp_": "موظف",
  "_ekyc_vali_msg1_": "صورة الملف الشخصي غير صالحة.",
  "_ekyc_vali_msg2_": "لكي تبدأ ه ك ذ ج، يجب عليك تحميل صورة الملف الشخصي التي تحتوي على وجهك.",
  "_ekyc_vali_msg3_": "صورة غير لائقة",
  "_ekyc_vali_msg4_": "تمت إزالة صورتك لأنها تحتوي على محتوى غير لائق",
  "_ekyc_vali_msg5_": "يرجى تحميل صورة صالحة.",
  "_emplist_update_heading_": "العنوان / التعيين",
  "_emp_update_head_place_": "على سبيل المثال محلل بيانات",
  "_emplist_update_email_place_": "على سبيل المثال جاك @ اختبار. ج س م",
  "_emplist_update_email_": "تحديث الموظف",
  "_emp_invite_defaultmsg_": "يسعدنا أن ندعوك إلى EduKula، منصة تدريب الموظفين التي سنستخدمها في شركتنا! نحن ملتزمون حقًا بمساعدتك في تحقيق أهدافك التعليمية والتطويرية من خلال EduKula.",
  "_send_": "يرسل",
  "_emplist_del_con_": "سيتم تفعيل هذا",
  "_emplist_del_con2_": "حساب الموظف لأنشطة تسجيل الدخول",
  "_emplist_del_con4_": "سيؤدي هذا إلى تعطيل",
  "_emplist_del_con5_": "هل أنت متأكد أنك تريد حذف",
  "_emplist_del_con6_": "حساب الموظف ؟",
  "_emp_invite_platformdefaultmsg_": "يسعدنا أن ندعوك إلى EduKula، منصة تدريب الموظفين التي سنستخدمها في شركتنا! نحن ملتزمون حقًا بمساعدتك في تحقيق أهدافك التعليمية والتطويرية من خلال EduKula.",
  "_emp_add_employee_": "إضافة موظف",
  "_emp_back_tolisting_": "العودة إلى القائمة",
  "_emplist_select_role_": "حدد الدور",
  "_password_": "كلمة المرور",
  "_emp_pass_place_": "أدخل كلمة مرور قوية",
  "_emp_add_employee_about_": "عن",
  "_emp_add_about_des_": "اكتب مقدمة قصيرة عن هذا الموظف",
  "_course_tour_m1_": "ابدأ بشكل صحيح",
  "_course_tour_m2_": "قبل أن تبدأ رحلتك التعليمية، ننصحك بالقيام بجولة سريعة في هذه الصفحة. وسوف يطلعك على الموارد والميزات المتاحة، مما يحسن تجربتك. لا تفوت هذه الفرصة!",
  "_learn_course_tour_title1_": "نظرة عامة على الدورة",
  "_learn_course_tour_m1_": "ستجد هنا نظرة عامة موجزة عن الدورة التدريبية التي أنت على وشك الشروع فيها.",
  "_learn_course_tour_t2_": "قسم الأسئلة والأجوبة",
  "_learn_course_tour_m2_": "ابحث عن الوضوح وتعميق فهمك! في قسم الأسئلة والأجوبة المخصص هذا، لديك الفرصة لطرح أي أسئلة تتعلق بالدورة التدريبية. تفاعل مع زملائك المتعلمين والمعلمين للحصول على رؤى وحل الشكوك وتعزيز تجربة التعلم الخاصة بك.",
  "_learn_course_tour_t3_": "دفتر",
  "_learn_course_tour_m3_": "أطلق العنان لمنسقك الداخلي وابق منظمًا! قم بتدوين أي شكوك أو أسئلة قد تنشأ على طول الطريق. احتفظ بأفكارك في مكان واحد وقم بتخصيص تجربة التعلم الخاصة بك لجعلها ملكك حقًا.",
  "_learn_course_tour_t4_": "دروس حية",
  "_learn_course_tour_m4_": "انضم إلى إثارة الجلسات الحية! ستجد في هذا القسم معلومات حول أي جلسات مباشرة قادمة مرتبطة بالدورة التدريبية الخاصة بك",
  "_learn_course_tour_t5_": "مواد الدورة",
  "_learn_course_tour_m5_": "احصل على لمحة سريعة عما ينتظرك في رحلتك التعليمية.",
  "_learn_course_tour_t6_": "المواد الحالية",
  "_learn_course_tour_m6_": "هنا يمكنك بسهولة عرض مجموعة من المواد التعليمية والتفاعل معها مثل مقاطع الفيديو والمستندات والملفات التي تم تحميلها من المعلم.",
  "_learn_course_tour_t7_": "المعلم الظاهري",
  "_learn_course_tour_m7_": "معلمك الافتراضي موجود هنا لإرشادك في كل خطوة على الطريق! لا تتردد في طرح أي أسئلة تتعلق بالدورة الحالية، وسوف تتلقى إجابات فورية. تضمن هذه الميزة التفاعلية أن يكون لديك بيئة تعليمية داعمة حيث يتم حل الشكوك على الفور، مما يسمح لك بالتقدم بسلاسة وثقة.",
  "_careeradvice_tour_t1_": "منطقة عرض الدردشة",
  "_careeradvice_tour_m1_": "هذا هو المكان الذي سيتم فيه عرض المحادثة بينك وبين روبوت الذكاء الاصطناعي للحصول على المشورة المهنية. يمكنك عرض الردود والتوصيات المقدمة من روبوت الذكاء الاصطناعي.",
  "_careeradvice_tour_t2_": "مربع نص الرسالة",
  "_careeradvice_tour_m2_": "استخدم مربع النص هذا لإدخال المعلومات المتعلقة بحياتك المهنية واستفساراتك. اكتب رسالتك واضغط على زر الإدخال لإرسالها إلى روبوت الذكاء الاصطناعي لمعالجتها.",
  "_careeradvice_tour_t3_": "مسح زر الدردشة",
  "_careeradvice_tour_m3_": "انقر على هذا الزر لمسح سجل الدردشة وبدء محادثة جديدة مع روبوت الذكاء الاصطناعي.",
  "_dashboard_home_t1_": "حساب تعريفي",
  "_dashboard_home_m1_": "قم بالوصول إلى لوحة تحكم المستخدم الخاصة بشركتك، وقم بتحديث تفاصيل الملف الشخصي لشركتك، وقم بتسجيل الخروج باستخدام القائمة المنسدلة في الزاوية اليمنى العليا.",
  "_dashboard_home_t2_": "قائمة قفص الاتهام",
  "_dashboard_home_m2_": "قم بتوسيع القائمة وطيها على الجانب الأيسر من الشاشة باستخدام زر قائمة الإرساء.",
  "_dashboard_home_t3_": "بيت",
  "_dashboard_home_m3_": "احصل على التحديثات والدعوات والإعلانات المهمة بتنسيق موجز للدورات التدريبية ودوائر التعلم الخاصة بشركتك على EduKula.",
  "_dashboard_home_t4_": "دائرة التعلم",
  "_dashboard_home_m4_": "أنشئ بيئة تعليمية تعاونية لموظفيك باستخدام دائرة التعلم في EduKula. يمكن للأعضاء مشاركة مقاطع الفيديو والصور والمواد الأخرى أثناء التعلم والتفاعل مع بعضهم البعض. تفضل بزيارة قسم دائرة التعلم لعرض الدوائر التي انضممت إليها أو أنشأتها.",
  "_dashboard_home_t5_": "الدورات",
  "_dashboard_home_m5_": "قم بالوصول إلى جميع الدورات التدريبية التي نشرتها على EduKula ومسودات الدورات التدريبية التي تقوم بإنشائها في قسم الدورات التدريبية.",
  "_dashboard_home_t6_": "الشواغر",
  "_dashboard_home_m6_": "أنشئ وظائف شاغرة على EduKula واربطها بالدورات التدريبية التي قمت بإنشائها على EduKula. دعوة وتوظيف المواهب على أساس أدائهم.",
  "_dashboard_home_t7_": "موظفين",
  "_dashboard_home_m7_": "إنشاء أو دعوة الموظفين إلى المنصة وإدارة حسابات الموظفين المضافة.",
  "_dashboard_home_t8_": "التقارير",
  "_dashboard_home_m8_": "يمكنك الوصول إلى تقارير مفصلة عن نشاط المستخدم الخاص بموظفك، بالإضافة إلى تقارير عن الدورات التدريبية التي نشرتها على EduKula.",
  "_dashboard_home_t9_": "إشعارات",
  "_dashboard_home_m9_": "ابق على اطلاع دائم بدوائر التعلم والدورات التدريبية لشركتك عن طريق التحقق من قسم الإشعارات.",
  "_dashboard_home_t10_": "محادثة",
  "_dashboard_home_m10_": "تواصل مع الموظفين وأعضاء دوائر التعلم التي انضمت إليها شركتك أو أنشأتها.",
  "_dashboard_home2_m1_": "انتقل إلى لوحة تحكم المستخدم الخاصة بك، وقم بتحديث تفاصيل ملفك الشخصي، وقم بتسجيل الخروج باستخدام القائمة المنسدلة في الزاوية اليمنى العليا.",
  "_dashboard_home2_m2_": "استخدم زر قائمة الإرساء لتوسيع القائمة وطيها على الجانب الأيسر من الشاشة.",
  "_dashboard_home2_m4_": "تعاون مع المتعلمين ذوي التفكير المماثل في دائرة التعلم في EduKula. شارك مقاطع الفيديو والصور والمواد الأخرى أثناء التعلم والتفاعل مع بعضهم البعض. تفضل بزيارة قسم دائرة التعلم لعرض الدوائر التي انضممت إليها أو أنشأتها.",
  "_dashboard_home2_t5_": "طالب",
  "_dashboard_home2_m5_": "ابحث عن الدورات العامة ودورات التوظيف التي قمت بالتسجيل فيها من خلال EduKula والشهادات الخاصة بتلك التي أكملتها في قسم الطلاب.",
  "_dashboard_home2_t6_": "مدرس خاص",
  "_dashboard_home2_m6_": "قم بالوصول إلى جميع الدورات التدريبية التي نشرتها على EduKula ومسودات الدورات التي تقوم بإنشائها في قسم المعلم.",
  "_dashboard_home2_m7_": "يمكنك الوصول إلى تقارير تفصيلية عن نشاط المستخدم الخاص بك، بالإضافة إلى تقارير عن الدورات التدريبية التي قمت بالتسجيل فيها أو تدريسها حاليًا.",
  "_dashboard_home2_m8_": "طور حياتك المهنية من خلال اقتراحات مخصصة للدورات والبرامج الأكاديمية ودوائر التعلم بناءً على ملف تعريف المستخدم الخاص بك في قسم النصائح المهنية.",
  "_dashboard_home2_m9_": "عزز ثقتك بنفسك وقم بتقييم معرفتك باستخدام أداة التقييم الذاتي، التي تنشئ اختبارات حول موضوعات يختارها المتعلم باستخدام الذكاء الاصطناعي.",
  "_dashboard_home2_m10_": "ابق على اطلاع دائم بدوائر التعلم والدورات التدريبية التي قمت بالتسجيل فيها أو التدريس بها عن طريق التحقق من قسم الإشعارات.",
  "_dashboard_home2_m11_": "تواصل مع مدرسي الدورة وزملائك الأعضاء في دوائر التعلم التي انضممت إليها أو أنشأتها.",
  "_dashboard_home2_t11_": "استكشاف الدورات",
  "_dashboard_home2_m12_": "هذا هو المكان الذي يمكنك فيه البحث عن الدورات العامة واكتشافها لتلتحق بها في EduKula. يمكنك التصفية حسب الفئة والمستوى والمزيد للعثور على الدورة التدريبية المثالية لك.",
  "_dashboard_home2_t13_": "اكتشف دوائر التعلم",
  "_dashboard_home2_m13_": "هذا هو المكان الذي يمكنك فيه اكتشاف دوائر التعلم والانضمام إليها على EduKula. يمكنك البحث حسب الفئة والتقييم والمزيد للعثور على دائرة التعلم المناسبة.",
  "_nav_opt1_": "لوحة القيادة",
  "_nav_opt2_": "الدورات",
  "_nav_opt3_": "دائرة التعلم",
  "_nav_opt4_": "ابدأ التدريس",
  "_nav_opt5_": "للشركات",
  "_nav_log_in_": "تسجيل الدخول",
  "_nav_join_now_": "نضم الان",
  "_nav_user_dashboard_": "لوحة تحكم المستخدم",
  "_nav_profile_": "حساب تعريفي",
  "_nav_log_out_": "تسجيل خروج",
  "_nav_company_": "شركة",
  "_nav_personal_": "شخصي",
  "_footer_about_us_": "معلومات عنا",
  "_footer_blogs_": "المدونات والأخبار",
  "_footer_sitemap_": "خريطة الموقع",
  "_footer_connect_": "يتصل",
  "_footer_contact_us_": "اتصل بنا",
  "_footer_leader_": "قانوني",
  "_footer_copyright_": "حقوق النشر",
  "_footer_rights_": "كل الحقوق محفوظة.",
  "_footer_newsletter_": "النشرة الإخبارية",
  "_footer_email_place_": "عنوان بريدك  الإلكتروني",
  "_footer_email_error_": "عنوان البريد الإلكتروني غير صالح",
  "_footer_subscribe_": "يشترك",
  "_emplist_tour_t1_": "يضيف",
  "_emplist_tour_t2_": "تصدير إلى CSV",
  "_emplist_tour_t3_": "مطبعة",
  "_emplist_tour_t4_": "مقاس الصفحه",
  "_emplist_tour_m1_": "لإضافة موظفين إلى المنصة، انقر على زر \"إضافة\". يمكنك إما إنشاء موظف جديد أو دعوة الموظفين الحاليين عن طريق إضافة عناوين بريدهم الإلكتروني أو تحميل ملف CSV.",
  "_emplist_tour_m2_": "لتصدير قائمة الموظفين إلى ملف CSV، يمكنك أيضًا تصدير القائمة المصفاة إلى ملف CSV.",
  "_emplist_tour_m3_": "لطباعة قائمة الموظفين، يمكنك أيضًا تحديد عدد الموظفين لكل صفحة من خيار حجم الصفحة.",
  "_emplist_tour_m4_": "لتحديد عدد الموظفين لكل صفحة",
  "_mycircle_tour_t1_": "إنشاء دائرة التعلم",
  "_mycircle_tour_t2_": "دوائر التعلم التي قمت بإنشائها",
  "_mycircle_tour_t3_": "حلقات التعلم التي انضممت إليها",
  "_mycircle_tour_t4_": "دعوات الانضمام المعلقة",
  "_mycircle_tour_m1_": "انقر هنا لبدء مجموعة جديدة. اختر اسمًا، واكتب وصفًا، وعيّن مستوى الخصوصية، ثم قم بدعوة المشاركين. بمجرد إنشاء الدائرة، يمكنك مشاركة الموارد التعليمية وجدولة الاجتماعات.",
  "_mycircle_tour_m2_": "تعرض علامة التبويب هذه جميع دوائر التعلم التي قمت بإنشائها. ومن هنا، يمكنك إدارتها ودعوة الأعضاء وإغلاقها بمجرد تحقيق غرضها.",
  "_mycircle_tour_m3_": "تعرض علامة التبويب هذه جميع دوائر التعلم التي انضممت إليها. يمكنك الوصول إليها والتفاعل مع الأعضاء الآخرين والمساهمة في المواد التعليمية المشتركة.",
  "_mycircle_tour_m4_": "تعرض علامة التبويب هذه جميع دعوات الانضمام التي تلقيتها لدوائر التعلم. يمكنك إما قبول هذه الدعوات أو رفضها بناءً على تفضيلاتك.",
  "_notilist_tour_t1_": "إشعارات الدورة",
  "_notilist_tour_m1_": "تعرض علامة التبويب هذه جميع الإشعارات المتعلقة بالدورات التدريبية التي قمت بإنشائها لتدريب الموظفين وتوظيفهم. يمكنك عرض تفاصيل الإخطارات واتخاذ الإجراءات اللازمة",
  "_notilist_tour_t2_": "إشعارات دائرة التعلم",
  "_notilist_tour_m2_": "تعرض علامة التبويب هذه جميع الإشعارات المتعلقة بدوائر التعلم التي انضممت إليها أو أنشأتها. يمكنك عرض تفاصيل الإخطارات واتخاذ الإجراءات اللازمة.",
  "_notilist_tour_t3_": "إشعارات الطلاب",
  "_notilist_tour_m3_": "تعرض علامة التبويب هذه جميع الإشعارات المتعلقة بالدورات التدريبية التي قمت بالتسجيل فيها للتعلم. يمكنك عرض تفاصيل الإخطارات واتخاذ الإجراءات اللازمة.",
  "_notilist_tour_t4_": "إشعارات المعلم",
  "_notilist_tour_m4_": "تعرض علامة التبويب هذه جميع الإشعارات المتعلقة بالدورات التدريبية التي قمت بإنشائها. يمكنك عرض تفاصيل الإخطارات واتخاذ الإجراءات اللازمة.",
  "_hr_report_tour_t1_": "دورة",
  "_hr_report_tour_t2_": "يصدّر",
  "_hr_report_tour_t3_": "يشارك",
  "_hr_report_tour_t4_": "مطبعة",
  "_hr_report_tour_m2_": "لتصدير قائمة الطلاب إلى ملف CSV وتنزيله",
  "_hr_report_tour_m3_": "لمشاركة ملف التقرير مع عناوين البريد الإلكتروني",
  "_hr_report_tour_m4_": "لطباعة أو تحميل قائمة الطلاب بالتفاصيل بصيغة pdf",
  "_tour_back_": "خلف",
  "_tour_close_": "يغلق",
  "_tour_last_": "آخر",
  "_tour_next_": "التالي",
  "_tour_open_dialog_": "افتح مربع الحوار",
  "_tour_skip_": "يتخطى",
  "_sa_tour_t1_": "التقدم الوظيفي",
  "_sa_tour_m1_": "من خلال تحديد هذا الخيار، سيتذكر النظام موضوع التقييم الذاتي ويقدم الاقتراحات ذات الصلة للتقدم الوظيفي في المستقبل.",
  "_sa_tour_t2_": "فقط للممارسة",
  "_sa_tour_m2_": "يمكن استخدام هذا الخيار من قبل الأفراد الشباب الذين يرغبون في ممارسة واستكشاف مواضيع مختلفة دون أي تركيز وظيفي محدد.",
  "_mycourse_tour_t1_": "دورات مستمرة",
  "_mycourse_tour_m1_": "في علامة التبويب هذه، يمكنك العثور على جميع الدورات التدريبية التي قمت بالتسجيل فيها والتي إما لم تبدأ أو قيد التقدم حاليًا.",
  "_mycourse_tour_t2_": "تصفية الدورات",
  "_mycourse_tour_m2_": "استخدم هذه الميزة لتصفية الدورات التدريبية بناءً على معايير مثل الفئة وتقدم الدورة ونوع التعلم. يساعدك في العثور على الدورات التي تناسب تفضيلاتك واحتياجاتك.",
  "_mycourse_tour_t3_": "الدورات المكتملة",
  "_mycourse_tour_m3_": "ستجد في علامة التبويب هذه جميع الشهادات للدورات التي أكملتها بنجاح. إنه بمثابة سجل لإنجازاتك ومهاراتك المكتسبة.",
  "_courselist_tour_t1_": "إنشاء دورة",
  "_course_tour_m1_": "انقر فوق الزر \"إنشاء دورة تدريبية\" لبدء إنشاء دورة تدريبية جديدة. توفير تفاصيل الدورة، وإضافة المواد التعليمية، واختيار طريقة التعلم. بعد الانتهاء، أرسل الدورة للنشر.",
  "_courselist_tour_m1_": "انقر فوق الزر \"إنشاء دورة تدريبية\" لبدء إنشاء دورة تدريبية جديدة. توفير تفاصيل الدورة، وإضافة المواد التعليمية، واختيار طريقة التعلم. بعد الانتهاء، أرسل الدورة للنشر.",
  "_courselist_tour_t2_": "الدورات المنشورة",
  "_courselist_tour_m2_": "تعرض علامة التبويب هذه جميع الدورات التدريبية التي قمت بنشرها على EduKula. يمكنك إدارتها وتحديث تفاصيل الدورة التدريبية وعرض التسجيل ودعوة الموظفين والمواهب للتسجيل في الدورات التدريبية الخاصة بك.",
  "_courselist_tour_t3_": "المسودات",
  "_courselist_tour_m3_": "تعرض علامة التبويب هذه جميع الدورات التدريبية التي تعمل عليها حاليًا ولكن لم يتم نشرها بعد. يمكنك الاستمرار في تحريرها وإرسالها للنشر عندما تكون مستعدًا.",
  "_courselist_tour_t4_": "المعلم تمكين / تعطيل زر التبديل",
  "_courselist_tour_m4_": "استخدم زر التبديل هذا لتمكين أو تعطيل حالتك كمدرس. عند التمكين، يمكنك إنشاء الدورات التدريبية وإدارتها والتفاعل مع المتعلمين وتنفيذ أنشطة التدريس. عند تعطيلها، سيتم إلغاء تنشيط وظائف المعلم الخاصة بك مؤقتًا.",
  "_vacancy_tour_t1_": "إنشاء وظيفة شاغرة جديدة",
  "_vacancy_tour_m1_": "لإنشاء وظيفة شاغرة جديدة، انقر فوق الزر \"إنشاء وظيفة شاغرة جديدة\". قم بتعبئة التفاصيل اللازمة مثل المسمى الوظيفي، والوصف الوظيفي، وربطها بالدورة التدريبية ذات الصلة.",
  "_vacancy_tour_t2_": "مفتاح تبديل نشط/غير نشط",
  "_vacancy_tour_m2_": "قم بتبديل المفتاح لتنشيط أو إلغاء تنشيط وظيفة شاغرة. يتم عرض الوظائف الشاغرة النشطة للمرشحين المحتملين بينما لا يتم عرض الوظائف غير النشطة. يتحول المفتاح إلى اللون الأحمر للإشارة إلى أن الوظيفة الشاغرة نشطة",
  "_vacancy_tour_t3_": "دعوة المواهب",
  "_vacancy_tour_m3_": "قم بدعوة المرشحين للتقدم باستخدام ميزة \"دعوة المواهب \". أدخل عناوين بريدهم الإلكتروني وسيتلقون رسالة بريد إلكتروني تحتوي على تفاصيل الوظيفة الشاغرة ورابطًا للتعرف على الدورة التدريبية ذات الصلة لزيادة فرص تعيينهم.",
  "_vacancy_tour_t4_": "تفاصيل",
  "_vacancy_tour_m4_": "انقر فوق الزر \"التفاصيل\" لعرض أو تعديل تفاصيل وظيفة شاغرة معينة. يمكنك أيضًا الاطلاع على لوحة المتصدرين الخاصة بالعملاء المحتملين الذين قاموا بالتسجيل في الدورة التدريبية ذات الصلة ويمكن تعيينهم.",
  "_vacancy_tour_t5_": "يزيل",
  "_vacancy_tour_m5_": "قم بإزالة الوظيفة الشاغرة بالنقر فوق الزر \"إزالة\". سيؤدي هذا إلى حذف الوظيفة الشاغرة نهائيًا من النظام.",
  "_dh_student_": "طالب",
  "_dh_courses_": "الدورات",
  "_dh_tutor_": "مدرس خاص",
  "_dh_learningcircle_": "دائرة التعلم",
  "_dh_announcements_": "الإعلانات",
  "_load_more_": "تحميل المزيد",
  "_no_notification_": "لا توجد إخطارات حتى الآن",
  "_dh_register_m1_": "تهانينا على أن تصبح مدرسًا في EduKula. ابدأ رحلتك لتحسين مهارات الآخرين من خلال إنشاء دورة تدريبية!",
  "_dh_create_course_": "إنشاء دورة",
  "_dh_becometutor_": "تهانينا على التسجيل بنجاح كمدرس في EduKula",
  "_dh_coursepublished_m1_": "تهانينا ! لك",
  "_dh_coursepublished_m2_": "تم نشر الدورة بنجاح على EduKula.",
  "_dh_coursepublished_m3_": "إدارة هذه الدورة",
  "_dh_coursenotpublished_m2_": "بالطبع يتطلب بعض الإصلاحات من أجل نشرها على EduKula.",
  "_dh_coursenotpublished_m1_": "لك",
  "_dh_coursenotpublished_m3_": "عرض هذه الدورة",
  "_dh_lctab_m1_": "تم إنشاء الدائرة بنجاح على EduKula.",
  "_dh_lctab_m2_": "View this Learning circle",
  "_dh_lctab_m3_": "مالك",
  "_dh_lctab_m4_": "تعال وانضم إلى",
  "_dh_lctab_m5_": "دائرة لمزيد من استكشاف المفاهيم المستفادة في",
  "_trending_lc_": "دوائر التعلم الشائعة",
  "_dh_chat_head_": "محادثة",
  "_dh_lctab_m6_": "لقد تمت إضافتك بنجاح كعضو في",
  "_dh_lctab_m7_": "دائرة. مرحبا بكم في الدائرة!",
  "_dh_lctab_m7_": "إدارة هذه الدائرة",
  "_dh_lctab_m8_": "تهانينا! لقد تم تعيينك كمسؤول في",
  "_dh_lctab_m9_": "دائرة",
  "_dh_lctab_m10_": "إدارة الدائرة",
  "_dh_lctab_21_": "مرحبًا، أود أن أدعوك للانضمام إلى",
  "_dh_lctab_viewinvite_": "عرض الدعوات",
  "_dh_lctab_lcactive_m1_": "مرحبًا بعودتك! ال",
  "_dh_lctab_lcactive_m2_": "تم تنشيط الدائرة مرة أخرى.",
  "_dh_lctab_lcactive_m3_": "عرض هذه الدائرة",
  "_dh_lctab_lcactive_m4_": "تم جعل الدائرة غير نشطة مؤقتا. اراك قريبا!",
  "_dh_lctab_lcactive_m5_": "ال",
  "_dh_lctab_noupdate_": "لا توجد تحديثات لدائرة التعلم حتى الآن",
  "_dh_lctab_noupdate2_": "سيتم عرض جميع التحديثات المتعلقة بدائرة التعلم هنا.",
  "_dh_lctab_lcclose_m1_": "تم إغلاق الدائرة. شكرًا لانضمامك إلينا ودعمنا في رحلة التعلم هذه.",
  "_dh_lctab_register_": "قم بإنشاء دائرة تعليمية لتجربة رحلة تعليمية تتجاوز البنية التقليدية للتعلم، والتي يقودها التعاون والتآزر بين موظفيك وزملائك المتعلمين.",
  "_dh_announcementstab_": "لا إعلانات حتى الآن",
  "_dh_announcementstab_m2_": "عرض الإعلانات",
  "_dh_lctab_new_": "دورات الإصدار الجديد",
  "_dh_newrelease_course_": "لا توجد دورات جديدة",
  "_dh_studenttab_rgister_": "تحقق من مجموعتنا من الدورات التدريبية عبر الإنترنت عالية الطلب وقم بتطوير المهارات التي تحتاجها للتقدم في حياتك المهنية.",
  "_dh_studenttab_rgister_m2_": "شاهد مجموعتنا التدريبية الكاملة",
  "_dh_studenttab_rgister_m3_": "مبروك التسجيل بنجاح في",
  "_dh_studenttab_rgister_m4_": "عرض هذه الدورة",
  "_dh_studenttab_invite_m1_": "مرحبًا، تعال وانضم إلي",
  "_dh_studenttab_invite_m2_": "بالطبع لتعزيز حياتك المهنية.",
  "_dh_studenttab_invite_m3_": "يمكنك التسجيل باستخدام الكود :",
  "_dh_studenttab_complete_m1_": "تهانينا! لقد أكملت بنجاح",
  "_dh_studenttab_complete_m2_": "عرض الشهادة",
  "_dh_studenttab_share_m1_": "اعتقدت أنك قد تستمتع",
  "_dh_studenttab_share_m2_": "عرض الدورة",
  "_dh_studenttab_highestrating_": "الدورات الأعلى تقييمًا",
  "_role_heading_": "الأدوار والمسؤوليات",
  "_role_newrole_": "إنشاء دور جديد",
  "_role_rolename_": "اسم الدور:",
  "_role_responsibility_": "المسؤوليات :",
  "_role_m1_": "يمكن إدارة الموظفين",
  "_role_m2_": "يمكن إدارة الأدوار والمسؤوليات",
  "_role_m3_": "يمكن تحميل التقارير",
  "_role_m4_": "يمكن إدارة الدورات",
  "_role_m5_": "يمكن إدارة الوظائف الشاغرة",
  "_role_m6_": "يمكن إدارة دوائر التعلم",
  "_edit_": "يحرر",
  "_remove_": "يزيل",
  "_role_norole_": "لا يوجد دور مدرج!",
  "_role_notcreated_": "لم تقم بإنشاء أي أدوار بعد.",
  "_role_delete_confirm_": "هل سيتم نقل جميع الموظفين الحاليين في هذا الدور إلى حالة الموظف العادي؟ هل أنت متأكد من رغبتك في متابعة حذف هذا الدور؟",
  "_role_add_new_": "إضافة دور جديد",
  "_role_roles_": "الأدوار",
  "_roles_back_to_listing_": "العودة إلى القائمة",
  "_role_tittle_place_": "على سبيل المثال الموارد البشرية",
  "_roles_allowed_modules_": "الوحدات المسموح بها",
  "_roles_manage_emp_": "إدارة الموظفين",
  "_roles_manage_roles_": "إدارة الأدوار",
  "_roles_download_report_": "تحميل التقارير",
  "_roles_manage_courses_": "إدارة الدورات",
  "_roles_manage_vacancies_": "إدارة الوظائف الشاغرة",
  "_roles_manage_lc_": "إدارة دوائر التعلم",
  "_role_edit_role_": "تحرير الدور",
  "_chat_typemessage_": "اكتب رسالة",
  "_chat_online_": "متصل",
  "_chat_offline_": "غير متصل على الانترنت",
  "_chat_search_": "يبحث",
  "_noti_notification_": "إشعار",
  "_noti_student_": "طالب",
  "_noti_course_": "دورة",
  "_noti_tutor_": "مدرس خاص",
  "_noti_lc_": "دائرة التعلم",
  "_notilist_new_course_m1_": "تحقق من هذه الدورة التدريبية الجديدة تمامًا",
  "_notilist_new_course_m2_": "المنشورة على EduKula والتي تتوافق مع تفضيلاتك.",
  "_notilist_cmaterial_add_": "مادة جديدة بعنوان",
  "_notilist_cmaterial_add_m2_": "تمت إضافته إلى",
  "_notilist_cassignment_add_": "مهمة جديدة بعنوان",
  "_notilist_quiz_add_": "مسابقة جديدة بعنوان",
  "_notilist_course_": "دورة",
  "_notilist_material_update_m1_": "مادة جديدة بعنوان",
  "_notilist_material_update_m2_": "في ال",
  "_notilist_material_update_m3_": "تم تحديث الدورة من قبل المعلم.",
  "_notilist_assignment_update_m1_": "مهمة جديدة بعنوان",
  "_notilist_assignment_update_m2_": "في ال",
  "_notilist_assignment_update_m3_": "تم تحديث الدورة من قبل المعلم.",
  "_notilist_quiz_update_m1_": "مسابقة جديدة بعنوان",
  "_notilist_quiz_update_m2_": "في ال",
  "_notilist_assignment_update_m3_": "تم تحديث الدورة من قبل المعلم.",
  "_notilist_cmaterial_delete_m1_": "المادة بعنوان",
  "_notilist_cmaterial_delete_m2_": "في ال",
  "_notilist_cmaterial_delete_m3_": "تم حذف الدورة",
  "_notilist_assignment_delete_m1_": "المهمة بعنوان",
  "_notilist_assignment_delete_m2_": "في ال",
  "_notilist_assignment_delete_m3_": "تم حذف الدورة",
  "_notilist_quiz_delete_m1_": "المسابقة بعنوان",
  "_notilist_couinvite_m1_": "مرحبًا، تعال وانضم إلي",
  "_notilist_couinvite_m2_": "بالطبع لتعزيز حياتك المهنية",
  "_notilist_create_meeting_m1_": "أضاف المدرس جلسة جديدة من المقرر عقدها على",
  "_notilist_at_": "في",
  "_notilist_create_meeting_m2_": "لمدة",
  "_notilist_create_meeting_m3_": "في موضوع بعنوان",
  "_notilist_meeting_delete_m1_": "لقد ألغى المعلم الجلسة حول الموضوع بعنوان",
  "_notilist_meeting_accepted_m1_": "تم تأكيد حجز الجلسة - من المقرر عقد حجز الجلسة الخاصة بك على",
  "_notilist_meeting_accepted_m2_": "لمدة",
  "_notilist_meeting_accepted_m3_": "في موضوع بعنوان",
  "_notilist_meeting_accepted_m4_": "ل",
  "_notilist_meeting_accepted_m5_": "تم تأكيد الدورة",
  "_notilist_meeting_reject_m1_": "تم رفض حجز الجلسة - من المقرر عقد حجز الجلسة الخاصة بك في",
  "_notilist_meeting_reject_m2_": "في موضوع بعنوان",
  "_notilist_meeting_reject_m3_": "تم رفض الدورة. يرجى تحديد توقيت آخر مناسب لك والمحاولة مرة أخرى",
  "_notilist_meeting_scheduled_m1_": "الاجتماع المقرر اليوم في",
  "_notilist_meeting_scheduled_m2_": "في موضوع بعنوان",
  "_notilist_meeting_scheduled_tutor_m1_": "الاجتماع المقرر اليوم في",
  "_noti_roq_m1_": "لقد حصلت على",
  "_notilist_roq_m2_": "ردود جديدة على الاستعلام المنشور على",
  "_notilist_roq_m3_": "في قسم الأسئلة والأجوبة",
  "_notilist_courexpire_m1_": "لك",
  "_notilist_courexpire_m2_": "تنتهي الدورة في 30 يوما. يرجى إكمال الدورة قبل انتهاء صلاحيتها للحصول على الشهادة",
  "_notilist_vacancy_add_m1_": "الخروج من هذه الوظيفة الشاغرة",
  "_notilist_vacancy_add_m2_": "مفتوح فيما يتعلق بتفضيلاتك المرتبطة بهذا",
  "_notilist_cousubmit_m1_": "تم إرسال الدورة للمراجعة بنجاح. من المتوقع أن تتم مراجعة الدورة التدريبية الخاصة بك في غضون 72 ساعة.",
  "_notilist_courpublished_m1_": "تمت مراجعة الدورة ونشرها بنجاح",
  "_notilist_coursenotpublished_m1_": "لم يتم نشر الدورة التدريبية حيث يبدو أن محتوياتها تنتهك سياستنا. يرجى تحديث المحتوى والمحاولة مرة أخرى",
  "_notilist_newque_m1_": "تمت إضافة سؤال جديد إلى الخاص بك",
  "_notilist_otomeet_m1_": "لقد حجزت جلسة في",
  "_notilist_otomeet_m2_": "دورة حول موضوع بعنوان",
  "_notilist_otomeet_m3_": "والذي من المقرر أن يقام يوم",
  "_notilist_otomeet_m4_": "يرجى تأكيد حالة هذا الحجز",
  "_notilist_meetcancel_m1_": "تم إلغاء حجز الجلسة حول موضوع بعنوان",
  "_notilist_lcadd_m1_": "تفحص ال",
  "_notilist_lcadd_m2_": "دائرة التعلم التي تم تقديمها لبناء دائرة تعليمية جديدة على EduKula بناءً على تفضيلاتك",
  "_notilist_circle_": "دائرة",
  "_notilist_lcmaterial_m1_": "تمت إضافته بواسطة",
  "_notilist_to_the_": "إلى",
  "_notilist_lcmaterial_update_m1_": "تم تحديث الدائرة",
  "_notilist_lcmaterial_delete_m1_": "تم حذف الدائرة",
  "_notilist_lcmeet_add_m1_": "وقد حدد موعدًا لعقد اجتماع جديد بشأن",
  "_notilist_lcmeet_delete_m1_": "وقد ألغى الجلسة حول موضوع بعنوان",
  "_notilist_lcpost_add_m1_": "لديك 1 مشاركات جديدة في قسم الموجز في",
  "_notilist_lcpost_add_m2_": "دائرة التعلم",
  "_notilist_lcpost_video_review_m1_": "الفيديو الذي نشرته للتو في قسم الموجز في",
  "_notilist_lcpost_video_review_m2_": "دائرة التعلم قيد المراجعة",
  "_notilist_lcpost_video_remove_m1_": "الفيديو الذي نشرته في قسم الموجز في",
  "_notilist_lcpost_video_remove_m2_": "تمت إزالة دائرة التعلم",
  "_notilist_lcpoll_add_m1_": "لديك استطلاع واحد جديد في قسم الخلاصة في",
  "_notilist_lcpost_like_m1_": "الآخرين في",
  "_notilist_lcpost_like_m2_": "الدائرة أعجبت بمنشورك المتعلق بـ",
  "_notilist_and_": "و",
  "_notilist_new_post_comment_m1_": "علقت الدائرة على مشاركتك",
  "_mylc_heading_": "دوائر التعلم الخاصة بي",
  "_mylc_createlc_": "إنشاء دائرة التعلم",
  "_mylc_limit_swalbox_m1_": "لقد وصلت إلى الحد الأقصى لعدد دوائر التعلم التي يمكنك إنشاؤها. لإنشاء دائرة تعليمية جديدة، سيُطلب منك إما مغادرة دائرة تم إنشاؤها مسبقًا أو إغلاقها.",
  "_mylc_ok_": "نعم",
  "_mylc_circle_created_": "الدوائر التي قمت بإنشائها",
  "_mylc_circle_joined_": "الدوائر التي انضممت إليها",
  "_mylc_circle_invitations_": "دعوات الانضمام المعلقة",
  "_mylc_created_circle_head_": "الدائرة التي تم إنشاؤها",
  "_mylc_circle_created_main_msg_": "ابدأ دائرة تعليمية خاصة بك وتعرف على المزيد حول موضوع من اختيارك من خلال التواصل مع محترفين آخرين",
  "_mylc_created_circle_opt1_": "تحرير الدائرة",
  "_mylc_created_circle_rating_": "تقييم:",
  "_mylc_created_circle_norating_": "بدون تقييم",
  "_mylc_created_circle_noofpeople_": "عدد الأشخاص",
  "_mylc_circle_created_nocircle_": "لم يتم العثور على دوائر!",
  "_mylc_circle_created_nocircle_m1_": "لم تقم بإنشاء أي دوائر مائلة حتى الآن",
  "_mylc_trendinglc_head_": "دوائر التعلم الشائعة",
  "_mylc_lccard_": "بدأت",
  "_mylc_lccard_m2_": "أعضاء",
  "_mylc_lccard_rating_": "تقييم",
  "_mylc_circlejoined_head_": "انضمت إلى الدائرة",
  "_mylc_circlejoined_msg_": "انضم إلى أي مجموعة تعليمية احترافية تم إنشاؤها على بوابة التعلم الخاصة بنا للحصول على المشورة وتبادل الأفكار مع زملائك حول موضوعات محددة",
  "_mylc_circlejoined_nojoined_": "لم تنضم إلى أي دوائر مائلة حتى الآن.",
  "_mylc_circlejoined_create_": "انشأ من قبل",
  "_mylc_circle_noofpeople_": "عدد الأشخاص",
  "_mylc_pending_invitation_head_": "دعوة معلقة",
  "_mylc_pending_invitation_invitations_": "الدعوات",
  "_mylc_pending_invitation_m1_": "لقد دعاك للانضمام",
  "_mylc_pending_invitation_join_": "ينضم",
  "_mylc_pending_invitation_ignore_": "يتجاهل",
  "_mylc_pending_invitation_noinvite_": "لم يتم العثور على أي دعوات!",
  "_mylc_showmore_": "أظهر المزيد",
  "_lcform_add_": "نموذج دائرة التعلم",
  "_lcform_title_": "عنوان دائرة التعلم",
  "_lcform_title_place_": "على سبيل المثال: أساسيات المبيعات والتسويق",
  "_lcform_title_vali_m1_": "الرجاء إدخال العنوان.",
  "_lcform_title_vali_m2_": "لا يمكن أن يزيد اسم الدائرة عن 80 حرفًا.",
  "_lcform_desc_": "وصف دائرة التعلم",
  "_lcform_desc_place_": "وصف الدائرة",
  "_lcform_desc_vali_m1_": "الرجاء إدخال الوصف",
  "_lcform_category_": "فئة",
  "_lcform_category_m1_": "اختر الفئة ذات الصلة لدائرة التعلم",
  "_lcform_choose_category_": "اختر الفئة",
  "_lcform_category_vali_m1_": "الرجاء تحديد الفئة",
  "_lcform_subcategory_": "تصنيف فرعي",
  "_lcform_subcategory_m1_": "اختر الفئة الفرعية ذات الصلة لدائرة التعلم.",
  "_lcform_choose_subcategory_": "اختر الفئة الفرعية",
  "_lcform_subcategory_vali_m1_": "الرجاء تحديد الفئة الفرعية.",
  "_lcform_whyjoin_": "لماذا يجب على المتعلمين الانضمام إلى دائرة التعلم؟",
  "_lcform_whyjoin_m1_": "سيساعدك هذا القسم على شرح كيفية اختلاف دائرة التعلم الخاصة بك عن دوائر التعلم المماثلة الأخرى وجذب المزيد من الأعضاء.",
  "_lcform_whyjoin_place_": "وضح بالتفصيل ما يحققه الأعضاء من خلال الانضمام إلى دائرة التعلم الخاصة بك.",
  "_lcform_whyjoin_vali_m1_": "يرجى توضيح سبب انضمام المستخدم إلى الدائرة.",
  "_lcform_coverimage_": "قم بتحميل صورة الغلاف لدائرة التعلم",
  "_lcform_imagesize_": "*الحجم الموصى به (1266*400)",
  "_lcform_coverimage_vali_m1_": "يرجى تحميل صورة الدائرة.",
  "_lcform_parent_cou_": "اختر الدورة الأم",
  "_lcform_parent_cou_m1_": "حدد الدورة التدريبية الأصلية المتعلقة بدائرة التعلم هذه",
  "_lcform_parent_cou_vali_m1_": "الرجاء تحديد دورة الوالدين.",
  "_lcform_choose_privacy_": "اختر الخصوصية",
  "_lcform_choose_privacy_m1_": "يتم إدراج دوائر التعلم العامة على بوابة EduKula ويمكن لأي مستخدم الانضمام إليها. دوائر التعلم الخاصة غير مدرجة في EduKula ويمكن فقط للمستخدمين المعتمدين أو المدعوين من قبل المسؤول الانضمام.",
  "_lcform_privacy_opt1_": "اختر الخصوصية",
  "_lcform_privacy_opt2_": "عام",
  "_lcform_privacy_opt3_": "خاص",
  "_lcform_choose_privacy_vali_m1_": "الرجاء تحديد ما إذا كانت الدائرة عامة أم خاصة.",
  "_lcform_invite_": "حدد مستخدمي EduKula الذين ترغب في دعوتهم إلى دائرة التعلم",
  "_lcform_select_user_": "حدد مستخدمي EduKula",
  "_lcform_select_user_vali_m1_": "يرجى اختيار عضو واحد على الأقل لدعوته.",
  "_lcform_emailinvite_": "أدخل عناوين البريد الإلكتروني للأشخاص الذين ترغب في دعوتهم إلى دائرة التعلم",
  "_lcform_emailinvite_m1_": "أدخل عناوين البريد الإلكتروني (مفصولة بفواصل) للأشخاص الذين ترغب في دعوتهم إلى دائرة التعلم",
  "_lcform_emailinvite_place_": "أدخل عناوين البريد الإلكتروني (مفصولة بفواصل) للأشخاص الذين ترغب في دعوتهم إلى دائرة التعلم هذه.",
  "_explorelc_mainmsg_": "تعلم أحدث ما توصلت إليه تكنولوجيا المعلومات من خلال دوراتنا. من البرمجة إلى الأمن السيبراني، ستعمل دوراتنا المتنوعة على تعزيز مهاراتك وتطوير حياتك المهنية",
  "_explorelc_all_categories_": "عرض جميع الفئات",
  "_explorelc_joinmsg_": "انضم إلى دائرة التعلم اليوم!",
  "_explorelc_joinmsg_place_": "ماذا تريد أن تتعلم ؟",
  "_lc_bread_related_cat_": "الفئة ذات الصلة",
  "_lc_bread_view_all_cat_": "عرض جميع الفئات",
  "_explorelc_category_": "فئة",
  "_explorelc_subcategory2_": "تصنيف فرعي",
  "_explorelc_rating_": "تقييم",
  "_explorelc_rate_above_": "فوق",
  "_explorelc_clearfilter_": "امسح الكل",
  "_explorelc_learning_circle_": "دوائر التعلم",
  "_explorelc_onedukula_": "على إيدوكولا",
  "_explorelc_msg2_": "قم بتوسيع فهمك إلى ما هو أبعد من مجرد أخذ الدورات التدريبية",
  "_explorelc_nolc_found_": "لم يتم العثور على دوائر تعليمية!",
  "_explorelc_nolc_found_m2_": "لم يتم العثور على أي دوائر تعليمية تطابق استعلام البحث الخاص بك",
  "_explorelc_createlc_msg_": "كن أول من أنشأ دائرة تعليمية حول هذا الموضوع",
  "_explorelc_high_rated_": "الدوائر ذات التصنيف العالي",
  "_lcdetail_swalbox_m1_": "تنبيه التسجيل",
  "_lcdetail_swalbox_m2_": "للانضمام إلى الدائرة، يجب عليك تسجيل الدخول إلى المنصة. إذا لم يكن لديك حساب، فلا تقلق! يمكنك إنشاء واحد بسهولة عن طريق النقر فوق الزر  تسجيل أدناه.",
  "_lcdetail_swalbox_m3_": "تسجيل الدخول",
  "_lcdetail_swalbox_m4_": "اشتراك",
  "_lcdetail_circle_profile_": "ملف تعريف الدائرة",
  "_lcdetail_whyjoin_": "لماذا يجب عليك الانضمام",
  "_lcdetail_admin_": "مسؤل",
  "_lcdetail_about_lc_": "حول هذه الدائرة التعليمية",
  "_lcdetail_what_learn__": "ما ستتعلمه",
  "_lcdetail_std_": "طلاب",
  "_lcdetail_includes_": "تتضمن دائرة التعلم هذه",
  "_lcdetail_group_members_": "أعضاء المجموعة",
  "_lcdetail_attend_meetings_": "حضور الاجتماعات",
  "_lcdetail_share_material_": "مشاركة المواد",
  "_lcdetail_conduct_dis_": "إجراء المناقشة",
  "_lcdetail_rec_main_msg_": "دوائر التعلم الموصى بها لك",
  "_lcfeed_caption_vali_m1_": "لا يمكن أن تزيد التسمية التوضيحية عن 2000 حرف.",
  "_lcfeed_caption_place_": "ابدأ مشاركة",
  "_lcfeed_caption_vali_m2_": "الرجاء إدخال التعليق",
  "_lcfeed_caption_post_": "بريد",
  "_lcfeed_photo_": "صورة",
  "_lcfeed_video_": "فيديو",
  "_lcfeed_poll_": "تصويت",
  "_lcfeed_nofeed_": "لم يتم نشر أي خلاصات لهذه الدائرة",
  "_lcfeed_aboutcard_m1_": "حول هذه الدائرة",
  "_lcfeed_aboutcard_m2_": "الدائرة العامة",
  "_fc_title_": "للشركات",
  "_fc_train_msg_m1_": "تدريب الخاص بك",
  "_fc_train_msg_m2_": "قوة العمل المستقبلية",
  "_fc_train_msg_m3_": "البدء",
  "_fc_train_msg_m4_": "حل التوظيف والتدريب",
  "_fc_train_msg_m5_": "الحلول التي تمكن المنظمات",
  "_fc_train_msg_m6_": "تواصل الشركات والشباب",
  "_fc_train_msg_m7_": "الدورات الموجهة للتوظيف",
  "_fc_train_msg_m8_": "صمم دورة تدريبية موجهة نحو التوظيف بسهولة باستخدام أداة إنشاء الدورات التدريبية المتطورة الخاصة بمنصة التعلم الإلكتروني الخاصة بنا، لتقييم موهبتك المحتملة وتدريبها بدقة.",
  "_fc_train_msg_m9_": "إدارة الشواغر",
  "_fc_train_msg_m10_": "قم بإنشاء إعلان وظيفة، وربطه بالدورة التدريبية ذات الصلة، وحدد الموظفين المحتملين بسهولة بناءً على سجلات إكمال الدورة التدريبية الخاصة بهم.",
  "_fc_train_msg_m11_": "المتصدرين",
  "_fc_train_msg_m12_": "احصل على نظرة عامة متعمقة حول نتائج المرشح وتفاصيل إكمال الدورة التدريبية ومعلومات الاتصال من خلال لوحة المتصدرين الشاملة الخاصة بنا.",
  "_fc_train_msg_m13_": "تدريب الموظفين",
  "_fc_train_msg_m14_": "المدمج في تأليف الدورة",
  "_fc_train_msg_m15_": "تقديم تجربة تعليمية سلسة مع مزيج من مواد التعليم الإلكتروني الكلاسيكية مثل المستندات ومقاطع الفيديو والصور ومواد التعليم الإلكتروني الحديثة مثل TINCAN وSCORM",
  "_fc_train_msg_m16_": "تقييم وتقييم",
  "_fc_train_msg_m17_": "قم بتقييم النمو والاحتفاظ بمعارف موظفيك من خلال ميزة الاختبارات والواجبات المتكاملة لدينا.",
  "_fc_train_msg_m17_": "التعلم من نظير إلى نظير",
  "_fc_train_msg_m18_": "تعزيز ثقافة التعلم التعاوني وربط الموظفين مع أقرانهم، وتمكينهم من مشاركة المواد والتعاون افتراضيًا من خلال دوائر التعلم الجذابة لدينا",
  "_fc_train_msg_m19_": "إدارة المستخدم",
  "_fc_train_msg_m20_": "إنشاء حساب المستخدم",
  "_fc_train_msg_m21_": "قم بإضافة موظفيك بسهولة إلى منصة التعلم الإلكتروني الخاصة بنا عن طريق إنشاء حسابات مستخدمين فردية لكل منهم.",
  "_fc_train_msg_m22_": "دعوة المستخدم- CSV/البريد الإلكتروني",
  "_fc_train_msg_m23_": "تسمح لك ميزة الدعوة المبتكرة لدينا بدعوة عدة موظفين في وقت واحد، إما من خلال إدخال بريد إلكتروني بسيط أو عملية تحميل ملف CSV ملائمة.",
  "_fc_train_msg_m24_": "إدارةالمستخدم",
  "_fc_train_msg_m25_": "قم بإدارة حسابات الموظفين بسهولة من خلال ميزة إدارة المستخدم البديهية، مما يسمح لك بتنشيط الحسابات وإلغاء تنشيطها وإزالتها بسهولة.",
  "_fc_train_msg_m26_": "التقارير الذكية",
  "_fc_train_msg_m27_": "تقارير مخصصة",
  "_fc_train_msg_m28_": "يسمح بإنشاء تقارير مخصصة عن مقاييس المستخدم، وتقارير الدورة التدريبية، وتقارير دائرة التعلم لمستخدمين محددين أو جميعهم، عبر جميع دوائر التعلم والدورات التعليمية أو محددة.",
  "_fc_train_msg_m29_": "الرسوم البيانية بديهية",
  "_fc_train_msg_m30_": "تقديم بيانات المستخدم بطريقة واضحة وسهلة الفهم، مما يسمح للمسؤول بتحليلها واتخاذ قرارات مستنيرة لتحسين تجربة التعلم.",
  "_fc_train_msg_m31_": "تصدير CSV",
  "_fc_train_msg_m32_": "يتيح للمستخدمين تصدير البيانات بتنسيق CSV بسهولة، مما يمكنهم من معالجة البيانات وتحليلها حسب الحاجة",
  "_fc_train_msg_m33_": "سجل للحصول على حساب الشركة",
  "_fc_train_msg_m34_": "يسجل",
  "_managelc_swalbox_m1_": "متأكد من",
  "_managelc_swalbox_m2_": "يترك",
  "_managelc_currernt_rating_": "التصويت الحالي",
  "_managelc_not_sure_": "غير متأكد",
  "_managelc_back_to_dashboard_": "العودة إلى لوحة القيادة",
  "_managelc_deactivate_": "إلغاء التنشيط",
  "_managelc_activate_": "تفعيل",
  "_managelc_invite_": "يدعو",
  "_managelc_invite_emp_": "دعوة الموظفين",
  "_managelc_invite_user_": "قم بدعوة مستخدمي EduKula",
  "_managelc_invite_via_email_": "الدعوة عبر البريد الإلكتروني",
  "_managelc_invite_via_link_": "دعوة مع الرابط",
  "_managelc_view_profile_": "عرض الصفحة الشخصية",
  "_managelc_group_feed_": "خلاصة المجموعة",
  "_managelc_meetings_": "الاجتماعات",
  "_managelc_materials_": "مواد",
  "_managelc_members_": "أعضاء",
  "_managelc_more_": "أكثر",
  "_managelc_view_parent_cou_": "عرض دورة الوالدين",
  "_managelc_join-request_": "إدارة طلبات الانضمام",
  "_managelc_sent_invite_": "الدعوات المرسلة",
  "_managelc_close_circle_": "إغلاق الدائرة",
  "_managelc_leave_circle_": "ترك الدائرة",
  "_managelc_report_circle_": "دائرة التقارير",
  "_managelc_rate_circle_": "قيم هذه الدائرة",
  "_managelc_rate_msg_": "كيف تقيم هذه الدائرة؟",
  "_managelc_select_rating_": "حدد التقييم",
  "_managelc_rate_tellus_": "من فضلك أخبرنا بالمزيد (اختياري)",
  "_managelc_circle_inactive_": "هذه الدائرة غير نشطة حاليا!",
  "_managelc_select_all_": "اختر الكل",
  "_managelc_nouser_found_": "لم يتم العثور على المستخدم",
  "_managelc_send_invitations_": "يرسل دعوات",
  "_managelc_email_invitation_msg_": "سيشاهد مستلمو البريد الإلكتروني رسالة الدعوة",
  "_managelc_invite_email_vali_m1_": "الرجاء إدخال عنوان البريد الإلكتروني",
  "_managelc_copy_link_": "نسخ الوصلة",
  "_managelc_delete_swal_m1_": "متأكد من حذف المشاركة؟",
  "_managelc_remove_poll_": "إزالة التصويت",
  "_managelc_left_": "غادر",
  "_managelc_poll_vote_": "تصويت",
  "_managelc_votes_": "الأصوات",
  "_managelc_delete_": "يمسح",
  "_managelc_see_more_": "شاهد المزيد",
  "_managelc_see_less_": "انظر أقل",
  "_managelc_comments_": "تعليقات",
  "_managelc_comment_": "تعليق",
  "_managelc_about_card_m1_": "يمكن لأي مستخدم لـ EduKula الانضمام إلى هذه الدائرة",
  "_managelc_about_card_m2_": "مرئي",
  "_managelc_about_card_m3_": "يمكن لأي شخص العثور على هذه الدائرة",
  "_managelc_about_card_m4_": "تاريخ",
  "_managelc_about_card_m5_": "تم إنشاء الدائرة بتاريخ",
  "_managelc_create_post_": "إنشاء مشاركة",
  "_managelc_postphoto_caption_vali_m1_": "لا يمكن أن تزيد التسمية التوضيحية عن 1000 حرف.",
  "_managelc_postphoto_caption_place_": "ما الذي يدور في ذهنك",
  "_managelc_postphoto_photo_vali_": "الرجاء اختيار صورة لهذا المنصب",
  "_managelc_video_notsupported_": "عذرًا، متصفحك لا يدعم مقاطع الفيديو المضمنة.",
  "_managelc_postvideo_vali_m1_": "الرجاء اختيار فيديو لهذا المنصب",
  "_managelc_postpoll_create_poll_": "إنشاء استطلاع",
  "_managelc_postpoll_poll_caption_": "تسمية توضيحية للاستطلاع",
  "_managelc_postpoll_poll_caption_place_": "على سبيل المثال، ما موضوع هذا الاستطلاع؟",
  "_managelc_postpoll_question_": "سؤالك",
  "_managelc_postpoll_question_opt_": "خيار",
  "_managelc_postpoll_add_opt_": "إضافة خيار",
  "_managelc_postpoll_poll_duration_": "مدة الاستطلاع",
  "_managelc_postpoll_poll_select_day_": "اختر اليوم",
  "_managelc_postpoll_poll_ins_": "نحن لا نوافق على طلبات الحصول على آراء سياسية أو معلومات طبية أو غيرها من البيانات الحساسة.",
  "_managelc_postpoll_post_btn_": "بريد",
  "_managelc_loading_": "تحميل....",
  "_managelc_feedcomments_loadmore_": "تحميل المزيد من التعليقات",
  "_managelc_feedcomments_of_": "ل",
  "_managelc_feedcomments_comments_": "تعليقات",
  "_managelc_comment_delete_verify_": "متأكد من حذف التعليق؟",
  "_managelc_reply_btn_": "رد",
  "_managelc_load_more_reply_": "تحميل المزيد من الردود",
  "_managelc_replies_": "الردود",
  "_managelc_like_": "يحب",
  "_managelc_liked_": "احب",
  "_managelc_write_a_public_comment_": "اكتب تعليق عام...",
  "_managelc_invite_model_search_": "البحث عن المستخدم بالاسم",
  "_managelc_email_invitation_msg_place_": "سيتم إرسال هذه الرسالة إلى المستخدمين المدعوين",
  "_managelc_email_invitation_msg_defaultvalue_": "مرحبًا، يسعدني أن أدعوك للانضمام إلى دائرة التعلم الخاصة بي على EduKula. دعونا نتواصل في الدائرة ونتعلم معًا!",
  "_lcmeeting_delete_swal_m1_": "متأكد من حذف الاجتماع؟",
  "_lcmeeting_meeting_list_": "قائمة الاجتماعات",
  "_lcmeeting_add_meeting_": "إضافة اجتماع",
  "_lcmeeting_meeting_opt1_": "وقت البدء",
  "_lcmeeting_meeting_opt2_": "عنوان",
  "_lcmeeting_meeting_opt3_": "مدة",
  "_lcmeeting_meeting_opt4_": "حالة",
  "_lcmeeting_meeting_opt5_": "فعل",
  "_lcmeeting_meeting_no_meeting_created_": "لم يتم إنشاء أي اجتماعات",
  "_lcmeeting_meetbtn_start_": "يبدأ",
  "_lcmeeting_meetbtn_join_": "ينضم",
  "_lcmeeting_meetbtn_search_": "بحث عن الاجتماعات",
  "_lcmeeting_addmeet_timezone_": "اختر المجال الزمني",
  "_lcmeeting_addmeet_timezone_vali_m1_": "الرجاء تحديد المنطقة الزمنية",
  "_lcmeeting_addmeet_topic_": "موضوع الاجتماع*",
  "_lcmeeting_addmeet_title_vali_m1_": "لا يمكن أن يزيد العنوان عن 80 حرفًا.",
  "_lcmeeting_addmeet_timezone_vali_m2_": "الرجاء إدخال موضوع الاجتماع.",
  "_lcmeeting_addmeet_agenda_": "الأجندة المختصرة*",
  "_lcmeeting_addmeet_agenda_vali_m1_": "الرجاء إدخال جدول أعمال الاجتماع.",
  "_lcmeeting_addmeet_date_": "تاريخ*",
  "_lcmeeting_addmeet_date_vali_m1_": "الرجاء تحديد تاريخ الاجتماع.",
  "_lcmeeting_addmeet_time_": "وقت*",
  "_lcmeeting_addmeet_time_vali_m1_": "الرجاء تحديد وقت الاجتماع.",
  "_lcmeeting_addmeet_duration_": "المدة (بالدقائق)*",
  "_lcmeeting_addmeet_duration_vali_m1_": "الرجاء إدخال مدة الاجتماع بالدقائق.",
  "_lcmeeting_addmeet_duration_vali_m2_": "يجب أن تكون المدة 10 دقائق على الأقل.",
  "_lcmeeting_addmeet_duration_vali_m3_": "لا يمكن أن تزيد المدة عن 200 دقيقة.",
  "_general_profile_com_name_place_": "اسم الشركة",
  "_general_profile_com_name_vali_m1_": "لا يمكن أن يزيد اسم الشركة عن 80 حرفًا.",
  "_general_profile_com_name_vali_m2_": "الرجاء إدخال 3 أحرف على الأقل.",
  "_general_profile_com_name_vali_m3_": "الرجاء إدخال اسم الشركة",
  "_general_profile_com_name_vali_m4_": "الرجاء إضافة الاسم الأول بشكل مناسب.",
  "_general_profile_com_name_vali_m5_": "لا يمكن أن يزيد الاسم الأول عن 80 حرفًا.",
  "_general_profile_first_name_place_": "الاسم الأول",
  "_general_profile_last_name_vali_m1_": "الرجاء إضافة الاسم الأخير بشكل مناسب",
  "_general_profile_last_name_vali_m2_": "لا يمكن أن يزيد اسم العائلة عن 80 حرفًا.",
  "_general_profile_last_name_vali_m3_": "الرجاء إدخال 3 أحرف على الأقل.",
  "_general_profile_last_name_place_": "اسم العائلة",
  "_general_profile_phone_vali_m1_": "الرجاء إدخال الحد الأدنى 8 والحد الأقصى 16 رقما",
  "_general_profile_phone_vali_m2_": "الرجاء إدخال رقم الهاتف",
  "_general_profile_heading_vali_m1_": "لا يمكن أن يزيد العنوان عن 80 حرفًا.",
  "_general_profile_heading_vali_m2_": "الرجاء إدخال العنوان",
  "_general_profile_username_vali_m1_": "الرجاء إدخال اسم مستخدم صالح للفيسبوك",
  "_general_profile_username_vali_m2_": "الرجاء إدخال اسم مستخدم صالح للفيسبوك",
  "_general_profile_twitter_vali_m1_": "الرجاء إدخال اسم مستخدم تويتر صالح",
  "_general_profile_twitter_vali_m2_": "لا يمكن أن يزيد اسم مستخدم تويتر عن 15 حرفًا.",
  "_general_profile_twitter_vali_m3_": "لا يمكن أن يكون اسم مستخدم تويتر أقل من 4 أحرف",
  "_general_profile_linkedin_vali_m1_": "الرجاء إدخال اسم مستخدم لينكد إن صالح",
  "_general_profile_website_vali_m1_": "Please enter valid company URL.(Ex: www.example.com)",
  "_general_profile_website_vali_m2_": "الرجاء إدخال 3 أحرف على الأقل",
  "_general_profile_website_vali_m3_": "لا يمكن أن يزيد طول موقع الشركة الإلكتروني عن 80 حرفًا.",
  "_general_profile_comwebsite_place_": "موقع الشركة",
  "_coursedetail_title_": "فئة الدورة",
  "_coursedetail_title2_": "الدورات",
  "_coursedetail_search_for_courses_": "البحث عن الدورات",
  "_coursedetail_search_place_": "ماذا تريد أن تتعلم ؟",
  "_coursedetail_sort_level_": "مستوى",
  "_coursedetail_sort_course_type_": "دورة كتابية",
  "_coursedetail_sort_learning_type_": "نوع التعلم",
  "_coursedetail_msg_results_": "نتائج",
  "_coursedetail_main_msg_2_": "تعلم الدورات التدريبية لتحسين مهاراتك وتعزيز فرص العمل لديك",
  "_coursedetail_sort_course_highrate_": "الأعلى تقييمًا",
  "_coursedetail_sort_course_selling_": "أفضل مبيعات",
  "_coursedetail_sort_course_trending_": "الشائع",
  "_coursedetail_sort_course_hiring_": "توظيف",
  "_custom_pagi_previous_": "سابق",
  "_custom_pagi_next_": "التالي",
  "_coursedetail_nocourse_m1_": "لم يتم العثور على دورات!",
  "_coursedetail_nocourse_m2_": "استكشف كتالوج الدورات التدريبية الخاص بنا للتسجيل في الدورة وبدء رحلتك التعليمية.",
  "_coursedetail_alert_t1_": "تنبيه التسجيل",
  "_coursedetail_alert_m1_": "للتسجيل في هذه الدورة، يجب عليك تسجيل الدخول إلى المنصة. إذا لم يكن لديك حساب، فلا تقلق! يمكنك إنشاء واحدة بسهولة عن طريق النقر فوق الزر تسجيل أدناه.",
  "_coursedetail_enroll_course_error_": "الرجاء إدخال الرمز الفريد الخاص بك للتسجيل في هذه الدورة.",
  "_coursedetail_alert_t2_": "تأكيد التسجيل في الدورة",
  "_coursedetail_alert_m2_": "تهانينا! لقد قمت بالتسجيل في الدورة بنجاح. لقد حان الوقت لبدء رحلة التعلم الخاصة بك. سيتم إعادة توجيهك إلى صفحة الدورة التدريبية في لحظة.",
  "_coursedetail_msg_mode_of_learning_": "تعلم بالسرعة التي تناسبك",
  "_coursedetail_msg_session_": "جلسات شخصية",
  "_coursedetail_msg_onetomany_": "جلسات جماعية",
  "_coursedetail_msg_free_": "حر",
  "_coursedetail_msg_startlearning_": "ابدأ التعلم على الفور",
  "_coursedetail_enroll_now_": "تسجيل الآن",
  "_coursedetail_gotocourse_": "اذهب إلى الدورة",
  "_coursedetail_instructors_": "المدربين",
  "_coursedetail_prerequisites_": "المتطلبات الأساسية",
  "_coursedetail_cour_include_": "تتضمن هذه الدورة",
  "_coursedetail_cour_enrolled_": "المقيدين",
  "_coursedetail_cour_certificate_": "شهادة إتمام",
  "_coursedetail_report_course_": "الإبلاغ عن هذه الدورة",
  "_coursedetail_cour_nofeedback_m1_": "لم يتم تقديم أي تعليقات حتى الآن.",
  "_coursedetail_cour_related_": "متعلق ب",
  "_coursedetail_cour_course_m1_": "دورة",
  "_coursedetail_more_course_": "المزيد من الدورات بواسطة",
  "_coursedetail_about_tab_1_": "حول هذه الدورة",
  "_coursedetail_cour_subcategory_": "تصنيف فرعي",
  "_coursedetail_nooutcome_": "لا توجد نتائج",
  "_coursedetail_prerequisites_tab_1_": "لا توجد متطلبات مسبقة",
  "_course_category_main_msg_m2_": "تعلم أحدث ما توصلت إليه تكنولوجيا المعلومات من خلال دوراتنا. من البرمجة إلى الأمن السيبراني، ستعمل دوراتنا المتنوعة على تعزيز مهاراتك وتطوير حياتك المهنية.",
  "_learncourse_nocour_m1_": "مهم!",
  "_learncourse_nocour_m2_": "إما أن تتم إزالة هذه الدورة أو أنها غير متوفرة.",
  "_learncourse_back_btn_": "العودة إلى لوحة تحكم الطالب",
  "_learncourse_course_progress_": "عرض تقدم الدورة",
  "_learncourse_course_com_m2_": "يرجى إكمال هذه الدورة خلال",
  "_learncourse_course_com_m3_": "أيام، لأن هذه الدورة لم تعد متوفرة.",
  "_learncourse_virtualtutor_m1_": "أنا المعلم الافتراضي الخاص بك",
  "_learncourse_virtualtutor_m2_": "بإمكانكم طرح استفساراتكم...",
  "_learncourse_virtualtutor_m3_": "اسألني اي شئ!",
  "_learncourse_course_com_m4_": "سيؤدي هذا إلى إكمال الدورة التدريبية ولن تتلقى أي تحديثات مستقبلية أو تحضر جلسات مستقبلية.",
  "_learncourse_slidelist_m1_": "قسم",
  "_learncourse_slidelist_m2_": "إنهاء الدورة",
  "_learncourse_slidelist_feedback_m1_": "كيف كانت تجربتك ؟",
  "_learncourse_slidelist_feedback_m2_": "قيمنا",
  "_learncourse_slidelist_feedback_m3_": "من فضلك شارك تجربتك هنا...",
  "_learncourse_detail_overview_": "ملخص",
  "_learncourse_detail_qa_": "سؤال وجواب",
  "_learncourse_detail_livesessions_": "جلسات حية",
  "_learncourse_ratecour_m1_": "قيم هذه الدورة",
  "_learncourse_ratecour_m2_": "كيف تقيم هذه الدورة؟",
  "_learncourse_ratecour_m3_": "من فضلك أخبرنا المزيد (اختياري).",
  "_learncourse_qa_place_": "أكتب استفسارك...",
  "_learncourse_qa_noqa_": "لا توجد استفسارات مرفوعة!",
  "_learncourse_qa_delete_con_": "متأكد من حذف الاستعلام؟",
  "_learncourse_notebook_note_vali_m1_": "لا يمكن أن تكون الملاحظات أكبر من 1000 حرف",
  "_learncourse_notebook_note_vali_m2_": "إنشاء ملاحظة جديدة",
  "_learncourse_notebook_note_opt1_": "جميع المحاضرات",
  "_learncourse_notebook_note_opt2_": "المحاضرة الحالية",
  "_learncourse_notebook_note_opt3_": "الترتيب حسب الأحدث",
  "_learncourse_notebook_note_opt4_": "الترتيب حسب الأقدم",
  "_learncourse_notebook_note_opt5_": "متبقي",
  "_learncourse_notebook_nonote_": "لم يتم العثور على ملاحظات.",
  "_learncourse_notebook_sweetalert_m2_": "متأكد من حذف الملاحظة؟",
  "_learncourse_notebook_edit_note_": "تحرير مذكرة",
  "_learncourse_meeting_msg_m1_": "احجز جلسة",
  "_learncourse_meeting_msg_m2_": "اختر المجال الزمني",
  "_learncourse_meeting_timezone_vali_m1_": "الرجاء تحديد المنطقة الزمنية.",
  "_learncourse_meeting_requested_ses_": "الجلسات المطلوبة",
  "_learncourse_meeting_requested_no_ses_": "لا توجد جلسات مطلوبة.",
  "_learncourse_meeting_no_sess_": "لم يتم العثور على جلسات",
  "_learncourse_suggestvideo_filtervideo_": "تصفية مقاطع الفيديو",
  "_learncourse_suggestvideo_lan_": "لغة",
  "_lan_eng_": "إنجليزي",
  "_lan_hindi_": "الهندية",
  "_lan_french_": "فرنسي",
  "_lan_japan_": "اليابانية",
  "_lan_span_": "الأسبانية",
  "_lan_arabic_": "عربي",
  "_lan_russian_": "الروسية",
  "_lan_portu_": "البرتغالية",
  "_courseilearn_student_dash_": "لوحة تحكم الطالب",
  "_courseilearn_no_cour_": "لم يتم تسجيل أي دورات",
  "_courseilearn_cou_progress_": "تقدم الدورة",
  "_courseilearn_card_alert_m1_": "أكمل الدورة التدريبية الخاصة بك - لا تفوت!",
  "_courseilearn_card_alert_m2_": "يبدو أنك لم تنته من هذه الدورة بعد. قبل أن تغادر، تذكر أنه سيتعين عليك البدء من جديد إذا كنت تريد الانضمام مرة أخرى لاحقًا. هل أنت متأكد أنك تريد ترك الدورة؟",
  "_courseilearn_card_opt1_": "ترك الدورة",
  "_courseilearn_card_opt2_": "تعلُّم",
  "_courseilearn_card_opt3_": "شهادة",
  "_courseilearn_card_no_cour_": "لا توجد دورات مستمرة",
  "_courseilearn_completed_cou_": "لا توجد دورات مكتملة",
  "_courcerti_m1_": "شهادة الدورة",
  "_courcerti_m2_": "هذه الشهادة أعلاه تثبت ذلك",
  "_courcerti_m3_": "أكملت الدورة بنجاح",
  "_courcerti_m4_": "على",
  "_courcerti_m5_": "كما يدرسها",
  "_courcerti_m6_": "على إيدوكولا. تشير الشهادة إلى أن الدورة التدريبية بأكملها قد تم التحقق منها من قبل الطالب. تمثل مدة الدورة إجمالي ساعات الفيديو الخاصة بالدورة في وقت آخر استكمال لها",
  "_courcerti_m7_": "حول الدورة",
  "_courcerti_m8_": "مكتمل",
  "_courcerti_m9_": "متلقي الشهادة",
  "_courcerti_m10_": "شارك عبر",
  "_courcerti_m11_": "تحميل",
  "_courcerti_m12_": "جبيغ",
  "_courcerti_m13_": "بي دي إف",
  "_courselist_alert_m1_": "إنشاء دورة باستخدام الذكاء الاصطناعي",
  "_courselist_alert_m2_": "هل ترغب في إنشاء هذه الدورة بمساعدة الذكاء الاصطناعي؟",
  "_courselist_alert_m3_": "ملحوظة: - أنت تقوم بإنشاء الدورة التدريبية كممثل لشركتك وجميع الحقوق المرتبطة بها هي ملكية خاصة لشركتك.",
  "_courselist_alert2_m1_": "سيؤدي هذا إلى تمكين خدمات المعلم الخاصة بك.",
  "_courselist_alert2_m2_": "سيؤدي هذا إلى تعطيل خدمات المعلم الخاصة بك. سيتم إلغاء نشر جميع الدورات التدريبية التي لم يسجل فيها طلاب التعلم النشط وإرسالها إلى المسودات على الفور. سيتم إلغاء نشر الدورات التي تحتوي على طلاب التعلم النشط خلال 30 يومًا.",
  "_courselist_corpo_dash_": "لوحة تحكم الشركة",
  "_courselist_tutor_dash_": "لوحة تحكم المعلم",
  "_courselist_tutor_disable_": "تعطيل المعلم",
  "_courselist_tutor_enable_": "يُمكَِن",
  "_courselist_create_cou_btn_": "إنشاء دورة",
  "_courselist_tutor_service_dis_": "خدمة المعلم معطلة!",
  "_courselist_cour_descript_": "وصف مختصر للدورة",
  "_courselist_cour_descript_vali_m1_": "لا يمكن أن يزيد الوصف الموجز للدورة عن 300 حرف",
  "_courselist_cour_descript_place_": "أدخل وصفًا مختصرًا للدورة، مثل أساسيات Photoshop وتصميم الجرافيك، واستراتيجيات التسويق الرقمي",
  "_courselist_cour_descript_vali_m2_": "وصف مختصر للدورة.",
  "_courselist_cour_learner_level_": "مستوى المتعلم",
  "_courselist_cour_learner_level_opt1_": "حدد مستوى المتعلم",
  "_courselist_cour_learner_level_opt2_": "استهلالي",
  "_courselist_cour_learner_level_opt3_": "متوسط",
  "_courselist_cour_learner_level_opt4_": "خبير",
  "_courselist_cour_learner_level_vali_m1_": "الرجاء تحديد القسم.",
  "_courselist_cour_duration_": "مدة الدورة",
  "_courselist_cour_duration_opt1_": "حدد مدة الدورة",
  "_courselist_cour_duration_opt2_": "0-1 شهر",
  "_courselist_cour_duration_opt3_": "1-3 أشهر",
  "_courselist_cour_duration_opt4_": "3-6 أشهر",
  "_courselist_cour_duration_opt5_": "6-9 أشهر",
  "_courselist_cour_duration_opt6_": "9-12 شهرًا",
  "_courselist_cour_apply_": "يتقدم",
  "_couseiteach_publish_title_": "الدورات المنشورة",
  "_couseiteach_no_publish_": "لم يتم نشر أي دورات",
  "_couseiteach_del_alert_m1_": "متأكد من حذف الدورة؟",
  "_couseiteach_del_alert_m2_": "متأكد من إلغاء النشر؟",
  "_couseiteach_del_alert_m3_": "متأكد من تغيير الخصوصية؟",
  "_couseiteach_card_opt1_": "إلغاء نشر الدورة التدريبية",
  "_couseiteach_card_opt2_": "أصنعها",
  "_couseiteach_draft_alert_m1_": "هل تريد بالتأكيد حذف مسودة الدورة التدريبية؟",
  "_couseiteach_draft_title_": "مشروع الدورة",
  "_couseiteach_draft_edit_pro_": "تعديل الملف الشخصي",
  "_couseiteach_draft_msg_m1_": "من أجل تقديم دورة تدريبية للمراجعة، يجب إكمال الملف التعريفي العام لشركتك مع مقدمة الشركة.",
  "_couseiteach_draft_msg_m2_": "من أجل إرسال دورة تدريبية للمراجعة، يجب إكمال ملفك الشخصي باسمك ومقدمة ذاتية وصورة وتفضيلات المعلم",
  "_couseiteach_draft_delete_btn_": "حذف المسودة",
  "_couseiteach_draft_submit_review_": "تقديم للمراجعة",
  "_couseiteach_draft_no_cour_draft_": "لا توجد دورات في المسودة",
  "_couseiteach_draft_main_msg_m1_": "دورتك بعنوان",
  "_couseiteach_draft_main_msg_m2_": "تم تقديمه للمراجعة وسيتم نشره خلال 72 ساعة.",
  "_couseiteach_draft_main_msg_m3_": "ماذا تريد أن تفعل بهذه الدورة بعد ذلك؟",
  "_couseiteach_draft_main_msg_m4_": "خلق الشواغر وتوظيف المواهب",
  "_couseiteach_draft_main_msg_m5_": "دعوة لتدريب الموظفين",
  "_couseiteach_draft_main_msg_m6_": "تخطي في الوقت الراهن",
  "_createcourse_step1_m1_": "ملاحظة",
  "_createcourse_step1_m2_": "تفاصيل الدورة الأساسية",
  "_createcourse_step1_m3_": "عنوان الدورة",
  "_createcourse_step1_m4_": "لا بأس إذا لم تتمكن من التفكير في عنوان جيد الآن، فيمكنك تغييره من مسوداتك لاحقًا قبل تقديمه للمراجعة.",
  "_createcourse_step1_m5_": "لا يمكن أن يزيد اسم الدورة عن 80 حرفًا",
  "_createcourse_step1_m6_": "على سبيل المثال تعلم فوتوشوب CS6 من الصفر",
  "_createcourse_step1_m7_": "الرجاء إدخال العنوان.",
  "_createcourse_step1_m8_": "إذا لم تكن متأكدًا من الفئة الصحيحة، فيمكنك تغييرها من مسوداتك لاحقًا قبل إرسالها للمراجعة.",
  "_createcourse_step1_m9_": "اختر فئة",
  "_createcourse_step1_m10_": "إذا لم تكن متأكدًا من الفئة الفرعية الصحيحة، فيمكنك تغييرها من مسوداتك لاحقًا قبل إرسالها للمراجعة.",
  "_createcourse_step1_m11_": "هدف الدرس",
  "_createcourse_step1_m12_": "ما هو الهدف الرئيسي الذي سيحققه الطالب من خلال تعلم هذا المقرر؟",
  "_createcourse_step1_m13_": "لا يمكن أن يكون الهدف أكثر من 500 حرف.",
  "_createcourse_step1_m14_": "على سبيل المثال سوف يتعلم الطلاب الأدوات الأساسية المستخدمة في Photoshop لإنشاء الصور وتحريرها.",
  "_createcourse_step1_m15_": "الرجاء إدخال الهدف.",
  "_createcourse_step1_m16_": "مستوى الدراسة",
  "_createcourse_step1_m17_": "اختر مستوى الخبرة الذي سيحققه الطالب من خلال إكمال هذه الدورة.",
  "_createcourse_step1_m18_": "اختر مستوى الدراسة",
  "_createcourse_step1_m19_": "الرجاء تحديد المستوى.",
  "_createcourse_step1_m20_": "تحميل صورة الدورة",
  "_createcourse_step1_m21_": "الرجاء تحديد صورة الدورة.",
  "_createcourse_step1_m22_": "صورة الدورة",
  "_createcourse_step1_m23_": "لغة الدورة",
  "_createcourse_step1_m24_": "اختر اللغة الأساسية التي يتم تدريس الدورة بها.",
  "_createcourse_step1_m25_": "الرجاء اختيار اللغة.",
  "_createcourse_step1_m26_": "يكمل",
  "_createcourse_step1_m27_": "حفظ وخروج",
  "_createcourse_step2_m1_": "وصف الدورة بالتفصيل",
  "_createcourse_step2_m2_": "الدورات العامة مدرجة على بوابة EduKula ويمكن لأي مستخدم التسجيل فيها. الدورات الخاصة غير مدرجة في EduKula ويمكن للطلاب المدعوين فقط التسجيل في الدورة.",
  "_createcourse_step2_m3_": "اختر الخصوصية",
  "_createcourse_step2_m4_": "الرجاء تحديد الخصوصية.",
  "_createcourse_step2_m5_": "تحميل فيديو تمهيدي",
  "_createcourse_step2_m6_": "سيؤدي تحميل مقطع فيديو تمهيدي يعطي لمحة عن الدورة إلى زيادة فرص الطالب في التسجيل في الدورة التدريبية الخاصة بك.",
  "_createcourse_step2_m7_": "فيديو الدورة",
  "_createcourse_step2_m8_": "أو",
  "_createcourse_step2_m9_": "إضافة رابط فيديو يوتيوب",
  "_createcourse_step2_m10_": "على سبيل المثال https://youtu.be/QUJ3OJZqe84",
  "_createcourse_step2_m11_": "وصف الدورة التدريبية",
  "_createcourse_step2_m12_": "أدخل وصف الدورة التدريبية الخاصة بك.",
  "_createcourse_step2_m13_": "الرجاء إدخال الوصف.",
  "_createcourse_step2_pre_m1_": "قم بإدراج المتطلبات أو المتطلبات الأساسية لأخذ الدورة التدريبية الخاصة بك",
  "_createcourse_step2_pre_m2_": "إذا لم تكن هناك متطلبات، استخدم هذه المساحة لتسليط الضوء على كيفية إتاحة الدورة التدريبية للجميع.",
  "_createcourse_step2_pre_m3_": "قم بإدراج الأدوات والمهارات ومستوى الخبرة المطلوبة التي يجب أن يتمتع بها المتعلمون قبل بدء الدورة التدريبية الخاصة بك.",
  "_createcourse_step2_pre_m4_": "مثال: لا حاجة إلى خبرة في البرمجة. سوف تتعلم كل ما تحتاج إلى معرفته",
  "_createcourse_step2_pre_m5_": "مثال: لا حاجة إلى خبرة في البرمجة. سوف تتعلم كل ما تحتاج إلى معرفته",
  "_createcourse_step2_pre_m6_": "أضف المزيد إلى ردك",
  "_createcourse_step2_out_m1_": "ماذا سيتعلم الطلاب في الدورة الخاصة بك؟",
  "_createcourse_step2_out_m2_": "يجب عليك إدخال ما لا يقل عن 3 مفاهيم أو مهارات أساسية سيكتسبها المتعلمون بعد إكمال الدورة التدريبية الخاصة بك.",
  "_createcourse_step2_out_m3_": "مثال: أساسيات التصميم الجرافيكي وتحرير الصور.",
  "_createcourse_step2_out_m4_": "مثال: أساسيات التصميم الجرافيكي وتحرير الصور.",
  "_createcourse_step2_out_m5_": "مثال: كيفية التنقل في واجهة Photoshop.",
  "_createcourse_step2_out_m6_": "مثال: تقنيات اختيار الصور ومعالجتها.",
  "_createcourse_step2_out_m7_": "مثال: أساسيات التصميم الجرافيكي وتحرير الصور.",
  "_createcourse_step2_input_m1_": "متأكد من حذف النقطة الرئيسية؟",
  "_createcourse_step4_m1_": "طريقة التعلم",
  "_createcourse_step4_m2_": "يسير بخطى ذاتية",
  "_createcourse_step4_m3_": "يمكن للطلاب دراسة الدورة بمفردهم دون تدخل المعلم.",
  "_createcourse_step4_m4_": "بقيادة المدرب",
  "_createcourse_step4_m5_": "دورة",
  "_createcourse_step4_m6_": "يمكن للمدرس تعليم الأفراد أو مجموعة من الطلاب من خلال جلسات عبر الإنترنت.",
  "_createcourse_step4_m7_": "واحد لواحد",
  "_createcourse_step4_m8_": "التدريس",
  "_createcourse_step4_m9_": "يمكن للمدرس إجراء جلسات شخصية عبر الإنترنت حسب طلب الطالب.",
  "_createcourse_step4_m10_": "واحد لكثير",
  "_createcourse_step4_m11_": "يمكن للمدرس جدولة جلسات عبر الإنترنت وفقًا لتقديره لمجموعة من الطلاب.",
  "_createcourse_step5_m1_": "سيتم تقديم الدورة التدريبية الخاصة بك للمراجعة. بمجرد مراجعة الدورة والموافقة عليها، سيتم نشر الدورة التدريبية الخاصة بك على المنصة.",
  "_createcourse_step3_m1_": "إعادة الترتيب",
  "_createcourse_step3_m2_": "عرض الشريحة",
  "_createcourse_step3_m3_": "أدخل عنوانا",
  "_createcourse_step3_m4_": "الرجاء إدخال عنوان القسم",
  "_createcourse_step3_m5_": "حفظ القسم",
  "_createcourse_step3_m6_": "الانزلاق",
  "_createcourse_step3_m7_": "حدد القسم",
  "_createcourse_step3_m8_": "عنوان الشريحة",
  "_createcourse_step3_m9_": "الرجاء إدخال عنوان الشريحة",
  "_createcourse_step3_m10_": "وصف الشريحة بالتفصيل",
  "_createcourse_step3_m11_": "وصف الشريحة",
  "_createcourse_step3_m12_": "الرجاء إدخال وصف قصير",
  "_createcourse_step3_m13_": "يمكن أن يؤثر استخدام الرموز النقطية في المحتوى النصي سلبًا على جودة الفيديو عند تحويل النص إلى فيديو.",
  "_createcourse_step3_m14_": "عند استخدام ميزة تحويل النص إلى صوت، يرجى استخدام رمز النقطتين: في نهاية العنوان للإشارة إلى توقف مؤقت في الصوت، مما يسمح بإيقاع كلام طبيعي وواضح.",
  "_createcourse_step3_m15_": "توليد الفيديو",
  "_createcourse_step3_m16_": "توليد الفيديو والنص",
  "_createcourse_step3_m17_": "حفظ الشريحة",
  "_createcourse_step3_m18_": "مادة",
  "_createcourse_step3_m19_": "عنوان الملف",
  "_createcourse_step3_m20_": "الرجاء إدخال عنوان المادة",
  "_createcourse_step3_m21_": "الملف المحدد",
  "_createcourse_step3_m22_": "يمكنك تحميل ملفات PDF وWord وPPT والفيديو والصورة وSCORM وTINCAN",
  "_createcourse_step3_m23_": "إنشاء نسخة",
  "_createcourse_step3_m24_": "تحميل النص",
  "_createcourse_step3_m25_": "الملف الحالي",
  "_createcourse_step3_m26_": "يمكنك تحميل ملف النص",
  "_createcourse_step3_m27_": "الرجاء تحديد ملف المواد.",
  "_createcourse_step3_m28_": "حفظ المواد",
  "_createcourse_step3_m29_": "تحرير المهمة",
  "_createcourse_step3_m30_": "إضافة مهمة",
  "_createcourse_step3_m31_": "عنوان المهمة",
  "_createcourse_step3_m32_": "الرجاء إدخال عنوان المهمة.",
  "_createcourse_step3_m33_": "مجموع درجات الواجب",
  "_createcourse_step3_m34_": "أدخل مجموع علامات المهمة",
  "_createcourse_step3_m35_": "من فضلك مجموع درجات المهمة",
  "_createcourse_step3_m36_": "هل تحتاج إلى مساعدة الذكاء الاصطناعي في تقييم المهمة؟",
  "_createcourse_step3_m37_": "الرجاء تحديد أي خيار",
  "_createcourse_step3_m38_": "يرجى كتابة معايير تصنيف مهمتك",
  "_createcourse_step3_m39_": "اشرح معايير تصنيف مهمتك باختصار.",
  "_createcourse_step3_m40_": "وصف للمهمة",
  "_createcourse_step3_m41_": "الرجاء إدخال وصف المهمة.",
  "_createcourse_step3_m42_": "حفظ الواجب",
  "_createcourse_step3_m43_": "إنشاء مهمة",
  "_createcourse_step3_m44_": "نبذة تقريبية عن المهمة",
  "_createcourse_step3_m45_": "أدخل وصف مهمتك هنا. على سبيل المثال: قم بإنشاء مهمة تتمحور حول [الموضوع/المجال]، مع تحديد [المهمة/المتطلبات] المطلوبة. يجب أن يكون الوصف واضحًا وشاملاً، مع تفصيل الأهداف والغايات، بالإضافة إلى أي تعليمات أو قيود محددة.",
  "_createcourse_step3_m46_": "شكل التقديم",
  "_createcourse_step3_m47_": "مستند مكتوب (DOC، DOCX، PDF، TXT، إلخ.)",
  "_createcourse_step3_m48_": "العرض التقديمي (PPT أو PPTX)",
  "_createcourse_step3_m49_": "فيديو (MP4، AVI، MKV، إلخ.)",
  "_createcourse_step3_m50_": "الصوت (MP3، WAV، AAC، إلخ.)",
  "_createcourse_step3_m51_": "صورة (JPEG، PNG، GIF، BMP، إلخ.)",
  "_createcourse_step3_m52_": "تنسيق جداول البيانات (XLS، XLSX، CSV، إلخ.)",
  "_createcourse_step3_m53_": "تحرير فيديو يوتيوب",
  "_createcourse_step3_m54_": "إضافة فيديو يوتيوب",
  "_general_profile_comwebsite_vali_m1_": "الرجاء الدخول على موقع الشركة.",
  "_general_profile_upload_file_": "الملف الذي تم تحميله",
  "_general_profile_about_me_": "قدّم شركتك وشاركنا حول رحلتها المهنية وإنجازاتها هنا.",
  "_general_profile_about_me_vali_m1_": "الرجاء إدخال حول الوصف",
  "_profile_picture_update_w_m1_": "تحذير بشأن تحديث صورة الملف الشخصي",
  "_profile_picture_update_w_m2_": "ملاحظة هامة: \nصورة ملفك الشخصي الجديدة لا تتطابق مع مستند KYC المقدم، إذا كنت لا تزال تقوم بتحديث صورة ملفك الشخصي، فسيتعين عليك متابعة عملية KYC مرة أخرى. \nتابع بحذر. \nهل تريد تحديث الصورة الشخصية؟",
  "_profile_picture_update_w_m3_": "أفهم. تحديث الصورة الشخصية.",
  "_e_education_vali_m1_": "الرجاء إدخال اسم المدرسة",
  "_e_education_vali_m2_": "الرجاء إدخال 3 أحرف على الأقل.",
  "_e_education_vali_m3_": "لا يمكن أن يزيد اسم المدرسة عن 80 حرفًا.",
  "_e_education_vali_m4_": "الرجاء إدخال الدرجة",
  "_e_education_vali_m5_": "الرجاء إدخال مجال الدراسة",
  "_e_education_vali_m6_": "مجال الدراسة لا يمكن أن يكون أكثر من 80 حرفا.",
  "_e_education_vali_m7_": "الرجاء إدخال الدرجة بالنمط التالي: A+، B",
  "_e_education_vali_m8_": "يجب أن يتطابق مع النمط التالي: A+, B",
  "_e_education_vali_m9_": "الرجاء تحديد تاريخ البدء",
  "_e_education_vali_m10_": "الرجاء تحديد تاريخ الانتهاء",
  "_lp_learning_preferences_profe_vali_m1_": "الرجاء تحديد مهنتك.",
  "_lp_learning_preferences_profe_vali_m2_": "قيمة غير صالحة",
  "_lp_learning_preferences_profe_vali_m3_": "الرجاء إدخال الصناعة.",
  "_lp_learning_preferences_profe_vali_m4_": "الرجاء إدخال منطقة",
  "_lp_learning_preferences_profe_vali_m5_": "أدخل تفضيلات التعلم",
  "_lp_learning_preferences_profe_vali_m6_": "أدخل تفضيلات التعلم.",
  "_lp_learning_preferences_profe_vali_m7_": "الرجاء تحديد لغة واحدة على الأقل",
  "_lp_learning_preferences_profe_vali_m8_": "الرجاء تحديد أي تفضيل واحد.",
  "_lp_learning_preferences_profe_vali_m9_": "اتصالات من الدورة ودائرة التعلم",
  "_as_pass_vali_m1_": "الرجاء إدخال كلمة المرور.",
  "_as_pass_vali_m2_": "نعم، احذف حسابي.",
  "_as_pass_vali_m3_": "هل انت متأكد انك تريد حذف حسابك؟",
  "_as_pass_vali_m4_": "الرجاء إدخال البريد الإلكتروني.",
  "_as_pass_vali_m5_": "يجب أن تحتوي كلمة المرور على حرف ورقم ورمز خاص على الأقل.",
  "_as_pass_vali_m6_": "كلمة المرور لا يمكن أن تكون أقل من 8 أحرف.",
  "_as_pass_vali_m7_": "كلمة المرور لا يمكن أن تكون أكثر من 50 حرفا.",
  "_as_pass_vali_m8_": "تأكيد كلمة المرور لا يمكن أن يكون أقل من 8 أحرف.",
  "_as_pass_vali_m9_": "تأكيد كلمة المرور لا يمكن أن يكون أكثر من 50 حرفا.",
  "_as_pass_vali_m10_": "تأكيد كلمة المرور غير متطابقة.",
  "_as_pass_vali_m11_": "الرجاء إدخال تأكيد كلمة المرور.",
  "_as_pass_vali_m12_": "يجب أن يحتوي على كلمة المرور:",
  "_as_pass_vali_m13_": "8 أحرف أو أكثر كحد أدنى",
  "_as_pass_vali_m14_": "حرف واحد أو أكثر مع أرقام",
  "_as_pass_vali_m15_": "واحد أو أكثر من الأحرف الصغيرة",
  "_as_pass_vali_m16_": "حرف كبير واحد أو أكثر",
  "_as_pass_vali_m17_": "واحد أو أكثر من الشخصيات الخاصة",
  "_as_pass_vali_m18_": "مطابقة كلمة المرور مع تأكيد كلمة المرور",
  "_as_pass_vali_m19_": "يجب أن تتبع كلمة المرور القواعد المذكورة أعلاه.",
  "_as_pass_vali_m20_": "أدخل كلمة المرور الحالية",
  "_as_pass_vali_m21_": "الرجاء إدخال كلمة المرور الحالية.",
  "_as_pass_vali_m22_": "يؤكد",
  "_mate_listslide_m1_": "تحويل إلى فيديو",
  "_mate_listslide_m2_": "عرض وتحرير",
  "_mate_listslide_m3_": "سيتم تحويل هذه الشريحة إلى ملف فيديو. هل أنت متأكد أنك تريد تحويل هذه الشريحة إلى ملف فيديو؟",
  "_mate_listslide_m4_": "يتم تشغيل هذه الميزة بواسطة الذكاء الاصطناعي، ولا يكون الإخراج دائمًا دقيقًا بنسبة 100%. يرجى التحقق جيدًا من المخرجات التي تم إنشاؤها قبل استخدامها.",
  "_mate_popquiz_m1_": "تحرير الاختبار",
  "_mate_popquiz_m2_": "إضافة مسابقة",
  "_mate_popquiz_m3_": "عنوان الإختبار",
  "_mate_popquiz_m4_": "الرجاء إدخال عنوان الاختبار.",
  "_mate_popquiz_m5_": "لا يمكن أن يزيد الوصف عن 500 حرف.",
  "_mate_popquiz_m6_": "الرجاء إدخال وصف الاختبار.",
  "_mate_popquiz_m7_": "سؤال",
  "_mate_popquiz_m8_": "أدخل السؤال",
  "_mate_popquiz_m9_": "الرجاء إدخال السؤال.",
  "_mate_popquiz_m10_": "إجابة",
  "_mate_popquiz_m11_": "أضف إجابة",
  "_mate_popquiz_m12_": "الرجاء إدخال الإجابة.",
  "_mate_popquiz_m13_": "اشرح لماذا تعتبر هذه الإجابة هي الأفضل أم لا.",
  "_createcourse_step2_m14_": "أضف سؤال",
  "_createcourse_step2_m15_": "حفظ الاختبار",
  "_mate_p2_m1_": "عنوان مقطع الفيديو",
  "_mate_part2_m2_": "أدخل عنوان الفيديو",
  "_mate_part2_m3_": "الرجاء إدخال عنوان الفيديو.",
  "_mate_part2_m4_": "رابط الفيديو",
  "_mate_part2_m5_": "أدخل عنوان URL لفيديو YouTube",
  "_mate_part2_m6_": "الرجاء إدخال عنوان URL للفيديو.",
  "_mate_part2_m7_": "حفظ الفيديو",
  "_mate_part2_m8_": "تحميل الدورة التدريبية بأكملها (ملف مضغوط)",
  "_mate_part2_m9_": "تحميل ملف مضغوط",
  "_mate_part2_m10_": "يمكنك تحميل ملف ZIP فقط.",
  "_mate_part2_m11_": "تحميل الرمز البريدي",
  "_mate_part2_m12_": "تحميل الدورة التدريبية بأكملها (مجلد)",
  "_mate_part2_m13_": "تحميل المجلد",
  "_mate_part2_m14_": "المجلد المحدد:",
  "_mate_part2_m15_": "تحميل المجلد",
  "_mate_part2_m16_": "متصفحك لا يدعم إطارات iframe",
  "_mate_part2_m17_": "تحميل المواد",
  "_mate_add_model_m1_": "إضافة قسم",
  "_mate_add_model_m2_": "أضف شريحة",
  "_mate_add_model_m3_": "رفع ملف",
  "_mate_add_model_m4_": "تحميل الرمز البريدي",
  "_mate_add_model_m5_": "تحميل المجلد",
  "_mate_add_model_m6_": "بنك الأسئلة",
  "_mate_add_model_m7_": "جارٍ حفظ الأسئلة... الرجاء الانتظار",
  "_mate_add_model_m8_": "إضافة مهمة",
  "_mate_add_model_m9_": "جارٍ إنشاء الأسئلة... الرجاء الانتظار",
  "_mate_add_model_m10_": "إضافة فيديو يوتيوب",
  "_mate_model1_m1_": "إضافة قائمة تشغيل يوتيوب",
  "_mate_model1_m2_": "عنوان قائمة التشغيل (سيكون هذا هو عنوان القسم الخاص بك)",
  "_mate_model1_m3_": "أضف هذا الفيديو إلى قائمة التشغيل",
  "_mate_model1_m4_": "جارٍ جلب مقاطع الفيديو...يرجى الانتظار",
  "_mate_model1_m5_": "تحميل مقاطع الفيديو",
  "_mate_model1_m6_": "واضح",
  "_mate_model1_m7_": "جارٍ إنشاء قائمة التشغيل...يرجى الانتظار",
  "_mate_model1_m8_": "إنشاء قائمة التشغيل",
  "_mate_add_model_m7_": "جارٍ حفظ الأسئلة... الرجاء الانتظار",
  "_mate_add_model_m8_": "حفظ الأسئلة",
  "_mate_add_model_m9_": "جارٍ إنشاء الأسئلة... الرجاء الانتظار",
  "_mate_add_model_m10_": "توليد الأسئلة",
  "_mate_add_model_m11_": "حذف المواد المحددة",
  "_mate_add_model_m12_": "تحويل الشرائح المحددة إلى فيديو",
  "_ekyc_alert_m1_": "إشعار مهم - عملية التحقق من KYC",
  "_ekyc_alert_m2_": "يرجى ملاحظة أنه بمجرد اكتمال عملية التحقق من هويتك، لن يكون من الممكن تغيير اسمك الأول أو اسم العائلة دون إعادة التحقق من هويتك.",
  "_ekyc_alert_m3_": "تابع عملية اعرف عميلك الإلكترونية (e-KYC).",
  "_ekyc_alert_m4_": "إلغاء عملية KYC الإلكترونية",
  "_ekyc_alert_m5_": "نحن نتحقق من مستنداتك...يرجى الانتظار",
  "_ekyc_alert_m6_": "المستندات المطلوبة",
  "_ekyc_alert_m7_": "يرجى تحميل المستندات الخاصة بك قبل الإرسال.",
  "_ekyc_alert_m8_": "حالة KYC الإلكترونية",
  "_ekyc_alert_m9_": "تمت الموافقة على خدمة KYC الإلكترونية الخاصة بك.",
  "_ekyc_alert_m10_": "لقد تم رفض e-KYC الخاص بك، يرجى المحاولة مرة أخرى.",
  "_ekyc_alert_m11_": "المستند الذي قدمته لا يحمل وجهك، يرجى تحميل مستندات صالحة.",
  "_ekyc_alert_m12_": "يتم الآن التحقق من المستندات الخاصة بك.",
  "_ekyc_alert_m13_": "فشل تطبيق KYC الإلكتروني",
  "_ekyc_alert_m14_": "المستندات التي قدمتها غير صالحة أو لا تفي بالكامل بمتطلبات e-KYC الخاصة بنا.",
  "_ekyc_alert_m15_": "يرجى تحميل المستندات الخاصة بك قبل الإرسال.",
  "_dh_alert_m1_": "غير كلمة المرور الخاصة بك.",
  "_dh_alert_m2_": "حسابك لديه كلمة مرور افتراضية. هل ترغب في تعيين كلمة المرور الآن؟.",
  "_dh_announcements_latest_anno_": "أحدث الإعلانات",
  "_lcform_max_lc_limit_m1_": "لقد وصلت إلى الحد الأقصى لعدد دوائر التعلم التي يمكنك إنشاؤها. لإنشاء دائرة تعليمية جديدة، سيُطلب منك إما مغادرة دائرة تم إنشاؤها مسبقًا أو إغلاقها.",
  "_managelc_invite_email_vali_m31_": "يمكن إرسال 30 دعوة كحد أقصى في المرة الواحدة.",
  "_managelc_invite_email_vali_m32_": "بعض رسائل البريد الإلكتروني مكررة ولا تتم إضافتها إلى القائمة.",
  "_managelc_invite_email_vali_m33_": "هذا البريد الإلكتروني موجود بالفعل في القائمة.",
  "_managelc_invite_email_vali_m34_": "هذا البريد الإلكتروني موجود بالفعل في قائمة الموظفين لديك.",
  "_managecour_msg_m1_": "سيؤدي هذا إلى تمكين ميزة التقدير لهذه الدورة.",
  "_managecour_msg_m2_": "سيؤدي هذا إلى تعطيل ميزة وضع الدرجات لهذه الدورة التدريبية.",
  "_managecour_msg_m3_": "انتباه!",
  "_managecour_msg_m4_": "سيؤدي تحرير تفاصيل الدورة التدريبية الخاصة بك إلى إلغاء نشر الدورة التدريبية الخاصة بك. بمجرد التعديل، سيُطلب منك إرسال الدورة مرة أخرى للمراجعة. يعد ذلك ضروريًا لأغراض مراقبة الجودة وللتأكد من أن الدورة التدريبية الخاصة بك تلبي معاييرنا. هل أنت متأكد أنك تريد متابعة التحرير؟",
  "_managecour_msg_m5_": "متأكد من تغيير الخصوصية؟",
  "_managecour_msg_m6_": "وضع العلامات",
  "_managecour_msg_m7_": "عرض الطلاب",
  "_managecour_courstudent_m1_": "طلاب الدورة",
  "_managecour_courstudent_m2_": "سيظهر هنا الطلاب الذين سجلوا هذه الدورة.",
  "_managecour_courstudent_m3_": "البحث عن الطلاب بالاسم",
  "_managecour_inviteviaemail_m1_": "البريد الإلكتروني موجود بالفعل في قائمة طلاب الدورة التدريبية.",
  "_managecour_inviteviaemail_m2_": "رسالة دعوة",
  "_managecour_inviteviaemail_m3_": "يسعدني أن أتقدم بدعوة شخصية للانضمام إلى الدورة التدريبية الخاصة بي على EduKula، حيث ستتاح لك الفرصة لتوسيع معرفتك ومهاراتك من خلال المحاضرات الجذابة والاختبارات والواجبات العملية.",
  "_managecour_invitevialink_m1_": "تم نسخ الرابط!",
  "_managecour_reorder_m1_": "إعادة ترتيب المواد",
  "_managecour_session_m1_": "إضافة جلسة",
  "_managecour_session_m2_": "إضافة جلسة جديدة",
  "_managecour_session_m3_": "التاريخ والوقت",
  "_managecour_session_m4_": "بتوصية من",
  "_pagenotfound_m1_": "الموارد غير موجود",
  "_pagenotfound_m2_": "عُد",
  "_pagenotfound_m3_": "الصفحة الرئيسية",
  "_ca_msg_m1_": "مرحبًا بك في المستشار الوظيفي في Edukula.",
  "_ca_msg_m2_": "أنا طالب",
  "_ca_msg_m3_": "أعذب",
  "_ca_msg_m4_": "أنا أعذب",
  "_ca_msg_m5_": "العمل المهني",
  "_ca_msg_m6_": "أنا محترف في العمل",
  "_ca_msg_m7_": "اكتب رسالة ثم اضغط على زر الإدخال...",
  "_ca_msg_m8_": "دردشة واضحة",
  "_sa_warn_m1_": "نوع التقييم الذاتي",
  "_sa_warn_m2_": "الرجاء اختيار الخيار الذي يناسب احتياجاتك. سيساعدنا هذا على تصميم تجربة التقييم الذاتي وفقًا لأهدافك المحددة.",
  "_sa_warn_m3_": "يتم تشغيل هذه الميزة بواسطة الذكاء الاصطناعي، ولا يكون الإخراج دائمًا دقيقًا بنسبة 100%. يرجى التحقق جيدًا من المخرجات التي تم إنشاؤها قبل استخدامها.",
  "_sa_warn_m4_": "التقدم الوظيفي",
  "_sa_warn_m5_": "فقط للممارسة",
  "_sa_warn_m6_": "يتم الآن إنشاء الاختبار الخاص بك... الرجاء الانتظار",
  "_sa_area_of_assessment_": "المنطقة/موضوع التقييم",
  "_sa_area_of_assessment_vali_m1_": "المنطقة/الموضوع لا يمكن أن يزيد عن 80 حرفًا.",
  "_sa_area_of_assessment_vali_m2_": "الرجاء إدخال 3 أحرف على الأقل.",
  "_sa_area_of_assessment_place_": "أدخل المنطقة/موضوع التقييم",
  "_sa_area_of_assessment_vali_m3_": "الرجاء إدخال المنطقة/الموضوع.",
  "_sa_eligibility_type_": "نوع الأهلية",
  "_sa_eligibility_type_opt1_": "أنا أتحقق من أهليتي للتقدم للدورة",
  "_sa_eligibility_type_opt2_": "أنا أتحقق من أهليتي للتقدم للحصول على وظيفة",
  "_sa_eligibility_type_vali_m1_": "الرجاء تحديد القسم.",
  "_sa_noofques_label_": "عدد الأسئلة",
  "_sa_noofques_vali_m1_": "الرجاء إدخال المنطقة/الموضوع.",
  "_sa_generate_quiz_": "إنشاء مسابقة",
  "_sa_resultmsg_m1_": "لقد سجلت",
  "_sa_resultmsg_m2_": "تذكر، هناك دائمًا مجال للتحسين، حتى بالنسبة للذكاء الاصطناعي مثلي. استمر في التدريب، وسترى التقدم قريبًا!",
  "_sa_resultmsg_m3_": "جهد عظيم على درجاتك! استمر في التدريب وستكون جاهزًا للفصل التالي في حياتك المهنية.",
  "_sa_resultmsg_m4_": "عمل عظيم! لقد أعدك عملك الجاد للفصل التالي في حياتك المهنية.",
  "_sa_resultmsg_m5_": "لقد أكملت الاختبار.",
  "_vacancy_alert_m1_": "سيؤدي هذا إلى تفعيل الشغور.",
  "_vacancy_alert_m2_": "سيؤدي هذا إلى إلغاء تنشيط الوظيفة الشاغرة.",
  "_vacancy_alert_m3_": "الدورة التدريبية المرتبطة حاليًا بالوظيفة الشاغرة إما لم يتم نشرها بعد على EduKula أو تواجه مشكلة أخرى. يرجى التحقق من نشر الدورة التدريبية أو تعديل الوظيفة الشاغرة وربطها بدورة تدريبية أخرى منشورة ذات صلة لتفعيل الوظيفة الشاغرة.",
  "_vacancy_alert_m4_": "هل ترغب في تعديل الوظيفة الشاغرة وتغيير الدورة؟",
  "_vacancy_alert_m5_": "الدورة معطلة",
  "_v_delete_sucees_m1_": "حذف بنجاح",
  "_v_list_msg_m1_": "لقد تم إنشاء وظيفتك الشاغرة بنجاح لإدراجها في قائمة دورات التوظيف في EduKula.",
  "_v_list_msg_m2_": "إذا كانت الدورة التدريبية الخاصة بك قيد المراجعة، فسوف تبدأ في الظهور في القائمة بمجرد نشرها. هل ترغب في المضي قدمًا ودعوة المواهب لتعلم الدورة؟",
  "_v_list_msg_m3_": "نعم، دعونا ندعو المواهب",
  "_vacancy_add_form_generate_btn_": "إنشاء الوصف باستخدام الذكاء الاصطناعي",
  "_vacancy_email_limit_m1_": "يمكن إرسال 30 رسالة بريد إلكتروني كحد أقصى في المرة الواحدة.",
  "_vacancy_add_note_place_": "يمكنك كتابة ملاحظاتك هنا",
  "_vacancy_share_report_m1_": "تمت مشاركة التقرير.",
  "_vacancy_share_report_m2_": "تم إرسال بريد إلكتروني إلى عنوان (عناوين) البريد الإلكتروني التي قدمتها.",
  "_vacancy_alert_msg_m1_": "أضف ملاحظة",
  "_vacancy_alert_msg_m2_": "قبل إجراء أي تغييرات، يلزم إضافة ملاحظة للمرجع...",
  "_vacancy_alert_msg_m3_": "تذكر أنه من الضروري تضمين ملاحظة. لن يتم تحديث حالة التوظيف التي حددتها إلا إذا قمت بإضافة ملاحظة.",
  "_vacancy_alert_msg_m4_": "تحتاج إلى كتابة شيء ما!",
  "_emp_addemp_csv_m1_": "ملف CSV فارغ، يرجى تنزيل ملف نموذجي للتنسيق ثم تحريره",
  "_emp_addemp_csv_m2_": "ملف CSV ليس بالتنسيق الصحيح، يرجى تنزيل ملف نموذجي للتنسيق ثم تحريره",
  "_emp_addemp_csv_m3_": "الرجاء تحديد ملف CSV.",
  "_report_msg_m1_": "تقرير مع تفاصيل الموظفين",
  "_report_msg_m2_": "تصدير إلى CSV",
  "_report_msg_m3_": "تقرير تسجيل دخول / خروج الموظفين (آخر 7 أيام)",
  "_report_msg_m4_": "تقرير تسجيل الدخول / الخروج (آخر 7 أيام)",
  "_report_msg_m5_": "المتدربون المسجلون في الدورة",
  "_report_msg_m6_": "تقرير حالة دورة الموظف",
  "_report_msg_m7_": "تقرير حالة دورة الموظف",
  "_noauthcerti_msg_m1_": "يمكنك تنزيل جميع شهادات إتمام الدورة التدريبية الخاصة بك عن طريق النقر فوق زر التنزيل أدناه",
  "_noauthcerti_msg_m2_": "استكشاف المزيد",
  "_noauthcerti_msg_m3_": "تحميل الشهادة",
  "_courprogress_msg_m1_": "الشرائح المكتملة",
  "_courprogress_msg_m2_": "الشرائح المتبقية",
  "_courprogress_msg_m3_": "العودة إلى الدورة",
  "_courprogress_msg_m4_": "إجمالي الشرائح",
  "_courprogress_msg_m5_": "الشرائح المكتملة",
  "_e_experience_vali_m11_": "الرجاء تحديد نوع الوظيفة",
  "_e_education_vali_m12_": "الرجاء إدخال الموقع",
  "_e_education_vali_m13_": "لا يمكن أن يزيد طول الموقع عن 80 حرفًا.",
  "_e_education_vali_m14_": "الرجاء إدخال الصناعة",
  "_e_education_vali_m15_": "الرجاء إدخال حرفين على الأقل.",
  "_e_education_vali_m16_": "لا يمكن أن تزيد الصناعة عن 80 حرفًا.",
  "_lcmaterial_msg_m1_": "الرجاء تحديد ملف صالح",
  "_lcmaterial_msg_m2_": "الملف",
  "_lcmaterial_msg_m3_": "الذي تحاول تحميله غير مدعوم من EduKula. يرجى التأكد من أن ملفك يتوافق مع تنسيقات الملفات المدعومة لدينا، والتي تشمل: PDF وWord وPPT وAudio وVideo وImage وZIP وSCORM وTINCAN",
  "_lcmaterial_msg_m4_": "تنسيق ملف غير معتمد",
  "_lcmaterial_msg_m5_": "متأكد من حذف قسم المواد؟",
  "_lcmaterial_msg_m6_": "متأكد من حذف شريحة المواد؟",
  "_lcmaterial_msg_m7_": "قائمة مواد",
  "_lcmaterial_msg_m8_": "مواد البحث",
  "_lcmaterial_msg_m9_": "إضافة بعد",
  "_lcmaterial_msg_m10_": "حدد الموقف",
  "_lcmaterial_msg_m11_": "بعد",
  "_lcmaterial_msg_m12_": "الرجاء تحديد موضع القسم",
  "_lcmaterial_msg_m13_": "وصف قصير",
  "_lcmaterial_msg_m14_": "لا يمكن أن يزيد الوصف المختصر عن 200 حرف.",
  "_lcmaterial_msg_m15_": "الرجاء إدخال وصف قصير.",
  "_lcmaterial_msg_m16_": "الرجاء إدخال عنوان الشريحة.",
  "_lcmaterial_msg_m17_": "عن الشريحة..",
  "_lcmaterial_msg_m18_": "الرجاء إدخال وصف قصير.",
  "_lcmaterial_msg_m19_": "عرض المحتوى",
  "_lcmaterial_msg_m20_": "تمت الإضافة بواسطة",
  "_lcmaterial_msg_m21_": "التاريخ والوقت",
  "_lcmaterial_msg_m22_": "لا توجد مواد تم تحميلها",
  "_lcmaterial_msg_m23_": "لم تتم إضافة أي أقسام أو مواد",
  "_lcmember_msg_m1_": "سيظهر هنا الأشخاص والصفحات الجديدة التي تنضم إلى هذه المجموعة.",
  "_lcmember_msg_m2_": "المشرفين",
  "_lcmember_msg_m3_": "العثور على عضو",
  "_lcmember_msg_m4_": "استبعاد كمسؤول",
  "_lcmember_msg_m5_": "طرد",
  "_lcmember_msg_m6_": "لم يتم العثور على أعضاء",
  "_lcjoinrequest_msg_m1_": "طلبات الانضمام",
  "_lcjoinrequest_msg_m2_": "وقد طلب الانضمام إلى الدائرة",
  "_lcjoinrequest_msg_m3_": "يقبل",
  "_lcjoinrequest_msg_m4_": "لم يتم العثور على أي طلب.",
  "_lcsendinvitation_msg_m1_": "تم إرسال الدعوة إلى",
  "_lcsendinvitation_msg_m2_": "بواسطة",
  "_lcsendinvitation_msg_m3_": "لم تكن هناك دعوات مرسلة.",
  "_mentorpre_msg_m11_": "أدخل تفضيلات المرشد",
  "_mentorpre_msg_m12_": "من فضلك أدخل لماذا يجب أن يتعلم الطلاب منك",
  "_vacancy_vali_m1_": "مطلوب منصب وظيفي",
  "_vacancy_vali_m2_": "الرجاء اختيار الدورة",
  "_vacancy_vali_m3_": "الرجاء إدخال 50 حرفًا كحد أقصى.",
  "_vacancy_vali_m4_": "الرجاء إدخال الخبرة",
  "_role_vali_m1_": "اسم الدور مطلوب",
  "_rolename_vali_m1_": "اسم الدور مطلوب",
  "_empform_vali_msg_m1_": "يرجى إدخال البريد الإلكتروني الصحيح",
  "_empform_vali_msg_m2_": "يجب أن تتكون كلمة المرور من ثمانية أحرف على الأقل ويجب أن تحتوي على حرف ورقم ورمز خاص على الأقل.",
  "_tutorprofile_msg_m1_": "إجمالي الطلاب",
  "_tutorprofile_msg_m2_": "التعليقات",
  "_tutorprofile_msg_m3_": "الدورات",
  "_tutorprofile_msg_m4_": "لا حول معلومات",
  "_aboutus_msg_m1_": "حول إيدوكولا",
  "_aboutus_msg_m2_": "EduKula هي منصة تعليمية مستقبلية تقدم العديد من الدورات التدريبية في أنواع مختلفة للمتعلمين المتحمسين. إنها منصة كاملة عبر الإنترنت، توفر سوقًا للدورات التدريبية حول موضوعات مختلفة مثل تطوير الويب، والتصميم، والتسويق، وريادة الأعمال، والفن، والموسيقى، والتمويل، وأسلوب الحياة، وتنمية الشخصية، والرسوم المتحركة، والإدارة، وغيرها الكثير. تدعم EduKula الخبراء والمهنيين والمتخصصين في هذا المجال لإنشاء دورات مختلفة ومشاركة معارفهم مع عدد كبير من الأشخاص. لقد قمنا بإنشاء مجتمع قائم على المعرفة بدعم من المعلمين البارعين والمتعلمين المتحمسين من كل ركن من أركان العالم.",
  "_aboutus_msg_m3_": "40 م",
  "_aboutus_msg_m4_": "اللغات",
  "_aboutus_msg_m5_": "480 م",
  "_aboutus_msg_m6_": "التسجيلات",
  "_aboutus_msg_m7_": "بلدان",
  "_aboutus_msg_m8_": "عملاء المؤسسات",
  "_aboutus_msg_m9_": "موثوق به",
  "_contactus_vali_ma_": "يرجى إكمال التحقق من reCAPTCHA.",
  "_contactus_msg_m1_": "العنوان والساعات",
  "_contactus_msg_m2_": "ارسل رسالة",
  "_contactus_msg_m3_": "لن يتم نشر عنوان بريدك الإلكتروني. تم وضع علامة على الحقول المطلوبة",
  "_contactus_msg_m4_": "الرجاء إدخال اسم صالح.",
  "_contactus_msg_m5_": "لا يمكن أن يزيد الاسم عن 30 حرفًا.",
  "_contactus_msg_m6_": "اسمك",
  "_contactus_msg_m7_": "من فضلك أدخل إسمك.",
  "_contactus_msg_m8_": "بريدك الالكتروني",
  "_contactus_msg_m9_": "رجاءا أدخل بريدك الإلكتروني.",
  "_contactus_msg_m10_": "لا يمكن أن تزيد الرسالة عن 500 حرف.",
  "_contactus_msg_m11_": "رسالة",
  "_contactus_msg_m12_": "أدرج رسالتك من فضلك.",
  "_contactus_msg_m13_": "شروط الاستخدام",
  "_contactus_msg_m14_": "أنا أوافق على",
  "_contactus_msg_m15_": "وقد قرأت",
  "_contactus_msg_m16_": "سياسة الخصوصية.",
  "_contactus_msg_m17_": "يرجى الموافقة على شروط الاستخدام وسياسة الخصوصية.",
  "_mylc_managelc_warn_m1_": "ملحوظة: - أنت تقوم بإنشاء دائرة تعليمية نيابة عن شركتك وجميع الحقوق المرتبطة بها هي ملكية خاصة لشركتك.",
  "_home2_msg_m1_": "لإنشاء دائرة تعليمية، يجب عليك تسجيل الدخول إلى المنصة. إذا لم يكن لديك حساب، فلا تقلق! يمكنك إنشاء واحدة بسهولة عن طريق النقر فوق الزر تسجيل أدناه.",
  "_home2_msg_m2_": "الصفحة الرئيسية",
  "_home2_msg_m3_": "تعزيز مهاراتك",
  "_home2_msg_m4_": "والتقدم في حياتك المهنية",
  "_home2_msg_m5_": "ماذا تريد أن تتعلم ؟",
  "_home2_msg_m6_": "الشركات",
  "_home2_msg_m7_": "حلول ل",
  "_home2_msg_m8_": "الشركات",
  "_home2_msg_m9_": "التواصل بين الأوساط الأكاديمية والشبابية",
  "_home2_msg_m10_": "يربط الشركات بالأوساط الأكاديمية لإنشاء دورات تدريبية ذات صلة بالصناعة، ويمكّن الشركات من استخدامها لتدريب المواهب وتوظيفها.",
  "_home2_msg_m11_": "تدريب فريقك",
  "_home2_msg_m12_": "توفير التدريب الشامل والدعم المستمر لتمكين الموظفين، وتعزيز أدائهم في الأدوار الوظيفية الحالية، وتسهيل التقدم الوظيفي.",
  "_home2_msg_m13_": "التقارير والتحليلات",
  "_home2_msg_m14_": "راقب أنشطة التعلم لموظفيك واحصل على رؤى قيمة تمكنك من اتخاذ قرارات مستنيرة لتحقيق أهداف مؤسستك.",
  "_home2_msg_m15_": "عمل",
  "_home2_msg_m16_": "المهنيين",
  "_home2_msg_m17_": "حلول ل",
  "_home2_msg_m18_": "المهنيين العاملين",
  "_home2_msg_m19_": "تنمو في حياتك المهنية",
  "_home2_msg_m20_": "سواء كنت تسعى لاكتساب مهارة جديدة أو ترغب في تعزيز مهاراتك الحالية من خلال تحسين المهارات أو إعادة المهارات، يمكن لمنصتنا أن تساعدك على تسريع حياتك المهنية.",
  "_home2_msg_m21_": "التعاون مع أقرانهم",
  "_home2_msg_m22_": "احصل على رؤى أعمق في مجال عملك من خلال إنشاء دائرة تعليمية أو الانضمام إليها والتعاون مع الأفراد ذوي التفكير المماثل وأقران الصناعة.",
  "_home2_msg_m23_": "نصيحة مهنية",
  "_home2_msg_m24_": "احصل على مشورة مهنية مخصصة بناءً على ملفك الشخصي، مما يساعدك على تحديد فرص التعلم المناسبة لتحسين مهاراتك والنمو في مجال عملك الحالي.",
  "_home2_msg_m25_": "والمرطبات",
  "_home2_msg_m26_": "الطلاب والمستجدين",
  "_home2_msg_m27_": "تصبح جاهزة للعمل",
  "_home2_msg_m28_": "ابق على اطلاع بمتطلبات الصناعة واكتسب خبرة عملية من خلال تعزيز مهاراتك ومعرفتك من خلال أفضل الدورات التدريبية ودوائر التعلم الشائعة.",
  "_home2_msg_m29_": "نصيحة مهنية",
  "_home2_msg_m30_": "بالنظر إلى تفاصيل ملفك الشخصي، يقترح النظام الدورات التدريبية الأكثر ملاءمة ودوائر التعلم والوظائف الشاغرة والمزيد لمساعدتك في بدء حياتك المهنية.",
  "_home2_msg_m31_": "الحصول على التعاقد وكسب المكافآت",
  "_home2_msg_m32_": "الدورات الدراسية التي تم تحميلها من قبل الشركات على EduKula، والتي تم تصميمها للعثور على المواهب الجديدة وتوظيفها، والحصول على مكافأة مالية مقابل حصولك على وظيفة أحلامك.",
  "_home2_msg_m33_": "دورات للباحثين عن عمل",
  "_home2_msg_m34_": "الدورات التدريبية التي أنشأتها الشركات التي تسعى إلى توظيف المواهب لوظيفة شاغرة معينة",
  "_home2_msg_m35_": "قمة",
  "_home2_msg_m36_": "الدورات",
  "_home2_msg_m37_": "استكشف دورات تدريبية عالية الجودة عبر الإنترنت حول مجموعة واسعة من المواضيع والتخصصات.",
  "_home2_msg_m38_": "تسخير قوة",
  "_home2_msg_m39_": "التعلم من الأقران",
  "_home2_msg_m40_": "هل تبحث عن منصة قوية لرفع مستوى التعلم الخاص بك؟ يمكن إثبات أن دائرة التعلم هي منقذك. إنها مجموعة دراسية غنية بالميزات تسهل على الأفراد التجمع والتفاعل مع بعضهم البعض لاكتساب المعرفة المتقدمة حول مواضيع مختلفة.",
  "_home2_msg_m41_": "تعرف أكثر",
  "_home2_msg_m42_": "إنشاء دائرة التعلم",
  "_home2_msg_m43_": "انضم إلى دائرة التعلم",
  "_home2_msg_m36_": "دوائر التعلم",
  "_home2_msg_m37_": "مجموعات التعلم التعاوني التي تربطك بنظرائك في الصناعة وتساعدك على تعزيز معرفتك",
  "_home2_msg_m44_": "المتعلمين",
  "_home2_msg_m45_": "المتعلمين",
  "_home2_msg_m46_": "يمكنك إنشاء الدورات التدريبية ونشرها على EduKula دون أي تكلفة، مع الاستمتاع بالتحكم الكامل في مواد الدورة التدريبية الخاصة بك وبدون أي رسوم خفية.",
  "_home2_msg_m47_": "كسب الإيرادات",
  "_home2_msg_m48_": "قم بتوليد الدخل من خلال تطوير دورات مخصصة خاصة بالصناعة لعملاء الشركات والتي تتوافق مع متطلباتهم التدريبية.",
  "_home2_msg_m49_": "تتبع نشاط المتعلم",
  "_home2_msg_m50_": "تقوم منصتنا بإنشاء تقارير تتتبع نشاط المتعلم، مما يوفر لك رؤى قيمة حول كيفية أداء دوراتك.",
  "_homesearch_msg_m1_": "بحث بكلمة مفتاحية",
  "_homesearch_msg_m2_": "نتائج",
  "_home2_msg_new_course_p_m1_": "استكشف دورات تدريبية عالية الجودة عبر الإنترنت حول مجموعة واسعة من المواضيع والتخصصات.",
  "_home2_courses_msg_m_": "الدورات",
  "_home2_msg_m37_11_": "مجموعات التعلم التعاوني التي تربطك بنظرائك في الصناعة وتساعدك على تعزيز معرفتك",
  "_home2_msg_m36_11_": "دوائر التعلم",
  "_blogs_msg_m1_": "لم يتم العثور على مدونات أو أخبار.",
  "_mate_add_model_m9_11_": "قائمة تشغيل يوتيوب تم إنشاؤها بواسطة الذكاء الاصطناعي",
  "_mate_add_slide_video_opt_not_": "لا تقم بإنشاء الفيديو",
  "_mate_video_upload_without_transcript_": "تحميل بدون نص",
  "_courseplay_assign_m1_": "لقد قمت بالفعل بإرسال هذه المهمة",
  "_courseplay_assign_m2_": "عرض الواجب المقدم",
  "_courseplay_assign_m3_": "انقر هنا لإعادة الإرسال.",
  "_courseplay_assign_m4_": "نتيجة",
  "_mate_converting_msg_m1_": "سيتم تحويل المحتوى النصي الخاص بك إلى مقطع فيديو قريبًا، اعتمادًا على طوله. شكرا لك على صبرك.",
  "_mate_converting_msg_m2_": "سيتم تحويل المحتوى النصي الخاص بك إلى مقطع فيديو قريبًا، اعتمادًا على طوله. شكرا لك على صبرك.",
  "_mate_converting_msg_m3_": "يقوم الذكاء الاصطناعي بإنشاء مواد الدورة التدريبية. شكرا لك على صبرك.",
  "_mate_converting_msg_m4_": "لقد تم تحويل شريحتك إلى ملف فيديو!",
  "_mate_converting_msg_m5_": "تمت إضافة مواد الدورة!"} 
export default aeJson;
