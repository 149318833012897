import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useParams } from "react-router-dom";
import { API_BASE } from "../../../constants/global";
import { Toaster } from "../../../entity/GlobalJS";
import ReactTextareaAutosize from "react-textarea-autosize";
import { useTranslation } from "react-i18next";

const PostPollPopup = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const { unique_id } = useParams();
    const OPTIONS_INITIAL_STATE = { value: "" };
    const durationL = { 1: '1 Day', 3: '3 Days', 7: '1 Week', 14: '2 Weeks' };
    const duration = [];
    const { t } = useTranslation();

    // form validation rules
    const validationSchema = Yup.object().shape({
        caption: Yup.string().nullable().max(300, 'Caption can not be more than 300 charaters.'),
        question: Yup.string().required('Please enter the question').max(300, 'Question can not be more than 300 charaters.'),
        option: Yup.array().of(
            Yup.object().shape({
                value: Yup.string().required('Please enter option value'),
            })
        ),
        duration: Yup.string().required('Please select the poll duration')
    });
    // functions to build form returned by useForm() and useFieldArray() hooks
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, control, handleSubmit, formState, clearErrors, reset } = useForm(formOptions);
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({ name: 'option', control });
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        remove(0);
        fetchDefaultOption();
    }, []);
    let fetchDefaultOption = () => {
        remove(0);
        append(OPTIONS_INITIAL_STATE);
        append(OPTIONS_INITIAL_STATE);
    }

    for (var key in durationL) {
        duration[key] = durationL[key];
    }

    const AddOption = () => {
        append(OPTIONS_INITIAL_STATE);
    }

    const RemoveOption = (i) => {
        remove(i);
    }

    const savePost = async (form) => {
        setSubmitting(true);
        const response = await fetch(`${API_BASE}post-create-poll`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
            body: JSON.stringify(form)
        });

        const data = await response.json();
        if (data.status) {
            props.feedPosted(data.data.feed);
            Toaster.success(data.message);
            document.getElementById('close_popup_poll').click();
            resetForm();
            setSubmitting(false);
            // clearForm();
        } else {
            Toaster.error(data.message);
            setSubmitting(false);
        }
    }

    const resetForm = () => {
        reset({
            caption: "",
            question: "",
            option: [OPTIONS_INITIAL_STATE, OPTIONS_INITIAL_STATE],
            duration: ""
        });
    };

    return (
        <>
            <div className="modal fade" id="pollModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('_managelc_postpoll_create_poll_')} </h4>
                            <button type="button" className="close" id="close_popup_poll" data-dismiss="modal" aria-label="Close" onClick={() => {
                                resetForm();
                            }}>
                                <span aria-hidden="true">X</span>
                            </button>
                        </div>
                        <form onSubmit={handleSubmit(savePost)}>
                            <input type="hidden" name="post_type" {...register(`post_type`)} value="3" />
                            <input type="hidden" name="group_id" {...register(`group_id`)} value={unique_id} />
                            <div className="modal-body scroll_add">
                                <div className="form_box">
                                    <div className="form-group">
                                        <label>{t('_managelc_postpoll_poll_caption_')} </label>
                                        <ReactTextareaAutosize
                                            name={`caption`}
                                            {...register(`caption`)}
                                            type="text"
                                            className={`form-control`}
                                            data-customcls="option"
                                            onChange={(e) => { clearErrors(`caption`); }}
                                            rows="10"
                                            cols="5"
                                            minRows={3}
                                            maxRows={5}
                                            placeholder={t('_managelc_postpoll_poll_caption_place_')}
                                        />
                                        <span className="text-danger">{errors.caption?.message}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>{t('_managelc_postpoll_question_')}*</label>
                                        <textarea name={`question`} {...register(`question`)} type="text" className={`form-control`} data-customcls="option" onChange={(e) => { clearErrors(`question`); }} rows="3" cols="10" placeholder="E.g., How do you commute to work?"></textarea>
                                        <span className="text-danger">{errors.question?.message}</span>
                                    </div>
                                    {fields.map((element, index) => (
                                        <div key={index}>
                                            <div className="form-group">
                                                <label>{t('_managelc_postpoll_question_opt_')} {index + 1}* {fields.length > 2 ? <span className="link" onClick={(e) => { RemoveOption(index); }}>{t('_remove_')} </span> : ''}</label>
                                                <input name={`option[${index}]value`} {...register(`option.${index}.value`)} type="text" className={`form-control`} onChange={(e) => { clearErrors(`option.${index}.value`); }} />
                                                <span className="text-danger">{errors.option?.[index]?.value?.message}</span>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="form-group">
                                        <button onClick={(e) => { AddOption(); }} type="button" className="btn add_option">+ {t('_managelc_postpoll_add_opt_')}</button>
                                    </div>
                                    <div className="form-group">
                                        <label>{t('_managelc_postpoll_poll_duration_')} </label>
                                        <select name="duration" {...register("duration", { required: "required" })} >
                                            <option value=''>{t('_managelc_postpoll_poll_select_day_')} </option>
                                            {
                                                duration.map((value, index) =>
                                                    <option key={index} value={index}>{value}</option>
                                                )
                                            }
                                        </select>
                                        <span className="text-danger">{errors.duration?.message}</span>
                                        <p>{t('_managelc_postpoll_poll_ins_')}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="submit" disabled={submitting} className="btn submit-pink-btn">
                                    {submitting ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : t('_managelc_postpoll_post_btn_')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PostPollPopup;
