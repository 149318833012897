import { Link } from "react-router-dom";
import VerifiedBadge from "../../../../components/VerifiedBadge";
import { useTranslation } from "react-i18next";

const EnrolledCourse = ({ ntfcn }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src={ntfcn?.course?.creator?.image} className="rounded-circle" alt={ntfcn?.course?.creator?.name} />
                    </div>
                    <div className="user-details-title">
                        <h6><Link to={`/profile-details/${ntfcn?.course?.creator?.slug}`}>{ntfcn?.course?.creator?.name}</Link><VerifiedBadge _is_verified={ntfcn?.course?.creator?.is_verified} refname="notification" /><span>{ntfcn.date_time}</span></h6>
                        {
                            ntfcn?.course?.creator?.is_corporate == '1'
                            &&
                            <p>{t('_nav_company_')} - {ntfcn?.course?.title}</p>
                        }
                        {
                            ntfcn?.course?.creator?.is_corporate != '1'
                            &&
                            <p>{t('_dh_tutor_')} - {ntfcn?.course?.title}</p>
                        }

                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>{t('_dh_studenttab_rgister_m3_') + " "} <Link to={`/learn-course/${ntfcn?.course?.slug}`}><strong>{ntfcn?.course?.title}</strong></Link> {t('_hr_report_tour_t1_')}</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link" to={'/learn-course/' + ntfcn?.course?.slug}>
                            <img src={ntfcn?.course?.image} alt={ntfcn?.course?.title} />
                            <span>{t('_dh_studenttab_rgister_m4_')} </span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EnrolledCourse;
