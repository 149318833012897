import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useState } from "react";
import { API_BASE } from "../../../../../constants/global";
import { SwalBox, Toaster } from "../../../../../entity/GlobalJS";
import Comments from "./Comments";
import { useTranslation } from "react-i18next";

const Comment = ({ index, course_id, comment, is_reply, new_added, total_count, setTotalCount }) => {
    const _token = localStorage.getItem('lmstoken');
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const profile = user.image;
    const [newAddX, setnewAddX] = useState(new_added);
    const [ths_comment, setThisComment] = useState(comment);
    const [comment_text, setCommentText] = useState('');
    const [new_comment, setNewComment] = useState('');
    const [edit_id, setEditId] = useState('');
    const [udpated, setUpdated] = useState(false);
    const [new_comments, setNewComments] = useState('');
    const [editing, setEditing] = useState(false);
    const { t } = useTranslation();

    const updateComment = async (parent_id = '') => {
        if (new_comment.trim() !== '' || comment_text.trim() !== '') {
            const formData = new FormData();
            if (parent_id !== '') {
                formData.append('parent_id', parent_id);
                formData.append('comment', comment_text);
            } else {
                formData.append('comment', new_comment);
            }
            formData.append('course_id', course_id);

            const response = await fetch(`${API_BASE}post-course-query${edit_id !== '' ? `/${edit_id}` : ''}`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData
            });

            const data = await response.json();
            if (data.status) {
                if (parent_id !== '') {
                    setNewComments([data.data.comment, ...new_comments]);
                    setUpdated(true);
                    setCommentText('');
                    setnewAddX(true);
                } else {
                    setThisComment(data.data.comment);
                    setEditId('');
                    setUpdated(true);
                }
            } else {
                Toaster.error(data.message);
            }
        }
    }

    const ConfirmDeleteSection = (id) => {
        SwalBox.fire({
            title: t('_v_delete_confirm_'),
            text: t('_managelc_comment_delete_verify_'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_lp_no_')
        }).then((result) => {
            if (result.isConfirmed) {
                deleteComment(id);
            }
        });
    }

    const deleteComment = async (id) => {
        const response = await fetch(`${API_BASE}delete-course-query/${id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setThisComment('');
            setTotalCount(total_count - 1);
        } else {
            Toaster.error(data.message);
        }
    }

    const addEmoji = (e) => {
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        setNewComment(new_comment + emoji);
    };

    const addEmoji2 = (e) => {
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        setCommentText(comment_text + emoji);
    };

    return (
        <>
            {
                ths_comment !== ''
                &&
                <div className={"public_coment" + (is_reply ? ' sub_coment pl-5' : '')}>
                    <div className="prof_img">
                        <img src={comment.user.image} alt={comment.user.name} />
                    </div>
                    <div className="coment_box">
                        {
                            edit_id === ''
                            &&
                            <>
                                <div className="bg_box">
                                    <h3>
                                        <a>{comment.user.name}</a>
                                        {
                                            (
                                                (!udpated && !newAddX && ths_comment.is_my_reply === 1)
                                                ||
                                                ((udpated || newAddX) && ths_comment.is_my_comment === 1)
                                            )
                                            &&
                                            <>
                                                <a className="ellipsbtn float-right ml-3" id="MaterialsMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MaterialsMenuButton">
                                                    <a onClick={(e) => { setNewComment(ths_comment.query); setEditId(comment.id); }} className="dropdown-item">{t('_edit_')} </a>
                                                    <a onClick={(e) => { ConfirmDeleteSection(comment.id); }} className="dropdown-item">{t('_managelc_delete_')} </a>
                                                </div>
                                            </>
                                        }
                                    </h3>
                                    <p>{ths_comment.query}</p>
                                </div>
                                {
                                    !is_reply
                                    &&
                                    <p><a onClick={(e) => { setEditing(!editing); }} className="link">{t('_managelc_reply_btn_')} </a></p>
                                }
                            </>
                        }

                        {
                            edit_id !== ''
                            &&
                            <>
                                <input type="text" onChange={(e) => { setNewComment(e.target.value); }} onKeyUp={(e) => { if (e.key === 'Enter') { updateComment(); } }} className="form-control" placeholder="Write a comment..." value={new_comment ? new_comment : ''} />
                                <i id={`${comment.id}_MenuButtonEmoji`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="far fa-smile"></i>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MenuButtonEmoji">
                                    <div className='emojibox'>
                                        <Picker
                                            data={data}
                                            onEmojiSelect={addEmoji}
                                            style={{
                                                position: "absolute",
                                                marginTop: "465px",
                                                marginLeft: -40,
                                                maxWidth: "320px",
                                                borderRadius: "20px",
                                            }}
                                            theme="dark"
                                        />
                                    </div>
                                </div>
                                <a className='edit-button cursor-pointer' onClick={(e) => { setNewComment(''); setEditId(''); }}>{t('_cancel_')} </a>
                            </>
                        }
                    </div>
                    {
                        !is_reply
                        &&
                        <>
                            {
                                editing
                                &&
                                <div className="own_coment sub_coment pl-5">
                                    <div className="prof_img">
                                        <img src={profile} alt={user.first_name} />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            onChange={(e) => { setCommentText(e.target.value); }}
                                            onKeyUp={(e) => { if (e.key === 'Enter') { updateComment(comment.id); } }}
                                            type="text"
                                            className="form-control"
                                            placeholder={t('_managelc_write_a_public_comment_')}
                                            value={comment_text}
                                        />
                                        <i id={`${comment.id}_MenuButtonEmoji`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="far fa-smile"></i>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MenuButtonEmoji">
                                            <div className='emojibox'>
                                                <Picker
                                                    data={data}
                                                    onEmojiSelect={addEmoji2}
                                                    style={{
                                                        position: "absolute",
                                                        marginTop: "465px",
                                                        marginLeft: -40,
                                                        maxWidth: "320px",
                                                        borderRadius: "20px",
                                                    }}
                                                    theme="dark"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                (udpated && new_comments !== '')
                                &&
                                new_comments.map((commentX, index) => {
                                    return <Comment key={index} index={index} course_id={course_id} comment={commentX} is_reply={true} new_added={true} />
                                }
                                )
                            }

                            {
                                ((!udpated && !newAddX) && comment.replies !== null && typeof comment.replies !== 'undefined')
                                &&
                                comment.replies.map((comment, index) =>
                                    <Comment key={index} index={index} course_id={course_id} comment={comment} is_reply={true} new_added={false} />
                                )
                            }
                            {
                                ((udpated || newAddX) && comment.comments !== null && typeof comment.comments !== 'undefined')
                                &&
                                comment.comments.map((comment, index) =>
                                    <Comment key={index} index={index} course_id={course_id} comment={comment} is_reply={true} new_added={false} />
                                )
                            }
                        </>
                    }
                </div>
            }
        </>
    );
}

export default Comment;
