import { useState } from "react";
import { useEffect } from "react";
import DetailListSkeleton from "../../../components/skeletons/DetailListSkeleton";
import { API_BASE } from "../../../constants/global";
import { useTranslation } from "react-i18next";

const WhatYouLearn = ({ course }) => {
    const _token = localStorage.getItem('lmstoken');
    const [outcomes, setOutcomes] = useState('');
    const [processing, setProcessing] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        fetchOutcomes();
        // eslint-disable-next-line
    }, []);

    const fetchOutcomes = async () => {
        setProcessing(true);
        const response = await fetch(`${API_BASE}get-course-outcomes/${course.id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setOutcomes(data.data.what_you_learn);
        }
        setProcessing(false);
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-learn" role="tabpanel" aria-labelledby="pills-learn-tab">
                <div className="course-tab-contents">
                    <div className="course-tabs-pane-block">
                        <h3>{t('_lcdetail_what_learn__')} </h3>
                        <div className="course-tabs-box">
                            <div className="course-tabs-body">
                                <div className="course-accordion-section">
                                    <ul className="about-course-list">

                                        {
                                            processing
                                                ?
                                                <DetailListSkeleton count={3} />
                                                :
                                                <>
                                                    {
                                                        outcomes !== ''
                                                        &&
                                                        outcomes.map((outcome, index) =>
                                                            <li key={index}><span>{outcome.description}</span></li>
                                                        )
                                                    }

                                                    {
                                                        (outcomes === '' || outcomes.length <= 0)
                                                        &&
                                                        <li><span>{t('_coursedetail_nooutcome_')}</span></li>
                                                    }
                                                </>
                                        }

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WhatYouLearn;
