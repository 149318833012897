import { useEffect, useLayoutEffect, useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import authContext from '../../context/auth/authContext';
import { Link, NavLink, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { API_BASE, FRONT_BASE } from "../../constants/global";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import RateStart from "../../components/RateStart";
import ReportCircle from "./ReportCircle";
import { Widget, addResponseMessage, deleteMessages, setBadgeCount, toggleMsgLoader } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import chatbotlogo from '../../assets/images/chatbotlogo.png';
import EduKulaAi from "../../entity/EdukulaAi";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

let _current_lmsuser_type = '';
try {
    const _current_lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
    _current_lmsuser_type = _current_lmsuser.is_corporate;
} catch (err) { }

const ManageCircle = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const is_meeting_create = window.location.href.indexOf("create-meeting") > -1 ? true : false;
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const cntxAuth = useContext(authContext);
    const _token = localStorage.getItem('lmstoken');
    const { unique_id } = useParams();
    localStorage.setItem('learningCircleUniqueId', unique_id);
    const { t } = useTranslation();

    const [group, setGroup] = useState('');

    const [requests_total, setRequestsTotal] = useState(0);
    const [members_total, setMembersTotal] = useState(0);
    const [page, setPage] = useState(1);

    const [active, setActive] = useState(true);
    const [isowner, setIsOwner] = useState(false);
    const [isadmin, setIsAdmin] = useState(false);
    const [ispublic, setIsPublic] = useState(false);
    const [owners, setOwners] = useState(false);

    const [corpempShow, setCorpempShow] = useState(false);
    const [existingShow, setExistingShow] = useState(false);
    const [viaEmailShow, setViaEmailShow] = useState(false);
    const [viaLinkShow, setViaLinkShow] = useState(false);

    const [questions, setQuestions] = useState('');
    const [feedback_id, setFeedbackId] = useState('');
    const [report_types, setReportTypes] = useState('');
    const [my_rating, setMyRating] = useState(0);

    const [rating, setRating] = useState('');
    const [feedbacks, setFeedbacks] = useState('');

    const rateArrays = [1, 2, 3, 4, 5];
    const [showVirtualTutor, setShowVirtualTutor] = useState(false);
    useLayoutEffect(() => {
        const onWidth1 = (window.innerWidth < 990) ? 580 : 500;
        const onWidth2 = (window.innerWidth < 990) ? 425 : 300;
        const scrollTo = (location.pathname.indexOf('feeds') === -1) ? onWidth1 : onWidth2; // for resposive 720
        window.scrollTo(0, scrollTo);
    }, [location.pathname]);

    useEffect(() => {
        setPage(1 + 1);
        fetchGroup();
        fetchQuestions();
        fetchReportTypes();
        fetchRating();
        fetchFeedbacks();

        deleteMessages();
        addResponseMessage('Hi, ' + cntxAuth.userInfo.first_name + '. How may I help you ?');
        setBadgeCount(0);
        isVirtualTutorEnabled();

        // eslint-disable-next-line
    }, []);

    const fetchGroup = async () => {
        const response = await fetch(`${API_BASE}get-group/${unique_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            if (data.message === "Server Error") {
                navigate('/explore-learning-circles');
            }

            setOwners(data.data.group?.owners !== null ? data.data.group?.owners[0] : '');
            setGroup(data.data.group);
            setMyRating(data.data.group?.rate);
            setActive(data.data.group?.active);
            setIsOwner(data.data.isowner);
            setIsAdmin(data.data.isadmin);
            setMembersTotal(data?.data?.group?.totalMembersCount);
            setIsPublic(data.data.group?.private === 'Public' ? true : false);

            if (data.data.isowner || data.data.isadmin) {
                fetchJoinRequest();
            }
        } else if (data.message === "Server Error") {
            SwalBox.fire({
                title: "Link is invalid",
                text: "It seems that the link is not valid. Please try again.",
                icon: "error",
            });
            setTimeout(() => {
                SwalBox.close();
            }, 3000);
            navigate('/explore-learning-circles');
        }
    }

    const fetchQuestions = async () => {
        const response = await fetch(API_BASE + 'get-feedback-questions', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setQuestions(data.data.questions);
        }
    }

    const fetchReportTypes = async () => {
        const response = await fetch(`${API_BASE}get-report-types`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setReportTypes(data.data.types);
        }
    }

    const fetchRating = async () => {
        const response = await fetch(`${API_BASE}get-average-ratings/${unique_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            if (data.data.average_ratings !== null) {
                setRating(data.data.average_ratings[0]);
            }
        }
    }

    const fetchFeedbacks = async () => {
        const response = await fetch(`${API_BASE}get-average-feedback/${unique_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setFeedbacks(data.data.average_feedback);
        }
    }

    const fetchJoinRequest = async () => {
        const response = await fetch(`${API_BASE}get-group-join-request/${unique_id}?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setRequestsTotal(data.data.count);
        } else {
            setRequestsTotal(0);
        }
    }

    const changeStaus = async (flag) => {
        const response = await fetch(`${API_BASE}change-active-status/${unique_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            setActive(flag);
        }
    }

    const toggleMenu = () => {
        document.getElementById('navbarDropdown11').nextSibling.classList.toggle("show");
    }

    const toggleMenu2 = () => {
        document.getElementById('navbarDropdown12').nextSibling.classList.toggle("dshow");
    }

    const leaveCircle = async () => {
        const response = await fetch(`${API_BASE}leave-learning-circle/${unique_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            navigate('/dashboard/my-circles');
        }
    }

    const closeCircle = async () => {
        const response = await fetch(`${API_BASE}close-learning-circle/${unique_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            navigate('/dashboard/my-circles');
        }
    }

    const postRate = async (rate) => {
        const formData = new FormData();
        formData.append('group_id', unique_id);
        formData.append('star_count', rate);

        const response = await fetch(`${API_BASE}post-circle-rating`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            setFeedbackId(rdata.data.rating.id);
            Toaster.success(rdata.message);
            document.getElementById('closeratingModalLongTitle').click();
            document.getElementById('nextModalCenterclick').click();
            setMyRating(rate);
        } else {
            Toaster.error(rdata.message);
        }
    }

    const ConfirmDelete = (leave = true) => {
        SwalBox.fire({
            title: t('_v_delete_confirm_'),
            text: `${t('_managelc_swalbox_m1_')} ${leave ? t('_managelc_swalbox_m2_') : t('_hr_close_')} ${t('_notilist_circle_')} ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_lp_no_')
        }).then((result) => {
            if (result.isConfirmed) {
                if (leave) {
                    leaveCircle();
                } else {
                    closeCircle();
                }
            }
        });
    }

    const onSaveFeedback = async (form) => {
        const response = await fetch(`${API_BASE}post-circle-feedback/${feedback_id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(form)
        });

        const rdata = await response.json();
        if (rdata.status) {
            document.getElementById('nextModalCenterClose').click();
            Toaster.success(rdata.message);
        } else {
            Toaster.error(rdata.message);
        }
    }
    const handleNewUserMessage = (newMessage) => {
        toggleMsgLoader();
        let _default_prompt = 'Learning Circle is collaborative learning environment. I want you to act as my virtual tutor. I will initiate chat with you. I may ask you queries related to different topics and you will explain or answer me based on my queries. You will need to analise my reply/statement first and answer me according to the type of my statement i.e whether it is a question or just a sentense. Please keep your reply as short as possible.';
        //let _default_course = '\n Here is my query :';
        let _default_course = 'Currently I am in '+group.title+ 'Learning Cirlce. \n Here is my query :';
        let _request_prompt = _default_prompt +'\n'+_default_course+'\n'+newMessage+'.';
        
        try {
            EduKulaAi.createCompletion({
                model: "text-davinci-003",
                prompt: _request_prompt,
                stop: "Stop",
                max_tokens: 4097-(_request_prompt.length),
                temperature: 0.1,
            }).then((completion) => {
                toggleMsgLoader();
                const responseText = completion.data.choices[0].text;
                addResponseMessage(responseText);
            }).catch((error) => {
                SwalBox.fire({
                    title: 'Something went wrong',
                    text: 'Please try again after some time.',
                    icon: 'error',
                });
            });
        }catch(err) { }

    };

    const isVirtualTutorEnabled = async () => {
        let formData = {};
        const _lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
        const _lms_learner_id = _lmsuser['id'];
        formData.user_id = _lms_learner_id;
        formData.action = "fetch";
        const response = await fetch(`${API_BASE}get-responsible-ai`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(formData)
        });
        const data = await response.json();
        if (data.status) {
            if (data.data.virtual_tutor == "TRUE") { setShowVirtualTutor(true); }
            //console.info(showVirtualTutor);
        } else {
        }
    }

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_dashboard_home_t4_')}`} </title>
            </Helmet>
            {
                (group !== '')
                &&
                <>
                    <section className="top_banner_section white_bg">
                        <div className="container">
                            <div className="circle-cover-img">
                                <img src={group.image} className="circle-cover w-100" alt="" />
                                <div className="created-by"><b>{t('_mylc_circlejoined_create_')}:</b> {group.owner}</div>
                            </div>
                            <div className="row circle-title-btn">
                                <div className="col-md-6 col-lg-6 circle-title">
                                    <h2>{group.title}</h2>
                                    <p>
                                        {
                                            rating !== ''
                                            &&
                                            <>
                                                <a className="ratingview cursor-pointer" data-toggle="modal" data-target="#ratingviewModalCenter"><i className="far fa-star"></i> {rating.average} {t('_explorelc_rating_')}</a>
                                                &nbsp;
                                            </>
                                        }
                                        {group.private} {t('_dh_lctab_m9_')} - {members_total} {t('_mylc_lccard_m2_')}
                                    </p>
                                    <div className="modal fade ratingviewpop" id="ratingviewModalCenter" tabIndex="-1" role="dialog" aria-labelledby="ratingviewModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h4 className="modal-title" id="ratingviewModalLongTitle">{t('_managelc_currernt_rating_')}</h4>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="rating_pop_mai">
                                                        <h4>{rating.average} {t('_explorelc_rating_')}</h4>
                                                        {
                                                            feedbacks !== '' && feedbacks.feedback !== null
                                                            &&
                                                            <div className="feedback_detailed-view">
                                                                <ul className="list-feedbacks">
                                                                    {
                                                                        feedbacks.feedback.map((feedback, indexx) => {
                                                                            return (
                                                                                <li key={indexx}>
                                                                                    <span>{feedback.question}</span>
                                                                                    <div className="feedback-percent">
                                                                                        <span style={{ width: feedback.feedback.Yes + '%' }} className="yes">{feedback.feedback.Yes}%</span>
                                                                                        <span style={{ width: feedback.feedback.No + '%' }} className="no">{feedback.feedback.No}%</span>
                                                                                        <span style={{ width: feedback.feedback.Not_Sure + '%' }} className="not_sure">{feedback.feedback.Not_Sure}%</span>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }
                                                                        )
                                                                    }
                                                                </ul>
                                                                <ul className="percent-courns">
                                                                    <li className="one">0</li>
                                                                    <li className="two">20</li>
                                                                    <li className="three">40</li>
                                                                    <li className="four">60</li>
                                                                    <li className="five">80</li>
                                                                    <li className="six">100</li>
                                                                </ul>
                                                                <div className="template_names">
                                                                    <span className="yes">{t('_lp_yes_')}</span>
                                                                    <span className="no">{t('_lp_no_')}</span>
                                                                    <span className="not_sure">{t('_managelc_not_sure_')}</span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6 circle-dropdown text-right">
                                    <a onClick={() => { navigate(`/dashboard/my-circles/i-have-created`); }} className="btn btn-circle btn-info mr-3">{t('_managelc_back_to_dashboard_')}</a>

                                    {
                                        (isowner && active === true)
                                        &&
                                        <span onClick={(e) => { changeStaus(false); }} className="btn btn-circle btn-success mr-3">{t('_managelc_deactivate_')} </span>
                                    }

                                    {
                                        (isowner && active !== true)
                                        &&
                                        <span onClick={(e) => { changeStaus(true); }} className="btn btn-circle btn-info mr-3">{t('_managelc_activate_')} </span>
                                    }

                                    {
                                        ((isowner || isadmin || ispublic) && active === true)
                                        &&
                                        <>
                                            <div className="dropdown inline-block">
                                                <span onClick={(e) => { toggleMenu2(); }} className="btn btn-circle btn-info mr-3" id="navbarDropdown12"><i className="fa fa-plus" aria-hidden="true"></i> {t('_managelc_invite_')}</span>
                                                <div className="dropdown-menu sm-menu border-top invitedrop">
                                                    <ul>
                                                        {
                                                            _current_lmsuser_type == '1'
                                                            &&
                                                            <li><span onClick={() => { setCorpempShow(true); toggleMenu2(); }} className="dropdown-item"><strong><i className="fas fa-user"></i> {t('_managelc_invite_emp_')}</strong></span></li>
                                                        }
                                                        <li><span onClick={() => { setExistingShow(true); toggleMenu2(); }} className="dropdown-item"><strong><i className="fas fa-user"></i> {t('_managelc_invite_user_')}</strong></span></li>
                                                        <li><span onClick={() => { setViaEmailShow(true); toggleMenu2(); }} className="dropdown-item"><strong><i className="fas fa-envelope"></i> {t('_managelc_invite_via_email_')}</strong></span></li>
                                                        <li><span onClick={() => { setViaLinkShow(true); toggleMenu2(); }} className="dropdown-item"><strong><i className="fas fa-link"></i> {t('_managelc_invite_via_link_')}</strong></span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <div className="profile-detail">
                                        <ul>
                                            <li className="nav-item dmenu dropdown">
                                                <span onClick={(e) => { toggleMenu(); }} className="nav-link dropdown-toggle" id="navbarDropdown11"><img src={owners.profile} className="rounded-circle" alt="Profile" /></span>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                                    <Link to={`/profile-details/${owners.user_slug}`} className="dropdown-item">{t('_managelc_view_profile_')}</Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {
                                    active
                                        ?
                                        <div className="col-md-12">
                                            <div className="invite_nav_bnr">
                                                <div className="circle-navlinks">
                                                    <NavLink className={`nav-link`} to={`/view-learning-circle/${unique_id}/feeds`}><span>{t('_managelc_group_feed_')}</span></NavLink>
                                                    <NavLink className={`nav-link ${is_meeting_create ? 'active' : ''}`} to={`/view-learning-circle/${unique_id}/meetings`}>{t('_managelc_meetings_')} </NavLink>
                                                    <NavLink className={`nav-link`} to={`/view-learning-circle/${unique_id}/materials`}>{t('_managelc_materials_')} </NavLink>
                                                    <NavLink className={`nav-link`} to={`/view-learning-circle/${unique_id}/members`}>{t('_managelc_members_')} </NavLink>
                                                    <div className="more-options-link">
                                                        <span id="MoreMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('_managelc_more_')} <i className="fas fa-angle-down"></i></span>
                                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MoreMenuButton">
                                                            {
                                                                group.course !== ''
                                                                &&
                                                                <Link to={`/course-details/${group.course_slug}`} className="dropdown-item ek-lrs-course-initialized" data-ek-lrs-url={`/course-details/${group.course.slug}`} data-ek-lrs-title={group.course.title}>{t('_managelc_view_parent_cou_')} </Link>
                                                            }

                                                            {
                                                                (group.private !== 'Public' && (isowner || isadmin))
                                                                &&
                                                                <NavLink className={'dropdown-item'} to={`/view-learning-circle/${unique_id}/join-requests`}>{t('_managelc_join-request_')} <span className="request-count">{requests_total}</span></NavLink>
                                                            }

                                                            {
                                                                (isowner || isadmin)
                                                                &&
                                                                <NavLink className={'dropdown-item'} to={`/view-learning-circle/${unique_id}/sent-invitations`}>{t('_managelc_sent_invite_')}</NavLink>
                                                            }

                                                            {
                                                                isowner
                                                                &&
                                                                <a onClick={(e) => { ConfirmDelete(false); }} className="dropdown-item">{t('_managelc_close_circle_')}</a>
                                                            }
                                                            <a onClick={(e) => { ConfirmDelete(); }} className="dropdown-item">{t('_managelc_leave_circle_')} </a>
                                                            <a className="dropdown-item" data-toggle="modal" data-target="#reportModalCenter">{t('_managelc_report_circle_')}</a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <ReportCircle report_types={report_types} unique_id={unique_id} for_course={false} course_id={''} />

                                                <a className="rating_text" data-toggle="modal" data-target="#ratingModalCenter"><i className="fas fa-star"></i> {t('_managelc_rate_circle_')}</a>
                                                <div className="modal fade ratingpopup" id="ratingModalCenter" tabIndex="-1" role="dialog" aria-labelledby="ratingModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h4 className="modal-title" id="ratingModalLongTitle">{t('_managelc_rate_msg_')}</h4>
                                                                <button type="button" id="closeratingModalLongTitle" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <h5>{t('_managelc_select_rating_')}</h5>
                                                                {
                                                                    rateArrays.map((rate_val, indexv) =>
                                                                        <RateStart key={indexv} postRate={postRate} value={rate_val} my_rating={my_rating} />
                                                                    )
                                                                }
                                                                <div className="clear"></div>
                                                                <div className="btn_right">
                                                                    <button type="button" data-dismiss="modal">{t('_cancel_')} </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <a id="nextModalCenterclick" className="d-none" data-toggle="modal" data-target="#nextModalCenter"><i className="fas fa-star"></i></a>
                                                <div className="modal fade ratingpopup2" id="nextModalCenter" tabIndex="-1" role="dialog" aria-labelledby="nextModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h4 className="modal-title" id="nextModalLongTitle">{t('_managelc_rate_tellus_')}.</h4>
                                                                <button type="button" id="nextModalCenterClose" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <form onSubmit={handleSubmit(onSaveFeedback)}>
                                                                    {
                                                                        (questions !== '' && questions !== null)
                                                                        &&
                                                                        questions.map((question, index) =>
                                                                            <div key={index} className="form-group">
                                                                                <label>{question.question}</label>
                                                                                <div className="form-check">
                                                                                    <input {...register(`feedback.${question.id}`)} id={`exampleRadios1${index}1`} className="form-check-input" type="radio" value="Yes" />
                                                                                    <label className="form-check-label" htmlFor={`exampleRadios1${index}1`}>{t('_lp_yes_')}</label>
                                                                                </div>
                                                                                <div className="form-check">
                                                                                    <input {...register(`feedback.${question.id}`)} className="form-check-input" id={`exampleRadios1${index}2`} type="radio" value="No" />
                                                                                    <label className="form-check-label" htmlFor={`exampleRadios1${index}2`}>{t('_lp_no_')}</label>
                                                                                </div>
                                                                                <div className="form-check disabled">
                                                                                    <input {...register(`feedback.${question.id}`)} className="form-check-input" id={`exampleRadios1${index}3`} type="radio" value="Not Sure" />
                                                                                    <label className="form-check-label" htmlFor={`exampleRadios1${index}3`}>{t('_managelc_not_sure_')}</label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    <div className="clearfix"></div>
                                                                    <div className="btn_right">
                                                                        <input type="submit" value="Save" />
                                                                        <button type="button" data-dismiss="modal">{t('_cancel_')}</button>
                                                                    </div>
                                                                    <div className="clearfix"></div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-12">
                                            <div className="empty_state">
                                                <h3>{t('_managelc_circle_inactive_')} </h3>
                                                <img alt="" src="images/no_courses.png" />
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </section>
                    {
                        showVirtualTutor == true
                        &&
                        <Widget
                            handleNewUserMessage={handleNewUserMessage}
                            profileAvatar={chatbotlogo}
                            title="I am your Virtual Tutor"
                            subtitle="Your can ask your queries..."
                            senderPlaceHolder="Ask me anything!"
                            profileClientAvatar={cntxAuth.userInfo.image}
                            autofocus={true}
                            showTimeStamp={true}
                            resizable={false}
                            emojis={true}
                            showBadge={true}
                        />
                    }
                    {
                        active
                        &&
                        <>
                            <Outlet context={[group, setRequestsTotal, isowner, isadmin, members_total, setMembersTotal]} />
                            <InviteCorpemp show={corpempShow} onHide={() => setCorpempShow(false)} unique_id={unique_id} />
                            <InviteExistingUser show={existingShow} onHide={() => setExistingShow(false)} unique_id={unique_id} />
                            <InviteViaEmail show={viaEmailShow} onHide={() => setViaEmailShow(false)} unique_id={unique_id} />
                            <InviteViaLink show={viaLinkShow} onHide={() => setViaLinkShow(false)} unique_id={unique_id} />
                        </>
                    }
                </>
            }
        </>
    );
}

const InviteCorpemp = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const [emps, setEmps] = useState([]);
    const { register, handleSubmit, setValue } = useForm();
    const [processing, setProcessing] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        fetchEmployees('');
    }, [])
    const fetchEmployees = async (search) => {
        setProcessing(true);

        const response = await fetch(`${API_BASE}employee/get?page=0&limit=4000&search=` + search, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setEmps(data.data.employees);
        } else {
            setEmps([]);
        }

        setProcessing(false);
    }
    const SearchEmp = (e) => {
        const search = e.target.value;
        //if (search.length > 0 && search !== '') {
        fetchEmployees(search);
        //}
    }
    const onCorpempSubmit = async (form) => {
        setProcessing(true);
        if (form.existing_emps === false || form.existing_emps.length <= 0) {
            Toaster.error("Please select any user to invite.");
            setProcessing(false);
            return false;
        }
        const formData = new FormData();
        formData.append('existing_users', form.existing_emps);
        var sURL = `${API_BASE}invite-existing-user-to-course/${props.course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const rdata = await response.json();
        if (rdata.status) {
            setValue('existing_emps', '');
            document.getElementById('search_user_control').value = '';
            //setEmps([]);
            Toaster.success(rdata.message);
            props.onHide();
        } else {
            Toaster.error(rdata.message);
        }
        setProcessing(false);

    }
    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <h4>{t('_emplist_inviteemps_')}</h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body className="scroll_add">
                <div id="invite_user" className="invitepop">
                    <div className="form-group">
                        <i className="fas fa-search"></i><input onChange={(e) => { SearchEmp(e); }} type="text" className="form-control" id="search_user_control" placeholder={t('_managelc_invite_model_search_')} />
                    </div>
                    <div className="form-group">


                    </div>

                    <form onSubmit={handleSubmit(onCorpempSubmit)}>
                        <div className="member_list invitemax_scroll">
                            <ul>
                                <li><a className="btn ml-2 mb-2 btn_right p-20 invt_corp_emp"><strong>{t('_managelc_select_all_')} </strong>&nbsp;&nbsp;&nbsp;</a> </li>
                                {
                                    (typeof emps !== 'undefined' && emps.length > 0)
                                    &&
                                    <>
                                        {
                                            emps.map((user, index) =>
                                                <li key={index}>
                                                    <div className="list_img">
                                                        <div className="img_box">
                                                            <img src={user.image} alt="" />
                                                        </div>
                                                        <h5>{user.full_name}
                                                            {
                                                                user?.headline
                                                                &&
                                                                <span>{user?.headline}</span>
                                                            }
                                                        </h5>
                                                        <input {...register("existing_emps[]")} className="invt_corp_emp_cbx" type="checkbox" value={user.ref} />
                                                    </div>
                                                </li>
                                            )
                                        }
                                    </>
                                }
                                {
                                    (typeof emps === 'undefined' || emps.length <= 0)
                                    &&
                                    <>
                                        <li>
                                            <div className="list_img text-center">
                                                <h5>{t('_managelc_nouser_found_')}.</h5>
                                            </div>
                                        </li>
                                    </>
                                }
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                        <div className="btn_right pad0">
                            <button onClick={props.onHide} type="button" className="btn btn-circle btn-md cancel-btn">{t('_cancel_')} </button>
                            {
                                typeof emps !== 'undefined' && emps.length > 0
                                &&
                                <button className="btn btn-circle btn-md submit-pink-btn">
                                    {
                                        processing
                                        &&
                                        <i className="fa fa-refresh fa-spin fa-fw"></i>
                                    }
                                    {
                                        !processing
                                        &&
                                        t('_managelc_send_invitations_')
                                    }
                                </button>
                            }
                        </div>
                    </form>
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
        </Modal>
    );

}
const InviteExistingUser = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const [users, setUsers] = useState([]);
    const { register, handleSubmit } = useForm();
    const [processing, setProcessing] = useState(false);
    const { t } = useTranslation();

    const fetchUsers = async (search) => {
        setProcessing(true);
        const response = await fetch(`${API_BASE}search-user/${search}/${props.unique_id}/false`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setUsers(data.data.users);
        } else {
            setUsers([]);
        }

        setProcessing(false);
    }

    const SearchUser = (e) => {
        const search = e.target.value;

        if (search.length > 0 && search !== '') {
            fetchUsers(search);
        }
    }

    const onSubmit = async (form) => {
        setProcessing(true);

        if (form.existing_users === false || form.existing_users.length <= 0) {
            Toaster.error("Please select any user to invite.");
            setProcessing(false);
            return false;
        }

        const formData = new FormData();
        formData.append('existing_users', form.existing_users);
        var sURL = `${API_BASE}invite-existing-member-to-learningcircle/${props.unique_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            document.getElementById('search_user_control').value = '';
            setUsers([]);
            Toaster.success(rdata.message);
            props.onHide();
        } else {
            Toaster.error(rdata.message);
        }

        setProcessing(false);
    }

    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <h4>{t('_managelc_invite_user_')}</h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body className="scroll_add">
                <div id="invite_user" className="invitepop">
                    <div className="form-group">
                        <i className="fas fa-search"></i><input onChange={(e) => { SearchUser(e); }} type="text" className="form-control" id="search_user_control" placeholder={t('_managelc_invite_model_search_')} />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="member_list invitemax_scroll">
                            <ul>
                                {
                                    (typeof users !== 'undefined' && users.length > 0)
                                    &&
                                    <>
                                        {
                                            users.map((user, index) =>
                                                <li key={index}>
                                                    <div className="list_img">
                                                        <div className="img_box">
                                                            <img src={user.image} alt="" />
                                                        </div>
                                                        <h5>{user.name}
                                                            {
                                                                user?.headline
                                                                &&
                                                                <span>{user?.headline}</span>
                                                            }
                                                        </h5>
                                                        <input {...register("existing_users[]")} type="checkbox" value={user.id} />
                                                    </div>
                                                </li>
                                            )
                                        }
                                    </>
                                }
                                {
                                    (typeof users === 'undefined' || users.length <= 0)
                                    &&
                                    <>
                                        <li>
                                            <div className="list_img text-center">
                                                <h5>{t('_managelc_nouser_found_')}.</h5>
                                            </div>
                                        </li>
                                    </>
                                }
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                        <div className="btn_right pad0">
                            <button onClick={props.onHide} type="button" className="btn btn-circle btn-md cancel-btn">{t('_cancel_')} </button>
                            {
                                typeof users !== 'undefined' && users.length > 0
                                &&
                                <button className="btn btn-circle btn-md submit-pink-btn">
                                    {
                                        processing
                                        &&
                                        <i className="fa fa-refresh fa-spin fa-fw"></i>
                                    }
                                    {
                                        !processing
                                        &&
                                        "Send Invitations"
                                    }
                                </button>
                            }
                        </div>
                    </form>
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const InviteViaEmail = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const { register, handleSubmit, formState: { errors }, setValue, setError, setFocus } = useForm();
    const [emails, setEmails] = useState([]);
    const [processing, setProcessing] = useState(false);
    const { t } = useTranslation();

    const onSubmit = async (data) => {
        if (emails.length >= 30) {
            setError("email", { type: "focus", message: t('_managelc_invite_email_vali_m31_') }, { shouldFocus: false });
            return false;
        }

        setProcessing(true);
        //var iEmail = data.email;
        var _email_string = data.email;
        var _remove_all_spaces = _email_string.replace(/\s\s+/g, ' ');
        var _convert_space_in_coma = _remove_all_spaces.split(/[ ,]+/).join(',');
        var iEmail = _convert_space_in_coma.trim();
        var comEmails = iEmail.split(",");

        if (typeof comEmails !== 'undefined' && comEmails.length > 1) {
            var duplicate = false;
            var checkEmails = [];
            for (let index = 0; index < comEmails.length; index++) {
                const sepEmail = comEmails[index].trim();

                if (emails.indexOf(sepEmail) === -1) {
                    checkEmails.push(sepEmail);
                } else {
                    var duplicate = true;
                }
            }
            const callAsync = async () => {
                // const newEmails = await checkAPI();
                const newList = [...emails, ...checkEmails];
                setEmails(newList.splice(30));
                setEmails(newList);
            }

            callAsync();

            if (duplicate) {
                Toaster.info(t('_managelc_invite_email_vali_m32_'));
            }

            setValue('email', '');
            setFocus('email');
            setProcessing(false);
        } else {
            if (emails.indexOf(iEmail) !== -1) {
                setError("email", { type: "focus", message: t('_managelc_invite_email_vali_m33_') }, { shouldFocus: true });
                setProcessing(false);
                return false;
            }

            const formData = new FormData();
            formData.append('email', iEmail);
            var sURL = `${API_BASE}check-email-exists/${props.unique_id}`;
            const response = await fetch(sURL, {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + _token
                },
                body: formData
            });

            const rdata = await response.json();
            if (rdata.status && rdata.data.exists === true) {
                setError("email", { type: "focus", message: 'The email is already exists in the circle.' }, { shouldFocus: true });
                setProcessing(false);
                return false;
            }

            setEmails([...emails, data.email]);
            setValue('email', '');
            setFocus('email');
            setProcessing(false);
        }
    }

    const sendInvitation = async () => {
        const message = document.getElementById('invite_message_box').value;
        var formData = new FormData();
        for (var i = 0; i < emails.length; i++) {
            formData.append('members[]', emails[i]);
        }
        formData.append('message', message);

        var sURL = `${API_BASE}invite-to-group/${props.unique_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            Toaster.success(rdata.message);
            setEmails([]);
            setValue('email', '');
        } else {
            Toaster.error(rdata.message);
        }

        props.onHide();
    }

    const removeItem = async (e, item) => {
        const newEmails = [];

        emails.forEach((email, index) => {
            if (item !== email) {
                newEmails.push(email);
            }
        });

        setEmails(newEmails);
    }

    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <h4>{t('_managelc_invite_via_email_')}</h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body>
                <div id="invite_email" className="invitepop">
                    <p className="text-label-invite"><strong>{t('_managelc_email_invitation_msg_')}</strong></p>
                    <textarea rows="3" className="form-control" id="invite_message_box" placeholder={t('_managelc_email_invitation_msg_place_')} defaultValue={t('_managelc_email_invitation_msg_defaultvalue_')}></textarea>
                    <br />

                    <>
                        <strong>{t('_v_invite_add_emails_')}</strong>
                        <p>{t('_v_invite_limit_')}.</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="email_box">
                                <input {...register("email", {
                                    required: "required",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: t('_hr_email_error_msg_')
                                    }
                                })} type="text" placeholder={t('_hr_email_placeholder_')} />
                                <button className="btn btn-circle btn-info ml-2">
                                    {
                                        processing
                                        &&
                                        <i className="fa fa-refresh fa-spin fa-fw"></i>
                                    }
                                    {
                                        !processing
                                        &&
                                        t('_emplist_tour_t1_')
                                    }
                                </button>
                            </div>
                            <span className="text-danger">
                                {errors.email && errors.email.type === "required" && t('_managelc_invite_email_vali_m1_')}
                                {errors.email && errors.email.type !== "required" && errors.email.message}
                            </span>
                        </form>
                    </>

                    {
                        emails.length > 0
                        &&
                        <>
                            <hr />
                            <ul className="list-add-email-invite">
                                {
                                    emails.map((email, index) =>
                                        <li key={index}>
                                            <span>{email}</span>
                                            <i onClick={(e) => { removeItem(e, email) }} className="fa fa-times text-danger float-right mt-1" title="Close" aria-hidden="true"></i>
                                        </li>
                                    )
                                }
                            </ul>
                        </>
                    }


                    <div className="btn_right">
                        {
                            emails.length > 0
                            &&
                            <input className="btn btn-circle btn-md submit-pink-btn" onClick={(e) => { sendInvitation() }} disabled={processing} type="submit" value="Send" />
                        }
                        <button onClick={props.onHide} type="button" className="btn btn-circle btn-md cancel-btn">{t('_cancel_')} </button>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const InviteViaLink = (props) => {
    const { t } = useTranslation();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(FRONT_BASE + 'learning-circle-details/' + props.unique_id);
        Toaster.success('Link Copied!');
    }

    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <h4>{t('_managelc_invite_via_link_')}</h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
                {/* <i onClick={props.onHide} className="fa fa-times text-danger" title="Close" aria-hidden="true"></i> */}
            </Modal.Header>
            <Modal.Body>
                <div id="invite_link" className="invitepop">
                    <strong>{FRONT_BASE + 'learning-circle-details/' + props.unique_id}</strong>
                    <br />
                    <div className="btn_left float-right">
                        <button className="btn btn-circle btn-md submit-pink-btn" onClick={() => { copyToClipboard(); props.onHide(); }} type="button">{t('_managelc_copy_link_')}</button>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ManageCircle;
