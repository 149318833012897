import {
  React,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { API_BASE, LRS_ENDPOINT } from "../../constants/global";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import Select from "react-select";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";

function TrainingReport() {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    setFocus,
    clearErrors,
  } = useForm();
  const [course_options, setCourseOptions] = useState([]);
  const [report_options, setReportOptions] = useState([
    { value: "by_course", label: "Report by Course" },
    { value: "by_employee", label: "Report by Employee" },
  ]);
  const _token = localStorage.getItem("lmstoken");
  const [selected_course, setSelectedCourse] = useState("");
  const [selected_report, setSelectedReport] = useState("");
  const [loading, setLoading] = useState(false);
  const [employee_options, setEmployeeOption] = useState("");
  const [selected_employee, setSelectedEmployee] = useState("");
  const [sharingReport, setSharingReport] = useState(false);
  const [report_file_url, setReportFileUrl] = useState("");
  const [emails, setEmails] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [rowDataHiring, setRowDataHiring] = useState([]);
  const [rowDataTraining, setRowDataTraining] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [isBlank, setIsBlank] = useState(false);
  const [selectedCourseName, setSelectedCourseName] = useState("");
  const [selectedEmployeeName, setSelectedEmployeeName] = useState("");
  const [selectedEmployeeAgent, setSelectedEmployeeAgent] = useState("");
  const defaultColDef = {
    resizable: true,
    editable: false,
    filter: true,
    sortable: true,
    floatingFilter: true,
    suppressNavigable: true,
  };
  const { t } = useTranslation();

  const columnDefsCourse = [
    {
      headerName: t('_tr_emp_col_sr_'),
      valueGetter: "node.rowIndex + 1",
      filter: false,
      sortable: false,
      floatingFilter: false,
      width: 70,
      minWidth: 70,
      colId: "No",
    },
    {
      headerName: t('_tr_learner_name_'),
      field: "name",
      cellRenderer: (params) => {
        return (
          <>
            <img
              src={params.data.image}
              alt="Profile Photo"
              width={45}
              height={45}
            ></img>
            <span>&nbsp;&nbsp;{params.data.name}</span>
          </>
        );
      },
      width: 250,
      minWidth: 250,
    },
    {
      headerName: t('_tr_learner_name_'),
      field: "name",
      hide: true,
      colId: "EName",
    },
    {
      headerName: t('_tr_emp_col_email_'),
      field: "email",
      width: 250,
      minWidth: 250,
      colId: "Email",
    },
    {
      headerName: t('_tr_emp_enroll_'),
      field: "course_enroll",
      cellRenderer: (params) => {
        let _hstatus = "N/A";
        if (params.data.lrs.course_enroll != null) {
          _hstatus = formatDate(params.data.lrs.course_enroll);
        }
        return _hstatus;
      },
      filterValueGetter: (params) => {
        let _hstatus = "N/A";
        if (params.data.lrs.course_enroll != null) {
          _hstatus = formatDate(params.data.lrs.course_enroll);
        }
        return _hstatus;
      },
      colId: "DOE",
    },
    {
      headerName: t('_tr_emp_com_'),
      field: "course_complete",
      cellRenderer: (params) => {
        let _hstatus = "N/A";
        if (
          params.data.lrs.course_completed != null &&
          params.data.lrs.course_progress == "Completed"
        ) {
          _hstatus = formatDate(params.data.lrs.course_completed);
        }
        return _hstatus;
      },
      filterValueGetter: (params) => {
        let _hstatus = "N/A";
        if (
          params.data.lrs.course_completed != null &&
          params.data.lrs.course_progress == "Completed"
        ) {
          _hstatus = formatDate(params.data.lrs.course_completed);
        }
        return _hstatus;
      },
      colId: "DOC",
    },
    {
      headerName: t('_tr_emp_course_status_'),
      field: "course_progress",
      cellRenderer: (params) => {
        let _hstatus = "N/A";
        if (params.data.lrs.course_progress != null) {
          _hstatus = params.data.lrs.course_progress;
        }
        return _hstatus;
      },
      filterValueGetter: (params) => {
        let _hstatus = "N/A";
        if (params.data.lrs.course_progress != null) {
          _hstatus = params.data.lrs.course_progress;
        }
        return _hstatus;
      },
      colId: "Status",
    },
    {
      headerName: t('_tr_cou_time_'),
      field: "duration",
      cellRenderer: (params) => {
        return params.data.duration.total_hours;
      },
      filterValueGetter: (params) => {
        return params.data.duration.total_hours;
      },
      colId: "CourseComplete",
    },
    {
      headerName: t('_tr_cou_score_'),
      field: "score",
      cellRenderer: (params) => {
        let _hstatus = "N/A";
        if (params.data.lrs.quiz != null) {
          if (params.data.lrs.quiz.total_count != "0") {
            _hstatus =
              params.data.lrs.quiz.right_count +
              " / " +
              params.data.lrs.quiz.total_count;
          }
        }
        return _hstatus;
      },
      filterValueGetter: (params) => {
        let _hstatus = "N/A";
        if (params.data.lrs.quiz != null) {
          if (params.data.lrs.quiz.total_count != "0") {
            _hstatus =
              params.data.lrs.quiz.right_count +
              " / " +
              params.data.lrs.quiz.total_count;
          }
        }
        return _hstatus;
      },
      colId: "Score",
      filter: "agTextColumnFilter",
    },
  ];

  const columnDefsEmployee = [
    {
      headerName: t('_tr_emp_col_sr_'),
      valueGetter: "node.rowIndex + 1",
      filter: false,
      sortable: false,
      floatingFilter: false,
      width: 70,
      minWidth: 70,
      colId: "No",
    },
    {
      headerName: t('_tr_emp_course_name_'),
      field: "name",
      cellRenderer: (params) => {
        return params.data.title;
      },
      filterValueGetter: (params) => {
        return params.data.title;
      },
      width: 450,
      minWidth: 250,
    },
    {
      headerName: t('_tr_emp_course_name_'),
      field: "name",
      hide: true,
      colId: "EName",
    },
    {
      headerName: t('_tr_emp_doe_'),
      field: "enrolled_date",
      cellRenderer: (params) => {
        let _hstatus = "N/A";
        if (params.data.enrolled_date != null) {
          _hstatus = formatDate(params.data.enrolled_date);
        }
        return _hstatus;
      },
      colId: "DOE",
      filterValueGetter: (params) => {
        let _hstatus = "N/A";
        if (params.data.enrolled_date != null) {
          _hstatus = formatDate(params.data.enrolled_date);
        }
        return _hstatus;
      }
    },
    {
      headerName: t('_tr_emp_doc_'),
      field: "-",
      cellRenderer: (params) => {
        let _hstatus = "N/A";
        if (
          params.data.progress != null &&
          params.data.progress == "Completed"
        ) {
          _hstatus = formatDate(params.data.updated_date);
        }
        return _hstatus;
      },
      filterValueGetter: (params) => {
        let _hstatus = "N/A";
        if (
          params.data.progress != null &&
          params.data.progress == "Completed"
        ) {
          _hstatus = formatDate(params.data.updated_date);
        }
        return _hstatus;
      },
      colId: "DOC",
    },
    {
      headerName: t('_tr_emp_course_status_'),
      field: "course_progress",
      cellRenderer: (params) => {
        let _hstatus = "N/A";
        if (params.data.progress != null) {
          _hstatus = params.data.progress;
        }
        return _hstatus;
      },
      colId: "Status",
      filterValueGetter: (params) => {
        let _hstatus = "N/A";
        if (params.data.progress != null) {
          _hstatus = params.data.progress;
        }
        return _hstatus;
      }
    },
    {
      headerName: t('_tr_emp_spent_'),
      field: "-",
      cellRenderer: (params) => {
        return params.data.duration.total_hours;
      },
      filterValueGetter: (params) => {
        return params.data.duration.total_hours;
      },
      colId: "CourseComplete",
    },
    {
      headerName: t('_tr_emp_score_obt'),
      field: "score",
      cellRenderer: (params) => {
        let _hstatus = "N/A";
        if (params.data.quiz != null) {
          if (params.data.quiz.total_count != "0") {
            _hstatus =
              params.data.quiz.right_count +
              " / " +
              params.data.quiz.total_count;
          }
        }
        return _hstatus;
      },
      filterValueGetter: (params) => {
        let _hstatus = "N/A";
        if (params.data.quiz != null) {
          if (params.data.quiz.total_count != "0") {
            _hstatus =
              params.data.quiz.right_count +
              " / " +
              params.data.quiz.total_count;
          }
        }
        return _hstatus;
      },
      colId: "Score",
      filter: "agTextColumnFilter",
    },
  ];
  const gridRef = useRef();

  const toggleLeftMenu = async () => {
    try {
      let sidebar = document.querySelector(".sidebar");
      sidebar.classList.add("close");
    } catch (e) { }
  };

  useEffect(() => {
    fetchCourses();
    fetchEmployees();
    toggleLeftMenu();
  }, []);

  function formatDate(inputDateStr) {
    const date = new Date(inputDateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  function s2t(t) {
    return (
      parseInt(t / 86400) +
      "d " +
      new Date((t % 86400) * 1000)
        .toUTCString()
        .replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, "$1h $2m $3s")
    );
  }

  function sortRecordsByKey(array, sortKey, sortOrder = "asc") {
    if (sortOrder !== "asc" && sortOrder !== "desc") {
      throw new Error("Invalid sortOrder. Use 'asc' or 'desc'.");
    }
    return array.sort((a, b) => {
      const valueA = a[sortKey];
      const valueB = b[sortKey];

      if (sortOrder === "asc") {
        if (valueA < valueB) {
          return -1;
        } else if (valueA > valueB) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (valueA < valueB) {
          return 1;
        } else if (valueA > valueB) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  }
  const fetchCourses = async () => {
    const response = await fetch(
      `${API_BASE}get-training-report-course-list?page=0`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${_token}`,
        },
      }
    );
    const data = await response.json();
    if (data.status) {
      let defaultValue = "";
      var stringCourse = [];
      data.data.courses.forEach(function (value, i) {
        stringCourse.push({ value: value.id, label: value.title });
      });
      setCourseOptions(stringCourse);
      if (defaultValue !== "") {
        setSelectedCourse(stringCourse.find((c) => c.value === defaultValue));
      }
    }
  };

  const fetchEmployees = async (page = 0, search = "") => {
    setLoading(true);
    const response = await fetch(
      `${API_BASE}employee/get?page=${page}&search=${search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${_token}`,
        },
      }
    );

    const data = await response.json();

    if (data.status) {
      var stringCourse = [];
      data.data.employees.forEach(function (value, i) {
        stringCourse.push({ value: value.uid, label: value.full_name });
      });
      setEmployeeOption(stringCourse);
    } else {
    }
    setLoading(false);
  };

  const showSelectedReport = async (reporttype) => { };
  const fetchStudentEmployees = async (cid) => {
    SwalBox.fire({
      title: t('_sweetalert_process_'),
      html: t('_sweetalert_wait_'),
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        SwalBox.showLoading();
      },
    });
    let page = 1;
    let search = "";
    // setProcessing(true);
    const response = await fetch(
      `${API_BASE}course-students/${cid}?page=${page}&search=${search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + _token,
        },
      }
    );

    const data = await response.json();

    if (data.status) {
      if (data.data.all_counts !== 0) {
        let _sorted = sortRecordsByKey(data.data.students, "name", "asc"); //setRowData(data.data.students);
        let _updated_leaderboard_rows = [];
        let _total_time = 0;
        let _existing_leaderboard_rows = _sorted;
        if (_existing_leaderboard_rows.length > 0) {
          for (let [
            i,
            _existing_leaderboard_row,
          ] of _existing_leaderboard_rows.entries()) {
            var _req_slug = data.data.course_slug;
            var _req_agent = _existing_leaderboard_row.email;
            var _rep_1_request_json = { slug: _req_slug, agent: _req_agent };
            const response_n = await fetch(
              `${LRS_ENDPOINT}statements/get-course-duration?slug=` +
              _req_slug +
              "&agent=" +
              _req_agent,
              {
                method: "POST",
                body: JSON.stringify(_rep_1_request_json),
              }
            );
            let data_n = await response_n.json();
            _existing_leaderboard_row.duration = {};
            let _duration = data_n.duration;
            if (_duration != undefined && typeof _duration != "undefined") {
              _existing_leaderboard_row.duration.total_hours = s2t(_duration);
              _existing_leaderboard_row.spent_time = parseInt(_duration);
              _total_time += parseInt(_duration);
              if (_existing_leaderboard_row.lrs.completed != null) {
                _existing_leaderboard_row.course_status = "Completed";
              } else {
                _existing_leaderboard_row.course_status = "Not Completed";
              }
            } else {
              _existing_leaderboard_row.duration.total_hours = "0 Hr";
              _existing_leaderboard_row.spent_time = 0;
              _existing_leaderboard_row.duration.total_hours = "0 Hr";
              _existing_leaderboard_row.spent_time = 0;
              _existing_leaderboard_row.course_status = "Not Started";
            }
            if (
              typeof _existing_leaderboard_row.lrs.quiz.right_count !=
              "undefined" &&
              _existing_leaderboard_row.lrs.quiz.right_count != null
            ) {
              _existing_leaderboard_row.score = parseFloat(
                parseInt(_existing_leaderboard_row.lrs.quiz.right_count) +
                " / " +
                parseInt(_existing_leaderboard_row.lrs.quiz.total_count) * 100
              );
              _existing_leaderboard_row.score_2 =
                parseInt(_existing_leaderboard_row.lrs.quiz.right_count) +
                " / " +
                parseInt(_existing_leaderboard_row.lrs.quiz.total_count);
            } else {
              _existing_leaderboard_row.score = 0;
              _existing_leaderboard_row.score_2 = 0;
            }
            _updated_leaderboard_rows.push(_existing_leaderboard_row);
          }
        }
        setRowDataHiring(_sorted);
        setIsDisabled(false);
      } else {
        setRowDataHiring([]);
      }
    } else {
    }
    SwalBox.close();
  };

  const fetchEmployeeCourses = async (uid) => {
    SwalBox.fire({
      title: t('_sweetalert_process_'),
      html: t('_sweetalert_wait_'),
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        SwalBox.showLoading();
      },
    });
    setRowDataTraining([]);
    //alert(uid);
    setSelectedEmployee(uid);

    setIsDisabled(true);
    const response = await fetch(`${API_BASE}get-learners-course-list/${uid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + _token,
      },
    });

    const data = await response.json();

    if (data.status) {
      if (data.data.total_count !== 0) {
        setSelectedEmployeeAgent(data.data.agent);
        //setRowDataTraining(data.data.courses);

        if (data.status) {
          if (data.data.total_count !== 0) {
            let _sorted = sortRecordsByKey(data.data.courses, "title", "asc"); //setRowData(data.data.students);
            let _updated_leaderboard_rows = [];
            let _total_time = 0;
            let _existing_leaderboard_rows = _sorted;
            if (_existing_leaderboard_rows.length > 0) {
              for (let [
                i,
                _existing_leaderboard_row,
              ] of _existing_leaderboard_rows.entries()) {
                var _req_slug = _existing_leaderboard_row.slug;
                var _req_agent = data.data.agent;
                var _rep_1_request_json = {
                  slug: _req_slug,
                  agent: _req_agent,
                };
                const response_n = await fetch(
                  `${LRS_ENDPOINT}statements/get-course-duration?slug=` +
                  _req_slug +
                  "&agent=" +
                  _req_agent,
                  {
                    method: "POST",
                    body: JSON.stringify(_rep_1_request_json),
                  }
                );
                let data_n = await response_n.json();
                _existing_leaderboard_row.duration = {};
                let _duration = data_n.duration;
                if (_duration != undefined && typeof _duration != "undefined") {
                  _existing_leaderboard_row.duration.total_hours =
                    s2t(_duration);
                  _existing_leaderboard_row.spent_time = parseInt(_duration);
                  _total_time += parseInt(_duration);
                  if (_existing_leaderboard_row.completed != null) {
                    _existing_leaderboard_row.course_status = "Completed";
                  } else {
                    _existing_leaderboard_row.course_status = "Not Completed";
                  }
                } else {
                  _existing_leaderboard_row.duration.total_hours = "0 Hr";
                  _existing_leaderboard_row.spent_time = 0;
                  _existing_leaderboard_row.duration.total_hours = "0 Hr";
                  _existing_leaderboard_row.spent_time = 0;
                  _existing_leaderboard_row.course_status = "Not Started";
                }
                if (
                  typeof _existing_leaderboard_row.quiz.right_count !=
                  "undefined" &&
                  _existing_leaderboard_row.quiz.right_count != null
                ) {
                  _existing_leaderboard_row.score = parseFloat(
                    parseInt(_existing_leaderboard_row.quiz.right_count) +
                    " / " +
                    parseInt(_existing_leaderboard_row.quiz.total_count) * 100
                  );
                  _existing_leaderboard_row.score_2 =
                    parseInt(_existing_leaderboard_row.quiz.right_count) +
                    " / " +
                    parseInt(_existing_leaderboard_row.quiz.total_count);
                } else {
                  _existing_leaderboard_row.score = 0;
                  _existing_leaderboard_row.score_2 = 0;
                }
                _updated_leaderboard_rows.push(_existing_leaderboard_row);
              }
            }
            setRowDataTraining(_sorted);
            setIsDisabled(false);
          } else {
            setRowDataTraining([]);
          }
        }

        setIsDisabled(false);
        SwalBox.close();
      } else {
        setRowDataTraining([]);
        SwalBox.close();
      }
    } else {
      setRowDataTraining([]);
      SwalBox.close();
    }
  };

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv({
      columnKeys: [
        "No",
        "EName",
        "Email",
        "DOE",
        "DOC",
        "Status",
        "CourseComplete",
        "Score",
      ],
    });
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    const allColumnIds = [];
    params.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });

    params.columnApi.autoSizeColumns(allColumnIds);
  };

  const exportResults = async (download) => {
    let _continue = true;
    if (
      selected_report == "by_course" &&
      (selected_course == null ||
        selected_course == "" ||
        selected_course == undefined)
    ) {
      SwalBox.fire({
        title: "Select a Course",
        text: "Please select a Course from the dropdown list.",
        icon: "error",
      });
      setTimeout(() => {
        SwalBox.close();
      }, 3000);
      _continue = false;
    }
    if (
      selected_report == "by_employee" &&
      (selected_employee == null ||
        selected_employee == "" ||
        selected_employee == undefined)
    ) {
      SwalBox.fire({
        title: "Select an Employee",
        text: "Please select an Employee from the dropdown list.",
        icon: "error",
      });
      setTimeout(() => {
        SwalBox.close();
      }, 3000);
      _continue = false;
    }

    if (_continue === true) {
      SwalBox.fire({
        title: t('_sweetalert_process_'),
        html: t('_sweetalert_wait_'),
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          SwalBox.showLoading();
        },
      });

      if (selected_report == "by_course") {
        const gridApi = gridRef.current.api;
        const visibleData = [];
        gridApi.forEachNodeAfterFilterAndSort((node) => {
          visibleData.push(node.data);
        });
        //const emails = employee.map(obj => obj.email);
        const emails = visibleData.map((obj) => obj.email);
        var _email_string = emails.join(",");

        let reportscore = [];
        const filteredArray = visibleData.filter(function (item) {
          var cDuration = "Not Started";
          if (item.duration == null) {
            var cDuration = "Not Started";
          } else {
            var cDuration = item.duration.total_hours;
          }
          reportscore.push(
            item.student_id +
            "/" +
            item.email +
            "/" +
            item.lrs.quiz.right_count +
            "/" +
            item.lrs.quiz.total_count +
            "/" +
            ref_vacancy +
            "/" +
            cDuration
          );
        });
        const _course_details_response = await fetch(
          `${API_BASE}get-course-detail-by-id/${selected_course}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + _token,
            },
          }
        );
        var _slug = "";
        const _course_details_data = await _course_details_response.json();
        if (_course_details_data.status) {
          _slug = _course_details_data.data.course.slug;
          var ref_vacancy = "";
          try {
            var formData = new FormData();
            formData.append("slug", _slug);
            formData.append("ref_vacancy", ref_vacancy);
            formData.append("slides", btoa(_course_details_data.data.slides));
            formData.append("agents", btoa(_email_string));
            formData.append("emails", download);
            formData.append("details", btoa(reportscore.join("*")));
            const _download_response = await fetch(
              `${LRS_ENDPOINT}report/download-hiring-report`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${_token}`,
                },
                body: formData,
              }
            );
            const _download_data = await _download_response.json();

            if (_download_data.status) {
              SwalBox.close();
              setReportFileUrl(_download_data.furl);
              if (download === true) {
                window.location.href = _download_data.furl;
              } else {
                SwalBox.fire({
                  title: "Report has been shared.",
                  text: "An email has been sent to the email address(es) you have provided.",
                  icon: "success",
                });
                setTimeout(() => {
                  document.getElementById("ShareReportClose").click();
                }, 2000);
                setTimeout(() => {
                  SwalBox.close();
                }, 3000);
              }
            }
          } catch (e) { }
          SwalBox.close();
        }
      } else if (selected_report == "by_employee") {
        const gridApi = gridRef.current.api;
        const visibleData = [];
        gridApi.forEachNodeAfterFilterAndSort((node) => {
          visibleData.push(node.data);
        });
        console.info(visibleData);
        let _temp_string = '';
        let _courses_data = [];
        visibleData.forEach((_curr_course, index) => {
          _temp_string = '';

          _temp_string += btoa(_curr_course.title);
          _temp_string += '####';

          _temp_string += btoa(_curr_course.slug);
          _temp_string += '####';

          _temp_string += btoa(_curr_course.progress);
          _temp_string += '####';

          _temp_string += btoa(_curr_course.course_status);
          _temp_string += '####';

          _temp_string += btoa(_curr_course.duration.total_hours);
          _temp_string += '####';

          _temp_string += btoa(_curr_course.spent_time);
          _temp_string += '####';

          _temp_string += btoa(_curr_course.score_2);
          _temp_string += '####';

          _temp_string += btoa(_curr_course.enrolled_date);
          _temp_string += '####';

          _temp_string += btoa(_curr_course.completed);
          _temp_string += '####';
          _courses_data.push(_temp_string);
        });
        const slugs = visibleData.map((obj) => obj.slug);
        var _slug_string = slugs.join(",");
        var _agent_string = selectedEmployeeAgent;
        var _learner_string = selectedEmployeeName;
        try {
          var formData = new FormData();
          formData.append("_slug_string", btoa(_slug_string));
          formData.append("_agent_string", btoa(_agent_string));
          formData.append("_learner_string", btoa(_learner_string));
          formData.append("_course_data", _courses_data.join("*****"));
          formData.append("emails", download);
          const _download_response = await fetch(
            `${LRS_ENDPOINT}report/download-training-report`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${_token}`,
              },
              body: formData,
            }
          );
          const _download_data = await _download_response.json();
          if (_download_data.status) {
            SwalBox.close();
            setReportFileUrl(_download_data.furl);
            if (download === true) {
              window.location.href = _download_data.furl;
            } else {
              SwalBox.fire({
                title: "Report has been shared.",
                text: "An email has been sent to the email address(es) you have provided.",
                icon: "success",
              });
              setTimeout(() => {
                document.getElementById("ShareReportClose").click();
              }, 2000);
              setTimeout(() => {
                SwalBox.close();
              }, 3000);
            }
          }
        } catch (e) { }
        SwalBox.close();
      } else {
        SwalBox.close();
      }
    }
  };

  const ShareResults = async () => {
    document.getElementById("ShareReportButton2").click();
  };
  const gridRefDiv = useRef();

  const printAgGrid = useReactToPrint({
    content: () => gridRefDiv.current,
    documentTitle: "Training Report",
  });

  const onSubmit = async (data) => {
    if (emails.length >= 30) {
      setError(
        "email",
        { type: "focus", message: "Maximum 30 emails can be sent at a time." },
        { shouldFocus: false }
      );
      return false;
    }
    setProcessing(true);
    var _email_string = data.email;
    var _remove_all_spaces = _email_string.replace(/\s\s+/g, " ");
    var _convert_space_in_coma = _remove_all_spaces.split(/[ ,]+/).join(",");
    var iEmail = _convert_space_in_coma.trim();
    var comEmails = iEmail.split(",");
    if (typeof comEmails !== "undefined" && comEmails.length > 1) {
      var duplicate = false;
      var checkEmails = [];
      for (let index = 0; index < comEmails.length; index++) {
        const sepEmail = comEmails[index].trim();
        if (emails.indexOf(sepEmail) === -1) {
          checkEmails.push(sepEmail);
        } else {
          duplicate = true;
        }
      }
      const callAsync = async () => {
        const newList = [...emails, ...checkEmails];
        setEmails(newList.splice(30));
        setEmails(newList);
      };
      callAsync();
      if (duplicate) {
        Toaster.info(
          t('_managelc_invite_email_vali_m32_')
        );
      }
      setValue("email", "");
      setFocus("email");
      setProcessing(false);
    } else {
      if (emails.indexOf(iEmail) !== -1) {
        setError(
          "email",
          { type: "focus", message: t('_managelc_invite_email_vali_m33_') },
          { shouldFocus: true }
        );
        setProcessing(false);
        return false;
      }
      setEmails([...emails, iEmail]);
      setValue("email", "");
      setFocus("email");
      setProcessing(false);
    }
  };
  const removeItem = async (e, item) => {
    const newEmails = [];
    emails.forEach((email, index) => {
      if (item !== email) {
        newEmails.push(email);
      }
    });
    setEmails(newEmails);
  };
  const processShareReport = async () => {
    var formData = new FormData();
    for (var i = 0; i < emails.length; i++) {
      formData.append("members[]", emails[i]);
    }
    //alert(emails.toString());
    var _email_string = emails.toString();
    exportResults(_email_string);
  };
  function getCurrentDateFormatted() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = months[currentDate.getMonth()];
    const day = currentDate.getDate();

    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  }

  const formattedCreationDate = getCurrentDateFormatted();

  const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector("#myGrid");
    eGridDiv.style.width = "";
    // eGridDiv.style.height = '';
    api.setDomLayout("print");
  };

  const setNormal = (api) => {
    const eGridDiv = document.querySelector("#myGrid");
    eGridDiv.style.width = "100%";
    // eGridDiv.style.height = '664px';
    api.setDomLayout();
    // const gridApi = gridRef.current.api;
    // gridApi.setDomLayout();
    var Griddivlist = document.querySelectorAll(
      ".ag-root-wrapper-body.ag-focus-managed"
    );
    Griddivlist[0].style.height = "fit-content";
  };

  const setAgGridForPrint = () => {
    const api = gridRef.current.api;
    setPrinterFriendly(api);
    setTimeout(function () {
      printAgGrid();
    }, 1000);
    setTimeout(function () {
      setNormal(api);
    }, 2000);
  };

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <>
      <a
        className="btn ml-2 mb-2 d-none"
        data-toggle="modal"
        id="ShareReportButton2"
        data-target="#ShareReportModal2"
      >
        &nbsp
      </a>
      <div
        className="modal header_red fade"
        id="ShareReportModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ShareReportModalTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 id="ShareReportTitle">Share Report</h4>
              <button
                type="button"
                id="ShareReportClose"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body invitepop">
              <div className="form-group">
                <label>Enter Email Addresses to Share Report File</label>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="email_box">
                  <input
                    {...register("email", {
                      required: "required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format",
                      },
                    })}
                    type="text"
                    placeholder="Enter email address"
                  />
                  <button
                    disabled={processing}
                    className="btn btn-circle btn-info ml-2 add_btn"
                  >
                    {processing && (
                      <i className="fa fa-refresh fa-spin fa-fw"></i>
                    )}
                    {!processing && "Add"}
                  </button>
                </div>
                <span className="text-danger">
                  {errors.email &&
                    errors.email.type === "required" &&
                    "Please enter email address"}
                  {errors.email &&
                    errors.email.type !== "required" &&
                    errors.email.message}
                </span>
              </form>
              {emails.length > 0 && (
                <>
                  <div className="emailList_box">
                    <ul className="mt-3">
                      {emails.map((email, index) => (
                        <li
                          key={index}
                          className="border px-2 py-1 mt-1 bg-white"
                        >
                          <span>{email}</span>
                          <i
                            onClick={(e) => {
                              removeItem(e, email);
                            }}
                            className="fa fa-times text-danger float-right mt-1"
                            title="Close"
                            aria-hidden="true"
                          ></i>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-circle btn-md cancel-btn"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={(e) => {
                  processShareReport();
                }}
                disabled={sharingReport}
                type="button"
                className="btn btn-circle btn-md submit-pink-btn"
              >
                {" "}
                {sharingReport ? (
                  <>
                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                  </>
                ) : (
                  <>Share Report</>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-2">
        <div className="row m-2 justify-content-between ek-align-items-cent">
          <div className="col-md-4  ek-mb-0 px-0 py-0 materials_section">
            <div className="form-group">
              <label className="m-0" htmlFor={"report_type"}>
                <strong>{t('_tr_report_type_')}</strong>
              </label>
              <>
                <Controller
                  control={control}
                  name="report_type"
                  id="report_type"
                  rules={{ required: false, message: "" }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={report_options}
                      onChange={(val) => {
                        setSelectedReport(val.value);
                        setValue("report_type", val.value);
                        showSelectedReport(val.value);
                        setIsDisabled(true);
                      }}
                      searchable={true}
                    />
                  )}
                />
              </>
            </div>
          </div>

          <div className="col-md-5  ek-mb-0 px-0 py-0 materials_section">
            <button
              disabled={isDisabled}
              onClick={(e) => {
                exportResults(true);
              }}
              type="button"
              className="btn btn-circle btn-info"
            >
              <i className="fa-solid fa-file-excel">&nbsp;</i>{t('_hr_export_')}
            </button>
            &nbsp;
            <button
              disabled={isDisabled}
              onClick={(e) => {
                ShareResults();
              }}
              type="button"
              className="btn btn-circle btn-info"
            >
              <i className="fa-solid fa-share">&nbsp;</i>{t('_hr_share_')}
            </button>
            &nbsp;
            <button
              disabled={isDisabled}
              onClick={() => {
                setAgGridForPrint();
              }}
              type="button"
              className="btn btn-circle btn-info"
            >
              <i className="fa-solid fa-print">&nbsp;</i>{t('_hr_print_')}
            </button>
          </div>
        </div>

        <div className="row m-2 justify-content-between">
          <div className="col-lg-4 ek-mb-0 px-0 py-0">
            {selected_report == "by_course" && (
              <div className="form-group">
                <label className="m-0" htmlFor={"course_id"}>
                  <strong>{t('_tr_select_course_')}</strong>
                </label>
                <>
                  <Controller
                    control={control}
                    name="course_id"
                    id="course_id"
                    rules={{ required: false, message: "" }}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={course_options}
                        onChange={(val) => {
                          setSelectedCourse(val.value);
                          setValue("course_id", val.value);
                          fetchStudentEmployees(val.value);
                          setSelectedCourseName(val.label);
                        }}
                        searchable={true}
                      />
                    )}
                  />
                </>
              </div>
            )}

            {selected_report == "by_employee" && (
              <div className="form-group">
                <label className="m-0" htmlFor={"employee_id"}>
                  <strong>{t('_tr_select_emp_')}</strong>
                </label>
                <>
                  <Controller
                    control={control}
                    name="employee_id"
                    id="employee_id"
                    rules={{ required: false, message: "" }}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={employee_options}
                        onChange={(val) => {
                          setSelectedEmployee(val.value);
                          setValue("employee_id", val.value);
                          fetchEmployeeCourses(val.value);
                          setSelectedEmployeeName(val.label);
                        }}
                        searchable={true}
                      />
                    )}
                  />
                </>
              </div>
            )}
          </div>

          <div className="col-lg-1 ek-mb-0 px-0 py-0">&nbsp;</div>
        </div>
        {selected_course !== "" &&
          selected_course !== null &&
          selected_course !== undefined &&
          selected_report == "by_course" &&
          (rowDataHiring.length !== 0 ? (
            <>
              <div className="Pageselectparent hiringperpage">
                {t('_hr_page_size_')}
                <select onChange={onPageSizeChanged} id="page-size" className="Pageselect">
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="500">1000</option>
                </select>
              </div>
              <div ref={gridRefDiv} className="gridprint">
                <div className="hiringPrint">
                  <p>
                    <span className="PrintColumn">{t('_tr_print_opt1_')}</span>{" "}
                    {t('_training_report_')}
                  </p>
                  <p>
                    <span className="PrintColumn">{t('_hr_print_opt2_')}</span>{" "}
                    {formattedCreationDate}
                  </p>
                  <p>
                    <span className="PrintColumn">{t('_hr_print_opt3_')}</span>
                    {selectedCourseName}
                  </p>
                  <p>
                    <span className="PrintColumn">{t('_hr_print_opt4_')} </span>{" "}
                    {t('_tr_course_report_')}
                  </p>
                </div>
                <div
                  id="myGrid"
                  className="ag-theme-alpine"
                  style={{ height: "100%", width: "100%" }}
                >
                  <AgGridReact
                    onGridReady={onGridReady}
                    columnDefs={columnDefsCourse}
                    // columnDefs={QuizData}
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    rowData={rowDataHiring}
                    domLayout="autoHeight"
                    enableFilter={true}
                    enableSorting={true}
                    enableColResize={true}
                    pagination={true}
                    paginationPageSize={20}
                    rowHeight={50}
                    suppressRowClickSelection={true}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-12">
                <div className="empty_state">
                  <h3>No Record Found</h3>
                  <img alt="" src="images/no_courses.png" />
                </div>
              </div>
            </>
          ))}

        {selected_employee !== "" &&
          selected_employee !== null &&
          selected_employee !== undefined &&
          selected_report == "by_employee" &&
          (rowDataTraining.length !== 0 ? (
            <>
              <div className="Pageselectparent hiringperpage">
                {t('_hr_page_size_')}
                <select onChange={onPageSizeChanged} id="page-size" className="Pageselect">
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </div>
              <div ref={gridRefDiv} className="gridprint">
                <div className="hiringPrint">
                  <p>
                    <span className="PrintColumn">{t('_tr_print_opt1_')} </span>{" "}
                    {t('_training_report_')}
                  </p>
                  <p>
                    <span className="PrintColumn">{t('_hr_print_opt2_')}   </span>{" "}
                    {formattedCreationDate}
                  </p>
                  <p>
                    <span className="PrintColumn">{t('_tr_print_opt3_')} </span>
                    {selectedEmployeeName}
                  </p>
                  <p>
                    <span className="PrintColumn">{t('_hr_print_opt4_')} </span>{" "}
                    {t('_tr_emp_report_')}
                  </p>
                </div>
                <div
                  id="myGrid"
                  className="ag-theme-alpine"
                  style={{ height: "100%", width: "100%" }}
                >
                  <AgGridReact
                    onGridReady={onGridReady}
                    columnDefs={columnDefsEmployee}
                    // columnDefs={QuizData}
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    rowData={rowDataTraining}
                    domLayout="autoHeight"
                    enableFilter={true}
                    enableSorting={true}
                    enableColResize={true}
                    pagination={true}
                    paginationPageSize={20}
                    rowHeight={50}
                    suppressRowClickSelection={true}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-12">
                <div className="empty_state">
                  <h3>No Record Found</h3>
                  <img alt="" src="images/no_courses.png" />
                </div>
              </div>
            </>
          ))}


        {isBlank == true && (
          <>
            <br></br>
            <h3 className="d-flex justify-content-center">{t('_hr_no_record_')}</h3>
          </>
        )}
      </div>
    </>
  );
}

export default TrainingReport;
