import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";


const AboutCard = ({ group }) => {
    const { t } = useTranslation();

    return (
        <div className="about_circle sticky-top">
            <h4>{t('_lcfeed_aboutcard_m1_')} </h4>
            <p className="pad0"><strong>{group.title}</strong></p>
            {parse(group.about)}

            {
                group.private === 'Public'
                &&
                <>
                    <p className="pad0 opacity"><i className="fas fa-globe"></i> <strong>{t('_lcfeed_aboutcard_m2_')} </strong></p>
                    <p>{t('_managelc_about_card_m1_')}.</p>
                    <p className="pad0 opacity"><i className="fas fa-eye"></i> <strong>{t('_managelc_about_card_m2_')}</strong></p>
                    <p>{t('_managelc_about_card_m3_')}.</p>
                </>
            }
            <p className="pad0 opacity"><i className="fas fa-clock"></i> <strong>{t('_managelc_about_card_m4_')}</strong></p>
            <p>{t('_managelc_about_card_m5_')} {group.created_at}</p>
        </div>
    );
}

export default AboutCard;
