import { useTranslation } from "react-i18next";

const About = ({ course }) => {
    const { t } = useTranslation();

    return (
        <>
            {
                (course !== '' && typeof course !== 'undefined')
                &&
                <div className="tab-pane fade show active" id="pills-course" role="tabpanel" aria-labelledby="pills-course-tab">
                    <div className="blog-card">
                        <div className="row justify-content-lg-center">
                            <div className="col-md-12 col-lg-8">
                                <h4>{t('_coursedetail_about_tab_1_')} </h4>
                                <p>{course.title}</p>
                                <h5 className="pt-5">{t('_lcdetail_what_learn__')} </h5>
                                <ul className="about-course-list">
                                    {
                                        course.course_outcomes.length > 0
                                        &&
                                        course.course_outcomes.map((outcome, index) =>
                                            <li key={index}><span>{outcome.description}</span></li>
                                        )
                                    }

                                    {
                                        (course.course_outcomes === '' || course.course_outcomes.length <= 0)
                                        &&
                                        <li><span>{t('_coursedetail_nooutcome_')} </span></li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default About
