import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <section className="custom-section">
                <div className="teaching-about">
                    <div className="container">
                        <div className="teaching-about-info text-center">
                            <h1>{t('_pagenotfound_m1_')} </h1>
                            <img src="/images/404_page.jpg" alt="404" />
                            <div className="text-center mt-3">
                                <button onClick={() => navigate(-2)} type="button" className="btn btn-success btn-sm ml-1">{t('_pagenotfound_m2_')}</button>
                                <Link className="btn btn-success btn-sm ml-2" to='/'>{t('_pagenotfound_m3_')} </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PageNotFound;
