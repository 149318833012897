import { useDropzone } from "react-dropzone";
import { useState, useCallback, useRef, useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import EmployeeListSkeleton from "../../components/skeletons/EmployeeListSkeleton";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import { API_BASE, DEFAULT_UPLOAD_FILE_IMG, SMPLE_EMPLOYEE_CSV } from "../../constants/global";
import CustomPagination from "../../entity/CustomPagination";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Joyride from 'react-joyride';
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useReactToPrint } from 'react-to-print';

const toggleLeftMenu = async () => {
    try {
        let sidebar = document.querySelector(".sidebar");
        sidebar.classList.add("close");
    } catch (e) { }
}

const EmployeeListing = () => {
    const location = useLocation();
    const gridRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const _token = localStorage.getItem('lmstoken');
    const [employees, setEmployees] = useState('');
    const [loading, setLoading] = useState(false);
    const [total_count, setTotalCount] = useState(0);
    const [processing, setProcessing] = useState(false);
    const [file, setFiles] = useState('');
    const [viaEmailShow, setViaEmailShow] = useState(false);
    const [tour_steps, setTourSteps] = useState([]);
    const user = JSON.parse(localStorage.getItem('lmsuser'));

    const [rowData, setRowData] = useState([]);

    const [roles, setRoles] = useState('');
    const [employeeid, setEmployeeid] = useState('');
    const [viewModal, setViewModal] = useState(false);

    const { t } = useTranslation();

    const { register: register2, control: control2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, clearErrors: clearErrors2, setError: setError2, reset: reset2, resetField: resetField2, unregister: unregister2, setValue: setValue2 } = useForm(
        {
            mode: "all",
        }
    );

    const initialValues = {
        file: "",
        message_box: t('_emp_invite_platformdefaultmsg_'),
    }

    const gridRefDiv = useRef();

    const printAgGrid = useReactToPrint({
        content: () => gridRefDiv.current,
        documentTitle: "Employeelist_Edukula",
    });

    useEffect(() => {
        fetchEmployees(currentPage);
        showEmployeeTour();
        fetchRoles();
    }, [location, reload]);

    useEffect(() => {
        toggleLeftMenu();
    }, []);

    const fetchRoles = async (page = 0) => {
        const response = await fetch(`${API_BASE}role/get?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            setRoles(data.data.roles);
        } else {

        }
    }

    const showEmployeeTour = () => {
        let show_tour = Cookies.get("show_employee_tour");
        if (show_tour != 'NO') {
            SwalBox.fire({
                allowOutsideClick: false,
                title: t('_show_tour_maintitle_'),
                text: t('_show_tour_msg_emplist_'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: t('_show_tour_yes_'),
                cancelButtonText: t('_show_tour_no_')
            }).then((result) => {
                if (result.isConfirmed) {
                    startEmployeeTour();
                } else {
                    Cookies.set('show_employee_tour', 'NO');
                }
            });
        }
    }
    const startEmployeeTour = () => {
        const ek_manage_employees = localStorage.getItem('manage_employees');
        let steps = [];
        if (user?.is_corporate === 1 || ek_manage_employees == 'YES') {
            steps = [
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-employee-step-01', title: t('_emplist_tour_t1_'), content: t('_emplist_tour_m1_'), },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-employee-step-02', title: t('_emplist_tour_t2_'), content: t('_emplist_tour_m2_') },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-employee-step-03', title: t('_emplist_tour_t3_'), content: t('_emplist_tour_m3_') },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-employee-step-04', title: t('_emplist_tour_t4_'), content: t('_emplist_tour_m4_') },
            ];
        } else {
            steps = [
            ];
        }
        setTourSteps(steps);
        Cookies.set('show_employee_tour', 'NO');
    }

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }

    const fetchEmployees = async (page = 0, search = "") => {
        setLoading(true);
        const response = await fetch(`${API_BASE}employee/get?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            setEmployees(data.data.employees);
            setRowData(data.data.employees);

            setTotalCount(data.data.total_count);
        } else {
            setEmployees('');
            setTotalCount(0);
        }
        setLoading(false);
    }

    const ConfirmStatusChange = (corporate_emp_status, emp_id, name = '') => {
        var statusEnable = true;
        if (corporate_emp_status === 1) {
            statusEnable = false
        }
        var textT = statusEnable ?
            `${t('_emplist_del_con_')} ${name}’s ${t('_emplist_del_con2_')}.`
            :
            `${t('_emplist_del_con4_')} ${name}’s ${t('_emplist_del_con2_')}.`;

        SwalBox.fire({
            title: t('_v_delete_confirm_'),
            text: textT,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_cancel_')
        }).then((result) => {
            if (result.isConfirmed) {
                ChangeStatus(statusEnable, emp_id);
            }
        });
    }

    const ChangeStatus = async (status, emp_id) => {
        const response = await fetch(`${API_BASE}employee/status/${emp_id}/${status ? 1 : 0}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            setReload(!reload);
        } else {
            Toaster.error(data.message);
        }
    }

    const ConfirmDeleteEmployee = (employee_id, name = '') => {
        SwalBox.fire({
            title: t('_v_delete_confirm_'),
            text: `${t('_emplist_del_con5_')} ${name}’s ${t('_emplist_del_con6_')}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_cancel_')
        }).then((result) => {
            if (result.isConfirmed) {
                deleteEmployee(employee_id);
            }
        });
    }

    const deleteEmployee = async (emp_id, status = 2) => {
        const response = await fetch(`${API_BASE}employee/status/${emp_id}/${status}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            setReload(!reload);
        } else {
            Toaster.error(data.message);
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'text/csv': ['.csv']
        },
        maxFiles: 1,
        onDrop: (acceptFile, fileRejections) => {
            if (fileRejections.length == 0) {
                setFieldValue("file", acceptFile[0])
                setFiles(acceptFile[0].name)

                const reader = new FileReader();
                reader.onload = function (e) {
                    const csvContent = e.target.result;
                    const lines = csvContent.split("\n");

                    const sampleFirstLine = "First name ( Required ),Last name ( Required ),Email ( Required )";
                    const sampleFirstLine2 = "First name,Last name,Email";
                    const sampleFirstLine3 = "First Name,Last Name,Email";
                    const sampleFirstLine4 = "FirstName,LastName,Email";
                    const sampleFirstLine5 = "Firstname,Lastname,Email";
                    const sampleFirstLine6 = "firstname,lastname,email";
                    const sampleFirstLine7 = "first_name,last_name,email";

                    if (lines.length === 2 && lines[1].trim() === "") {
                        SwalBox.fire({
                            text: t('_emp_addemp_csv_m1_'),
                            icon: 'warning',
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: t('_mylc_ok_')
                        });
                        setFieldValue("file", '');
                        setFiles('');
                    }
                    else if (lines.length > 0 && lines[0].trim() !== sampleFirstLine && lines[0].trim() !== sampleFirstLine2 && lines[0].trim() !== sampleFirstLine3 && lines[0].trim() !== sampleFirstLine4 && lines[0].trim() !== sampleFirstLine5 && lines[0].trim() !== sampleFirstLine6 && lines[0].trim() !== sampleFirstLine7) {
                        SwalBox.fire({
                            text: t('_emp_addemp_csv_m2_'),
                            icon: 'warning',
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: t('_mylc_ok_')
                        });
                        setFieldValue("file", '');
                        setFiles('');
                    }
                }
                reader.readAsText(acceptFile[0]);
            } else {
                setFieldValue("file", '');
                setFiles('');
            }
        },
    });

    const removeFile = () => {
        setFieldValue("file", '')
        setFiles('')
    }

    const validationSchema = Yup.object().shape({
        file: Yup.string().required(t('_emp_addemp_csv_m3_')),
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, action) => {
            const formData = new FormData();
            setProcessing(true);
            for (let value in values) {
                formData.append(value, values[value]);
            }


            let url = `${API_BASE}employee/save-by-csv/1`;  // For invitation only use url 'employee/save-by-csv/1'
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData,
            });
            const data = await response.json();
            if (data.status) {
                setProcessing(false);
                action.resetForm();
                document.getElementById('UploadModalLongClose').click();
                Toaster.success(data.message);
                fetchEmployees();
                setFieldValue("file", '')
                setFiles('')
            } else {
                Toaster.error(data.message);
            }
        }
    });

    const defaultColDef = {
        resizable: true,
        editable: false,
        filter: true,
        sortable: true,
        floatingFilter: true,
        suppressNavigable: true,
    }

    const columnDefs = [
        {
            headerName: t('_tr_emp_col_sr_'),
            valueGetter: "node.rowIndex + 1",
            filter: false,
            sortable: false,
            floatingFilter: false,
            width: 80,
            minWidth: 80,
            colId: "No"
        },
        {
            headerName: t('_emplist_ag_col_name_'),
            field: 'full_name',
            cellRenderer: (params) => {
                return (
                    <>
                        <img src={params.data.image} alt="Profile Photo" width={45} height={45} ></img>
                        <span>&nbsp;&nbsp;{params.data.full_name}</span>
                    </>
                )
            },
            width: 250,
            minWidth: 250,
            colId: "FullName"
        },
        {
            headerName: t('_emplist_ag_col_name_'),
            field: "full_name",
            hide: true,
            colId: "FName"
        },
        {
            headerName: t('_emplist_ag_col_role_'),
            field: 'corporate_emp_role',
            width: 100,
            minWidth: 100,
            colId: "Role"
        },
        {
            headerName: t('_emplist_ag_col_email_'),
            field: 'email',
            flex: 3,
            colId: "email"
        },
        {
            headerName: t('_emplist_ag_col_status_'),
            field: 'corporate_emp_status',
            valueGetter: (params) => {
                if (params.data.corporate_emp_status === 1) {
                    return 'Active';
                }
                else if (params.data.corporate_emp_status !== 1) {
                    return 'Inactive';
                }
            },
            width: 100,
            minWidth: 100,
            cellStyle: params => {
                if (params.data.corporate_emp_status === 1) {
                    return { color: 'green' };
                }
                else if (params.data.corporate_emp_status !== 1) {
                    return { color: '#ef453f' };
                }
                return null;
            },
            colId: 'Status'
        },
        {
            headerName: t('_emplist_ag_col_action_'),
            width: 150,
            pinned: 'right',
            filter: false,
            sortable: false,
            floatingFilter: false,
            resizable: false,
            cellRenderer: (params) => {
                return (
                    <>
                        <span style={{
                            cursor: 'pointer',
                            color: '#ef453f'
                        }}>
                            <span onClick={() => { ConfirmStatusChange(params?.data?.corporate_emp_status, params?.data?.uid, params?.data?.full_name) }} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Active / InActive"><i className="fa-solid fa-sliders"></i></span>
                            <span onClick={() => { setViewModal(true); ConfirmUpdateEmployee(params?.data?.uid, params?.data?.full_name) }} style={{ marginLeft: '15px' }} data-bs-toggle="tooltip" data-bs-placement="bottom" title="View">
                                <i className="fa-solid fa-eye"></i>
                            </span>
                            <span onClick={() => { ConfirmUpdateEmployee(params?.data?.uid, params?.data?.full_name) }} style={{ marginLeft: '15px' }} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit"><i className="fa fa-pencil"></i></span>
                            <span onClick={() => { ConfirmDeleteEmployee(params?.data?.uid, params?.data?.full_name) }} style={{ marginLeft: '15px' }} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"><i className="fa fa-trash"></i></span>
                        </span>
                    </>
                );
            },
            colId: 'button'
        },
    ];



    const ConfirmUpdateEmployee = async (employee_id, name = '') => {
        const response = await fetch(`${API_BASE}employee/detail/${employee_id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
        });
        const data = await response.json();
        if (data.status) {
            setProcessing(true);
            document.getElementById('modalTogglerUpdateEmployee').click();
            setValue2('first_name', data.data.employee.first_name);
            setValue2('last_name', data.data.employee.last_name);
            setValue2('phone', data.data.employee.phone);
            setValue2('headline', data.data.employee.headline);
            setValue2('email', data.data.employee.email);
            setValue2("page", "update");

            roles.map((role, index) => {
                if (role.name === data.data.employee.corporate_emp_role) {
                    setValue2('role_id', role.role_id);
                }
            })

            setEmployeeid(data.data.employee.uid);
            setProcessing(false);
        } else {
            Toaster.error(data.message);
        }
    }


    const SaveQuestion = async (form) => {

        setProcessing(true);

        let url = `${API_BASE}employee/save/${employeeid}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(form),
        });

        const data = await response.json();
        if (data.status) {
            document.getElementById('UploadModalLongClose2').click();
            Toaster.success(data.message);
            setProcessing(false);
            setReload(!reload);
            reset2();
        } else {
            setProcessing(false);
            document.getElementById('UploadModalLongClose2').click();
            reset2();
        }
    }

    const handleCloseUpdateQuestion = () => {
        reset2();
        setViewModal(false);
    }

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ columnKeys: ['No', 'FName', 'Role', 'email', 'Status'] });
    }, []);

    function getCurrentDateFormatted() {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = months[currentDate.getMonth()];
        const day = currentDate.getDate();

        const formattedDate = `${month}-${day}-${year}`;
        return formattedDate;
    }

    const formattedCreationDate = getCurrentDateFormatted();

    const onPageSizeChanged = useCallback(() => {
        var value = document.getElementById('page-size').value;
        gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_ud_l_emplist_')} `}</title>
            </Helmet>
            <div className="col-md-9 home-section">
                <section className={`custom-section materials_section`}>
                    <div className="profile_user_middle_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="custom-user-profile-content custom-learning-circle-content chatPage">

                                        <div className="custom-learnig-circle-head">
                                            <div className="top_list">
                                                <h4>{t('_ud_l_emplist_')} </h4>
                                                <div className="col-md-12 text-right">
                                                    <a className="btn btn-circle btn-info tour-employee-step-01" id="MaterialsMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-plus" aria-hidden="true"></i> {t('_emplist_add_')} </a>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MaterialsMenuButton">
                                                        <Link to={'/dashboard/employee/form'} className="dropdown-item">{t('_emplist_addnewemp_')}</Link>
                                                        <Link to={''} className="dropdown-item" data-toggle="modal" data-target="#UploadModalLong">{t('_emplist_importcsv_')} </Link>
                                                        <Link to={''} onClick={() => { setViaEmailShow(true); }} className="dropdown-item">{t('_emplist_inviteviaemail_')} </Link>
                                                    </div>
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className='QuizSelectedParent'>
                                                    <div className="QuizSelected">
                                                        <button className='GenVid GenQueBank tour-employee-step-02' onClick={() => { onBtnExport() }}>
                                                            {t('_emplist_exportcsv_')}
                                                        </button>

                                                        <button className='GenVid GenQueBank tour-employee-step-03' onClick={() => { printAgGrid() }}>
                                                            {t('_emplist_print_')}
                                                        </button>
                                                    </div>
                                                    <div className="Pageselectparent tour-employee-step-04">
                                                        {t('_hr_page_size_')}
                                                        <select onChange={onPageSizeChanged} id="page-size" className="Pageselect">
                                                            <option value="10">10</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="150">150</option>
                                                            <option value="200">200</option>
                                                            <option value="500">500</option>
                                                            <option value="1000">1000</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div ref={gridRefDiv} className="gridprint">
                                            <div className="hiringPrint">
                                                <p><span className="PrintColumn">{t('_tr_print_opt1_')}</span>{t('_ud_l_emplist_')}</p>
                                                <p><span className="PrintColumn">{t('_hr_print_opt2_')} </span> {formattedCreationDate}</p>
                                                <p><span className="PrintColumn">{t('_emplist_print_cmp_')} </span>{user?.first_name}</p>
                                            </div>

                                            <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }} >
                                                <AgGridReact
                                                    // onGridReady={onGridReady}
                                                    columnDefs={columnDefs}
                                                    ref={gridRef}
                                                    // suppressExcelExport={true}
                                                    defaultColDef={defaultColDef}
                                                    rowData={rowData}
                                                    domLayout="autoHeight"
                                                    enableFilter={true}
                                                    enableSorting={true}
                                                    enableColResize={true}
                                                    pagination={true}
                                                    paginationPageSize={10}
                                                    rowHeight={50}
                                                    suppressRowClickSelection={true}
                                                />

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <div className="modal fade uploadcsv" id="UploadModalLong" tabIndex="-1" role="dialog" aria-labelledby="UploadModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 id="UploadModalLongTitle">{t('_emplist_importcsv_')}</h4>
                            <button type="button" id="UploadModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} encType="multipart/form-data">
                                <div className="form-group">
                                    <strong>{t('_emp_invite_msg_')} </strong>
                                    <textarea rows="4" className="form-control" id="message_box" name="message_box" onChange={handleChange} onBlur={handleBlur} placeholder={t('_managelc_email_invitation_msg_place_')} value={values.message_box}></textarea>
                                </div>
                                <div className="form-group">
                                    <label>{t('_emplist_upload_')}</label>
                                    {
                                        file === ''
                                        &&
                                        <>
                                            <div {...getRootProps()} className="upfile_img_vdo">
                                                <input {...getInputProps()} />
                                                <a className="profile-img"><img src={DEFAULT_UPLOAD_FILE_IMG} className="" alt='preview' /></a>
                                            </div>
                                        </>
                                    }

                                    {
                                        file !== ''
                                        &&
                                        <>
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <span className="text-danger">{file}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="btnGrp">
                                                        <button type="button" onClick={(e) => { removeFile(); }} className="dlt_btn"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div><br />
                                        </>
                                    }
                                    {
                                        (errors.file && touched.file) &&
                                        <span className="text-danger">{errors.file}</span>
                                    }
                                    <p className="small_txt">{t('_emplist_upload_csv_msg_')} <a href={SMPLE_EMPLOYEE_CSV} download style={{ color: "#ef403d" }}>{t('_emp_samplefile_')}</a></p>
                                </div>
                                <div className="form-group border-top pt-2 text-right mt-4">
                                    <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">{t('_hr_close_')}</button>
                                    <button disabled={processing} type="submit" className="btn btn-circle btn-md submit-pink-btn">
                                        {
                                            processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : t('_emplist_inviteemps_')
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="materials_section">
                <div className="top_list">
                    <div className="modal fade viewemployee" id="UpdateEmployeeModalLong" tabIndex="-1" role="dialog" aria-labelledby="UpdateEmployeeModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered modal-ek-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 id="UpdateEmployeeModalLongTitle">{viewModal ? t('_emplist_view_') : t('_emplist_update_')} {t('_emplist_empdetail_')}</h4>
                                    <button type="button" id="UploadModalLongClose2" className="close" data-dismiss="modal" aria-label="Close"
                                        onClick={handleCloseUpdateQuestion}
                                    ><span aria-hidden="true">&times;</span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="container">
                                        <form key={2} onSubmit={handleSubmit2(SaveQuestion)}>

                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label><b>{t('_emp_update_name_')}</b></label>
                                                    <input type="text" autoComplete="none" name="first_name" id="first_name" placeholder={t('_emp_update_name_place_')} className="form-control" autoFocus
                                                        {...register2("first_name", {
                                                            required: "required",
                                                        })} disabled={viewModal}
                                                    />
                                                    <span className="text-danger">
                                                        {errors2.first_name && errors2.first_name.type === "required" && t('_emplist_error_fname_')}
                                                    </span>
                                                </div>
                                                <div className="col-md-6">
                                                    <label><b>{t('_emplist_lastname_')} </b></label>
                                                    <input type="text" autoComplete="none" name="last_name" id="last_name" placeholder={t('_emplist_lastname_place')} className="form-control"
                                                        {...register2("last_name", {
                                                            required: "required",
                                                        })} disabled={viewModal}
                                                    />
                                                    <span className="text-danger">
                                                        {errors2.last_name && errors2.last_name.type === "required" && t('_emplist_lastname_error_')}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label><b>{t('_emplist_phone_')} </b></label>
                                                    <input type="text" autoComplete="none" name="phone" id="phone" placeholder={t('_emplist_phone_place_')} className="form-control"
                                                        {...register2("phone", {
                                                            pattern: {
                                                                value: /^(\+\d{1,3}[- ]?)?\d{8,16}$/,
                                                                message: t('_emplist_phone_vali_msg_')
                                                            },
                                                        })} disabled={viewModal}
                                                    />
                                                    <span className="text-danger">
                                                        {errors2.phone && errors2.phone.type !== "required" && errors2.phone.message}
                                                    </span>
                                                </div>
                                                <div className="col-md-6">
                                                    <label><b>{t('_emplist_ag_col_role_')} </b></label>
                                                    <select name="role_id" id="corporate_emp_role" className="form-control select2"
                                                        {...register2("role_id")} disabled={viewModal}
                                                    >
                                                        <option value='eyJpdiI6Ind5OTBGQVE2dzRYS3V6N3p2N2Zuc1E9PSIsInZhbHVlIjoiQTVRc3M4QTZKV2RSZUJNMDRkQ29KZz09IiwibWFjIjoiYzRiOWYwNjgwMmRjN2I5MGU5MzAyZjNlMzMyYTBlODc4Yjc4NTZjOTcwZWUyMjlhZWQwZTU1ZmViNTZiYTAwYSIsInRhZyI6IiJ9'>{t('_emplist_update_emp_')}</option>
                                                        {roles &&
                                                            roles.map((role, index) =>
                                                                <option key={index} value={role.role_id}>{role.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label><b>{t('_emplist_update_heading_')}</b></label>
                                                    <input type="text" autoComplete="none" name="headline" id="headline" placeholder={t('_emp_update_head_place_')} className="form-control" {...register2("headline")} disabled={viewModal}
                                                    />
                                                    {/* <span className="text-danger">
                                                        {errors2.headline && errors2.headline.type === "required" && 'Please enter Headline'}
                                                    </span> */}
                                                </div>
                                                <div className="col-md-6">
                                                    <label><b>{t('_emplist_ag_col_email_')} </b></label>
                                                    <input disabled={true} type="email" name="email" id="email" placeholder={t('_emplist_update_email_place_')} className="form-control"
                                                        {...register2("email")}
                                                    />
                                                </div>
                                            </div>
                                            {viewModal === false ? (
                                                <button disabled={processing} type="submit" className="btn btn-circle btn-md submit-pink-btn">{processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : t('_emplist_update_email_')}   </button>
                                            ) : (<></>)
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a id="modalTogglerUpdateEmployee" data-toggle="modal" data-target="#UpdateEmployeeModalLong">&nbsp;</a>

            <InviteViaEmail show={viaEmailShow} onHide={() => setViaEmailShow(false)} />


            {
                tour_steps.length > 0
                &&
                <Joyride
                    steps={tour_steps}
                    disableCloseOnEsc={true}
                    disableOverlayClose={true}
                    showSkipButton={true}
                    continuous={true}
                    hideCloseButton={true}
                    locale={{ back: t('_tour_back_'), close: t('_tour_close_'), last: t('_tour_last_'), next: t('_tour_next_'), open: t('_tour_open_dialog_'), skip: t('_tour_skip_') }}
                    styles={{ options: { width: 500, zIndex: 5000, primaryColor: '#31314c', textColor: '#ef4a45', overlayColor: 'rgb(2 1 0 / 69%)', } }}
                />
            }


        </>
    )
}

const InviteViaEmail = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const { register, handleSubmit, formState: { errors }, setValue, setError, setFocus } = useForm();
    const [emails, setEmails] = useState([]);
    const [processing, setProcessing] = useState(false);
    const { t } = useTranslation();

    const onSubmit = async (data) => {
        if (emails.length >= 30) {
            setError("email", { type: "focus", message: t('_managelc_invite_email_vali_m31_') }, { shouldFocus: false });
            return false;
        }

        setProcessing(true);
        //var iEmail = data.email;
        var _email_string = data.email;
        var _remove_all_spaces = _email_string.replace(/\s\s+/g, ' ');
        var _convert_space_in_coma = _remove_all_spaces.split(/[ ,]+/).join(',');
        var iEmail = _convert_space_in_coma.trim();
        var comEmails = iEmail.split(",");

        if (typeof comEmails !== 'undefined' && comEmails.length > 1) {
            var duplicate = false;
            var checkEmails = [];
            for (let index = 0; index < comEmails.length; index++) {
                const sepEmail = comEmails[index].trim();
                if (emails.indexOf(sepEmail) === -1) {
                    checkEmails.push(sepEmail);
                } else {
                    var duplicate = true;
                }
            }
            checkEmails = checkEmails.join(",");
            const checkAPI = async () => {
                const formData = new FormData();
                formData.append('email', checkEmails);
                var sURL = `${API_BASE}employee/check-emails-exists`;
                const response = await fetch(sURL, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${_token}`
                    },
                    body: formData
                });
                const rdata = await response.json();
                if (rdata.status === true) {
                    return rdata.data.emails;
                }
            }
            const callAsync = async () => {
                const newEmails = await checkAPI();
                //const newList = [...emails, ...checkEmails];
                const newList = newEmails;
                setEmails(newList.splice(30));
                setEmails(newList);
            }
            callAsync();
            if (duplicate) {
                Toaster.info(t('_managelc_invite_email_vali_m32_'));
            }
            setValue('email', '');
            setFocus('email');
            setProcessing(false);
        } else {
            if (emails.indexOf(iEmail) !== -1) {
                setError("email", { type: "focus", message: t('_managelc_invite_email_vali_m33_') }, { shouldFocus: true });
                setProcessing(false);
                return false;
            }
            const formData = new FormData();
            formData.append('email', iEmail);
            var sURL = `${API_BASE}employee/check-email-exists`;
            const response = await fetch(sURL, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData
            });
            const rdata = await response.json();
            if (rdata.status && rdata.data.exists === true) {
                setError("email", { type: "focus", message: t('_managelc_invite_email_vali_m34_') }, { shouldFocus: true });
                setProcessing(false);
                return false;
            }
            setEmails([...emails, data.email]);
            setValue('email', '');
            setFocus('email');
            setProcessing(false);
        }
    }

    const sendInvitation = async () => {
        const message = document.getElementById('invite_message_box').value;
        var formData = new FormData();
        for (var i = 0; i < emails.length; i++) {
            formData.append('members[]', emails[i]);
        }
        formData.append('message', message);
        setProcessing(true);
        var sURL = `${API_BASE}employee/invite-to-corporate`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            Toaster.success(rdata.message);
            setEmails([]);
            setValue('email', '');
            setProcessing(false);
        } else {
            Toaster.error(rdata.message);
            setProcessing(false);
        }

        props.onHide();
    }

    const removeItem = async (e, item) => {
        const newEmails = [];

        emails.forEach((email, index) => {
            if (item !== email) {
                newEmails.push(email);
            }
        });

        setEmails(newEmails);
    }

    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <h4>{t('_emplist_inviteviaemail_')}</h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body>
                <div id="invite_email" className="invitepop">
                    <p className="text-label-invite"><strong>{t('_v_invite_msg_')} </strong></p>
                    <textarea rows="4" className="form-control" id="invite_message_box" placeholder={t('_managelc_email_invitation_msg_place_')} defaultValue={t('_emp_invite_defaultmsg_')}></textarea>
                    <br />

                    <>
                        <strong>{t('_v_invite_add_emails_')}</strong>
                        <p>{t('_v_invite_limit_')}</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="email_box">
                                <input {...register("email", {
                                    required: "required",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: "Entered value does not match email format"
                                    }
                                })} type="text" placeholder={t('_v_invite_email_place_')} />
                                <button disabled={processing} className="btn btn-circle btn-info ml-2">
                                    {
                                        processing
                                        &&
                                        <i className="fa fa-refresh fa-spin fa-fw"></i>
                                    }
                                    {
                                        !processing
                                        &&
                                        t('_v_invite_email_add_')
                                    }
                                </button>
                            </div>
                            <span className="text-danger">
                                {errors.email && errors.email.type === "required" && 'Please enter email address'}
                                {errors.email && errors.email.type !== "required" && errors.email.message}
                            </span>
                        </form>
                    </>

                    {
                        emails.length > 0
                        &&
                        <>
                            <hr />
                            <ul className="">
                                {
                                    emails.map((email, index) =>
                                        <li key={index} className="border px-2 py-1 mt-1">
                                            <span>{email}</span>
                                            <i onClick={(e) => { removeItem(e, email) }} className="fa fa-times text-danger float-right mt-1" title="Close" aria-hidden="true"></i>
                                        </li>
                                    )
                                }
                            </ul>
                        </>
                    }


                    <div className="btn_right">
                        <button onClick={props.onHide} type="button" className="btn btn-circle btn-md cancel-btn">{t('_v_cancel_')}</button>
                        {
                            emails.length > 0
                            &&
                            <button type="submit" className="btn btn-circle btn-md submit-pink-btn" disabled={processing} onClick={(e) => { sendInvitation() }}>
                                {
                                    processing
                                    &&
                                    <i className="fa fa-refresh fa-spin fa-fw"></i>
                                }
                                {
                                    !processing
                                    &&
                                    t('_send_')
                                }
                            </button>
                        }
                    </div>
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default EmployeeListing;
