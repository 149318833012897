import { Controller, useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import { useEffect, useCallback, useRef, useState } from "react";
import ReactTags from 'react-tag-autocomplete';
import { SwalBox, Toaster } from "./../../entity/GlobalJS";
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt.ts";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const LearningPreferences = () => {
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const navigate = useNavigate();
    const _token = localStorage.getItem('lmstoken');
    const { register, control, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();
    const [learning_email, setLearningEmail] = useState(1);
    const [learning_chat, setLearningChat] = useState('private');
    const [career_type, setCareerType] = useState('');
    const [learning_industry, setIndustry] = useState('');
    const [learning_area, setArea] = useState('');
    const [selected_lang, setSelectedLang] = useState([]);
    const [course_interest, setCourseInterest] = useState(false);
    const [circle_interest, setCircleInterest] = useState(false);
    const [job_interest, setJobInterest] = useState(false);
    const [lang_options, setLangOptions] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [tags, setTags] = useState([]);
    const [career, setCareer] = useState(null);
    const [fetching, setFetching] = useState(true);
    const [autoPlay, setAutoPlay] = useState(true);
    const [autoComplete, setAutoComplete] = useState(true);

    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation] = useCallbackPrompt(showDialog);
    const { t } = useTranslation();

    useEffect(() => {
        if (user?.is_corporate === 1) {
            navigate('/profile/general');
        }

        fetchCareerTypes();
        fetchCategories();
        fetchLearningPreferences();
        fetchPreferencesSuggestions();
    }, []);

    useEffect(() => {
        if (showPrompt) {
            ConfirmSaveChanges();
        }
    }, [showPrompt]);

    const ConfirmSaveChanges = () => {
        SwalBox.fire({
            title: t('_confirm_save_title_'),
            text: t('_confirm_save_msg_'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            cancelButtonColor: '#d33',
            cancelButtonClass: 'button_cancel_class',
            confirmButtonText: t('_save_'),
            cancelButtonText: t('_cancel_')
        }).then((result) => {
            if (result.isConfirmed) {
                handleSubmit(saveLearnignPreference)();
                confirmNavigation();
            } else {
                confirmNavigation();
            }
        });
    }

    const reactTags = useRef();
    const onDelete = useCallback((tagIndex) => {
        setTags(tags.filter((_, i) => i !== tagIndex));
        setShowDialog(true);
    }, [tags]);

    const onAddition = useCallback((newTag) => {
        setTags([...tags, newTag]);
        setShowDialog(true);
    }, [tags]);

    const fetchCareerTypes = async () => {
        const response = await fetch(`${API_BASE}get-career-types`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        });

        const data = await response.json();
        if (data.status) {
            setCareer(data.data);
        } else {
            setCareer(null);
        }
    }

    const fetchLearningPreferences = async () => {
        setFetching(true);
        const response = await fetch(`${API_BASE}get-learning-preferences`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            var learningPref = data.data.learning_preference;
            setLearningEmail(learningPref.email);
            setCareerType(learningPref.career_type);
            setValue('career_type', learningPref.career_type);
            setValue('learning_industry', learningPref.industry);
            setValue('learning_area', learningPref.area);
            setValue('learning_lang', learningPref.lang);
            setCourseInterest(learningPref.course_interest === 1 ? true : false);
            setCircleInterest(learningPref.circle_interest === 1 ? true : false);
            setJobInterest(learningPref.job_interest === 1 ? true : false);
            let tags = [];
            learningPref.topic.forEach((element, index, array) => {
                tags[index] = { id: index, name: element };
            })
            setTags(tags)

            let stringLang = [];
            learningPref.lang.forEach(function (value, i) {
                stringLang.push({ value: value, label: value });
            });
            setSelectedLang(stringLang);
            setFetching(false);
        } else {
            Toaster.error(data.message);
        }
    }

    const fetchCategories = async () => {
        const response = await fetch(`${API_BASE}get-course-languages`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            var stringJ = [];
            data.data.languages.forEach(function (value, i) {
                stringJ.push({ value: value.name, label: value.name });
            });
            setLangOptions(stringJ);
        }
    }

    const fetchPreferencesSuggestions = async () => {
        let url = '';
        url = `${API_BASE}get-preferences-suggestions`

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            setSuggestions(data.data.suggestions);
        }
    }

    const saveLearnignPreference = async (form) => {
        const newTags = tags.map((tag, inx) => tag.name);
        const newForm = {
            ...form,
            'learn_pref': newTags,
            course_interest: course_interest,
            circle_interest: circle_interest,
            job_interest: job_interest,
        };

        setFetching(true);
        const response = await fetch(`${API_BASE}post-learning-preferences`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(newForm)
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);

            if (form?.career_type === 'Working Professional') {
                localStorage.setItem('lmsuser', JSON.stringify({ ...user, career_type: career_type }));
            }
            setFetching(false);
            setShowDialog(false);
        } else {
            Toaster.error(data.message);
            setFetching(false);
        }
    }

    const handleChange = event => {
        clearErrors('learning_email');
        var radioSelect = event.target.value == 1 ? 1 : 0
        setLearningEmail(radioSelect);
    };
    const handleChangeChat = event => {
        clearErrors('learning_chat');
        var radioSelect = event.target.value;
        setLearningChat(radioSelect);
        alert(radioSelect);
    };

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_lp_learning_preferences_')}`}</title>
            </Helmet>
            <div className="tab-pane fade show active" id="v-pills-learning" role="tabpanel" aria-labelledby="v-pills-learning-tab">
                <div className="profile-dashboard-content">
                    <h4>{t('_lp_learning_preferences_')}</h4>
                    <div className="profile-dashboard-box">
                        <form onSubmit={handleSubmit(saveLearnignPreference)}>
                            <div className="row">

                                <div className="col-sm-12">
                                    <div className="">
                                        {
                                            career
                                                ?
                                                <div className="form-group">
                                                    <label className="w-100" htmlFor="">{career?.text}</label>
                                                    {
                                                        career?.types
                                                            ?
                                                            career?.types?.map((ctype, index) =>
                                                                <div key={index} className="w-50">
                                                                    <input {...register('career_type', {
                                                                        required: "required",
                                                                    })}
                                                                        checked={career_type === ctype ? 'checked' : ''}
                                                                        type={'radio'}
                                                                        id={`${ctype}`}
                                                                        value={ctype}
                                                                        onChange={(e) => {
                                                                            setCareerType(e.target.value);
                                                                            setShowDialog(true);
                                                                        }}
                                                                    />
                                                                    <label className="cursor-pointer" htmlFor={`${ctype}`}>&nbsp; {ctype}</label>
                                                                </div>
                                                            )
                                                            :
                                                            null
                                                    }
                                                    <span className="text-danger">
                                                        {errors.career_type && errors.career_type.type === "required" && t('_lp_learning_preferences_profe_vali_m1_')}
                                                        {errors.career_type && errors.career_type.type !== "required" && errors.career_type.message}
                                                    </span>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input {...register("learning_industry", {
                                            required: "required",
                                            pattern: {
                                                value: /^[a-z A-Z]+$/,
                                                message: t('_lp_learning_preferences_profe_vali_m2_')
                                            },
                                        })} type="text" onChange={(event) => {
                                            clearErrors('learning_industry');
                                            setIndustry(event.target.value);
                                            setShowDialog(true);
                                        }} defaultValue={learning_industry ? learning_industry : ''} className="form-control" placeholder={t('_lp_industries_')} />
                                        <span className="text-danger">
                                            {errors.learning_industry && errors.learning_industry.type === "required" && t('_lp_learning_preferences_profe_vali_m3_')}
                                            {errors.learning_industry && errors.learning_industry.type !== "required" && errors.learning_industry.message}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input {...register("learning_area", {
                                            required: "required",
                                            pattern: {
                                                value: /^[a-z A-Z\,]+$/,
                                                message: t('_lp_learning_preferences_profe_vali_m2_')
                                            },
                                        })}
                                            type="text"
                                            onChange={(event) => { clearErrors('learning_area'); setArea(event.target.value); setShowDialog(true); }}
                                            defaultValue={learning_area ? learning_area : ''}
                                            className="form-control"
                                            placeholder={`${t('_lp_area_')} ${career_type === 'Working Professional' ? t('_lp_specialization_') : t('_lp_interest_')}`}
                                        />
                                        <span className="text-danger">
                                            {errors.learning_area && errors.learning_area.type === "required" && `${t('_lp_learning_preferences_profe_vali_m4_')} ${career_type === 'Working Professional' ? t('_lp_specialization_') : t('_lp_interest_')}.`}
                                            {errors.learning_area && errors.learning_area.type !== "required" && errors.learning_area.message}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <Controller
                                            control={control}
                                            name="learn_pref"
                                            rules={{ required: false, message: t('_lp_learning_preferences_profe_vali_m5_') }}
                                            render={({ field: { onChange, onKeyUp, value, name, classNames, ref } }) => (
                                                <ReactTags
                                                    ref={reactTags}
                                                    tags={tags}
                                                    suggestions={suggestions}
                                                    onDelete={onDelete}
                                                    onAddition={onAddition}
                                                    allowNew={true}
                                                    placeholderText={tags.length > 0 ? '' : t('_lp_topics_')}
                                                />
                                            )}
                                        />
                                        <span className="text-danger">
                                            {errors.learn_pref?.type === "required" && t('_lp_learning_preferences_profe_vali_m6_')}
                                            {errors.learn_pref?.type !== "required" && errors.learn_pref?.message}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <Controller
                                            control={control}
                                            name="learning_lang"
                                            rules={{ required: false, message: t('_lp_learning_preferences_profe_vali_m7_') }}
                                            render={({ field: { onChange, value, name, ref } }) => (
                                                <Select
                                                    inputRef={ref}
                                                    classNamePrefix="addl-class"
                                                    options={lang_options}
                                                    value={selected_lang}
                                                    onChange={(val) => {
                                                        onChange(val.map((c) => c.value));
                                                        setSelectedLang(val);
                                                        setShowDialog(true);
                                                    }}
                                                    isMulti
                                                />
                                            )}
                                        />
                                        <span className="text-danger">
                                            {errors.learning_lang?.type === "required" && t('_lp_learning_preferences_profe_vali_m7_')}
                                            {errors.learning_lang?.type !== "required" && errors.learning_lang?.message}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="switch switch-1-1">
                                            <input {...register("course_interest")} type="checkbox" onChange={(e) => {
                                                setCourseInterest(!course_interest);
                                                setShowDialog(true);
                                            }} checked={course_interest ? 'checked' : ''} id="switch-1-1" />
                                            <span className="slider round slider-1-1"></span>
                                        </label>
                                        <label className="" htmlFor="">&nbsp;{t('_lp_check1_')}</label>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="switch switch-1-1">
                                            <input {...register("circle_interest")} type="checkbox" onChange={(e) => {
                                                setCircleInterest(!circle_interest);
                                                setShowDialog(true);
                                            }} checked={circle_interest ? 'checked' : ''} id="switch-1-1" />
                                            <span className="slider round slider-1-1"></span>
                                        </label>
                                        <label className="" htmlFor="">&nbsp;{t('_lp_check2_')}</label>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="switch switch-1-1">
                                            <input {...register("job_interest")} type="checkbox" onChange={(e) => {
                                                setJobInterest(!job_interest);
                                                setShowDialog(true);
                                            }} checked={job_interest ? 'checked' : ''} id="switch-1-1" />
                                            <span className="slider round slider-1-1"></span>
                                        </label>
                                        <label className="" htmlFor="">&nbsp;{t('_lp_check3_')}</label>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className=""><p><strong>{t('_lp_que1_')} </strong></p></div>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input {...register("learning_email", {
                                                required: "required",
                                            })} type="radio" onChange={(event) => { handleChange(event); setShowDialog(true); }} className="form-check-input" name="learning_email" defaultValue={1} checked={learning_email === 1 ? 'checked' : ''} />{t('_lp_yes_')}
                                        </label>
                                    </div>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input {...register("learning_email", {
                                                required: "required",
                                            })} type="radio" onChange={(event) => { handleChange(event); setShowDialog(true); }} className="form-check-input" name="learning_email" defaultValue={0} checked={learning_email === 0 ? 'checked' : ''} />{t('_lp_no_')}
                                        </label>
                                    </div>
                                    <span className="text-danger">
                                        {errors.learning_email && errors.learning_email.type === "required" && t('_lp_learning_preferences_profe_vali_m8_')}
                                        {errors.learning_email && errors.learning_email.type !== "required" && errors.learning_email.message}
                                    </span>
                                </div>
                                <div className="col-sm-12">
                                    <div className="">
                                        <p><strong>{t('_lp_que2_')}</strong></p>
                                    </div>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input {...register("learning_chat", {
                                                required: "required",
                                            })} type="radio" onChange={(event) => { handleChangeChat(event); setShowDialog(true); }} className="form-check-input" name="learning_chat" defaultValue={'private'} checked={learning_chat === 'private' ? 'checked' : ''} />{t('_lp_learning_preferences_profe_vali_m9_')}
                                        </label>
                                    </div>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input {...register("learning_chat", {
                                                required: "required",
                                            })} type="radio" onChange={(event) => { handleChangeChat(event); setShowDialog(true); }} className="form-check-input" name="learning_chat" defaultValue={'public'} checked={learning_chat === 'public' ? 'checked' : ''} />{t('_lp_mes_opt2_')}
                                        </label>
                                    </div>
                                    <span className="text-danger">
                                        {errors.learning_chat && errors.learning_chat.type === "required" && t('_lp_learning_preferences_profe_vali_m8_')}
                                        {errors.learning_chat && errors.learning_chat.type !== "required" && errors.learning_chat.message}
                                    </span>
                                </div>
                                <div className="col-sm-12">
                                    <div className="">
                                        <p><strong>{t('_lp_que3_')}</strong></p>
                                    </div>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input {...register("auto_play")} type="radio" onChange={(event) => { setAutoPlay(event.target.value === 'true'); setShowDialog(true); }} className="form-check-input" name="auto_play" value='true' checked={autoPlay === true} />{t('_lp_yes_')}
                                        </label>
                                    </div>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input {...register("auto_play")} type="radio" onChange={(event) => { setAutoPlay(event.target.value === 'true'); setShowDialog(true); }} className="form-check-input" name="auto_play" value='false' checked={autoPlay === false} />{t('_lp_no_')}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="">
                                        <p><strong> {t('_lp_que4_')}</strong></p>
                                    </div>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input {...register("auto_complete")} type="radio" onChange={(event) => { setAutoComplete(event.target.value === 'true'); setShowDialog(true); }} className="form-check-input" name="auto_complete" value='true' checked={autoComplete === true} />{t('_lp_yes_')}
                                        </label>
                                    </div>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input {...register("auto_complete")} type="radio" onChange={(event) => { setAutoComplete(event.target.value === 'true'); setShowDialog(true); }} className="form-check-input" name="auto_complete" value='false' checked={autoComplete === false} />{t('_lp_no_')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <button disabled={fetching} type="submit" className="btn btn-success btn-circle btn-md"> {fetching ? <i className="fas fa-spinner fa-spin"></i> : <>{t('_save_')}</>}</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LearningPreferences;
