import { Link, useParams, useNavigate } from "react-router-dom";
import { API_BASE } from "../../../constants/global";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import { useState, useEffect } from "react";
import { event } from "jquery";
import VacancyLeaderboard from "./VacancyLeaderboard";
import parse from 'html-react-parser';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const VacancyDetail = () => {
    const { vacancy_id } = useParams();
    const _token = localStorage.getItem('lmstoken');
    const [vacancy, setVacancy] = useState('');
    const [employee, setEmployee] = useState('');
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        fetchVacancy();
    }, []);

    const fetchVacancy = async (page = 0, search = "") => {
        const response = await fetch(`${API_BASE}vacancy/detail/${vacancy_id}?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();

        if (data.status) {
            //console.info(data.data);
            setVacancy(data.data.vacancy);
            setEmployee(data.data.students ? data.data.students : '');
        } else if (data.message === "Server Error") {
            SwalBox.fire({
                title: "Link is invalid",
                text: "It seems that the link is not valid. Please try again.",
                icon: "error",
            });
            setTimeout(() => {
                SwalBox.close();
            }, 3000);
            navigate('/dashboard/vacancy');
        }
        else {
            setVacancy('');
        }
    }
    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_v_vacancy_')}`}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard vacancypage">
                    <div className="custom-learnig-circle-head">
                        <h4>{vacancy?.job_position}</h4>
                        <Link className="btn btn-circle btn-info" to={"/dashboard/vacancy"}>{t('_v_back_to_list_')}</Link>

                    </div>

                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    <div className="explore-block">
                                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="pills-basic-tab" data-toggle="pill" href="#pills-basic" role="tab" aria-controls="pills-basic" aria-selected="true">{t('_v_basic_detail_')} </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="pills-lederboard-tab" data-toggle="pill" href="#pills-lederboard" role="tab" aria-controls="pills-lederboard" aria-selected="true">{t('_v_leaderboard_')}</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-basic" role="tabpanel" aria-labelledby="pills-basic-tab">
                                                <div className="course-tab-contents">
                                                    <div className="course-tabs-pane-block px-2 py-3">
                                                        <div className="course-tabs-body">
                                                            {
                                                                vacancy !== '' && vacancy !== null && typeof vacancy !== 'undefined'
                                                                &&
                                                                <>
                                                                    <div className="text-right">
                                                                        <Link className="btn btn-circle btn-warning pull-right" to={`/dashboard/vacancy/edit/${vacancy.vacancy_id}`}>{t('_v_update_vacancy_')}</Link>
                                                                    </div>
                                                                    <div className="course-accordion-section">
                                                                        <ul className="about-course-list">
                                                                            <li><div><strong>{t('_v_job_position_')} </strong><span>{vacancy?.job_position}</span></div></li>
                                                                            <li><div><strong>{t('_v_course_prerequisite_')} </strong><span>{vacancy?.course_title}</span></div></li>
                                                                            <li><div><strong>{t('_v_experience_')} </strong><span>{vacancy?.experience}</span></div></li>
                                                                        </ul>
                                                                        {
                                                                            vacancy?.description
                                                                                ?
                                                                                <p>
                                                                                    <strong>{t('_v_full_job_')} </strong><br />
                                                                                    <span className="vacancy-details-li-set" dangerouslySetInnerHTML={{ __html: vacancy?.description }}></span>
                                                                                </p>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="pills-lederboard" role="tabpanel" aria-labelledby="pills-lederboard-tab">
                                                <div className="course-tab-contents">
                                                    <div className="course-tabs-pane-block px-2 py-3">
                                                        <div className="course-tabs-body">
                                                            <VacancyLeaderboard ekCourseTitle={vacancy?.course_title} ekCourseSlug={vacancy?.course_id} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VacancyDetail;
