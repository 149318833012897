import { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { API_BASE, FRONT_BASE } from "../../../constants/global";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import Materials from "./manage/Materials";
import QA from "./manage/QA";
import Sessions from "./manage/Sessions";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const ManageCourse = () => {
    const _token = localStorage.getItem('lmstoken');
    const { course_id } = useParams();
    const [active_tab, setActiveTab] = useState(1);
    const [course, setCourse] = useState('');
    const [rating, setRating] = useState('');
    const [feedbacks, setFeedbacks] = useState('');
    const [gradingEnable, setGradingEnable] = useState(false);
    const navigate = useNavigate();

    const [studentShow, setStudentShow] = useState(false);
    const [corpempShow, setCorpempShow] = useState(false);
    const [existingShow, setExistingShow] = useState(false);
    const [viaEmailShow, setViaEmailShow] = useState(false);
    const [viaLinkShow, setViaLinkShow] = useState(false);

    let ek_location = useLocation();
    const queryParams = new URLSearchParams(ek_location.search);
    const currTabName = queryParams.get('tab');

    const [current_usertype, setCurrentUserType] = useState('');
    const { t } = useTranslation();

    const toggleLeftMenu = async () => {
        try {
            let sidebar = document.querySelector(".sidebar");
            sidebar.classList.add("close");
        } catch (e) { }
    }

    useEffect(() => {
        toggleLeftMenu();
        fetchCourse();
        fetchRating();
        fetchFeedbacks();
        setTimeout(() => {
            if (currTabName === "Ques") {
                document.getElementById('pills-qanda-tab').click();
                document.getElementById('pills-qanda-tab').scrollIntoView();
            }
        }, 1000)
        // eslint-disable-next-line
    }, []);

    const fetchCourse = async () => {
        const response = await fetch(`${API_BASE}get-course-form-data-stepOne/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            let _current_lmsuser = JSON.parse(localStorage.getItem('lrsuser'));
            setCurrentUserType(_current_lmsuser.is_corporate);
            setCourse(data.data);
            setGradingEnable(data?.data?.is_gradable);
        } else {
            navigate('/dashboard/tutor/published-courses');
            Toaster.error(data.message);
        }
    }

    const fetchRating = async () => {
        const response = await fetch(`${API_BASE}get-average-course-ratings/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            if (data.data.average_ratings !== null) {
                setRating(data.data.average_ratings[0]);
            }
        }
    }

    const fetchFeedbacks = async () => {
        const response = await fetch(`${API_BASE}get-average-course-feedback/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setFeedbacks(data.data.average_feedback);
        }
    }

    const ConfirmGradeChange = (e) => {
        var gradingEnable = e.target.checked;

        var textT = gradingEnable ? t('_managecour_msg_m1_') : t('_managecour_msg_m2_');

        SwalBox.fire({
            title: t('_v_delete_confirm_'),
            text: textT,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_cancel_')
        }).then((result) => {
            if (result.isConfirmed) {
                ChangeGradable(gradingEnable);
                setGradingEnable(gradingEnable);
            }
        });
    }

    const ChangeGradable = async (IsTutorServiceEnable) => {
        const response = await fetch(`${API_BASE}post-set-gradable/${course_id}/${IsTutorServiceEnable}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
        } else {
            Toaster.error(data.message);
        }
    }

    const showCourseEditWarning = () => {
        SwalBox.fire({
            title: t('_managecour_msg_m3_'),
            text: t('_managecour_msg_m4_'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            cancelButtonColor: '#d33',
            cancelButtonClass: 'button_cancel_class',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_lp_no_')
        }).then((result) => {
            if (result.isConfirmed) {
                setCourseInEditMode(course_id);
            } else {

            }
        });
    }

    const setCourseInEditMode = async (id) => {
        const response = await fetch(`${API_BASE}change-publish-status/${id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status && data.data.changed) {
            navigate(`/dashboard/tutor/edit-course/${id}`);
        }
    }




    const ConfirmUnpublish = (id) => {
        SwalBox.fire({
            title: t('_v_delete_confirm_'),
            text: t('_couseiteach_del_alert_m2_'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_lp_no_')
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUnpublish(id);
            }
        });
    }

    const deleteUnpublish = async (id) => {
        const response = await fetch(`${API_BASE}change-publish-status/${id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();


        if (data.status && data.data.changed) {

        }
    }

    const ConfirmPrivacy = (id) => {
        SwalBox.fire({
            title: t('_v_delete_confirm_'),
            text: t('_managecour_msg_m5_'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('_lp_yes_'),
            cancelButtonText: t('_lp_no_')
        }).then((result) => {
            if (result.isConfirmed) {
                changePrivacy(id);
            }
        });
    }

    const changePrivacy = async (id) => {
        const response = await fetch(`${API_BASE}change-public-status/${id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();


        if (data.status && data.data.changed) {
            Toaster.success(data.message);
            setCourse(data.data.course);
        }
    }





    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_roles_manage_courses_')}`}</title>
            </Helmet>
            {
                course !== ''
                &&
                <div className="col-lg-9 col-lg-9 home-section">
                    <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                        <div className="custom-learnig-circle-head top_butn_issue">
                            <h4>
                                {course.title}
                                {
                                    rating !== ''
                                    &&
                                    <>
                                        <a className="ratingview cursor-pointer" data-toggle="modal" data-target="#ratingviewModalCenter"><i className="far fa-star"></i> {rating.average} {t('_explorelc_rating_')}</a>
                                        &nbsp;
                                    </>
                                }
                            </h4>
                            <h4 className="ManaCourwid">
                                <a className="ekellipsinnerbtn" id={`MaterialsMenuButton${course.id}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby={`MaterialsMenuButton${course.id}`}>
                                    <a onClick={(e) => { ConfirmUnpublish(course.id); }} className="dropdown-item ekdropdown-item">{t('_couseiteach_card_opt1_')}</a>
                                    <a onClick={(e) => { ConfirmPrivacy(course.id); }} className="dropdown-item ekdropdown-item">{t('_couseiteach_card_opt2_')} {(course.course_privacy === 'Public') ? t('_lcform_privacy_opt3_') : t('_lcform_privacy_opt2_')}</a>
                                </div>
                            </h4>
                            <h6>
                                {t('_managecour_msg_m6_')}
                                <label className="switch switch-1-1">
                                    <input type="checkbox" onChange={(e) => { ConfirmGradeChange(e) }} checked={gradingEnable ? 'checked' : ''} name="switch-1-1" id="switch-1-1" />
                                    <span className="slider round slider-1-1"></span>
                                </label>
                                {gradingEnable ? t('_r_enabled_') : t('_r_disabled_')}
                            </h6>
                            <div className="right_btn_part">
                                <button className="ellipsbtn btn btn-circle btn-md submit-pink-btn pl-3 pr-3" onClick={() => { setStudentShow(true); }}>{t('_managecour_msg_m7_')}</button>
                                <button className="ellipsbtn btn btn-circle btn-md submit-pink-btn pl-3 pr-3" id={`MaterialsMenuButton`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">+ {t('_managelc_invite_')}</button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MaterialsMenuButton">
                                    <div className="buttons_action">
                                        {
                                            current_usertype == 1
                                            &&
                                            <a onClick={() => { setCorpempShow(true); }} className="dropdown-item cursor-pointer"><strong><i className="fas fa-user"></i> {t('_emplist_inviteemps_')}</strong></a>
                                        }
                                        <a onClick={() => { setExistingShow(true); }} className="dropdown-item cursor-pointer"><strong><i className="fas fa-user"></i> {t('_managelc_invite_user_')}</strong></a>
                                        <a onClick={() => { setViaEmailShow(true); }} className="dropdown-item cursor-pointer"><strong><i className="fas fa-envelope"></i> {t('_v_invite_email_')}</strong></a>
                                        {
                                            course.course_privacy === 'Public'
                                            &&
                                            <a onClick={() => { setViaLinkShow(true); }} className="dropdown-item cursor-pointer"><strong><i className="fas fa-link"></i> {t('_managelc_invite_via_link_')}</strong></a>
                                        }
                                    </div>
                                </div>
                                <Link className="btn btn-circle btn-md submit-pink-btn pl-3 pr-3 back_btn_pos" to={'/dashboard/tutor/published-courses'}><i className="fa fa-arrow-left" aria-hidden="true"></i></Link>
                                <a className="btn btn-circle btn-md submit-pink-btn pl-3 pr-3" onClick={() => { showCourseEditWarning(); }}><i className="fa fa-edit" aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <div className="modal fade ratingviewpop" id="ratingviewModalCenter" tabIndex="-1" role="dialog" aria-labelledby="ratingviewModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title" id="ratingviewModalLongTitle">{t('_managelc_currernt_rating_')}</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="rating_pop_mai">
                                            <h4>{rating.average} {t('_explorelc_rating_')}</h4>
                                            {
                                                feedbacks !== '' && feedbacks !== null
                                                &&
                                                <div className="feedback_detailed-view">
                                                    <ul className="list-feedbacks">
                                                        {
                                                            feedbacks.map((feedback, indexx) => {
                                                                return (
                                                                    <li key={indexx}>
                                                                        <span>{feedback.question}</span>
                                                                        <div className="feedback-percent">
                                                                            <span style={{ width: feedback.feedback.Yes + '%' }} className="yes">{feedback.feedback.Yes}%</span>
                                                                            <span style={{ width: feedback.feedback.No + '%' }} className="no">{feedback.feedback.No}%</span>
                                                                            <span style={{ width: feedback.feedback.Not_Sure + '%' }} className="not_sure">{feedback.feedback.Not_Sure}%</span>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </ul>
                                                    <ul className="percent-courns">
                                                        <li className="one">0</li>
                                                        <li className="two">20</li>
                                                        <li className="three">40</li>
                                                        <li className="four">60</li>
                                                        <li className="five">80</li>
                                                        <li className="six">100</li>
                                                    </ul>
                                                    <div className="template_names">
                                                        <span className="yes">{t('_lp_yes_')}</span>
                                                        <span className="no">{t('_lp_no_')}</span>
                                                        <span className="not_sure">{t('_managelc_not_sure_')}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="learning-circle-courses">
                            <section>
                                <div className="explore-section">
                                    <div className="container">
                                        <div className="explore-block">
                                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                <li className="nav-item">
                                                    <a onClick={(e) => { setActiveTab(1); }} className={"nav-link" + (active_tab === 1 ? ' active' : '')} id="pills-Materials-tab" data-toggle="pill" href="#pills-Materials" role="tab" aria-controls="pills-Materials" aria-selected="true">{t('_managelc_materials_')} </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a onClick={(e) => { setActiveTab(2); }} className={"nav-link" + (active_tab === 2 ? ' active' : '')} id="pills-qanda-tab" data-toggle="pill" href="#pills-qanda" role="tab" aria-controls="pills-qanda" aria-selected="true">{t('_learncourse_detail_qa_')} </a>
                                                </li>
                                                {
                                                    course.mode_of_learning !== 'Self Paced'
                                                    &&
                                                    <li className="nav-item">
                                                        <a onClick={(e) => { setActiveTab(3); }} className={"nav-link" + (active_tab === 3 ? ' active' : '')} id="pills-live-tab" data-toggle="pill" href="#pills-live" role="tab" aria-controls="pills-live" aria-selected="true">{t('_learncourse_detail_livesessions_')}</a>
                                                    </li>
                                                }
                                            </ul>
                                            <div className="tab-content" id="pills-tabContent">
                                                {
                                                    active_tab === 1
                                                    &&
                                                    <div className="tab-pane fade show active" id="pills-Materials" role="tabpanel" aria-labelledby="pills-Materials-tab">
                                                        <div className="blog-card">
                                                            <Materials course_id={course_id} is_edit={true} />
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    active_tab === 2
                                                    &&
                                                    <QA course_id={course_id} manage={true} />
                                                }

                                                {
                                                    (active_tab === 3 && course.mode_of_learning !== 'Self Paced')
                                                    &&
                                                    <Sessions course_id={course_id} learning_mode={course.mode_of_learning} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <CourseStudents show={studentShow} onHide={() => setStudentShow(false)} course_id={course_id} />
                            <InviteCorpemp show={corpempShow} onHide={() => setCorpempShow(false)} course_id={course_id} />
                            <InviteExistingUser show={existingShow} onHide={() => setExistingShow(false)} course_id={course_id} />
                            <InviteViaEmail show={viaEmailShow} onHide={() => setViaEmailShow(false)} course_id={course_id} />
                            <InviteViaLink show={viaLinkShow} onHide={() => setViaLinkShow(false)} course_id={course.slug} />
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default ManageCourse;

const CourseStudents = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const [students, setStudents] = useState([]);
    const [all, setAll] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [processing, setProcessing] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setPage(1 + 1);
        fetchStudents();
        // eslint-disable-next-line
    }, []);

    const fetchStudents = async (search = '') => {
        setProcessing(true);
        const response = await fetch(`${API_BASE}course-students/${props.course_id}?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setStudents(data.data.students);
            setCount(data.data.all_counts);
            if (data.data.total === 1) {
                setAll(true);
            }
        } else {
            setStudents([]);
        }

        setProcessing(false);
    }

    const showMore = async () => {
        setPage(page + 1);
        fetchStudents();
    }

    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <h4>{t('_managecour_courstudent_m1_')}</h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body className="scroll_add">
                <section className="custom-section member_section mb-0">
                    <div className="profile_user_middle_section">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="custom-user-profile-content p-0">
                                    <div className="custom-learnig-circle-head">
                                        <h4 className="text-left">{t('_coursedetail_cour_enrolled_')} {<>- {`(${count} ${t('_lcdetail_std_')})`}</>}</h4>
                                        <p>{t('_managecour_courstudent_m2_')}</p>
                                    </div>
                                    <div className="member_list border0">
                                        <div className="form-group">
                                            <i className="fas fa-search"></i><input onChange={(e) => { fetchStudents(e.target.value); }} type="text" className="form-control" id="search_user_control" placeholder={t('_managecour_courstudent_m3_')} />
                                        </div>
                                        <ul>
                                            {
                                                (typeof students !== 'undefined' && students.length > 0)
                                                &&
                                                <>
                                                    {
                                                        students.map((student, index) =>
                                                            <li key={index}>
                                                                <div className="list_img">
                                                                    <div className="img_box">
                                                                        <img src={student.image} alt="" />
                                                                    </div>
                                                                    <h5>
                                                                        <Link to={`/profile-details/${student.slug}`}>{student.name}</Link>
                                                                        <span className="headline">{student?.exp}</span>
                                                                    </h5>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </>
                                            }

                                            {
                                                (typeof students === 'undefined' || students.length <= 0)
                                                &&
                                                <li>
                                                    <div className="list_img">
                                                        <h5 className="text-center">{t('_v_no_student_')} </h5>
                                                    </div>
                                                </li>
                                            }
                                        </ul>
                                        {
                                            all === false
                                            &&
                                            <div className="join-invite-more">
                                                <button onClick={(e) => { showMore() }} className="show-more-btn btn-pink">{t('_mylc_showmore_')} </button>
                                            </div>
                                        }
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    );
}

const InviteCorpemp = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const [emps, setEmps] = useState([]);
    const { register, handleSubmit, setValue } = useForm();
    const [processing, setProcessing] = useState(false);
    const [checkedemps, setCheckedEmps] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        fetchEmployees('');
    }, [])
    const fetchEmployees = async (search) => {
        setProcessing(true);

        const response = await fetch(`${API_BASE}employee/get?page=0&limit=4000&search=` + search, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setEmps(data.data.employees);
        } else {
            setEmps([]);
        }

        setProcessing(false);
    }
    const SearchEmp = (e) => {
        const search = e.target.value;
        //if (search.length > 0 && search !== '') {
        fetchEmployees(search);
        //}
    }

    const checkAllCheckBoxes = () => {
        var elements = document.querySelectorAll('[id^="checkboxes"]');
        elements.forEach(e => e.click());
    }

    const onCorpempSubmit = async (form) => {
        setProcessing(true);
        if (form.existing_emps === false || form.existing_emps.length <= 0) {
            Toaster.error("Please select any user to invite.");
            setProcessing(false);
            return false;
        }
        const formData = new FormData();
        formData.append('existing_users', form.existing_emps);
        var sURL = `${API_BASE}invite-existing-user-to-course/${props.course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });
        const rdata = await response.json();
        if (rdata.status) {
            setValue('existing_emps', '');
            document.getElementById('search_user_control').value = '';
            //setEmps([]);
            Toaster.success(rdata.message);
            props.onHide();
        } else {
            Toaster.error(rdata.message);
        }
        setProcessing(false);

    }


    const isCheckedEmp = (id) => {
        let _existing_chekced = checkedemps;
        let uniqueArray = [...new Set(_existing_chekced)];
        const exists = uniqueArray.includes(id);
        if (exists == true) {
            return true;
        } else {
            return false;
        }
    }
    const toggleCurrentCheckbox = (id) => {
        let _existing_chekced = checkedemps;
        let uniqueArray = [...new Set(_existing_chekced)];
        var currentCheckbox = document.getElementById('checkboxes' + id);
        if (currentCheckbox.checked) {
            uniqueArray.push(parseInt(id));
            setCheckedEmps(uniqueArray);
        } else {
            const newArray = uniqueArray.filter((item) => item !== parseInt(id));
            setCheckedEmps(newArray);
        }
        isCheckedEmp(id);
    }

    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <h4>{t('_emplist_inviteemps_')} </h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body className="scroll_add">
                <div id="invite_user" className="invitepop">
                    <div className="form-group">
                        <i className="fas fa-search"></i><input onChange={(e) => { SearchEmp(e); }} type="text" className="form-control" id="search_user_control" placeholder={t('_managelc_invite_model_search_')} />
                    </div>
                    <div className="form-group">


                    </div>

                    <form onSubmit={handleSubmit(onCorpempSubmit)}>
                        <div className="member_list invitemax_scroll">
                            <ul>
                                <li><button type="button" className="btn ml-2 mb-2 btn_right p-20 invt_corp_emp" onClick={() => checkAllCheckBoxes()} ><strong>{t('_managelc_select_all_')}</strong>&nbsp;&nbsp;&nbsp;</button> </li>
                                {
                                    (typeof emps !== 'undefined' && emps.length > 0)
                                    &&
                                    <>
                                        {
                                            emps.map((user, index) =>
                                                <li key={index}>
                                                    <div className="list_img">
                                                        <div className="img_box">
                                                            <img src={user.image} alt="" />
                                                        </div>
                                                        <h5>{user.full_name}
                                                            {
                                                                user?.headline
                                                                &&
                                                                <span>{user?.headline}</span>
                                                            }
                                                        </h5>

                                                        <input
                                                            onClick={() => toggleCurrentCheckbox(user.ref)}
                                                            {...register("existing_emps[]")}
                                                            id={`checkboxes${user.ref}`}
                                                            className="invt_corp_emp_cbx"
                                                            type="checkbox"
                                                            value={user.ref}
                                                            checked={isCheckedEmp(user.ref)}
                                                        />
                                                    </div>
                                                </li>
                                            )
                                        }
                                    </>
                                }
                                {
                                    (typeof emps === 'undefined' || emps.length <= 0)
                                    &&
                                    <>
                                        <li>
                                            <div className="list_img text-center">
                                                <h5>{t('_managelc_nouser_found_')}</h5>
                                            </div>
                                        </li>
                                    </>
                                }
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                        <div className="btn_right pad0">
                            <button onClick={props.onHide} type="button" className="btn btn-circle btn-md cancel-btn">{t('_cancel_')} </button>
                            {
                                typeof emps !== 'undefined' && emps.length > 0
                                &&
                                <button className="btn btn-circle btn-md submit-pink-btn">
                                    {
                                        processing
                                        &&
                                        <i className="fa fa-refresh fa-spin fa-fw"></i>
                                    }
                                    {
                                        !processing
                                        &&
                                        t('_managelc_send_invitations_')
                                    }
                                </button>
                            }
                        </div>
                    </form>
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
        </Modal>
    );

}
const InviteExistingUser = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const [users, setUsers] = useState([]);
    const { register, handleSubmit, setValue } = useForm();
    const [processing, setProcessing] = useState(false);
    const { t } = useTranslation();

    const fetchUsers = async (search) => {
        setProcessing(true);
        const response = await fetch(`${API_BASE}search-user/${search}/${props.course_id}/true`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setUsers(data.data.users);
        } else {
            setUsers([]);
        }

        setProcessing(false);
    }

    const SearchUser = (e) => {
        const search = e.target.value;

        if (search.length > 0 && search !== '') {
            fetchUsers(search);
        }
    }

    const onSubmit = async (form) => {
        setProcessing(true);

        if (form.existing_users === false || form.existing_users.length <= 0) {
            Toaster.error("Please select any user to invite.");
            setProcessing(false);
            return false;
        }

        const formData = new FormData();
        formData.append('existing_users', form.existing_users);
        var sURL = `${API_BASE}invite-existing-user-to-course/${props.course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            setValue('existing_users', '');
            document.getElementById('search_user_control').value = '';
            setUsers([]);
            Toaster.success(rdata.message);
            props.onHide();
        } else {
            Toaster.error(rdata.message);
        }

        setProcessing(false);
    }

    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <h4>{t('_managelc_invite_user_')}</h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body className="scroll_add">
                <div id="invite_user" className="invitepop">
                    <div className="form-group">
                        <i className="fas fa-search"></i><input onChange={(e) => { SearchUser(e); }} type="text" className="form-control" id="search_user_control" placeholder={t('_managelc_invite_model_search_')} />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="member_list invitemax_scroll">
                            <ul>
                                {
                                    (typeof users !== 'undefined' && users.length > 0)
                                    &&
                                    <>
                                        {
                                            users.map((user, index) =>
                                                <li key={index}>
                                                    <div className="list_img">
                                                        <div className="img_box">
                                                            <img src={user.image} alt="" />
                                                        </div>
                                                        <h5>{user.name}
                                                            {
                                                                user?.headline
                                                                &&
                                                                <span>{user?.headline}</span>
                                                            }
                                                        </h5>
                                                        <input {...register("existing_users[]")} type="checkbox" value={user.id} />
                                                    </div>
                                                </li>
                                            )
                                        }
                                    </>
                                }
                                {
                                    (typeof users === 'undefined' || users.length <= 0)
                                    &&
                                    <>
                                        <li>
                                            <div className="list_img text-center">
                                                <h5>{t('_managelc_nouser_found_')}.</h5>
                                            </div>
                                        </li>
                                    </>
                                }
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                        <div className="btn_right pad0">
                            <button onClick={props.onHide} type="button" className="btn btn-circle btn-md cancel-btn">{t('_cancel_')}</button>
                            {
                                typeof users !== 'undefined' && users.length > 0
                                &&
                                <button className="btn btn-circle btn-md submit-pink-btn">
                                    {
                                        processing
                                        &&
                                        <i className="fa fa-refresh fa-spin fa-fw"></i>
                                    }
                                    {
                                        !processing
                                        &&
                                        t('_managelc_send_invitations_')
                                    }
                                </button>
                            }
                        </div>
                    </form>
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const InviteViaEmail = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const { register, handleSubmit, formState: { errors }, setValue, setError, setFocus, clearErrors } = useForm();
    const [emails, setEmails] = useState([]);
    const [processing, setProcessing] = useState(false);
    const EmailField = useRef();
    const [isPasted, setIsPasted] = useState(false);
    const { t } = useTranslation();

    const onSubmit = async (data) => {
        if (isPasted === true) {
            clearErrors('email')
        }

        console.log(data, "Data array")
        if (emails.length >= 30) {
            setError("email", { type: "focus", message: t('_managelc_invite_email_vali_m31_') }, { shouldFocus: false });
            return false;
        }

        setProcessing(true);
        //var iEmail = data.email;
        var _email_string = data.email;
        var _remove_all_spaces = _email_string.replace(/\s\s+/g, ' ');
        var _convert_space_in_coma = _remove_all_spaces.split(/[ ,]+/).join(',');
        var iEmail = _convert_space_in_coma.trim();
        var comEmails = iEmail.split(",");

        if (typeof comEmails !== 'undefined' && comEmails.length > 1) {
            var duplicate = false;
            var checkEmails = [];
            for (let index = 0; index < comEmails.length; index++) {
                const sepEmail = comEmails[index].trim();
                if (emails.indexOf(sepEmail) === -1) {
                    checkEmails.push(sepEmail);
                } else {
                    var duplicate = true;
                }
            }

            //checkEmails = checkEmails.join(",");
            const checkAPI = async () => {
                var allowedEmails = [];
                for (let index = 0; index < checkEmails.length; index++) {
                    const usepEmail = checkEmails[index].trim();
                    const formData = new FormData();
                    formData.append('email', usepEmail);
                    var sURL = `${API_BASE}check-email-course/${props.course_id}`;
                    const response = await fetch(sURL, {
                        method: 'POST',
                        headers: {
                            "Authorization": `Bearer ${_token}`
                        },
                        body: formData
                    });
                    const rdata = await response.json();
                    if (rdata.status && rdata.data.exists === true) {

                    } else {
                        allowedEmails.push(usepEmail);
                    }
                }
                return allowedEmails;
            }
            const callAsync = async () => {
                const newEmails = await checkAPI();
                //const newList = [...emails, ...checkEmails];
                const newList = newEmails;
                setEmails(newList.splice(30));
                setEmails(newList);
                setProcessing(false);
            }
            callAsync();
            if (duplicate) {
                Toaster.info(t('_managelc_invite_email_vali_m32_'));
            }
            setValue('email', '');
            setFocus('email');

        } else {
            if (emails.indexOf(iEmail) !== -1) {
                setError("email", { type: "focus", message: t('_managelc_invite_email_vali_m33_') }, { shouldFocus: true });
                setProcessing(false);
                return false;
            }
            const formData = new FormData();
            formData.append('email', iEmail);
            var sURL = `${API_BASE}check-email-course/${props.course_id}`;
            const response = await fetch(sURL, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData
            });
            const rdata = await response.json();
            if (rdata.status && rdata.data.exists === true) {
                setError("email", { type: "focus", message: t('_managecour_inviteviaemail_m1_') }, { shouldFocus: true });
                setProcessing(false);
                return false;
            }
            setEmails([...emails, data.email]);
            setValue('email', '');
            setFocus('email');
            setProcessing(false);
        }
    }

    const sendInvitation = async () => {
        const message = document.getElementById('invite_message_box').value;
        var formData = new FormData();
        for (var i = 0; i < emails.length; i++) {
            formData.append('members[]', emails[i]);
        }
        formData.append('message', message);

        var sURL = `${API_BASE}invite-to-course/${props.course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            Toaster.success(rdata.message);
            setEmails([]);
            setValue('email', '');
        } else {
            Toaster.error(rdata.message);
        }

        props.onHide();
    }

    const removeItem = async (e, item) => {
        const newEmails = [];

        emails.forEach((email, index) => {
            if (item !== email) {
                newEmails.push(email);
            }
        });

        setEmails(newEmails);
    }

    const handleEmailkeypress = (e) => {
        if (e.key === ' ' || e.key === ",") {
            handleSubmit(onSubmit)();
        }
    }

    // const EmailField = document.getElementById("EmailField");

    const handleEmailPaste = (e) => {
        setIsPasted(true);
        clearErrors("email");
        e.preventDefault();
        // console.log(e.clipboardData.getData('Text'));
        var _email_string = e.clipboardData.getData('Text');
        var _remove_all_spaces = _email_string.replace(/\s\s+/g, ' ');
        var _convert_space_in_coma = _remove_all_spaces.split(/[ ,]+/).join(',');
        var iEmail = _convert_space_in_coma.trim();
        var comEmails = iEmail.split(",");

        // console.log(comEmails);

        // const emailArray = comEmails.split(/[,\s]+/);

        // // Filter the array to keep only valid email addresses
        // const validEmailArray = comEmails.filter((email)
        //     => { /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(email) });

        // console.log(validEmailArray);

        // const validEmailArray = emailArray.filter((email) => {
        //     const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        //     return emailRegex.test(email);
        // });

        // console.log(validEmailArray);

        function isValidEmail(email) {
            // Regular expression for email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        }

        function filterValidEmails(emails) {
            return emails.filter(isValidEmail);
        }

        const validEmails = filterValidEmails(comEmails);

        // console.log(validEmails, "This is valid");

        // EmailField.current.clearValue();
        EmailField.current.value = validEmails;
        // console.log(EmailField.current.value);
        setEmails(validEmails);
        setValue("email", EmailField.current.value);

        // EmailField.value = validEmails;
        // handleSubmit(onSubmit)();
        // EmailField.current.clearValue();
    }

    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <h4>{t('_managelc_invite_via_email_')} </h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body>
                <div id="invite_email" className="invitepop">
                    <strong>{t('_managecour_inviteviaemail_m2_')} </strong>
                    <p className="text-label-invite">{t('_v_invite_msg_')} </p>
                    <textarea rows="4" className="form-control" id="invite_message_box" placeholder={t('_managelc_email_invitation_msg_place_')} defaultValue={t('_managecour_inviteviaemail_m3_')}></textarea>
                    <br />

                    <>
                        <strong>{t('_v_invite_add_emails_')} </strong>
                        <p>{t('_v_invite_limit_')}</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="email_box">
                                <input {...register("email", {
                                    required: "required",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: t('_hr_email_error_msg_')
                                    }
                                })} type="text" placeholder={t('_hr_email_placeholder_')}
                                    onKeyPress={handleEmailkeypress}
                                    onPaste={handleEmailPaste}
                                    id="EmailField"
                                    ref={EmailField}


                                />
                                {/* <button className="btn btn-circle btn-info ml-2">
                                    {
                                        processing
                                        &&
                                        <i className="fa fa-refresh fa-spin fa-fw"></i>
                                    }
                                    {
                                        !processing
                                        &&
                                        "Add"
                                    }
                                </button> */}
                            </div>
                            <span className="text-danger">
                                {errors.email && errors.email.type === "required" && t('_managelc_invite_email_vali_m1_')}
                                {errors.email && errors.email.type !== "required" && errors.email.message}
                            </span>
                        </form>
                    </>

                    {
                        emails.length > 0
                        &&
                        <>
                            <hr />
                            <ul className="list-add-email-invite">
                                {
                                    emails.map((email, index) =>
                                        <li key={index}>
                                            <span>{email}</span>
                                            <i onClick={(e) => { removeItem(e, email) }} className="fa fa-times text-danger float-right mt-1" title="Close" aria-hidden="true"></i>
                                        </li>
                                    )
                                }
                            </ul>
                        </>
                    }


                    <div className="btn_right">
                        <button onClick={props.onHide} type="button" className="btn btn-circle btn-md cancel-btn">{t('_cancel_')} </button>
                        {
                            emails.length > 0
                            &&
                            <input className="btn btn-circle btn-md submit-pink-btn" onClick={(e) => { sendInvitation() }} disabled={processing} type="submit" value="Send" />
                        }
                    </div>
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const InviteViaLink = (props) => {
    const { t } = useTranslation();
    const copyToClipboard = () => {
        navigator.clipboard.writeText(`${FRONT_BASE}course-details/${props.course_id}`);
        Toaster.success(t('_managecour_invitevialink_m1_'));
    }

    return (
        <Modal
            className="invitePopup header_red"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <h4>{t('_managelc_invite_via_link_')} </h4>
                <button type="button" className="close" onClick={props.onHide}><span aria-hidden="true">×</span></button>
                {/* <i onClick={props.onHide} className="fa fa-times text-danger" title="Close" aria-hidden="true"></i> */}
            </Modal.Header>
            <Modal.Body>
                <div id="invite_link" className="invitepop">
                    <strong>{`${FRONT_BASE}course-details/${props.course_id}`}</strong>
                    <br />
                    <div className="btn_right">
                        <button className="btn btn-circle btn-md submit-pink-btn" onClick={() => { copyToClipboard(); props.onHide(); }} type="button">{t('_managelc_copy_link_')} </button>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
