import { Controller, useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import { useEffect, useCallback, useRef, useState } from "react";
import ReactTags from 'react-tag-autocomplete';
import { SwalBox, Toaster } from "./../../entity/GlobalJS";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt.ts";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const MentorPreferences = () => {
    const navigate = useNavigate();
    const _token = localStorage.getItem('lmstoken');
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const { register, control, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();
    const [mentor_email, setCourseTopicEmail] = useState(1);
    const [mentor_why, setWhy] = useState('');
    const [mentor_topic, setTopic] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [selected_lang, setSelectedLang] = useState([]);
    const [lang_options, setLangOptions] = useState([]);
    const [tags, setTags] = useState([]);

    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation] = useCallbackPrompt(showDialog);
    const { t } = useTranslation();
    const reactTags = useRef();
    const onDelete = useCallback((tagIndex) => {
        setTags(tags.filter((_, i) => i !== tagIndex));
        setShowDialog(true);
    }, [tags])

    const onAddition = useCallback((newTag) => {
        setTags([...tags, newTag]);
        setShowDialog(true);
    }, [tags])


    useEffect(() => {
        fetchCategories();
        fetchMentorPreferences();
        fetchPreferencesSuggestions();
        if (user?.is_corporate === 1 || user?.is_tutor !== 1) {
            navigate('/profile/general');
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (showPrompt) {
            ConfirmSaveChanges();
        }
    }, [showPrompt]);

    const ConfirmSaveChanges = () => {
        SwalBox.fire({
            title: t('_confirm_save_title_'),
            text: t('_confirm_save_msg_'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            cancelButtonColor: '#d33',
            cancelButtonClass: 'button_cancel_class',
            confirmButtonText: t('_save_'),
            cancelButtonText: t('_cancel_')
        }).then((result) => {
            if (result.isConfirmed) {
                handleSubmit(saveMentorPreference)();
                confirmNavigation();
            } else {
                confirmNavigation();
            }
        });
    }

    const fetchMentorPreferences = async () => {
        const response = await fetch(`${API_BASE}get-mentor-preferences`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            var mentorPref = data.data.mentor_preference;
            setCourseTopicEmail(mentorPref.email);
            setValue('mentor_lang', mentorPref.lang);
            setValue('mentor_why', mentorPref.why);
            let tags = [];
            mentorPref.topic.forEach((element, index, array) => {
                tags[index] = { id: index, name: element };
            })
            setTags(tags)

            let stringLang = [];
            mentorPref.lang.forEach(function (value, i) {
                stringLang.push({ value: value, label: value });
            });
            setSelectedLang(stringLang)
        } else {
            Toaster.error(data.message);
        }
    }

    const fetchCategories = async () => {
        const response = await fetch(`${API_BASE}get-course-languages`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            var stringJ = [];
            data.data.languages.forEach(function (value, i) {
                stringJ.push({ value: value.name, label: value.name });
            });
            setLangOptions(stringJ);
        }
    }

    const fetchPreferencesSuggestions = async () => {
        let url = '';
        url = `${API_BASE}get-preferences-suggestions`

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            setSuggestions(data.data.suggestions);
        }
    }

    const saveMentorPreference = async (form) => {
        const newTags = tags.map((tag, inx) => tag.name);
        const newForm = { ...form, 'mentor_topic': newTags };

        const response = await fetch(`${API_BASE}post-mentor-preferences`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(newForm)
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            setShowDialog(false);
        } else {
            Toaster.error(data.message);
        }
    }

    const handleChange = event => {
        var radioSelect = event.target.value == 1 ? 1 : 0
        setCourseTopicEmail(radioSelect);
        setShowDialog(true);
    };

    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_mp_mentor_preference_')}`}</title>
            </Helmet>
            <div className="tab-pane fade show active" id="v-pills-mentor" role="tabpanel" aria-labelledby="v-pills-mentor-tab">
                <div className="profile-dashboard-content">
                    <h4>{t('_mp_mentor_preference_')} </h4>
                    <div className="profile-dashboard-box">
                        <form onSubmit={handleSubmit(saveMentorPreference)}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="">
                                        <p>{t('_mp_email_')}  </p>
                                    </div>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input {...register("mentor_email", {
                                                required: "required",
                                            })} type="radio" onChange={(event) => { clearErrors('mentor_email'); handleChange(event) }} className="form-check-input" name="mentor_email" defaultValue={1} checked={mentor_email === 1 ? 'checked' : ''} />{t('_lp_yes_')}
                                        </label>
                                    </div>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input {...register("mentor_email", {
                                                required: "required",
                                            })} type="radio" onChange={(event) => { clearErrors('mentor_email'); handleChange(event) }} className="form-check-input" name="mentor_email" defaultValue={0} checked={mentor_email === 0 ? 'checked' : ''} />{t('_lp_no_')}
                                        </label>
                                    </div>
                                    <span className="text-danger">
                                        {errors.mentor_email && errors.mentor_email.type === "required" && t('_mp_error_email1_')}
                                        {errors.mentor_email && errors.mentor_email.type !== "required" && errors.mentor_email.message}
                                    </span>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <Controller
                                            control={control}
                                            name="mentor_topic"
                                            rules={{ required: false, message: t('_mentorpre_msg_m11_') }}
                                            render={({ field: { onChange, onKeyUp, value, name, classNames, ref } }) => (
                                                <ReactTags
                                                    ref={reactTags}
                                                    tags={tags}
                                                    suggestions={suggestions}
                                                    onDelete={onDelete}
                                                    onAddition={onAddition}
                                                    allowNew={true}
                                                    placeholderText={tags.length > 0 ? '' : t('_mp_topics_')}
                                                />
                                            )}
                                        />
                                        <span className="text-danger">
                                            {errors.mentor_topic?.type === "required" && t('_mentorpre_msg_m11_')}
                                            {errors.mentor_topic?.type !== "required" && errors.mentor_topic?.message}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <Controller
                                            control={control}
                                            name="mentor_lang"
                                            rules={{ required: false, message: t('_lp_learning_preferences_profe_vali_m7_') }}
                                            render={({ field: { onChange, value, name, ref } }) => (
                                                <Select
                                                    inputRef={ref}
                                                    classNamePrefix="addl-class"
                                                    options={lang_options}
                                                    value={selected_lang}
                                                    onChange={(val) => {
                                                        onChange(val.map((c) => c.value));
                                                        setSelectedLang(val);
                                                        setShowDialog(true);
                                                    }}
                                                    isMulti
                                                />
                                            )}
                                        />
                                        <span className="text-danger">
                                            {errors.mentor_lang?.type === "required" && t('_lp_learning_preferences_profe_vali_m7_')}
                                            {errors.mentor_lang?.type !== "required" && errors.mentor_lang?.message}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <textarea rows="4" {...register("mentor_why")} onChange={(event) => {
                                            clearErrors('mentor_why');
                                            setWhy(event.target.value);
                                            setShowDialog(true);
                                        }} className="form-control" placeholder={t('_mp_message_')} defaultValue={mentor_why ? mentor_why : ''}></textarea>
                                        <span className="text-danger">
                                            {errors.mentor_why && errors.mentor_why.type === "required" && t('_mentorpre_msg_m12_')}
                                            {errors.mentor_why && errors.mentor_why.type !== "required" && errors.mentor_why.message}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-success btn-circle btn-md">{t('_save_')}</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MentorPreferences;
