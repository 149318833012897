import { useState } from "react";
import { Link } from "react-router-dom";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import { useTranslation } from "react-i18next";

const ListItem = (props) => {
    const [action, setAction] = useState(props.data.action);
    const { t } = useTranslation();

    function removeBrTags(inputString) {
        const regex = /<br\s*\/?>/gi; // Match <br>, <br/>, <br />, etc.
        return inputString.replace(regex, ''); // Replace matched occurrences with an empty string
    }

    return (
        <>
            {
                action === 'Course_New_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/course-details/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_new_course_m1_')} <b>{props.data?.course?.title}</b> {t('_notilist_new_course_m2_')}</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Material_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_cmaterial_add_')} {props.data?.courseSlideTitle} {t('_notilist_cmaterial_add_m2_')} {props.data?.course?.title} {t('_hr_report_tour_t1_')}</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Assignment_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p> {t('_notilist_cassignment_add_')} {props.data?.courseSlideTitle} {t('_notilist_cmaterial_add_m2_')} {props.data?.course?.title} {t('_hr_report_tour_t1_')}</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Quiz_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_quiz_add_')} {props.data?.courseSlideTitle} {t('_notilist_cmaterial_add_m2_')} {props.data?.course?.title} {t('_notilist_course_')}</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Material_Updated'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_material_update_m1_')} {props.data?.courseSlideTitle} {t('_notilist_material_update_m2_')} {props.data?.course?.title} {t('_notilist_material_update_m3_')}</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Assignment_Updated'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_assignment_update_m1_')} {props.data?.courseSlideTitle} {t('_notilist_assignment_update_m2_')} {props.data?.course?.title} {t('_notilist_assignment_update_m3_')}</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Quiz_Updated'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_quiz_update_m1_')} {props.data?.courseSlideTitle} {t('_notilist_quiz_update_m2_')} {props.data?.course?.title} {t('_notilist_assignment_update_m3_')}</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Material_Deleted'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_cmaterial_delete_m1_')} {props.data?.courseSlideTitle} {t('_notilist_cmaterial_delete_m2_')} {props.data?.course?.title} {t('_notilist_cmaterial_delete_m3_')}</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Assignment_Deleted'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_assignment_delete_m1_')} {props.data?.courseSlideTitle} {t('_notilist_assignment_delete_m2_')} {props.data?.course?.title} {t('_notilist_assignment_delete_m3_')} </p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Quiz_Deleted'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-launched" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_quiz_delete_m1_')} {props.data?.courseSlideTitle} {t('_notilist_assignment_delete_m2_')} {props.data?.course?.title} {t('_notilist_cmaterial_delete_m3_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Invitation'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link ek-lrs-course-initialized" to={`/course-details/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p> {t('_notilist_couinvite_m1_')} {props.data?.course?.title} {t('_notilist_couinvite_m2_')}. </p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'OTM_Meeting_Created'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p> {t('_notilist_create_meeting_m1_')} {props.data?.courseMeeting?.date} {t('_notilist_at_')} {props.data?.courseMeeting?.time} {t('_notilist_create_meeting_m2_')} {props.data?.courseMeeting?.duration} {t('_notilist_create_meeting_m3_')} {props.data?.courseMeeting?.meeting_title} {t('_notilist_quiz_update_m2_')} {props.data?.course?.title} {t('_notilist_course_')} </p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'OTM_Meeting_Deleted'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_meeting_delete_m1_')} {props.data?.courseMeeting?.meeting_title} {t('_notilist_quiz_update_m2_')} {props.data?.course?.title} {t('_notilist_course_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'OTO_Meeting_Accepted'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_meeting_accepted_m1_')} {props.data?.courseMeeting?.date} {t('_notilist_at_')} {props.data?.courseMeeting?.time} {t('_notilist_meeting_accepted_m2_')} {props.data?.courseMeeting?.duration} {t('_notilist_meeting_accepted_m3_')} {props.data?.courseMeeting?.meeting_title} {t('_notilist_meeting_accepted_m4_')} {props.data?.course?.title} {t('_notilist_meeting_accepted_m5_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'OTO_Meeting_Rejected'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_meeting_reject_m1_')} {props.data?.courseMeetingSlot?.date} {t('_notilist_at_')} {props.data?.courseMeetingSlot?.time} {t('_notilist_meeting_accepted_m2_')} {props.data?.courseMeetingSlot?.duration} {t('_notilist_meeting_reject_m2_')} {props.data?.courseMeetingSlot?.meeting_title} {t('_notilist_meeting_accepted_m4_')} {props.data?.course?.title} {t('_notilist_meeting_reject_m3_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Meeting_Scheduled'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_meeting_scheduled_m1_')} {props.data?.courseMeeting?.time} {t('_notilist_meeting_accepted_m2_')} {props.data?.courseMeeting?.duration} {t('_notilist_meeting_scheduled_m2_')} {props.data?.courseMeeting?.meeting_title} {t('_notilist_meeting_accepted_m4_')} {props.data?.course?.title} {t('_notilist_course_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Meeting_Scheduled_Tutor'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    {
                        props.data?.course?.can_access === 1
                            ?
                            <Link className="link" to={`/dashboard/tutor/manage-course/${props.data?.course?.id}`} target="_blank">
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p> {t('_notilist_meeting_scheduled_tutor_m1_')} {props.data?.courseMeeting?.time} {t('_notilist_meeting_accepted_m2_')} {props.data?.courseMeeting?.duration} {t('_notilist_meeting_accepted_m3_')} {props.data?.courseMeeting?.meeting_title} {t('_notilist_meeting_accepted_m4_')} {props.data?.course?.title} {t('_notilist_course_')}.</p>
                                </div>
                            </Link>
                            :
                            <span className="link cursor-pointer" onClick={() => { SwalBox.fire('Access Denied', 'This course has been unpublished by you and hence cannot be accessed.', 'error'); }}>
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>{t('_notilist_meeting_scheduled_tutor_m1_')} {props.data?.courseMeeting?.time} {t('_notilist_meeting_accepted_m2_')} {props.data?.courseMeeting?.duration} {t('_notilist_meeting_accepted_m3_')} {props.data?.courseMeeting?.meeting_title} {t('_notilist_meeting_accepted_m4_')} {props.data?.course?.title} {t('_notilist_course_')}.</p>
                                </div>
                            </span>
                    }
                </li>
            }

            {
                action === 'Reply_On_Query'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link" to={`/learn-course/${props.data?.course?.slug}?tab=QA`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_noti_roq_m1_')} {props.data?.courseQuery?.count} {t('_notilist_roq_m2_')} {props.data?.courseQuery?.date} {t('_notilist_at_')} {props.data?.courseQuery?.time} {t('_notilist_roq_m3_')} {props.data?.course?.title} {t('_notilist_course_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Expiring'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link data-ek-lrs-url={`/learn-course/${props.data?.course?.slug}`} data-ek-lrs-title={`/learn-course/${props.data?.course?.title}`} className="link" to={`/learn-course/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_courexpire_m1_')} <strong>{props.data?.course?.title}</strong> {t('_notilist_courexpire_m2_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Vacancy_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/course-details/${props.data?.course?.slug}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_vacancy_add_m1_')} <strong>{props.data?.vacancy?.job_position}</strong> {t('_notilist_vacancy_add_m2_')} <strong>{props.data?.course?.title}</strong> {t('_notilist_course_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Submitted_To_Review'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/dashboard/tutor/draft-courses`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_courexpire_m1_')} <strong>{props.data?.course?.title}</strong> {t('_notilist_cousubmit_m1_')}</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Published'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/dashboard/tutor/published-courses`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_dh_coursepublished_m1_')} <strong>{props.data?.course?.title}</strong> {t('_notilist_courpublished_m1_')}. </p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'Course_Not_Published'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/dashboard/tutor/edit-course/${props.data?.course?.id}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.course?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_dh_coursenotpublished_m1_')} <strong>{props.data?.course?.title}</strong> {t('_notilist_coursenotpublished_m1_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'New_Question_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    {
                        props.data?.course?.can_access === 1
                            ?
                            <Link className="link" to={`/dashboard/tutor/manage-course/${props.data?.course?.id}?tab=Ques`} target="_blank">
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>{t('_notilist_newque_m1_')} <strong>{props.data?.course?.title}</strong> {t('_notilist_course_')}.</p>
                                </div>
                            </Link>
                            :
                            <span className="link cursor-pointer" onClick={() => { SwalBox.fire('Access Denied', 'This course has been unpublished by you and hence cannot be accessed.', 'error'); }}>
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>{t('_notilist_newque_m1_')} <strong>{props.data?.course?.title}</strong> {t('_notilist_course_')}.</p>
                                </div>
                            </span>
                    }

                </li>
            }

            {
                action === 'OTO_Meeting_Request'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    {
                        props.data?.course?.can_access === 1
                            ?
                            <Link className="link" to={`/dashboard/tutor/manage-course/${props.data?.course?.id}`} target="_blank">
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>{props.data?.courseMeetingSlot?.student} {t('_notilist_otomeet_m1_')} {props.data?.course?.title} {t('_notilist_otomeet_m2_')} {props.data?.courseMeetingSlot?.meeting_title} {t('_notilist_otomeet_m3_')} {props.data?.courseMeetingSlot?.date} {t('_notilist_at_')} {props.data?.courseMeetingSlot?.time} {t('_notilist_meeting_accepted_m2_')} {props.data?.courseMeetingSlot?.duration} {t('_notilist_otomeet_m4_')}.</p>
                                </div>
                            </Link>
                            :
                            <span className="link cursor-pointer" onClick={() => { SwalBox.fire('Access Denied', 'This course has been unpublished by you and hence cannot be accessed.', 'error') }}>
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>{props.data?.courseMeetingSlot?.student} {t('_notilist_otomeet_m1_')} {props.data?.course?.title} {t('_notilist_otomeet_m2_')} {props.data?.courseMeetingSlot?.meeting_title} {t('_notilist_otomeet_m3_')} {props.data?.courseMeetingSlot?.date} {t('_notilist_at_')} {props.data?.courseMeetingSlot?.time} {t('_notilist_meeting_accepted_m2_')} {props.data?.courseMeetingSlot?.duration} {t('_notilist_otomeet_m4_')}.</p>
                                </div>
                            </span>
                    }
                </li>
            }

            {
                action === 'OTO_Meeting_Cancelled'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    {
                        props.data?.course?.can_access === 1
                            ?
                            <Link className="link" to={`/dashboard/tutor/manage-course/${props.data?.course?.id}`} target="_blank">
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>{props.data?.courseMeetingSlot?.student} {t('_notilist_meetcancel_m1_')} {props.data?.courseMeetingSlot?.meeting_title} {t('_notilist_material_update_m2_')} {props.data?.course?.title} {t('_notilist_course_')}.</p>
                                </div>
                            </Link>
                            :
                            <span className="link cursor-pointer" onClick={() => { SwalBox.fire('Access Denied', 'This course has been unpublished by you and hence cannot be accessed.', 'error') }}>
                                <div className="left_photo">
                                    <img src={props.data?.course?.image} alt="" />
                                </div>
                                <div className="right_text">
                                    <p>{props.data?.courseMeetingSlot?.student} {t('_notilist_meetcancel_m1_')} {props.data?.courseMeetingSlot?.meeting_title} {t('_notilist_material_update_m2_')} {props.data?.course?.title} {t('_notilist_course_')}.</p>
                                </div>
                            </span>
                    }
                </li>
            }

            {
                action === 'LC_NEW_ADDED'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/learning-circle-details/${props.data?.group?.unique_id}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_lcadd_m1_')} <b>{props.data?.group?.title}</b> {t('_notilist_lcadd_m2_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Material_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/materials`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_material_update_m1_')} {props.data?.groupSlide?.title} {t('_notilist_lcmaterial_m1_')} {props.data?.groupSlide?.creator} {t('_notilist_to_the_')} {props.data?.group?.title} {t('_notilist_circle_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Material_Updated'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/materials`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_cmaterial_delete_m1_')} {props.data?.groupSlide?.title} {t('_notilist_cmaterial_delete_m2_')} {props.data?.group?.title} {t('_notilist_lcmaterial_update_m1_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Material_Deleted'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/materials`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_cmaterial_delete_m1_')} {props.data?.groupSlide?.title} {t('_notilist_cmaterial_delete_m2_')} {props.data?.group?.title} {t('_notilist_lcmaterial_delete_m1_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Meeting_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/meetings`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{props.data?.groupMeeting?.creator} {t('_notilist_lcmeet_add_m1_')} {props.data?.groupMeeting?.date} {t('_notilist_at_')} {props.data?.groupMeeting?.time} {t('_notilist_meeting_accepted_m2_')} {props.data?.groupMeeting?.duration} {t('_notilist_meeting_reject_m2_')} {props.data?.groupMeeting?.meeting_title} {t('_notilist_to_the_')} {props.data?.group?.title} {t('_notilist_circle_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Meeting_Deleted'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/meetings`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{props.data?.groupMeeting?.creator} {t('_notilist_lcmeet_delete_m1_')} {props.data?.groupMeeting?.meeting_title} {t('_notilist_cmaterial_delete_m2_')} {props.data?.group?.title} {t('_notilist_circle_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Post_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/feeds?feedID=${props.data?.group_feed?.id}`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_lcpost_add_m1_')} {props.data?.group?.title} {t('_notilist_lcpost_add_m2_')}.</p>
                        </div>
                    </Link>
                </li>
            }
            {
                action === 'LC_Post_Video_Under_Review'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/feeds`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_lcpost_video_review_m1_')} {props.data?.group?.title} {t('_notilist_lcpost_video_review_m2_')}.</p>
                        </div>
                    </Link>
                </li>
            }
            {
                action === 'LC_Post_Video_Removed'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/feeds`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_lcpost_video_remove_m1_')} {props.data?.group?.title} {t('_notilist_lcpost_video_remove_m2_')}.</p>
                        </div>
                    </Link>
                </li>
            }
            {
                action === 'LC_Poll_Added'
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/feeds`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{t('_notilist_lcpoll_add_m1_')} {props.data?.group?.title} {t('_notilist_lcpost_add_m2_')}.</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_Post_Liked' && props.data?.group_feed?.likes?.total_count > 0
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/feeds`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{props.data?.group_feed?.likes?.liked_by ? (props.data?.group_feed?.likes?.liked_by[0] + " " + t('_notilist_and_')) : ''}  {props.data?.group_feed?.likes?.total_count > 1 ? props.data?.group_feed?.likes?.total_count - 1 : ''} {t('_notilist_lcpost_like_m1_')} {props.data?.group?.title} {t('_notilist_lcpost_like_m2_')} {props.data?.group_feed?.caption ? removeBrTags(props.data?.group_feed?.caption.substring(0, 20)) : props.data?.group_feed?.poll_exist?.question}</p>
                        </div>
                    </Link>
                </li>
            }

            {
                action === 'LC_New_Post_Comment' && props.data?.group_feed?.comments?.comments_count > 0
                &&
                <li className={`${props.data?.status == 0 ? 'unread' : ''}`}>
                    <Link className="link" to={`/view-learning-circle/${props.data?.group?.unique_id}/feeds`} target="_blank">
                        <div className="left_photo">
                            <img src={props.data?.group?.image} alt="" />
                        </div>
                        <div className="right_text">
                            <p>{props.data?.group_feed?.comments?.comments_by[0]} {t('_notilist_and_')} {props.data?.group_feed?.comments?.comments_count > 1 ? props.data?.group_feed?.comments?.comments_count - 1 : ''} {t('_notilist_lcpost_like_m1_')} {props.data?.group?.title} {t('_notilist_new_post_comment_m1_')} {props.data?.group_feed?.caption ? ' ' + removeBrTags(props.data?.group_feed?.caption.substring(0, 20)) : props.data?.group_feed?.poll_exist?.question}.</p>
                        </div>
                    </Link>
                </li>
            }
        </>

    );
}
export default ListItem;
