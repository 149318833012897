import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
// import { EmployeeSchema } from "./schemas"
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";


const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    headline: "",
    email: "",
    password: "",
    about: "",
}

const EmployeeForm = () => {
    const [processing, setProcessing] = useState(false);
    const { control } = useForm();
    const _token = localStorage.getItem('lmstoken');
    const navigate = useNavigate();
    const [passwordType, setPasswordType] = useState("password");
    const { t } = useTranslation();

    const EmployeeSchema = Yup.object({
        first_name: Yup.string().required(t('_emplist_error_fname_')),
        last_name: Yup.string().required(t('_emplist_lastname_error_')),
        phone: Yup.string().required(t('_general_profile_phone_vali_m2_')).matches(/^(\+\d{1,3}[- ]?)?\d{8,16}$/, t('_emplist_phone_vali_msg_')),
        email: Yup.string().required(t('_as_pass_vali_m4_')).email(t('_empform_vali_msg_m1_')),
        password: Yup.string().required(t('_as_pass_vali_m1_')).matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, t('_empform_vali_msg_m2_')),
    });

    useEffect(() => {
        fetchRoles();
    }, []);
    const [roles, setRoles] = useState('');
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: EmployeeSchema,
        onSubmit: async (values, action) => {
            setProcessing(true);
            let url = `${API_BASE}employee/save`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
                body: JSON.stringify(values),
            });

            const data = await response.json();
            if (data.status) {
                setProcessing(false);
                Toaster.success(data.message);
                action.resetForm();
                navigate('/dashboard/employee');
            } else {
                setProcessing(false);
                Toaster.error(data.message);
            }
        }
    });
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const fetchRoles = async (page = 0) => {
        const response = await fetch(`${API_BASE}role/get?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            //console.info(data.data);
            setRoles(data.data.roles);
        } else {

        }
    }


    return (
        <>
            <Helmet>
                <title>{`EduKula - ${t('_emplist_update_emp_')}`}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        <h4>{t('_emp_add_employee_')}</h4>
                        <Link className="btn btn-circle btn-info" to={"/dashboard/employee"}>{t('_emp_back_tolisting_')}</Link>
                    </div>

                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    <form autoComplete="off" onSubmit={handleSubmit}>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label><b>{t('_emp_update_name_')} </b></label>
                                                <input
                                                    type="text"
                                                    autoComplete="none"
                                                    name="first_name"
                                                    id="first_name"
                                                    placeholder={t('_emp_update_name_place_')}
                                                    className="form-control"
                                                    autoFocus
                                                    value={values.first_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    (errors.first_name && touched.first_name) &&
                                                    <span className="text-danger">{errors.first_name}</span>
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <label><b>{t('_emplist_lastname_')} </b></label>
                                                <input
                                                    type="text"
                                                    autoComplete="none"
                                                    name="last_name"
                                                    id="last_name"
                                                    placeholder={t('_emplist_lastname_place')}
                                                    className="form-control"
                                                    value={values.last_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    (errors.last_name && touched.last_name) &&
                                                    <span className="text-danger">{errors.last_name}</span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label><b>{t('_emplist_phone_')} </b></label>
                                                <input
                                                    type="text"
                                                    autoComplete="none"
                                                    name="phone"
                                                    id="phone"
                                                    placeholder={t('_emplist_phone_place_')}
                                                    className="form-control"
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    (errors.phone && touched.phone) &&
                                                    <span className="text-danger">{errors.phone}</span>
                                                }
                                            </div>

                                            <div className="col-md-6">
                                                <label><b>{t('_emplist_ag_col_role_')}</b></label>
                                                <select
                                                    name="corporate_emp_role"
                                                    id="corporate_emp_role"
                                                    className="form-control select2"
                                                    value={values.corporate_emp_role}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    <option value="">{t('_emplist_select_role_')}</option>
                                                    {
                                                        roles &&
                                                        roles.map((role, index) =>
                                                            <option key={index} value={role.role_id}>{role.name}</option>
                                                        )
                                                    }

                                                </select>
                                            </div>

                                        </div>


                                        <div className="form-group row">



                                            <div className="col-md-12">
                                                <label><b>{t('_emplist_update_heading_')}</b></label>
                                                <input
                                                    type="text"
                                                    autoComplete="none"
                                                    name="headline"
                                                    id="headline"
                                                    placeholder={t('_emp_update_head_place_')}
                                                    className="form-control"
                                                    value={values.headline}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    (errors.headline && touched.headline) &&
                                                    <span className="text-danger">{errors.headline}</span>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label><b>{t('_tr_emp_col_email_')} </b></label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder={t('_emplist_update_email_place_')}
                                                    className="form-control"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    (errors.email && touched.email) &&
                                                    <span className="text-danger">{errors.email}</span>
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <label><b>{t('_password_')} </b></label>
                                                <input
                                                    type={passwordType}
                                                    autoComplete="new-password"
                                                    name="password"
                                                    id="password"
                                                    placeholder={t('_emp_pass_place_')}
                                                    className="form-control"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <span toggle="#password-field" className={`${passwordType === "password" ? "fa fa-eye " : "fa fa-eye-slash"} field-icon`} onClick={togglePassword}></span>
                                                {
                                                    (errors.password && touched.password) &&
                                                    <span className="text-danger">{errors.password}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label><b>{t('_emp_add_employee_about_')} </b></label>
                                            <textarea
                                                name="about"
                                                id="about"
                                                placeholder={t('_emp_add_about_des_')}
                                                className="form-control"
                                                value={values.about}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                            </textarea>
                                            {
                                                (errors.about && touched.about) &&
                                                <span className="text-danger">{errors.about}</span>
                                            }
                                        </div>
                                        <Link className="btn btn-circle btn-md cancel-btn" to={"/dashboard/employee"}>{t('_cancel_')}</Link>
                                        <button disabled={processing} type="submit" className="btn btn-circle btn-md submit-pink-btn">
                                            {
                                                processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : t('_emp_add_employee_')
                                            }
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeForm;
